import { MEDIA_TYPE_TO_TITLE_MAP } from 'app/modules/attachmentsRefresh/constants';

export const parseOutFilename = (filename: string): [string, string] => {
  const extensionStartIndex = filename.lastIndexOf('.');

  // no extension
  if (extensionStartIndex === -1) {
    return [filename, ''];
  }
  return [
    filename.substring(0, filename.lastIndexOf('.')),
    filename.substring(filename.lastIndexOf('.'), filename.length),
  ];
};

export const toReadableMediaType = (mediaType: string) => {
  return MEDIA_TYPE_TO_TITLE_MAP[mediaType] ?? mediaType;
};
