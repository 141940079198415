import React from 'react';
import { isEmpty, startCase } from 'lodash';

// Components
import { Table } from 'semantic-ui-react';

// Styles
import styles from 'app/shared/auditTrail/styles/AuditTrail.module.scss';

interface OwnProps {
  data?: {
    [key: string]: any;
  };
  formatKeys?: boolean;
}

type AllProps = OwnProps;

const renderValue = (
  value: null | string | boolean | string[] | number | number[],
  key = '',
) => {
  switch (typeof value) {
    case 'object':
      if (Array.isArray(value)) {
        return value.join(', ');
      }
      if (value === null) {
        return <div key={key} />;
      }
      return (
        <div key={key} className={styles.objectContainer}>
          <pre>{JSON.stringify(value, null, 1)}</pre>
        </div>
      );
    default:
      return (
        <div className={styles.valueContainer} key={key}>
          <span>{value}</span>
        </div>
      );
  }
};

const BeforeAfterTable = ({ data, formatKeys = true }: AllProps) => {
  if (!data || isEmpty(data)) {
    return null;
  }
  return (
    <Table celled striped stackable className={styles.table}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Tracked Values</Table.HeaderCell>
          <Table.HeaderCell>Before</Table.HeaderCell>
          <Table.HeaderCell>After</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {Object.keys(data).map((key: string) => {
          const value = data[key];
          const before = value[0];
          const after = value[1];
          const fKey = formatKeys ? startCase(key) : key;

          return (
            <Table.Row key={key}>
              <Table.Cell collapsing>{fKey}</Table.Cell>
              <Table.Cell className={styles.tableCell}>
                {renderValue(before)}
              </Table.Cell>
              <Table.Cell className={styles.tableCell}>
                {renderValue(after)}
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};

export default BeforeAfterTable;
