import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

// Actions
import { clearToast as clearToastAction } from 'app/shared/toasts/actions';

// Models
import { ToastStateObject } from 'app/shared/toasts/models';

// Selectors
import { selectToasts } from 'app/shared/toasts/selectors';

// Styles
import styles from 'app/shared/toasts/styles/ToastMessages.module.scss';
import 'app/shared/toasts/styles/ToastAnimations.scss';

import { ToastComponent } from 'app/shared/toasts/components/ToastComponent';

interface ToastMessagesState {
  activeClass: 'Enter' | 'Exit';
  selectedToast: ToastStateObject;
  clearToastTimeout: NodeJS.Timeout;
}

const initialState: ToastMessagesState = Object.freeze({
  activeClass: 'Enter',
  selectedToast: {} as ToastStateObject,
  clearToastTimeout: setTimeout(() => {}, 0),
});

const mapStateToProps = (state: RootState) => ({
  toasts: selectToasts(state),
});

const mapDispatchToProps = {
  clearToast: clearToastAction,
};
type ToastMessagesProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const ToastMessages = ({ toasts, clearToast }: ToastMessagesProps) => {
  const [activeClass, setActiveClass] = useState(initialState.activeClass);
  const [selectedToast, setSelectedToast] = useState({
    ...initialState.selectedToast,
  });
  // need to have the setTimeout window function be a part of state
  // this allows us to clear the timeout - used to auto dismiss a toast
  // if the user closes the toast prior to the auto dismiss
  const [clearToastTimeout, setToastTimeout] = useState(
    initialState.clearToastTimeout,
  );

  const handleClearToast = (message: string) => {
    // change our animation to the exit animation
    setActiveClass('Exit');
    // clear toast setTimeout in the event the user has dismissed the toast
    clearTimeout(clearToastTimeout);
    setToastTimeout(initialState.clearToastTimeout);
    // setTimeout is called here to give our exit animation time to render
    setTimeout(() => {
      // clearToast, reset our selectedToast, reset animation to enter
      clearToast(message);
      setSelectedToast({} as ToastStateObject);
      setActiveClass('Enter');
    }, 300);
  };

  const handleClearToastRef = useRef(handleClearToast);
  handleClearToastRef.current = handleClearToast;
  useEffect(() => {
    const newSelected = toasts[0];
    // setting a new selectedToast after a clear
    if (newSelected && Object.keys(selectedToast).length === 0) {
      setSelectedToast(newSelected);
      // setting our new timeout so the selected toast will auto dismiss
      const newTimeout = setTimeout(
        () => handleClearToastRef.current(newSelected.message),
        4000,
      );
      setToastTimeout(newTimeout);
    }
  }, [selectedToast, toasts]);

  // only render content if a selectedToast exists
  if (Object.keys(selectedToast).length > 0) {
    const { message, position, type, cta } = selectedToast;

    return (
      <div
        className={`toast${position}${activeClass} ${
          styles[`${`toast${position}Container`}`]
        }`}
      >
        <ToastComponent
          position={position}
          message={message}
          clearToast={() => handleClearToast(message)}
          type={type}
          cta={cta}
        />
      </div>
    );
  }
  return null;
};

export default connect(mapStateToProps, mapDispatchToProps)(ToastMessages);
