import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useForm, useFormState, useField } from 'react-final-form';

// Schemas
import {
  CASE_ALERTS,
  CASE_ENTITIES,
  CASE_TAGS,
  CASE_TRANSACTIONS,
  CASE_WATCHERS,
  CASE_ACTION_EVENTS,
  CASE_LINK_ALL_RELATED_TRANSACTIONS,
  CASE_LINK_ALL_RELATED_ENTITIES,
} from 'app/modules/cases/schemas/detailsCaseCreate';

// Utils
import { generateFormFieldProps } from 'app/shared/utils/form';

// Selectors
import {
  selectHasReadAgentsPermissions,
  selectHasReadAlertsPermission,
  selectHasReadEntitiesPermissions,
  selectHasReadEventsPermission,
} from 'app/modules/session/selectors';

// Models
import {
  CaseDetailsPages,
  CasesAddAlertParams,
} from 'app/modules/cases/models';

// Components
import {
  U21Chip,
  U21FormField,
  U21FormFieldCustom,
  U21FormLabel,
  U21Section,
  U21SelectOptionProps,
  U21Spacer,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { AlertAssociationToCasesModal } from 'app/modules/cases/components/AlertAssociationToCasesModal';
import { CaseDetailsReadonlyCard } from 'app/modules/cases/components/CaseDetailsReadonlyCard';

interface Props {
  readOnly?: boolean;
  pageType: CaseDetailsPages;
  isPdf?: boolean;
}

export const CaseCreateDetailsCard = ({ readOnly, pageType, isPdf }: Props) => {
  // values contains the previous, non-updated state. Used to compare to updated values
  const { values } = useFormState();
  const hasReadAgentsPermission = useSelector(selectHasReadAgentsPermissions);
  const hasReadAlertsPermission = useSelector(selectHasReadAlertsPermission);
  const hasReadEventsPermission = useSelector(selectHasReadEventsPermission);
  const hasReadEntitiesPermission = useSelector(
    selectHasReadEntitiesPermissions,
  );

  const [selectedAlert, setSelectedAlert] = useState<number>(-1);

  const [entityOptions, setEntityOptions] = useState<U21SelectOptionProps[]>(
    [],
  );
  const {
    input: { value: txnEvents },
  } = useField<string[] | undefined>(CASE_TRANSACTIONS.name, {
    subscription: { value: true },
  });

  const [actionEventOptions, setActionEventOptions] = useState<
    U21SelectOptionProps[]
  >([]);
  const [modalOpen, setModalOpen] = useState(false);

  const {
    mutators: { setFieldValue },
  } = useForm();

  const handleAlertSelected = (val: number[]) => {
    const selectedAlertsCount = values?.alerts?.length || 0;
    if (val.length > selectedAlertsCount) {
      const [alert] = val.slice(-1);
      setSelectedAlert(alert);
      setModalOpen(true);
    }
  };

  const handleAddAlertButtonPressed = (casesAddAlert: CasesAddAlertParams) => {
    const selectedAlerts = values?.alerts ?? [];
    const selectedEntities = values?.entities ?? [];
    const selectedTransactions = values?.events ?? [];

    const newSelectedAlerts = [...new Set([...selectedAlerts, selectedAlert])];

    const newSelectedEntities = [
      ...new Set([
        ...selectedEntities,
        ...casesAddAlert.entities.map((entity) => entity.value),
      ]),
    ];

    const newSelectedTransactions = [
      ...new Set([
        ...selectedTransactions,
        ...casesAddAlert.events.map((event) => event.value),
      ]),
    ];

    const newSelectedActions = [
      ...new Set([
        ...selectedTransactions,
        ...casesAddAlert.action_events.map((i) => i.value),
      ]),
    ];

    setFieldValue(CASE_ALERTS.name, newSelectedAlerts);
    setFieldValue(CASE_ENTITIES.name, newSelectedEntities);
    setFieldValue(CASE_TRANSACTIONS.name, newSelectedTransactions);
    setFieldValue(CASE_ACTION_EVENTS.name, newSelectedActions);

    setEntityOptions([...entityOptions, ...casesAddAlert.entities]);
    setActionEventOptions([
      ...actionEventOptions,
      ...casesAddAlert.action_events,
    ]);
    setSelectedAlert(-1);
  };

  if (readOnly) {
    return <CaseDetailsReadonlyCard isPdf={isPdf} />;
  }

  return (
    <U21Section
      title={
        <U21Spacer horizontal>
          <span>Details</span>
        </U21Spacer>
      }
    >
      <U21Spacer>
        <U21FormFieldCustom {...generateFormFieldProps(CASE_TAGS)} />
        {hasReadAgentsPermission && (
          <U21FormFieldCustom {...generateFormFieldProps(CASE_WATCHERS)} />
        )}
        {hasReadAlertsPermission && (
          <U21FormFieldCustom
            {...generateFormFieldProps(CASE_ALERTS)}
            fieldProps={{
              ...CASE_ALERTS.fieldProps,
              onChange:
                pageType === CaseDetailsPages.CREATE
                  ? undefined
                  : handleAlertSelected,
            }}
          />
        )}
        {pageType === CaseDetailsPages.CREATE &&
          values.alerts?.length > 0 &&
          hasReadEntitiesPermission && (
            <U21FormField
              {...generateFormFieldProps(CASE_LINK_ALL_RELATED_ENTITIES)}
            />
          )}
        {pageType === CaseDetailsPages.CREATE &&
          values.alerts?.length > 0 &&
          hasReadEventsPermission && (
            <U21FormField
              {...generateFormFieldProps(CASE_LINK_ALL_RELATED_TRANSACTIONS)}
            />
          )}
        {hasReadEntitiesPermission && (
          <U21FormFieldCustom
            {...generateFormFieldProps(CASE_ENTITIES)}
            fieldProps={{
              ...CASE_ENTITIES.fieldProps,
              additionalOptions: entityOptions,
            }}
          />
        )}
        {hasReadEventsPermission && (
          <div>
            <U21FormLabel>{CASE_TRANSACTIONS.label}</U21FormLabel>
            {txnEvents && txnEvents.length > 0 ? (
              <U21Spacer wrap>
                {txnEvents.map((txnExternalId) => (
                  <U21Chip key={txnExternalId}>{txnExternalId}</U21Chip>
                ))}
              </U21Spacer>
            ) : (
              <U21Typography color="text.secondary" variant="body2">
                None
              </U21Typography>
            )}
          </div>
        )}
        {hasReadEventsPermission && (
          <U21FormFieldCustom
            {...generateFormFieldProps(CASE_ACTION_EVENTS)}
            fieldProps={{
              ...CASE_ACTION_EVENTS.fieldProps,
              additionalOptions: actionEventOptions,
            }}
          />
        )}
      </U21Spacer>
      <AlertAssociationToCasesModal
        alertId={selectedAlert}
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        handleAddAlertButtonPressed={handleAddAlertButtonPressed}
      />
    </U21Section>
  );
};
