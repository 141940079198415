// Transform react-country-region-selector.CountryRegionData into a much more search-friendly format

import { CountryRegionData } from 'react-country-region-selector';

/*
  Input format:
  [
    ['United States', 'US', <region stuff>],
    ['Jamaica', 'JM', <region stuff>],
  ]
  Output format:
  {
    'US': 'United States',
    'JM': 'Jamaica',
    ...
  }
*/
export const COUNTRY_CODES_TO_COUNTRY_NAMES = CountryRegionData.reduce<
  Record<string, string>
>((acc, [countryName, countryCode]) => {
  return {
    ...acc,
    [countryCode]: countryName,
  };
}, {});

// Transform react-country-region-selector.CountryRegionData into a much more search-friendly format
/*
  Input format:
  [
    ['United States', 'US', 'Arizona~AZ|Virginia~VA'],
    ...
  ]
  Output format:
  {
    'US': {
      'AZ': 'Arizona',
      'VA': 'Virginia',
      ...
    }
    'JM': {
      '01': 'Kingston'
    },
    ...
  }
*/
export const COUNTRY_CODES_TO_REGIONS = CountryRegionData.reduce<
  Record<string, Record<string, string>>
>((acc, [, countryCode, regionData]) => {
  return {
    ...acc,
    [countryCode]: regionData
      .split('|')
      .reduce<Record<string, string>>((regionAcc, regionEntry) => {
        const [regionValue, regionCode] = regionEntry.split('~');
        return {
          ...regionAcc,
          [regionCode]: regionValue,
        };
      }, {}),
  };
}, {});
