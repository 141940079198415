import { get } from 'app/shared/utils/fetchr';
import { useQuery } from '@tanstack/react-query';
import { FINCEN_SAR_KEYS } from 'app/modules/fincenSarNew/queries/keys';
import { useSelector } from 'react-redux';
import { selectHasReadSARsPermissions } from 'app/modules/session/selectors';
import { FullSARFilingResponse } from 'app/modules/fincenSarNew/response';

export const useGetFinCENSAR = (id: number) => {
  const hasReadSARsPermission = useSelector(selectHasReadSARsPermissions);
  return useQuery({
    enabled: hasReadSARsPermission,
    queryKey: FINCEN_SAR_KEYS.getFinCENSAR(id),
    queryFn: () => get<FullSARFilingResponse>(`/sars/${id}`),
  });
};
