import { useState } from 'react';
import { U21Button, U21Chip, U21Spacer } from 'app/shared/u21-ui/components';
import { IconCopy } from '@u21/tabler-icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styled from 'styled-components';

interface OwnProps {
  content: string;
}

export const CopyButton = ({ content }: OwnProps) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  return (
    <U21Spacer align="center" horizontal>
      <StyledU21Chip color={isCopied ? 'success' : 'default'}>
        Copied
      </StyledU21Chip>
      <StyledCopyToClipboard
        text={content}
        onCopy={(_, success) => {
          setIsCopied(success);
          setTimeout(() => {
            setIsCopied(false);
          }, 2000);
        }}
      >
        <U21Button
          aria-label="copy"
          icon={<IconCopy size={18} />}
          onClick={(e) => e.stopPropagation()}
          size="small"
        />
      </StyledCopyToClipboard>
    </U21Spacer>
  );
};

const StyledU21Chip = styled(U21Chip)<{ color: string }>`
  opacity: ${(p) => (p.color === 'success' ? 1 : 0)};
  transition: opacity 0.3s;
`;

const StyledCopyToClipboard = styled(CopyToClipboard)`
  margin: 0 5px;
`;
