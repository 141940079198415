import { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  U21Button,
  U21LoadError,
  U21Section,
  U21Spacer,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { IconEye, IconEyeOff } from '@u21/tabler-icons';

import { selectAlert } from 'app/modules/alerts/selectors';
import { useGetAlertEntities } from 'app/modules/alerts/queries/useGetAlertEntities';

import { useGetAlertFinCEN314aScanResult } from 'app/modules/lists/queries/useGetAlertFinCEN314aScanResult';
import { FinCEN314aMatch } from 'app/modules/lists/components/FinCEN314aMatch';

export const FinCEN314aMatchSummary = () => {
  const { id: alertId } = useSelector(selectAlert);

  const {
    isLoading,
    isError,
    data: scanResult,
    refetch,
  } = useGetAlertFinCEN314aScanResult(alertId);

  const {
    data: { entities } = { count: 0, entities: [] },
    isLoading: entitiesLoading,
  } = useGetAlertEntities({
    alertId: String(alertId),
    limit: 1,
    offset: 1,
  }); // 314a alert always has a single associated entity

  const [isEmptyFieldShown, setIsEmptyFieldShown] = useState<boolean>(false);

  if (isError) {
    return <U21LoadError label="matches" onTryAgain={() => refetch()} />;
  }

  return (
    <U21Section>
      <U21Spacer spacing={3}>
        <StyledU21Spacer horizontal>
          <U21Typography variant="h4">Match Summary</U21Typography>
          <U21Spacer horizontal>
            <U21Button
              onClick={() => setIsEmptyFieldShown((prev) => !prev)}
              startIcon={isEmptyFieldShown ? <IconEyeOff /> : <IconEye />}
            >
              {isEmptyFieldShown ? 'Hide' : 'Show'} Empty Fields
            </U21Button>
          </U21Spacer>
        </StyledU21Spacer>
        <FinCEN314aMatch
          entity={entities.length < 1 ? undefined : entities[0]}
          isLoading={isLoading || entitiesLoading}
          matchDetails={scanResult?.match_details}
          isEmptyFieldShown={isEmptyFieldShown}
        />
      </U21Spacer>
    </U21Section>
  );
};

const StyledU21Spacer = styled(U21Spacer)`
  justify-content: space-between;
`;
