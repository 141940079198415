import { IconFileDownload } from '@u21/tabler-icons';
import { EXPORT_TYPE_LABELS } from 'app/modules/fileExports/constants';
import { FileExportResponse } from 'app/modules/fileExports/responses';
import { humanReadableFileSize } from 'app/shared/helpers';
import { U21Section, U21Typography } from 'app/shared/u21-ui/components';
import { formatDatetime } from 'app/shared/utils/date';
import { useState } from 'react';
import styled from 'styled-components';

interface AiFileExportDetail {
  fileExport: FileExportResponse;
}

interface DataFieldProps {
  title: string;
  field: string;
}

const DataField = ({ title, field }: DataFieldProps) => {
  return (
    <StyledDataField>
      <U21Typography color="text.secondary" variant="subtitle2">
        {title}
      </U21Typography>
      <U21Typography variant="body1">{field}</U21Typography>
    </StyledDataField>
  );
};

export const AiFileExportsDetail = ({ fileExport }: AiFileExportDetail) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  return (
    <StyledSection
      collapsible
      collapsed={isCollapsed}
      onToggleCollapse={() => setIsCollapsed((prev) => !prev)}
      titleIcon={<IconFileDownload />}
      title={<U21Typography variant="h5">File Export Info</U21Typography>}
    >
      <HorizontalSpacer>
        <DataField title="ID" field={fileExport.id.toString()} />
        <DataField
          title="Created at"
          field={formatDatetime(fileExport.created_at)}
        />
        <DataField
          title="Type"
          field={EXPORT_TYPE_LABELS[fileExport.export_type]}
        />
        <DataField title="Source" field={fileExport.request_source} />
        <DataField
          title="File size"
          field={humanReadableFileSize(fileExport.file_size)}
        />
      </HorizontalSpacer>
    </StyledSection>
  );
};

const StyledSection = styled(U21Section)`
  margin-bottom: 16px;
  box-shadow: ${(props) => props.theme.customShadows.z8};
`;

const HorizontalSpacer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 32px;
  margin-right: 32px;
`;

const StyledDataField = styled.div`
  width: fit-content;
`;
