import { useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'app/store/storeHooks';

// Components
import {
  U21Button,
  U21Form,
  U21Modal,
  U21Spacer,
  U21FormField,
  U21FormFieldCustom,
} from 'app/shared/u21-ui/components';
import { IconPlus, IconUsers } from '@u21/tabler-icons';

// Utils
import { generateFormFieldProps } from 'app/shared/utils/form';

// Constants
import {
  TEAM_NAME_FIELD,
  TEAM_DESCRIPTION_FIELD,
  TEAM_AGENTS_FIELD,
} from 'app/modules/teams/formSchema';
import { ROUTES_MAP } from 'app/shared/utils/routes';

// Redux
import { selectCreateTeamLoading } from 'app/modules/teams/selectors';
import { selectHasCreateTeamsPermission } from 'app/modules/session/selectors';
import { createTeamThunk } from 'app/modules/teams/sliceTeams';

// Models
import { CreatEditTeamFormValues } from 'app/modules/teams/models';

// Permissions auditor
import { addAuditorPermissionsThunk } from 'app/modules/devtools/sliceDevtools';

interface CreateTeamModalProps {
  includeAuditorPermissionSet?: boolean;
}

const CREATE_TEAM_FORM_ID = 'create-team-form';

export const CreateTeamButtonModal = (props: CreateTeamModalProps) => {
  // Modal open
  const [open, setOpen] = useState(false);

  // Permissions Auditor
  const { includeAuditorPermissionSet = false } = props;

  // Create Team BE interation
  const history = useHistory();
  const dispatch = useAppDispatch();
  const loadingCreateTeam = useSelector(selectCreateTeamLoading);

  // Permission test
  const hasCreateTeamsPermission = useSelector(selectHasCreateTeamsPermission);
  if (!hasCreateTeamsPermission) {
    return null;
  }

  return (
    <>
      <U21Button
        startIcon={<IconPlus />}
        variant="contained"
        color="primary"
        onClick={() => setOpen(!open)}
      >
        Create Team
      </U21Button>
      <U21Modal
        title="Create Team"
        titleIcon={<IconUsers />}
        open={open}
        onClose={() => setOpen(false)}
        actionButtonProps={{
          children: 'Create Team',
          type: 'submit',
          form: CREATE_TEAM_FORM_ID,
          loading: loadingCreateTeam,
        }}
      >
        <U21Form
          onSubmit={async (values: CreatEditTeamFormValues) => {
            const { name, description, agentIds } = values;
            try {
              const response = await dispatch(
                createTeamThunk({
                  name,
                  description,
                  agent_ids: agentIds,
                }),
              ).unwrap();
              if (includeAuditorPermissionSet) {
                await dispatch(
                  addAuditorPermissionsThunk({
                    ...response,
                  }),
                );
              }
              setOpen(false);
              history.push(
                ROUTES_MAP.teamsId.path.replace(':id', `${response.id}`),
              );
            } catch {}
          }}
          prompt={false}
          id={CREATE_TEAM_FORM_ID}
        >
          <U21Spacer spacing={4}>
            <U21FormField {...generateFormFieldProps(TEAM_NAME_FIELD)} />
            <U21FormField {...generateFormFieldProps(TEAM_DESCRIPTION_FIELD)} />
            <U21FormFieldCustom
              {...generateFormFieldProps(TEAM_AGENTS_FIELD)}
            />
          </U21Spacer>
        </U21Form>
      </U21Modal>
    </>
  );
};
