import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { U21Typography } from 'app/shared/u21-ui/components';
import { generateColor } from 'app/shared/utils/stringToColor';

import { selectAgent } from 'app/modules/session/selectors';
import { isSuitableBackgroundColor } from 'app/shared/helpers';
import { useMemo } from 'react';

export const SwitchedOrgBanner = ({
  isOrgSwitched,
}: {
  isOrgSwitched?: boolean;
}) => {
  const { org_name: orgName, org_display_name: orgDisplayName } =
    useSelector(selectAgent);

  const [backgroundColor, suitableBackground] = useMemo(() => {
    const backgroundColorInner = generateColor(orgName);
    return [
      backgroundColorInner,
      isSuitableBackgroundColor(backgroundColorInner),
    ];
  }, [orgName]);

  if (!isOrgSwitched) {
    return null;
  }

  return (
    <StyledBanner $backgroundColor={backgroundColor}>
      <U21Typography
        color={suitableBackground ? 'common.white' : 'text.primary'}
      >
        {orgDisplayName}
      </U21Typography>
    </StyledBanner>
  );
};

interface StyledBannerProps {
  $backgroundColor: string;
}

const StyledBanner = styled.div<StyledBannerProps>`
  height: 24px;
  background: ${(props) => props.$backgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
`;
