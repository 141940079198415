import { NOTIFICATIONS_SLICE_NAME } from 'app/modules/notifications/sliceNotifications';

export const selectNotifications = (state: RootState) =>
  state[NOTIFICATIONS_SLICE_NAME].notifications;

export const selectTopbarNotifications = (state: RootState) =>
  state[NOTIFICATIONS_SLICE_NAME].topbarNotifications;

export const selectNotificationsCount = (state: RootState) =>
  state[NOTIFICATIONS_SLICE_NAME].notificationsCount;

export const selectUnreadNotificationsCount = (state: RootState) =>
  state[NOTIFICATIONS_SLICE_NAME].unreadNotificationsCount;

// Loading
export const selectLoadingNotifications = (state: RootState) =>
  state[NOTIFICATIONS_SLICE_NAME].loadingNotifications;

export const selectLoadingTopbarNotifications = (state: RootState) =>
  state[NOTIFICATIONS_SLICE_NAME].loadingTopbarNotifications;

export const selectLoadingMarkAllNotificationsRead = (state: RootState) =>
  state[NOTIFICATIONS_SLICE_NAME].loadingMarkAllNotificationsRead;
