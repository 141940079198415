import { FFIP_NAME } from 'app/modules/pullBasedDataFiles/slicePullBasedDataFiles';
import { Stream } from 'app/modules/pullBasedDataFiles/responses';

export const selectModalState = (state: RootState) => {
  return state[FFIP_NAME].modalState;
};

export const selectUploadingFlatFilesForIngestions = (
  state: RootState,
  streamId: Stream['id'],
) => {
  return state[FFIP_NAME].uploadingFlatFilesByStreamId[streamId] || {};
};

export const selectStreams = (state: RootState) => state[FFIP_NAME].streams;

export const selectDataFilePagesByStreamId = (state: RootState) =>
  state[FFIP_NAME].dataFilePagesByStreamId;

export const selectSelectedStream = (state: RootState) =>
  state[FFIP_NAME].selectedStream;

export const selectSelectedDatafile = (state: RootState) =>
  state[FFIP_NAME].selectedDatafile;
