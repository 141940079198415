// Components
import { PriorArticlesTable } from 'app/modules/alerts/components/AlertDetails/PriorActivity/PriorArticlesTable';

// Models
import { U21TableColumn } from 'app/shared/u21-ui/components';
import { AssociatedAlert } from 'app/modules/alerts/types';

// Utils
import routes from 'app/shared/utils/routes';

// Columns
import {
  DISPOSITION_COLUMN,
  STATUS_COLUMN,
} from 'app/modules/investigations/columns';
import { TITLE_COLUMN } from 'app/modules/alerts/columns';
import {
  ENTITY_IDS_COLUMN,
  CREATED_AT_COLUMN,
} from 'app/modules/alerts/components/AlertDetails/PriorActivity/columns';

const ALERT_ASSOCIATED_ALERTS_TABLE_CONFIG: U21TableColumn<AssociatedAlert>[] =
  [
    { id: 'id', accessor: 'id', Header: 'ID' },
    {
      ...(TITLE_COLUMN as unknown as U21TableColumn<AssociatedAlert>),
      accessor: 'title',
      Header: 'Title',
      headerIcon: undefined,
    },
    ENTITY_IDS_COLUMN,
    {
      ...(STATUS_COLUMN as unknown as U21TableColumn<AssociatedAlert>),
      accessor: 'status',
      Header: 'Status',
      headerIcon: undefined,
    },
    {
      ...(DISPOSITION_COLUMN as unknown as U21TableColumn<AssociatedAlert>),
      accessor: 'disposition',
      Header: 'Disposition',
    },
    CREATED_AT_COLUMN,
  ];

interface OwnProps {
  associatedAlerts: AssociatedAlert[];
}

export const PriorAlerts = ({ associatedAlerts }: OwnProps) => {
  return (
    <PriorArticlesTable
      category="alert"
      articles={associatedAlerts}
      detailsRoute={routes.lumos.alertsId}
      config={ALERT_ASSOCIATED_ALERTS_TABLE_CONFIG}
    />
  );
};
