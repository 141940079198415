import React from 'react';
import { useSelector } from 'react-redux';

// Components
import { Segment, Icon } from 'semantic-ui-react';
import GenericButton from 'app/shared/components/GenericButton';

// Selectors
import { selectAlertComponentActionTriggers } from 'app/modules/session/selectors';

// Styles
import styles from 'app/modules/alerts/styles/workspace.module.scss';
import {
  ActionTriggerModel,
  AlertComponentActionTriggerModel,
} from 'app/modules/webhooks/models';

interface OwnProps {
  handleWhitelistEntity?: () => void;
  handleActionTriggerButton: (id: number) => void;
  disabled: boolean;
  tagIds?: number[];
}

type AllProps = OwnProps;

const AlertComponentResolutionButtons = (props: AllProps) => {
  const { handleWhitelistEntity, handleActionTriggerButton, disabled, tagIds } =
    props;
  const actionTriggers = useSelector(selectAlertComponentActionTriggers);

  const getActionTriggersToDisplay = (triggers) => {
    const hasDisplayTags = (trigger: ActionTriggerModel) => {
      if (!trigger.display_tags) {
        return true;
      }

      // the resource's tags must include every display tag
      return trigger.display_tags.every((tag) => tagIds?.includes(tag));
    };

    const doesNotHaveMaskingTags = (trigger: ActionTriggerModel) => {
      if (!trigger.masking_tags) {
        return true;
      }

      // the resource's tags must _not_ include _any_ masking tag
      return !trigger.masking_tags.some((tag) => tagIds?.includes(tag));
    };

    return !tagIds
      ? triggers
      : triggers.filter(hasDisplayTags).filter(doesNotHaveMaskingTags);
  };

  const renderActionTriggerButton = (
    trigger: AlertComponentActionTriggerModel,
  ) => {
    return (
      <GenericButton
        key={trigger.label}
        icon
        className={styles.entityButton}
        color={trigger.color}
        disabled={disabled}
        onClick={() => handleActionTriggerButton(trigger.id)}
        labelPosition="left"
      >
        <Icon name={trigger.icon} />
        {trigger.label}
      </GenericButton>
    );
  };

  // Don't render empty segments
  if (!handleWhitelistEntity && actionTriggers.length === 0) {
    return null;
  }

  const actionTriggersToDisplay: AlertComponentActionTriggerModel[] =
    getActionTriggersToDisplay(actionTriggers);

  if (
    actionTriggersToDisplay.length === 1 &&
    actionTriggersToDisplay[0].config.resolution === 'UNRESOLVED'
  ) {
    actionTriggersToDisplay.pop(); // don't show `Unresolve` if it's the only trigger left
  }

  return (
    <Segment className={styles.entityButtons}>
      {actionTriggersToDisplay.map(
        (trigger: AlertComponentActionTriggerModel) =>
          renderActionTriggerButton(trigger),
      )}

      {handleWhitelistEntity && (
        <GenericButton // TODO shift whitelist component to action trigger, the hard coded values are here because this is temporary
          icon
          className={styles.entityButton}
          color="blue"
          disabled={disabled}
          onClick={handleWhitelistEntity}
          labelPosition="left"
        >
          <Icon name="arrow alternate circle right" />
          Whitelist
        </GenericButton>
      )}
    </Segment>
  );
};

export default AlertComponentResolutionButtons;
