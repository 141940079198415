import { PaginatedAgentsPayload } from 'app/modules/agents/requests';

const BASE = ['agents'] as const;

export const AGENT_QUERY_KEYS = {
  getAgents: (payload: PaginatedAgentsPayload) =>
    [...BASE, 'agents', payload] as const,
  getAgent: (id?: number) => [...BASE, 'agent', id] as const,
  searchAgents: (payload?: PaginatedAgentsPayload) => {
    if (payload) {
      return [...BASE, 'search', payload] as const;
    }
    return [...BASE, 'search'];
  },
};
