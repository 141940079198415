import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import pluralize from 'pluralize';
import { isEmpty } from 'lodash';
import { useAppDispatch } from 'app/store/storeHooks';

// Components
import {
  U21Button,
  U21Section,
  U21NoData,
  U21Spacer,
  U21Loading,
} from 'app/shared/u21-ui/components';
import { IconFiles, IconFileText, IconPlus } from '@u21/tabler-icons';
import { NarrativeDetails } from 'app/modules/narratives/components/NarrativeDetails';
import { AddNarrativeModal } from 'app/modules/narratives/components/AddNarrativeModal';

// Models
import { InvestigationType } from 'app/modules/investigations/models';
import { CreateNarrativePayload } from 'app/modules/narratives/requests';
import { NarrativeTemplateType } from 'app/modules/narrativeTemplates/models';

// Selectors
import { selectHasReadWorkflowsPermission } from 'app/modules/session/selectors';
import {
  selectLoadingNarratives,
  selectNarratives,
} from 'app/modules/narratives/selectors';
import {
  selectHasEditInvestigationPermission,
  selectInvestigation,
} from 'app/modules/investigations/selectors';

// Thunks
import {
  createNarrativeThunk,
  retrieveNarrativesListThunk,
} from 'app/modules/narratives/sliceNarratives';

// Constants
import { ALERT_NARRATIVE_TEMPLATES_DOCS } from 'app/shared/constants/docs';
import { ROUTES_MAP } from 'app/shared/utils/routes';
import { AlertDetails } from 'app/modules/alerts/models';
import { CaseDetails } from 'app/modules/casesOld/models';
import { U21_DOCS_PASSWORD_TOOLTIP } from 'app/shared/u21-ui/components/dashboard';

interface OwnProps {
  type: InvestigationType;
  readOnly: boolean;
}

export const NarrativesList = ({ type, readOnly }: OwnProps) => {
  const dispatch = useAppDispatch();
  const hasReadWebhooksPermissions = useSelector(
    selectHasReadWorkflowsPermission,
  );
  const hasEditInvestigationPermissions = useSelector(
    selectHasEditInvestigationPermission(type),
  );
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);

  const article = useSelector(selectInvestigation(type));
  const narratives = useSelector(selectNarratives);
  const loadingNarratives = useSelector(selectLoadingNarratives);
  const investigationText = type === InvestigationType.ALERT ? 'Alert' : 'Case';

  const canCreateNarrative = hasEditInvestigationPermissions && !readOnly;

  useEffect(() => {
    if (article.id > 0) {
      dispatch(retrieveNarrativesListThunk({ article_id: article.id }));
    }
  }, [dispatch, article.id]);

  const headerActions = useMemo(() => {
    return (
      <U21Spacer spacing={1} horizontal>
        {hasReadWebhooksPermissions && (
          <U21Button
            startIcon={<IconFiles />}
            to={
              type === InvestigationType.ALERT
                ? ROUTES_MAP.alertNarrativeTemplates.path
                : ROUTES_MAP.caseNarrativeTemplates.path
            }
          >
            Manage narrative templates
          </U21Button>
        )}
        {canCreateNarrative && (
          <U21Button
            startIcon={<IconPlus />}
            onClick={() => setOpenCreateModal(true)}
            color="primary"
            variant="contained"
          >
            Add narrative
          </U21Button>
        )}
      </U21Spacer>
    );
  }, [hasReadWebhooksPermissions, type, canCreateNarrative]);

  const articleQueueId = useMemo(() => {
    return (
      (type === InvestigationType.ALERT
        ? (article as AlertDetails).queue_id
        : (article as CaseDetails).queue_id) || undefined
    );
  }, [article, type]);

  const handleCreateNarrative = useCallback(
    async (
      narrativeTemplate: string,
      narrativeTemplateId: number | undefined,
    ) => {
      const payload: CreateNarrativePayload = {
        article_id: article.id,
        narrative: narrativeTemplate || '',
        narrative_template_id: narrativeTemplateId,
        queue_id: articleQueueId,
      };
      try {
        await dispatch(createNarrativeThunk(payload)).unwrap();
        setOpenCreateModal(false);
      } catch {}
    },
    [article.id, articleQueueId, dispatch],
  );

  const renderNarratives = () => {
    if (loadingNarratives) {
      return <U21Loading loading={loadingNarratives} />;
    }

    return isEmpty(narratives) ? (
      <U21NoData />
    ) : (
      <U21Spacer spacing={4}>
        {narratives.map((narrative) => (
          <NarrativeDetails
            key={narrative.id}
            narrative={narrative}
            readOnly={!hasEditInvestigationPermissions}
            investigationType={type}
          />
        ))}
      </U21Spacer>
    );
  };

  return (
    <U21Section
      action={headerActions}
      helpModalProps={{
        children: `
          Narratives are text forms filled out by Agents when investigating a ${investigationText}. 
          A Narrative can be free form text, but it is often based off of a ${investigationText} Narrative Template, which has fill-in-the-blank text segments that needs to be tailored for each ${investigationText}. 
          ${investigationText} Narrative Templates can appear for ${pluralize(
            investigationText.toLowerCase(),
            2,
          )} in every queue, or can be set to only appear in ${pluralize(
            investigationText.toLowerCase(),
            2,
          )} belonging to specific queues.`,
        docsLink: ALERT_NARRATIVE_TEMPLATES_DOCS,
        docsLinkProps: { tooltip: U21_DOCS_PASSWORD_TOOLTIP },
      }}
      titleIcon={<IconFileText />}
      title="Narratives"
    >
      {renderNarratives()}
      <AddNarrativeModal
        open={openCreateModal}
        handleClose={() => setOpenCreateModal(false)}
        handleSubmit={handleCreateNarrative}
        queueId={articleQueueId || null}
        type={
          type === InvestigationType.ALERT
            ? NarrativeTemplateType.ALERT_NARRATIVE_TEMPLATE
            : NarrativeTemplateType.CASE_NARRATIVE_TEMPLATE
        }
      />
    </U21Section>
  );
};
