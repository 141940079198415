import { Fragment, useMemo } from 'react';

// Components
import {
  U21_NO_VALUE,
  U21Card,
  U21Grid,
  U21Loading,
  U21NoData,
  U21Spacer,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { IconUserCircle } from '@u21/tabler-icons';
import { MatchCardSection } from 'app/modules/watchlists/components/MatchCardSection';

// Models
import {
  FormattedData,
  OrgDataSettingsConfig,
} from 'app/modules/dataSettings/responses';
import {
  FormattedWatchlistFields,
  WatchlistResult,
} from 'app/modules/watchlists/models';
import { ShortEntityResponse } from 'app/modules/entities/types/responses';

// Queries
import { useFetchWatchlistConfig } from 'app/modules/watchlists/queries/useFetchWatchlistConfig';

// Utils
import { isFieldEmpty } from 'app/modules/dataSettings/utils';

import {
  getWatchlistResultName,
  resizeConfigArr,
} from 'app/modules/watchlists/helpers';
import { FetchWatchlistConfigResponse } from 'app/modules/watchlists/responses';
import { selectEntityDataSettingsByNativeKey } from 'app/modules/dataSettings/selectors';
import { useSelector } from 'react-redux';
import { DataSettingDataDisplay } from 'app/modules/dataSettings/shared/DataSettingDataDisplay';
import { WatchlistProviders } from 'app/modules/detectionModels/components/scenarioWidgets/watchlist/enums';
import { selectWatchlistProvider } from 'app/shared/featureFlags/selectors';
import styled from 'styled-components';
import { MatchSummaryCard } from 'app/modules/watchlists/components/cards/MatchSummaryCard';

interface MatchWatchlistProps {
  provider: string;
  watchlist: WatchlistResult;
  entity: ShortEntityResponse | undefined;
  isEmptyFieldShown: boolean;
  watchlistPreviewFields?: FormattedWatchlistFields[];
}

const mapFieldsForCard = (
  config: OrgDataSettingsConfig | null,
  formattedData: FormattedData | undefined,
  matchConfig: OrgDataSettingsConfig,
  matchFormattedData: FormattedData | undefined,
) => {
  if (config === null || formattedData === undefined) {
    return {
      key: `placeholder-${matchConfig.id}`,
      dataSetting: undefined,
      data: {},
      isEmpty:
        // Whole row is not considered empty when there is a matching value on the same row
        matchFormattedData === undefined ||
        matchConfig === null ||
        isFieldEmpty(matchFormattedData[matchConfig.id]),
    };
  }
  return {
    key: `${config.id}`,
    dataSetting: config,
    data: formattedData,
    isEmpty:
      // Whole row is considered empty when the entire row of values are empty
      (matchFormattedData === undefined ||
        matchConfig === null ||
        isFieldEmpty(matchFormattedData[matchConfig.id])) &&
      isFieldEmpty(formattedData[config.id]),
  };
};

export const MatchWatchlist = ({
  provider,
  watchlist,
  entity,
  isEmptyFieldShown,
  watchlistPreviewFields,
}: MatchWatchlistProps) => {
  const watchlistProvider: WatchlistProviders = useSelector(
    selectWatchlistProvider,
  );

  const isConfigPreview = useMemo<boolean>(
    () => watchlistPreviewFields !== undefined,
    [watchlistPreviewFields],
  );

  // Get provider config
  const { data: fetchedConfig, isLoading } = useFetchWatchlistConfig(
    provider,
    isConfigPreview,
  );
  const entityDataSettingsByNativeKey = useSelector(
    selectEntityDataSettingsByNativeKey,
  );

  const config = useMemo<FetchWatchlistConfigResponse | undefined>(
    () =>
      isConfigPreview
        ? {
            fields: watchlistPreviewFields!.map((i) => ({
              entity_data_values: [...i.entity_data_values],
              watchlist_data_values: [...i.watchlist_data_values],
            })),
            entityUnusedFields: [],
            watchlistUnusedFields: [],
            formattedData: {},
          }
        : fetchedConfig,
    [fetchedConfig, isConfigPreview, watchlistPreviewFields],
  );

  // Get config separately for card
  const watchlistConfig: OrgDataSettingsConfig[][] | undefined = useMemo(
    () =>
      config?.fields.map((groupConfig) =>
        resizeConfigArr(
          groupConfig.watchlist_data_values,
          groupConfig.entity_data_values,
        ),
      ),
    [config],
  );

  const entityConfig: OrgDataSettingsConfig[][] | undefined = useMemo(
    () =>
      config?.fields.map((groupConfig) =>
        resizeConfigArr(
          groupConfig.entity_data_values,
          groupConfig.watchlist_data_values,
        ),
      ),
    [config],
  );

  const watchlistName: string = useMemo(
    () => getWatchlistResultName(watchlistConfig, watchlist.formatted_data),
    [watchlistConfig, watchlist.formatted_data],
  );

  if (isLoading) {
    return <U21Loading loading />;
  }

  if (
    !isConfigPreview &&
    (config === undefined ||
      (config.fields.length === 1 &&
        config.fields[0].entity_data_values.length === 0 &&
        config.fields[0].watchlist_data_values.length === 0))
  ) {
    return (
      <U21NoData>
        {`There is no configuration for provider: ${provider}.`}
      </U21NoData>
    );
  }

  if (entity === undefined) {
    return <U21NoData>There is no entity.</U21NoData>;
  }

  const dataSetting = entityDataSettingsByNativeKey.registered_at;

  return (
    <U21Spacer>
      <U21Grid columns={2} spacing={1}>
        <U21Card
          title={entity.name_readable}
          subheader={
            <U21Spacer horizontal spacing={0.5}>
              <span>Onboarding date:</span>
              <DataSettingDataDisplay
                dataSetting={dataSetting}
                formattedData={entity.formatted_data}
                dataDisplayProps={{ DATE_TIME: { color: 'text.secondary' } }}
              />
            </U21Spacer>
          }
          icon={<IconUserCircle />}
        >
          {/* watchlistConfig is resized so [i][j] will definitely exist even if it's a placeholder */}
          <U21Spacer dividers>
            {entityConfig?.map((groupConfig, i) => {
              const data = groupConfig.map((ec, j) =>
                mapFieldsForCard(
                  ec,
                  entity?.formatted_data,
                  watchlistConfig![i][j],
                  watchlist.formatted_data,
                ),
              );

              return (
                <Fragment key={JSON.stringify(data)}>
                  <MatchCardSection
                    key={JSON.stringify(data)}
                    fields={data}
                    isEmptyFieldShown={isEmptyFieldShown}
                  />
                </Fragment>
              );
            })}
          </U21Spacer>
        </U21Card>
        <StyledCard
          title={watchlistName || U21_NO_VALUE}
          subheader={
            <U21Typography variant="body2" ellipsis color="inherit">
              {watchlist.names
                ? new Intl.ListFormat('en-US').format(watchlist.names)
                : U21_NO_VALUE}
            </U21Typography>
          }
          icon={<IconUserCircle />}
        >
          {/* entityConfig is resized so [i][j] will definitely exist even if it's a placeholder */}
          <U21Spacer dividers>
            {watchlistConfig?.map((groupConfig, i) => {
              const data = groupConfig.map((wc, j) =>
                mapFieldsForCard(
                  wc,
                  watchlist.formatted_data,
                  entityConfig![i][j],
                  entity?.formatted_data,
                ),
              );
              return (
                <Fragment key={JSON.stringify(data)}>
                  <MatchCardSection
                    key={JSON.stringify(data)}
                    fields={data}
                    isEmptyFieldShown={isEmptyFieldShown}
                  />
                </Fragment>
              );
            })}
          </U21Spacer>
        </StyledCard>
      </U21Grid>
      {watchlistProvider === 'LexisNexis' && (
        <MatchSummaryCard watchlist={watchlist} />
      )}
    </U21Spacer>
  );
};

// Needed to ensure subheader has ellipsis when page is too small
const StyledCard = styled(U21Card)`
  & .MuiCardHeader-content {
    width: 100%;
  }
`;
