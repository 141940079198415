import { FilterOption } from 'app/modules/filters/models';

import { FILTER_OPERATOR, FILTER_TYPE } from 'app/modules/filters/constants';
import { PrimaryObject } from 'app/modules/dataMapping/types';
import { TABLE_TO_READABLE_NAME } from 'app/modules/dataMapping/constants';

export const STREAM_FILTER_KEYS = {
  SEARCH: 'search',
  TYPE: 'type',
} as const;

export const ALL_STREAM_FILTER_OPTIONS: FilterOption[] = [
  {
    key: STREAM_FILTER_KEYS.SEARCH,
    label: 'Search',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: STREAM_FILTER_KEYS.TYPE,
    label: 'Type',
    type: FILTER_TYPE.MULTI_SELECT,
    customize: {
      [FILTER_OPERATOR.IS_ONE_OF]: {
        inputProps: {
          options: Object.values(PrimaryObject).map((o) => ({
            value: o,
            text: TABLE_TO_READABLE_NAME[o],
          })),
        },
      },
      [FILTER_OPERATOR.IS_NOT_ONE_OF]: { disabled: true },
    },
  },
];
