import React, { ReactNode } from 'react';
import { Header } from 'semantic-ui-react';
import { StyleConstants } from 'app/styles/StyleConstants';

interface ContentHeaderProps {
  children: string | ReactNode;
  floatLeft?: boolean;
}

/**
 * @deprecated Use U21 layout components instead
 */
const ContentHeader = ({ children, floatLeft }: ContentHeaderProps) => {
  if (floatLeft) {
    return (
      <Header floated="left" as="h1" style={styles.text} textAlign="left">
        <Header.Content style={styles.content}>{children}</Header.Content>
      </Header>
    );
  }
  return (
    <Header as="h1" style={styles.text} textAlign="left">
      <Header.Content style={styles.content}>{children}</Header.Content>
    </Header>
  );
};

const styles: StyleMap = {
  text: {
    fontFamily: StyleConstants.FONT_FAMILY,
    fontSize: StyleConstants.CONTENT_HEADER_FONT_SIZE,
    fontWeight: StyleConstants.CONTENT_LIGHT_FONT_WEIGHT,
  },
  content: {
    wordWrap: 'break-word',
    display: 'inline',
  },
};

export default ContentHeader;
