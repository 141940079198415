import {
  FilterOperatorAndValue,
  FilterOption,
} from 'app/modules/filters/models';

import { useState } from 'react';
import styled from 'styled-components';

import { FilterBuilder } from 'app/modules/filters/components/FilterBuilder';
import { IconPinFilled } from '@u21/tabler-icons';
import { U21Chip, U21MenuLayout } from 'app/shared/u21-ui/components';

interface Props {
  onPinChange?: () => void;
  onSubmit: (operatorAndValue: FilterOperatorAndValue) => void;
  option: FilterOption;
  disabled?: boolean;
}

export const FilterPinnedChip = (props: Props) => {
  const { onSubmit, onPinChange, option, disabled } = props;
  const { label } = option;
  const [open, setOpen] = useState(false);
  return (
    <U21MenuLayout
      onClose={() => setOpen(false)}
      open={open}
      trigger={
        <StyledU21Chip
          disabled={disabled}
          icon={<StyledIconPinFilled />}
          onClick={() => setOpen(true)}
          rounded
          variant="outlined"
        >
          {label}
        </StyledU21Chip>
      }
    >
      <FilterBuilder
        onClose={() => setOpen(false)}
        onPinChange={onPinChange}
        onSubmit={(operatorAndValue) => {
          onSubmit(operatorAndValue);
          setOpen(false);
        }}
        option={option}
        pinned
      />
    </U21MenuLayout>
  );
};

const StyledU21Chip = styled(U21Chip)`
  color: ${(props) => props.theme.palette.text.secondary};

  .MuiChip-icon {
    color: ${(props) => props.theme.palette.text.secondary};
  }
`;

const StyledIconPinFilled = styled(IconPinFilled)`
  color: ${(props) => props.theme.palette.text.secondary};
`;
