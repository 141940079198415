import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Components
import { TabLabel } from 'app/modules/investigations/components/InvestigationTabs/TabLabel';

// Selectors
import { selectAlert, selectHasValidAlert } from 'app/modules/alerts/selectors';
import { selectNotes } from 'app/modules/notes/selectors';

// Actions
import { retrieveNotes } from 'app/modules/notes/actions';

export const NotesLabel = () => {
  const dispatch = useDispatch();

  const plural = (n: number) => (n !== 1 ? 's' : '');

  const { id } = useSelector(selectAlert);
  const hasValidAlert = useSelector(selectHasValidAlert);

  const notes = useSelector(selectNotes);

  useEffect(() => {
    if (id > 0 && hasValidAlert) {
      dispatch(retrieveNotes(id));
    }
  }, [id, hasValidAlert, dispatch]);

  const tooltip = notes.length
    ? `This alert has ${notes.length} note${plural(notes.length)}`
    : undefined;

  return <TabLabel label="Notes" badge={notes.length} tooltip={tooltip} />;
};
