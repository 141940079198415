import { useSelector } from 'react-redux';
import { selectHasAdminAPIPermission } from 'app/modules/session/selectors';
import { useQuery } from '@tanstack/react-query';
import { ORG_MANAGEMENT_QUERY_KEYS } from 'app/modules/orgManagement/queries/keys';
import { get } from 'app/shared/utils/fetchr';
import { ShortOrgResponse } from 'app/modules/orgManagement/responses';
import { U21SelectOptionProps } from 'app/shared/u21-ui/components';

type Options<TSelect = ShortOrgResponse[]> = {
  enabled?: boolean;
  select?: (data: ShortOrgResponse[]) => TSelect;
};

export const useGetOrgs = <TSelect = ShortOrgResponse[]>(
  options: Options<TSelect> = {},
) => {
  const { enabled = true, select } = options;
  const hasAdminAPIPermission = useSelector(selectHasAdminAPIPermission);
  return useQuery({
    enabled: enabled && hasAdminAPIPermission,
    queryKey: ORG_MANAGEMENT_QUERY_KEYS.getOrgs(),
    queryFn: () => get<ShortOrgResponse[]>('/orgs/list'),
    select,
    staleTime: Infinity,
  });
};

export const useGetOrgOptions = (options?: Omit<Options, 'select'>) => {
  const { data = [], ...restQuery } = useGetOrgs({
    ...options,
    select: (response) =>
      response.map<U21SelectOptionProps<number>>((i) => ({
        value: i.id,
        text: i.display_name,
      })),
  });
  return {
    ...restQuery,
    data,
  };
};
