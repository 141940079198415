import { useQuery } from '@tanstack/react-query';

import { post } from 'app/shared/utils/fetchr';
import { TxnAnalysisPayload } from 'app/modules/txnAnalysis/requests';
import { TxnAnalysisResponse } from 'app/modules/txnAnalysis/responses';
import { TXN_ANALYSIS_QUERY_KEYS } from 'app/modules/txnAnalysis/queries/keys';

export const retrieveTxnAnalysis = (
  payload: TxnAnalysisPayload,
): Promise<TxnAnalysisResponse> => {
  return post(`/txn-analysis/list`, payload);
};

export const useGetTxnAnalysis = (payload: TxnAnalysisPayload) => {
  return useQuery({
    queryKey: TXN_ANALYSIS_QUERY_KEYS.getTxnAnalysisQueryKey(payload),
    queryFn: () => retrieveTxnAnalysis(payload),
    meta: { errorMessage: 'Failed to get transaction analysis.' },
  });
};
