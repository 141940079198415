import {
  InvestigationType,
  AttachmentsFormValues,
} from 'app/modules/investigations/models';

import { INVESTIGATION_DOCUMENTS } from 'app/modules/investigations/schemas';

import { createFormInitialValues } from 'app/shared/utils/form';
import { selectAlert } from 'app/modules/alerts/selectors';
import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { InvestigationDocuments } from 'app/modules/investigations/components/InvestigationDocuments';
import { U21Form } from 'app/shared/u21-ui/components';

export const Documents = () => {
  const alert = useSelector(selectAlert);
  const initialValues = useMemo(
    () =>
      createFormInitialValues<AttachmentsFormValues>(
        [INVESTIGATION_DOCUMENTS],
        alert,
      ),
    [alert],
  );

  return (
    <U21Form onSubmit={() => {}} initialValues={initialValues}>
      <InvestigationDocuments type={InvestigationType.ALERT} />
    </U21Form>
  );
};
