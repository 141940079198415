import { UnknownError } from 'app/modules/pullBasedDataFiles/components/sidebars/UnknownError';
import { UnhandledProcessingExceptionInstance } from 'app/modules/pullBasedDataFiles/responses';
import {
  U21Alert,
  U21Chip,
  U21Spacer,
  U21Subsection,
  U21Typography,
} from 'app/shared/u21-ui/components';
import styled from 'styled-components';

interface Props {
  errors: UnhandledProcessingExceptionInstance[];
  count: number;
  defaultCollapsed: boolean;
}

export const UnknownErrorsSection = ({
  errors,
  count,
  defaultCollapsed,
}: Props) => {
  return (
    <U21Subsection
      collapsed={defaultCollapsed}
      collapsible
      title={
        <U21Spacer horizontal align="center">
          <U21Typography variant="subtitle1">Unknown Errors</U21Typography>
          <StyledU21Chip variant="ghost">{count} errors</StyledU21Chip>
        </U21Spacer>
      }
    >
      {count > errors.length && (
        <U21Alert severity="info">
          Only the first 10 instances of unknown errors are displayed.
        </U21Alert>
      )}
      <U21Spacer spacing={0.5} dividers>
        {errors.map((e) => (
          <UnknownError obj={e} key={e.row_number} />
        ))}
      </U21Spacer>
    </U21Subsection>
  );
};

const StyledU21Chip = styled(U21Chip)`
  text-transform: none;
`;
