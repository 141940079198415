import React, { SyntheticEvent } from 'react';
import autoBindReact from 'auto-bind/react';

// Redux
import { connect } from 'react-redux';

// Models
import {
  SortDirection,
  RowId,
  SelectableCheckboxProps,
  RowMenuItem,
  TableConfig,
  PaginationPayload,
  EndOfRowIcon,
} from 'app/shared/pagination/models';
import { SarSummary } from 'app/modules/fincenSar/models';
import { TableConfigType } from 'app/shared/CustomConfig/models';

// Components
import {
  Table,
  Checkbox,
  SemanticICONS,
  Dropdown,
  TableProps,
  Icon,
  Label,
} from 'semantic-ui-react';
import ReactTooltip from 'react-tooltip';
import ResizableInput from 'app/shared/components/ResizableInput';
import LoadingTable from 'app/shared/components/LoadingTable';
import NoResultsComponent from 'app/shared/components/NoResultsComponent';
import GenericButton from 'app/shared/components/GenericButton';
import LoadingWrapper from 'app/shared/components/LoadingWrapper';

// Selectors
import {
  selectDefaultTagConfig,
  selectDefaultTagConfigExists,
  selectGetDefaultTagConfigNames,
} from 'app/modules/orgSettings/selectors';

// Actions
import { toggleTableConfigSidebar as toggleTableConfigSidebarAction } from 'app/modules/sidebar/slice';

// Helpers
import {
  formatTableValue,
  getValueFromEntity,
} from 'app/modules/entities/helpers';

// Utils
import emptyFn from 'app/shared/utils/empty-fn';
import assets from 'app/shared/utils/assets';

import { heapTrack } from 'app/shared/utils/heap';
import heapEvents from 'app/shared/utils/heapEvents';

// Constants
import {
  PAGE_LIMIT_OPTIONS,
  EXTENDED_PAGE_LIMIT_OPTIONS,
} from 'app/shared/constants';
import {
  DEFAULT_OFFSET,
  DEFAULT_PAGINATION_LIMIT,
} from 'app/shared/pagination/constants';

// Styles
import styles from 'app/shared/pagination/styles/PaginationTable.module.scss';
import { ValueField } from 'app/modules/search/helpers';

// looks nice with our max-height of 500px;
const LOADING_LIMIT = 8;

type EndRowIconFunc = (sarSummary: SarSummary) => EndOfRowIcon | undefined;

interface OwnProps {
  // Required
  rows: any[];
  config: TableConfig[];
  update: (payload: PaginationPayload, tableConfig: TableConfig[]) => void;
  totalCount: number | null; // null count means too many to count
  loading: boolean;
  addDefaultTagConfigColumn?: boolean; // to add the default tag config column

  // Optional
  classNameProps?: string; // optional styles
  limit?: number; // optional page limit
  basic?: boolean;
  dataType?: string; // one of 'alerts' | 'cases' | 'entities' | etc...
  dataTypeIcon?: SemanticICONS;
  deleteOnClick?: (rowId: string | number) => void;
  disableFooter?: boolean;
  disableFooterReload?: boolean;
  icon?: SemanticICONS;
  isRowClickable?: (row: any) => boolean;
  onRowClick?: (e: MouseEvent | KeyboardEvent, row: any) => void;
  renderCellValue?: (config: TableConfig, row: any) => React.ReactNode;
  rowMenuItems?: RowMenuItem[];
  selectableCheckboxProps?: SelectableCheckboxProps; // to add selectable checkbox on left column
  selectedRowId?: RowId; // to highlight a selected row
  tableProps?: TableProps;
  fullPageTable?: boolean;
  useLoadingWrapper?: boolean;
  printView?: boolean;
  endOfRowIcon?: EndOfRowIcon | EndRowIconFunc;
  tableConfigType?: TableConfigType;
  extendedRowCountOptions?: boolean;
  valueField?: ValueField;
}

const mapStateToProps = (state: RootState) => ({
  defaultTagConfig: selectDefaultTagConfig(state),
  defaultTagConfigExists: selectDefaultTagConfigExists(state),
  getDefaultTagConfigNames: selectGetDefaultTagConfigNames(state),
});

const mapDispatchToProps = {
  toggleTableConfigSidebar: toggleTableConfigSidebarAction,
};

type AllProps = OwnProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

interface AllState {
  limit: number;
  offset: number;
  prevOffset: number;
  sortDirection: SortDirection;
  sortColumn: string;
}

const initialState: AllState = {
  limit: DEFAULT_PAGINATION_LIMIT,
  offset: DEFAULT_OFFSET,
  prevOffset: DEFAULT_OFFSET,
  sortDirection: 'descending',
  sortColumn: '',
};

/**
 * @deprecated Use U21Table instead
 */
class PaginationTable extends React.Component<AllProps, AllState> {
  static defaultProps = {
    addDefaultTagConfigColumn: true,
    disableFooter: false,
    disableFooterReload: false,
    fullPageTable: false,
    useLoadingWrapper: false,
    printView: false,
    valueField: ValueField.ID,
  };

  constructor(props: AllProps) {
    super(props);

    const { limit } = this.props;
    this.state = {
      ...initialState,
      limit: limit || DEFAULT_PAGINATION_LIMIT,
    };

    autoBindReact(this);
  }

  componentDidMount() {
    this.retrieveDataWithState();
  }

  handleLimitChange(e, { value }) {
    this.setState(
      { limit: value, offset: initialState.offset },
      this.retrieveDataWithState,
    );
  }

  handleOffsetToggle(buttonPressed: 'increment' | 'decrement') {
    const { offset } = this.state;
    let newOffset = offset;
    if (buttonPressed === 'increment') {
      newOffset += 1;
    } else {
      newOffset -= 1;
    }
    this.setState(
      { offset: newOffset, prevOffset: newOffset },
      this.retrieveDataWithState,
    );
  }

  handleReload() {
    this.setState({ ...initialState }, this.retrieveDataWithState);
  }

  handleSort(columnKey: string) {
    const { sortDirection, sortColumn, limit } = this.state;
    let determinedSortDirection = sortDirection;

    if (columnKey === sortColumn) {
      determinedSortDirection =
        sortDirection === 'ascending' ? 'descending' : 'ascending';
    } else {
      // to account for columns that do not match && if the previous state of sort direction is ascending
      determinedSortDirection = 'descending';
    }
    this.setState(
      {
        sortDirection: determinedSortDirection,
        sortColumn: columnKey,
        offset: DEFAULT_OFFSET,
        prevOffset: DEFAULT_OFFSET,
        limit,
      },
      this.retrieveDataWithState,
    );
  }

  handleRowClick(e: MouseEvent, row: any) {
    const { onRowClick, isRowClickable } = this.props;

    if (isRowClickable && !isRowClickable(row)) {
      return;
    }

    if (onRowClick) {
      onRowClick(e, row);
    }
  }

  handleCheckboxClick(rowId: RowId) {
    const { selectableCheckboxProps } = this.props;
    // used for typing, this should never happen as the checkbox won't render unless the props exist
    if (!selectableCheckboxProps) return;
    const newSelectedRows = [...selectableCheckboxProps.selected];
    const idxOfRow = selectableCheckboxProps.selected.indexOf(rowId);
    // row is selected, remove selected row
    if (idxOfRow >= 0) {
      newSelectedRows.splice(idxOfRow, 1);
      // row is not selected, add selected row
    } else {
      newSelectedRows.push(rowId);
    }

    selectableCheckboxProps.handleSelected(newSelectedRows);
  }

  handleSelectAllCheckbox() {
    const { selectableCheckboxProps, valueField, rows } = this.props;
    // used for typing, this should never happen as the checkbox won't render unless the props exist
    if (!selectableCheckboxProps) return;
    let allChecked;
    if (valueField === ValueField.ID) {
      allChecked = rows.every(
        (row) =>
          selectableCheckboxProps.selected.includes(row.id) ||
          selectableCheckboxProps.disabled?.includes(row.id),
      );
    } else {
      allChecked = rows.every(
        (row) =>
          selectableCheckboxProps.selected.includes(row.external_id) ||
          selectableCheckboxProps.disabled?.includes(row.external_id),
      );
    }
    const newSelectedRows = allChecked
      ? this.deselectAllDisplayedRows()
      : this.selectAllDisplayedRows();

    selectableCheckboxProps.handleSelected(newSelectedRows);
  }

  handlePaginationOffsetChange = (event: SyntheticEvent, value) => {
    // Not updating prevOffset here because we'll compare offset
    // to prevOffset in setPaginationOffsetSave()
    this.setState({ offset: value });
  };

  setPaginationOffsetSave() {
    const { offset, prevOffset, sortColumn, sortDirection, limit } = this.state;
    const { totalCount, update, config } = this.props;

    const totalPages =
      totalCount !== null ? Math.ceil(totalCount / limit) : null;
    let changedOffset: number = DEFAULT_OFFSET;

    // Check if is valid number
    if (!isNaN(offset)) {
      const offsetNum = Number(offset);
      if (totalPages) {
        if (offsetNum >= totalPages) {
          changedOffset = totalPages;
        } else if (offsetNum > 0 && offsetNum < totalPages) {
          changedOffset = offsetNum;
        } else {
          changedOffset = prevOffset;
        }
      }
    } else {
      changedOffset = prevOffset;
    }

    this.setState({
      offset: changedOffset,
      prevOffset: changedOffset,
    });

    const payload: PaginationPayload = {
      sort_column: sortColumn,
      sort_direction: sortDirection,
      offset: changedOffset,
      limit,
    };

    // Only make API request if there is an actual page change
    if (changedOffset !== prevOffset) {
      update(payload, config);
    }
  }

  selectAllDisplayedRows(): RowId[] {
    // if selected is greater than the current number of rows, means we are in pagination or the rows are filtered, i.e. with a FE search bar
    // toggle should then select all currently displayed if not all are selected
    const { selectableCheckboxProps, valueField, rows } = this.props;
    const selectedRows = [
      ...(selectableCheckboxProps?.selected || []),
    ] as RowId[];
    rows.forEach((row) => {
      if (
        !(
          (selectableCheckboxProps && selectableCheckboxProps.disabled) ||
          []
        ).includes(valueField === ValueField.ID ? row.id : row.external_id)
      ) {
        selectedRows.push(
          valueField === ValueField.ID ? row.id : row.external_id,
        );
      }
    });
    return selectedRows;
  }

  retrieveDataWithState() {
    const { sortColumn, sortDirection, offset, limit } = this.state;
    const { update, config } = this.props;
    const payload: PaginationPayload = {
      offset,
      limit,
    };
    // only add sort column and sort direction if something is being sorted
    if (sortColumn.length > 0) {
      payload.sort_column = sortColumn;
      payload.sort_direction = sortDirection;
    }
    update(payload, config);
  }

  deselectAllDisplayedRows(): RowId[] {
    // if selected is greater than the current number of rows, means we are in pagination or the rows are filtered, i.e. with a FE search bar
    // if all currently displayed rows are selected, then toggle all should uncheck all currently displayed rows but keep the other rows
    const { selectableCheckboxProps, valueField, rows } = this.props;
    if (!selectableCheckboxProps) {
      return [];
    }
    return selectableCheckboxProps.selected.filter((selectedId) => {
      // deselect if on the current page AND not disabled
      const onCurrentPage = rows.some((row) =>
        valueField === ValueField.ID
          ? row.id === selectedId
          : row.external_id === selectedId,
      );
      const isDisabled = selectableCheckboxProps?.disabled?.some(
        (disabledId) => disabledId === selectedId,
      );

      return onCurrentPage && isDisabled;
    });
  }

  renderDeleteHeader() {
    const { deleteOnClick } = this.props;
    if (!deleteOnClick) {
      return null;
    }
    return <Table.HeaderCell collapsing />;
  }

  renderDeleteCell(rowId: RowId) {
    const { deleteOnClick } = this.props;
    if (!deleteOnClick) {
      return null;
    }
    return (
      <Table.Cell
        collapsing
        onClick={() => deleteOnClick(rowId)}
        className={styles.deleteCell}
      >
        <Icon name={assets.icons.trash} />
      </Table.Cell>
    );
  }

  renderCheckboxCell(rowId: RowId) {
    const { selectableCheckboxProps } = this.props;

    if (selectableCheckboxProps) {
      const handleClick = () => this.handleCheckboxClick(rowId);
      const isDisabled = (selectableCheckboxProps.disabled || []).includes(
        rowId,
      );
      return (
        <Table.Cell
          className={styles.checkboxCell}
          onClick={handleClick}
          disabled={isDisabled}
        >
          <div className={styles.checkboxContainer}>
            <Checkbox
              checked={selectableCheckboxProps.selected.includes(rowId)}
              disabled={isDisabled}
            />
          </div>
        </Table.Cell>
      );
    }
    return null;
  }

  renderDefaultTags(row) {
    const { getDefaultTagConfigNames } = this.props;
    const tags = row.tags || [];
    const tagNames = getDefaultTagConfigNames(tags);

    return (
      <Table.Cell>
        <div>
          {tagNames.map((name, idx) => {
            return (
              <Label
                className={styles.defaultTags}
                key={String(name) + String(idx)}
              >
                {name}
              </Label>
            );
          })}
        </div>
      </Table.Cell>
    );
  }

  renderDefaultTagsHeader() {
    const {
      defaultTagConfig,
      defaultTagConfigExists,
      addDefaultTagConfigColumn,
    } = this.props;

    if (addDefaultTagConfigColumn && defaultTagConfigExists) {
      return <Table.HeaderCell>{defaultTagConfig.name}</Table.HeaderCell>;
    }
    return null;
  }

  renderLoadingTable() {
    const { config, classNameProps } = this.props;
    // TODO update config length based on if checkbox and default tag column is added
    return (
      <div className={`${styles.tableContainer} ${classNameProps}`}>
        <Table>
          <Table.Body>
            <LoadingTable numRows={LOADING_LIMIT} numCells={config.length} />
          </Table.Body>
        </Table>
      </div>
    );
  }

  renderConfigTableHeaderCells() {
    const { sortColumn, sortDirection } = this.state;
    const { handleSort } = this;
    const { config, dataType } = this.props;

    return config
      .filter((col) => !col.hidden)
      .map((columnConfig) => {
        const isColumnSortable =
          handleSort !== undefined && columnConfig.sortable !== false;
        const sortKey = columnConfig.key;
        const columnWidth = columnConfig.width;
        const columnCollapse = columnConfig.collapsing;
        const columnLabel = columnConfig.label;
        return (
          <Table.HeaderCell
            key={`${dataType || 'table'}-list-header-${sortKey}`}
            sorted={sortColumn === sortKey ? sortDirection : undefined}
            collapsing={columnCollapse}
            // @ts-ignore
            width={columnWidth || undefined}
            onClick={isColumnSortable ? () => handleSort(sortKey) : emptyFn}
            className={isColumnSortable ? styles.clickable : ''}
          >
            {columnLabel}
            {isColumnSortable &&
              sortColumn === sortKey &&
              ((sortDirection === 'ascending' && (
                <Icon name={assets.icons.caretUp} />
              )) ||
                (sortDirection === 'descending' && (
                  <Icon name={assets.icons.caretDown} />
                )))}
          </Table.HeaderCell>
        );
      });
  }

  renderCellValue(config, row) {
    const { renderCellValue } = this.props;

    if (renderCellValue) {
      return renderCellValue(config, row);
    }

    if (
      config.dataSettingId &&
      config.Cell &&
      config.key?.includes('custom_data')
    ) {
      return config.Cell({ row });
    }

    if (config && !config.dataSettingId && config.accessor) {
      return config.accessor(row);
    }

    return formatTableValue({
      value: getValueFromEntity(row, config.key),
      valueType: config.type || undefined,
      fmtstr: config.fmtstr || undefined,
      useRandomColor: config.useRandomStyle || undefined,
      useGenerateColor: config.key === 'action_type' || undefined,
      row,
      isGoto: true,
    });
  }

  renderEndOfRowIcon(row) {
    const { endOfRowIcon } = this.props;
    if (!endOfRowIcon) {
      return null;
    }

    // Make sure the conditional returned object is not null or undefined
    if (typeof endOfRowIcon === 'function' && !endOfRowIcon(row)) {
      return null;
    }

    const {
      icon: TablerIcon,
      onClick,
      tooltip,
    } = typeof endOfRowIcon === 'function'
      ? (endOfRowIcon(row) as EndOfRowIcon)
      : endOfRowIcon;
    return (
      <Table.Cell collapsing className={styles.endOfRowIcon}>
        <ReactTooltip id={String(row.id)} />
        <TablerIcon
          data-for={String(row.id)}
          data-tip={tooltip || 'View Full Details'}
          onClick={(e) => onClick(e, row)}
        />
      </Table.Cell>
    );
  }

  renderRowMenu(row) {
    const { rowMenuItems } = this.props;
    return (
      <Table.Cell collapsing className={styles.rowMenuStyle}>
        <Dropdown upward icon={assets.icons.ellipsisVertical}>
          <Dropdown.Menu className={styles.dropdownMenu}>
            {rowMenuItems &&
              rowMenuItems.map(
                ({ text, key, onClick, shouldDisable }: RowMenuItem) => (
                  <Dropdown.Item
                    text={text}
                    key={key}
                    onClick={() => onClick(row)}
                    disabled={shouldDisable(row)}
                  />
                ),
              )}
          </Dropdown.Menu>
        </Dropdown>
      </Table.Cell>
    );
  }

  renderNoResults() {
    const {
      classNameProps,
      basic,
      tableProps,
      dataType,
      dataTypeIcon,
      config,
      selectableCheckboxProps,
      addDefaultTagConfigColumn,
      defaultTagConfigExists,
      rowMenuItems,
      printView,
    } = this.props;
    const noResultsComponentTableCols =
      config.length +
      (selectableCheckboxProps ? 1 : 0) +
      (addDefaultTagConfigColumn && defaultTagConfigExists ? 1 : 0) +
      (rowMenuItems ? 1 : 0);

    return (
      <div
        className={
          printView
            ? `${styles.printContainer} ${classNameProps}`
            : `${styles.tableContainer} ${classNameProps}`
        }
      >
        <Table basic={basic} {...(tableProps || {})}>
          <Table.Body>
            <NoResultsComponent
              title={`No ${dataType || 'data'} found`}
              icon={dataTypeIcon || null}
              tableCols={noResultsComponentTableCols}
            />
          </Table.Body>
        </Table>
      </div>
    );
  }

  renderCheckboxHeader() {
    const { selectableCheckboxProps, valueField, rows } = this.props;
    if (selectableCheckboxProps) {
      const allChecked = rows.every((row) =>
        selectableCheckboxProps.selected.includes(
          valueField === ValueField.ID ? row.id : row.external_id,
        ),
      );

      return (
        <Table.HeaderCell width={1}>
          <div className={styles.checkboxContainer}>
            <Checkbox
              checked={allChecked}
              onClick={this.handleSelectAllCheckbox}
            />
          </div>
        </Table.HeaderCell>
      );
    }
    return null;
  }

  renderCell(columnConfig, row, idx) {
    const onClick = (e) => this.handleRowClick(e, row);
    const cellValue = this.renderCellValue(columnConfig, row);
    return (
      <Table.Cell
        collapsing={columnConfig.collapsing}
        textAlign={columnConfig.centered ? 'center' : 'left'}
        onClick={onClick}
        key={`${row.id}-${idx}-${columnConfig.key}`}
      >
        {cellValue}
      </Table.Cell>
    );
  }

  renderTable() {
    const {
      basic,
      rows,
      config,
      loading,
      tableProps,
      totalCount,
      isRowClickable,
      onRowClick,
      rowMenuItems,
      endOfRowIcon,
      classNameProps,
      defaultTagConfigExists,
      addDefaultTagConfigColumn,
      selectedRowId,
      disableFooter,
      disableFooterReload,
      fullPageTable,
      tableConfigType,
      toggleTableConfigSidebar,
      extendedRowCountOptions,
      valueField,
    } = this.props;

    const { handleOffsetToggle } = this;
    const { offset, limit } = this.state;

    const totalPages =
      totalCount !== null ? Math.ceil(totalCount / limit) : null;

    // if we have total pages, standard check that we at the end (totalPages = offset)
    // if we don't have totalPages (caused by no totalCount), check to see if the rows are less than the limit, indicating we are on the final page
    const pageIncreaseDisabled =
      (totalPages && totalPages === offset) ||
      (totalCount !== null && totalCount < limit);

    // loading UI if loading
    if (loading) {
      return this.renderLoadingTable();
    }

    // no results UI if no results
    if (totalCount !== null && totalCount <= 0) {
      return this.renderNoResults();
    }

    // if calculated totalPages < offset, set to 1
    const offsetValue = totalPages != null && totalPages < offset ? 1 : offset;

    return (
      <>
        <div
          className={`${fullPageTable ? styles.maxHeightTable : ''} ${
            styles.tableContainer
          } ${classNameProps}`}
        >
          <Table basic={basic} {...(tableProps || {})}>
            <Table.Header>
              <Table.Row>
                {rowMenuItems && <Table.HeaderCell />}
                {this.renderCheckboxHeader()}
                {this.renderConfigTableHeaderCells()}
                {this.renderDefaultTagsHeader()}
                {this.renderDeleteHeader()}
                {endOfRowIcon && <Table.HeaderCell />}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {rows.map((row, idx) => {
                let rowId: RowId;
                if (valueField === ValueField.ID) {
                  rowId = row.id;
                } else {
                  rowId = row.external_id;
                }
                // to highlight a selected row
                const isHighlighted =
                  Boolean(selectedRowId === rowId) && rowId !== undefined;
                let className = '';
                if (isRowClickable) {
                  if (isRowClickable(row)) {
                    className = styles.clickable;
                  }
                } else if (onRowClick) {
                  className = styles.clickable;
                }
                if (isHighlighted) {
                  className += ` ${styles.highlightedRow}`;
                }

                return (
                  <Table.Row
                    key={rowId}
                    className={className}
                    selected={isHighlighted}
                  >
                    {rowMenuItems && this.renderRowMenu(row)}
                    {this.renderCheckboxCell(rowId)}
                    {config
                      .filter((col) => !col.hidden)
                      .map((columnConfig) =>
                        this.renderCell(columnConfig, row, idx),
                      )}
                    {addDefaultTagConfigColumn &&
                      defaultTagConfigExists &&
                      this.renderDefaultTags(row)}
                    {this.renderDeleteCell(rowId)}
                    {this.renderEndOfRowIcon(row)}
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>

        {!disableFooter && (
          <div className={styles.bottomRowContainer}>
            <div className={styles.resultCountText}>
              {totalCount !== null &&
                `${totalCount} ${totalCount > 1 ? 'results' : 'result'}`}
            </div>

            <div className={styles.pageSizeSelectorContainer}>
              <GenericButton
                className={styles.paginationArrowButton}
                disabled={offset === DEFAULT_OFFSET}
                onClick={() => handleOffsetToggle('decrement')}
                icon={
                  <Icon
                    name={assets.icons.angleLeft}
                    size="large"
                    color="grey"
                  />
                }
              />
              <p className={styles.paginationMenuText}>Page</p>
              <ResizableInput
                className={styles.paginationOffsetInput}
                value={offsetValue}
                onChange={(e, value) =>
                  this.handlePaginationOffsetChange(e, value)
                }
                onBlur={this.setPaginationOffsetSave}
              />
              {totalPages && (
                <p className={styles.paginationPageCount}>{totalPages}</p>
              )}

              <GenericButton
                className={styles.paginationArrowButton}
                disabled={pageIncreaseDisabled}
                onClick={() => handleOffsetToggle('increment')}
                icon={
                  <Icon
                    name={assets.icons.angleRight}
                    size="large"
                    color="grey"
                  />
                }
              />
            </div>
            <div className={styles.paginationMenuItem}>
              <Dropdown
                selection
                options={
                  extendedRowCountOptions
                    ? [...PAGE_LIMIT_OPTIONS, ...EXTENDED_PAGE_LIMIT_OPTIONS]
                    : PAGE_LIMIT_OPTIONS
                }
                placeholder="Limit Rows"
                className={styles.paginationDropdown}
                onChange={this.handleLimitChange}
                value={limit}
                selectOnBlur={false}
                icon={
                  <Icon
                    name={assets.icons.caretDown}
                    className={styles.paginationDropdownIcon}
                  />
                }
              />
              {tableConfigType && (
                <Icon
                  onClick={() => {
                    // Analytics
                    heapTrack(
                      heapEvents.tableConfigs
                        .paginationTableClickedTableSettings,
                      {
                        type: tableConfigType,
                      },
                    );
                    toggleTableConfigSidebar({
                      tableConfigType,
                    });
                  }}
                  name={assets.icons.settings}
                  className={styles.paginationFooterIcon}
                  data-for="tableSettingsToolTip"
                  data-tip="markTooltip"
                />
              )}
              {tableConfigType && (
                <ReactTooltip
                  id="tableSettingsToolTip"
                  place="top"
                  effect="solid"
                  getContent={() => 'Table Settings'}
                />
              )}
              {!disableFooterReload && (
                <Icon
                  onClick={this.handleReload}
                  name={assets.icons.redoAlternate}
                  className={styles.paginationFooterIcon}
                  data-for="redoToolTip"
                  data-tip="markTooltip"
                />
              )}
              <ReactTooltip
                id="redoToolTip"
                place="top"
                effect="solid"
                getContent={() => 'Reload'}
              />
            </div>
          </div>
        )}
      </>
    );
  }

  render() {
    const { useLoadingWrapper, loading } = this.props;

    if (!useLoadingWrapper) {
      return this.renderTable();
    }

    return (
      <LoadingWrapper
        firstLoadOnly
        loading={loading}
        styleProps={{ height: '300px' }}
      >
        {this.renderTable()}
      </LoadingWrapper>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaginationTable);
