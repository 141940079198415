import React, { Component } from 'react';

// Redux
import { connect } from 'react-redux';

// Models
import {
  AlertDetails,
  ChangeAlertStatusPayload,
  EntityResolutionTypes,
  RetrieveAlertEntitiesPayload,
} from 'app/modules/alerts/models';
import { TableConfig } from 'app/shared/pagination/models';
import { RuleEntitiesWhitelistPayload } from 'app/modules/rules/models';
import { EntitySummary, EntityType } from 'app/modules/entities/models';
import { PaginationPayload } from 'app/shared/models';

// Components
import { Segment, Label, Icon } from 'semantic-ui-react';
import AlertComponentResolutionButtons from 'app/modules/alerts/components/AlertComponentResolutionButtons';
import EntityTable from 'app/modules/entities/components/EntityTable';
import LoadingWrapper from 'app/shared/components/LoadingWrapper';

// Actions
import {
  changeAlertComponentStatus as changeAlertComponentStatusAction,
  retrieveAlertEntities as retrieveAlertEntitiesAction,
} from 'app/modules/alerts/actions';
import {
  whitelistEntitiesForRule as whitelistEntitiesForRuleAction,
  retrieveRuleValidationAlertEntities as retrieveRuleValidationAlertEntitiesAction,
} from 'app/modules/rules/actions';

// Selectors
import {
  selectAlertComponentStatusLoading,
  selectAlertEntitiesLoading,
  selectAlertEntities,
} from 'app/modules/alerts/selectors';
import { selectRetrieveValidationRuleAlertEntitiesLoading } from 'app/modules/rules/selectors';
import { selectEntityTableConfig } from 'app/shared/CustomConfig/selectors';
import { selectAlertComponentActionTriggers } from 'app/modules/session/selectors';

// Styles
import styles from 'app/modules/alerts/styles/workspace.module.scss';

// Helpers
import {
  formatTableValue,
  getValueFromEntity,
} from 'app/modules/entities/helpers';

// Constants
import { DEFAULT_OFFSET } from 'app/shared/pagination/constants';
import { ENTITIES_PAGE_LIMIT } from 'app/modules/entities/constants';
import { INITIAL_ALERT } from 'app/modules/alerts/constants';
import { ValueField } from 'app/modules/search/helpers';

const RESULT_CONFIG: TableConfig = {
  key: 'result',
  label: 'Result',
  centered: true,
};
const NUM_ALERTS_CONFIG: TableConfig = {
  key: 'num_alerts',
  label: 'Total Alerts',
  sortable: false,
  type: 'number',
};
const NUM_OPEN_ALERTS_CONFIG: TableConfig = {
  key: 'num_open_alerts',
  label: 'Open Alerts',
  sortable: false,
  type: 'number',
};

const LIVE_ALERT_ADDED_TABLE_CONFIG: TableConfig[] = [
  RESULT_CONFIG,
  NUM_ALERTS_CONFIG,
  NUM_OPEN_ALERTS_CONFIG,
];

const DEFAULT_PAGINATED_PAYLOAD: PaginationPayload = {
  offset: DEFAULT_OFFSET,
  limit: ENTITIES_PAGE_LIMIT,
};

interface AllState {
  selectedEntityExternalIds: string[];
  tablePayload: PaginationPayload;
}

interface OwnProps {
  alert: AlertDetails;
  validationOnly?: boolean;

  // optional
  onRowClick?: (e: MouseEvent, row: any) => void;
  entityType?: EntityType;
  // to highlight a selected row
  selectedRowId?: number | string;

  // to add gotoEntity on the dropdown
  addGotoEntityInDropdown?: boolean;
  whitelistDisabled?: boolean;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  alertComponentStatusLoading: selectAlertComponentStatusLoading(state),
  alertEntitiesLoading: selectAlertEntitiesLoading(state),
  entityTableConfig: selectEntityTableConfig(state),
  alertEntities: selectAlertEntities(state),
  retrieveValidationRuleAlertEntitiesLoading:
    selectRetrieveValidationRuleAlertEntitiesLoading(
      state,
      String(ownProps.alert.id),
    ),
  actionTriggers: selectAlertComponentActionTriggers(state),
});

const mapDispatchToProps = {
  changeAlertComponentStatus: changeAlertComponentStatusAction,
  whitelistEntitiesForRule: whitelistEntitiesForRuleAction,
  retrieveAlertEntities: retrieveAlertEntitiesAction,
  retrieveRuleValidationAlertEntities:
    retrieveRuleValidationAlertEntitiesAction,
};

type AllProps = OwnProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

/* Take an entity summary with resolution and
 * return teh appropriate Label
 */
const renderEntityResult = (
  entity: EntitySummary & { resolution?: string },
) => {
  const resolution = entity?.resolution;

  switch (resolution) {
    case 'FALSE_POSITIVE':
      return <Label color="red">FALSE POSITIVE</Label>;
    case 'TRUE_POSITIVE':
      return <Label color="green">TRUE POSITIVE</Label>;
    case 'UNRESOLVED':
      return <Icon name="question" color="grey" />;
    default:
      return <Label color="grey">{resolution}</Label>;
  }
};

const renderCellValue = (
  config: TableConfig,
  entity: EntitySummary & { resolution: string },
) => {
  if (config.key === 'result') {
    return renderEntityResult(entity);
  }

  return formatTableValue({
    value: getValueFromEntity(entity, config.key),
    valueType: config.type || undefined,
    fmtstr: config.fmtstr || undefined,
  });
};

class AssociatedEntities extends Component<AllProps, AllState> {
  static defaultProps = {
    validationOnly: false,
  };

  constructor(props: AllProps) {
    super(props);
    this.state = {
      selectedEntityExternalIds: [],
      tablePayload: { ...DEFAULT_PAGINATED_PAYLOAD },
    };

    this.retrieveEntities = this.retrieveEntities.bind(this);
  }

  componentDidMount() {
    this.retrieveEntities(DEFAULT_PAGINATED_PAYLOAD);
  }

  handleCheckAllEntities = (selectedEntityExternalIds) => {
    this.setState({ selectedEntityExternalIds });
  };

  handleActionTriggerButton = (id: number) => {
    const { alert, changeAlertComponentStatus, entityType } = this.props;
    const { selectedEntityExternalIds, tablePayload } = this.state;

    const payload: ChangeAlertStatusPayload = {
      alertId: alert.id,
      entities: selectedEntityExternalIds,
      action_trigger_id: id,
    };
    const alertEntityPayload: RetrieveAlertEntitiesPayload = {
      ...tablePayload,
      alertId: String(alert.id),
    };
    if (entityType) {
      alertEntityPayload.entity_type = entityType;
    }
    changeAlertComponentStatus(payload, { entityUpdate: alertEntityPayload });

    // clear selected ID's
    this.setState({ selectedEntityExternalIds: [] });
  };

  handleWhitelistEntity = () => {
    this.handleEntityButton('WHITELIST_ENTITY');
  };

  handleEntityButton = (status: EntityResolutionTypes) => {
    const { alert, changeAlertComponentStatus, whitelistEntitiesForRule } =
      this.props;
    const { selectedEntityExternalIds } = this.state;
    if (selectedEntityExternalIds.length > 0) {
      if (status === 'WHITELIST_ENTITY') {
        const payload: RuleEntitiesWhitelistPayload = {
          rule_id: alert?.rules[0].id || -1,
          entity_external_ids: selectedEntityExternalIds,
          alert_id: alert.id,
        };
        whitelistEntitiesForRule(payload);
      } else {
        const payload: ChangeAlertStatusPayload = {
          alertId: alert.id,
          entities: selectedEntityExternalIds,
          status,
        };
        changeAlertComponentStatus(payload);
      }
      // clear selected ID's
      this.setState({ selectedEntityExternalIds: [] });
    }
  };

  getEntitiesCount(alert: AlertDetails): number {
    const { entityType } = this.props;
    switch (entityType) {
      case 'USER':
        return alert.num_user_entities;
      case 'BUSINESS':
        return alert.num_business_entities;
      default:
        return alert.num_entities || 0;
    }
  }

  formatConfig() {
    const { validationOnly, entityTableConfig } = this.props;
    return validationOnly
      ? entityTableConfig
      : [...entityTableConfig, ...LIVE_ALERT_ADDED_TABLE_CONFIG];
  }

  retrieveEntities(payload: PaginationPayload) {
    const {
      alert,
      retrieveAlertEntities,
      validationOnly,
      retrieveRuleValidationAlertEntities,
      entityType,
    } = this.props;

    //
    // avoid retrieving data when alert has not been loaded
    if (alert.id === INITIAL_ALERT.id) {
      return;
    }

    // TODO refactor this if we add any more actions
    // retrieve validation alert txns if in validation only mode
    if (validationOnly) {
      const formattedValidationPayload = {
        id: alert.id,
        offset: payload.offset,
        limit: payload.limit,
        sort_column: payload.sort_column,
        sort_direction: payload.sort_direction,
      };
      retrieveRuleValidationAlertEntities(formattedValidationPayload); // TODO
      return;
    }

    const newPayload: RetrieveAlertEntitiesPayload = {
      ...payload,
      alertId: String(alert.id),
    };
    if (entityType) {
      newPayload.entity_type = entityType;
    }
    retrieveAlertEntities(newPayload);
    this.setState((prevState) => {
      return { ...prevState, tablePayload: payload };
    });
  }

  renderEntityTable() {
    const {
      actionTriggers,
      validationOnly,
      whitelistDisabled,
      alert,
      retrieveValidationRuleAlertEntitiesLoading,
      alertComponentStatusLoading,
      alertEntitiesLoading,
      onRowClick,
      selectedRowId,
      addGotoEntityInDropdown,
      alertEntities,
    } = this.props;
    const { selectedEntityExternalIds } = this.state;
    const entities = alertEntities;
    const entitiesCount = this.getEntitiesCount(alert);
    const hideCheckboxSelection =
      validationOnly || (actionTriggers.length <= 0 && whitelistDisabled);
    // hide selectable checkbox props if validation only
    // TODO refactor this to be a boolean taken in by Pagination table to avoid recreating a new object on every render
    const optionalProps = hideCheckboxSelection
      ? {}
      : {
          selectableCheckboxProps: {
            selected: selectedEntityExternalIds,
            handleSelected: this.handleCheckAllEntities,
          },
        };
    const loading = validationOnly
      ? retrieveValidationRuleAlertEntitiesLoading
      : Boolean(alertComponentStatusLoading || alertEntitiesLoading);

    return (
      <Segment
        className={`${styles.zeroPadding} ${styles.verticalScroll} ${styles.maxHeight}`}
      >
        {/* We need to provide a truthy value to `onRowClick` to make it "do nothing" */}
        <EntityTable
          loading={loading}
          onRowClick={onRowClick}
          entities={entities}
          totalCount={entitiesCount}
          update={this.retrieveEntities}
          ownPropsConfig={this.formatConfig()}
          renderCellValue={renderCellValue}
          selectedRowId={selectedRowId}
          addGotoEntityInDropdown={addGotoEntityInDropdown}
          valueField={ValueField.EXTERNAL_ID}
          {...optionalProps}
        />
      </Segment>
    );
  }

  render() {
    const {
      alert,
      validationOnly,
      alertEntitiesLoading,
      retrieveValidationRuleAlertEntitiesLoading,
      whitelistDisabled,
    } = this.props;
    const { selectedEntityExternalIds } = this.state;
    const loading = validationOnly
      ? retrieveValidationRuleAlertEntitiesLoading
      : alertEntitiesLoading;
    const entities = alert?.entities || [];

    if (entities.length <= 0 && !loading) {
      return null;
    }

    return (
      <div className={styles.entityTableContainer}>
        <LoadingWrapper
          loading={loading}
          firstLoadOnly
          styleProps={{ height: 300 }}
        >
          <Segment.Group className={styles.analysisSegmentGroup}>
            <Segment color="teal" textAlign="center">
              <span>Flagged Entities</span>
            </Segment>
            {this.renderEntityTable()}
            {!validationOnly && (
              <AlertComponentResolutionButtons
                handleWhitelistEntity={
                  whitelistDisabled ? undefined : this.handleWhitelistEntity
                }
                handleActionTriggerButton={this.handleActionTriggerButton}
                disabled={selectedEntityExternalIds.length === 0}
                tagIds={alert.tags?.map((tag) => tag.id)}
              />
            )}
          </Segment.Group>
        </LoadingWrapper>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssociatedEntities);
