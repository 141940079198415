import React, { Component, ReactElement } from 'react';
import autoBindReact from 'auto-bind/react';

// Redux
import { connect } from 'react-redux';

// Components
import { Note } from 'app/modules/notes/components/Note';
import Editor from 'app/modules/notes/components/Editor';
import NoteContainer from 'app/modules/notes/components/NoteContainer';

// Actions
import {
  addNote,
  updateNote,
  deleteNote,
  retrieveNotes,
  toggleEditMode,
  toggleDeleteMode,
} from 'app/modules/notes/actions';

// Selectors
import { selectNotes, selectNotesLoading } from 'app/modules/notes/selectors';
import { selectAgent } from 'app/modules/session/selectors';
import { selectImmutableNotesEnabled } from 'app/shared/featureFlags/selectors';

// Style
import styles from 'app/modules/notes/styles/NoteSection.module.scss';

// Models
import { EditorState, RawDraftContentState } from 'draft-js';
import { U21Loading } from 'app/shared/u21-ui/components';
import { NoteImmutable } from 'app/modules/notes/components/NoteImmutable';
import { NoteModel } from 'app/modules/notes/models';

interface OwnProps {
  articleType?: ArticleType;
  articleId: number;
  disabled?: boolean;
  disableFromNowTimeFormat?: boolean;
  forceImmutable?: boolean;
}

type AllProps = OwnProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const mapStateToProps = (state: RootState) => ({
  agent: selectAgent(state),
  notes: selectNotes(state),
  notesLoading: selectNotesLoading(state),
  immutableNotesEnabled: selectImmutableNotesEnabled(state),
});

const mapDispatchToProps = {
  retrieveNotes,
  addNote,
  updateNote,
  deleteNote,
  toggleEditMode,
  toggleDeleteMode,
};

class NoteSection extends Component<AllProps> {
  constructor(props: AllProps) {
    super(props);

    autoBindReact(this);
  }

  componentDidMount() {
    const { articleId, retrieveNotes: retrieveNotesProps } = this.props;
    if (articleId > 0) {
      retrieveNotesProps(articleId);
    }
  }

  componentDidUpdate(prevProps) {
    const { articleId, retrieveNotes: retrieveNotesProps } = this.props;
    if (articleId > 0 && articleId !== prevProps.articleId) {
      retrieveNotesProps(articleId);
    }
  }

  addNote(content: RawDraftContentState) {
    const { articleId, addNote: addNoteProps } = this.props;
    addNoteProps(articleId, content);
  }

  updateNote(id: number, content: RawDraftContentState) {
    const { updateNote: updateNoteProps } = this.props;
    updateNoteProps(id, content);
  }

  /* @ Handle rendering the mutable or mutable version
   * @ of the Notes component based on the comprehensive
   * @ audit trail feature flag
   */
  renderNote = (note: NoteModel): ReactElement => {
    const {
      disableFromNowTimeFormat,
      agent,
      disabled,
      toggleEditMode: toggleEditModeProps,
      toggleDeleteMode: toggleDeleteModeProps,
      deleteNote: deleteNoteProps,
      immutableNotesEnabled,
      forceImmutable,
    } = this.props;

    if (!immutableNotesEnabled && !forceImmutable && !note.disposition) {
      return (
        <Note
          key={note.id}
          note={note}
          disableFromNowTimeFormat={disableFromNowTimeFormat}
          toggleEditMode={
            agent.id === note.author.id && !disabled
              ? toggleEditModeProps
              : undefined
          }
          toggleDeleteMode={
            agent.id === note.author.id && !disabled
              ? toggleDeleteModeProps
              : undefined
          }
          deleteNote={deleteNoteProps}
          updateNote={this.updateNote}
        />
      );
    }

    return (
      <NoteImmutable
        key={note.id}
        note={note}
        disableFromNowTimeFormat={disableFromNowTimeFormat}
      />
    );
  };

  render() {
    const { articleId, notes, notesLoading, disabled, articleType } =
      this.props;

    return (
      <div className={styles.notesDiv}>
        {notesLoading ? <U21Loading loading /> : notes?.map(this.renderNote)}

        {disabled && articleType && !notes.length && (
          <p>No notes associated with this {articleType.toLowerCase()}.</p>
        )}
        {!disabled && (
          <NoteContainer>
            <Editor
              initialState={EditorState.createEmpty()}
              addNote={this.addNote}
              noteId={articleId}
              saveToLocalStorage
            />
          </NoteContainer>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NoteSection);
