import { createSelector } from 'reselect';

// Models
import { TransactionsActionTypes } from 'app/modules/transactions/models';
import { FeatureFlag } from 'app/shared/featureFlags/models';

// Constants
import { initialActionEvent } from 'app/modules/transactions/constants';

// Selectors
import { selectHasReadAlertComponentButtonsPermissions } from 'app/modules/session/selectors';
import { selectFeatureFlags } from 'app/shared/featureFlags/selectors';

export const selectActionEvent = (state: RootState) => {
  return state.transactions.actionEvent || initialActionEvent;
};

// Loading

export const selectActionEventLoading = (state: RootState) =>
  Boolean(state.loading[TransactionsActionTypes.RETRIEVE_ACTION_EVENT]);

export const selectCanResolveTransactions = createSelector(
  selectHasReadAlertComponentButtonsPermissions,
  selectFeatureFlags,
  (hasReadAlertComponentButtonsPermissions, featureFlags) =>
    Boolean(
      hasReadAlertComponentButtonsPermissions &&
        featureFlags[FeatureFlag.ALERT_COMPONENT_BUTTONS],
    ),
);
