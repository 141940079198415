// Constants
import { FEATURES_TO_ROLL } from 'app/modules/alertScore/constants';

export const getChartHeight = (showFeatures: number) => {
  if (showFeatures <= FEATURES_TO_ROLL) {
    return showFeatures * 60;
  } else if (
    showFeatures > FEATURES_TO_ROLL &&
    showFeatures <= FEATURES_TO_ROLL * 2
  ) {
    return showFeatures * 42;
  }
  return 520;
};
