import { FC, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import styled, { css } from 'styled-components';

// Models
import { NotificationStatus } from 'app/modules/notifications/models';
import { NotificationResponse } from 'app/modules/notifications/types/responses';

// Constants
import { NOTIFICATION_ICON_MAPPING } from 'app/modules/notifications/constants';

// Selectors
import { selectAgent } from 'app/modules/session/selectors';

// utils
import { formatDistanceToNow } from 'date-fns';
import { handleRoutingOnEntityType } from 'app/modules/notifications/utils';

// thunk
import { readNotificationThunk } from 'app/modules/notifications/sliceNotifications';

// Components
import { IconClock } from '@u21/tabler-icons';
import { U21Spacer, U21Typography } from 'app/shared/u21-ui/components';

interface NotificationPopoverItemProps {
  notification: NotificationResponse;
  setClose: () => void;
}

export const NotificationPopoverItem: FC<NotificationPopoverItemProps> = ({
  notification,
  setClose,
}) => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const agent = useSelector(selectAgent);

  const handleOnClick = useCallback(() => {
    handleRoutingOnEntityType(
      notification.entity_type,
      notification.entity_id,
      push,
    );
    if (notification.status === 'UNREAD') {
      dispatch(
        readNotificationThunk({
          agent_id: agent.id,
          notification_id: notification.id,
        }),
      );
    }
    setClose();
  }, [dispatch, agent.id, notification, push, setClose]);

  return (
    <StyledListItem
      key={notification.id}
      onClick={handleOnClick}
      $status={notification.status}
    >
      <StyledHeader horizontal>
        <U21Spacer horizontal spacing={2}>
          <div>{NOTIFICATION_ICON_MAPPING[notification.entity_type]}</div>
          <U21Spacer>
            <U21Typography variant="subtitle1">
              {notification.content}
            </U21Typography>
            <U21Typography
              icon={<IconClock />}
              color="text.secondary"
              variant="body2"
            >
              {formatDistanceToNow(new Date(notification.created_at!))}
            </U21Typography>
          </U21Spacer>
        </U21Spacer>
      </StyledHeader>
    </StyledListItem>
  );
};

const StyledHeader = styled(U21Spacer)`
  justify-content: space-between;
  padding: 16px;
`;

const StyledListItem = styled.li<{ $status: `${NotificationStatus}` }>`
  cursor: pointer;
  border-radius: 8px;

  ${({ theme, $status }) => {
    const { palette } = theme;
    if ($status === NotificationStatus.UNREAD) {
      return css`
        background-color: ${palette.background.neutral};
      `;
    }
    return '';
  }}

  ${({ theme }) => {
    const { palette } = theme;
    return css`
      &:hover {
        background-color: ${palette.action.hover};
      }
    `;
  }}
`;
