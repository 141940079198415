import { GetActionEventsPayload } from 'app/modules/actionEvents/requests';
import { GetActionEventsResponse } from 'app/modules/actionEvents/responses';

import { ACTION_EVENT_QUERY_KEYS } from 'app/modules/actionEvents/queries/keys';

import { post } from 'app/shared/utils/fetchr';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import {
  selectHasReadEventsPermission,
  selectHasReadAlertsPermission,
} from 'app/modules/session/selectors';

export const useGetActionEvents = (
  payload: GetActionEventsPayload,
  {
    useCacheEndpoint = false,
    enabled: enabledProp = true,
  }: {
    useCacheEndpoint?: boolean;
    enabled?: boolean;
  } = {},
) => {
  const hasReadEventsPermission = useSelector(selectHasReadEventsPermission);
  const hasReadAlertsPermission = useSelector(selectHasReadAlertsPermission);
  const enabled = enabledProp && payload.limit > 0;
  const endpoint = useCacheEndpoint
    ? '/action-events/cached/list'
    : '/action-events/list';
  let hasPermissions = hasReadEventsPermission;

  if (useCacheEndpoint && payload.alert_id && payload.alert_id > 0) {
    hasPermissions = hasReadAlertsPermission;
  }

  return useQuery({
    queryFn: () => post<GetActionEventsResponse>(endpoint, payload),
    queryKey: ACTION_EVENT_QUERY_KEYS.getActionEvents(endpoint, payload),
    enabled: enabled && hasPermissions && payload.limit > 0,
  });
};
