import React, { Component, ReactNode } from 'react';

// Redux
import { connect } from 'react-redux';

// Components
import { Modal, Icon, Segment, Tab, Label } from 'semantic-ui-react';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import ContentHeader from 'app/shared/components/ContentHeader';
import DictionaryToTable from 'app/shared/auditTrail/components/DictionaryToTable';
import LoadingWrapper from 'app/shared/components/LoadingWrapper';
import GenericButton from 'app/shared/components/GenericButton';
import BeforeAfterTable from 'app/shared/auditTrail/components/BeforeAfterTable';

// Actions
import { retrieveAuditTrail as retrieveAuditTrailAction } from 'app/shared/auditTrail/actions';

// Selectors
import {
  selectAuditTrail,
  selectAuditTrailLoading,
} from 'app/shared/auditTrail/selectors';

// Styles
import styles from 'app/shared/auditTrail/styles/AuditTrail.module.scss';

// Helpers
import { renderAuditTrailIconStyle } from 'app/shared/helpers';
import { getLocalFormat } from 'app/shared/utils/timeHelpers';

// Utils
import emptyFn from 'app/shared/utils/empty-fn';
import assets from 'app/shared/utils/assets';
import { StyleConstants } from 'app/styles/StyleConstants';
import ActionTriggerDigestSummary from 'app/shared/auditTrail/components/ActionTriggerDigestSummary';
import { Unit21AuditTrailActionTypes } from 'app/shared/auditTrail/models';
import LinkedLabel from 'app/shared/components/LinkedLabel';

interface OwnProps {
  handleClose: () => void;
  id: string;
}

const mapStateToProps = (state: RootState) => {
  return {
    auditTrail: selectAuditTrail(state),
    auditTrailLoading: selectAuditTrailLoading(state),
  };
};

const mapDispatchToProps = {
  retrieveAuditTrail: retrieveAuditTrailAction,
};

type AllProps = OwnProps &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

class AuditTrailModal extends Component<AllProps> {
  colorCache = {
    ADDED: 'green',
    REMOVED: 'red',
  };

  componentDidMount() {
    const { id, retrieveAuditTrail } = this.props;
    retrieveAuditTrail(id);
  }

  generateReqPanes = () => {
    const { auditTrail: trail } = this.props;
    const resultTable = <DictionaryToTable data={trail.req_data} />;
    const raw = (
      <Editor
        onValueChange={emptyFn}
        readOnly
        value={JSON.stringify(trail.req_data, null, 2)}
        highlight={(code) => highlight(code, languages.json)}
        padding={10}
        tabSize={4}
      />
    );
    const panes = [] as any;

    panes.push({
      menuItem: 'Request Data',
      render: () => (
        <Tab.Pane className={styles.resultPane}>{resultTable}</Tab.Pane>
      ),
    });
    panes.push({
      menuItem: 'Raw Request',
      render: () => <Tab.Pane className={styles.resultPane}>{raw}</Tab.Pane>,
    });

    return panes;
  };

  renderSummary = () => {
    const { auditTrail: trail } = this.props;
    const { trailTitleString } = renderAuditTrailIconStyle(trail.action_type);
    const author = trail.api_event ? 'API' : trail.author;
    return (
      <Segment.Group className={styles.noBorderContainer}>
        <Segment.Group horizontal>
          <Segment style={StyleConstants.HALF_COLUMN_SEGMENT}>
            <div className={styles.detailItem}>
              <span className={styles.itemTitle}>Action</span>
              <span>{trailTitleString}</span>
            </div>
            <div className={styles.detailItem}>
              <span className={styles.itemTitle}>Content</span>
              <span>{trail.content}</span>
            </div>
          </Segment>

          <Segment style={StyleConstants.HALF_COLUMN_SEGMENT}>
            <div className={styles.detailItem}>
              <span className={styles.itemTitle}>Agent</span>
              <span>{author}</span>
            </div>
            <div className={styles.detailItem}>
              <span className={styles.itemTitle}>Event Time</span>
              <span>{getLocalFormat(trail.created_at, 'LLLL')}</span>
            </div>
          </Segment>
        </Segment.Group>
      </Segment.Group>
    );
  };

  renderActionTriggerDigest = () => {
    const { auditTrail: trail } = this.props;

    if (trail.action_trigger_digest) {
      return (
        <>
          <h4>Action Trigger Details</h4>
          <ActionTriggerDigestSummary
            actionTriggerDigest={trail.action_trigger_digest}
          />
        </>
      );
    }
    return null;
  };

  renderBeforeAfter = () => {
    const { auditTrail: trail } = this.props;
    if (trail.before_after) {
      return <BeforeAfterTable data={trail.before_after} />;
    }
    return null;
  };

  renderWhiteListing = () => {
    const { auditTrail: trail } = this.props;
    if (trail.whitelist_duration) {
      return <h4>Resources Whitelisted For {trail.whitelist_duration} Days</h4>;
    } else if (
      [
        Unit21AuditTrailActionTypes.GLOBAL_UNWHITELIST,
        Unit21AuditTrailActionTypes.RULE_WHITELIST,
      ].includes(trail.action_type)
    ) {
      return <h4>Resources Whitelisted Indefinitely</h4>;
    }
    return null;
  };

  renderAssociationsLabels = (
    associations: { [key: string]: string[] },
    type:
      | 'Entity'
      | 'Event'
      | 'Alert'
      | 'Case'
      | 'Rule'
      | 'Instrument'
      | 'SAR Filing',
  ): ReactNode => {
    const { handleClose } = this.props;
    const result: ReactNode[] = [];

    for (const [key, value] of Object.entries(associations)) {
      result.push(
        <Label.Group className={styles.flaggedLabelGroup} key={key}>
          {key && <Label color={this.colorCache[key]}>{key} :</Label>}
          {value.map((id) => {
            return (
              <div className={styles.flaggedLabel} key={id}>
                <LinkedLabel
                  id={id}
                  type={type}
                  shouldLink
                  onClick={handleClose}
                />
              </div>
            );
          })}
        </Label.Group>,
      );
    }

    if (result.length === 0) {
      return null;
    }

    return (
      <div className={styles.linkContainer}>
        <h4>Related {type}(s)</h4>
        {result}
      </div>
    );
  };

  renderContent = () => {
    const { id, handleClose, auditTrailLoading, auditTrail } = this.props;

    return (
      <>
        <Modal.Content>
          <LoadingWrapper
            styleProps={{ height: 300 }}
            loading={auditTrailLoading}
          >
            <div className={styles.headerContainer}>
              <ContentHeader>
                <Icon name="history" />
                {`Audit Trail: ${id}`}
              </ContentHeader>
            </div>
            <Icon
              name="close"
              onClick={handleClose}
              className={styles.closeIcon}
            />
            <br />
            {this.renderSummary()}
            <br />
            {this.renderActionTriggerDigest()}
            <br />
            {this.renderBeforeAfter()}
            <br />
            {this.renderWhiteListing()}
            {this.renderAssociationsLabels(auditTrail.entities, 'Entity')}
            {this.renderAssociationsLabels(auditTrail.events, 'Event')}
            {this.renderAssociationsLabels(auditTrail.sars || {}, 'SAR Filing')}
            {this.renderAssociationsLabels(auditTrail.rules, 'Rule')}
            {this.renderAssociationsLabels(auditTrail.alerts, 'Alert')}
            {this.renderAssociationsLabels(auditTrail.cases, 'Case')}
            {this.renderAssociationsLabels(
              auditTrail.txn_instruments,
              'Instrument',
            )}
            {auditTrail.req_data && (
              <Tab
                menu={{ secondary: true, pointing: true }}
                panes={this.generateReqPanes()}
                className={styles.resultTabs}
              />
            )}
          </LoadingWrapper>
        </Modal.Content>
        <Modal.Actions>
          <GenericButton
            negative
            icon={assets.icons.close}
            labelPosition="right"
            content="Close"
            onClick={handleClose}
          />
        </Modal.Actions>
      </>
    );
  };

  render() {
    const { handleClose } = this.props;
    return (
      <Modal className={styles.modal} onClose={handleClose} open>
        {this.renderContent()}
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuditTrailModal);
