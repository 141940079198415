import { useQuery } from '@tanstack/react-query';
import { get } from 'app/shared/utils/fetchr';
import { U21SelectOptionProps } from 'app/shared/u21-ui/components';
import { DATAMAPPING_QUERY_KEYS } from 'app/modules/dataMapping/queries/keys';

const getTimezones = (): Promise<U21SelectOptionProps[]> => {
  return get(`/database-connections/streams/get_timezones`);
};

export const useGetTimezones = () => {
  return useQuery({
    queryKey: DATAMAPPING_QUERY_KEYS.getTimezoneListKey(),
    queryFn: () => getTimezones(),
    meta: { errorMessage: 'Failed to get timezone list.' },
    refetchOnWindowFocus: false,
  });
};
