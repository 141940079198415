import { Filter } from 'app/modules/filters/models';
import { GetTransactionsFilters } from 'app/modules/transactions/requests';

import { TRANSACTION_FILTER_KEYS } from 'app/modules/transactions/filters';
import { FILTER_OPERATOR } from 'app/modules/filters/constants';

import { addDays, format, startOfDay } from 'date-fns';
import { getBEDateFormat } from 'app/shared/utils/timeHelpers';
import moment from 'moment';
import {
  FormattedData,
  FormattedDataItem,
} from 'app/modules/dataSettings/responses';

export const formatTransactionDate = (dt: Date) =>
  dt ? format(dt, 'dd MMM yyyy') : '';

const filterStrings = (array: (number | string)[]): string[] => {
  return array.filter((i): i is string => typeof i === 'string');
};

export const createTransactionFilterPayload = (
  filters: Filter[],
): GetTransactionsFilters => {
  return filters.reduce<GetTransactionsFilters>(
    (acc, i) => {
      const { key, operator, value } = i;
      switch (key) {
        case TRANSACTION_FILTER_KEYS.AMOUNT: {
          if (
            operator === FILTER_OPERATOR.IS_GREATER_THAN_NUMBER &&
            value > 0
          ) {
            acc.minimum_amount = value;
          } else if (
            operator === FILTER_OPERATOR.IS_LESS_THAN_NUMBER &&
            value > 0
          ) {
            acc.maximum_amount = value;
          } else if (operator === FILTER_OPERATOR.IS_BETWEEN_NUMBER) {
            const [min, max] = value;
            if (min > 0) {
              acc.minimum_amount = min;
            }
            if (max > 0) {
              acc.maximum_amount = max;
            }
          }
          break;
        }

        case TRANSACTION_FILTER_KEYS.CURRENCY: {
          if (operator === FILTER_OPERATOR.CONTAINS_TEXT) {
            acc.currency = value;
          }
          break;
        }

        case TRANSACTION_FILTER_KEYS.ENTITY: {
          if (operator === FILTER_OPERATOR.IS_ONE_OF) {
            acc.entity_ids = value;
          }
          break;
        }

        case TRANSACTION_FILTER_KEYS.RECEIVER_ENTITY: {
          if (operator === FILTER_OPERATOR.IS_ONE_OF) {
            acc.receiver_external_entity_ids = filterStrings(value);
          }
          break;
        }

        case TRANSACTION_FILTER_KEYS.SENDER_ENTITY: {
          if (operator === FILTER_OPERATOR.IS_ONE_OF) {
            acc.sender_external_entity_ids = filterStrings(value);
          }
          break;
        }

        case TRANSACTION_FILTER_KEYS.STATUS: {
          if (operator === FILTER_OPERATOR.IS_ONE_OF) {
            acc.statuses = filterStrings(value);
          }
          break;
        }

        case TRANSACTION_FILTER_KEYS.TIMESTAMP: {
          // note: this API is using the legacy date time format so using the old formatter which requires moment
          if (operator === FILTER_OPERATOR.IS_AFTER_DATE) {
            acc.start_date = getBEDateFormat(value, moment.ISO_8601);
          } else if (operator === FILTER_OPERATOR.IS_BEFORE_DATE) {
            acc.end_date = getBEDateFormat(
              startOfDay(addDays(new Date(value), 1)),
              moment.ISO_8601,
            );
          } else if (operator === FILTER_OPERATOR.IS_BETWEEN_DATE) {
            const [start, end] = value;
            acc.start_date = getBEDateFormat(start, moment.ISO_8601);
            acc.end_date = getBEDateFormat(
              startOfDay(addDays(new Date(end), 1)),
              moment.ISO_8601,
            );
          }
          break;
        }

        case TRANSACTION_FILTER_KEYS.TYPE: {
          if (operator === FILTER_OPERATOR.IS_ONE_OF) {
            acc.internal_txn_types = filterStrings(value);
          }
          break;
        }

        case TRANSACTION_FILTER_KEYS.TAG: {
          if (operator === FILTER_OPERATOR.IS_ONE_OF) {
            acc.tag_ids = value;
          }
          break;
        }

        default:
          break;
      }
      return acc;
    },
    {
      hide_placeholder: true,
      hide_spooled: true,
    },
  );
};

export const getTxnDataByFlow = (formattedData: FormattedData) => {
  return Object.entries(formattedData).reduce<
    Record<
      'OUTBOUND' | 'INBOUND' | 'OTHER',
      { formattedData: FormattedDataItem; dataSettingId: number }[]
    >
  >(
    (acc, [key, value]) => {
      if (value.formatted_value?.flow === 'OUTBOUND') {
        acc.OUTBOUND.push({
          formattedData: value,
          dataSettingId: parseInt(key, 10),
        });
      } else if (value.formatted_value?.flow === 'INBOUND') {
        acc.INBOUND.push({
          formattedData: value,
          dataSettingId: parseInt(key, 10),
        });
      } else if (value.formatted_value?.flow === 'OTHER') {
        acc.OTHER.push({
          formattedData: value,
          dataSettingId: parseInt(key, 10),
        });
      }
      return acc;
    },
    { OUTBOUND: [], INBOUND: [], OTHER: [] },
  );
};

export const getDataSettingIdsByFlow = (
  formattedData: FormattedData,
  flow: 'INBOUND' | 'OUTBOUND' | 'OTHER',
) =>
  Object.entries(formattedData)
    .filter((entry) => entry[1].formatted_value?.flow === flow)
    .map(([id]) => id);
