import { useQuery, useQueryClient } from '@tanstack/react-query';
import { NETWORK_ANALYSIS_QUERY_KEYS } from 'app/modules/networkAnalysis/queries/keys';
import { selectNetworkAnalysisRequestIsQueued } from 'app/modules/networkAnalysis/selectors';
import { removeQueuedRequest } from 'app/modules/networkAnalysis/sliceNetworkAnalysis';
import { post } from 'app/shared/utils/fetchr';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export interface NetworkAnalysisStatusResponse {
  status: 'ERROR' | 'PENDING' | 'STARTED' | 'COMPLETED';
  created_at: string;
  updated_at: string;
}

const getNetworkAnalysisStatus = (entityExternalId: string) =>
  post<NetworkAnalysisStatusResponse>(`/entities/network-analysis/status`, {
    entity_external_id: entityExternalId,
  });

export const usePollNetworkAnalysisStatus = (
  entityId: string,
  entityExternalId: string,
) => {
  // check redux to see if the entity ID is in our set of queued network analysis requests
  const enabled = useSelector((state) =>
    selectNetworkAnalysisRequestIsQueued(state, entityExternalId),
  );

  const query = useQuery({
    queryKey: NETWORK_ANALYSIS_QUERY_KEYS.getNetworkAnalysisStatusKey(
      entityId,
      entityExternalId,
    ),
    queryFn: () => getNetworkAnalysisStatus(entityExternalId),
    refetchInterval: (q) =>
      !(q.state.status === 'error') &&
      (enabled ||
      !q.state.data?.status ||
      q.state.data?.status === 'PENDING' ||
      q.state.data?.status === 'STARTED'
        ? 1500
        : undefined), // refetch every 1.5 seconds
  });

  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      (query.data?.status === 'COMPLETED' || query.data?.status === 'ERROR') &&
      !query.isFetching &&
      enabled
    ) {
      // invalidate the network analysis query so that the UI will update
      queryClient.invalidateQueries({
        queryKey: NETWORK_ANALYSIS_QUERY_KEYS.getUseNetworkAnalysisKey(
          entityId,
          entityExternalId,
          true,
        ),
      });
      // remove the entity ID from the set of queued network analysis requests to disable polling
      dispatch(removeQueuedRequest(entityExternalId));
    }
  }, [
    query.data,
    enabled,
    queryClient,
    dispatch,
    entityId,
    entityExternalId,
    query.isFetching,
  ]);
  return query;
};
