import { AnalyticsEvents, EventConfig } from 'app/shared/u21-ui/analytics';
import { U21SelectOptionProps } from 'app/shared/u21-ui/components';

export const onchange: EventConfig<U21SelectOptionProps[]> = {
  name: 'U21MultiSelect changed',
  createPayload: (props, _, option) => {
    const name = props.label || props['aria-label'] || props.name;
    if (!name) {
      return null;
    }

    return {
      name,
      value: option.map((i) => i.value),
    };
  },
};

export const u21MultiSelectEvents = {
  [AnalyticsEvents.U21MULTISELECT_ON_CHANGE]: onchange,
};
