import { createSelector } from 'reselect';

// Models
import { InvestigationType } from 'app/modules/investigations/models';
import {
  AlertActionTriggerModel,
  CaseActionTriggerModel,
} from 'app/modules/webhooks/models';

// Selectors
import {
  selectFilteredActionTriggers,
  selectTriggerAlertActionLoading,
} from 'app/modules/alerts/selectors';
import {
  selectInvestigation,
  selectInvestigationLoading,
} from 'app/modules/investigations/selectors/investigations';
import { selectCustomChecklistValidated } from 'app/shared/customChecklist/selectors';
import { selectCaseActionTriggers } from 'app/modules/session/selectors';
import { shouldRenderCase } from 'app/modules/investigations/helpers';
import { CaseDetails } from 'app/modules/casesOld/models';

export const selectFilteredCaseDetailsActionTriggers = createSelector(
  selectInvestigation(InvestigationType.CASE),
  selectCaseActionTriggers,
  (caseDetails, actionTriggers) => {
    return actionTriggers
      .filter((trigger) =>
        shouldRenderCase(trigger, caseDetails as CaseDetails),
      )
      .sort((triggerA, triggerB) => {
        // Sorting alphabetically
        if (triggerA.label < triggerB.label) {
          return -1;
        }
        return 1;
      });
  },
);

export const selectActionTriggers = (type: InvestigationType) =>
  createSelector(
    selectFilteredActionTriggers,
    selectFilteredCaseDetailsActionTriggers,
    (alertActionTriggers, caseActionTriggers) =>
      type === InvestigationType.ALERT
        ? alertActionTriggers
        : caseActionTriggers,
  );

export const selectTriggerActionTriggerLoading = (type: InvestigationType) =>
  createSelector(
    selectTriggerAlertActionLoading,
    (triggerAlertActionLoading) =>
      type === InvestigationType.ALERT ? triggerAlertActionLoading : false,
  );

const selectGetAlertTriggerDisabledReason = createSelector(
  selectInvestigation(InvestigationType.ALERT),
  selectCustomChecklistValidated,
  (investigation, isChecklistValidated) =>
    (trigger: AlertActionTriggerModel): string | null => {
      const {
        config: { close_alert: closeAlert, enable_if_invalid: enableIfInvalid },
      } = trigger;

      if (closeAlert && investigation.status === 'CLOSED') {
        return 'The alert is closed';
      }

      if (!isChecklistValidated && !enableIfInvalid) {
        return 'The alert investigation is not validated';
      }

      return null;
    },
);

const selectGetCaseTriggerDisabledReason = createSelector(
  selectInvestigationLoading(InvestigationType.CASE),
  selectTriggerActionTriggerLoading(InvestigationType.CASE),
  selectCustomChecklistValidated,
  (investigationLoading, triggerActionTriggerLoading, isChecklistValidated) =>
    (trigger: CaseActionTriggerModel): string | null => {
      const {
        config: { enable_if_invalid: enableIfInvalid },
      } = trigger;

      if (investigationLoading || triggerActionTriggerLoading) {
        return 'The case is loading';
      }

      if (!isChecklistValidated && !enableIfInvalid) {
        return 'The case investigation is not validated';
      }

      return null;
    },
);

export const selectGetDisabledReason = (type: InvestigationType) =>
  createSelector(
    selectGetAlertTriggerDisabledReason,
    selectGetCaseTriggerDisabledReason,
    (alertGetDisabledReason, caseGetDisabledReason) =>
      type === InvestigationType.ALERT
        ? alertGetDisabledReason
        : caseGetDisabledReason,
  );
