// External imports
import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

// Components
import {
  U21Button,
  U21Loading,
  U21ModalFooter,
  U21NoData,
  U21TextField,
} from 'app/shared/u21-ui/components';
import { AiFileExportsDetail } from 'app/modules/codeInterpreter/components/AiFileExportsDetail';
import { AiChatMessageDisplay } from 'app/modules/codeInterpreter/components/AiChatMessageDisplay';
import { IconAiSearch } from 'app/shared/components/Icons/IconAiSearch';
import { IconSearch } from '@u21/tabler-icons';
import { AiChatPromptGroup } from 'app/modules/codeInterpreter/components/AiChatPromptGroup';

// Queries
import { useCreateAiMessage } from 'app/modules/codeInterpreter/queries/useCreateAiMessage';
import { useFetchAiChatSession } from 'app/modules/codeInterpreter/queries/useFetchAiChatSession';

// Utils
import { formatDatetime } from 'app/shared/utils/date';

// Responses
import { FileExportResponse } from 'app/modules/fileExports/responses';
import { AiChatMessageResponse } from 'app/modules/codeInterpreter/responses';

interface AiChatProps {
  sessionId: number;
  setHasChatted: (hasChatted: boolean) => void;
  setIsOpen: (isOpen: boolean) => void;
}

export const AiChat = ({
  sessionId,
  setHasChatted,
  setIsOpen,
}: AiChatProps) => {
  const lastMessageRef = useRef<HTMLDivElement>(null);

  const [chat, setChat] = useState<AiChatMessageResponse[]>([]);
  const [fileExport, setFileExport] = useState<
    FileExportResponse | undefined
  >();
  const [isWaiting, setIsWaiting] = useState<boolean>(false);
  const [userMessage, setUserMessage] = useState<string>('');

  const { data, isInitialLoading: isAiChatLoading } =
    useFetchAiChatSession(sessionId);

  const { mutateAsync: createAiMessage, isPending: isAiResponding } =
    useCreateAiMessage(sessionId);

  useEffect(() => {
    if (data !== undefined) {
      setChat(data.message_history);
      setFileExport(data.file_export_details);
      setIsWaiting(data.is_waiting);
    }
  }, [data]);

  const handleSendingMessage = useCallback(
    async (message: string) => {
      // Update chat for responsive UI
      setChat((prevChat) => [
        ...prevChat,
        {
          role: 'user',
          message,
          created_at: formatDatetime(Date.now()),
          id: -1,
          image_link: null,
        },
      ]);
      try {
        setIsWaiting(true);
        await createAiMessage({ message });
      } catch {
        // Error is handled within the mutateAsync hook
      }
      setUserMessage('');
    },
    [createAiMessage],
  );

  // Set viewport on latest message whenever chat updates
  useEffect(() => {
    lastMessageRef.current?.scrollIntoView();
  }, [chat]);

  if (isAiChatLoading || fileExport === undefined) {
    return (
      <ErrorWrapper>
        {isAiChatLoading ? (
          <U21Loading loading />
        ) : (
          <StyledNoData>
            Something went wrong. Please try again later.
          </StyledNoData>
        )}
        <U21ModalFooter>
          <U21Button onClick={() => setIsOpen(false)}>Close</U21Button>
        </U21ModalFooter>
      </ErrorWrapper>
    );
  }

  return (
    <ChatWrapper>
      <AiFileExportsDetail fileExport={fileExport} />
      <ChatBody>
        {chat.length === 0 ? (
          <>
            <StyledNoData>
              There is no chat history yet. Ask us something!
            </StyledNoData>
            <PromptWrapper>
              <AiChatPromptGroup onClick={handleSendingMessage} />
            </PromptWrapper>
          </>
        ) : (
          chat.map((chatMessage, index) => (
            <AiChatMessageDisplay
              ref={index === chat.length - 1 ? lastMessageRef : null}
              key={chatMessage.id}
              {...chatMessage}
            />
          ))
        )}

        <StyledLoading
          loading={isAiResponding || isWaiting}
          variant="dots"
          label="Generating a response..."
        />
      </ChatBody>

      <StyledFooter>
        <U21TextField
          autoFocus
          minRows={1}
          startIcon={<IconSearch />}
          placeholder="What would you like to know?"
          onChange={(value: string = '') => setUserMessage(value)}
          value={userMessage}
          disabled={isAiResponding}
          type="textarea"
        />
        <U21Button
          color="primary"
          variant="contained"
          startIcon={<IconAiSearch />}
          onClick={() => {
            setHasChatted(true);
            handleSendingMessage(userMessage);
          }}
          loading={isAiResponding || isWaiting}
        >
          Ask AI
        </U21Button>
      </StyledFooter>
    </ChatWrapper>
  );
};

const StyledLoading = styled(U21Loading)`
  margin-top: 16px;
`;

const StyledNoData = styled(U21NoData)`
  display: flex;
  flex: 1;
`;

const ErrorWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const ChatWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
`;

const ChatBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  margin-bottom: 24px;
`;

const StyledFooter = styled(U21ModalFooter)`
  display: flex;
  flex: 1;
`;

const PromptWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-end;
`;
