// Models
import {
  ArrayComparisonOperators,
  CustomDataFilters,
  SimpleComparisonOperators,
} from 'app/modules/filtersOld/models';
import { Filter } from 'app/modules/filters/models';

// Constants
import { FILTER_OPERATOR } from 'app/modules/filters/constants';
import { Unit21DataClassifier } from 'app/modules/dataSettings/responses';
import { TXN_ANALYSIS_FILTER_KEYS_SET } from 'app/modules/txnAnalysis/filters';

export const createTxnAnalysisFilters = (filters: Filter[]) => {
  const { nativeFilters, customFilters } = filters.reduce<{
    nativeFilters: Filter[];
    customFilters: Filter[];
  }>(
    (acc, i) => {
      if (TXN_ANALYSIS_FILTER_KEYS_SET.has(i.key)) {
        acc.nativeFilters.push(i);
      } else {
        acc.customFilters.push(i);
      }
      return acc;
    },
    {
      nativeFilters: [],
      customFilters: [],
    },
  );

  const customDataFilters = customFilters.reduce<CustomDataFilters>(
    (acc, i) => {
      const CLASSIFIER_CUSTOM_DATA_KEY_MAPPING = {
        [Unit21DataClassifier.ENTITY]: 'entity',
        [Unit21DataClassifier.INSTRUMENT]: 'txn_instrument',
        [Unit21DataClassifier.EVENT]: 'event',
      };
      const { classifier, key } = JSON.parse(i.key);
      const customDataKey = CLASSIFIER_CUSTOM_DATA_KEY_MAPPING[classifier];
      if (!acc[customDataKey]) {
        acc[customDataKey] = [];
      }
      const { operator, value } = i;
      switch (operator) {
        case FILTER_OPERATOR.IS_GREATER_THAN_NUMBER:
          acc[customDataKey].push({
            key,
            nested: false,
            operator: SimpleComparisonOperators.GT,
            value,
          });
          break;

        case FILTER_OPERATOR.IS_LESS_THAN_NUMBER:
          acc[customDataKey].push({
            key,
            nested: false,
            operator: SimpleComparisonOperators.LT,
            value,
          });
          break;

        case FILTER_OPERATOR.IS_EXACT_TEXT:
          acc[customDataKey].push({
            key,
            nested: false,
            operator: SimpleComparisonOperators.EQ,
            value,
          });
          break;

        case FILTER_OPERATOR.IS_ONE_OF:
          acc[customDataKey].push({
            key,
            nested: false,
            operator: ArrayComparisonOperators.IN,
            value,
          });
          break;

        case FILTER_OPERATOR.IS_NOT_EXACT_TEXT:
          acc[customDataKey].push({
            key,
            nested: false,
            operator: SimpleComparisonOperators.NEQ,
            value,
          });
          break;

        case FILTER_OPERATOR.IS_NOT_ONE_OF:
          acc[customDataKey].push({
            key,
            nested: false,
            operator: ArrayComparisonOperators.NOT_IN,
            value,
          });
          break;

        case FILTER_OPERATOR.IS_EMPTY:
          acc[customDataKey].push({
            key,
            nested: false,
            operator: SimpleComparisonOperators.EQ,
            value: null,
          });
          break;

        case FILTER_OPERATOR.IS_NOT_EMPTY:
          acc[customDataKey].push({
            key,
            nested: false,
            operator: SimpleComparisonOperators.NEQ,
            value: null,
          });
          break;

        default:
          break;
      }

      return acc;
    },
    {},
  );

  return {
    filters: nativeFilters,
    custom_data_filters: customDataFilters,
  };
};
