import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Models
import { PaginationPayload, TableConfig } from 'app/shared/pagination/models';

// Components
import PaginationTable from 'app/shared/pagination/components/PaginationTable';
import { Segment } from 'semantic-ui-react';

// Actions
import { retrieveEntityAlerts } from 'app/modules/entities/actions';

// Selectors
import {
  selectEntityAlertsLoading,
  selectPaginatedEntityAlerts,
} from 'app/modules/entities/selectors';

// Utils
import routes, { openInNewTab } from 'app/shared/utils/routes';

// Constants
import { DEFAULT_PAGINATION_PAYLOAD } from 'app/shared/pagination/constants';

const ENTITY_ASSOCIATED_ALERTS_TABLE_CONFIG: TableConfig[] = [
  { key: 'id', type: 'text', label: 'ID' },
  { key: 'status', type: 'label', label: 'Status' },
  { key: 'created_at', type: 'datetime', label: 'Flagged Date' },
  {
    key: 'disposition',
    type: 'label',
    label: 'Disposition',
    sortable: false,
  },
];

interface OwnProps {
  entityExternalId: string;
}

export const EntityAssociatedAlerts: FC<OwnProps> = ({ entityExternalId }) => {
  const dispatch = useDispatch();
  const entityAlerts = useSelector(selectPaginatedEntityAlerts);
  const entityAlertsLoading = useSelector(selectEntityAlertsLoading);

  useEffect(() => {
    dispatch(
      retrieveEntityAlerts({
        ...DEFAULT_PAGINATION_PAYLOAD,
        entityExternalId,
      }),
    );
  }, [dispatch, entityExternalId]);

  const retrieveAlerts = (payload: PaginationPayload) => {
    dispatch(retrieveEntityAlerts({ ...payload, entityExternalId }));
  };

  const history = useHistory();
  const onRowClick = (e: MouseEvent, alert: { id: any }) => {
    const path = routes.lumos.alertsId.replace(':id', String(alert.id));

    if (e.metaKey) {
      openInNewTab(path);
      return;
    }
    history.push(path);
  };
  return (
    <Segment.Group>
      <Segment color="red" textAlign="center">
        <span>Flagged Alerts</span>
      </Segment>
      <PaginationTable
        update={retrieveAlerts}
        loading={entityAlertsLoading}
        totalCount={entityAlerts.count}
        config={ENTITY_ASSOCIATED_ALERTS_TABLE_CONFIG}
        rows={entityAlerts.alerts}
        onRowClick={onRowClick}
      />
    </Segment.Group>
  );
};
