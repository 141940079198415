import { CSSProperties, Fragment, ReactElement } from 'react';
import numeral from 'numeral';
import { omit, get } from 'lodash';

// Components
import { Label, Button } from 'semantic-ui-react';
import LinkedLabel from 'app/shared/components/LinkedLabel';
import FlaggedLabel from 'app/shared/components/FlaggedLabel';
import { IdsTableCell } from 'app/shared/pagination/components/IdsTableCell';

// Models
import { EntityDetails, EntitySummary } from 'app/modules/entities/models';

import { TableValueType, RowMenuItem } from 'app/shared/pagination/models';
import {
  EntityExternalLinkModel,
  LumosConfig,
} from 'app/modules/orgSettings/models';
import { ExportStatus } from 'app/modules/fileExportsOld/models';

// API
import { downloadFileExport } from 'app/shared/api/fileExports';

// Constants
import { AMOUNT_FORMAT, MUSTACHE_MATCHER_REGEX } from 'app/shared/constants';
import { LABEL_PREDEFINED_COLORS } from 'app/modules/entities/constants';

// Styles
import { StyleConstants, UNIT21_COLORS } from 'app/styles/StyleConstants';
import scssStyles from 'app/shared/events/styles/EventTable.module.scss';

// Utils
import getByPath from 'app/shared/utils/getByPath';
import formatPhoneNumber from 'app/shared/utils/formatPhoneNumber';
import { getLocalFormat, displayUTCFormat } from 'app/shared/utils/timeHelpers';
import { generateColor } from 'app/shared/utils/stringToColor';

// Helpers
import {
  generateLabelBackgroundColor,
  humanReadableFileSize,
  isArrayOfType,
  isSuitableBackgroundColor,
} from 'app/shared/helpers';
import { IconUser, IconId, IconCoin } from '@u21/tabler-icons';

export const formatTableValue = (attrs: {
  idx?: any;
  value: any;
  valueType?: TableValueType;
  key?: string;
  fmtstr?: string;
  isGoto?: boolean;
  onClick?: (key: string, value: string) => void;
  useRandomColor?: boolean;
  useGenerateColor?: boolean;
  row?: any;
}) => {
  const {
    idx,
    key,
    value,
    valueType,
    fmtstr,
    isGoto,
    onClick,
    useRandomColor,
    useGenerateColor,
    row,
  } = attrs;
  switch (valueType) {
    case 'phone_numbers':
      if (typeof value === 'string') {
        return formatPhoneNumber(value);
      }
      if (isArrayOfType(value, 'object')) {
        return (
          <div className={scssStyles.textArray}>
            {value.map((i) => i.value_).join(', ')}
          </div>
        );
      }

      // handle unexpected data
      if (!isArrayOfType(value, 'string')) {
        return null;
      }

      return (
        <div className={scssStyles.textArray}>
          {value.map((text, jdx) => {
            if (jdx === value.length - 1) {
              return formatPhoneNumber(text);
            }
            return `${formatPhoneNumber(text)}, `;
          })}
        </div>
      );

    case 'text_array':
      // handle unexpected data
      if (!isArrayOfType(value, 'string')) {
        return null;
      }
      return (
        <div className={scssStyles.textArray}>
          {value.map((text, jdx) => {
            if (jdx === value.length - 1) {
              return text;
            }
            return `${text}, `;
          })}
        </div>
      );
    case 'label_array':
      // handle unexpected data
      if (!isArrayOfType(value, 'string')) {
        return null;
      }
      return (
        <Label.Group>
          {value.map((item, ix) => (
            <Label
              // eslint-disable-next-line react/no-array-index-key
              key={`${key}-${idx}-${ix}`}
              className={scssStyles.valueLabel}
            >
              {item}
            </Label>
          ))}
        </Label.Group>
      );
    case 'dollar':
      return numeral(value).format(AMOUNT_FORMAT);

    // TODO need to make sure that every function calling formatTableValue is passing in a datetime *without* TZ info
    case 'datetime':
      if (key === 'date_of_birth') {
        return displayUTCFormat(value, fmtstr || 'lll');
      }
      return getLocalFormat(value, fmtstr || 'lll');

    case 'email_address':
    case 'phone_number':
    case 'physical_id':
    case 'address':
    case 'label': {
      if (value === undefined || value === '' || value === null) {
        return <div />;
      }

      const isObject = value instanceof Object;
      const valueToRender = isObject ? value.value_ : value;

      const bgColor = generateColor(valueToRender);
      const textColor = isSuitableBackgroundColor(bgColor) ? 'white' : 'black';

      let labelStyle: CSSProperties;
      if (isObject) {
        labelStyle = getLabelStyle(1);
      } else if (useGenerateColor) {
        labelStyle = {
          background: bgColor,
          border: `1px solid ${bgColor}`,
          color: textColor,
        };
      } else {
        labelStyle = getLabelStyle(value, useRandomColor);
      }

      const label = (
        <Label
          key={`${key}-${idx}`}
          onClick={(event) => {
            event.stopPropagation();
            if (onClick) {
              onClick(key!, value);
            }
          }}
          className={scssStyles.valueLabel}
          style={labelStyle}
        >
          {String(valueToRender)}
        </Label>
      );

      if (valueType === 'address' && value.type) {
        return (
          <div className={scssStyles.horizontal}>
            {label}
            <Label className={scssStyles.valueLabel} style={getLabelStyle(1)}>
              {String(value.type)}
            </Label>
          </div>
        );
      }
      return label;
    }

    case 'tags':
      if (value === undefined || value === null || value.length === 0) {
        return <div />;
      }
      return (
        <Label.Group>
          {value.map((tag) => (
            <Label
              key={`${tag.type}:${tag.name}`}
              className={scssStyles.valueLabel}
              style={getLabelStyle(`${tag.type}:${tag.name}`)}
            >
              {tag.type}:{tag.name}
            </Label>
          ))}
        </Label.Group>
      );

    case 'number':
      if (typeof value === 'number') {
        return <div className={scssStyles.numberText}>{value}</div>;
      }
      if (value) {
        return <div>{String(value)}</div>;
      }
      return null;

    case 'alert':
      if (!value || value === '') {
        return <div />;
      }
      return (
        <LinkedLabel
          key={`${key}-${idx}`}
          id={value.id}
          title={value.title}
          type="Alert"
          shouldLink={isGoto && Boolean(value.id)}
        />
      );

    case 'entity_ids':
      if (!value || value === '') {
        return <div />;
      }
      return (
        <Fragment key={`${key}-${idx}`}>
          <IdsTableCell ids={value} type="Entity" />
        </Fragment>
      );

    case 'rule':
      if (!value || value === '') {
        return <div />;
      }
      return (
        <LinkedLabel
          key={`${key}-${idx}`}
          id={value.id}
          title={value.title}
          type="Rule"
          shouldLink={isGoto && Boolean(value.id)}
        />
      );

    case 'rule_id':
      if (!value || value === '') {
        return <div />;
      }
      return (
        <LinkedLabel
          key={`${key}-${idx}`}
          id={value.id}
          title={value.title}
          type="Rule ID"
          shouldLink
        />
      );

    case 'entity':
      if (value === undefined || value === '') {
        return <div />;
      }
      return (
        <LinkedLabel
          key={`${key}-${idx}`}
          id={value.id}
          title={value.title}
          type="Entity"
          shouldLink={isGoto && value.id !== undefined}
        />
      );

    case 'file_exports_download': {
      // this case renders a download icon for file exports download
      const { id, file_name: fileName, status } = row;
      const enabledStatus: ExportStatus = 'READY_FOR_DOWNLOAD';
      return (
        <div className={scssStyles.downloadIconContainer}>
          <Button
            className={scssStyles.downloadIconButton}
            circular
            icon="download"
            disabled={Boolean(status !== enabledStatus)}
            onClick={() => downloadFileExport({ id, file_name: fileName })}
          />
        </div>
      );
    }

    case 'file_size':
      return humanReadableFileSize(value);

    case 'statistics': {
      if (!value) {
        return <div />;
      }
      const flaggedLabels: ReactElement[] = [];
      if (Object.prototype.hasOwnProperty.call(value, 'num_txns')) {
        flaggedLabels.push(
          <FlaggedLabel
            id="flaggedEventTooltip"
            content={value.num_txns}
            icon={<IconCoin />}
            key="flaggedEventTooltip"
            classNameProps={
              value.num_txns > 0
                ? scssStyles.flaggedLabelRed
                : scssStyles.flaggedLabel
            }
          />,
        );
      }
      if (Object.prototype.hasOwnProperty.call(value, 'num_entities')) {
        flaggedLabels.push(
          <FlaggedLabel
            id="flaggedEntitiesTooltip"
            content={value.num_entities}
            icon={<IconUser />}
            key="flaggedEntitiesTooltip"
            classNameProps={
              value.num_entities > 0
                ? scssStyles.flaggedLabelRed
                : scssStyles.flaggedLabel
            }
          />,
        );
      }
      if (Object.prototype.hasOwnProperty.call(value, 'num_instruments')) {
        flaggedLabels.push(
          <FlaggedLabel
            id="flaggedInstrumentsTooltip"
            content={value.num_instruments}
            icon={<IconId />}
            key="flaggedInstrumentsTooltip"
            classNameProps={
              value.num_instruments > 0
                ? scssStyles.flaggedLabelRed
                : scssStyles.flaggedLabel
            }
          />,
        );
      }
      return flaggedLabels.map((label) => label);
    }

    case 'text':
    default:
      if (value instanceof Object) {
        return Object.prototype.hasOwnProperty.call(value, 'value_')
          ? value.value_
          : '';
      }

      return Array.isArray(value) ? '' : `${value}`;
  }
};

export const getValueFromEntity = (
  entity: EntityDetails | EntitySummary | any,
  keyOptions: string | string[] | ((item: any) => any),
): string => {
  // If keyOptions is an array, it means that there are different entity types
  // with different keys used to extract the value from test to see which key
  // exists on entity - e.g. "user_name" for Users and "name" for Businesses
  let key;
  if (Array.isArray(keyOptions)) {
    for (let i = 0; i < keyOptions.length; i++) {
      if (Object.prototype.hasOwnProperty.call(entity, keyOptions[i])) {
        key = keyOptions[i];
        break;
      }
    }
  } else if (typeof keyOptions === 'function') {
    return keyOptions(entity);
  } else {
    key = keyOptions;
  }

  if (!key) {
    return '';
  }

  if (key.startsWith('custom_data.internal.')) {
    return entity && entity.custom_data && entity.custom_data.internal
      ? entity.custom_data.internal[key.substr('custom_data.internal.'.length)]
      : '';
  } else if (key.startsWith('custom_data.customer_raw.')) {
    // custom_data is either returned with custom_data.customer_raw.values_we_care_about OR custom_data.values_we_care_about
    // so we want to account for both cases
    const customDataExists = Boolean(entity && entity.custom_data);
    if (!customDataExists) {
      return '';
    }
    if (entity.custom_data.customer_raw) {
      // get the nested key in custom_data.customer_raw
      return get(entity, key) || '';
    }
    // custom_data has been flattened (i.e. no top level customer_raw key)
    return (
      entity.custom_data[key.substr('custom_data.customer_raw.'.length)] || ''
    );
  } else if (key.includes('[') && key.includes(']')) {
    const ixStart = key.lastIndexOf('[');
    const ixEnd = key.lastIndexOf(']');
    const position = parseInt(key.substr(ixStart + 1).slice(0, ixEnd), 10);
    const fieldName = key.substr(0, ixStart);
    const arrayVal = entity[fieldName];
    if (Array.isArray(arrayVal) && arrayVal.length > position) {
      if (!key.endsWith(']') && arrayVal[position]) {
        // Accessing field within array object
        const subfieldName = key.substr(ixEnd + 2);
        return arrayVal[position][subfieldName];
      }
      return arrayVal[position];
    }
    return '';
  }
  return entity[key];
};

export const getLabelStyle = (
  value: string | number,
  useRandomColor?: boolean,
) => {
  let labelValue = value;
  const labelStyle: StyleMap = {
    color: 'white',
    fontFamily: StyleConstants.FONT_FAMILY,
    fontSize: StyleConstants.FONT_SIZE_LABEL,
  };

  if (!labelValue) {
    return labelStyle;
  }

  if (typeof labelValue === 'number') {
    // no custom label colors for numbers (i.e. ids)
    labelStyle.backgroundColor = UNIT21_COLORS.UNIT21_INDIGO;
    return labelStyle;
  }

  if (typeof labelValue === 'string') {
    labelValue = labelValue.toLowerCase();
  }

  if (labelValue.toUpperCase() in LABEL_PREDEFINED_COLORS) {
    labelStyle.backgroundColor =
      LABEL_PREDEFINED_COLORS[labelValue.toUpperCase()];
  } else if (useRandomColor) {
    labelStyle.backgroundColor = generateLabelBackgroundColor(labelValue);
  } else {
    labelStyle.backgroundColor = UNIT21_COLORS.UNIT21_INDIGO;
  }
  return labelStyle;
};

export const generateObjectPlaceholderTableConfig = (objectType: string) => {
  return {
    partial_data: [
      {
        key: 'created_at',
        type: 'datetime',
        label: 'Created at',
      },
      {
        key: 'external_id',
        type: 'text',
        label: `${objectType} ID`,
      },
      {
        key: 'id',
        type: 'text',
        label: 'Unit21 ID',
      },
      {
        key: 'type',
        type: 'label',
        label: `${objectType} Type`,
      },
    ],
  };
};

// Retrieves custom_data from lumosConfig.entity_page and then removes the keys that exist from the keys in entityDetails.custom_data
export const filterEntityCustomData = (
  entityDetails: EntityDetails,
  lumosConfig: LumosConfig,
) => {
  // Custom data that will be displayed
  const entityCustomData = entityDetails.custom_data || {};
  const lumosConfigCustomData = lumosConfig.entity_page.user?.reduce(
    (fields, userConfig) => {
      const customKeys =
        userConfig.custom_data?.map((field) =>
          field.key.replace('custom_data.customer_raw.', ''),
        ) || [];
      return [...fields, ...customKeys];
    },
    [],
  );
  return omit(entityCustomData, lumosConfigCustomData);
};

export const replaceEntityLinkMustache = (
  content: string,
  entity: EntitySummary,
): string => {
  MUSTACHE_MATCHER_REGEX.lastIndex = 0;
  const match = MUSTACHE_MATCHER_REGEX.exec(content);
  if (!match) return '';

  const value = getNestedValueForEntityCustomData(match, entity);

  return `${content.substring(
    0,
    match.index,
  )}${value}${content.substring(match.index + match[0].length)}`;
};

export const getNestedValueForEntityCustomData = (
  match,
  entity: EntitySummary,
): string => {
  const key = match[0].substring(2, match[0].length - 2);
  let value = entity[key];

  if (key.startsWith('custom_data.') && entity.custom_data) {
    // remove the custom_data key
    const nestedKeys = key.split('.').slice(1);
    let customData = entity.custom_data;

    // check if there is the legacy customer_raw nested fields
    if (customData.customer_raw) {
      customData = customData.customer_raw;
    }

    value = getByPath(customData, nestedKeys);
  }

  return value;
};

export const formatExternalUrl = (
  entity: EntitySummary,
  link: EntityExternalLinkModel,
): string => {
  const urlTemplate = link.url_template || '';
  MUSTACHE_MATCHER_REGEX.lastIndex = 0;
  const hasMustache = MUSTACHE_MATCHER_REGEX.exec(urlTemplate);

  if (hasMustache) {
    // has a dynamic value to replace from an entity
    return replaceEntityLinkMustache(urlTemplate, entity);
  }
  // no dynamic value needed in the url
  return urlTemplate;
};

export const formatEntityExternalLink = (
  externalLink: EntityExternalLinkModel,
): RowMenuItem => {
  const { label } = externalLink;

  return {
    key: `ext-button-${label.replace(/\s/g, '-').toLowerCase()}`,
    text: label,
    onClick: (entity: EntityDetails) => {
      const externalUrl = formatExternalUrl(entity, externalLink);
      window.open(externalUrl, '_blank');
    },
    shouldDisable: (entity: EntityDetails) => {
      MUSTACHE_MATCHER_REGEX.lastIndex = 0;
      const match = MUSTACHE_MATCHER_REGEX.exec(externalLink.url_template);

      if (!match) {
        return false;
      }
      // Disable this link if there is no custom_data for entity associated with this link
      const customNestedValue = getNestedValueForEntityCustomData(
        match,
        entity,
      );
      return !customNestedValue;
    },
  };
};

export const getEntityExternalLinks = (config: LumosConfig): RowMenuItem[] => {
  const configEntityExternalLinks: EntityExternalLinkModel[] =
    config?.entity_page?.external_links || [];

  return configEntityExternalLinks.map((externalLink) => {
    return formatEntityExternalLink(externalLink);
  });
};
