import { PaginatedAgentsPayload } from 'app/modules/agents/requests';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { AGENT_QUERY_KEYS } from 'app/modules/agents/queries/keys';
import { post } from 'app/shared/utils/fetchr';
import {
  PaginatedAgentsResponse,
  ShortAgentResponse,
} from 'app/modules/agents/responses';
import { selectHasReadAgentsPermissions } from 'app/modules/session/selectors';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { unionBy } from 'lodash';

export const useSearchAgents = (payload: PaginatedAgentsPayload) => {
  const queryClient = useQueryClient();
  const hasReadAgentsPermissions = useSelector(selectHasReadAgentsPermissions);
  const query = useQuery({
    enabled: payload.limit > 0 && hasReadAgentsPermissions,
    queryFn: () =>
      post<PaginatedAgentsResponse>('/agents/autocomplete', payload),
    queryKey: AGENT_QUERY_KEYS.searchAgents(payload),
  });

  const cachedQueryData = queryClient.getQueriesData<PaginatedAgentsResponse>({
    queryKey: AGENT_QUERY_KEYS.searchAgents(),
  });

  const agents = useMemo(
    () =>
      cachedQueryData.reduce<ShortAgentResponse[]>((acc, i) => {
        const [, queryData] = i;
        if (queryData) {
          return unionBy(queryData.agents, acc, 'id');
        }
        return acc;
      }, []),
    [cachedQueryData],
  );

  return {
    ...query,
    agents,
  };
};
