import { useSelector } from 'react-redux';

// Components
import {
  U21Chip,
  U21ShowMoreList,
  U21Skeleton,
  U21Spacer,
} from 'app/shared/u21-ui/components';
import { IconFlag } from '@u21/tabler-icons';

// Selectors
import { selectHasReadAlertsPermission } from 'app/modules/session/selectors';

// Queries
import { useGetAlertDispositionByAlertIds } from 'app/modules/alerts/queries/useGetAlertDispositionByAlertIds';

// Utils
import { ROUTES_MAP } from 'app/shared/utils/routes';
import { formatDisposition } from 'app/shared/utils/disposition';

type OwnProps = {
  alertIds: number[];
};

export const AlertDispositionChipList = ({ alertIds }: OwnProps) => {
  const hasReadAlertsPermission = useSelector(selectHasReadAlertsPermission);
  const { data, isFetching } = useGetAlertDispositionByAlertIds(alertIds);

  if (!hasReadAlertsPermission) {
    return null;
  }

  return (
    <U21ShowMoreList
      displayFunc={(alertId) => {
        return (
          <U21Chip
            to={ROUTES_MAP.alertsId.path.replace(':id', String(alertId))}
            icon={<IconFlag />}
          >
            <U21Spacer horizontal>
              #{alertId}:{' '}
              {isFetching ? (
                <U21Skeleton width={80} />
              ) : (
                (formatDisposition(data[alertId]) ?? '--')
              )}
            </U21Spacer>
          </U21Chip>
        );
      }}
      onLessClick={(e) => e.stopPropagation()}
      onMoreClick={(e) => e.stopPropagation()}
      value={alertIds}
    />
  );
};
