import { IconProps } from 'app/shared/components/Icons/models';

export const IconAiSearch = ({
  size = 20,
  color = 'currentColor',
  stroke = 2,
  ...props
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    {...props}
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={stroke}
      d="M10 17a7 7 0 1 0 0-14 7 7 0 0 0 0 14ZM21 21l-6-6"
    />
    <path
      fill={color}
      d="M9.905 5.889a.1.1 0 0 1 .19 0l.836 2.542a1 1 0 0 0 .638.638l2.542.836a.1.1 0 0 1 0 .19l-2.542.836a1 1 0 0 0-.638.638l-.836 2.542a.1.1 0 0 1-.19 0l-.836-2.542a1 1 0 0 0-.638-.638l-2.542-.836a.1.1 0 0 1 0-.19l2.542-.836a1 1 0 0 0 .638-.638l.836-2.542ZM2.405 15.289a.1.1 0 0 1 .19 0l.366 1.112a1 1 0 0 0 .637.638l1.113.366a.1.1 0 0 1 0 .19l-1.113.366a1 1 0 0 0-.637.638l-.366 1.112a.1.1 0 0 1-.19 0L2.039 18.6a1 1 0 0 0-.637-.638l-1.113-.366a.1.1 0 0 1 0-.19l1.113-.366a1 1 0 0 0 .637-.638l.366-1.112ZM2.405.289a.1.1 0 0 1 .19 0l.366 1.113a1 1 0 0 0 .637.637l1.113.366a.1.1 0 0 1 0 .19l-1.113.366a1 1 0 0 0-.637.637l-.366 1.113a.1.1 0 0 1-.19 0l-.366-1.113a1 1 0 0 0-.637-.637L.289 2.595a.1.1 0 0 1 0-.19l1.113-.366a1 1 0 0 0 .637-.637L2.405.289Z"
    />
  </svg>
);
