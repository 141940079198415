import styled from 'styled-components';
import { Fragment, useState } from 'react';
import { IconArrowDown, IconArrowUp } from '@u21/tabler-icons';
import { useTheme } from '@mui/material/styles';
import {
  U21Chip,
  U21Grid,
  U21Loading,
  U21MenuLayout,
  U21Spacer,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { useGetTransactionRiskScoreEvidence } from 'app/modules/transactionScore/queries/useGetTransactionRiskScoreEvidence';
import { useSelector } from 'react-redux';
import { selectHasReadEventsPermission } from 'app/modules/session/selectors';
import { fromUpperToSentenceCase } from 'app/shared/utils/string';

interface Props {
  eventID: string;
  score: number | null;
}

export const TransactionRiskScore = ({ eventID, score }: Props) => {
  const hasReadEventsPermission = useSelector(selectHasReadEventsPermission);

  const { palette } = useTheme();
  const [open, setOpen] = useState(false);

  const { data: evidence, isLoading } = useGetTransactionRiskScoreEvidence(
    eventID,
    {
      enabled: Boolean(eventID) && open && hasReadEventsPermission,
      select: (data) =>
        Object.entries(data)
          .map(([k, v]) => ({
            ...v,
            name: k,
            value: Math.round(v.value),
          }))
          .sort((a, b) => {
            const absoluteA = Math.abs(a.value);
            const absoluteB = Math.abs(b.value);
            if (absoluteB > absoluteA) {
              return 1;
            }
            if (absoluteB < absoluteA) {
              return -1;
            }
            return 0;
          })
          .slice(0, 3),
    },
  );

  if (score === null || !hasReadEventsPermission) {
    return null;
  }

  return (
    <U21MenuLayout
      onClose={(e) => {
        // stop propagation to prevent bubbling to table rows clicks
        e.stopPropagation();
        setOpen(false);
      }}
      trigger={
        <U21Chip
          onClick={(e) => {
            e.stopPropagation();
            setOpen(true);
          }}
          color="error"
        >
          {score.toFixed(2)}
        </U21Chip>
      }
      open={open}
    >
      <Container spacing={2} onClick={(e) => e.stopPropagation()}>
        <U21Typography variant="subtitle2">
          ACH Risk Score Evidence
        </U21Typography>
        <U21Loading delay={0} loading={isLoading} />
        {!isLoading && (
          <U21Grid minWidth={1}>
            <U21Typography variant="subtitle2">Evidence</U21Typography>
            <RiskTypography variant="subtitle2">Risk</RiskTypography>
            {evidence ? (
              evidence?.map((item) => {
                return (
                  <Fragment key={item.name}>
                    <U21Typography variant="body2">
                      {item.pretty_name || fromUpperToSentenceCase(item.name)}
                    </U21Typography>
                    <RiskTypography
                      variant="body2"
                      icon={
                        item.increase_risk ? (
                          <IconArrowUp color={palette.colors.red.main} />
                        ) : (
                          <IconArrowDown color={palette.colors.green.main} />
                        )
                      }
                    >
                      {item.value}
                    </RiskTypography>
                  </Fragment>
                );
              })
            ) : (
              <U21Typography variant="body2">None</U21Typography>
            )}
          </U21Grid>
        )}
      </Container>
    </U21MenuLayout>
  );
};

const Container = styled(U21Spacer)`
  padding: 16px;
  width: 400px;
`;

const RiskTypography = styled(U21Typography)`
  margin-left: auto;
  margin-right: auto;
`;
