import { post } from 'app/shared/utils/fetchr';
import { useQuery } from '@tanstack/react-query';
import { ALERT_QUERY_KEYS } from 'app/modules/alerts/queries/keys';
import { ShortEntityResponse } from 'app/modules/entities/types';
import { RetrieveAlertEntitiesPayload } from 'app/modules/alerts/models';
import { useSelector } from 'react-redux';
import { selectHasReadAlertsPermission } from 'app/modules/session/selectors';

export const useGetAlertEntities = (body: RetrieveAlertEntitiesPayload) => {
  const hasReadAlertsPermission = useSelector(selectHasReadAlertsPermission);
  return useQuery({
    queryKey: ALERT_QUERY_KEYS.getAlertEntities(body),
    queryFn: () =>
      post<{ count: number | null; entities: ShortEntityResponse[] }>(
        `/alerts/${body.alertId}/entities`,
        body,
      ),
    meta: { errorMessage: 'Failed to load alert entities.' },
    enabled: hasReadAlertsPermission && body.limit !== 0,
  });
};
