import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

// Components
import { U21SideMenu } from 'app/shared/u21-ui/components/dashboard';
import { U21Button, U21Spacer } from 'app/shared/u21-ui/components';
import { SidebarSummary } from 'app/modules/sidebar/components/utils/SidebarSummary';
// Models
import { ActionEventSidebarDefinition } from 'app/modules/sidebar/models';

// Utils

// Selectors
import {
  selectActionEvent,
  selectActionEventLoading,
} from 'app/modules/transactions/selectors';
import { selectRealTimeRulesEnabled } from 'app/modules/orgSettings/selectors';
import { selectFraudTransactionRulesEnabled } from 'app/shared/featureFlags/selectors';
import { selectEvaluationLog } from 'app/shared/events/selectors';

// Actions
import { retrieveActionEvent } from 'app/modules/transactions/actions';

// Constants
import { ROUTES_MAP } from 'app/shared/utils/routes';
import {
  clearEvaluationLog,
  retrieveEvaluationLogThunk,
} from 'app/shared/events/sliceEvents';
import { SidebarEvaluationLogInfo } from 'app/modules/sidebar/components/SidebarEvaluationLogInfo';
import { SummaryViewConfigTypeClassifier } from 'app/modules/summarySettings/types';

interface OwnProps {
  data: ActionEventSidebarDefinition['data'];
}

export const SidebarActionEvent = ({ data: { id } }: OwnProps) => {
  const [showEmptyFields, setShowEmptyFields] = useState(false);

  const data = useSelector(selectActionEvent);
  const loading = useSelector(selectActionEventLoading);
  const evaluationLog = useSelector(selectEvaluationLog);
  const fraudTransactionRulesEnabled = useSelector(
    selectFraudTransactionRulesEnabled,
  );
  const dispatch = useDispatch();
  const { push } = useHistory();

  const isRTREnabled = useSelector(selectRealTimeRulesEnabled);
  useEffect(() => {
    dispatch(retrieveActionEvent(id));
  }, [id, dispatch]);

  useEffect(() => {
    if ((fraudTransactionRulesEnabled || isRTREnabled) && data.external_id) {
      dispatch(clearEvaluationLog());
      dispatch(retrieveEvaluationLogThunk(data.external_id));
    }
  }, [fraudTransactionRulesEnabled, isRTREnabled, data.external_id, dispatch]);

  const isOnActionEventDetails = Boolean(
    useRouteMatch(ROUTES_MAP.dataExplorerActionEventsId.path),
  );

  return (
    <U21SideMenu
      onClose={() => {
        if (isOnActionEventDetails) {
          push(ROUTES_MAP.dataExplorerActionEvents.path);
        }
      }}
      footerLeftContent={
        <U21Button
          onClick={() => setShowEmptyFields((prev) => !prev)}
          variant="ghost"
        >
          {showEmptyFields ? 'Hide' : 'Show'} Empty Fields
        </U21Button>
      }
      loading={loading}
      noPadding
      title="Action Event Details"
    >
      <U21Spacer spacing={0.5}>
        <SidebarSummary
          classifier={SummaryViewConfigTypeClassifier.ACTION_EVENT}
          showEmptyFields={showEmptyFields}
          details={data}
        />
        <SidebarEvaluationLogInfo evaluationLog={evaluationLog} />
      </U21Spacer>
    </U21SideMenu>
  );
};
