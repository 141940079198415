import moment from 'moment';

// Components
import { U21Typography } from 'app/shared/u21-ui/components';
import { U21DynamicColorChip } from 'app/shared/u21-ui/components/dashboard';

// Types
import { ChainalysisContentField } from 'app/modules/alerts/types';

export const chainalysisAlertDetailsCellRenderer = (
  key: ChainalysisContentField,
  value: any,
) => {
  if (key === 'asset') {
    return <U21DynamicColorChip>{value}</U21DynamicColorChip>;
  }

  if (key === 'level') {
    return <U21DynamicColorChip>{value}</U21DynamicColorChip>;
  }

  if (key === 'category') {
    return <U21DynamicColorChip>{value.toUpperCase()}</U21DynamicColorChip>;
  }

  if (key === 'createdAt') {
    return (
      <U21Typography variant="body2">
        {moment(value).format('lll')}
      </U21Typography>
    );
  }

  if (key === 'direction') {
    return <U21DynamicColorChip>{value}</U21DynamicColorChip>;
  }

  if (key === 'timestamp') {
    return (
      <U21Typography variant="body2">
        {moment(value).format('lll')}
      </U21Typography>
    );
  }

  if (key === 'exposureType') {
    return <U21DynamicColorChip>{value}</U21DynamicColorChip>;
  }

  if (key === 'transferReportedAt') {
    return (
      <U21Typography variant="body2">
        {moment(value).format('lll')}
      </U21Typography>
    );
  }

  if (key === 'kytAlertUrl') {
    return (
      <a href={value} target="_blank" rel="noreferrer">
        View on Chainalysis
      </a>
    );
  }

  return value;
};
