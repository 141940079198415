import permissions from 'app/shared/utils/permissions';

// Components
import { InvestigationChecklist } from 'app/modules/investigations/components/InvestigationTabs/InvestigationChecklist';
import { NetworkAnalysis } from 'app/modules/alerts/components/AlertDetails/NetworkAnalysis';
import { InvestigationChecklistLabel } from 'app/modules/investigations/components/InvestigationTabs/InvestigationChecklistLabel';
import { NetworkAnalysisLabel } from 'app/modules/alerts/components/AlertDetails/Tabs/NetworkAnalysisLabel';

// Types
import {
  AlertDetailsTab,
  AdditionalFilters,
  DisabledOnReason,
  AlertType,
} from 'app/modules/alerts/types';
import { InvestigationType } from 'app/modules/investigations/models';

// Constants
import { transactionAlertTabs } from 'app/modules/alerts/components/AlertDetails/Tabs/transactionAlertTabs';
import {
  ALL_TYPES,
  TAB_PATHS,
} from 'app/modules/alerts/components/AlertDetails/Tabs/constants';
import { chainalysisAlertTabs } from 'app/modules/alerts/components/AlertDetails/Tabs/chainalysisAlertTabs';
import { darkWebAlertTabs } from 'app/modules/alerts/components/AlertDetails/Tabs/darkWebAlertTabs';
import { MatchSummary } from 'app/modules/watchlists/components/MatchSummary';
import { FinCEN314aMatchSummary } from 'app/modules/lists/components/FinCEN314aMatchSummary';
import { FeatureFlag } from 'app/shared/featureFlags/models';

const MATCH_SUMMARY_TAB = {
  label: 'Match Summary',
  path: 'match-summary',
  component: <MatchSummary />,
  visibleOn: {
    types: [AlertType.WATCHLIST],
    flags: [FeatureFlag.WATCHLIST_ALERTS],
  },
};

const INVESTIGATION_CHECKLIST_TAB = {
  label: <InvestigationChecklistLabel />,
  labelString: 'Investigation Checklist',
  path: TAB_PATHS.CHECKLIST,
  component: <InvestigationChecklist type={InvestigationType.ALERT} />,
  visibleOn: {
    types: ALL_TYPES,
    filters: [AdditionalFilters.INTUIT, AdditionalFilters.NEEDS_CHECKLIST],
  },
};

const NETWORK_ANALYSIS_TAB = {
  label: <NetworkAnalysisLabel />,
  labelString: 'Network Analysis',
  path: 'network-analysis',
  component: <NetworkAnalysis />,
  visibleOn: {
    types: ALL_TYPES,
    permissions: [permissions.readLinkAnalysis, permissions.readEntities],
    filters: [AdditionalFilters.INTUIT],
  },
  disabledOn: [DisabledOnReason.NO_NETWORK_ANALYSIS],
};

const FINCEN_314A_MATCH_SUMMARY_TAB = {
  label: 'Match Summary',
  path: 'match-summary',
  component: <FinCEN314aMatchSummary />,
  visibleOn: {
    types: [AlertType.FINCEN_314A],
    flags: [FeatureFlag.FINCEN_314A],
  },
};

export const DEFAULT_TM_TABS: AlertDetailsTab[] = [
  ...transactionAlertTabs,
  INVESTIGATION_CHECKLIST_TAB,
  NETWORK_ANALYSIS_TAB,
];

export const DEFAULT_WATCHLIST_TABS: AlertDetailsTab[] = [
  MATCH_SUMMARY_TAB,
  ...transactionAlertTabs,
  INVESTIGATION_CHECKLIST_TAB,
  NETWORK_ANALYSIS_TAB,
];

export const DEFAULT_CHAINALYSIS_TABS: AlertDetailsTab[] = [
  ...chainalysisAlertTabs,
  INVESTIGATION_CHECKLIST_TAB,
];

export const DEFAULT_DARK_WEB_TABS: AlertDetailsTab[] = [...darkWebAlertTabs];

export const DEFAULT_FINCEN_314A_TABS: AlertDetailsTab[] = [
  FINCEN_314A_MATCH_SUMMARY_TAB,
  ...transactionAlertTabs,
  INVESTIGATION_CHECKLIST_TAB,
  NETWORK_ANALYSIS_TAB,
];
