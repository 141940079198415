import { FullAttachmentResponse } from 'app/modules/attachments/types';

import styled from 'styled-components';

import { U21Typography } from 'app/shared/u21-ui/components';
import { ReloadableIFrame } from 'app/modules/attachmentsRefresh/components/ReloadableIFrame';
import { U21ImageEditor } from 'app/shared/u21-ui/components/dashboard';

interface Props {
  file?: FullAttachmentResponse;
  isEditableImage?: boolean;
  isOverlayMode?: boolean;
  setParentOffsetCanvas?: (offsetCanvas: OffscreenCanvas) => void;
}

export const DocumentDisplay = ({
  file,
  isEditableImage = false,
  isOverlayMode,
  setParentOffsetCanvas,
}: Props) => {
  if (!file) {
    return null;
  }

  const {
    embed_url: url,
    mime_type: type,
    name,
    storage_resource: storageResource,
  } = file;

  if (!url) {
    return (
      <StyledU21Typography color="text.secondary">
        Preview unavailable
      </StyledU21Typography>
    );
  }

  if (storageResource === 'GDRIVE') {
    return <ReloadableIFrame src={url} title={name} />;
  }

  if (type.includes('image')) {
    if (isEditableImage) {
      return (
        <U21ImageEditor
          imgSrc={url}
          isCopy={isOverlayMode}
          setParentOffsetCanvas={setParentOffsetCanvas}
        />
      );
    }

    return (
      <ImgContainer>
        <img alt={name} src={url} />
      </ImgContainer>
    );
  }
  if (type === 'video/mp4') {
    return (
      <Video controls title={name}>
        <source src={url} type="video/mp4" />
      </Video>
    );
  }

  return (
    <ReloadableIFrame
      src={`https://docs.google.com/gview?embedded=true&url=${encodeURIComponent(
        url,
      )}`}
      showLoading
      maxTries={10}
      maxTimeoutMs={10000}
      title={name}
    />
  );
};

const StyledU21Typography = styled(U21Typography)`
  justify-content: center;
`;

const Video = styled.video`
  width: 100%;
`;

const ImgContainer = styled.div`
  overflow: auto;
`;
