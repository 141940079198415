import { AnalyticsEvents, EventConfig } from 'app/shared/u21-ui/analytics';
import { U21NestedMenuProps } from 'app/shared/u21-ui/components';

const click: EventConfig = {
  name: 'Menu Item Clicked',
  createPayload: (props: U21NestedMenuProps) => {
    const {
      item: { text },
    } = props;
    if (typeof text === 'string' || typeof text === 'number') {
      return { name: text };
    }
    return null;
  },
};

export const u21MenuItemEvents = {
  [AnalyticsEvents.U21MENUITEM_ON_CLICK]: click,
};
