import { PaginationPayload, TableConfig } from 'app/shared/pagination/models';
import {
  U21TableColumn,
  U21TableColumnTypesType,
  U21TableState,
} from 'app/shared/u21-ui/components';

export const createTableColumnConfig = <T extends object>(
  columns: TableConfig[],
  additionalConfig: Record<string, Partial<U21TableColumn<T>>> = {},
): U21TableColumn<T>[] => {
  return columns.reduce((acc, i) => {
    if (i.hidden) {
      return acc;
    }
    const { key, label, sortable = true, type, ...restColumnProps } = i;
    let additionalProps = additionalConfig[i.key];
    // for table columns with a column constant that also utilize DataSettingDataDisplay
    // e.g. entity.type
    if (additionalProps && restColumnProps.Cell) {
      // remove the `Cell` prop so DataSettingDataDisplay doesn't override custom rendering logic
      const { Cell, accessor, ...restColumnPropsWithoutCell } = restColumnProps;
      additionalProps = { ...restColumnPropsWithoutCell, ...additionalProps };
    } else {
      additionalProps = { ...restColumnProps, ...additionalProps };
    }
    return [
      ...acc,
      {
        accessor: key,
        id: key,
        disableSortBy: !sortable,
        Header: label,
        type: type as U21TableColumnTypesType,
        ...additionalProps,
      },
    ];
  }, []);
};

export const createPaginationPayload = (
  state: U21TableState,
): PaginationPayload => {
  const { page, pageSize, sortBy } = state;
  const { desc, id } = sortBy[0] ?? {};
  return {
    limit: pageSize,
    offset: page,
    sort_column: id,
    sort_direction: desc ? 'descending' : 'ascending',
  };
};
