// Models
import {
  FiltersActionTypes,
  LinkAnalysisFilters,
} from 'app/modules/filtersOld/models';

// Utils
import { ActionsUnion, createAction } from 'app/shared/utils/action-helpers';

export const setLinkAnalysisFilters = (payload: LinkAnalysisFilters) =>
  createAction(FiltersActionTypes.SET_LINK_ANALYSIS_FILTERS, payload);

// For when we only want to modify some part of the filters without providing the entire object
export const modifyLinkAnalysisFilters = (
  payload: Partial<LinkAnalysisFilters>,
) => createAction(FiltersActionTypes.MODIFY_LINK_ANALYSIS_FILTERS, payload);

const FiltersActionsList = {
  setLinkAnalysisFilters,
  modifyLinkAnalysisFilters,
};

export type FiltersActions = ActionsUnion<typeof FiltersActionsList>;

export default FiltersActions;
