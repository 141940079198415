import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { get } from 'app/shared/utils/fetchr';
import { ALERT_QUERY_KEYS } from 'app/modules/alerts/queries/keys';
import { selectHasReadAlertsPermission } from 'app/modules/session/selectors';

const getAlertDispositionByAlertIds = (
  alertIds: number[],
): Promise<Record<number, string>> =>
  get<Record<number, string>>(`/alert/disposition?ids=${alertIds.toString()}`);

export const useGetAlertDispositionByAlertIds = (alertIds: number[]) => {
  const hasReadAlertsPermission = useSelector(selectHasReadAlertsPermission);
  return useQuery({
    queryKey: ALERT_QUERY_KEYS.getAlertDispositionByAlertIds(alertIds),
    queryFn: () => getAlertDispositionByAlertIds(alertIds),
    meta: { errorMessage: 'Failed to get alert dispositions.' },
    initialData: {},
    enabled: hasReadAlertsPermission && alertIds.length > 0,
  });
};
