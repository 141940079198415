import { ShortAgentResponse } from 'app/modules/agentsOld/types';
import { getAgentDisplayName } from 'app/modules/agents/utils';

export const byAgentNameHelper = (agent: ShortAgentResponse) => {
  const displayName = getAgentDisplayName(agent);

  if (displayName === 'None' || !displayName) {
    return '';
  }

  return `by ${displayName}`;
};
