import styled from 'styled-components';

// Components
import {
  U21Button,
  U21ModalBase,
  U21ModalBody,
  U21ModalFooter,
  U21ModalHeader,
  U21NoData,
  U21Spacer,
} from 'app/shared/u21-ui/components';
import { DocumentDisplay } from 'app/modules/attachmentsRefresh/components/DocumentDisplay';
import { DocumentList } from 'app/modules/attachmentsRefresh/components/DocumentList';

// Utils
import { FullAttachmentResponse } from 'app/modules/attachments/types';
import { IconDownload } from '@u21/tabler-icons';
import { downloadAttachmentThunk } from 'app/modules/attachmentsRefresh/sliceAttachments';
import { useDispatch } from 'react-redux';

type OwnProps = {
  documents: FullAttachmentResponse[];
  isOpen: boolean;
  selectedFileIdx: number;
  setIsOpen: (newIsOpen: boolean) => void;
  setSelectedFileIdx: (newIdx: number) => void;
  handleOnDelete: () => void;
};

export const InstrumentsDocumentModal = ({
  documents,
  isOpen,
  selectedFileIdx,
  setIsOpen,
  setSelectedFileIdx,
  handleOnDelete,
}: OwnProps) => {
  const dispatch = useDispatch();

  return (
    <StyledModaBase open={isOpen} size="fullscreen">
      <U21ModalHeader
        onClose={() => {
          setIsOpen(false);
        }}
      >
        View Document
      </U21ModalHeader>
      <StyledU21ModalBody>
        <StyledSpacer spacing={12}>
          {documents.length > 0 ? (
            <StyledSpacer spacing={2} horizontal align="start">
              <DocumentList
                title="Instrument Documents"
                files={documents}
                selectedFileIdx={selectedFileIdx}
                setSelectedFileIdx={setSelectedFileIdx}
                handleOnDelete={handleOnDelete}
              />
              <DocumentSpacer spacing={2}>
                <DocumentDisplay
                  file={documents[selectedFileIdx]}
                  isEditableImage
                />
              </DocumentSpacer>
            </StyledSpacer>
          ) : (
            <U21NoData />
          )}
        </StyledSpacer>
      </StyledU21ModalBody>
      <U21ModalFooter>
        <U21Button
          onClick={() => {
            setIsOpen(false);
          }}
        >
          Close
        </U21Button>
        <U21Button
          startIcon={<IconDownload />}
          onClick={() => {
            dispatch(
              downloadAttachmentThunk({
                id: documents[selectedFileIdx].id,
                fileName: documents[selectedFileIdx].name,
              }),
            );
          }}
        >
          Download
        </U21Button>
      </U21ModalFooter>
    </StyledModaBase>
  );
};

const StyledModaBase = styled(U21ModalBase)`
  .MuiDialog-container {
    .MuiDialog-paper {
      overflow-y: scroll;
    }
  }
`;

const StyledSpacer = styled(U21Spacer)<{ $hidden?: boolean }>`
  display: ${(props) => (props.$hidden ? 'none' : 'flex')};
  width: 100%;
  height: 100%;
`;

const DocumentSpacer = styled(U21Spacer)`
  height: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const StyledU21ModalBody = styled(U21ModalBody)`
  width: 100%;
`;
