import React, { FC, useState, useCallback } from 'react';

// Components
import {
  U21Modal,
  U21Spacer,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { U21RichTextEditor } from 'app/shared/components/RichTextEditor/U21RichTextEditor';
import { U21NarrativeTemplateSelect } from 'app/shared/u21-ui/components/dashboard/U21NarrativeTemplateSelect';
import { IconPlus } from '@u21/tabler-icons';

// Models
import { NarrativeTemplateType } from 'app/modules/narrativeTemplates/models';

// Selectors
import { useSelector } from 'react-redux';
import { selectSearchNarrativeTemplates } from 'app/modules/narrativeTemplates/selectors';

// Utils
import emptyFn from 'app/shared/utils/empty-fn';

interface AddNarrativeModalProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: (
    narrativeTemplate: string | undefined,
    narrativeTemplateId: number | undefined,
  ) => void;
  queueId: number | null;
  type: NarrativeTemplateType;
}

export const AddNarrativeModal: FC<AddNarrativeModalProps> = ({
  open,
  handleClose,
  handleSubmit,
  queueId,
  type,
}) => {
  const searchNarrativeTemplates = useSelector(
    selectSearchNarrativeTemplates(type),
  );

  const [selectedTemplate, setSelectedTemplate] = useState<string | undefined>(
    undefined,
  );
  const [selectedTemplateId, setSelectedTemplateId] = useState<
    number | undefined
  >(undefined);

  const handleOnChange = useCallback(
    (val: number) => {
      const narrativeTemplate = searchNarrativeTemplates.find(
        (template) => template.id === val,
      );

      setSelectedTemplateId(val);
      setSelectedTemplate(narrativeTemplate?.content);
    },
    [searchNarrativeTemplates],
  );

  return (
    <U21Modal
      open={open}
      title="Add Narrative"
      onAction={() => {
        handleSubmit(selectedTemplate, selectedTemplateId);
      }}
      onExited={() => {
        setSelectedTemplate(undefined);
        setSelectedTemplateId(undefined);
      }}
      onClose={handleClose}
      actionButtonProps={{
        children: selectedTemplate
          ? 'Add narrative from template'
          : 'Add blank narrative',
        color: 'primary',
        startIcon: <IconPlus />,
      }}
    >
      <U21Spacer spacing={4}>
        <U21Typography>
          Create a blank narrative or start from a template
        </U21Typography>
        <U21NarrativeTemplateSelect
          queueID={queueId}
          onChange={handleOnChange}
          value={selectedTemplateId}
          type={type}
        />
        {Boolean(selectedTemplate) && (
          <U21Spacer>
            <U21Typography variant="h6">Preview template</U21Typography>
            <U21RichTextEditor
              onChange={emptyFn}
              value={selectedTemplate}
              disabled
            />
          </U21Spacer>
        )}
      </U21Spacer>
    </U21Modal>
  );
};
