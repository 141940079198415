import { useQuery } from '@tanstack/react-query';

import { WATCHLIST_QUERY_KEYS } from 'app/modules/watchlists/queries/keys';

import { get } from 'app/shared/utils/fetchr';
import { FetchWatchlistConfigResponse } from 'app/modules/watchlists/responses';

const fetchWatchlistConfig = (
  provider: string,
): Promise<FetchWatchlistConfigResponse> =>
  get(`/watchlists/providers/${provider}/config`);

export const useFetchWatchlistConfig = (
  provider: string,
  isConfigPreview: boolean = false,
) => {
  return useQuery({
    enabled: Boolean(provider) && !isConfigPreview,
    queryKey: WATCHLIST_QUERY_KEYS.fetchWatchlistConfig(provider),
    queryFn: () => fetchWatchlistConfig(provider),
    meta: { errorMessage: 'Failed to get watchlist view config.' },
  });
};
