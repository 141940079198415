import { selectFormatAmount } from 'app/modules/orgSettings/selectors';
import {
  U21Loading,
  U21Spacer,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { useSelector } from 'react-redux';

interface TotalAmountCardProps {
  amount?: number;
  isLoading: boolean;
}

export const TotalAmountCard = ({
  amount,
  isLoading,
}: TotalAmountCardProps) => {
  const formatAmount = useSelector(selectFormatAmount);

  return isLoading ? (
    <U21Loading loading label="" />
  ) : (
    <U21Spacer horizontal>
      <U21Typography variant="subtitle1">
        Total Value of Transactions:
      </U21Typography>
      <U21Typography variant="body1">
        {formatAmount({ amount: amount ?? -1 })}
      </U21Typography>
    </U21Spacer>
  );
};
