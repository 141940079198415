import { AlertDetails } from 'app/modules/alerts/models';
import { FormFieldSchema } from 'app/shared/models/form';
import { FullAttachmentResponse } from 'app/modules/attachments/types';
import { NewCaseDetails } from 'app/modules/cases/models';
import { U21FormFieldTypes } from 'app/shared/u21-ui/components';

export const INVESTIGATION_DOCUMENTS: FormFieldSchema<
  FullAttachmentResponse[]
> = {
  name: 'attachments',
  type: U21FormFieldTypes.FILE_UPLOADER,
  default: (investigation: AlertDetails | NewCaseDetails) =>
    investigation.attachments ?? [],
};
