import { useMemo } from 'react';

// Components
import { IconScale } from '@u21/tabler-icons';
import {
  U21Button,
  U21Chip,
  U21ChipProps,
  U21Spacer,
  U21Subsection,
  U21Typography,
} from 'app/shared/u21-ui/components';
import styled from 'styled-components';

// Models
import { EvaluationLog, EvaluationLogStatus } from 'app/shared/events/models';

// Utils
import { ROUTES_MAP } from 'app/shared/utils/routes';
import { startCase } from 'lodash';
import { CopyToClipboard } from 'react-copy-to-clipboard';

interface Props {
  evaluationLog: EvaluationLog;
}

const STATUS_COLOR: Record<EvaluationLogStatus, U21ChipProps['color']> = {
  PASS: 'success',
  FAIL: 'error',
  ERROR: 'default', // system error color, need dev to investigate
};

export const SidebarEvaluationLogInfo = ({ evaluationLog }: Props) => {
  const ruleEvaluations = useMemo(
    () =>
      Object.keys(evaluationLog.rule_executions).map((ruleId) => {
        const ruleEvaluation = evaluationLog.rule_executions[ruleId];
        return {
          id: ruleId,
          name: ruleEvaluation.rule_name,
          status: ruleEvaluation.status,
        };
      }),
    [evaluationLog.rule_executions],
  );

  const failed = useMemo(
    () => ruleEvaluations.filter((e) => e.status !== 'PASS').length,
    [ruleEvaluations],
  );

  if (!evaluationLog.evaluation_id) return null;

  return (
    <U21Subsection
      collapsed
      collapsible
      shaded
      title={
        <U21Spacer horizontal>
          <span>Rules triggered</span>
          <U21Chip>{ruleEvaluations.length}</U21Chip>
          <NonTransformedTypography variant="body2">
            ({failed} out of {ruleEvaluations.length} failed)
          </NonTransformedTypography>
        </U21Spacer>
      }
      titleIcon={<IconScale />}
    >
      <U21Spacer spacing={1}>
        {ruleEvaluations.map((ruleEvaluation) => {
          return (
            <SpaceBetweenDiv key={ruleEvaluation.id}>
              <EllipsisChip
                variant="filled"
                color="default"
                icon={<IconScale />}
                to={ROUTES_MAP.detectionModelsId.path.replace(
                  ':id',
                  String(ruleEvaluation.id),
                )}
                tooltip={
                  <U21Spacer>
                    <U21Spacer align="start" horizontal>
                      <StyledTypography color="inherit" variant="body2">
                        Name:
                      </StyledTypography>
                      <U21Typography color="inherit" variant="body2">
                        {ruleEvaluation.name}
                      </U21Typography>
                    </U21Spacer>
                    <U21Spacer align="start" horizontal>
                      <StyledTypography color="inherit" variant="body2">
                        ID:
                      </StyledTypography>
                      <U21Spacer horizontal spacing={4}>
                        <U21Typography color="inherit" variant="body2">
                          {ruleEvaluation.id}
                        </U21Typography>
                        <CopyToClipboard text={ruleEvaluation.id}>
                          <U21Button
                            variant="text"
                            onClick={(e) => e.stopPropagation()}
                          >
                            Copy ID
                          </U21Button>
                        </CopyToClipboard>
                      </U21Spacer>
                    </U21Spacer>
                  </U21Spacer>
                }
              >
                {ruleEvaluation.name}
              </EllipsisChip>
              <PaddedChip
                disabled={ruleEvaluation.status === 'ERROR'}
                color={STATUS_COLOR[ruleEvaluation.status]}
              >
                {startCase(ruleEvaluation.status.toLowerCase())}
              </PaddedChip>
            </SpaceBetweenDiv>
          );
        })}
      </U21Spacer>
    </U21Subsection>
  );
};

const NonTransformedTypography = styled(U21Typography)`
  text-transform: none;
`;

const PaddedChip = styled(U21Chip)`
  margin-left: 10px;
`;

const EllipsisChip = styled(U21Chip)`
  overflow: hidden;
`;

const SpaceBetweenDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledTypography = styled(U21Typography)`
  min-width: 50px;
  justify-content: flex-end;
`;
