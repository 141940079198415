import { useState } from 'react';

// Components
import { TxnDocumentModal } from 'app/modules/transactions/components/TxnDocumentModal';
import { U21Chip, U21ShowMoreList } from 'app/shared/u21-ui/components';

// Models
import { ShortTxnResponse } from 'app/modules/transactions/types';
import { FullAttachmentResponse } from 'app/modules/attachments/types';

// Utils
import { parseOutFilename } from 'app/modules/attachmentsRefresh/utils';

type OwnProps = {
  txnEvent: ShortTxnResponse;
  handleOnDelete?: () => void;
};

export const TxnDocumentChipList = ({ txnEvent, handleOnDelete }: OwnProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedFileIdx, setSelectedFileIdx] = useState<number>(0);

  return (
    <>
      <U21ShowMoreList
        limit={2}
        displayFunc={(file: FullAttachmentResponse, index: number) => (
          <U21Chip
            key={file.id}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedFileIdx(index);
              setIsModalOpen(true);
            }}
          >
            {parseOutFilename(file.name)[0]}
          </U21Chip>
        )}
        onLessClick={(e) => e.stopPropagation()}
        onMoreClick={(e) => e.stopPropagation()}
        value={txnEvent.documents}
      />
      <TxnDocumentModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        txnEvent={txnEvent}
        selectedFileIdx={selectedFileIdx}
        setSelectedFileIdx={setSelectedFileIdx}
        handleOnDelete={handleOnDelete}
      />
    </>
  );
};
