import styled from 'styled-components';
import { formatDistanceToNow } from 'date-fns';
import { MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import {
  U21Button,
  U21TableColumnTypes,
  U21Typography,
} from 'app/shared/u21-ui/components';
import {
  IconClock,
  IconEye,
  IconMail,
  IconMailOpened,
} from '@u21/tabler-icons';

// Models
import {
  NotificationStatus,
  NotificationTableColumnProps,
} from 'app/modules/notifications/models';

// Constants
import { NOTIFICATION_ICON_MAPPING } from 'app/modules/notifications/constants';

// Selectors
import { selectAgent } from 'app/modules/session/selectors';

// Thunks
import { readNotificationThunk } from 'app/modules/notifications/sliceNotifications';

const NOTIFICATIONS_VIEWED = {
  id: 'notifications_viewed',
  Header: () => {
    return <StyledIconEye />;
  },
  Cell: (props: NotificationTableColumnProps) => {
    const { row } = props;
    const { status } = row;

    return (
      <CenteringContainer>
        {status === NotificationStatus.UNREAD ? <BlueDot /> : null}
      </CenteringContainer>
    );
  },
  maxWidth: 10,
  disableResizing: true,
  disableSortBy: true,
};

const NOTIFICATIONS_TITLE = {
  id: 'notifications_content',
  accessor: 'content',
  Header: 'Title',
  cellProps: (row) => ({
    icon: NOTIFICATION_ICON_MAPPING[row.entity_type],
  }),
  width: 300,
  disableSortBy: true,
};

export const NOTIFICATIONS_TIME = {
  id: 'notifications_created_at',
  accessor: 'created_at',
  Header: 'Time',
  headerIcon: <IconClock />,
  Cell: (props: NotificationTableColumnProps) => {
    const { row } = props;
    const { created_at: createdAt } = row;

    return (
      <U21Typography>{formatDistanceToNow(new Date(createdAt))}</U21Typography>
    );
  },
  disableSortBy: true,
};

export const NOTIFICATIONS_STATUS = {
  type: U21TableColumnTypes.BUTTON,
  id: 'notifications_status',
  accessor: 'status',
  Header: 'Mark as read',
  Cell: (props: NotificationTableColumnProps) => {
    const dispatch = useDispatch();
    const agent = useSelector(selectAgent);

    const { row } = props;
    const { status, id } = row;

    const handleOnClick = (e: MouseEvent<HTMLButtonElement>) => {
      dispatch(
        readNotificationThunk({
          agent_id: agent.id,
          notification_id: id,
        }),
      );
      e.stopPropagation();
    };

    return (
      <CenteringContainer>
        <U21Button
          icon={
            status === NotificationStatus.UNREAD ? (
              <IconMail />
            ) : (
              <IconMailOpened />
            )
          }
          color={status === NotificationStatus.UNREAD ? 'primary' : undefined}
          disabled={status !== NotificationStatus.UNREAD}
          onClick={handleOnClick}
          aria-label="Mark as read"
        />
      </CenteringContainer>
    );
  },
  minWidth: 100,
  disableSortBy: true,
  disableResizing: true,
};

export const NOTIFICATIONS_COLUMNS = [
  NOTIFICATIONS_VIEWED,
  NOTIFICATIONS_TITLE,
  NOTIFICATIONS_TIME,
  NOTIFICATIONS_STATUS,
];

const StyledIconEye = styled(IconEye)`
  /* hardcoded to center it inside the 50px container*/
  margin-left: 9px;
`;

const BlueDot = styled.div`
  height: 10px;
  width: 10px;
  background-color: ${(props) => props.theme.palette.primary.main};
  border-radius: 50%;
  display: inline-block;
`;

const CenteringContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
