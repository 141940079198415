import { get } from 'lodash';

// Constants
import { FEATURE_DESCRIPTION } from 'app/modules/alertScore/constants';

// Models
import { VariableDescription } from 'app/modules/mlInsights/models';

export const getFeatureDescription = (opts) => {
  try {
    const dataPoint = get(
      opts,
      `w.config.series[${opts.seriesIndex}].data[${opts.dataPointIndex}]`,
      {},
    );

    // Try to use the description obtained from the BE
    const formattedName: VariableDescription | undefined = get(
      dataPoint,
      'formattedName',
    );
    if (formattedName?.description) {
      return formattedName.description;
    }

    // Fallback to default logic, hardcoded map of descriptions
    const featureName: string = dataPoint.x || '';
    if (`${featureName}`.includes('_CUSTOM_DATA_')) {
      return (
        'This feature has been derived from the values submitted from your custom data.' +
        ' Please refer to the name of this feature for the relevant custom_data attribute.'
      );
    }
    return FEATURE_DESCRIPTION[featureName] || '';
  } catch {
    return '';
  }
};
