import { QueueAccessType } from 'app/modules/queues/models';

export const isQueueAccessible = (
  hasReadAssignmentsPermission: boolean,
  accessibleQueues: number[],
  queueId: number | null,
  queueAccessType?: `${QueueAccessType}`,
): boolean => {
  // If agent is an admin, all queues are accessible to them.
  if (hasReadAssignmentsPermission) {
    return true;
  }
  // If `queueAccessType` is undefined (object is not an article and not subject to queue access)
  // or `OPEN` (article is in an open queue accessible to all agents), return true.
  if (
    queueAccessType === undefined ||
    queueAccessType === QueueAccessType.OPEN
  ) {
    return true;
  }
  // If `queueId` is undefined (article with no queue is assumed to be in the Default Queue)
  // or included in `accessibleQueues` (article is in a queue that agent has access to), return true.
  if (queueId === null || accessibleQueues.includes(queueId)) {
    return true;
  }

  return false;
};
