import { useMemo } from 'react';
import { useSelector } from 'react-redux';

// Components
import { U21Section } from 'app/shared/u21-ui/components';

// Types
import { AlertType, ChainalysisContentField } from 'app/modules/alerts/types';

// Selectors
import { selectAlert } from 'app/modules/alerts/selectors';

// Utils
import { chainalysisAlertDetailsCellRenderer } from 'app/modules/alerts/components/AlertDetails/ChainalysisSummary/utils';

// Constants
import { CHAINALYSIS_ALERT_DETAILS_TABLE_CONFIG } from 'app/modules/alerts/components/AlertDetails/ChainalysisSummary/constants';
import { Table } from 'semantic-ui-react';

export const ChainalysisAlertDetails = () => {
  const alert = useSelector(selectAlert);
  const { content } = alert;

  // Filter out columns that don't have anything to show
  const columns = CHAINALYSIS_ALERT_DETAILS_TABLE_CONFIG.filter(
    (column) => !!content[column.key],
  );

  const chainalysisRows = useMemo(() => {
    return columns.map((key) => ({
      label: key.label,
      value: content[key.key],
      key: key.key as ChainalysisContentField,
    }));
  }, [columns, content]);

  // Return null if not a Chainalysis alert or there aren't columns to render
  if (alert.alert_type !== AlertType.CHAINALYSIS || !columns.length) {
    return null;
  }

  return (
    <U21Section title="Chainalysis Alert Details">
      <Table>
        <Table.Body>
          {chainalysisRows.map((row) => (
            <Table.Row key={`${row.label}`}>
              <Table.Cell>
                <strong>{row.label}</strong>
              </Table.Cell>
              <Table.Cell>
                {chainalysisAlertDetailsCellRenderer(row.key, row.value)}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </U21Section>
  );
};
