import { DeadlineType } from 'app/modules/deadlines/models';
import { QueueType } from 'app/modules/queues/models';

// query result types

export type AuditTrailAssetType =
  | 'team'
  | 'agent'
  | 'entity'
  | 'case'
  | 'alert'
  | 'sar'
  | 'goaml'
  | 'fincen_ctr'
  | 'rule'
  | 'risk_model'
  | 'instrument';

// Audit log data types

type AuditLogActorType = 'api' | 'system' | 'agent' | 'rule' | 'sar';

export type AuditLogActor = {
  id: number | string;
  name: string;
  type: AuditLogActorType;
  isMissing?: boolean;
};

export const VALID_AUDIT_LOG_ASSET_TYPES = [
  'action_event',
  'agent',
  'alert',
  'attachment',
  'case',
  'deadline',
  'disposition',
  'entity',
  'fincen_ctr',
  'fincen_ctr_batch',
  'goaml',
  'instrument',
  'investigation_checklist',
  'matchlist',
  'narrative',
  'narrative_template',
  'permission',
  'queue',
  'risk_model',
  'risk_score',
  'rule',
  'sar',
  'sar_template',
  'subdisposition',
  'subdisposition_option',
  'tag',
  'team',
  'txn_event',
  'webhook',
  'workflow_action',
] as const;

type AuditLogAssetType = (typeof VALID_AUDIT_LOG_ASSET_TYPES)[number];

type BasicAuditLogAsset = {
  id: number | string;
  name: string;
  type: AuditLogAssetType;
  isMissing?: boolean;
};

type DeadlineAuditCustomData = {
  type: DeadlineType;
  date: string;
};

// AuditLogAsset when type === 'deadline'
export type DeadlineAuditLogAsset = {
  id: number;
  name: string;
  type: 'deadline';
  isMissing?: boolean;
  custom_data: DeadlineAuditCustomData;
};

export function isDeadlineAuditLogAsset(
  asset: AuditLogAsset,
): asset is DeadlineAuditLogAsset {
  return asset.type === 'deadline' && Object.hasOwn(asset, 'custom_data');
}

export type AuditLogAsset = BasicAuditLogAsset | DeadlineAuditLogAsset;

export interface AuditServiceEntry {
  action: string;
  actor: AuditLogActor;
  asset: AuditLogAsset;
  details: AuditLogAsset[];
  submittedAt: string;
  createdAt: string;
}

// Chip prop types

export type BasicChipProps = {
  id: number | string;
  name: string;
  isMissing?: boolean;
};

type TypedChips<A, T extends string> = A & { type: T };

export type GenericChipProps = TypedChips<{ text: string }, 'generic'>;

export type AssetsChipProps = TypedChips<BasicChipProps, AuditLogAssetType>;

export type ActorChipProps = TypedChips<BasicChipProps, AuditLogActorType>;

export type QueueChipProps = {
  queueType: QueueType;
} & BasicChipProps;

export type DateChipProps = {
  date: string;
};

export type DeadlineTypeChipProps = {
  deadlineType:
    | DeadlineType.ARTICLE_DEADLINE
    | DeadlineType.MANUAL_DEADLINE
    | DeadlineType.QUEUE_DEADLINE;
};

export type AssetChipComponentProps =
  | AssetsChipProps
  | ActorChipProps
  | TypedChips<QueueChipProps, 'queue_internal'>
  | TypedChips<DateChipProps, 'date'>
  | TypedChips<DeadlineTypeChipProps, 'deadline_internal'>
  | GenericChipProps;
