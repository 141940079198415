import { LinkAnalysisFilters } from 'app/modules/filtersOld/models';

export const FILTER_TO_LABEL: Record<keyof LinkAnalysisFilters, string> = {
  // Selectable
  selectedEntity: 'Entity',
  groupBy: 'Grouping',
  eventTypeShown: 'Coloring',

  // Checkbox filters
  showCurrentAlert: 'Only show transactions in this alert',
  showEntities: 'Entities',
  showCurrency: 'Currency',
  showInstruments: 'Instruments',

  // Used for storing the legend filters
  eventTypesShown: '',
};
