import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

// Components
import { IconArrowRight, IconFlag } from '@u21/tabler-icons';
import { U21SideMenu } from 'app/shared/u21-ui/components/dashboard';
import {
  U21Button,
  U21Chip,
  U21ShowMoreList,
  U21Spacer,
  U21Subsection,
  U21TitleCountLabel,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { EntityRiskScore } from 'app/modules/riskRatings/components/EntityRiskScore';
import { SidebarDataRow } from 'app/modules/sidebar/components/utils/SidebarDataRow';
import { SidebarSummary } from 'app/modules/sidebar/components/utils/SidebarSummary';

// Models
import { EntitySidebarDefinition } from 'app/modules/sidebar/models';
import { EntityDetails } from 'app/modules/entities/models';
import { FullEntityResponse } from 'app/modules/entities/types';
import { SummaryViewConfigTypeClassifier } from 'app/modules/summarySettings/types';

// Utils
import { formatDate } from 'app/shared/utils/date';
import { sortByTime } from 'app/modules/dataExplorer/helpers';
import { ROUTES_MAP } from 'app/shared/utils/routes';

// Selectors
import { selectDispositionActions } from 'app/shared/featureFlags/selectors';

import { useGetEntityByExternalId } from 'app/modules/entitiesRefresh/queries/useGetEntityByExternalId';
import { useGetBlockedReasons } from 'app/modules/entitiesRefresh/queries/useGetBlockedReasons';
import { useFetchEntityRiskScore } from 'app/modules/riskRatings/queries/useFetchEntityRiskScore';

// Constants
import { EMPTY_ENTITY } from 'app/modules/entities/constants';
import { useEntityAlerts } from 'app/modules/entitiesRefresh/queries/useEntityAlerts';
import { SidebarCommentsCollapsible } from 'app/modules/sidebar/components/SidebarCommentsCollapsible';
import { SidebarDataLabels } from 'app/modules/sidebar/components/SidebarDataLabels';
import { useEntityDetails } from 'app/modules/entitiesRefresh/queries/useEntityDetails';
import { SidebarExternalLinkList } from 'app/modules/sidebar/components/SidebarExternalLinkList';

interface OwnProps {
  data: EntitySidebarDefinition['data'];
}

export const SidebarEntity = ({ data }: OwnProps) => {
  const [showEmptyFields, setShowEmptyFields] = useState(false);

  const isDispositionActionsEnabled = useSelector(selectDispositionActions);

  const {
    data: entityByUnit21Id = EMPTY_ENTITY,
    isLoading: entityByUnit21IdLoading,
  } = useEntityDetails(String(data.id));

  const {
    data: entityByExternalId = EMPTY_ENTITY,
    isLoading: entityByExternalIdLoading,
  } = useGetEntityByExternalId(data.externalId || '');

  const [entity, entityLoading] = useMemo((): [
    FullEntityResponse | EntityDetails,
    boolean,
  ] => {
    if (data.externalId) {
      return [entityByExternalId, entityByExternalIdLoading];
    }
    return [entityByUnit21Id, entityByUnit21IdLoading];
  }, [
    data.externalId,
    entityByExternalId,
    entityByExternalIdLoading,
    entityByUnit21Id,
    entityByUnit21IdLoading,
  ]);

  const { id, tags, comments, external_id: externalId } = entity;

  const tagIds = useMemo(() => tags.map((i) => i.id), [tags]);

  const { data: blockedReasonsResponse, isLoading: loadingBlockedReasons } =
    useGetBlockedReasons(externalId);

  const { blocked_reasons: blockedReasons } = blockedReasonsResponse || {};
  const isBlocked = blockedReasons && blockedReasons.length > 0;

  const { data: riskScore } = useFetchEntityRiskScore(externalId || '');

  const { data: entityAlerts } = useEntityAlerts(externalId, {
    limit: 100,
    offset: 1,
  });

  const idForButtonLink = data.id || id;

  return (
    <U21SideMenu
      actionButtonProps={{
        children: 'Go to Detail Page',
        endIcon: <IconArrowRight />,
        disabled: idForButtonLink === EMPTY_ENTITY.id,
        // data.id has higher priority than entity.id because data.id is immediately available, whereas entity.id only available on entity load.
        // But data.id is not available when the sidebar is shown from the alert's group-by-entity page, so we fall back to entity.id in this case.
        to: ROUTES_MAP.entitiesId.path.replace(':id', String(idForButtonLink)),
      }}
      footerLeftContent={
        <U21Button
          onClick={() => setShowEmptyFields((prev) => !prev)}
          variant="ghost"
        >
          {showEmptyFields ? 'Hide' : 'Show'} Empty Fields
        </U21Button>
      }
      loading={entityLoading}
      noPadding
      title="Entity Details"
    >
      <SidebarSummary
        classifier={SummaryViewConfigTypeClassifier.ENTITY}
        showEmptyFields={showEmptyFields}
        details={entity}
      />
      <U21Spacer spacing={0.5}>
        {riskScore?.riskModel && (
          <U21Subsection
            shaded
            title={
              <U21Spacer horizontal>
                <span>Risk Score</span>
                <EntityRiskScore entityExternalId={externalId} />
              </U21Spacer>
            }
          >
            {null}
          </U21Subsection>
        )}
        {isDispositionActionsEnabled && !loadingBlockedReasons && (
          <U21Subsection
            shaded
            collapsible={isBlocked}
            title={
              <U21Spacer horizontal>
                <span>Blocked</span>
                <U21Chip
                  color={isBlocked ? 'error' : 'success'}
                  variant="ghost"
                >
                  {isBlocked ? 'Yes' : 'No'}
                </U21Chip>
              </U21Spacer>
            }
          >
            {isBlocked && (
              <SidebarDataRow label="Reasons" showEmptyFields={showEmptyFields}>
                <U21ShowMoreList value={blockedReasons} />
              </SidebarDataRow>
            )}
          </U21Subsection>
        )}
        <U21Subsection
          collapsible
          shaded
          title={
            <U21TitleCountLabel count={entityAlerts?.count || 0} label="">
              Flagged Alerts
            </U21TitleCountLabel>
          }
          titleIcon={<IconFlag />}
        >
          {entityAlerts && entityAlerts.count > 0 ? (
            <SidebarExternalLinkList
              list={sortByTime(entityAlerts.alerts, 'start_date').map((i) => ({
                id: i.id,
                label: formatDate(i.start_date),
                route: ROUTES_MAP.alertsId.path.replace(':id', String(i.id)),
                value: i.title,
              }))}
            />
          ) : (
            <StyledU21Typography variant="caption" color="text.secondary">
              No flagged alerts
            </StyledU21Typography>
          )}
        </U21Subsection>
        <SidebarDataLabels ids={tagIds} />
        <SidebarCommentsCollapsible comments={comments} />
      </U21Spacer>
    </U21SideMenu>
  );
};

const StyledU21Typography = styled(U21Typography)`
  margin-bottom: 8px;
`;
