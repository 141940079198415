import { AiInvestigationResultsResponse } from 'app/modules/investigations/types/responses';

import { INVESTIGATION_QUERY_KEYS } from 'app/modules/investigations/queries/keys';

import { get } from 'app/shared/utils/fetchr';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { InvestigationType } from 'app/modules/investigations/models';
import { selectAiInvestigationsOrgFeatureEnabled } from 'app/modules/orgSettings/selectors';

export interface GetAiInvestigationResultsPayload {
  articleId: number;
  customChecklistSubmissionId?: number;
}

const getAiInvestigationResults = ({
  articleId,
  customChecklistSubmissionId,
}: GetAiInvestigationResultsPayload): Promise<AiInvestigationResultsResponse> => {
  const args = [{ key: 'article_id', value: String(articleId) }];
  if (customChecklistSubmissionId)
    args.push({
      key: 'custom_checklist_submission_id',
      value: String(customChecklistSubmissionId),
    });

  return get(`/ai-investigations/get-results`, args);
};

export const useAiInvestigationResults = (
  type: InvestigationType,
  { articleId, customChecklistSubmissionId }: GetAiInvestigationResultsPayload,
) => {
  const aiInvestigationsOrgFeatureEnabled: boolean = useSelector(
    selectAiInvestigationsOrgFeatureEnabled,
  );

  return useQuery({
    queryKey: INVESTIGATION_QUERY_KEYS.getAiInvestigationResult({
      article_id: articleId,
      custom_checklist_submission_id: customChecklistSubmissionId,
    }),
    queryFn: () =>
      getAiInvestigationResults({ articleId, customChecklistSubmissionId }),
    enabled:
      aiInvestigationsOrgFeatureEnabled &&
      type === InvestigationType.ALERT &&
      articleId > 0,
    refetchInterval: (query) => {
      if (
        query.state.data?.current_ai_investigations_results?.status !==
        'PENDING'
      ) {
        return false;
      }
      return 30 * 1000;
    },
  });
};
