import { createSelector } from 'reselect';
import { createTableColumnConfig } from 'app/shared/utils/table';
import { selectWatchlistTableConfig } from 'app/shared/CustomConfig/selectors';
import { WatchlistResult } from 'app/modules/watchlists/models';
import { WATCHLIST_COLUMN_CONFIG } from 'app/modules/watchlists/columns';
import { WATCHLISTS_SLICE_NAME } from 'app/modules/watchlists/sliceWatchlists';

export const selectWatchlistTableColumns = createSelector(
  [selectWatchlistTableConfig],
  (tableConfig) =>
    createTableColumnConfig<WatchlistResult>(
      tableConfig,
      WATCHLIST_COLUMN_CONFIG,
    ),
);

export const selectWatchlistFilters = (state: RootState) =>
  state[WATCHLISTS_SLICE_NAME].watchlistFilters;
