import { GetCtrsPayload } from 'app/modules/fincenCtr/requests';

const BASE = ['ctr-filings'] as const;

export const CTR_FILINGS_QUERY_KEYS = {
  base: BASE,
  getFilingsQueryKey: (body: GetCtrsPayload) => [...BASE, body] as const,
  getGroupedFilingsQueryKey: (body: GetCtrsPayload) =>
    [...BASE, 'grouped', body] as const,
};
