import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

// Components
import { IconFlagX } from 'app/shared/components/Icons/IconFlagX';
import { IconFlag } from '@u21/tabler-icons';

import {
  U21Spacer,
  U21ButtonGroup,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { U21FiltersBar } from 'app/shared/u21-ui/components/dashboard';

import { EntitySelect } from 'app/modules/linkAnalysis/components/ActivityAnalysis/EntitySelect';

// Selectors
import { selectAlertEntities } from 'app/modules/alerts/selectors';
import { selectLinkAnalysisFilters } from 'app/modules/filtersOld/selectors';

// Actions
import { modifyLinkAnalysisFilters } from 'app/modules/filtersOld/actions';

export const TransactionActivityFilters = () => {
  const dispatch = useDispatch();

  const { showCurrentAlert } = useSelector(selectLinkAnalysisFilters);

  const entities = useSelector(selectAlertEntities);
  const hasNoEntities = !entities.length;

  const handleButtonGroupChange = (newValue) => {
    dispatch(
      modifyLinkAnalysisFilters({
        showCurrentAlert: newValue,
      }),
    );
  };

  return (
    <U21FiltersBar title="Transaction filters">
      <StyledSpacer horizontal spacing={4}>
        <U21Spacer horizontal spacing={2}>
          <U21Typography variant="body2" color="grey.700">
            Transaction types
          </U21Typography>
          <U21ButtonGroup
            buttons={[
              {
                label: 'Flagged',
                value: true,
                startIcon: <IconFlag />,
                tooltip: 'Only show flagged transactions',
              },
              {
                label: 'All',
                value: false,
                startIcon: <IconFlagX />,
                tooltip: hasNoEntities
                  ? 'This alert has no entities to show events for'
                  : 'Show transactions related to the selected entity',
                disabled: hasNoEntities,
              },
            ]}
            value={showCurrentAlert}
            onClick={handleButtonGroupChange}
          />
        </U21Spacer>
        <EntitySelect />
      </StyledSpacer>
    </U21FiltersBar>
  );
};

const StyledSpacer = styled(U21Spacer)`
  margin-left: 16px;
`;
