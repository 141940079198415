import { useMemo } from 'react';

import { PopoverSectionProps } from 'app/modules/navigator/models';
import { OrgSwitcherResponse } from 'app/modules/orgSwitcher/types/responses';
import {
  endOrgSwitcher,
  startOrgSwitcher,
} from 'app/modules/orgSwitcher/sliceOrgSwitcher';

import styled from 'styled-components';
import { AccountPopoverSection } from 'app/modules/navigator/components/AccountPopoverSection';
import { IconArrowsLeftRight, IconUserX } from '@u21/tabler-icons';
import { U21Badge } from 'app/shared/u21-ui/components';
import {
  getOrgSwitcherAgentId,
  getOriginalAgentId,
} from 'app/shared/utils/sessionStorage';
import { useAppDispatch } from 'app/store/storeHooks';
import { AuthClient } from 'app/modules/session/models';
import { useHistory } from 'react-router';

interface OwnProps extends PopoverSectionProps {
  orgSwitcher: OrgSwitcherResponse;
  auth: AuthClient;
}

export const OrgSwitcherSection = ({
  orgSwitcher,
  auth,
  closePopover,
}: OwnProps) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const originalAgentId = getOriginalAgentId();
  const orgSwitcherAgentId = getOrgSwitcherAgentId();
  const currentlyOrgSwitched =
    orgSwitcherAgentId !== -1 && orgSwitcherAgentId !== originalAgentId;

  const { orgs: orgSwitcherOrgs } = orgSwitcher;

  const options = useMemo(
    () =>
      orgSwitcherOrgs.map((org) => ({
        key: org.display_name,
        text: (
          <>
            {org.display_name}
            {org.notification_count > 0 && (
              <StyledU21Badge color="error" content={org.notification_count} />
            )}
          </>
        ),
        icon: <IconArrowsLeftRight />,
        onClick: async () => {
          dispatch(startOrgSwitcher({ agentId: org.agent_id, auth, history }));
        },
        badge:
          org.notification_count > 0 ? (
            <StyledU21Badge color="error" content={org.notification_count} />
          ) : undefined,
      })),
    [auth, orgSwitcherOrgs, dispatch, history],
  );

  const cancelOption = useMemo(
    () => [
      {
        key: 'cancel',
        text: 'Switch back to parent org',
        icon: <IconUserX />,
        onClick: async () => {
          dispatch(endOrgSwitcher({ agentId: originalAgentId, auth, history }));
        },
      },
    ],
    [auth, dispatch, history, originalAgentId],
  );

  return (
    <AccountPopoverSection
      title="Switch Organizations"
      items={currentlyOrgSwitched ? cancelOption : options}
      closePopover={closePopover}
    />
  );
};

const StyledU21Badge = styled(U21Badge)`
  margin-left: auto;
`;
