import { SARSidebarDefinition } from 'app/modules/sidebar/models';
import { IconArrowRight, IconBuildingBank } from '@u21/tabler-icons';
import { REPORT_TYPE_PATH_MAP } from 'app/modules/fincenSar/constants/shared';
import { U21SideMenu } from 'app/shared/u21-ui/components/dashboard';
import { ReportType, SarStatus } from 'app/modules/fincenSar/models';
import { useGetFinCENSAR } from 'app/modules/fincenSarNew/queries/useGetFinCENSAR';
import {
  U21Chip,
  U21Subsection,
  U21Spacer,
  U21LoadError,
} from 'app/shared/u21-ui/components';
import styled from 'styled-components';
import { SidebarDataRow } from 'app/modules/sidebar/components/utils/SidebarDataRow';
import { StatusPill } from 'app/modules/fincenSarNew/components/StatusPill';
import { formatDatetime } from 'app/shared/utils/date';
import { SidebarCommentsCollapsible } from 'app/modules/sidebar/components/SidebarCommentsCollapsible';
import { SidebarAssociatedEntities } from 'app/modules/sidebar/components/SidebarAssociatedEntities';
import { SidebarAssociatedTransactions } from 'app/modules/sidebar/components/SidebarAssociatedTransactions';
import { SidebarAssociatedAlerts } from 'app/modules/sidebar/components/SidebarAssociatedAlerts';
import { SidebarAssociatedFilings } from 'app/modules/sidebar/components/SidebarAssociatedFilings';
import { ROUTES_MAP } from 'app/shared/utils/routes';
import { STATUS_TO_COLOR } from 'app/modules/investigations/constants';
import { useSelector } from 'react-redux';
import {
  selectAgent,
  selectHasReadAssignmentsPermissions,
  selectHasReadCasesPermissions,
} from 'app/modules/session/selectors';
import { isQueueAccessible } from 'app/modules/queues/utils';
import { selectClassifiedQueuesEnabled } from 'app/shared/featureFlags/selectors';
import { useMemo } from 'react';
import { sortCreatedAt, sortEventTime } from 'app/modules/sidebar/utils';
import { ArticleDeadlineChip } from 'app/modules/deadlines/components/ArticleDeadlineChip';
import { useQueryClient } from '@tanstack/react-query';
import { FINCEN_SAR_KEYS } from 'app/modules/fincenSarNew/queries/keys';

interface Props {
  data: SARSidebarDefinition['data'];
}

export const SidebarFinCENSAR = (props: Props) => {
  const {
    data: { id, hideActionButton },
  } = props;

  const hasReadCasesPermission = useSelector(selectHasReadCasesPermissions);
  const hasReadAssignmentsPermission = useSelector(
    selectHasReadAssignmentsPermissions,
  );
  const classifiedQueuesEnabled = useSelector(selectClassifiedQueuesEnabled);
  const sessionAgent = useSelector(selectAgent);

  const queryClient = useQueryClient();
  const { data: sar, isError, isLoading, refetch } = useGetFinCENSAR(id);

  const sortedAlerts = useMemo(() => {
    if (!sar) {
      return [];
    }
    return sortCreatedAt(sar.alerts);
  }, [sar]);

  const sortedFilings = useMemo(() => {
    if (!sar?.sars) {
      return [];
    }
    return sortCreatedAt(sar.sars);
  }, [sar]);

  const sortedEntities = useMemo(() => {
    if (!sar) {
      return [];
    }
    return sortCreatedAt(sar.entities);
  }, [sar]);

  const sortedTransactions = useMemo(() => {
    if (!sar) {
      return [];
    }
    return sortEventTime(sar.events);
  }, [sar]);

  return (
    <U21SideMenu
      actionButtonProps={
        hideActionButton
          ? undefined
          : {
              children: 'Go to Filing Page',
              endIcon: <IconArrowRight />,
              to: REPORT_TYPE_PATH_MAP[ReportType.FINCEN_SAR].replace(
                ':id',
                String(id),
              ),
            }
      }
      loading={isLoading}
      noPadding
      title="FinCEN SAR Details"
    >
      {(() => {
        // loading handled by U21SideMenu
        if (isLoading) {
          return null;
        }

        if (isError) {
          return (
            <U21LoadError label="FinCEN SAR" onTryAgain={() => refetch()} />
          );
        }

        if (!sar) {
          return null;
        }

        return (
          <U21Spacer spacing={0.5}>
            <U21Subsection
              shaded
              title={<Span>{sar.name}</Span>}
              titleIcon={<IconBuildingBank />}
            >
              <U21Spacer>
                <SidebarDataRow label="Assigned to" showEmptyFields>
                  {sar.reviewer?.full_name || sar.reviewer?.email}
                </SidebarDataRow>
                <SidebarDataRow label="Queue" showEmptyFields>
                  {sar.queue?.title}
                </SidebarDataRow>
                <SidebarDataRow label="Status" showEmptyFields>
                  <StatusPill status={sar.status} />
                </SidebarDataRow>
                <SidebarDataRow label="Deadline" showEmptyFields>
                  {sar.status !== SarStatus.ARCHIVED && !sar.submitted && (
                    <ArticleDeadlineChip
                      deadline={sar.deadline}
                      deadlineConfig={sar.deadline_config}
                      onEditSuccess={() => {
                        queryClient.invalidateQueries({
                          queryKey: FINCEN_SAR_KEYS.getFinCENSAR(id),
                        });
                      }}
                    />
                  )}
                </SidebarDataRow>
                <SidebarDataRow label="Case" showEmptyFields>
                  {sar.case && (
                    <U21Chip
                      color={STATUS_TO_COLOR[sar.case.status]}
                      to={
                        hasReadCasesPermission &&
                        (!classifiedQueuesEnabled ||
                          isQueueAccessible(
                            hasReadAssignmentsPermission,
                            sessionAgent.accessible_queues,
                            sar.case.queue_id,
                            sar.case.queue_access_type,
                          ))
                          ? ROUTES_MAP.casesId.path.replace(
                              ':id',
                              String(sar.case.id),
                            )
                          : undefined
                      }
                      variant={sar.case.status === 'OPEN' ? 'filled' : 'ghost'}
                    >
                      {sar.case.title}
                    </U21Chip>
                  )}
                </SidebarDataRow>
                <SidebarDataRow label="Created at" showEmptyFields>
                  {formatDatetime(sar.created_at)}
                </SidebarDataRow>
                <SidebarDataRow label="Filed at" showEmptyFields>
                  {formatDatetime(sar.filed_at)}
                </SidebarDataRow>
                <SidebarDataRow label="BSA tracking ID" showEmptyFields>
                  {sar.bsa_tracking_id}
                </SidebarDataRow>
                <SidebarDataRow label="BSA ID" showEmptyFields>
                  {sar.bsa_id}
                </SidebarDataRow>
              </U21Spacer>
            </U21Subsection>
            <SidebarAssociatedAlerts
              alerts={sortedAlerts}
              count={sortedAlerts.length}
            />
            <SidebarAssociatedFilings
              count={sortedFilings.length}
              filings={sortedFilings}
            />
            <SidebarAssociatedEntities
              count={sortedEntities.length}
              entities={sortedEntities}
            />
            <SidebarAssociatedTransactions
              count={sortedTransactions.length}
              transactions={sortedTransactions}
            />
            <SidebarCommentsCollapsible comments={sar.comments} />
          </U21Spacer>
        );
      })()}
    </U21SideMenu>
  );
};

const Span = styled.span`
  text-transform: none;
`;
