// Components
import { IconLock } from '@u21/tabler-icons';
import { U21Modal, U21Typography } from 'app/shared/u21-ui/components';

interface OwnProps {
  open: boolean;
  dismiss: () => void;
}

const LogoutModal = (props: OwnProps) => {
  const { open, dismiss } = props;

  return (
    <U21Modal
      title="You have been logged out"
      titleIcon={<IconLock />}
      open={open}
      onClose={dismiss}
      closeButtonProps={{
        children: 'Log In',
        color: 'primary',
      }}
    >
      <U21Typography variant="body1">
        For your security, you were logged out because your session was inactive
        for a while. Please log in again.
      </U21Typography>
    </U21Modal>
  );
};

export default LogoutModal;
