import moment from 'moment';

import { EntityExternalLinkModel } from 'app/modules/orgSettings/models';

// Models
import { EntitySummary } from 'app/modules/entities/models';

// Helpers
import { formatExternalUrl } from 'app/modules/entities/helpers';

export const sortByTime = (
  arr: any[],
  field: string | undefined = 'created_at',
) => {
  return [...arr].sort((itemA, itemB) =>
    moment(itemA[field]).isBefore(itemB[field]) ? -1 : 1,
  );
};

// This method returns the items that get passed into U21Table's actions
export const getExternalLinksActionsItems = (
  externalLinks: EntityExternalLinkModel[],
  entity: EntitySummary,
) => {
  return externalLinks.map((externalLink) => {
    return {
      text: externalLink.label,
      onClick: () => {
        window.open(
          formatExternalUrl(entity, externalLink),
          '_blank',
          'noopener,noreferrer',
        );
      },
    };
  });
};
