import { useQuery } from '@tanstack/react-query';
import { get } from 'app/shared/utils/fetchr';
import { AiSessionResponse } from 'app/modules/codeInterpreter/responses';
import { AI_CHAT_KEYS } from 'app/modules/codeInterpreter/queries/keys';

const getAllAiChatSession = (): Promise<AiSessionResponse[]> =>
  get<AiSessionResponse[]>(`/code-interpreter-session/list`);

export const useFetchAllAiChatSession = (
  isCodeInterpreterEnabled: boolean | undefined,
) => {
  return useQuery({
    enabled: isCodeInterpreterEnabled,
    queryKey: AI_CHAT_KEYS.getAllAiChatSessionQueryKey(),
    initialData: [],
    queryFn: () => getAllAiChatSession(),
    meta: { errorMessage: 'Failed to get chat sessions.' },
  });
};
