import { useLocation } from 'react-router';
import { matchPath, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Selectors
import {
  selectAgent,
  selectHasReadAlertsPermission,
  selectHasReadCasesPermissions,
  selectHasReadDataFileUploadsPermission,
} from 'app/modules/session/selectors';
import { selectCustomHomePage } from 'app/shared/CustomConfig/selectors';

// Helpers
import { isAuth0Callback } from 'app/shared/helpers';

// Constants
import {
  NOT_ALLOWED_BACK_ROUTES,
  ROUTES,
  ROUTES_MAP,
} from 'app/shared/utils/routes';
import { LocalStorageKeys } from 'app/shared/constants/localStorage';

export const Home = () => {
  const { hash } = useLocation();
  const hasReadAlertsPermission = useSelector(selectHasReadAlertsPermission);
  const hasReadCasesPermission = useSelector(selectHasReadCasesPermissions);
  const customHomePage = useSelector(selectCustomHomePage);
  const hasDatafileUploadsPermissions = useSelector(
    selectHasReadDataFileUploadsPermission,
  );

  const { id } = useSelector(selectAgent);

  // auth0 callback
  if (isAuth0Callback(hash)) {
    const route = localStorage.getItem(LocalStorageKeys.LAST_ROUTE);
    if (
      route &&
      !NOT_ALLOWED_BACK_ROUTES[route] &&
      ROUTES.find((i) => matchPath(route, i))
    ) {
      // remove after its used
      localStorage.removeItem(LocalStorageKeys.LAST_ROUTE);
      return <Redirect to={route} />;
    }
  }

  if (customHomePage) {
    return <Redirect to={customHomePage.path} />;
  }

  if (hasReadAlertsPermission) {
    return <Redirect to={ROUTES_MAP.alerts.path} />;
  } else if (hasDatafileUploadsPermissions) {
    return <Redirect to={ROUTES_MAP.dataExplorerUploadFiles.path} />;
  } else if (hasReadCasesPermission) {
    return <Redirect to={ROUTES_MAP.cases.path} />;
  }

  return <Redirect to={ROUTES_MAP.agentsId.path.replace(':id', String(id))} />;
};
