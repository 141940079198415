import { useMutation } from '@tanstack/react-query';
import { sendErrorToast, sendSuccessToast } from 'app/shared/toasts/actions';
import { post } from 'app/shared/utils/fetchr';
import { useDispatch } from 'react-redux';
import { ArticleDeadline } from 'app/modules/deadlines/models';

export interface EditArticleDeadlinePayload {
  article_id: number;
  duration: number; // In Seconds
}

export const editArticleDeadline = (
  payload: EditArticleDeadlinePayload,
): Promise<ArticleDeadline> => {
  return post('/deadlines/edit', payload);
};

export const useEditArticleDeadline = () => {
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: (payload: EditArticleDeadlinePayload) =>
      editArticleDeadline(payload),
    onError: () =>
      dispatch(sendErrorToast('Failed to change deadline. Please try again')),
    onSuccess: (res: ArticleDeadline) => {
      dispatch(sendSuccessToast('Deadline updated'));
      return res;
    },
  });
};
