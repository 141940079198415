import { WatchlistFeedTypes } from 'app/modules/detectionModels/components/scenarioWidgets/watchlist/enums';
import { AdverseMediaSummaryCard } from 'app/modules/watchlists/components/cards/AdverseMediaSummaryCard';
import { PepSummaryCard } from 'app/modules/watchlists/components/cards/PepSummaryCard';
import { SanctionSummaryCard } from 'app/modules/watchlists/components/cards/SanctionSummaryCard';
import { WatchlistResult } from 'app/modules/watchlists/models';

interface MatchSummaryCardProps {
  watchlist: WatchlistResult;
}

export const MatchSummaryCard = ({ watchlist }: MatchSummaryCardProps) => {
  if (watchlist.type === WatchlistFeedTypes.ADVERSE_MEDIA) {
    return (
      <AdverseMediaSummaryCard
        notes={watchlist.notes}
        categories={watchlist.categories}
        urls={watchlist.urls}
      />
    );
  }

  if (watchlist.type === WatchlistFeedTypes.PEP) {
    return watchlist.pep_infos.map((pepInfo) => (
      <PepSummaryCard key={JSON.stringify(pepInfo)} info={pepInfo} />
    ));
  }

  if (watchlist.type === WatchlistFeedTypes.SANCTIONS) {
    return <SanctionSummaryCard notes={watchlist.notes} />;
  }

  return null;
};
