// Components
import { U21Spacer } from 'app/shared/u21-ui/components';
import { EventActivityTable } from 'app/modules/alerts/components/AlertDetails/ActionEventActivity/EventActivityTable';
import { EventActivityFilters } from 'app/modules/alerts/components/AlertDetails/ActionEventActivity/EventActivityFilters';
import { useSelector } from 'react-redux';
import { selectHasReadEventsPermission } from 'app/modules/session/selectors';

export const ActionEventActivity = () => {
  const hasReadEventsPermission = useSelector(selectHasReadEventsPermission);

  return (
    <U21Spacer spacing={1}>
      {hasReadEventsPermission && <EventActivityFilters />}
      <EventActivityTable />
    </U21Spacer>
  );
};
