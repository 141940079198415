import { TEAMS_SLICE_NAME } from 'app/modules/teams/sliceTeams';
import { selectActiveAgentOptions } from 'app/shared/dropdown/selectors';
import { createSelector } from 'reselect';

export const selectTeamsCount = (state: RootState) =>
  state[TEAMS_SLICE_NAME].teamsCount;
export const selectTeams = (state: RootState) => state[TEAMS_SLICE_NAME].teams;
export const selectTeamDetails = (state: RootState) =>
  state[TEAMS_SLICE_NAME].team;
export const selectAgentFilters = (state: RootState) =>
  state[TEAMS_SLICE_NAME].agentFilters;
export const selectAgents = (state: RootState) =>
  state[TEAMS_SLICE_NAME].agents;
export const selectAgentsCount = (state: RootState) =>
  state[TEAMS_SLICE_NAME].agentsCount;
export const selectAssignmentChanges = (state: RootState) =>
  state[TEAMS_SLICE_NAME].assignmentChanges;
export const selectAgentsNotInTeam = createSelector(
  [selectActiveAgentOptions, selectTeamDetails],
  (activeAgents, teamDetails) => {
    const teamAgentIdsSet = new Set(teamDetails.agent_ids);
    return activeAgents.filter((agent) => !teamAgentIdsSet.has(agent.value));
  },
);
export const selectTeamAllowedTags = createSelector(
  [selectTeamDetails],
  (teamDetails) => teamDetails.allowed_tags.map((i) => i.id),
);

// Loading
export const selectLoadingTeams = (state: RootState) =>
  state[TEAMS_SLICE_NAME].loadingTeams;
export const selectCreateTeamLoading = (state: RootState) =>
  state[TEAMS_SLICE_NAME].loadingCreateTeam;
export const selectTeamDetailsLoading = (state: RootState) =>
  state[TEAMS_SLICE_NAME].loadingTeamDetails;
export const selectLoadingAgents = (state: RootState) =>
  state[TEAMS_SLICE_NAME].loadingAgents;
export const selectCheckAssignmentsLoading = (state: RootState) =>
  state[TEAMS_SLICE_NAME].loadingCheckAssignmentChanges;
export const selectLoadingDeleteTeam = (state: RootState) =>
  state[TEAMS_SLICE_NAME].loadingDeleteTeam;
export const selectEditTeamLoading = (state: RootState) =>
  state[TEAMS_SLICE_NAME].loadingEditTeam;
export const selectRemoveTeamAgents = (state: RootState) =>
  state[TEAMS_SLICE_NAME].loadingRemoveTeamAgents;
export const selectAddTeamAgents = (state: RootState) =>
  state[TEAMS_SLICE_NAME].loadingAddTeamAgents;
export const selectDuplicateTeamLoading = (state: RootState) =>
  state[TEAMS_SLICE_NAME].loadingDuplicateTeam;
