export enum ExportStatuses {
  REQUESTED = 'REQUESTED',
  GENERATING = 'GENERATING',
  READY_FOR_DOWNLOAD = 'READY_FOR_DOWNLOAD',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED',
}

// All CSV file types should end the enum string with '_CSV'
export enum ExportTypes {
  ALERT = 'ALERT',
  ALERT_CSV = 'ALERT_CSV',
  CASE = 'CASE',
  CASE_CSV = 'CASE_CSV',
  CHECKLIST = 'CHECKLIST',
  CHECKLIST_CSV = 'CHECKLIST_CSV',
  DEVICES = 'DEVICES',
  DEVICES_CSV = 'DEVICES_CSV',
  ENTITIES = 'ENTITIES',
  ENTITIES_CSV = 'ENTITIES_CSV',
  EVENTS = 'EVENTS',
  EVENTS_CSV = 'EVENTS_CSV',
  INSTRUMENTS = 'INSTRUMENTS',
  INSTRUMENTS_CSV = 'INSTRUMENTS_CSV',
  RULE = 'RULE',
  RULE_VALIDATION = 'RULE_VALIDATION',
  RULE_VALIDATION_CSV = 'RULE_VALIDATION_CSV',
  RULE_CSV = 'RULE_CSV',
  SAR_FILING = 'SAR_FILING',
  SAR_FILING_CSV = 'SAR_FILING_CSV',
  TRANSACTIONS = 'TRANSACTIONS',
  TRANSACTIONS_CSV = 'TRANSACTIONS_CSV',
  CRR_CSV = 'CRR_CSV',
}

export const CSV_EXPORT_TYPES = new Set(
  Object.values(ExportTypes).filter((exportType) =>
    exportType.includes('_CSV'),
  ),
);

enum ExportSources {
  DASHBOARD = 'Dashboard',
  API = 'Unit21 API',
  SYSTEM = 'System',
}

export interface FileExportResponse {
  created_at: string;
  export_type: `${ExportTypes}`;
  external_ids: number[];
  file_name: string;
  file_size: number;
  id: number;
  request_source: `${ExportSources}`;
  requested_by_id: number;
  requested_by_name: string;
  status: `${ExportStatuses}`;
  status_message: string;
}

export interface FileExportsResponse {
  count: number;
  file_exports: FileExportResponse[];
}
