// Models
import {
  EntityRiskSegment,
  MetricMappingMethod,
  NumericComparisonOperator,
  RiskMetricType,
} from 'app/modules/riskRatings/models';
import { RiskParameterName } from 'app/modules/riskRatings/components/riskParameters';
import { U21SelectOptionProps } from 'app/shared/u21-ui/components';
import { InvestigationStatus } from 'app/modules/investigations/models';
import { AlertType } from 'app/modules/alerts/types';
import { RuleStatus } from 'app/modules/rules/models';
import { PaginationPayload } from 'app/shared/pagination/models';
import {
  AgeOfRecordMetric,
  ArticleVolumeMetric,
  EntityInformationMatchMetric,
  GeographicMatchMetric,
  SarVolumeMetric,
} from 'app/modules/riskRatings/components/riskParameters/metrics';

export const MIN_RISK = 0;
export const MAX_RISK = 100;
export const DEFAULT_LOW_RISK_CEILING = 40;
export const DEFAULT_HIGH_RISK_FLOOR = 80;

export const RISK_SLIDER_MARKS = [
  {
    label: '0',
    value: MIN_RISK,
  },
  {
    label: '50',
    value: MAX_RISK / 2,
  },
  {
    label: '100',
    value: MAX_RISK,
  },
];

// Risk Model Constants
export const DEFAULT_ENTITY_RISK_SEGMENTS: Omit<EntityRiskSegment, 'id'>[] = [
  {
    title: 'All Entities',
    filters: {},
    parameters: [],
  },
];

export const U21_SIMPLE_MODEL: EntityRiskSegment[] = [
  {
    ...DEFAULT_ENTITY_RISK_SEGMENTS[0],
    parameters: [
      {
        parameter: 'GEOGRAPHIC_MATCH' as RiskParameterName.GEOGRAPHIC_MATCH,
        weight: 0.5,
        parameter_args: {},
        metrics: [
          {
            metric: 'ENTITY_COUNTRY' as GeographicMatchMetric.ENTITY_COUNTRY,
            metric_type: RiskMetricType.TEXT,
            unknown_risk: 100,
            unscored_risk: 0,
            risk_mappings: [],
          },
          {
            metric: 'ENTITY_STATE' as GeographicMatchMetric.ENTITY_STATE,
            metric_type: RiskMetricType.TEXT,
            unknown_risk: 100,
            unscored_risk: 0,
            risk_mappings: [],
          },
          {
            metric: 'ENTITY_CITY' as GeographicMatchMetric.ENTITY_CITY,
            metric_type: RiskMetricType.TEXT,
            unknown_risk: 100,
            unscored_risk: 0,
            risk_mappings: [],
          },
          {
            metric: 'ENTITY_ZIP_CODE' as GeographicMatchMetric.ENTITY_ZIP_CODE,
            metric_type: RiskMetricType.TEXT,
            unknown_risk: 100,
            unscored_risk: 0,
            risk_mappings: [],
          },
        ],
      },
      {
        parameter: 'AGE_OF_RECORD' as RiskParameterName.AGE_OF_RECORD,
        weight: 0.5,
        parameter_args: {},
        metrics: [
          {
            metric:
              'ENTITY_MONTHS_FROM_REGISTRATION' as AgeOfRecordMetric.ENTITY_MONTHS_FROM_REGISTRATION,
            metric_type: RiskMetricType.NUMBER,
            unknown_risk: 100,
            unscored_risk: 0,
            risk_mappings: [
              {
                method: MetricMappingMethod.NUMERIC_COMPARISON,
                risk: 100,
                content: {
                  operator: NumericComparisonOperator.LT,
                  value: 3,
                },
              },
              {
                method: MetricMappingMethod.NUMERIC_COMPARISON,
                risk: 80,
                content: {
                  operator: NumericComparisonOperator.LT,
                  value: 6,
                },
              },
              {
                method: MetricMappingMethod.NUMERIC_COMPARISON,
                risk: 60,
                content: {
                  operator: NumericComparisonOperator.LT,
                  value: 9,
                },
              },
              {
                method: MetricMappingMethod.NUMERIC_COMPARISON,
                risk: 40,
                content: {
                  operator: NumericComparisonOperator.LT,
                  value: 12,
                },
              },
            ],
          },
        ],
      },
      {
        parameter:
          'ENTITY_INFORMATION_MATCH' as RiskParameterName.ENTITY_INFORMATION_MATCH,
        weight: 0.5,
        parameter_args: {},
        metrics: [
          {
            metric:
              'ENTITY_BUSINESS_NAME' as EntityInformationMatchMetric.ENTITY_BUSINESS_NAME,
            metric_type: RiskMetricType.TEXT,
            unknown_risk: 0,
            unscored_risk: 0,
            risk_mappings: [],
          },
          {
            metric: 'ENTITY_AGE' as EntityInformationMatchMetric.ENTITY_AGE,
            metric_type: RiskMetricType.NUMBER,
            unknown_risk: 0,
            unscored_risk: 0,
            risk_mappings: [],
          },
          {
            metric:
              'ENTITY_STATUS' as EntityInformationMatchMetric.ENTITY_STATUS,
            metric_type: RiskMetricType.TEXT,
            unknown_risk: 0,
            unscored_risk: 0,
            risk_mappings: [],
          },
        ],
      },
      {
        parameter: 'SAR_VOLUME' as RiskParameterName.SAR_VOLUME,
        weight: 0.5,
        parameter_args: {
          SAR_COUNT_PAST_12_MONTHS: {
            statuses: [
              'SUBMITTING',
              'SUBMISSION_ACCEPTED',
              'SUBMISSION_SUCCESSFUL',
              'ARCHIVED',
            ],
          },
        },
        metrics: [
          {
            metric:
              'SAR_COUNT_PAST_12_MONTHS' as SarVolumeMetric.SAR_COUNT_PAST_12_MONTHS,
            metric_type: RiskMetricType.NUMBER,
            unknown_risk: 0,
            unscored_risk: 0,
            risk_mappings: [
              {
                method: MetricMappingMethod.NUMERIC_COMPARISON,
                content: {
                  operator: NumericComparisonOperator.GT,
                  value: 0,
                },
                risk: 100,
              },
            ],
          },
        ],
      },
      {
        parameter: 'ARTICLE_VOLUME' as RiskParameterName.ARTICLE_VOLUME,
        weight: 0.5,
        parameter_args: {
          ALERT_COUNT_PAST_12_MONTHS: {
            alert_types: [AlertType.TM],
            dispositions: ['TRUE_POSITIVE'],
            statuses: [InvestigationStatus.CLOSED],
          },
          CASE_COUNT_PAST_12_MONTHS: {
            dispositions: ['TRUE_POSITIVE'],
            statuses: [InvestigationStatus.CLOSED],
          },
        },
        metrics: [
          {
            metric:
              'ALERT_COUNT_PAST_12_MONTHS' as ArticleVolumeMetric.ALERT_COUNT_PAST_12_MONTHS,
            metric_type: RiskMetricType.NUMBER,
            unknown_risk: 0,
            unscored_risk: 0,
            risk_mappings: [
              {
                method: MetricMappingMethod.NUMERIC_COMPARISON,
                content: {
                  operator: NumericComparisonOperator.GT,
                  value: 0,
                },
                risk: 100,
              },
            ],
          },
          {
            metric:
              'CASE_COUNT_PAST_12_MONTHS' as ArticleVolumeMetric.CASE_COUNT_PAST_12_MONTHS,
            metric_type: RiskMetricType.NUMBER,
            unknown_risk: 0,
            unscored_risk: 0,
            risk_mappings: [
              {
                method: MetricMappingMethod.NUMERIC_COMPARISON,
                content: {
                  operator: NumericComparisonOperator.GT,
                  value: 0,
                },
                risk: 100,
              },
            ],
          },
        ],
      },
    ],
  },
];

export const NUMERIC_COMPARISON_OPERATOR_OPTIONS = Object.values({
  [NumericComparisonOperator.GT]: {
    text: 'Greater than',
    value: NumericComparisonOperator.GT,
  },
  [NumericComparisonOperator.LT]: {
    text: 'Less than',
    value: NumericComparisonOperator.LT,
  },
  [NumericComparisonOperator.EQ]: {
    text: 'Equal to',
    value: NumericComparisonOperator.EQ,
  },
  [NumericComparisonOperator.NEQ]: {
    text: 'Not equal to',
    value: NumericComparisonOperator.NEQ,
  },
  [NumericComparisonOperator.IN_RANGE]: {
    text: 'In between',
    value: NumericComparisonOperator.IN_RANGE,
  },
} satisfies {
  [k in NumericComparisonOperator]: U21SelectOptionProps<NumericComparisonOperator>;
});

// Make sure we show the rules no matter the status
export const RULES_SELECTOR_FILTERED_STATUSES: RuleStatus[] = [
  'ACTIVE',
  'INACTIVE',
  'VALIDATION',
  'ACTIVE_SHADOW',
  'PENDING_ACTIVATION',
  'PENDING_SHADOW_ACTIVATION',
];

export const DEFAULT_RISK_SCORES_SORT_METHOD: Omit<
  PaginationPayload,
  'limit' | 'offset'
> = {
  sort_direction: 'descending',
  sort_column: 'score',
};
