import { useMemo } from 'react';
import styled from 'styled-components';

// Components
import {
  U21Button,
  U21Divider,
  U21ModalBase,
  U21ModalBody,
  U21ModalFooter,
  U21ModalHeader,
  U21Spacer,
} from 'app/shared/u21-ui/components';
import { TxnImageComparisonModalBody } from 'app/modules/transactions/components/TxnImageComparisonModalBody';

// Models
import { ShortTxnResponse } from 'app/modules/transactions/types/responses';

type OwnProps = {
  associatedTxnEvents: ShortTxnResponse[];
  selectedTxnEvents: ShortTxnResponse[];
  isOpen: boolean;
  setIsOpen: (newIsOpen: boolean) => void;
};

export const TxnImageComparisonModal = ({
  associatedTxnEvents = [],
  selectedTxnEvents = [],
  isOpen,
  setIsOpen,
}: OwnProps) => {
  // Document id to txn event map for txns associated with the investigation
  const associatedTxnEventMap = useMemo(
    () =>
      associatedTxnEvents.reduce<Record<number, ShortTxnResponse>>(
        (acc, txnEvent) => {
          txnEvent.documents.forEach((document) => {
            acc[document.id] = txnEvent;
          });
          return acc;
        },
        {},
      ),
    [associatedTxnEvents],
  );

  // Document id to txn event map for txns selected by the agent
  const selectedTxnEventMap = useMemo(
    () =>
      selectedTxnEvents.reduce<Record<number, ShortTxnResponse>>(
        (acc, txnEvent) => {
          txnEvent.documents.forEach((document) => {
            acc[document.id] = txnEvent;
          });
          return acc;
        },
        {},
      ),
    [selectedTxnEvents],
  );

  return (
    <U21ModalBase
      onClick={(e) => e.stopPropagation()}
      open={isOpen}
      size="fullscreen"
    >
      <U21ModalHeader onClose={() => setIsOpen(false)}>
        Image Comparison
      </U21ModalHeader>
      <StyledU21ModalBody>
        <U21Spacer horizontal>
          <TxnImageComparisonModalBody
            txnEventMap={associatedTxnEventMap}
            header="Flagged Images"
            subheader="Select which image you would like to compare"
            isOverlayMode={false}
          />
          <U21Divider />
          <TxnImageComparisonModalBody
            txnEventMap={selectedTxnEventMap}
            header="Selected Images"
            subheader="Select which image you would like to compare against"
            isOverlayMode={false}
          />
        </U21Spacer>
      </StyledU21ModalBody>
      <U21ModalFooter>
        <U21Button onClick={() => setIsOpen(false)}>Cancel</U21Button>
      </U21ModalFooter>
    </U21ModalBase>
  );
};

const StyledU21ModalBody = styled(U21ModalBody)`
  width: 100%;
  height: 100%;
`;
