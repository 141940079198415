import { GoogleDriveDoc } from 'app/modules/uploads/models';
import { StorageResource } from 'app/modules/attachments/models';

import {
  DOCUMENT_UPLOAD_LIMIT,
  DOCUMENT_UPLOAD_FILE_LENGTH_LIMIT,
  STORAGE_RESOURCE_LABELS,
} from 'app/modules/attachmentsRefresh/constants';

import {
  selectHasGDriveEnabled,
  selectHasS3Enabled,
} from 'app/modules/attachments/selectors';
import { sendErrorToast } from 'app/shared/toasts/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo, useState } from 'react';
import styled from 'styled-components';

import { GoogleDrivePicker } from 'app/modules/googleDrive/components/GoogleDrivePicker';
import {
  U21FileUploader,
  U21RadioGroup,
  U21Spacer,
} from 'app/shared/u21-ui/components';

interface Props {
  currentDocumentCount: number;
  loading?: boolean;
  onPickGDrive?: (docs: GoogleDriveDoc[]) => void;
  onUploadGDrive?: (files: File[]) => void;
  onUploadS3?: (files: File[]) => void;
}

export const DocumentUpload = (props: Props) => {
  const {
    currentDocumentCount,
    loading,
    onPickGDrive,
    onUploadGDrive,
    onUploadS3,
  } = props;
  const dispatch = useDispatch();
  const hasGDriveEnabled = useSelector(selectHasGDriveEnabled);
  const hasS3Enabled = useSelector(selectHasS3Enabled);

  const options = useMemo(() => {
    const opts: { label: string; value: StorageResource }[] = [];
    if (hasS3Enabled && onUploadS3) {
      opts.push({
        label: STORAGE_RESOURCE_LABELS[StorageResource.S3],
        value: StorageResource.S3,
      });
    }
    if (hasGDriveEnabled && onUploadGDrive) {
      opts.push({
        label: STORAGE_RESOURCE_LABELS[StorageResource.GDRIVE],
        value: StorageResource.GDRIVE,
      });
    }
    return opts;
  }, [hasGDriveEnabled, hasS3Enabled, onUploadGDrive, onUploadS3]);

  const [selected, setSelected] = useState<StorageResource>(options[0]?.value);

  if (!options.length) {
    return null;
  }

  return (
    <U21Spacer>
      {(options.length > 1 || selected === StorageResource.GDRIVE) && (
        <OptionContainer>
          {options.length > 1 && (
            <U21RadioGroup
              horizontal
              onChange={setSelected}
              options={options}
              value={selected}
            />
          )}
          {selected === StorageResource.GDRIVE && onPickGDrive && (
            <GoogleDrivePicker onPick={onPickGDrive} />
          )}
        </OptionContainer>
      )}
      <U21FileUploader
        onDrop={(acceptedFiles: File[]) => {
          if (
            acceptedFiles.length + currentDocumentCount >
            DOCUMENT_UPLOAD_LIMIT
          ) {
            dispatch(
              sendErrorToast(
                `File upload limit of ${DOCUMENT_UPLOAD_LIMIT} exceeded! Please remove some files before uploading more.`,
              ),
            );
            return;
          }

          if (
            !acceptedFiles.every(
              (file) => file.path.length <= DOCUMENT_UPLOAD_FILE_LENGTH_LIMIT,
            )
          ) {
            dispatch(
              sendErrorToast(
                `File names cannot be longer than ${DOCUMENT_UPLOAD_FILE_LENGTH_LIMIT} characters. Please reduce the length of this filename and re-upload.`,
              ),
            );
            return;
          }

          if (acceptedFiles.length > 0) {
            if (selected === StorageResource.GDRIVE) {
              onUploadGDrive?.(acceptedFiles);
            } else {
              onUploadS3?.(acceptedFiles);
            }
          }
        }}
        loading={loading}
        multi
      />
    </U21Spacer>
  );
};

const OptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
