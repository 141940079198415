import {
  U21InfoItem,
  U21Section,
  U21Typography,
} from 'app/shared/u21-ui/components';
import styled, { css } from 'styled-components';

interface SanctionSummaryCardProps {
  notes: string;
}

export const SanctionSummaryCard = ({ notes }: SanctionSummaryCardProps) => {
  return (
    <StyledSection
      title={<U21Typography variant="h6">Sanction Summary</U21Typography>}
      collapsible
    >
      <StyledInfoItem
        label="Notes"
        width={90}
        valueProps={{ ellipsis: false }}
        style={{
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-word',
        }}
      >
        {notes}
      </StyledInfoItem>
    </StyledSection>
  );
};

const StyledSection = styled(U21Section)`
  ${(props) => css`
    background-color: ${props.theme.palette.background.neutral};
    border-color: ${props.theme.palette.background.neutral};
  `}
`;

const StyledInfoItem = styled(U21InfoItem)`
  margin-bottom: 12px;
  margin-top: 12px;
`;
