import { useQuery } from '@tanstack/react-query';
import { get } from 'app/shared/utils/fetchr';
import { LIST_QUERY_KEYS } from 'app/modules/lists/queries/keys';
import { useSelector } from 'react-redux';
import { selectHasReadAlertsPermission } from 'app/modules/session/selectors';
import { FinCEN314aScanResult } from 'app/modules/lists/responses';

export const useGetAlertFinCEN314aScanResult = (alertId: number) => {
  const hasReadAlertsPermission = useSelector(selectHasReadAlertsPermission);
  return useQuery<FinCEN314aScanResult>({
    enabled: hasReadAlertsPermission && alertId > 0,
    queryKey: LIST_QUERY_KEYS.getGetAlertFinCEN314aScanResultQueryKey(alertId),
    queryFn: () => get(`/alerts/${alertId}/fincen-314a-scan-result`),
  });
};
