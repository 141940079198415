import { ShortTxnResponse } from 'app/modules/transactions/types/responses';
import { get } from 'app/shared/utils/fetchr';
import { useQuery } from '@tanstack/react-query';
import { TRANSACTION_QUERY_KEYS } from 'app/modules/transactions/queries/keys';

export const useGetCheckImageTransactionById = (entityExternalId: string) => {
  return useQuery({
    queryKey:
      TRANSACTION_QUERY_KEYS.getCheckImageTransactions(entityExternalId),
    queryFn: () =>
      get<ShortTxnResponse[]>(
        `/entities/${encodeURIComponent(entityExternalId)}/check-image-transactions`,
      ),
    meta: {
      errorMessage:
        'Failed to fetch check image transactions for entity. Please try again.',
    },
    enabled: entityExternalId !== '',
  });
};
