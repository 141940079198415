import { useMemo, useState } from 'react';

// Components
import { IconArrowRight } from '@u21/tabler-icons';
import { U21SideMenu } from 'app/shared/u21-ui/components/dashboard';
import { InstrumentsDocumentChipList } from 'app/modules/instruments/components/InstrumentsDocumentChipList';

// Models
import { TxnInstrumentSidebarDefinition } from 'app/modules/sidebar/models';

// Utils
import { ROUTES_MAP } from 'app/shared/utils/routes';
import { SidebarSummary } from 'app/modules/sidebar/components/utils/SidebarSummary';
import { SummaryViewConfigTypeClassifier } from 'app/modules/summarySettings/types';
import {
  U21Button,
  U21Spacer,
  U21Subsection,
  U21TitleCountLabel,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { SidebarCommentsCollapsible } from 'app/modules/sidebar/components/SidebarCommentsCollapsible';
import { SidebarDataLabels } from 'app/modules/sidebar/components/SidebarDataLabels';
import { useInstrumentDetails } from 'app/modules/instruments/queries/useInstrumentDetails';

interface OwnProps {
  data: TxnInstrumentSidebarDefinition['data'];
}

export const SidebarInstrument = ({ data }: OwnProps) => {
  const [showEmptyFields, setShowEmptyFields] = useState(false);

  const {
    data: instrument,
    isLoading,
    refetch,
  } = useInstrumentDetails(String(data.id));

  const tagIds = useMemo(
    () => instrument?.tags.map((i) => i.id) || [],
    [instrument?.tags],
  );

  const idForButtonLink = data.id || instrument?.id;
  return (
    <U21SideMenu
      actionButtonProps={{
        children: 'Go to Detail Page',
        endIcon: <IconArrowRight />,
        disabled: !idForButtonLink,
        to: ROUTES_MAP.instrumentsId.path.replace(
          ':id',
          String(idForButtonLink),
        ),
      }}
      footerLeftContent={
        <U21Button
          onClick={() => setShowEmptyFields((prev) => !prev)}
          variant="ghost"
        >
          {showEmptyFields ? 'Hide' : 'Show'} Empty Fields
        </U21Button>
      }
      loading={isLoading}
      noPadding
      title="Instrument Details"
    >
      <SidebarSummary
        classifier={SummaryViewConfigTypeClassifier.INSTRUMENT}
        details={instrument}
        showEmptyFields={showEmptyFields}
      />
      <U21Spacer spacing={0.5}>
        <U21Subsection
          title={
            <U21TitleCountLabel
              count={instrument?.documents.length || 0}
              label=""
            >
              Documents
            </U21TitleCountLabel>
          }
          collapsible
          collapsed
          shaded
        >
          {instrument && instrument.documents.length > 0 ? (
            <InstrumentsDocumentChipList
              documents={instrument.documents}
              handleOnDelete={() => refetch()}
            />
          ) : (
            <U21Typography variant="caption" color="text.secondary">
              No documents found
            </U21Typography>
          )}
        </U21Subsection>
        <SidebarDataLabels ids={tagIds} />
        <SidebarCommentsCollapsible comments={instrument?.comments} />
      </U21Spacer>
    </U21SideMenu>
  );
};
