import { AnalyticsEvents, EventConfig } from 'app/shared/u21-ui/analytics';
import { U21SelectOptionProps } from 'app/shared/u21-ui/components';

export const onchange: EventConfig<U21SelectOptionProps> = {
  name: 'U21Select changed',
  createPayload: (props, _, option) => {
    const name = props.label || props['aria-label'] || props.name;
    if (!name) {
      return null;
    }

    return {
      value: option?.value,
      name,
    };
  },
};

export const u21SelectEvents = {
  [AnalyticsEvents.U21SELECT_ON_CHANGE]: onchange,
};
