import { createPortal } from 'react-dom';

// material
import { experimentalStyled } from '@mui/material/styles';

// Components
import { IconGripVertical } from '@u21/tabler-icons';

// Models
import { SidebarTableConfigItemData } from 'app/modules/sidebar/models';
import { TableConfig } from 'app/shared/pagination/models';
import { OrgTableColumnKeyType } from 'app/modules/tableSettings/responses';
import {
  U21Checkbox,
  U21Spacer,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { FrontendColumnDefinition } from 'app/modules/tableSettings/types';
import styled, { css } from 'styled-components';

const RootStyle = experimentalStyled('li')(() => ({
  padding: 10,
  marginBottom: 5,
  listStyle: 'none',
  display: 'flex',
  width: '100%',
  alignItems: 'center',
}));

const Title = experimentalStyled('label')(() => ({
  flex: 1,
  overflowWrap: 'anywhere',
}));

export const getColumnId = (column: FrontendColumnDefinition | TableConfig) =>
  column.key_type === OrgTableColumnKeyType.DATA_SETTING
    ? column.data_setting_id.toString()
    : column.key;

const SidebarTableConfigItem = ({
  provided,
  snapshot,
  portal,
  column,
  toggleColumns,
}: SidebarTableConfigItemData) => {
  const usePortal = snapshot.isDragging;
  const switchName = getColumnId(column);
  const { description } = column;
  const child = (
    <RootStyle
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      <StyledSpacer horizontal align={description ? 'start' : undefined}>
        <StyledIconContainer $addMargin={!!description}>
          <IconGripVertical />
        </StyledIconContainer>
        <U21Spacer spacing={0.5}>
          <Title htmlFor={column.label}>{column.label}</Title>
          <U21Typography variant="body2" color="text.secondary">
            {description}
          </U21Typography>
        </U21Spacer>
      </StyledSpacer>
      <U21Checkbox
        checked={!column.hidden}
        name={switchName}
        onChange={toggleColumns}
        id={column.label}
      />
    </RootStyle>
  );

  if (!usePortal) {
    return child;
  }

  return createPortal(child, portal);
};

export default SidebarTableConfigItem;

const StyledIconContainer = styled.div<{ $addMargin: boolean }>`
  display: flex;
  ${({ $addMargin }) =>
    $addMargin
      ? css`
          &&& {
            margin-top: 2px;
          }
        `
      : ''}
`;

const StyledSpacer = styled(U21Spacer)`
  flex-grow: 1;
`;
