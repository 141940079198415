// Components
import { ChainalysisSummary } from 'app/modules/alerts/components/AlertDetails/ChainalysisSummary';

// Types
import { AlertDetailsTab, AlertType } from 'app/modules/alerts/types';

export const chainalysisAlertTabs: AlertDetailsTab[] = [
  {
    label: 'Summary',
    path: 'summary',
    component: <ChainalysisSummary />,
    visibleOn: {
      types: [AlertType.CHAINALYSIS],
    },
  },
];
