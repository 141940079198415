// Models
import { PaginationPayload } from 'app/shared/pagination/models';

const BASE = ['risk'] as const;
export const RISK_QUERY_KEYS = {
  listRiskModels: (payload: PaginationPayload) =>
    [...BASE, 'list', payload] as const,
  getRiskModel: (modelId: number) => [...BASE, 'get', modelId] as const,
  getRiskModelDistribution: (modelId: number) =>
    [...BASE, 'distribution', modelId] as const,
  getEntityRiskScore: (entityExternalId: string) =>
    [...BASE, 'entity', entityExternalId] as const,
  getActiveRiskModel: () => [...BASE, 'active-risk-model'] as const,
  fetchEntitiesByRiskModel: (payload) =>
    [...BASE, 'entities-by-model-list', payload] as const,
};
