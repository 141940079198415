import styled, { css } from 'styled-components';

// Components
import {
  U21Tooltip,
  U21Badge,
  U21BadgeProps,
} from 'app/shared/u21-ui/components';

interface TabLabelProps {
  label: string;
  badge?: U21BadgeProps['content'];
  badgeProps?: Partial<U21BadgeProps>;
  tooltip?: string;
}

export const TabLabel = ({
  label,
  badge,
  badgeProps,
  tooltip,
}: TabLabelProps) => {
  const hasBadge =
    badge === 0 || Boolean(badge) || badgeProps?.variant === 'dot';

  const content = <Text $hasBadge={hasBadge}>{label}</Text>;

  const badgeChildren = tooltip ? (
    <U21Tooltip tooltip={tooltip}>{content}</U21Tooltip>
  ) : (
    content
  );

  return (
    <U21Badge content={badge === 0 ? String(badge) : badge} {...badgeProps}>
      {badgeChildren}
    </U21Badge>
  );
};

interface StyleProps {
  $hasBadge?: boolean;
}

const Text = styled.span<StyleProps>`
  ${(props) => {
    if (props.$hasBadge) {
      return css`
        padding-right: 8px;
      `;
    }
    return '';
  }}
`;
