import { useQuery } from '@tanstack/react-query';
import { get } from 'app/shared/utils/fetchr';
import { AI_CHAT_KEYS } from 'app/modules/codeInterpreter/queries/keys';
import { FileExportResponse } from 'app/modules/fileExports/responses';

const getGeneratedFileExports = () =>
  get<FileExportResponse[]>(`/code-interpreter-session/file-exports`);

export const useFetchGeneratedFileExports = () => {
  return useQuery({
    queryKey: AI_CHAT_KEYS.getGeneratedFileExports(),
    initialData: [],
    queryFn: () => getGeneratedFileExports(),
    meta: { errorMessage: 'Failed to fetch the file exports.' },
  });
};
