import React, { Component } from 'react';
import autoBind from 'auto-bind';

// Redux
import { connect } from 'react-redux';

// Components
import { Progress } from 'semantic-ui-react';
import ReactTooltip from 'react-tooltip';

// Styles
import styles from 'app/shared/styles/ProgressBar.module.scss';

const POLLING_INTERVAL = 5000;
const MAX_PROGRESS_BLOCKS = 20;
const BLOCKS_TOTAL_WIDTH =
  MAX_PROGRESS_BLOCKS * 29 + Math.max(MAX_PROGRESS_BLOCKS - 2, 0) * 6;

interface OwnProps {
  hasPendingJob: boolean;
  totalSteps: number;
  stepsDone: number;
  pollUpdate: () => void;
}

interface AllState {
  initRuleValidationsInterval: NodeJS.Timeout | null;
  isSubsequentUpdate: boolean;
}

const initialState: AllState = {
  initRuleValidationsInterval: null,
  isSubsequentUpdate: false,
};

/**
 * @deprecated Use U21Progress instead
 */
class ProgressBar extends Component<OwnProps, AllState> {
  constructor(props) {
    super(props);
    this.state = { ...initialState };

    autoBind(this);
  }

  componentDidMount() {
    this.startPolling();
  }

  componentDidUpdate(_: OwnProps, prevState: AllState) {
    const { hasPendingJob } = this.props;
    if (!prevState.isSubsequentUpdate && hasPendingJob) {
      this.setState({ isSubsequentUpdate: true }, this.startPolling);
    }
  }

  componentWillUnmount() {
    this.cancelInterval();
  }

  startPolling = () => {
    const { hasPendingJob, pollUpdate } = this.props;
    if (hasPendingJob) {
      this.setState({
        initRuleValidationsInterval: setInterval(() => {
          if (!hasPendingJob) {
            this.cancelInterval();
          } else {
            pollUpdate();
          }
        }, POLLING_INTERVAL),
      });
    }
  };

  cancelInterval = () => {
    const { initRuleValidationsInterval } = this.state;
    if (initRuleValidationsInterval !== null) {
      clearInterval(initRuleValidationsInterval);
      this.setState({
        initRuleValidationsInterval: null,
      });
    }
  };

  render() {
    const { totalSteps, stepsDone } = this.props;
    const numProgressBlocks = Math.min(10, totalSteps);
    const blockWidth =
      (BLOCKS_TOTAL_WIDTH - Math.max(0, numProgressBlocks - 2) * 6) /
      numProgressBlocks;

    const processingIx = Math.floor(
      stepsDone * (numProgressBlocks / totalSteps),
    );

    const windowProgressText = `${stepsDone} of ${totalSteps} steps done`;

    return (
      <div
        className={styles.progressContainer}
        data-for="validationRuleProgressTooltip"
        data-tip={windowProgressText}
      >
        <ReactTooltip id="validationRuleProgressTooltip" multiline />
        {[...Array(numProgressBlocks)].map((e, ix) =>
          ix === processingIx ? (
            <Progress
              /* eslint-disable-next-line react/no-array-index-key */
              key={`progress-cell-${ix}`}
              percent={100}
              indicating
              active
              className={styles.progressBlock}
              style={{ width: blockWidth - 5, marginRight: '10px' }}
            />
          ) : (
            <div
              /* eslint-disable-next-line react/no-array-index-key */
              key={`progress-cell-${ix}`}
              className={
                ix < processingIx
                  ? styles.completedBlock
                  : styles.incompleteBlock
              }
              style={{ width: blockWidth }}
            />
          ),
        )}
      </div>
    );
  }
}

export default connect()(ProgressBar);
