import { NO_AUTH_ROUTES, ROUTES_MAP } from 'app/shared/utils/routes';
import {
  selectAgent,
  selectStartSessionLoading,
} from 'app/modules/session/selectors';
import { selectLoadingOrgSettings } from 'app/modules/orgSettings/selectors';
import { selectCustomConfigsLoading } from 'app/shared/CustomConfig/selectors';
import { selectFeatureFlagsLoading } from 'app/shared/featureFlags/selectors';
import { selectLoadingPermissions } from 'app/modules/session/sliceSelectors';
import { startSession } from 'app/modules/session/actions';
import { useAuth } from 'app/shared/providers/AuthProvider';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { AppRouter } from 'app/routers/AppRouter';
import { ErrorBoundary } from 'app/components/ErrorBoundary';
import { U21Loading } from 'app/shared/u21-ui/components';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { Suspense, useEffect, useState } from 'react';
import EmailTokenExchange from 'app/modules/session/components/EmailTokenExchange';
import { GridLayout } from 'app/modules/navigator/components/GridLayout';
import Login from 'app/modules/session/components/Login';

export const App = () => {
  const dispatch = useDispatch();
  const agent = useSelector(selectAgent);
  const orgSettingsLoading = useSelector(selectLoadingOrgSettings);
  const startSessionLoading = useSelector(selectStartSessionLoading);
  const permissionsLoading = useSelector(selectLoadingPermissions);
  const featureFlagsLoading = useSelector(selectFeatureFlagsLoading);
  const customConfigsLoading = useSelector(selectCustomConfigsLoading);

  const [sessionStarted, setSessionStarted] = useState(false);

  const { authClient } = useAuth();
  const { loadingFlags, provider } = authClient;
  const authenticated = authClient.isAuthenticated();

  const { pathname } = useLocation();

  useEffect(() => {
    if (!loadingFlags && !sessionStarted) {
      if (authenticated || provider === 'auth0') {
        dispatch(startSession(authClient));
      }
      setSessionStarted(true);
    }
  }, [
    authClient,
    authenticated,
    dispatch,
    loadingFlags,
    provider,
    sessionStarted,
  ]);

  if (NO_AUTH_ROUTES.has(pathname)) {
    return (
      <Suspense fallback={<StyledU21Loading loading />}>
        <Switch>
          <Route
            component={Login}
            exact={ROUTES_MAP.agentLogin.exact}
            path={ROUTES_MAP.agentLogin.path}
          />
          <Route
            component={EmailTokenExchange}
            exact={ROUTES_MAP.emailLogin.exact}
            path={ROUTES_MAP.emailLogin.path}
          />
        </Switch>
      </Suspense>
    );
  }

  if (sessionStarted && !authenticated && provider === 'unit21') {
    return <Redirect to={ROUTES_MAP.agentLogin.path} />;
  }

  const loading =
    orgSettingsLoading ||
    startSessionLoading ||
    permissionsLoading ||
    featureFlagsLoading ||
    customConfigsLoading ||
    !Object.keys(agent).length;

  if (loading) {
    return <StyledU21Loading loading />;
  }

  return (
    <GridLayout>
      <ErrorBoundary>
        <Suspense fallback={<StyledU21Loading loading />}>
          <AppRouter />
        </Suspense>
      </ErrorBoundary>
    </GridLayout>
  );
};

const StyledU21Loading = styled(U21Loading)`
  height: 100%;
`;
