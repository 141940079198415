import { useMemo } from 'react';
import { useSelector } from 'react-redux';

// Utils
import { createFormInitialValues } from 'app/shared/utils/form';

// Selectors
import { selectEditCaseLoading } from 'app/modules/cases/selectors';
import { selectInvestigation } from 'app/modules/investigations/selectors/investigations';

// Models
import {
  InvestigationType,
  AttachmentsFormValues,
} from 'app/modules/investigations/models';

// Components
import { U21Form } from 'app/shared/u21-ui/components';
import { InvestigationDocuments } from 'app/modules/investigations/components/InvestigationDocuments';

// Schemas
import { INVESTIGATION_DOCUMENTS } from 'app/modules/investigations/schemas';

interface Props {
  readOnly?: boolean;
}

export const FormTabAttachment = (props: Props) => {
  const { readOnly } = props;
  const details = useSelector(selectInvestigation(InvestigationType.CASE));
  const loading = useSelector(selectEditCaseLoading);

  // Values are generated through the default field in the schema
  const newDetails = useMemo(() => {
    return createFormInitialValues<AttachmentsFormValues>(
      [INVESTIGATION_DOCUMENTS],
      details,
    );
  }, [details]);

  return (
    <U21Form loading={loading} onSubmit={() => {}} initialValues={newDetails}>
      <InvestigationDocuments
        readOnly={readOnly}
        type={InvestigationType.CASE}
      />
    </U21Form>
  );
};
