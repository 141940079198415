import { TRANSACTION_SCORE_QUERY_KEYS } from 'app/modules/transactionScore/queries/keys';
import { get } from 'app/shared/utils/fetchr';
import { useQuery } from '@tanstack/react-query';
import { TransactionRiskScoreEvidenceResponse } from 'app/modules/transactionScore/responses';

export const useGetTransactionRiskScoreEvidence = <
  TSelect = TransactionRiskScoreEvidenceResponse,
>(
  eventID: string,
  options?: {
    enabled?: boolean;
    select?: (data: TransactionRiskScoreEvidenceResponse) => TSelect;
  },
) => {
  return useQuery({
    queryFn: () =>
      get<TransactionRiskScoreEvidenceResponse>(
        `/ml/txn-score/shap-values/${eventID}`,
      ),
    queryKey:
      TRANSACTION_SCORE_QUERY_KEYS.getTransactionRiskScoreEvidence(eventID),
    ...options,
  });
};
