// Redux
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

// Components
import CustomChecklistContainer from 'app/shared/customChecklist/components/CustomChecklistContainer';

// Model
import {
  InvestigationStatus,
  InvestigationType,
} from 'app/modules/investigations/models';

// Selectors
import { selectInvestigation } from 'app/modules/investigations/selectors';

import {
  selectAgent,
  selectHasEditAlertsPermission,
  selectHasEditCasesPermission,
} from 'app/modules/session/selectors';

interface Props {
  type: InvestigationType;
  isExport?: boolean;
}

export const InvestigationChecklist = (props: Props) => {
  const { type, isExport } = props;
  const investigation = useSelector(selectInvestigation(type));
  const hasEditAlertPermission = useSelector(selectHasEditAlertsPermission);
  const hasEditCasePermission = useSelector(selectHasEditCasesPermission);
  const permission =
    type === InvestigationType.ALERT
      ? hasEditAlertPermission
      : hasEditCasePermission;
  const sessionAgent = useSelector(selectAgent);

  const isEditable = useMemo(() => {
    if (!permission) {
      return false;
    }
    if (investigation.status === InvestigationStatus.CLOSED) {
      return false;
    }
    if (
      investigation.status === InvestigationStatus.IN_REVIEW &&
      investigation.queue_id !== null &&
      !sessionAgent.accessible_queues.includes(investigation.queue_id)
    ) {
      return false;
    }
    if (isExport) {
      return false;
    }
    return true;
  }, [
    investigation.queue_id,
    investigation.status,
    isExport,
    permission,
    sessionAgent.accessible_queues,
  ]);

  return (
    <CustomChecklistContainer
      type={type}
      article={investigation}
      editable={isEditable}
      isExport={isExport}
    />
  );
};
