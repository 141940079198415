import {
  RiskMetricDetails,
  RiskMetricPayload,
} from 'app/modules/riskRatings/models';
import {
  AGE_OF_RECORD_METRIC_DETAILS,
  AgeOfRecordMetric,
  ARTICLE_VOLUME_METRIC_DETAILS,
  ArticleVolumeMetric,
  ENTITY_CUSTOM_DATA_MATCH_METRIC_DETAILS,
  ENTITY_INFORMATION_MATCH_METRIC_DETAILS,
  EntityCustomDataMatchMetric,
  EntityInformationMatchMetric,
  GEOGRAPHIC_MATCH_METRIC_DETAILS,
  GeographicMatchMetric,
  RULE_MATCH_METRIC_DETAILS,
  RuleMatchMetric,
  SAR_VOLUME_METRIC_DETAILS,
  SarVolumeMetric,
} from 'app/modules/riskRatings/components/riskParameters/metrics';
import { InvestigationStatus } from 'app/modules/investigations/models';
import { AlertType } from 'app/modules/alerts/types';

export interface ParameterProps {
  parameterPath: string;
}

export enum RiskParameterName {
  // Should match EntityRiskParameter in Pensieve
  GEOGRAPHIC_MATCH = 'GEOGRAPHIC_MATCH',
  AGE_OF_RECORD = 'AGE_OF_RECORD',
  ENTITY_INFORMATION_MATCH = 'ENTITY_INFORMATION_MATCH',
  ENTITY_CUSTOM_DATA_MATCH = 'ENTITY_CUSTOM_DATA_MATCH',
  SAR_VOLUME = 'SAR_VOLUME',
  ARTICLE_VOLUME = 'ARTICLE_VOLUME',
  RULE_MATCH = 'RULE_MATCH',
}

type PartialRecord<K extends string | number | symbol, T> = { [P in K]?: T };

// Risk Metrics By Risk Parameter Name
export const METRICS_BY_RISK_PARAMETER = {
  [RiskParameterName.GEOGRAPHIC_MATCH]: GeographicMatchMetric,
  [RiskParameterName.AGE_OF_RECORD]: AgeOfRecordMetric,
  [RiskParameterName.ENTITY_INFORMATION_MATCH]: EntityInformationMatchMetric,
  [RiskParameterName.SAR_VOLUME]: SarVolumeMetric,
  [RiskParameterName.ARTICLE_VOLUME]: ArticleVolumeMetric,
  [RiskParameterName.ENTITY_CUSTOM_DATA_MATCH]: EntityCustomDataMatchMetric,
  [RiskParameterName.RULE_MATCH]: RuleMatchMetric,
} as const satisfies { [key in RiskParameterName]: Record<string, string> };

export const ALL_RISK_METRICS = {
  ...GeographicMatchMetric,
  ...AgeOfRecordMetric,
  ...EntityInformationMatchMetric,
  ...SarVolumeMetric,
  ...ArticleVolumeMetric,
  ...EntityCustomDataMatchMetric,
  ...RuleMatchMetric,
} as const;

export type RiskMetricName = ValueOf<typeof ALL_RISK_METRICS>;
export type RiskMetricDetailsMap = PartialRecord<
  RiskMetricName,
  RiskMetricDetails
>;

export const ALL_RISK_METRIC_DETAILS_MAP: {
  [key in RiskMetricName]: RiskMetricDetails;
} = {
  ...GEOGRAPHIC_MATCH_METRIC_DETAILS,
  ...AGE_OF_RECORD_METRIC_DETAILS,
  ...ENTITY_INFORMATION_MATCH_METRIC_DETAILS,
  ...SAR_VOLUME_METRIC_DETAILS,
  ...ARTICLE_VOLUME_METRIC_DETAILS,
  ...ENTITY_CUSTOM_DATA_MATCH_METRIC_DETAILS,
  ...RULE_MATCH_METRIC_DETAILS,
} as const;

export interface RiskParameterArgsComponentProps {
  riskMetricPayload: RiskMetricPayload;
  parameterArgsPath: string;
}

export const RISK_PARAMETERS_MAP: {
  [key in RiskParameterName]: {
    label: string;
    // Allows the parameter to be added multiple times in the same risk model
    allowDuplicates?: boolean;
    hideUnknownUnscoredRiskInputs?: boolean;
  };
} = {
  [RiskParameterName.GEOGRAPHIC_MATCH]: {
    label: 'Address risk',
  },
  [RiskParameterName.AGE_OF_RECORD]: {
    label: 'New member risk',
  },
  [RiskParameterName.ENTITY_INFORMATION_MATCH]: {
    label: 'Customer information risk',
  },
  [RiskParameterName.SAR_VOLUME]: {
    label: 'Suspicious activity risk',
    hideUnknownUnscoredRiskInputs: true,
  },
  [RiskParameterName.ARTICLE_VOLUME]: {
    label: 'Alert & case risk',
    hideUnknownUnscoredRiskInputs: true,
  },
  [RiskParameterName.ENTITY_CUSTOM_DATA_MATCH]: {
    label: 'Custom data risk',
    allowDuplicates: true,
  },
  [RiskParameterName.RULE_MATCH]: {
    label: 'Rule risk',
    allowDuplicates: true,
    hideUnknownUnscoredRiskInputs: true,
  },
} as const;

export interface EntityCustomDataMatchParameterArgs {
  _hash?: string;
  label?: string;
}

export interface ArticleVolumeParameterArgs {
  [ArticleVolumeMetric.ALERT_COUNT_PAST_12_MONTHS]: {
    statuses: InvestigationStatus[];
    alert_types: AlertType[];
    dispositions: string[];
  };
  [ArticleVolumeMetric.CASE_COUNT_PAST_12_MONTHS]: {
    statuses: InvestigationStatus[];
    dispositions: string[];
  };
}

export interface SarVolumeParameterArgs {
  [SarVolumeMetric.SAR_COUNT_PAST_12_MONTHS]: {
    statuses: string[];
  };
}

export type RiskParameterArgs =
  | EntityCustomDataMatchParameterArgs
  | ArticleVolumeParameterArgs
  | SarVolumeParameterArgs;
