import { useDispatch, useSelector } from 'react-redux';
import { selectHasRequeueCasePermission } from 'app/modules/cases/selectors';
import { useGetCase } from 'app/modules/cases/queries/useGetCase';
import {
  U21Chip,
  U21ChipProps,
  U21MenuLayout,
} from 'app/shared/u21-ui/components';
import { useState } from 'react';
import { useRequeueCase } from 'app/modules/cases/queries/useRequeueCase';
import { IconCircleDashed, IconClipboardList } from '@u21/tabler-icons';
import { QueueType } from 'app/modules/queues/models';
import { ChangeQueueMenuList } from 'app/modules/queues/components/ChangeQueueMenuList';
import { post } from 'app/shared/utils/fetchr';
import {
  InvestigationsCaseResponse,
  InvestigationsPayload,
} from 'app/modules/investigations/types';
import { updateCaseCache } from 'app/modules/casesOld/actions';
import { updateCasesCache } from 'app/modules/cases/sliceCases';

interface Props {
  id: number;
}

export const Requeue = (props: Props) => {
  const { id } = props;

  const dispatch = useDispatch();
  const hasRequeueCasePermission = useSelector(selectHasRequeueCasePermission);

  const [open, setOpen] = useState(false);

  const { case: c } = useGetCase(id);
  const { mutateAsync: requeueCase } = useRequeueCase([id]);

  if (!c) {
    return null;
  }

  const chipProps: U21ChipProps = {
    children: c['queue.title'] || 'No queue',
    color: 'primary',
    icon: c.queue_id ? <IconClipboardList /> : <IconCircleDashed />,
    variant: 'ghost',
  };

  if (
    !hasRequeueCasePermission ||
    c.status === 'CLOSED' ||
    c.status === 'IN_REVIEW'
  ) {
    return <U21Chip {...chipProps} />;
  }

  return (
    <U21MenuLayout
      onClose={(e) => {
        e.stopPropagation();
        setOpen(false);
      }}
      trigger={<U21Chip {...chipProps} onClick={() => setOpen(true)} />}
      open={open}
    >
      <ChangeQueueMenuList
        onChange={async (queueID) => {
          setOpen(false);
          try {
            await requeueCase(queueID);
            // retrieve latest case info to update the cached redux data w/ new assignee
            // unfortunately the investigation/action API does not return the latest data
            // so need to manually trigger another API to get it
            // this will no longer be needed once the case table uses hooks
            const response = await post<InvestigationsCaseResponse<false>>(
              '/investigations/retrieve/case',
              {
                short: false,
                object_type: 'CASE',
                object_ids: [id],
              } satisfies InvestigationsPayload,
            );
            if (response.cases[0]) {
              dispatch(updateCaseCache(response.cases[0]));
              dispatch(updateCasesCache(response.cases[0]));
            }
          } catch {}
        }}
        queueType={QueueType.CASE_QUEUE}
      />
    </U21MenuLayout>
  );
};
