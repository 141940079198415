import { CommentModel } from 'app/modules/comments/models';
import { useMemo } from 'react';
import { CommentList } from 'app/modules/comments/components/CommentList';
import {
  U21Subsection,
  U21TitleCountLabel,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { IconMessage } from '@u21/tabler-icons';

interface Props {
  comments?: CommentModel[];
}

export const SidebarCommentsCollapsible = (props: Props) => {
  const { comments } = props;

  const orderedComments = useMemo(() => {
    if (!comments?.length) {
      return [];
    }
    return comments.slice().reverse();
  }, [comments]);

  return (
    <U21Subsection
      collapsible
      collapsed
      shaded
      title={
        <U21TitleCountLabel count={orderedComments.length} label="">
          Comments
        </U21TitleCountLabel>
      }
      titleIcon={<IconMessage />}
    >
      {orderedComments.length ? (
        <CommentList comments={orderedComments} />
      ) : (
        <U21Typography variant="caption" color="text.secondary">
          No comments found
        </U21Typography>
      )}
    </U21Subsection>
  );
};
