import {
  addRichTextEditorComment,
  updateRichTextEditorComment,
  deleteRichTextEditorComment,
  addRichTextEditorCommentThread,
  getRichTextEditorCommentThread,
  resolveRichTextEditorCommentThread,
  reopenRichTextEditorCommentThread,
  deleteRichTextEditorCommentThread,
} from 'app/shared/components/RichTextEditor/api';
import {
  INITIAL_RICH_TEXT_EDITOR_COMMENT_THREAD_ADDITION_RESPONSE,
  INITIAL_RICH_TEXT_EDITOR_COMMENT_THREAD,
} from 'app/shared/components/RichTextEditor/constants';
import { RichTextEditorCommentType } from 'app/shared/components/RichTextEditor/enums';
import {
  RichTextEditorCommentThread,
  RichTextEditorCommentThreadAddition,
  RichTextEditorCommentThreadResolve,
  RichTextEditorComment,
} from 'app/shared/components/RichTextEditor/models';
import { sendErrorToast } from 'app/shared/toasts/actions';

const DEFAULT_RICH_TEXT_EDITOR_COMMENT_ADAPTER = {
  addComment: (): Promise<Date> => {
    return Promise.resolve(new Date());
  },
  updateComment: (): Promise<void> => {
    return Promise.resolve();
  },
  removeComment: async (): Promise<void> => {
    return Promise.resolve();
  },
  addCommentThread: async (
    data: RichTextEditorCommentThread,
  ): Promise<RichTextEditorCommentThreadAddition> => {
    return Promise.resolve({
      ...INITIAL_RICH_TEXT_EDITOR_COMMENT_THREAD_ADDITION_RESPONSE,
      threadId: data.threadId,
    });
  },
  getCommentThread: async ({
    threadId,
  }: {
    threadId: string;
  }): Promise<RichTextEditorCommentThread> => {
    return Promise.resolve({
      ...INITIAL_RICH_TEXT_EDITOR_COMMENT_THREAD,
      threadId,
    });
  },
  updateCommentThread: async (): Promise<void> => {
    return Promise.resolve();
  },
  resolveCommentThread:
    async (): Promise<RichTextEditorCommentThreadResolve> => {
      return Promise.resolve({
        resolvedAt: null,
        resolvedBy: null,
      });
    },
  reopenCommentThread: async (): Promise<void> => {
    return Promise.resolve();
  },
  removeCommentThread: async (): Promise<void> => {
    return Promise.resolve();
  },
};

export const getRichTextEditorCommentAdapter = (
  parentId: number,
  parentType: RichTextEditorCommentType,
) => {
  if (parentId === -1) {
    return DEFAULT_RICH_TEXT_EDITOR_COMMENT_ADAPTER;
  }

  return {
    addComment: async (data: RichTextEditorComment): Promise<Date> => {
      try {
        const newComment: RichTextEditorComment =
          await addRichTextEditorComment({
            ...data,
            parentId,
            parentType,
          });

        return new Date(newComment.createdAt);
      } catch (e) {
        sendErrorToast('Failed to add comment. Please try again.');
        return new Date();
      }
    },
    updateComment: async (data: RichTextEditorComment): Promise<void> => {
      try {
        return await updateRichTextEditorComment({
          ...data,
          parentId,
          parentType,
        });
      } catch (e) {
        sendErrorToast('Failed to update comment. Please try again.');
        return undefined;
      }
    },
    removeComment: async (data: RichTextEditorComment): Promise<void> => {
      try {
        return await deleteRichTextEditorComment({
          ...data,
          parentId,
          parentType,
        });
      } catch (e) {
        sendErrorToast('Failed to delete comment. Please try again.');
        return undefined;
      }
    },
    addCommentThread: async (
      data: RichTextEditorCommentThread,
    ): Promise<RichTextEditorCommentThreadAddition> => {
      try {
        const res = await addRichTextEditorCommentThread({
          ...data,
          parentId,
          parentType,
        });
        return {
          threadId: res.threadId,
          comments: res.comments.map((comment) => ({
            commentId: comment.commentId,
            createdAt: new Date(comment.createdAt),
          })),
        };
      } catch (e) {
        sendErrorToast('Failed to add comment thread. Please try again.');
        return {
          ...INITIAL_RICH_TEXT_EDITOR_COMMENT_THREAD_ADDITION_RESPONSE,
          threadId: data.threadId,
        };
      }
    },
    getCommentThread: async ({
      threadId,
    }: {
      threadId: string;
    }): Promise<RichTextEditorCommentThread> => {
      try {
        const res = await getRichTextEditorCommentThread({
          threadId,
          parentId,
          parentType,
        });
        res.comments = res.comments.map((comment) => ({
          ...comment,
          createdAt: new Date(comment.createdAt),
        }));
        return res;
      } catch (e) {
        sendErrorToast('Failed to retrieve comment thread. Please try again.');
        return {
          ...INITIAL_RICH_TEXT_EDITOR_COMMENT_THREAD,
          threadId,
        };
      }
    },
    updateCommentThread: async (): Promise<void> => {
      sendErrorToast(
        'Failed to update comment thread. API is not implemented.',
      );
      return Promise.resolve();
    },
    resolveCommentThread: async (
      data: RichTextEditorCommentThread,
    ): Promise<RichTextEditorCommentThreadResolve> => {
      try {
        const res: RichTextEditorCommentThread =
          await resolveRichTextEditorCommentThread({
            threadId: data.threadId,
            parentId,
            parentType,
          });

        if (res.resolvedAt !== null && res.resolvedBy !== null) {
          return {
            resolvedAt: new Date(res.resolvedAt),
            resolvedBy: res.resolvedBy,
          };
        }

        throw new Error();
      } catch (e) {
        sendErrorToast('Failed to resolve comment thread. Please try again.');
        return {
          resolvedAt: null,
          resolvedBy: null,
        };
      }
    },
    reopenCommentThread: async (
      data: RichTextEditorCommentThread,
    ): Promise<void> => {
      try {
        return await reopenRichTextEditorCommentThread({
          threadId: data.threadId,
          parentId,
          parentType,
        });
      } catch (e) {
        sendErrorToast('Failed to reopen comment thread. Please try again.');
        return undefined;
      }
    },
    removeCommentThread: async (
      data: RichTextEditorCommentThread,
    ): Promise<void> => {
      try {
        return await deleteRichTextEditorCommentThread({
          threadId: data.threadId,
          parentId,
          parentType,
        });
      } catch (e) {
        sendErrorToast('Failed to delete comment thread. Please try again.');
        return undefined;
      }
    },
  };
};
