import { NARRATIVES_SLICE_NAME } from 'app/modules/narratives/sliceNarratives';

export const selectNarratives = (state: RootState) =>
  state[NARRATIVES_SLICE_NAME].narratives;

export const selectLoadingNarratives = (state: RootState) =>
  state[NARRATIVES_SLICE_NAME].loadingNarratives;

export const selectLoadingCreateEditDeleteNarrative = (state: RootState) =>
  state[NARRATIVES_SLICE_NAME].loadingCreateEditDeleteNarrative;
