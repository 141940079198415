import { useDispatch, useSelector } from 'react-redux';

// Components
import { IconUser } from '@u21/tabler-icons';

import { U21Chip } from 'app/shared/u21-ui/components';

// Selectors
import { selectHasReadEntitiesPermissions } from 'app/modules/session/selectors';

// Actions
import { toggleSidebar } from 'app/modules/sidebar/slice';

// Models
import { SidebarComponentTypes } from 'app/modules/sidebar/models';

interface OwnProps {
  internalId?: number;
  externalId?: string;
}

/**
 * @deprecated use U21EntityChip instead
 */
export const EntityChip = ({ internalId, externalId }: OwnProps) => {
  const dispatch = useDispatch();
  const hasReadEntitiesPermission = useSelector(
    selectHasReadEntitiesPermissions,
  );
  const entityString = externalId ?? `Entity #${internalId}`;

  if (!internalId && !externalId) {
    return null;
  }

  if (!hasReadEntitiesPermission) {
    return <U21Chip>{entityString}</U21Chip>;
  }

  return (
    <U21Chip
      icon={<IconUser />}
      onClick={(e) => {
        e.stopPropagation();
        dispatch(
          toggleSidebar({
            type: SidebarComponentTypes.ENTITY,
            data: { id: internalId || '', externalId },
          }),
        );
      }}
    >
      {entityString}
    </U21Chip>
  );
};
