import { U21Subsection } from 'app/shared/u21-ui/components';
import { IconTag } from '@u21/tabler-icons';
import { U21DataLabelSelect } from 'app/shared/u21-ui/components/dashboard';

interface Props {
  ids: number[];
}

export const SidebarDataLabels = (props: Props) => {
  const { ids } = props;
  return (
    <U21Subsection
      collapsed
      collapsible
      shaded
      title="Tags"
      titleIcon={<IconTag />}
    >
      <U21DataLabelSelect
        disabled
        label={undefined}
        onChange={() => {}}
        placeholder="No tags found"
        startIcon={undefined}
        value={ids}
      />
    </U21Subsection>
  );
};
