function compareByKeyImmer(a, b, key: string, ascending: boolean): number {
  if (a[key] < b[key]) return ascending ? -1 : 1;
  if (a[key] > b[key]) return ascending ? 1 : -1;
  return 0;
}

export function sortByObjValImmer(
  xs: any[],
  key: string,
  ascending: boolean = false,
) {
  // spread operator needed here to ensure it does not mutate the original array
  return [...xs].sort((a, b) => {
    return compareByKeyImmer(a, b, key, ascending);
  });
}
