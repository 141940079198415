import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CreateNarrativePayload } from 'app/modules/narratives/requests';
import { sendErrorToast, sendSuccessToast } from 'app/shared/toasts/actions';
import { post } from 'app/shared/utils/fetchr';
import { NARRATIVE_QUERY_KEYS } from 'app/modules/narratives/queries/keys';

export const useCreateNarrative = (id: number, queueID?: number | null) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: (
      payload: Omit<CreateNarrativePayload, 'article_id' | 'queue_id'>,
    ) =>
      post('/narratives', {
        ...payload,
        article_id: id,
        queue_id: queueID ?? undefined,
      }),
    onSuccess: () => {
      dispatch(sendSuccessToast('Successfully added narrative'));
      queryClient.invalidateQueries({
        queryKey: NARRATIVE_QUERY_KEYS.getNarratives({
          article_id: id,
        }),
      });
    },
    onError: () =>
      dispatch(sendErrorToast('Failed to add narrative. Please try again')),
  });
};
