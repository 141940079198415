import { FormFieldSchema } from 'app/shared/models/form';
import { U21FormFieldTypes } from 'app/shared/u21-ui/components';
import { required } from 'app/shared/utils/validation';
import { parseOutFilename } from 'app/modules/attachmentsRefresh/utils';

const DOCUMENT_NAME_FIELD: FormFieldSchema<string> = {
  default: (document) => parseOutFilename(document.name || '')[0],
  fieldProps: { autoFocus: true },
  label: 'Name',
  name: 'name',
  required: true,
  type: U21FormFieldTypes.TEXT,
  validate: required('Document name is required.'),
};

const DOCUMENT_DESCRIPTION_FIELD: FormFieldSchema<string> = {
  label: 'Description',
  name: 'description',
  type: U21FormFieldTypes.TEXTAREA,
};

export const EDIT_FILE_SCHEMA = [
  DOCUMENT_NAME_FIELD,
  DOCUMENT_DESCRIPTION_FIELD,
];
