import { ReactNode, useMemo } from 'react';

// components
import { Timeline } from 'react-event-timeline';
import {
  U21Alert,
  U21Button,
  U21Loading,
  U21NoData,
} from 'app/shared/u21-ui/components';

// queries
import { AuditTrailEntry } from 'app/modules/auditService/components/AuditTrailEntry';
import { useAuditServiceList } from 'app/modules/auditService/queries/useAuditServiceList';

// types
import {
  AuditTrailAssetType,
  AuditServiceEntry,
} from 'app/modules/auditService/types';
import { getFormattedAssetName } from 'app/modules/auditService/constants/auditTrailStrings';
import { formatDate } from 'app/shared/utils/date';
import { AUDIT_LOG_DOCS } from 'app/shared/constants/docs';
import { U21_DOCS_PASSWORD_TOOLTIP } from 'app/shared/u21-ui/components/dashboard';

// We can not show any action taken after this date
export const FIRST_AUDIT_ENTRY_TIMESTAMP = new Date('2023-07-01');

interface Props {
  assetType: AuditTrailAssetType;
  assetId: string;
  // Optional prop to show a warning if the asset was created before the first audit entry
  assetCreatedAt?: string;
}

/** A filter function to be use for an
 * array of `AuditServiceEntry`. This improves
 * the UI/UX of audit trails by preventing long
 * contiguous audits of the same action, such
 * as narrative edits (since they auto save).
 * If elements n and n+1 are both narrative edit
 * audits, remove n+1, for all n.
 */
const auditLogCompressor = (
  currentLog: AuditServiceEntry,
  index: number,
  array: AuditServiceEntry[],
): boolean => {
  /* Since we need a window size of 2 and
   * we look "back" to make the window, the first
   * element is _always_ carried through
   */
  if (index === 0) {
    return true;
  }

  /* If the current element and previous element are
   * one of the "filterable" audits, filter out the
   * current element
   */
  if (
    currentLog.action === 'alert.narrative_edit' &&
    array[index - 1].action === 'alert.narrative_edit'
  ) {
    return false;
  }
  if (
    currentLog.action === 'case.narrative_edit' &&
    array[index - 1].action === 'case.narrative_edit'
  ) {
    return false;
  }

  // Passing all if guards means it can stay in the array
  return true;
};

export const AuditTrail = ({ assetType, assetId, assetCreatedAt }: Props) => {
  const { data, isLoading } = useAuditServiceList(assetType, assetId);

  /* This has to come _first_ before any rendering, the
   * early returning messes with react.
   */
  const compressedAuditLogs = useMemo(() => {
    if (data === undefined) {
      return [];
    }
    // This removes _some_ audit logs for readability.
    // Read the function for more details
    return data.auditLogs.filter(auditLogCompressor);
  }, [data]);

  const auditTrailWarningAlert: ReactNode = useMemo(() => {
    if (assetCreatedAt) {
      const assetCreatedBeforeFirstAuditRecord =
        new Date(assetCreatedAt) < FIRST_AUDIT_ENTRY_TIMESTAMP;
      if (assetCreatedBeforeFirstAuditRecord) {
        // wrap in an extra div - U21Alert has min-height 0 for collapsing
        // which causes Timeline to squeeze this if there is enough content
        return (
          <div>
            <U21Alert severity="warning">
              The {getFormattedAssetName(assetType)} was created before{' '}
              {formatDate(FIRST_AUDIT_ENTRY_TIMESTAMP)}. The audit trail may not
              show all actions taken, if you need historical audits reach out to
              Unit21 Support.
            </U21Alert>
          </div>
        );
      }
    }
    return null;
  }, [assetCreatedAt, assetType]);

  if (isLoading) return <U21Loading loading />;
  if (!data || data.auditLogs.length === 0)
    return (
      <>
        {auditTrailWarningAlert}
        <U21NoData />
      </>
    );

  return (
    <>
      {auditTrailWarningAlert}
      <Timeline>
        {compressedAuditLogs.map((entry) => (
          <AuditTrailEntry key={entry.createdAt} {...entry} />
        ))}
      </Timeline>
      {data.auditLogs.length >= 100 && (
        <U21Alert severity="info">
          Only the 100 most recent audit logs are available in the app. You can
          access older audit logs by using the Audit Service. Click{' '}
          <U21Button
            color="primary"
            href={AUDIT_LOG_DOCS}
            tooltip={U21_DOCS_PASSWORD_TOOLTIP}
            variant="text"
          >
            here
          </U21Button>{' '}
          for more information.
        </U21Alert>
      )}
    </>
  );
};
