import React, { Component } from 'react';
import autoBind from 'auto-bind';
import { debounce } from 'lodash';

// Redux
import { connect } from 'react-redux';

// Constants
import {
  DEFAULT_OFFSET,
  DEFAULT_PAGINATION_LIMIT,
} from 'app/shared/pagination/constants';
import { TXN_DATETIME_FORMAT } from 'app/shared/constants';

// Components
import ProgressBar from 'app/shared/components/ProgressBar';
import { U21Chip } from 'app/shared/u21-ui/components';

// Actions
import { retrieveEntityLinkAnalysis as retrieveEntityLinkAnalysisAction } from 'app/modules/entities/actions';

// Selectors
import {
  selectLinkAnalysisLoading,
  selectEntityLinkAnalysisReport,
} from 'app/modules/entities/selectors';

// Utils
import { getLocalFormat } from 'app/shared/utils/timeHelpers';

// Styles
import styles from 'app/modules/linkAnalysis/styles/LinkAnalysis.module.scss';
import { isLinkAnalysisPending } from 'app/modules/linkAnalysis/utils';

const mapStateToProps = (state: RootState) => {
  return {
    linkAnalysisLoading: selectLinkAnalysisLoading(state),
    linkAnalysis: selectEntityLinkAnalysisReport(state),
  };
};

const mapDispatchToProps = {
  retrieveEntityLinkAnalysis: retrieveEntityLinkAnalysisAction,
};

interface OwnProps {
  entityId: number | string;
  onComplete?: () => void;
}

interface AllState {
  isSubsequentUpdate: boolean;
}

const initialState: AllState = {
  isSubsequentUpdate: false,
};

type AllProps = OwnProps &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

class LinkAnalysisJobState extends Component<AllProps, AllState> {
  constructor(props) {
    super(props);
    this.state = { ...initialState };

    autoBind(this);
    this.pollUpdate = debounce(this.pollUpdate, 3000);
  }

  componentDidMount() {
    this.retrieveDefault();
  }

  componentDidUpdate(prevProps: AllProps) {
    const { entityId, linkAnalysis, onComplete } = this.props;
    const { isSubsequentUpdate } = this.state;
    if (isSubsequentUpdate && !this.hasPendingJob()) {
      this.setState({
        isSubsequentUpdate: false,
      });
    }
    const wasPreviouslyLoading =
      prevProps?.linkAnalysis?.status === 'PENDING' ||
      prevProps?.linkAnalysis?.status === 'STARTED';
    const noLongerLoading = linkAnalysis?.status === 'COMPLETED';
    if (wasPreviouslyLoading && noLongerLoading) {
      onComplete?.();
    }

    // Reset state and retrieve new data if entityId has changed
    if (prevProps.entityId !== entityId) {
      this.setState({ ...initialState }, () => this.retrieveDefault());
    }
  }

  hasPendingJob(): boolean {
    const { linkAnalysis } = this.props;
    return Boolean(linkAnalysis && isLinkAnalysisPending(linkAnalysis));
  }

  retrieveDefault() {
    const { entityId, retrieveEntityLinkAnalysis } = this.props;
    retrieveEntityLinkAnalysis({
      entityId,
      offset: DEFAULT_OFFSET,
      limit: DEFAULT_PAGINATION_LIMIT,
    });
  }

  pollUpdate() {
    if (this.hasPendingJob()) {
      this.setState(
        {
          isSubsequentUpdate: true,
        },
        this.retrieveDefault,
      );
    }
  }

  renderLinkAnalysisJobState() {
    const { linkAnalysis, linkAnalysisLoading } = this.props;
    if (!linkAnalysis) {
      return 'No Job Queued';
    } else if (this.hasPendingJob()) {
      return (
        <ProgressBar
          hasPendingJob={this.hasPendingJob()}
          loading={linkAnalysisLoading}
          totalSteps={linkAnalysis.total_queries}
          stepsDone={linkAnalysis.queries_done}
          pollUpdate={this.pollUpdate}
        />
      );
    }
    return null;
  }

  renderJobLabel() {
    const { linkAnalysis } = this.props;
    if (!linkAnalysis) {
      return null;
    }
    if (this.hasPendingJob()) {
      return (
        <h4>
          Link Analysis report is{' '}
          <U21Chip color="info">{linkAnalysis.status}</U21Chip> queued at{' '}
          {getLocalFormat(
            linkAnalysis.created_at,
            TXN_DATETIME_FORMAT || 'lll',
          )}
        </h4>
      );
    }
    if (linkAnalysis.status === 'COMPLETED') {
      return (
        <h4>
          Link Analysis report is{' '}
          <U21Chip color="success">{linkAnalysis.status}</U21Chip> relevant as
          of{' '}
          {getLocalFormat(
            linkAnalysis.created_at,
            TXN_DATETIME_FORMAT || 'lll',
          )}
        </h4>
      );
    }
    return null;
  }

  render() {
    const { linkAnalysisLoading } = this.props;
    const { isSubsequentUpdate } = this.state;
    // first load
    if (!isSubsequentUpdate && linkAnalysisLoading) {
      return null;
    }

    return (
      <>
        <div className={styles.modalOpenButtonContainer}>
          {this.renderJobLabel()}
        </div>
        {this.renderLinkAnalysisJobState()}
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LinkAnalysisJobState);
