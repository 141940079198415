import React, { useState } from 'react';
import { Prompt } from 'react-router';

// Helpers
import { Editor as RichTextEditor } from 'react-draft-wysiwyg';
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
  RawDraftContentState,
} from 'draft-js';
import { toolbar } from 'app/modules/notes/config';

// Styles
import 'app/modules/notes/styles/react-draft-wysiwyg.css';
import styles from 'app/modules/notes/styles/NoteSection.module.scss';

// Components
import GenericButton from 'app/shared/components/GenericButton';

// Utils
import {
  setNoteStateCache,
  getNoteStateCache,
  removeNoteStateCache,
} from 'app/shared/utils/localStorage';
import { createSentryError } from 'app/shared/utils/sentry';

const getInitialEditorState = (
  initialState: EditorState,
  noteId: number | undefined,
  saveToLocalStorage: boolean | undefined,
) => {
  if (saveToLocalStorage && noteId) {
    try {
      const locallyCachedContent = getNoteStateCache(noteId);
      // If we have locally cached content, use it. Otherwise we use empty state from bottom of function.
      if (locallyCachedContent) {
        return EditorState.createWithContent(
          convertFromRaw(locallyCachedContent),
        );
      }
    } catch (e) {
      createSentryError({
        error: `Unable to pull Editor content from local storage for note (id: ${noteId}`,
      });
    }
  }
  return initialState || EditorState.createEmpty();
};

function Editor({
  initialState,
  addNote,
  noteId,
  saveToLocalStorage,
  updateNote,
  cancelEdit,
}: {
  initialState: EditorState;
  addNote?: (content: RawDraftContentState) => any;
  noteId?: number;
  saveToLocalStorage?: boolean;
  updateNote?: (content: RawDraftContentState) => any;
  cancelEdit?: () => any;
}) {
  const [editorState, setEditorState] = useState(
    getInitialEditorState(initialState, noteId, saveToLocalStorage),
  );

  const handleChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState);
    if (saveToLocalStorage) {
      if (editorState.getCurrentContent().getPlainText() === '') {
        removeNoteStateCache(noteId);
      } else {
        setNoteStateCache(
          noteId,
          convertToRaw(newEditorState.getCurrentContent()),
        );
      }
    }
  };

  const resetState = () => {
    setEditorState(EditorState.createEmpty());
    if (saveToLocalStorage) {
      removeNoteStateCache(noteId);
    }
  };

  const getRawContent = () => {
    return convertToRaw(editorState.getCurrentContent());
  };

  return (
    <>
      <Prompt
        when={editorState.getCurrentContent().getPlainText() !== ''}
        message="Are you sure you want to leave without saving?"
      />
      <RichTextEditor
        editorState={editorState}
        onEditorStateChange={handleChange}
        toolbar={toolbar}
        spellCheck
      />
      <div className={styles.editorButtonBar}>
        {cancelEdit && (
          <GenericButton
            color="red"
            className={styles.editorButton}
            onClick={cancelEdit}
          >
            Cancel
          </GenericButton>
        )}

        {updateNote && (
          <GenericButton
            positive
            disabled={!editorState.getCurrentContent().hasText()}
            className={styles.editorButton}
            onClick={() => updateNote(getRawContent())}
          >
            Update
          </GenericButton>
        )}

        {addNote && (
          <GenericButton
            positive
            disabled={!editorState.getCurrentContent().hasText()}
            onClick={() => {
              addNote(getRawContent());
              resetState();
            }}
          >
            Add Note
          </GenericButton>
        )}
      </div>
    </>
  );
}

export default Editor;
