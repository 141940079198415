import { ExportStatuses, ExportTypes } from 'app/modules/fileExports/responses';
import { U21ChipColor } from 'app/shared/u21-ui/components';

export const EXPORT_TYPE_LABELS: Record<ExportTypes, string> = {
  [ExportTypes.ALERT]: 'Alerts',
  [ExportTypes.ALERT_CSV]: 'Alerts (CSV)',
  [ExportTypes.CASE]: 'Cases',
  [ExportTypes.CASE_CSV]: 'Cases (CSV)',
  [ExportTypes.CHECKLIST]: 'Checklists',
  [ExportTypes.CHECKLIST_CSV]: 'Checklists (CSV)',
  [ExportTypes.DEVICES]: 'Devices',
  [ExportTypes.DEVICES_CSV]: 'Devices (CSV)',
  [ExportTypes.ENTITIES]: 'Entities',
  [ExportTypes.ENTITIES_CSV]: 'Entities (CSV)',
  [ExportTypes.EVENTS]: 'Events',
  [ExportTypes.EVENTS_CSV]: 'Events (CSV)',
  [ExportTypes.INSTRUMENTS]: 'Instruments',
  [ExportTypes.INSTRUMENTS_CSV]: 'Instruments (CSV)',
  [ExportTypes.RULE]: 'Rules',
  [ExportTypes.RULE_CSV]: 'Rules (CSV)',
  [ExportTypes.RULE_VALIDATION]: 'Rule Validations',
  [ExportTypes.RULE_VALIDATION_CSV]: 'Rule Validations (CSV)',
  [ExportTypes.SAR_FILING]: 'SAR Filings',
  [ExportTypes.SAR_FILING_CSV]: 'SAR Filings (CSV)',
  [ExportTypes.TRANSACTIONS]: 'Transactions',
  [ExportTypes.TRANSACTIONS_CSV]: 'Transactions (CSV)',
  [ExportTypes.CRR_CSV]: 'Risk Rating',
};

export const EXPORT_STATUS_LABEL_COLORS: Record<ExportStatuses, U21ChipColor> =
  {
    [ExportStatuses.CANCELED]: 'error',
    [ExportStatuses.FAILED]: 'error',
    [ExportStatuses.GENERATING]: 'default',
    [ExportStatuses.READY_FOR_DOWNLOAD]: 'success',
    [ExportStatuses.REQUESTED]: 'default',
  };
