import { useQuery } from '@tanstack/react-query';
import { RICH_TEXT_EDITOR_QUERY_KEYS } from 'app/shared/components/RichTextEditor/queries/keys';
import { GetRichTextEditorAgentPayload } from 'app/shared/components/RichTextEditor/requests';
import { RichTextEditorAgents } from 'app/shared/components/RichTextEditor/responses';

import { post } from 'app/shared/utils/fetchr';

const getRichTextEditorAgents = (
  payload: GetRichTextEditorAgentPayload,
): Promise<RichTextEditorAgents[]> =>
  post(`/inline-comments/get-agents`, payload);

export const useFetchAllRichTextEditorAgents = (
  body: GetRichTextEditorAgentPayload,
) => {
  return useQuery({
    initialData: [],
    enabled: body.parentId !== -1,
    queryKey: RICH_TEXT_EDITOR_QUERY_KEYS.getFetchAllRichTextEditorAgents(body),
    queryFn: () => getRichTextEditorAgents(body),
    meta: { errorMessage: 'Unable to fetch agents for comments' },
  });
};
