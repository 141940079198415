// https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
// https://developer.mozilla.org/en-US/docs/Web/API/URL

// check if string is a valid url
export function isValidHttpUrl(value?: string) {
  if (typeof value !== 'string') {
    return false;
  }
  let url;
  try {
    url = new URL(value);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}
