import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { sendErrorToast, sendSuccessToast } from 'app/shared/toasts/actions';
import { CASE_QUERY_KEYS } from 'app/modules/cases/queries/keys';
import { post } from 'app/shared/utils/fetchr';
import { InvestigationActionPayload } from 'app/modules/investigations/types/requests';

export const useRequeueCase = (ids: number[]) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: (queueID: number) =>
      post('/investigations/action', {
        object_ids: ids,
        object_type: 'CASE',
        investigation_queue_id: queueID,
      } satisfies InvestigationActionPayload),
    onError: () =>
      dispatch(
        sendErrorToast('Failed to change case queue. Please try again.'),
      ),
    onSuccess: () => {
      dispatch(sendSuccessToast('Successfully changed case queue.'));
      ids.forEach((i) => {
        queryClient.invalidateQueries({
          queryKey: CASE_QUERY_KEYS.getCase(i),
        });
      });
    },
  });
};
