import React from 'react';

// Utils
import emptyFn from 'app/shared/utils/empty-fn';
import { createSentryError } from 'app/shared/utils/sentry';
import { formatDatetime, formatDateToNow } from 'app/shared/utils/date';
import styled, { css } from 'styled-components';

// Styles
import styles from 'app/modules/notes/styles/NoteSection.module.scss';
import 'app/modules/notes/styles/react-draft-wysiwyg.css';

// Components
import NoteContainer from 'app/modules/notes/components/NoteContainer';
import Editor from 'app/modules/notes/components/Editor';
import {
  Editor as HtmlRenderer,
  EditorState,
  convertFromRaw,
  RawDraftContentState,
} from 'draft-js';
import { Button, Icon } from 'semantic-ui-react';
import { NoteModel } from 'app/modules/notes/models';
import GenericButton from 'app/shared/components/GenericButton';
import { U21Spacer, U21Typography } from 'app/shared/u21-ui/components';

const getEditorState = (content: RawDraftContentState, note: NoteModel) => {
  try {
    return EditorState.createWithContent(convertFromRaw(content));
  } catch (e) {
    createSentryError({
      error: `Unable to decode note (id: ${note.id} created at: ${
        note.created_at
      } ) with content: ${JSON.stringify(content)}`,
    });
  }
  return EditorState.createEmpty();
};

interface OwnProps {
  note: NoteModel;
  updateNote: (id: number, content: RawDraftContentState) => any;
  deleteNote: (id: number) => any;
  toggleEditMode?: (note: NoteModel) => any;
  toggleDeleteMode?: (note: NoteModel) => any;
  disableFromNowTimeFormat?: boolean;
}

export const Note = ({
  note,
  updateNote,
  deleteNote,
  toggleEditMode,
  toggleDeleteMode,
  disableFromNowTimeFormat,
}: OwnProps) => {
  const { author, content: noteContent, created_at: noteCreatedAt } = note;

  const editorState = getEditorState(noteContent, note);

  const relativeDate = formatDateToNow(noteCreatedAt, { addSuffix: true });
  const absoluteDate = formatDatetime(noteCreatedAt);

  const createdAt = !disableFromNowTimeFormat ? relativeDate : absoluteDate;
  return (
    <NoteContainer author={author}>
      {!note.editMode && (
        <div className={styles.noteHeader}>
          <StyledU21Spacer horizontal>
            <U21Typography variant="caption">{author.full_name}</U21Typography>
            <StyledU21Typography
              color="text.secondary"
              tooltip={absoluteDate}
              variant="caption"
            >
              {createdAt}
            </StyledU21Typography>
          </StyledU21Spacer>
          {!note.deleteMode && (
            <div>
              {toggleEditMode && (
                <Button
                  className={styles.noteActionButton}
                  onClick={() => toggleEditMode && toggleEditMode(note)}
                >
                  <Icon name="pencil alternate" />
                </Button>
              )}
              {toggleDeleteMode && (
                <Button
                  className={styles.noteActionButton}
                  onClick={() => toggleDeleteMode && toggleDeleteMode(note)}
                >
                  <Icon name="trash alternate" />
                </Button>
              )}
            </div>
          )}
        </div>
      )}

      {!note.editMode && (
        <div className={styles.notesDraftJS}>
          <HtmlRenderer editorState={editorState} onChange={emptyFn} readOnly />
        </div>
      )}

      {note.deleteMode && (
        <div className={styles.editorButtonBar}>
          <GenericButton
            color="red"
            className={styles.editorButton}
            onClick={() => toggleDeleteMode && toggleDeleteMode(note)}
          >
            Cancel
          </GenericButton>

          <GenericButton
            positive
            className={styles.editorButton}
            onClick={() => deleteNote(note.id)}
          >
            Confirm
          </GenericButton>
        </div>
      )}

      {note.editMode && (
        <Editor
          initialState={editorState}
          updateNote={(content) => {
            updateNote(note.id, content);
          }}
          cancelEdit={() => toggleEditMode && toggleEditMode(note)}
        />
      )}
      <div className={styles.noteConnectingLine} />
    </NoteContainer>
  );
};

const StyledU21Spacer = styled(U21Spacer)`
  flex: 1 1 auto;
  padding: 8px 0 8px 48px;
`;

const StyledU21Typography = styled(U21Typography)`
  &:hover {
    text-decoration: underline;
    ${(props) => css`
      text-decoration-color: ${props.theme.palette.grey[500]};
    `}
  }
`;
