import { sendErrorToast } from 'app/shared/toasts/actions';
import { useDispatch } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { getFileDownloadResponse } from 'app/shared/utils/fetchr';
import { downloadBlobFromResponse } from 'app/shared/utils/apiUtils';

const downloadBatchFile = async (id: number) => {
  return getFileDownloadResponse(`/filings/batch/${id}/download`);
};

export const useDownloadFilingsBatch = (batchId: number) => {
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: () => downloadBatchFile(batchId),
    onError: () => {
      dispatch(sendErrorToast('Failed to download batch.'));
    },
    onSuccess: async (response) => {
      if (response.status === 201) {
        const fileName = `CtrBatch${batchId}.xml`;
        await downloadBlobFromResponse(response, fileName);
      } else if (response.status === 202) {
        dispatch(sendErrorToast('Batch generation pending'));
      } else {
        dispatch(sendErrorToast('Failed to download batch.'));
      }
    },
  });
};
