import { EntitiesConsortiumHitsTable } from 'app/modules/entitiesRefresh/pages/EntitiesConsortiumHitsTable';
import { useFetchEntitiesConsortiumHits } from 'app/modules/entitiesRefresh/queries/useFetchEntitiesConsortiumHits';
import { selectInvestigationCase } from 'app/modules/investigations/selectors/investigations';
import { U21Loading, U21NoData } from 'app/shared/u21-ui/components';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

export const CaseConsortiumHitsTab = () => {
  const { entities } = useSelector(selectInvestigationCase);
  const externalIDs = useMemo(
    () => entities.map((entity) => entity.external_id),
    [entities],
  );
  const { data: consortiumHits, isLoading } =
    useFetchEntitiesConsortiumHits(externalIDs);
  if (entities.length === 0) {
    return <U21NoData />;
  }
  if (isLoading) {
    return <U21Loading loading />;
  }
  if (!consortiumHits) {
    return <U21NoData />;
  }
  return <EntitiesConsortiumHitsTable consortiumHits={consortiumHits.hits} />;
};
