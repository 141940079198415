import { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { selectThemeMode } from 'app/modules/theme/selectors';
import { U21Theme } from 'vendor/material-minimal';

interface Props {
  children: ReactNode;
}

export const U21ThemeWrapper = ({ children }: Props) => {
  const themeMode = useSelector(selectThemeMode);
  return <U21Theme mode={themeMode}>{children}</U21Theme>;
};
