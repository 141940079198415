import {
  RichTextEditorComment,
  RichTextEditorCommentThread,
} from 'app/shared/components/RichTextEditor/models';
import {
  RichTextEditorCommentPayload,
  RichTextEditorCommentThreadPayload,
  RichTextEditorCommentThreadIdPayload,
  AddCommentToNarrativePayload,
} from 'app/shared/components/RichTextEditor/requests';
import { post } from 'app/shared/utils/fetchr';

export const addRichTextEditorComment = (
  payload: RichTextEditorCommentPayload,
): Promise<RichTextEditorComment> =>
  post('/inline-comments/comment/add', payload);

export const updateRichTextEditorComment = (
  payload: RichTextEditorCommentPayload,
): Promise<void> => post('/inline-comments/comment/update', payload);

export const deleteRichTextEditorComment = (
  payload: RichTextEditorCommentPayload,
): Promise<void> => post('/inline-comments/comment/delete', payload);

export const addRichTextEditorCommentThread = (
  payload: RichTextEditorCommentThreadPayload,
): Promise<RichTextEditorCommentThread> =>
  post('/inline-comments/thread/add', payload);

export const getRichTextEditorCommentThread = (
  payload: RichTextEditorCommentThreadIdPayload,
): Promise<RichTextEditorCommentThread> =>
  post(`/inline-comments/thread/get`, payload);

export const resolveRichTextEditorCommentThread = (
  payload: RichTextEditorCommentThreadIdPayload,
): Promise<RichTextEditorCommentThread> =>
  post(`/inline-comments/thread/resolve`, payload);

export const reopenRichTextEditorCommentThread = (
  payload: RichTextEditorCommentThreadIdPayload,
): Promise<void> => post(`/inline-comments/thread/reopen`, payload);

export const deleteRichTextEditorCommentThread = (
  payload: RichTextEditorCommentThreadIdPayload,
): Promise<void> => post(`/inline-comments/thread/delete`, payload);

export const addCommentToNarrative = (
  payload: AddCommentToNarrativePayload,
): Promise<void> => post(`/inline-comments/add-comment-to-narrative`, payload);
