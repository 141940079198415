import { useState } from 'react';
import { createPortal } from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';

import styled from 'styled-components';

import {
  U21Button,
  U21Card,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { CreateTeamButtonModal } from 'app/modules/teams/components/CreateTeamButtonModal';
import { IconLock, IconX } from '@u21/tabler-icons';

import {
  selectAllUsedPermissions,
  selectCurrentRouteUsedPermissions,
} from 'app/modules/devtools/selectors';
import { resetPermissions } from 'app/modules/devtools/sliceDevtools';

export const PermissionsAuditorControl = () => {
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();
  const allPermissions = useSelector(selectAllUsedPermissions);
  const currentRoutePermissions = useSelector(
    selectCurrentRouteUsedPermissions,
  );

  const toggleOpen = () => setOpen(!open);
  const onResetClick = () => dispatch(resetPermissions());

  return createPortal(
    <>
      <StyledU21Button
        aria-label="Show permission auditor"
        size="large"
        icon={open ? <IconX /> : <IconLock />}
        onClick={toggleOpen}
      />
      {open && (
        <StyledU21Card
          title={
            <U21Typography variant="h5">Permissions Auditor</U21Typography>
          }
          action={
            <ButtonContainer>
              <U21Button onClick={onResetClick}>Reset</U21Button>
              <CreateTeamButtonModal includeAuditorPermissionSet />
            </ButtonContainer>
          }
        >
          <ContentContainer>
            <Column>
              <U21Typography variant="h6">
                Current Page Permissions
              </U21Typography>
              {currentRoutePermissions.map((permission) => (
                <U21Typography key={permission} variant="body2">
                  {permission}
                </U21Typography>
              ))}
            </Column>
            <Column>
              <U21Typography variant="h6">All Permissions</U21Typography>
              {Object.entries(allPermissions).map(([permission]) => (
                <U21Typography key={permission} variant="body2">
                  {permission}
                </U21Typography>
              ))}
            </Column>
          </ContentContainer>
        </StyledU21Card>
      )}
    </>,
    document.body,
  );
};

const StyledU21Button = styled(U21Button)`
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: ${(props) => props.theme.palette.grey[300]};
  z-index: 1000;
  margin: 30px;
`;

const StyledU21Card = styled(U21Card)`
  position: fixed;
  bottom: 10pt;
  right: 10pt;
  height: 33%;
  width: 25%;
  min-width: 450px;
  min-height: 330px;
  padding-bottom: 50pt;
  overflow: auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;
