import { createSelector } from 'reselect';

// Selectors
import { selectHasGoogleDriveToken } from 'app/modules/session/selectors';
import {
  selectStorageConfig,
  selectStorageResources,
} from 'app/modules/orgSettings/selectors';
import { StorageResource } from 'app/modules/attachments/models';

const ALL_STORAGE_RESOURCES = [StorageResource.S3, StorageResource.GDRIVE];
const selectNormalizedStorageResources = createSelector(
  selectStorageResources,
  (storageResources) => {
    // if empty, assume everything is enabled
    if (!storageResources.length) {
      return ALL_STORAGE_RESOURCES;
    }
    return storageResources;
  },
);

export const selectHasGDriveEnabled = createSelector(
  selectHasGoogleDriveToken,
  selectStorageConfig,
  selectNormalizedStorageResources,
  (hasGDriveToken, storageConfig, storageResources) => {
    return (
      hasGDriveToken &&
      Boolean(storageConfig.folder_id) &&
      storageResources.includes(StorageResource.GDRIVE)
    );
  },
);

export const selectHasS3Enabled = createSelector(
  selectNormalizedStorageResources,
  (storageResources) => storageResources.includes(StorageResource.S3),
);
