import { GetTransactionsPayload } from 'app/modules/transactions/requests';
import { GetTransactionsResponse } from 'app/modules/transactions/responses';

import { TRANSACTION_QUERY_KEYS } from 'app/modules/transactions/queries/keys';

import { post } from 'app/shared/utils/fetchr';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { selectHasReadEventsPermission } from 'app/modules/session/selectors';

export const useGetTransactions = (payload: GetTransactionsPayload) => {
  const hasReadEventsPermission = useSelector(selectHasReadEventsPermission);

  return useQuery({
    queryFn: () => post<GetTransactionsResponse>('/txn-events/list', payload),
    queryKey: [...TRANSACTION_QUERY_KEYS.getTransactions(payload)],
    enabled: hasReadEventsPermission,
  });
};
