import React, { Component, ReactNode } from 'react';
import autoBindReact from 'auto-bind/react';
import moment from 'moment';
import { isEmpty, debounce } from 'lodash';
import { withRouter, RouteComponentProps } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// Components
import { Image } from 'semantic-ui-react';
import { Timeline, TimelineEvent } from 'react-event-timeline';
import { U21AgentMentions } from 'app/shared/u21-ui/components/dashboard';
import NoResultsComponent from 'app/shared/components/NoResultsComponent';
import GenericButton from 'app/shared/components/GenericButton';

// Actions
import {
  addComment as addCommentAction,
  retrieveComments as retrieveCommentsAction,
  resetComments as resetCommentsAction,
} from 'app/modules/comments/actions';
import { retrieveDropdownAgents as retrieveDropdownAgentsAction } from 'app/shared/dropdown/actions';

// Models
import { TimelineTimeFormat, CommentModel } from 'app/modules/comments/models';

// constants
import {
  DEFAULT_SEARCH_PAGINATION_LIMIT,
  DEFAULT_OFFSET,
} from 'app/shared/pagination/constants';

// Selectors
import {
  selectComments,
  selectCommentsLoading,
  selectAddCommentLoading,
} from 'app/modules/comments/selectors';
import { selectAgent } from 'app/modules/session/selectors';
import { selectDropdownAgents } from 'app/shared/dropdown/selectors';

// Style
import { StyleConstants } from 'app/styles/StyleConstants';
import scssStyles from 'app/modules/comments/styles/CommentSection.module.scss';
import styled from 'styled-components';

// Etc
import { commentsConfig } from 'app/modules/comments/config';
import { getA11yClickProps } from 'app/shared/utils/a11y';
import { sortByObjValImmer } from 'app/shared/utils/sortr';
import Loading from 'app/shared/components/Loading';
import routes from 'app/shared/utils/routes';
import assets from 'app/shared/utils/assets';

// from redux store
interface AllState {
  mentionsVal: string;
  timeFormat: TimelineTimeFormat;
  sortedComments: CommentModel[];
}

interface OwnProps {
  articleId?: number;
  handleSubmit?: (comment: string) => void;
  ownPropsLoading?: boolean;
  disabled?: boolean;
  isFromNowTimeFormat?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  ownPropsComments?: CommentModel[];
}

type AllProps = OwnProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteComponentProps;

const mapStateToProps = (state: RootState, { ownPropsComments }: OwnProps) => ({
  agent: selectAgent(state),
  dropdownAgents: selectDropdownAgents(state),
  // use passed in comments, otherwise grab from the reducer
  // tech debt - we should remove this commonly shared comments state
  // tracking here: https://github.com/u21/lumos/issues/3776
  comments: ownPropsComments || selectComments(state),
  commentsLoading: selectCommentsLoading(state),
  addCommentLoading: selectAddCommentLoading(state),
});

const mapDispatchToProps = {
  retrieveComments: retrieveCommentsAction,
  addComment: addCommentAction,
  resetComments: resetCommentsAction,
  retrieveDropdownAgents: retrieveDropdownAgentsAction,
};

class CommentSection extends Component<AllProps, AllState> {
  static defaultProps = {
    isFromNowTimeFormat: true,
  };

  constructor(props: AllProps) {
    super(props);
    this.state = {
      mentionsVal: '',
      sortedComments: [],
      timeFormat: 'From Now',
    };

    autoBindReact(this);
    this.fetchAgents = debounce(this.fetchAgents, 500);
  }

  componentDidMount() {
    const { articleId, retrieveComments, dropdownAgents, isFromNowTimeFormat } =
      this.props;

    if (articleId) {
      retrieveComments(articleId);
    }
    if (isEmpty(dropdownAgents)) {
      this.fetchAgents();
    }
    if (isFromNowTimeFormat === false) {
      this.setState({ timeFormat: 'LLLL' });
    }
  }

  componentWillUnmount() {
    // reset comments to avoid stale comments
    const { resetComments } = this.props;

    resetComments();
  }

  static getDerivedStateFromProps(
    nextProps: AllProps,
    prevState: AllState,
  ): Partial<AllState> {
    const { sortedComments } = prevState;
    const newState: Partial<AllState> = {};

    if (
      nextProps.comments &&
      nextProps.comments.length !== sortedComments.length
    ) {
      const sorted = sortByObjValImmer(nextProps.comments, 'created_at');
      newState.sortedComments = sorted;
    }

    return newState;
  }

  findIdOfMention = (name: string) => {
    const { agent, dropdownAgents } = this.props;
    const filterName = name.slice(1);

    const agentId = Object.keys(dropdownAgents).filter(
      (id) =>
        dropdownAgents[id].full_name === filterName &&
        agent.org_id === dropdownAgents[id].org_id,
    );

    if (agentId[0]) return agentId[0];

    return '';
  };

  formatContent = (content: string) => {
    const { history } = this.props;
    const contentChars = content.split('');
    const resultWords: string[] = [];

    for (let i = 0; i < contentChars.length; i++) {
      // adding tag to the resultWordsArray;
      if (contentChars[i] === '@' && contentChars[i + 1] === '[') {
        let tag = '@';
        for (let c = i + 2; c < contentChars.length; c++) {
          if (contentChars[c] !== ']') {
            tag += contentChars[c];
          } else {
            resultWords.push(tag);
            i = c + 1;
            break;
          }
        }
      }
      // skipping the id
      if (contentChars[i] === '(' && contentChars[i - 1] === ']') {
        for (let c = i + 1; c < contentChars.length; c++) {
          if (contentChars[c] === ')') {
            i = c + 1;
            break;
          }
        }
      }

      resultWords.push(contentChars[i]);
    }

    return (
      <div className={scssStyles.contentContainer}>
        {resultWords.map((word, idx) => {
          const id = word?.includes('@') && this.findIdOfMention(word);
          if (id) {
            return (
              <span
                // eslint-disable-next-line react/no-array-index-key
                key={`${word}-${idx}`}
                style={styles.mentionedAgent}
                {...getA11yClickProps(() =>
                  history.push(routes.lumos.agentsId.replace(':id', id)),
                )}
              >
                {word}
              </span>
            );
          }
          return (
            <span
              className={word === ' ' ? scssStyles.spaceSpan : ''}
              // eslint-disable-next-line react/no-array-index-key
              key={`${word}-${idx}`}
            >
              {word}
            </span>
          );
        })}
      </div>
    );
  };

  toggleTimeFormat = () => {
    const { timeFormat } = this.state;

    this.setState({
      timeFormat: timeFormat === 'From Now' ? 'LLLL' : 'From Now',
    });
  };

  renderComment = (comment: CommentModel): ReactNode => {
    const { timeFormat } = this.state;
    const { content, author, created_at: createdAt, id } = comment;
    const type = author !== null ? 'default' : null;
    if (type === null) {
      return null;
    }

    const timeString =
      timeFormat === 'From Now'
        ? moment.utc(createdAt).fromNow()
        : moment.utc(createdAt).local().format('LLLL');

    const authorName = author.full_name || author.email;
    const title = commentsConfig[type].titleGenerator(authorName);
    const formattedContent = this.formatContent(content);
    return (
      <TimelineEvent
        key={`comment-${id}`}
        title={title}
        createdAt={timeString}
        icon={<Image avatar size="large" src={author.picture} />}
        style={styles.timeline.main}
        titleStyle={styles.timeline.title}
        subtitleStyle={styles.timeline.subtitle}
        iconStyle={{ ...styles.timeline.icon, cursor: 'auto' }}
        bubbleStyle={{ ...styles.timeline.bubble, border: 'unset' }}
        onClick={this.toggleTimeFormat}
      >
        {formattedContent}
      </TimelineEvent>
    );
  };

  handleSubmitComment = () => {
    const { articleId, agent, handleSubmit, addComment } = this.props;
    const { mentionsVal } = this.state;

    if (!agent) return;

    // Comments do not belong to an article, use handleSubmit props
    if (handleSubmit) {
      handleSubmit(mentionsVal);
    } else if (articleId) {
      // Handle BE comment addition to article
      addComment(articleId, {
        content: mentionsVal,
      });
    }

    this.setState({ mentionsVal: '' });
  };

  fetchAgents(query?: string) {
    const { articleId, retrieveDropdownAgents } = this.props;

    retrieveDropdownAgents({
      limit: DEFAULT_SEARCH_PAGINATION_LIMIT,
      offset: DEFAULT_OFFSET,
      article_id: articleId,
      query,
    });
  }

  render() {
    const { commentsLoading, ownPropsLoading, addCommentLoading, disabled } =
      this.props;
    const { mentionsVal, sortedComments } = this.state;

    return (
      <div style={styles.commentsDiv}>
        {!disabled && (
          <>
            <StyledU21AgentMentions
              onChange={(value) =>
                this.setState({
                  mentionsVal: value,
                })
              }
              value={mentionsVal}
            />
            <GenericButton
              positive
              style={styles.addCommentButton}
              onClick={this.handleSubmitComment}
              disabled={addCommentLoading || mentionsVal.trim() === ''}
              loading={addCommentLoading}
            >
              Add comment
            </GenericButton>
          </>
        )}
        {disabled && sortedComments.length === 0 && (
          <NoResultsComponent
            title="No comments found"
            icon={assets.icons.conversation}
            height={50}
          />
        )}
        {sortedComments.length > 0 && (
          <Timeline style={styles.timeline.main}>
            {commentsLoading || ownPropsLoading ? (
              <Loading hideText />
            ) : (
              sortedComments.map((comment) => this.renderComment(comment))
            )}
          </Timeline>
        )}
      </div>
    );
  }
}

const styles: StyleMap = {
  commentsDiv: {
    width: '100%',
    margin: '0 auto',
  },
  timeline: {
    main: {
      width: '100%',
    },
    title: {
      fontFamily: StyleConstants.FONT_FAMILY,
      fontSize: '1rem',
      fontWeight: StyleConstants.CONTENT_REGULAR_FONT_WEIGHT,
      opacity: 0.7,
    },
    subtitle: {
      fontFamily: StyleConstants.FONT_FAMILY,
      fontSize: '1rem',
      fontWeight: StyleConstants.CONTENT_BODY_FONT_WEIGHT,
    },
    icon: {
      marginTop: '-2px',
      marginLeft: '3px',
    },
    bubble: {
      left: '-1px',
      width: 'unset',
      height: '60px',
      background: 'unset',
    },
  },
  itemTitle: {
    opacity: 0.5,
  },
  addCommentButton: {
    fontFamily: StyleConstants.FONT_FAMILY,
    fontSize: StyleConstants.CONTENT_BODY_FONT_SIZE,
    fontWeight: StyleConstants.CONTENT_BODY_FONT_WEIGHT,
    marginBottom: '14px',
    width: '100%',
  },

  mentionedAgent: {
    fontWeight: 'bold',
    cursor: 'pointer',
  },
};

const StyledU21AgentMentions = styled(U21AgentMentions)`
  margin-bottom: 16px;
`;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(CommentSection));
