// Components
import { U21Spacer } from 'app/shared/u21-ui/components';
import { U21SideMenu } from 'app/shared/u21-ui/components/dashboard';

// Models
import { FilingsBatchDefinition } from 'app/modules/sidebar/models';

// Contexts
import { useFetchFilingsGrouped } from 'app/modules/fincenCtr/queries/useFetchFilingsGrouped';
import { ReportType } from 'app/modules/fincenSar/models';
import { IconDownload } from '@u21/tabler-icons';
import { formatDate } from 'app/shared/utils/date';
import { useDownloadFilingsBatch } from 'app/modules/fincenCtr/queries/useDownloadFilingsBatch';
import { useSelector } from 'react-redux';
import { selectFilingPageListFilters } from 'app/modules/fincenCtr/selectors';
import { SidebarDataRow } from 'app/modules/sidebar/components/utils/SidebarDataRow';

interface OwnProps {
  data: FilingsBatchDefinition['data'];
}

export const SidebarFincenCtr = ({
  data: { id: batchId, pageType },
}: OwnProps) => {
  const filingListFilters = useSelector(selectFilingPageListFilters(pageType));
  const { data: batchData, isLoading } = useFetchFilingsGrouped({
    limit: 1,
    offset: 1,
    report_type: ReportType.FINCEN_CTR,
    filters: {
      ...filingListFilters,
      batch_id: batchId,
    },
  });

  const { mutate: downloadFiling, isPending: downloadingBatch } =
    useDownloadFilingsBatch(batchId);

  const filingInfo = batchData?.filings?.[0];

  const {
    total,
    earliest,
    latest,
    accepted,
    rejected,
    created_by: createdBy,
  } = filingInfo ?? {};

  return (
    <U21SideMenu
      title={`Batch ${batchId}`}
      actionButtonProps={{
        children: 'Download Batch',
        endIcon: <IconDownload />,
        loading: downloadingBatch,
      }}
      onAction={() => downloadFiling()}
      loading={isLoading}
    >
      <U21Spacer>
        <SidebarDataRow label="Total filings" showEmptyFields>
          {total}
        </SidebarDataRow>
        <SidebarDataRow label="Accepted filings" showEmptyFields>
          {accepted}
        </SidebarDataRow>
        <SidebarDataRow label="Rejected filings" showEmptyFields>
          {rejected}
        </SidebarDataRow>
        <SidebarDataRow label="Date range" showEmptyFields>
          {`${formatDate(earliest) || 'N/A'} - ${formatDate(latest) || 'N/A'}`}
        </SidebarDataRow>
        <SidebarDataRow label="Created by" showEmptyFields>
          {createdBy}
        </SidebarDataRow>
      </U21Spacer>
    </U21SideMenu>
  );
};
