import { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Components
import { IconFlagX } from 'app/shared/components/Icons/IconFlagX';
import { IconLayoutColumns } from '@u21/tabler-icons';

import {
  U21Section,
  U21TitleCountLabel,
  U21Button,
  U21Table,
  U21TablePreference,
  U21TableState,
  U21LoadError,
} from 'app/shared/u21-ui/components';

// Models
import { SidebarComponentTypes } from 'app/modules/sidebar/models';
import { TableConfigType } from 'app/shared/CustomConfig/models';
import { ShortTxnResponse } from 'app/modules/transactions/types';

// Selectors
import { selectAlertEntities } from 'app/modules/alerts/selectors';
import { selectTxnTableConfig } from 'app/shared/CustomConfig/selectors';
import { selectLinkAnalysisFilters } from 'app/modules/filtersOld/selectors';

// Actions
import {
  toggleSidebar,
  toggleTableConfigSidebar,
} from 'app/modules/sidebar/slice';
import { useGetTransactions } from 'app/modules/transactions/queries/useGetTransactions';
import { modifyLinkAnalysisFilters } from 'app/modules/filtersOld/actions';

// Utils
import {
  createTableColumnConfig,
  createPaginationPayload,
} from 'app/shared/utils/table';
import { TRANSACTION_COLUMN_CONFIG } from 'app/modules/transactions/columns';
import { useLocalStorage } from 'app/shared/hooks/useLocalStorage';
import { LocalStorageKeys } from 'app/shared/constants/localStorage';
import { DEFAULT_TABLE_PREFRENCES } from 'app/shared/u21-ui/components/display/table/constants';
import { DEFAULT_GET_TRANSACTIONS_RESPONSE } from 'app/modules/transactions/constants';
import { selectHasReadEventsPermission } from 'app/modules/session/selectors';

export const AlertEntityTransactionsTable = () => {
  const dispatch = useDispatch();
  const transactionTableConfig = useSelector(selectTxnTableConfig);
  const hasReadEventsPermission = useSelector(selectHasReadEventsPermission);

  const newColumns = useMemo(
    () =>
      createTableColumnConfig<ShortTxnResponse>(
        transactionTableConfig,
        TRANSACTION_COLUMN_CONFIG,
      ),
    [transactionTableConfig],
  );

  const [tablePreferences, setTablePreferences] =
    useLocalStorage<U21TablePreference>(
      LocalStorageKeys.ALERT_TRANSACTION_TABLE_PREFERENCES,
      DEFAULT_TABLE_PREFRENCES,
    );

  const { selectedEntity } = useSelector(selectLinkAnalysisFilters);
  const entities = useSelector(selectAlertEntities);
  const currentEntity = useMemo(() => {
    return entities.find(({ id: entityID }) => entityID === selectedEntity)
      ?.name_readable;
  }, [entities, selectedEntity]);

  const [tableState, setTableState] = useState<U21TableState>({
    page: 1,
    pageSize: tablePreferences.pageSize,
    sortBy: tablePreferences.sortBy,
  });

  useEffect(() => {
    if (entities.length) {
      dispatch(
        modifyLinkAnalysisFilters({
          selectedEntity: entities[0].id,
        }),
      );
    }
  }, [entities, dispatch]);

  const payload = useMemo(
    () => ({
      ...createPaginationPayload(tableState),
      ...{ entity_ids: [selectedEntity] },
    }),
    [tableState, selectedEntity],
  );

  const {
    data: { count, txns } = DEFAULT_GET_TRANSACTIONS_RESPONSE,
    isError,
    isLoading: transactionsLoading,
    isRefetching,
    refetch,
  } = useGetTransactions(payload);

  return (
    <U21Section
      titleIcon={<IconFlagX />}
      action={
        <U21Button
          onClick={() => {
            dispatch(
              toggleTableConfigSidebar({
                tableConfigType: TableConfigType.TXN_TABLE,
              }),
            );
          }}
          startIcon={<IconLayoutColumns />}
        >
          Choose Columns
        </U21Button>
      }
      title={
        <U21TitleCountLabel count={count} label="transaction">
          {`${currentEntity}'s Transactions`}
        </U21TitleCountLabel>
      }
    >
      {isError ? (
        <U21LoadError label="transactions" onTryAgain={() => refetch()} />
      ) : (
        <U21Table
          key={selectedEntity}
          columns={newColumns}
          count={count}
          data={txns}
          defaultColumnWidths={tablePreferences.columnWidths}
          defaultPageSize={tablePreferences.pageSize}
          defaultSortBy={tablePreferences.sortBy}
          label="transaction"
          loading={transactionsLoading}
          manualPagination
          onPreferenceChange={(value) => setTablePreferences(value)}
          onRefresh={() => refetch()}
          onRowClick={
            hasReadEventsPermission
              ? (id: number | string) => {
                  dispatch(
                    toggleSidebar({
                      data: { id },
                      type: SidebarComponentTypes.TRANSACTION,
                    }),
                  );
                }
              : undefined
          }
          onStateChange={setTableState}
          refreshLoading={isRefetching}
        />
      )}
    </U21Section>
  );
};
