import { useAppVersionChanged } from 'app/modules/navigator/queries/useAppVersion';
import { useState } from 'react';
import styled from 'styled-components';

import { IconAlertTriangleFilled } from '@u21/tabler-icons';
import {
  U21Alert,
  U21Button,
  U21MenuLayout,
} from 'app/shared/u21-ui/components';

export const VersionOutdatedIndicator = () => {
  const changed = useAppVersionChanged();
  const [open, setOpen] = useState(false);
  if (changed) {
    return (
      <U21MenuLayout
        alignRight
        onClose={() => setOpen(false)}
        open={open}
        trigger={
          <U21Button
            aria-label="app-warning"
            color="warning"
            icon={<IconAlertTriangleFilled />}
            onClick={() => setOpen(true)}
            tooltip="Application outdated"
          />
        }
      >
        <StyledU21Alert severity="warning" title="Application outdated">
          Please save your work and{' '}
          <U21Button
            color="primary"
            onClick={() => window.location.reload()}
            variant="text"
          >
            refresh
          </U21Button>{' '}
          to get the latest version.
        </StyledU21Alert>
      </U21MenuLayout>
    );
  }
  return null;
};

const StyledU21Alert = styled(U21Alert)`
  width: 300px;
`;
