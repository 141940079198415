import { createSelector } from 'reselect';
import { GaFeatureTypes } from 'app/shared/components/GaBanner/constants';
import { name as gaBannerSliceName } from 'app/shared/components/GaBanner/sliceGaBanner';
import { selectFeatureFlags } from 'app/shared/featureFlags/selectors';
import { FeatureFlag } from 'app/shared/featureFlags/models';

export const selectGaBannerSlice = (state: RootState) => {
  return state[gaBannerSliceName];
};

export const selectGaBannerHidden = createSelector(
  [
    selectFeatureFlags,
    selectGaBannerSlice,
    (_, featureName: GaFeatureTypes, gaDateFeatureFlag: FeatureFlag) => [
      featureName,
      gaDateFeatureFlag,
    ],
  ],
  (featureFlags, gaBannerSlice, [featureName, gaDateFeatureFlag]) => {
    const gaDate: string | undefined = featureFlags[gaDateFeatureFlag];
    if (gaDate) {
      return gaBannerSlice[featureName].gaBannerHidden;
    }
    return true;
  },
);

export const selectPreReleaseBannerHidden = createSelector(
  [
    selectFeatureFlags,
    selectGaBannerSlice,
    (_, featureName: GaFeatureTypes, preReleaseFeatureFlag: FeatureFlag) => [
      featureName,
      preReleaseFeatureFlag,
    ],
  ],
  (featureFlags, gaBannerSlice, [featureName, preReleaseFeatureFlag]) => {
    const preRelease: boolean | undefined = featureFlags[preReleaseFeatureFlag];
    if (preRelease) {
      return gaBannerSlice[featureName].preReleaseBannerHidden;
    }

    return true;
  },
);

export const selectNewFeatureEnabled = createSelector(
  [
    selectFeatureFlags,
    selectGaBannerSlice,
    (
      _,
      featureName: GaFeatureTypes,
      newUIFeatureFlag: FeatureFlag,
      gaDateFeatureFlag: FeatureFlag,
    ) => [featureName, newUIFeatureFlag, gaDateFeatureFlag],
  ],
  (
    featureFlags,
    gaBannerSlice,
    [featureName, newUIFeatureFlag, gaDateFeatureFlag],
  ) => {
    const gaDate: string | undefined = featureFlags[gaDateFeatureFlag];
    if (gaDate) {
      return (
        featureFlags[newUIFeatureFlag] &&
        !gaBannerSlice[featureName].featureDisabled
      );
    }
    return featureFlags[newUIFeatureFlag];
  },
);

export const selectNewUIFeatureFlag = createSelector(
  [selectFeatureFlags, (_, newUIFeatureFlag: FeatureFlag) => newUIFeatureFlag],
  (featureFlags, newUIFeatureFlag) => featureFlags[newUIFeatureFlag],
);

export const selectNewFeatureGADate = createSelector(
  [
    selectFeatureFlags,
    (_, gaDateFeatureFlag: FeatureFlag) => gaDateFeatureFlag,
  ],
  (featureFlagsSlice, gaDateFeatureFlag): string | undefined => {
    return featureFlagsSlice[gaDateFeatureFlag];
  },
);

export const selectPreReleaseFeatureFlag = createSelector(
  [
    selectFeatureFlags,
    (_, preReleaseFeatureFlag: FeatureFlag) => preReleaseFeatureFlag,
  ],
  (featureFlagsSlice, preReleaseFeatureFlag): boolean | undefined => {
    return featureFlagsSlice[preReleaseFeatureFlag];
  },
);
