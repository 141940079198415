import { useQuery } from '@tanstack/react-query';
import { get } from 'app/shared/utils/fetchr';
import { FullAgentResponse } from 'app/modules/agents/responses';
import { AGENT_QUERY_KEYS } from 'app/modules/agents/queries/keys';
import { useSelector } from 'react-redux';
import { selectHasReadAgentsPermissions } from 'app/modules/session/selectors';

export const useGetAgent = (id?: number) => {
  const hasReadAgentsPermissions = useSelector(selectHasReadAgentsPermissions);
  return useQuery({
    enabled: hasReadAgentsPermissions && Boolean(id) && id !== -1,
    queryFn: () => get<FullAgentResponse>(`/agents/${id}/details`),
    queryKey: AGENT_QUERY_KEYS.getAgent(id),
  });
};
