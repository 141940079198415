// React
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { U21Chip, U21MenuLayout } from 'app/shared/u21-ui/components';
import { ReassignSarReviewerSelect } from 'app/modules/fincenSar/components/ReassignSarReviewerSelect';
import { IconUser, IconUserPlus } from '@u21/tabler-icons';

// Thunk
import { retrieveAssociatedAgentsThunk } from 'app/modules/queues/sliceQueues';

// Models
import { QueueType } from 'app/modules/queues/models';

// Selectors
import { selectHasEditSARsPermission } from 'app/modules/session/selectors';

// Utils
import { chipTextDisplayTransform } from 'app/modules/fincenSar/helpers';
import { SarReviewer } from 'app/modules/fincenSar/types/responses';

interface OwnProps {
  reviewer: SarReviewer;
  sarId: number;
  queueId?: number;
  disableReassign: boolean;
}

export const ReviewerPill = ({
  reviewer: { id = -1, full_name: fullName = '', email = '' },
  sarId,
  queueId,
  disableReassign = false,
}: OwnProps) => {
  const canEditSars = useSelector(selectHasEditSARsPermission);

  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const { tooltipText, displayText } = chipTextDisplayTransform(
    id,
    fullName,
    email,
  );

  return (
    <U21MenuLayout
      onClose={(e) => {
        e.stopPropagation();
        setOpen(false);
      }}
      trigger={
        <U21Chip
          icon={id === -1 ? <IconUserPlus /> : <IconUser />}
          variant="ghost"
          onClick={
            canEditSars && !disableReassign
              ? (e) => {
                  e.stopPropagation();
                  dispatch(
                    retrieveAssociatedAgentsThunk({
                      alert_queue_id: queueId,
                      queue_type: QueueType.SAR_QUEUE,
                    }),
                  );
                  setOpen(true);
                }
              : undefined
          }
          color={id === -1 ? 'warning' : 'primary'}
          tooltip={tooltipText}
        >
          {displayText}
        </U21Chip>
      }
      open={open}
    >
      <ReassignSarReviewerSelect
        sarId={sarId}
        queueId={queueId}
        onSelect={() => setOpen(false)}
      />
    </U21MenuLayout>
  );
};
