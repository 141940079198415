import { useState } from 'react';

// Components
import { U21Chip, U21ShowMoreList } from 'app/shared/u21-ui/components';
import { InstrumentsDocumentModal } from 'app/modules/instruments/components/InstrumentsDocumentModal';

// Models
import { FullAttachmentResponse } from 'app/modules/attachments/types';

// Utils
import { parseOutFilename } from 'app/modules/attachmentsRefresh/utils';

type OwnProps = {
  documents: FullAttachmentResponse[];
  handleOnDelete?: () => void;
};

export const InstrumentsDocumentChipList = ({
  documents,
  handleOnDelete = () => {},
}: OwnProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedFileIdx, setSelectedFileIdx] = useState<number>(0);
  return (
    <>
      <U21ShowMoreList
        limit={2}
        displayFunc={(file: FullAttachmentResponse, index: number) => (
          <U21Chip
            key={file.id}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedFileIdx(index);
              setIsModalOpen(true);
            }}
          >
            {parseOutFilename(file.name)[0]}
          </U21Chip>
        )}
        onLessClick={(e) => e.stopPropagation()}
        onMoreClick={(e) => e.stopPropagation()}
        value={documents}
      />
      <InstrumentsDocumentModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        documents={documents}
        selectedFileIdx={selectedFileIdx}
        setSelectedFileIdx={setSelectedFileIdx}
        handleOnDelete={handleOnDelete}
      />
    </>
  );
};
