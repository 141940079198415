import { CaseSidebarDefinition } from 'app/modules/sidebar/models';
import {
  IconArrowRight,
  IconBriefcase,
  IconScale,
  IconTag,
} from '@u21/tabler-icons';
import { ROUTES_MAP } from 'app/shared/utils/routes';
import {
  U21Chip,
  U21LoadError,
  U21ShowMoreList,
  U21Spacer,
  U21Subsection,
} from 'app/shared/u21-ui/components';
import { U21SideMenu } from 'app/shared/u21-ui/components/dashboard';
import { useGetCase } from 'app/modules/cases/queries/useGetCase';
import styled from 'styled-components';
import { SidebarDataRow } from 'app/modules/sidebar/components/utils/SidebarDataRow';
import { formatDatetime } from 'app/shared/utils/date';
import { getTagDisplayName } from 'app/modules/tags/utils';
import { SidebarCommentsCollapsible } from 'app/modules/sidebar/components/SidebarCommentsCollapsible';
import { SidebarAssociatedEntities } from 'app/modules/sidebar/components/SidebarAssociatedEntities';
import { SidebarAssociatedTransactions } from 'app/modules/sidebar/components/SidebarAssociatedTransactions';
import { SidebarAssociatedActionEvents } from 'app/modules/sidebar/components/SidebarAssociatedActionEvents';
import { SidebarAssociatedAlerts } from 'app/modules/sidebar/components/SidebarAssociatedAlerts';
import { SidebarAssociatedFilings } from 'app/modules/sidebar/components/SidebarAssociatedFilings';
import { useMemo } from 'react';
import { DispositionChip } from 'app/modules/investigations/components/DispositionChip';
import { StatusChip } from 'app/modules/investigations/components/StatusChip';
import { useSelector } from 'react-redux';
import { selectHasReadRulesPermission } from 'app/modules/session/selectors';
import { sortCreatedAt, sortEventTime } from 'app/modules/sidebar/utils';
import { ArticleDeadlineChip } from 'app/modules/deadlines/components/ArticleDeadlineChip';
import { CASE_QUERY_KEYS } from 'app/modules/cases/queries/keys';
import { useQueryClient } from '@tanstack/react-query';
import { Reassign } from 'app/modules/cases/components/SideBySide/Reassign';
import { Requeue } from 'app/modules/cases/components/SideBySide/Requeue';

interface Props {
  data: CaseSidebarDefinition['data'];
}

export const SidebarCase = (props: Props) => {
  const {
    data: { id, hideActionButton },
  } = props;

  const hasReadRulesPermission = useSelector(selectHasReadRulesPermission);

  const queryClient = useQueryClient();
  const { case: c, isError, isLoading, refetch } = useGetCase(id);

  const sortedAlerts = useMemo(() => {
    if (!c) {
      return [];
    }
    return sortCreatedAt(c.alerts);
  }, [c]);

  const sortedFilings = useMemo(() => {
    if (!c?.sars) {
      return [];
    }
    return sortCreatedAt(c.sars);
  }, [c]);

  const sortedEntities = useMemo(() => {
    if (!c) {
      return [];
    }
    return sortCreatedAt(c.entities);
  }, [c]);

  const sortedTransactions = useMemo(() => {
    if (!c) {
      return [];
    }
    return sortEventTime(c.events);
  }, [c]);

  const sortedActionEvents = useMemo(() => {
    if (!c) {
      return [];
    }
    return sortEventTime(c.action_events);
  }, [c]);

  return (
    <U21SideMenu
      actionButtonProps={
        hideActionButton
          ? undefined
          : {
              children: 'Go to Case Page',
              endIcon: <IconArrowRight />,
              to: ROUTES_MAP.casesId.path.replace(':id', String(id)),
            }
      }
      loading={isLoading}
      noPadding
      title="Case Details"
    >
      {(() => {
        // loading handled by U21SideMenu
        if (isLoading) {
          return null;
        }

        if (isError) {
          return <U21LoadError label="case" onTryAgain={() => refetch()} />;
        }

        if (!c) {
          return null;
        }

        return (
          <U21Spacer spacing={0.5}>
            <U21Subsection
              shaded
              title={<Span>{c.title}</Span>}
              titleIcon={<IconBriefcase />}
            >
              <U21Spacer>
                <SidebarDataRow
                  label="Description"
                  showEmptyFields
                  valueProps={{ ellipsis: false }}
                >
                  {c.description}
                </SidebarDataRow>
                <SidebarDataRow label="Assigned to" showEmptyFields>
                  <Reassign id={id} />
                </SidebarDataRow>
                <SidebarDataRow label="Queue" showEmptyFields>
                  <Requeue id={id} />
                </SidebarDataRow>
                <SidebarDataRow label="Status" showEmptyFields>
                  <StatusChip status={c.status} />
                </SidebarDataRow>
                <SidebarDataRow label="Deadline" showEmptyFields>
                  {c.status === 'OPEN' && (
                    <ArticleDeadlineChip
                      deadline={c.deadline}
                      deadlineConfig={c.deadline_config}
                      onEditSuccess={() => {
                        queryClient.invalidateQueries({
                          queryKey: CASE_QUERY_KEYS.getCase(id),
                        });
                      }}
                    />
                  )}
                </SidebarDataRow>
                <SidebarDataRow label="Disposition" showEmptyFields>
                  {c.disposition && (
                    <DispositionChip disposition={c.disposition} />
                  )}
                </SidebarDataRow>
                <SidebarDataRow
                  label="Disposition notes"
                  showEmptyFields
                  valueProps={{ ellipsis: false }}
                >
                  {c.disposition_notes}
                </SidebarDataRow>
                <SidebarDataRow label="Created at" showEmptyFields>
                  {formatDatetime(c.created_at)}
                </SidebarDataRow>
                <SidebarDataRow label="Created by" showEmptyFields>
                  {c.created_by?.full_name}
                </SidebarDataRow>
                <SidebarDataRow label="Started at" showEmptyFields>
                  {formatDatetime(c.start_date)}
                </SidebarDataRow>
                <SidebarDataRow label="Rules triggered" showEmptyFields>
                  {c.rules.length > 0 && (
                    <U21ShowMoreList
                      displayFunc={(i) => (
                        <U21Chip
                          key={i.id}
                          icon={<IconScale />}
                          to={
                            hasReadRulesPermission
                              ? ROUTES_MAP.detectionModelsId.path.replace(
                                  ':id',
                                  String(i.id),
                                )
                              : undefined
                          }
                        >
                          {i.title}
                        </U21Chip>
                      )}
                      value={c.rules}
                    />
                  )}
                </SidebarDataRow>
                <SidebarDataRow label="Tags" showEmptyFields>
                  {c.tags.length > 0 && (
                    <U21ShowMoreList
                      displayFunc={(i) => (
                        <U21Chip key={i.id} icon={<IconTag />}>
                          {getTagDisplayName(i)}
                        </U21Chip>
                      )}
                      value={c.tags}
                    />
                  )}
                </SidebarDataRow>
              </U21Spacer>
            </U21Subsection>
            <SidebarAssociatedAlerts
              alerts={sortedAlerts}
              count={sortedAlerts.length}
            />
            <SidebarAssociatedFilings
              count={sortedFilings.length}
              filings={sortedFilings}
            />
            <SidebarAssociatedEntities
              count={sortedEntities.length}
              entities={sortedEntities}
            />
            <SidebarAssociatedTransactions
              count={sortedTransactions.length}
              transactions={sortedTransactions}
            />
            <SidebarAssociatedActionEvents
              count={sortedActionEvents.length}
              actionEvents={sortedActionEvents}
            />
            <SidebarCommentsCollapsible comments={c.comments} />
          </U21Spacer>
        );
      })()}
    </U21SideMenu>
  );
};

const Span = styled.span`
  text-transform: none;
`;
