import { createPortal } from 'react-dom';
import { get, put, post, destroy } from 'app/shared/utils/fetchr';
import { sendErrorToast } from 'app/shared/toasts/actions';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';

import {
  IconArrowsMaximize,
  IconArrowsMinimize,
  IconX,
} from '@u21/tabler-icons';
import {
  U21Button,
  U21Section,
  U21Grid,
  U21Select,
  U21Spacer,
  U21TextField,
  U21JsonViewer,
} from 'app/shared/u21-ui/components';

const FETCH_METHOD_TYPES = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

const FETCH_METHODS = {
  [FETCH_METHOD_TYPES.GET]: get,
  [FETCH_METHOD_TYPES.POST]: post,
  [FETCH_METHOD_TYPES.PUT]: put,
  [FETCH_METHOD_TYPES.DELETE]: destroy,
};

const Postman = () => {
  const dispatch = useDispatch();
  const [body, setBody] = useState('{}');
  const [endpoint, setEndpoint] = useState('');
  const [loading, setLoading] = useState(false);
  const [maximized, setMaximized] = useState(false);
  const [method, setMethod] = useState(FETCH_METHOD_TYPES.GET);
  const [response, setResponse] = useState<object>({});

  const onFormat = useCallback(
    () =>
      setBody((currBody) => {
        try {
          return JSON.stringify(JSON.parse(currBody || '{}'), null, 2);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.warn('format error', e);
          return currBody;
        }
      }),
    [],
  );

  const onFetch = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        setLoading(true);
        let resp;

        if (
          method === FETCH_METHOD_TYPES.DELETE ||
          method === FETCH_METHOD_TYPES.GET
        ) {
          resp = await FETCH_METHODS[method](endpoint);
        } else if (
          method === FETCH_METHOD_TYPES.POST ||
          method === FETCH_METHOD_TYPES.PUT
        ) {
          resp = await FETCH_METHODS[method](
            endpoint,
            JSON.parse(body || '{}'),
          );
        }

        setResponse(resp);
      } catch (err) {
        setResponse({});
        // eslint-disable-next-line no-console
        console.error(err);
        dispatch(sendErrorToast('Error! See console for details.'));
      } finally {
        setLoading(false);
      }
    },
    [body, dispatch, endpoint, method],
  );

  const invalidBody = useMemo(() => {
    try {
      JSON.parse(body);
      return false;
    } catch {
      return true;
    }
  }, [body]);

  const hasBody =
    method === FETCH_METHOD_TYPES.POST || method === FETCH_METHOD_TYPES.PUT;

  return createPortal(
    <StyledU21Section
      $maximized={maximized}
      action={
        <U21Spacer horizontal>
          <U21Button
            aria-label={maximized ? 'minimize' : 'maximize'}
            icon={maximized ? <IconArrowsMinimize /> : <IconArrowsMaximize />}
            onClick={() => setMaximized(!maximized)}
          />
          <U21Button
            aria-label="close"
            icon={<IconX />}
            onClick={window.postman}
          />
        </U21Spacer>
      }
      title="U21 Postman"
    >
      <Form onSubmit={onFetch}>
        <U21Spacer horizontal>
          <SelectContainer>
            <U21Select
              label="Method"
              onChange={(val: string) => {
                setMethod(val);
                setResponse({});
              }}
              options={Object.values(FETCH_METHOD_TYPES).map((i) => ({
                text: i,
                value: i,
              }))}
              value={method}
            />
          </SelectContainer>
          <EndpointField
            autoFocus
            label="Endpoint"
            onChange={(value: string) => setEndpoint(value)}
            placeholder="/login"
            value={endpoint}
          />
          <U21Button
            color="primary"
            disabled={!method || !endpoint}
            loading={loading}
            type="submit"
            variant="contained"
          >
            Fetch
          </U21Button>
        </U21Spacer>
        <StyledGrid columns={hasBody ? 2 : 1}>
          {hasBody && (
            <Column>
              <ActionContainer>
                <U21Button onClick={onFormat} size="small">
                  Format
                </U21Button>
              </ActionContainer>
              <BodyField
                $invalid={invalidBody}
                inputProps={{
                  element: 'textarea',
                  style: {
                    height: '100%',
                    resize: 'none',
                  },
                }}
                label="Body"
                onChange={(value: string = '') => setBody(value)}
                placeholder="{}"
                type="textarea"
                value={body}
              />
            </Column>
          )}
          <Column>
            <ActionContainer>
              <U21Button onClick={() => setResponse({})} size="small">
                Clear
              </U21Button>
            </ActionContainer>
            <StyledU21JSONViewer data={response} />
          </Column>
        </StyledGrid>
      </Form>
    </StyledU21Section>,
    document.body,
  );
};

const StyledU21Section = styled(U21Section)<{ $maximized: boolean }>`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: ${(props) => (props.$maximized ? 'calc(100vh - 60px)' : '50%')};
  z-index: 1000;
  margin: 30px;
  border: 2px solid ${(props) => props.theme.palette.primary.main};
  box-shadow: 0 5px 30px #000000;
  transition: all 0.5s;

  ${U21Section.Content} {
    display: flex;
    flex-direction: column;
  }

  .MuiCardContent-root {
    overflow: hidden;
  }
`;

const Form = styled.form`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 5px; // margin to prevent label from being cut off
`;

const SelectContainer = styled.div`
  width: 200px;
`;

const EndpointField = styled(U21TextField)`
  flex: 1 1 auto;
`;

const StyledGrid = styled(U21Grid)`
  flex: 1;
  margin-top: 8px;
  overflow: hidden;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  width: 100%;
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
`;

const BodyField = styled(U21TextField)<{ $invalid?: boolean }>`
  flex: 1;

  ${(props) =>
    props.$invalid
      ? css`
          .MuiOutlinedInput-notchedOutline {
            border-color: ${props.theme.palette.error.main};
            border-width: 2px;
          }
        `
      : css``}

  .MuiInputBase-root {
    height: 100%;
  }
`;

const StyledU21JSONViewer = styled(U21JsonViewer)`
  flex: 1;
  overflow: auto;
`;

export default Postman;
