import {
  U21TableColumn,
  U21TableColumnTypes,
} from 'app/shared/u21-ui/components';
import { WatchlistResult } from 'app/modules/watchlists/models';
import { getDisplayWatchlistMatchThreshold } from 'app/modules/watchlists/helpers';

export type WatchlistTableColumn = U21TableColumn<WatchlistResult, any>;

const ID_COLUMN: WatchlistTableColumn = {
  id: 'id',
  Header: 'ID',
  accessor: (row) => row.id,
};

const CREATED_AT_COLUMN: WatchlistTableColumn = {
  id: 'created_at',
  Header: 'Created at',
  accessor: (row) => row.created_at,
  type: U21TableColumnTypes.DATETIME,
};

const WATCHLIST_TYPE_COLUMN: WatchlistTableColumn = {
  id: 'type',
  Header: 'Watchlist type',
  accessor: (row) => row.type,
  type: U21TableColumnTypes.LABEL,
};

const WATCHLIST_NAMES_COLUMN: WatchlistTableColumn = {
  id: 'names',
  Header: 'Watchlist names',
  accessor: (row) => row.names,
  type: U21TableColumnTypes.LIST,
  disableSortBy: true,
};

const PROVIDER_COLUMN: WatchlistTableColumn = {
  id: 'provider',
  Header: 'Provider',
  accessor: (row) => row.provider,
  type: U21TableColumnTypes.LABEL,
};

const WATCHLIST_MATCH_SCORE_COLUMN: WatchlistTableColumn = {
  id: 'match_score',
  Header: 'Match score',
  accessor: (row) =>
    row.match_score !== null
      ? getDisplayWatchlistMatchThreshold(row.match_score)
      : undefined,
  type: U21TableColumnTypes.TEXT,
};

export const WATCHLIST_COLUMN_CONFIG = {
  [ID_COLUMN.id]: ID_COLUMN,
  [CREATED_AT_COLUMN.id]: CREATED_AT_COLUMN,
  [WATCHLIST_TYPE_COLUMN.id]: WATCHLIST_TYPE_COLUMN,
  [WATCHLIST_NAMES_COLUMN.id]: WATCHLIST_NAMES_COLUMN,
  [PROVIDER_COLUMN.id]: PROVIDER_COLUMN,
  [WATCHLIST_MATCH_SCORE_COLUMN.id]: WATCHLIST_MATCH_SCORE_COLUMN,
};
