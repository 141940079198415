import { useSelector } from 'react-redux';
import { selectFormatAmount } from 'app/modules/orgSettings/selectors';
import {
  U21Card,
  U21Chip,
  U21Loading,
  U21ShowMoreList,
  U21Typography,
} from 'app/shared/u21-ui/components';
import styled from 'styled-components';

interface EntityToAggregateCardProps {
  title: string;
  data: { name: string; value: number }[];
  isLoading: boolean;
}

export const EntityToAggregateCard = (props: EntityToAggregateCardProps) => {
  const { title, data, isLoading } = props;
  const formatAmount = useSelector(selectFormatAmount);
  return (
    <U21Card title={title}>
      {isLoading ? (
        <U21Loading loading label="" />
      ) : (
        <U21ShowMoreList
          displayFunc={(item) => (
            <StyledChip key={item.name}>
              <U21Typography variant="subtitle2">{item.name}:</U21Typography>
              <U21Typography variant="body2">
                {formatAmount({ amount: item.value })}
              </U21Typography>
            </StyledChip>
          )}
          horizontal
          limit={3}
          value={data}
        />
      )}
    </U21Card>
  );
};

const StyledChip = styled(U21Chip)`
  height: unset;
`;
