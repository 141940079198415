import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@tanstack/react-query';

import { post } from 'app/shared/utils/fetchr';
import { ChangeAlertStatusPayload } from 'app/modules/alerts/models';
import { sendErrorToast, sendSuccessToast } from 'app/shared/toasts/actions';
import { retrieveAlertAuditTrail } from 'app/modules/alerts/actions';
import { selectAlert } from 'app/modules/alerts/selectors';

export const useChangeAlertComponentStatus = () => {
  const dispatch = useDispatch();
  const { id } = useSelector(selectAlert);
  return useMutation({
    mutationFn: (alertStatusPayload: ChangeAlertStatusPayload) =>
      post<Record<string, never>>(
        `/alerts/${id}/component-resolution`,
        alertStatusPayload,
      ),
    onSuccess: () => {
      dispatch(sendSuccessToast('Alert Component Status Updated'));
      dispatch(retrieveAlertAuditTrail(id));
    },
    onError: () =>
      dispatch(sendErrorToast(`Error changing the status of alert ${id}`)),
  });
};
