import {
  U21ExternalLink,
  U21InfoItem,
  U21Section,
  U21Typography,
} from 'app/shared/u21-ui/components';
import styled, { css } from 'styled-components';

interface AdverseMediaSummaryCardProps {
  notes: string;
  urls: string[];
  categories: string[];
}

export const AdverseMediaSummaryCard = ({
  notes,
  urls,
  categories,
}: AdverseMediaSummaryCardProps) => {
  return (
    <StyledSection
      title={<U21Typography variant="h6">Adverse Media Summary</U21Typography>}
      collapsible
    >
      <StyledInfoItem
        label="Notes"
        width={90}
        valueProps={{ ellipsis: false }}
        style={{
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-word',
        }}
      >
        {notes}
      </StyledInfoItem>

      <StyledInfoItem
        label="Categories"
        width={90}
        valueProps={{ ellipsis: false }}
      >
        {new Intl.ListFormat('en-US').format(categories)}
      </StyledInfoItem>

      <StyledInfoItem label="Source URLs" width={90}>
        {urls.length > 0 && (
          <URLList>
            {urls.map((url) => (
              <div key={url}>
                <U21ExternalLink variant="body2" url={url} />
              </div>
            ))}
          </URLList>
        )}
      </StyledInfoItem>
    </StyledSection>
  );
};

const StyledSection = styled(U21Section)`
  ${(props) => css`
    background-color: ${props.theme.palette.background.neutral};
    border-color: ${props.theme.palette.background.neutral};
  `}
`;

const URLList = styled.div`
  overflow: hidden;
`;

const StyledInfoItem = styled(U21InfoItem)`
  margin-bottom: 12px;
  margin-top: 12px;
`;
