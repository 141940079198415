import { createContext, ReactNode, useEffect, useMemo, useState } from 'react';

// Components
import FeatureFlags from 'app/shared/featureFlags/components/FeatureFlags';
// Models
import { ToggleIsThick } from 'app/shared/models';

// constants
import HEAP_EVENTS from 'app/shared/utils/heapEvents';

// Utils
import emptyFn from 'app/shared/utils/empty-fn';
import { heapTrack } from 'app/shared/utils/heap';

interface AppProviderProps {
  children: ReactNode;
}

export const cutoffWidth = 1500;

export const ViewportSizes = {
  huge: 'huge',
  large: 'large',
};

export const AppContext = createContext({
  isThick: true,
  toggleIsThick: emptyFn,
  viewportSize: ViewportSizes.huge,
});

export interface AppContextState {
  isThick: boolean;
  toggleIsThick: ToggleIsThick;
  viewportSize: string;
}

const AppProvider = ({ children }: AppProviderProps) => {
  const [viewportSize, setViewportSize] = useState(ViewportSizes.huge);
  const [isThick, setIsThick] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setViewportSize(
        window.innerWidth < cutoffWidth
          ? ViewportSizes.large
          : ViewportSizes.huge,
      );
    };

    let resizeTimeout: NodeJS.Timeout | null;

    const handleResizeThrottler = () => {
      resizeTimeout = setTimeout(handleResize, 300);
    };

    handleResize();
    window.addEventListener('resize', handleResizeThrottler, false);
    return () => {
      if (resizeTimeout) {
        clearTimeout(resizeTimeout);
      }
      window.removeEventListener('resize', handleResizeThrottler, false);
    };
  }, []);

  useEffect(() => {
    setIsThick(viewportSize === ViewportSizes.huge);
  }, [viewportSize]);

  const state: AppContextState = useMemo(
    () => ({
      isThick,
      toggleIsThick: () =>
        setIsThick((prevIsThick) => {
          heapTrack(
            prevIsThick
              ? HEAP_EVENTS.navigator.sidebarMinimize
              : HEAP_EVENTS.navigator.sidebarMaximize,
          );
          return !prevIsThick;
        }),
      viewportSize,
    }),
    [isThick, viewportSize],
  );

  return (
    <AppContext.Provider value={state}>
      <FeatureFlags />
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
