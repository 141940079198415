import { put } from 'app/shared/utils/fetchr';
import { sendErrorToast, sendSuccessToast } from 'app/shared/toasts/actions';
import { useDispatch } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { EditNarrativePayload } from 'app/modules/narratives/requests';

export const addNarrativeToRecord = (
  payload: EditNarrativePayload,
): Promise<void> => {
  return put('/narratives', payload);
};

export const useAddNarrativeToRecordMutation = () => {
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: (payload: EditNarrativePayload) =>
      addNarrativeToRecord(payload),
    onSuccess: () =>
      dispatch(sendSuccessToast('Narrative added to record successfully.')),
    onError: () =>
      dispatch(sendErrorToast('Failed to add narrative to record.')),
  });
};
