// Components
import { AssociatedEntities } from 'app/modules/alerts/components/AlertDetails/Intuit/AssociatedEntities';
import { AssociatedBusinesses } from 'app/modules/alerts/components/AlertDetails/Intuit/AssociatedBusinesses';

// Types
import {
  AlertDetailsTab,
  AdditionalFilters,
  AlertType,
} from 'app/modules/alerts/types';

// Models
import { FeatureFlag } from 'app/shared/featureFlags/models';

export const INTUIT_ALERT_TABS: AlertDetailsTab[] = [
  {
    label: 'Payer Details',
    path: 'associated-businesses',
    component: <AssociatedBusinesses />,
    visibleOn: {
      types: [AlertType.TM],
      filters: [AdditionalFilters.INTUIT_ONLY],
      flags: [FeatureFlag.SHOW_PAYER_PAYEE_TABS],
    },
  },
  {
    label: 'Payee Details',
    path: 'associated-users',
    component: <AssociatedEntities />,
    visibleOn: {
      types: [AlertType.TM],
      filters: [AdditionalFilters.INTUIT_ONLY],
      flags: [FeatureFlag.SHOW_PAYER_PAYEE_TABS],
    },
  },
];
