import { ListItem, ListItemButton, ListItemText } from '@mui/material';
import { IconChevronRight } from '@u21/tabler-icons';
import { AiSessionResponse } from 'app/modules/codeInterpreter/responses';
import { U21Typography } from 'app/shared/u21-ui/components';
import { formatDatetime } from 'app/shared/utils/date';
import styled from 'styled-components';

interface AiSessionItemProps {
  isSelected: boolean;
  session: AiSessionResponse;
  onClick: (newSessionId: number) => void;
}

export const AiSessionItem = ({
  isSelected,
  session,
  onClick,
}: AiSessionItemProps) => {
  return (
    <StyledListItem>
      <ListItemButton selected={isSelected} onClick={() => onClick(session.id)}>
        <ListItemText
          disableTypography
          primary={
            <U21Typography variant="subtitle1">
              Session {session.id}
            </U21Typography>
          }
          secondary={
            <>
              <U21Typography color="text.secondary" variant="body2">
                Created at: {formatDatetime(session.created_at)}
              </U21Typography>
              <U21Typography color="text.secondary" variant="body2">
                Updated at: {formatDatetime(session.updated_at)}
              </U21Typography>
            </>
          }
        />
        <IconChevronRight />
      </ListItemButton>
    </StyledListItem>
  );
};

const StyledListItem = styled(ListItem)`
  padding: 0;
  margin: 2px;
  width: calc(100% - 4px); // to deal with 2px left / right margin
  background-color: ${(props) => props.theme.palette.background.paper};
  &:first-child {
    margin-top: 0px;
  }
`;
