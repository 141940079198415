import {
  FullAlertResponse,
  ShortAlertResponse,
} from 'app/modules/alerts/types/responses';
import {
  FullCaseResponse,
  ShortCaseResponse,
} from 'app/modules/casesOld/types/responses';

export interface InvestigationsAlertResponse<Short extends boolean = true> {
  alerts: Short extends true ? ShortAlertResponse[] : FullAlertResponse[];
  count: number;
  txn_analysis_loading?: AlertTxnAnalysisLoading[];
}

export enum TxnAnalysisLoadingStatus {
  NOT_STARTED = 'NOT_STARTED',
  TO_QUEUE = 'TO_QUEUE',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
}

// This allows us to determine which alerts have outdated cache data and when was the last time that the cache job was executed so we can show the right loading state
export interface AlertTxnAnalysisLoading {
  article_id: number;
  article_type: 'ALERT';
  status: TxnAnalysisLoadingStatus;
  seconds_since_execution: number | null;
}

export interface InvestigationsCaseResponse<Short extends boolean = true> {
  cases: Short extends true ? ShortCaseResponse[] : FullCaseResponse[];
  count: number;
}
export interface InvestigationsAgentStatsResponse {
  open_count: number;
  completed_last_24_hrs_count: number;
  fetched_at: string;
}

export interface AiInvestigationResultItem {
  task: string;
  result: string; // NOTE: might be a stringified object...
  system_output: string; // NOTE: will be used as checklist input
}

export interface AiInvestigationResultSectionItem {
  [itemKey: string]: AiInvestigationResultItem;
}

export interface AiInvestigationResultSection {
  [sectionkey: string]: AiInvestigationResultSectionItem;
}

export interface AiInvestigationResult {
  overall: string;
  sections: AiInvestigationResultSection;
}

export interface AiInvestigationResultsResponsePopulated {
  id: number;
  article_id: number;
  custom_checklist_submission_id: number;
  updated_at: string;
  status: 'PENDING' | 'COMPLETED' | 'ERROR';
  status_message: string;
  results: AiInvestigationResult | null;
}

export type AiInvestigationResultsResponse =
  | {
      current_ai_investigations_results: AiInvestigationResultsResponsePopulated;
      historical_ai_investigations_results: AiInvestigationResultsResponsePopulated[];
      narrative?: string;
    }
  | Record<any, never>;
