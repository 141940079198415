import { name } from 'app/modules/summarySettings/sliceSummarySettings';
import { isEqual } from 'lodash';
import { createSelector } from 'reselect';
import { selectDataSettingsByClassifier } from 'app/modules/dataSettings/selectors';
import { OrgDataSettingsConfig } from 'app/modules/dataSettings/responses';
import {
  SummaryViewConfigFieldToGroupMap,
  SummaryViewConfigTypeClassifier,
} from 'app/modules/summarySettings/types';
import {
  dataSettingsToIdMap,
  getUnassignedFieldsAsSet,
} from 'app/modules/summarySettings/helpers';

export const selectSummaryViewConfigs = (state: RootState) =>
  state[name].configs;

export const selectInitialSummaryViewConfigs = (state: RootState) =>
  state[name].initialConfigs;

export const selectSelectedClassifier = (state: RootState) =>
  state[name].selectedClassifier;

export const selectSelectedGroupId = (state: RootState) =>
  state[name].selectedGroup;

export const selectSummaryConfigIsDirty = createSelector(
  [
    selectSummaryViewConfigs,
    selectInitialSummaryViewConfigs,
    selectSelectedClassifier,
  ],
  (configs, initialConfigs, classifier) => {
    if (configs.status !== 'COMPLETE') {
      return true;
    }
    const config = configs[classifier];
    const initialConfig = initialConfigs?.[classifier];
    return !isEqual(config, initialConfig);
  },
);

export const selectSummaryViewConfigBySelectedClassifier = createSelector(
  [selectSummaryViewConfigs, selectSelectedClassifier],
  (configs, classifier) =>
    configs.status === 'COMPLETE' ? configs[classifier] : undefined,
);

export const selectDataSettingsIdMapByClassifier = createSelector(
  [selectDataSettingsByClassifier, selectSelectedClassifier],
  dataSettingsToIdMap,
);

export const selectSummaryViewDataByClassifier = createSelector(
  [
    selectSummaryViewConfigs,
    selectDataSettingsByClassifier,
    (_, classifier: SummaryViewConfigTypeClassifier) => classifier,
  ],
  (configs, dataSettingsByClassifierMap, classifier) => {
    if (configs.status !== 'COMPLETE') {
      return undefined;
    }
    const config = configs[classifier];
    const dataSettingsIdMap = dataSettingsToIdMap(
      dataSettingsByClassifierMap,
      classifier,
    );
    return config.map<{ group: string; fields: OrgDataSettingsConfig[] }>(
      ({ group, fields }) => ({
        group,
        fields: fields.reduce<OrgDataSettingsConfig[]>(
          (acc, f) =>
            dataSettingsIdMap[f] ? [...acc, dataSettingsIdMap[f]] : acc,
          [],
        ),
      }),
    );
  },
);

export const selectSelectedGroup = createSelector(
  [selectSummaryViewConfigBySelectedClassifier, selectSelectedGroupId],
  (config, id) => config?.find((group) => group.id === id),
);

export const selectAssignedFieldsSetBySelectedClassifier = createSelector(
  [selectSummaryViewConfigBySelectedClassifier],
  (config) => getUnassignedFieldsAsSet(config),
);

export const selectUnassignedFields = createSelector(
  [
    selectAssignedFieldsSetBySelectedClassifier,
    selectSelectedClassifier,
    selectDataSettingsByClassifier,
  ],
  (assignedFields, classifier, dataSettings) => {
    if (classifier) {
      return dataSettings[classifier].filter(
        (ds) => !assignedFields.has(ds.id),
      );
    }
    return [];
  },
);

export const selectGroupNames = createSelector(
  [selectSummaryViewConfigBySelectedClassifier],
  (config) => config?.map(({ group }) => group) ?? [],
);

export const selectFieldToGroupMap = createSelector(
  [selectSummaryViewConfigBySelectedClassifier],
  (config) =>
    (config ?? []).reduce<SummaryViewConfigFieldToGroupMap>(
      (acc, { group, fields }) => {
        fields.forEach((f) => {
          acc[f] = acc[f] ? [...acc[f], group] : [group];
        });
        return acc;
      },
      {},
    ),
);

export const selectUpdatePending = (state: RootState) =>
  state[name].updateConfigPending;

export const selectOtherFields = createSelector(
  [
    selectSummaryViewConfigs,
    selectDataSettingsByClassifier,
    (_: RootState, classifier: SummaryViewConfigTypeClassifier) => classifier,
  ],
  (configs, dataSettings, classifier) => {
    if (configs.status !== 'COMPLETE') {
      return [];
    }
    const assignedFields = getUnassignedFieldsAsSet(configs[classifier]);
    return dataSettings[classifier].filter((ds) => !assignedFields.has(ds.id));
  },
);
