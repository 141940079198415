import {
  InvestigationStatus,
  InvestigationType,
} from 'app/modules/investigations/models';

import {
  addGoogleDocsThunk,
  addS3DocsThunk,
  deleteDocumentThunk,
  uploadAndAddGoogleDocsThunk,
} from 'app/modules/investigations/sliceInvestigations';
import { editAlertDocumentSuccess } from 'app/modules/alerts/actions';
import { editDocumentSuccess as editCaseDocumentSuccess } from 'app/modules/casesOld/actions';

import { selectAddDocumentLoading } from 'app/modules/investigations/selectors/loading';
import {
  selectHasEditAlertsPermission,
  selectHasEditCasesPermission,
} from 'app/modules/session/selectors';
import { selectInvestigation } from 'app/modules/investigations/selectors/investigations';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormState } from 'react-final-form';

import { DocumentsSection } from 'app/modules/attachmentsRefresh/components/DocumentsSection';

interface Props {
  readOnly?: boolean;
  type: InvestigationType;
}

export const InvestigationDocuments = (props: Props) => {
  const { readOnly, type } = props;
  const dispatch = useDispatch();
  const { id, status } = useSelector(selectInvestigation(type));
  const addDocumentLoading = useSelector(selectAddDocumentLoading);
  const hasEditPermission = useSelector(
    type === InvestigationType.ALERT
      ? selectHasEditAlertsPermission
      : selectHasEditCasesPermission,
  );

  const {
    values: { attachments },
  } = useFormState();

  const onDeleteDocument = useCallback(
    (docID) => dispatch(deleteDocumentThunk({ id, docID, type })),
    [dispatch, id, type],
  );

  return (
    <DocumentsSection
      addDocumentLoading={addDocumentLoading}
      documents={attachments}
      id={id}
      onAddGDrive={(gdriveDocs) =>
        dispatch(addGoogleDocsThunk({ id, gdriveDocs, type }))
      }
      onDeleteDocument={onDeleteDocument}
      onEditDocumentSuccess={(payload) => {
        const newAttachments = attachments.map((i) =>
          i.id === payload.id
            ? { ...i, description: payload.description, name: payload.name }
            : i,
        );
        if (type === InvestigationType.ALERT) {
          dispatch(editAlertDocumentSuccess({ attachments: newAttachments }));
        } else {
          dispatch(editCaseDocumentSuccess({ attachments: newAttachments }));
        }
      }}
      onUploadGDrive={(files) =>
        dispatch(uploadAndAddGoogleDocsThunk({ files, id, type }))
      }
      onUploadS3={(files) => dispatch(addS3DocsThunk({ id, files, type }))}
      readOnly={
        !hasEditPermission || status !== InvestigationStatus.OPEN || readOnly
      }
    />
  );
};
