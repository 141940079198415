import { useMemo } from 'react';
import { useSelector } from 'react-redux';

// Components
import { U21Chip } from 'app/shared/u21-ui/components';

// Selectors
import { selectDropdownAgents } from 'app/shared/dropdown/selectors';
import { selectHasReadAgentsPermissions } from 'app/modules/session/selectors';

// Utils
import { ROUTES_MAP } from 'app/shared/utils/routes';

export const TaggedAgent = ({ name }: { name: string }) => {
  const agents = useSelector(selectDropdownAgents);
  const hasReadAgentsPermission = useSelector(selectHasReadAgentsPermissions);

  const agent = useMemo(
    () =>
      Object.keys(agents).find((dropdownAgent) => {
        return (
          agents[dropdownAgent].full_name === name ||
          agents[dropdownAgent].email === name
        );
      }),
    [agents, name],
  );

  return (
    <U21Chip
      to={
        hasReadAgentsPermission && agent
          ? ROUTES_MAP.agentsId.path.replace(':id', agent)
          : undefined
      }
    >
      {name}
    </U21Chip>
  );
};
