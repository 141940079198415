import { selectInvestigationCase } from 'app/modules/investigations/selectors/investigations';
import { selectHasEditCasesPermission } from 'app/modules/session/selectors';
import { TxnAnalysisType } from 'app/modules/txnAnalysis/constants';
import { TXN_ANALYSIS_SLICE_NAME } from 'app/modules/txnAnalysis/sliceTxnAnalysis';
import { createSelector } from 'reselect';

export const selectTxnAnalysisFilters = (state: RootState) =>
  state[TXN_ANALYSIS_SLICE_NAME].txnAnalysisFilters;

export const selectTxnAnalysisPermissions = createSelector(
  [
    selectInvestigationCase,
    selectHasEditCasesPermission,
    (_, type: TxnAnalysisType): TxnAnalysisType => type,
  ],
  (caseDetails, canEditCase, type): boolean =>
    type === TxnAnalysisType.CASE
      ? caseDetails.status === 'OPEN' && canEditCase
      : false,
);
