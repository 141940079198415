import { post } from 'app/shared/utils/fetchr';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { ALERT_QUERY_KEYS } from 'app/modules/alerts/queries/keys';
import {
  InvestigationsAlertResponse,
  InvestigationsPayload,
} from 'app/modules/investigations/types';
import { useSelector } from 'react-redux';
import { selectHasReadAlertsPermission } from 'app/modules/session/selectors';
import { selectDLEnabledForAlertTxnAnalysis } from 'app/shared/featureFlags/selectors';
import { useEffect, useMemo, useRef } from 'react';
import {
  AlertTxnAnalysisLoading,
  TxnAnalysisLoadingStatus,
} from 'app/modules/investigations/types/responses';

export enum ColdStorageLoadingStatus {
  LOADED = 'LOADED',
  LOADING = 'LOADING',
  LOADING_TIMEOUT = 'LOADING_TIMEOUT', // We are still loading but it's been more than the threshold
}

const LOADING_TIME_THRESHOLD_IN_SECONDS = 60 * 5; // 5 minutes

const getLoadingTxnsFromColdStorageState = (
  alertId: number,
  alertsLoading: AlertTxnAnalysisLoading[],
  hasDLEnabledForAlertTxnAnalysis: boolean,
): ColdStorageLoadingStatus => {
  const alertLoadingState = alertsLoading.find(
    ({ article_id: id }) => id === alertId,
  );
  if (
    hasDLEnabledForAlertTxnAnalysis &&
    alertLoadingState &&
    alertLoadingState.seconds_since_execution !== null &&
    [
      TxnAnalysisLoadingStatus.LOADING,
      TxnAnalysisLoadingStatus.TO_QUEUE,
    ].includes(alertLoadingState.status)
  ) {
    // We loading the transactions from cold storage, show either a loading spinner or a long load warning based on the threshold
    return alertLoadingState.seconds_since_execution <
      LOADING_TIME_THRESHOLD_IN_SECONDS
      ? ColdStorageLoadingStatus.LOADING
      : ColdStorageLoadingStatus.LOADING_TIMEOUT;
  }

  return ColdStorageLoadingStatus.LOADED;
};

export const useGetAlert = (
  id: number,
  {
    enabled,
  }: Pick<UseQueryOptions<InvestigationsAlertResponse<false>>, 'enabled'> = {
    enabled: true,
  },
) => {
  const hasDLEnabledForAlertTxnAnalysis = useSelector(
    selectDLEnabledForAlertTxnAnalysis,
  );
  const hasReadAlertsPermission = useSelector(selectHasReadAlertsPermission);
  const dataUpdateCountRef = useRef(0);
  const query = useQuery({
    refetchInterval: ({ state: { data } }) => {
      /*
        We might want to move the refetch interval to the hook definition once we are sure the new cache tables work correctly, for now only using it on this tab
        Make sure we got only 1 alert ID, if it's loading from cold storage refetch after waiting some time as long as we don't run out of retries
      */
      const COLD_STORAGE_WAIT_TIME = 2 * 60 * 1000; // 2 minutes
      const COLD_STORAGE_MAX_RETRIES = 3;
      if (data?.alerts.length === 1) {
        const alertId = data?.alerts[0].id;
        const loadingStatus = getLoadingTxnsFromColdStorageState(
          alertId,
          data?.txn_analysis_loading || [],
          hasDLEnabledForAlertTxnAnalysis,
        );
        if (
          dataUpdateCountRef.current < COLD_STORAGE_MAX_RETRIES &&
          [
            ColdStorageLoadingStatus.LOADING,
            ColdStorageLoadingStatus.LOADING_TIMEOUT,
          ].includes(loadingStatus)
        ) {
          return COLD_STORAGE_WAIT_TIME;
        }
      }
      // Do not retry by default
      return false;
    },
    enabled: hasReadAlertsPermission && enabled,
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ALERT_QUERY_KEYS.getAlert(id),
    queryFn: () => {
      dataUpdateCountRef.current += 1;
      return post<InvestigationsAlertResponse<false>>(
        '/investigations/retrieve/alert',
        {
          short: false,
          object_type: 'ALERT',
          object_ids: [id],
        } satisfies InvestigationsPayload,
      );
    },
  });

  const loadingTxnFromColdStorage = useMemo(
    () =>
      getLoadingTxnsFromColdStorageState(
        id,
        query.data?.txn_analysis_loading || [],
        hasDLEnabledForAlertTxnAnalysis,
      ),
    [id, query.data, hasDLEnabledForAlertTxnAnalysis],
  );

  useEffect(() => {
    // Reset update count when the alert ID changes
    dataUpdateCountRef.current = 0;
  }, [id]);

  return {
    ...query,
    alert: query.data?.alerts[0],
    loadingTransactionAnalysisFromColdStorage: loadingTxnFromColdStorage,
  };
};
