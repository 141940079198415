import { HTMLProps, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { getDOMProps } from 'app/shared/utils/react';
import { Container } from '@mui/material';
import { U21IFrame, U21Loading } from 'app/shared/u21-ui/components';

interface Props extends HTMLProps<HTMLDivElement> {
  src: string;
  title: string;
  showLoading?: boolean;
  maxTries?: number;
  maxTimeoutMs?: number;
}

export const ReloadableIFrame = ({
  src,
  title,
  showLoading,
  maxTries,
  maxTimeoutMs,
  ...rest
}: Props) => {
  const [loaded, setLoaded] = useState<boolean>(!showLoading);
  const [attempts, setAttempts] = useState<number>(0);
  const iFrameRef = useRef<HTMLIFrameElement>(null);
  const iFrameInterval = useRef<ReturnType<typeof setInterval> | null>(null);

  const clearIFrameInterval = useCallback(() => {
    setLoaded(true);
    if (iFrameInterval.current) {
      clearInterval(iFrameInterval.current);
    }
  }, [setLoaded]);

  useEffect(() => {
    if (maxTries && maxTimeoutMs) {
      iFrameInterval.current = setInterval(() => {
        if (attempts > maxTries) {
          clearIFrameInterval();
        } else if (iFrameRef.current) {
          setAttempts((prevAttempts) => prevAttempts + 1);
          iFrameRef.current.src = src;
        }
      }, maxTimeoutMs);
    }

    return () => {
      clearIFrameInterval();
    };
  }, [src, maxTries, maxTimeoutMs, attempts, clearIFrameInterval]);

  return (
    <StyledContainer {...getDOMProps(rest)}>
      <StyledU21IFrame
        src={src}
        onLoad={clearIFrameInterval}
        title={title}
        ref={iFrameRef}
      />
      <StyledLoading loading={!loaded} />
    </StyledContainer>
  );
};

const StyledLoading = styled(U21Loading)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
`;
const StyledContainer = styled(Container)`
  height: 100%;
  position: relative;
`;

const StyledU21IFrame = styled(U21IFrame)`
  height: 100%;
  width: 100%;
`;
