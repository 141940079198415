// Components
import { IconLock } from '@u21/tabler-icons';
import {
  DataProps,
  SidebarExternalLinkList,
} from 'app/modules/sidebar/components/SidebarExternalLinkList';
import { U21DynamicColorChip } from 'app/shared/u21-ui/components/dashboard';
import {
  U21Subsection,
  U21TitleCountLabel,
  U21Typography,
} from 'app/shared/u21-ui/components';

// Models
import { ShortActionEventResponse } from 'app/modules/actionEvents/responses';

// Helpers
import { formatDatetime } from 'app/shared/utils/date';
import { ROUTES_MAP } from 'app/shared/utils/routes';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectHasReadEventsPermission } from 'app/modules/session/selectors';

interface Props {
  actionEvents: ShortActionEventResponse[];
  count: number | null;
}

export const SidebarAssociatedActionEvents = ({
  actionEvents,
  count,
}: Props) => {
  const hasReadEventsPermission = useSelector(selectHasReadEventsPermission);
  const list = useMemo(
    () =>
      actionEvents.map<DataProps>((i) => ({
        label: formatDatetime(i.event_time),
        value: i.action_type ? (
          <U21DynamicColorChip>{i.action_type}</U21DynamicColorChip>
        ) : null,
        type: 'label',
        id: i.id,
        route: hasReadEventsPermission
          ? ROUTES_MAP.dataExplorerActionEventsId.path.replace(
              ':id',
              String(i.id),
            )
          : undefined,
      })),
    [actionEvents, hasReadEventsPermission],
  );
  return (
    <U21Subsection
      aria-label="Flagged Action Events"
      collapsible
      collapsed
      shaded
      title={
        <U21TitleCountLabel count={count} label="">
          Flagged Action Events
        </U21TitleCountLabel>
      }
      titleIcon={<IconLock />}
    >
      {actionEvents.length ? (
        <SidebarExternalLinkList list={list} />
      ) : (
        <U21Typography variant="caption" color="text.secondary">
          No flagged action events
        </U21Typography>
      )}
    </U21Subsection>
  );
};
