import { HTMLProps, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { IconWand, IconZoomIn, IconZoomOut } from '@u21/tabler-icons';
import { alpha } from '@mui/system/colorManipulator';

import {
  U21Spacer,
  U21MenuLayout,
  U21Button,
  U21Typography,
  U21Slider,
} from 'app/shared/u21-ui/components';

export interface TxnImageComparisonOverlayProps
  extends HTMLProps<HTMLDivElement> {
  upperOffscreenCanvas: OffscreenCanvas | null;
  lowerOffscreenCanvas: OffscreenCanvas | null;
  hidden?: boolean;
}

export const TxnImageComparisonOverlay = ({
  upperOffscreenCanvas,
  lowerOffscreenCanvas,
  hidden = false,
}: TxnImageComparisonOverlayProps) => {
  const upperCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const lowerCanvasRef = useRef<HTMLCanvasElement | null>(null);

  const [isOpacityOpen, setIsOpacityOpen] = useState<boolean>(false);
  const [opacity, setOpacity] = useState<number>(60);

  useEffect(() => {
    if (upperOffscreenCanvas) {
      const upperCanvas = upperCanvasRef.current;
      const upperContext = upperCanvas?.getContext('2d');

      const upperOffscreenContext = upperOffscreenCanvas.getContext('2d');

      if (upperOffscreenContext && upperCanvas && upperContext) {
        upperCanvas.width = upperOffscreenCanvas.width;
        upperCanvas.height = upperOffscreenCanvas.height;

        upperContext.clearRect(0, 0, upperCanvas.width, upperCanvas.height);
        upperContext.drawImage(upperOffscreenCanvas, 0, 0);
      }
    }
  }, [upperOffscreenCanvas]);

  useEffect(() => {
    if (lowerOffscreenCanvas) {
      const lowerCanvas = lowerCanvasRef.current;
      const lowerContext = lowerCanvas?.getContext('2d');

      const upperOffscreenContext = lowerOffscreenCanvas.getContext('2d');

      if (upperOffscreenContext && lowerCanvas && lowerContext) {
        lowerCanvas.width = lowerOffscreenCanvas.width;
        lowerCanvas.height = lowerOffscreenCanvas.height;

        lowerContext.clearRect(0, 0, lowerCanvas.width, lowerCanvas.height);
        lowerContext.drawImage(lowerOffscreenCanvas, 0, 0);
      }
    }
  }, [lowerOffscreenCanvas]);

  return !hidden ? (
    <MainContainer>
      <ItemContainer>
        <MainCanvas ref={lowerCanvasRef} />
      </ItemContainer>
      <ItemContainer>
        <TransformWrapper>
          {({ zoomIn, zoomOut }) => (
            <>
              <TransformContainer $opacity={opacity / 100}>
                <TransformComponent
                  wrapperStyle={{
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <MainCanvas ref={upperCanvasRef} />
                </TransformComponent>
              </TransformContainer>
              <U21Spacer align="center">
                <OperationButtonWrapper>
                  <U21MenuLayout
                    alignRight
                    onClose={() => setIsOpacityOpen(false)}
                    open={isOpacityOpen}
                    trigger={
                      <StyledButton
                        key="opacity"
                        aria-label="Set Opacity image"
                        icon={<IconWand color="white" />}
                        onClick={() => setIsOpacityOpen(true)}
                      />
                    }
                  >
                    <InputGrid>
                      <U21Typography variant="body2">Opacity:</U21Typography>
                      <StyledU21Slider
                        autoFocus
                        valueLabelFormat={(val) => `${val}%`}
                        value={opacity}
                        max={100}
                        onChange={(val: number) => setOpacity(val)}
                      />
                      <U21Typography variant="body2">{opacity}%</U21Typography>
                    </InputGrid>
                  </U21MenuLayout>

                  <StyledButton
                    aria-label="Zoom in image"
                    icon={<IconZoomIn color="white" />}
                    onClick={() => zoomIn()}
                  />
                  <StyledButton
                    aria-label="Zoom out image"
                    icon={<IconZoomOut color="white" />}
                    onClick={() => zoomOut()}
                  />
                </OperationButtonWrapper>
              </U21Spacer>
            </>
          )}
        </TransformWrapper>
      </ItemContainer>
    </MainContainer>
  ) : null;
};

const TransformContainer = styled.div<{ $opacity: number }>`
  opacity: ${(props) => props.$opacity};
  width: 100%;
  height: 100%;
`;

const MainContainer = styled.div`
  display: grid;
  place-items: center;
  grid-template-areas: 'inner-div';
  padding: 20px;
`;
const ItemContainer = styled.div`
  grid-area: inner-div;
  height: 100%;
  width: 100%;
`;

const OperationButtonWrapper = styled.div`
  display: flex;
  background-color: ${(props) => alpha(props.theme.palette.grey[800], 0.8)};
  border-radius: 8px;
  padding: 4px;
`;

const StyledButton = styled(U21Button)`
  border-radius: 0;
  background-color: transparent;
  border: 0px;
  padding: 4px;

  &:hover {
    background-color: ${(props) => alpha(props.theme.palette.grey[800], 0.8)};
  }
`;

const StyledU21Slider = styled(U21Slider)`
  .MuiSlider-root {
    margin-top: 0px;
  }
`;

const InputGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 0.6fr;
  grid-auto-rows: auto;
  column-gap: 0px;
  padding: 5px;
  margin-top: 20px;
  margin-bottom: 16px;
`;

const MainCanvas = styled.canvas`
  height: 100%;
  width: 100%;
`;
