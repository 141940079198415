import { useSelector } from 'react-redux';

// Components
import NoteSection from 'app/modules/notes/components/NoteSection';

// Selectors
import { selectInvestigation } from 'app/modules/investigations/selectors';
import {
  selectHasEditAlertsPermission,
  selectHasEditCasesPermission,
} from 'app/modules/session/selectors';

// Types
import { InvestigationType } from 'app/modules/investigations/models';

interface OwnProps {
  type: InvestigationType;
  disabled?: boolean;
}

export const Notes = ({ disabled, type }: OwnProps) => {
  const investigation = useSelector(selectInvestigation(type));
  const hasEditCasesPermissions = useSelector(selectHasEditCasesPermission);
  const hasEditAlertsPermissions = useSelector(selectHasEditAlertsPermission);

  const articleType = type === InvestigationType.ALERT ? 'Alert' : 'Case';

  return (
    <NoteSection
      articleType={articleType}
      articleId={Number(investigation.id)}
      disabled={
        disabled ||
        (type === InvestigationType.ALERT && !hasEditAlertsPermissions) ||
        (type === InvestigationType.CASE && !hasEditCasesPermissions)
      }
    />
  );
};
