import React, { useState, ReactNode } from 'react';

// Components
import { Icon, IconProps, Segment } from 'semantic-ui-react';
import TextPlaceholder from 'app/shared/components/TextPlaceholder';

// Constants
import { StyleConstants } from 'app/styles/StyleConstants';
import { TEXT_PLACEHOLDER_DELAY_MS } from 'app/shared/constants';

interface CollapsibleDropdownProps {
  label: any;
  icon?: IconProps;
  labelClassNameProps?: string;
  dropdown: ReactNode;
  initialCollapse?: boolean;
  collapse?: boolean;
  loading?: boolean;
  handleExpand?: () => void; // called when dropdown is expanded
  handleCollapse?: () => void; // called when dropdown is collapsed
}

/**
 * @deprecated Use U21CollapsibleSection instead
 */
const CollapsibleDropdown = ({
  label,
  icon,
  dropdown,
  initialCollapse,
  collapse,
  loading,
  handleExpand = () => {},
  handleCollapse = () => {},
  labelClassNameProps,
}: CollapsibleDropdownProps) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean | undefined>(
    initialCollapse || collapse || false,
  );
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const handleClick = () => {
    setIsCollapsed((prevState) => {
      if (prevState) {
        handleExpand();
      } else {
        handleCollapse();
      }
      return !prevState;
    });
  };
  const dynamicStyles = {
    titleSegment: {
      ...styles.titleSegment,
      ...(isHovering && styles.titleSegment.hover),
    },
  };

  const renderSegment = () => {
    return (
      <>
        <Segment
          style={dynamicStyles.titleSegment}
          onMouseOver={() => setIsHovering(true)}
          onMouseOut={() => setIsHovering(false)}
          onClick={handleClick}
          className={labelClassNameProps}
        >
          <Icon name={isCollapsed ? 'caret right' : 'caret down'} />
          {label}
          {icon && <Icon {...icon} style={styles.icon} />}
        </Segment>
        {!isCollapsed && <Segment>{dropdown}</Segment>}
      </>
    );
  };
  return (
    <Segment.Group style={styles.segment}>
      {loading ? (
        <TextPlaceholder
          delay={TEXT_PLACEHOLDER_DELAY_MS}
          loading={loading}
          rows={1}
          isCollapsible
        >
          {renderSegment()}
        </TextPlaceholder>
      ) : (
        renderSegment()
      )}
    </Segment.Group>
  );
};

const styles: StyleMap = {
  icon: {
    marginLeft: 'auto',
  },
  titleSegment: {
    width: '100%',
    display: 'flex',
    cursor: 'pointer',
    userSelect: 'none',
    fontFamily: StyleConstants.FONT_FAMILY,
    fontSize: StyleConstants.CONTENT_BODY_FONT_SIZE,
    fontWeight: StyleConstants.CONTENT_BODY_FONT_WEIGHT,
    borderTop: 'inherit',
    hover: {
      backgroundColor: '#f3f4f5',
    },
  },
  segment: {
    width: '100%',
  },
};

export default CollapsibleDropdown;
