import { useQuery } from '@tanstack/react-query';
import { get } from 'app/shared/utils/fetchr';
import { WATCHLIST_QUERY_KEYS } from 'app/modules/watchlists/queries/keys';

const getWatchlistResultNames = (alertId: number): Promise<string[]> =>
  get(`/alerts/${alertId}/watchlist-result-names`);

export const useGetWatchlistResultNames = (alertId: number) => {
  return useQuery({
    queryKey: WATCHLIST_QUERY_KEYS.getWatchlistResultNames(alertId),
    queryFn: () => getWatchlistResultNames(alertId),
    meta: {
      errorMessage:
        'Unable to retrieve watchlist result names. Please try again',
    },
    initialData: [],
    enabled: alertId > 0,
  });
};
