// Models
import { FormFieldCustomSchema, FormFieldSchema } from 'app/shared/models/form';

// Components
import { U21FormFieldTypes } from 'app/shared/u21-ui/components';
import { U21AgentSelect } from 'app/shared/u21-ui/components/dashboard';
import { IconWriting, IconUser } from '@u21/tabler-icons';

// Utils
import { selectActiveAgentOptions } from 'app/shared/dropdown/selectors';
import { required } from 'app/shared/utils/validation';

export const TEAM_NAME_FIELD: FormFieldSchema<string> = {
  name: 'name',
  label: 'Team name',
  type: U21FormFieldTypes.TEXT,
  fieldProps: {
    startIcon: <IconUser />,
    placeholder: 'Enter team name',
  },
  required: true,
  validate: required('Team name is required.'),
};

export const TEAM_DESCRIPTION_FIELD: FormFieldSchema<string> = {
  fieldProps: {
    startIcon: <IconWriting />,
  },
  label: 'Description',
  name: 'description',
  type: U21FormFieldTypes.TEXT,
};

export const TEAM_DUPLICATE_INCLUDE_AGENTS_FIELD: FormFieldSchema<boolean> = {
  fieldProps: {
    startIcon: <IconUser />,
    label: 'Include agents',
    description: 'Agents in this team will be included in the new team.',
  },
  name: 'includeAgents',
  type: U21FormFieldTypes.CHECKBOX,
};

export const TEAM_AGENTS_FIELD: FormFieldCustomSchema<number[]> = {
  children: (
    <U21AgentSelect
      getPayload={(payload) => ({
        ...payload,
        statuses: ['ACTIVE'],
      })}
      label={undefined}
      multi
      onChange={() => {}}
      selector={selectActiveAgentOptions}
    />
  ),
  default: (team) => team.agent_ids ?? [],
  label: 'Agents',
  name: 'agentIds',
  type: 'CUSTOM',
};

export const TEAMS_SCHEMA = [
  TEAM_NAME_FIELD,
  TEAM_DESCRIPTION_FIELD,
  TEAM_AGENTS_FIELD,
];
