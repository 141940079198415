import React from 'react';

// Models
import { AlertDetails } from 'app/modules/alerts/models';
import { CaseDetails } from 'app/modules/casesOld/models';
import { SubdispositionOption } from 'app/shared/types/actionTriggers';
import { AuditTrailActionTriggerDigest } from 'app/shared/auditTrail/types';

// Styles
import styles from 'app/shared/styles/ArticleStatistics.module.scss';

// Components
import { Label } from 'semantic-ui-react';
import DispositionLabel from 'app/shared/components/DispositionLabel';

// Utils
import { getLocalFormat } from 'app/shared/utils/timeHelpers';

const DispositionDetails: React.FC<{
  obj: AlertDetails | CaseDetails | AuditTrailActionTriggerDigest;
}> = ({ obj }) => (
  <>
    <div className={styles.horizontalItemDetails}>
      {obj?.disposition && (
        <div className={styles.ruleDetailItem}>
          <span className={styles.itemTitle}>Disposition</span>
          <span>
            <DispositionLabel useIcon={false} resolution={obj.disposition} />
          </span>
        </div>
      )}
    </div>
    {obj?.dispositioned_at && (
      <div className={styles.ruleDetailItem}>
        <span className={styles.itemTitle}>Disposition Date</span>
        <span>{getLocalFormat(obj.dispositioned_at)}</span>
      </div>
    )}
    {obj?.subdispositions && obj?.subdispositions.length > 0 && (
      <div className={styles.ruleDetailItem}>
        <span className={styles.itemTitle}>Subdispositions</span>
        <span>
          {obj.subdispositions?.map((option: SubdispositionOption) => (
            <Label key={option.id} className={styles.subdispositionLabel}>
              {option.title}: {option.name}
            </Label>
          ))}
        </span>
      </div>
    )}
    {obj?.disposition_notes && (
      <div className={styles.horizontalItemDetails}>
        <div className={styles.ruleDetailItem}>
          <span className={styles.itemTitle}>Disposition Notes</span>
          <p>{obj.disposition_notes}</p>
        </div>
      </div>
    )}
  </>
);

export default DispositionDetails;
