import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { U21Loading, U21Typography } from 'app/shared/u21-ui/components';
import { ReactNode } from 'react';
import styled from 'styled-components';

interface ClickableSectionProps {
  description: string;
  title: string;
  onClick: () => void;
  icon?: ReactNode;
  disabled?: boolean;
  isLoading?: boolean;
}

export const ClickableSection = ({
  description,
  title,
  onClick,
  icon,
  disabled,
  isLoading,
}: ClickableSectionProps) => {
  return (
    <StyledListItemButton disabled={disabled} onClick={onClick}>
      {icon && (
        <ListItemIcon>
          <IconWrapper>
            {isLoading ? <U21Loading variant="spinner" loading /> : icon}
          </IconWrapper>
        </ListItemIcon>
      )}
      <ListItemText
        disableTypography
        primary={<U21Typography variant="subtitle2">{title}</U21Typography>}
        secondary={
          <U21Typography
            textAlign="left"
            variant="body2"
            color="text.secondary"
          >
            {description}
          </U21Typography>
        }
      />
    </StyledListItemButton>
  );
};

const StyledListItemButton = styled(ListItemButton)`
  border-radius: 16px;
  padding: 24px;
  border: 1px solid ${(props) => props.theme.palette.grey[300]};
`;

const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
`;
