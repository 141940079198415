import { Link } from 'react-router-dom';
import routes from 'app/shared/utils/routes';

export const addSARLink = (content: string) => {
  const m = content?.match(/(SAR Filing #(\d+))/);
  if (!m) {
    return [content];
  }
  const [, linkText, sarId] = m;
  const { index = 0 } = m;
  return [
    content.substring(0, index),
    <Link key={sarId} to={routes.lumos.filingsSarId.replace(':id', sarId)}>
      {linkText}
    </Link>,
    ...addSARLink(content.substring(index + linkText.length)),
  ];
};
