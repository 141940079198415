import { useGetAllQueues } from 'app/modules/queues/queries/useGetAllQueues';
import {
  U21MenuList,
  U21MenuListOptionProps,
} from 'app/shared/u21-ui/components';
import { useMemo } from 'react';
import { convertFormattedQueues } from 'app/modules/queues/helpers';
import { QUEUE_ATTRS } from 'app/modules/queues/constants';
import { QueueType } from 'app/modules/queues/models';

interface Props {
  onChange: (value: number) => void;
  queueType: QueueType;
}

export const ChangeQueueMenuList = ({ onChange, queueType }: Props) => {
  const { data, isLoading } = useGetAllQueues(queueType);

  const options: U21MenuListOptionProps[] = useMemo(() => {
    return convertFormattedQueues(data?.queues, queueType).map((queue) => {
      return {
        text: String(queue.text),
        onClick: (e) => {
          onChange(queue.value);
          e.stopPropagation();
        },
        icon: queue.icon,
      };
    });
  }, [data?.queues, queueType, onChange]);

  return (
    <U21MenuList
      loading={isLoading}
      options={options}
      placeholder={`Search ${QUEUE_ATTRS[queueType].title}`}
    />
  );
};
