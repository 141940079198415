import { History } from 'history';

import { pathJoin, ROUTES_MAP } from 'app/shared/utils/routes';
import { NotificationEntityType } from 'app/modules/notifications/models';

export const handleRoutingOnEntityType = (
  entityType: `${NotificationEntityType}`,
  entityId: number | string,
  push: History['push'],
) => {
  switch (entityType) {
    case 'PROFILE':
      push(ROUTES_MAP.agentsId.path.replace(':id', String(entityId)));
      break;
    case 'CASE':
      push(ROUTES_MAP.casesId.path.replace(':id', String(entityId)));
      break;
    case 'ALERT':
      if (entityId) {
        push(pathJoin(ROUTES_MAP.alerts.path, entityId));
      } else {
        // there would be scenarios where entityId is not populated as there is more than one alert assigned.
        push(ROUTES_MAP.alerts.path);
      }
      break;
    case 'ALERT_QUEUE':
      push(ROUTES_MAP.alertQueuesId.path.replace(':id', String(entityId)));
      break;
    case 'RULE':
      push(ROUTES_MAP.detectionModelsId.path.replace(':id', String(entityId)));
      break;
    case 'SAR':
      push(ROUTES_MAP.filingsSarId.path.replace(':id', String(entityId)));
      break;
    case 'FILE_EXPORT':
      push(ROUTES_MAP.fileExports.path);
      break;
    default:
      break;
  }
};
