import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

// Components
import {
  U21NoData,
  U21Select,
  U21Spacer,
  U21Table,
  U21TableBodyCellProps,
  U21TableColumnTypes,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { DocumentDisplay } from 'app/modules/attachmentsRefresh/components/DocumentDisplay';
import { AlertDispositionChipList } from 'app/modules/alerts/components/AlertDispositionChipList';

// Models
import { ShortTxnResponse } from 'app/modules/transactions/types/responses';
import { FullAttachmentResponse } from 'app/modules/attachments/types';

// Selectors
import { selectTxnTableConfig } from 'app/shared/CustomConfig/selectors';

// Utils
import { toReadableMediaType } from 'app/modules/attachmentsRefresh/utils';
import { formatDatetime } from 'app/shared/utils/date';
import { TRANSACTION_COLUMN_CONFIG } from 'app/modules/transactions/columns';
import { createTableColumnConfig } from 'app/shared/utils/table';
import { selectHasReadAlertsPermission } from 'app/modules/session/selectors';

type OwnProps = {
  txnEventMap: Record<number, ShortTxnResponse>;
  selectedAttachmentIdx?: number;
  header: string;
  subheader: string;
  isOverlayMode: boolean;
  setParentOffsetCanvas?: (offsetCanvas: OffscreenCanvas) => void;
};

export const TxnImageComparisonModalBody = ({
  txnEventMap,
  selectedAttachmentIdx = 0,
  header,
  subheader,
  isOverlayMode,
  setParentOffsetCanvas,
}: OwnProps) => {
  const hasReadAlertsPermission = useSelector(selectHasReadAlertsPermission);
  const transactionEventTableConfig = useSelector(selectTxnTableConfig);
  const [selectedAttachmentId, setSelectedAttachmentId] = useState(
    Object.keys(txnEventMap).at(selectedAttachmentIdx),
  );

  const attachments = useMemo(
    () =>
      Object.values(txnEventMap).reduce<Record<number, FullAttachmentResponse>>(
        (acc, txnEvent) => {
          txnEvent.documents.forEach((document) => {
            acc[document.id] = document;
          });
          return acc;
        },
        {},
      ),
    [txnEventMap],
  );

  const options = useMemo(
    () =>
      Object.keys(txnEventMap).map((attachmentId) => {
        const attachment: FullAttachmentResponse = txnEventMap[
          attachmentId
        ].documents.find((document) => {
          return document.id === Number(attachmentId);
        });

        return {
          value: attachmentId,
          text: `#${attachmentId}: ${attachment.name}`,
          description: `Media type: ${toReadableMediaType(
            attachment.media_type,
          )}\nTransaction time: ${
            formatDatetime(txnEventMap[attachmentId].event_time) ?? '--'
          }`,
        };
      }),
    [txnEventMap],
  );

  const columns = useMemo(
    () => [
      ...createTableColumnConfig<ShortTxnResponse>(
        transactionEventTableConfig,
        TRANSACTION_COLUMN_CONFIG,
      ),
      ...(hasReadAlertsPermission
        ? [
            {
              id: 'alert_ids',
              type: U21TableColumnTypes.LIST,
              accessor: 'alert_ids',
              Header: 'Alerts',
              disableSortBy: true,
              minWidth: 250,
              Cell: (
                props: U21TableBodyCellProps<ShortTxnResponse, number[]>,
              ) => {
                const { value } = props;
                return <AlertDispositionChipList alertIds={value} />;
              },
            },
          ]
        : []),
    ],
    [hasReadAlertsPermission, transactionEventTableConfig],
  );

  return (
    <ModalBodySpacer spacing={4}>
      <U21Spacer spacing={0}>
        <U21Typography variant="h4">{header}</U21Typography>
        <U21Typography variant="body2" color="text.secondary">
          {subheader}
        </U21Typography>
      </U21Spacer>
      {selectedAttachmentId ? (
        <StyledU21Spacer spacing={2}>
          <StyledU21Select
            placeholder="Select an image"
            options={options}
            onChange={(id: string) => setSelectedAttachmentId(id)}
            value={selectedAttachmentId}
            clearable={false}
          />
          <DocumentDisplay
            isEditableImage
            file={attachments[selectedAttachmentId]}
            isOverlayMode={isOverlayMode}
            setParentOffsetCanvas={setParentOffsetCanvas}
          />
          <U21Spacer>
            <U21Typography variant="subtitle1">
              Transaction #{txnEventMap[selectedAttachmentId].id}
            </U21Typography>
            <StyledU21Table
              columns={columns}
              data={[txnEventMap[selectedAttachmentId]]}
            />
          </U21Spacer>
        </StyledU21Spacer>
      ) : (
        <StyledU21Spacer>
          <U21NoData>{`There are no ${header.toLowerCase()}`}</U21NoData>
        </StyledU21Spacer>
      )}
    </ModalBodySpacer>
  );
};

const ModalBodySpacer = styled(U21Spacer)`
  width: 50%;
  height: 100%;
`;

const StyledU21Spacer = styled(U21Spacer)`
  height: 100%;
  align-content: space-between;
`;

const StyledU21Select = styled(U21Select)`
  width: 350px;
`;

const StyledU21Table = styled(U21Table<ShortTxnResponse>)`
  height: fit-content;
`;
