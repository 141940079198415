import { useMemo } from 'react';
import { useAuth } from 'app/shared/providers/AuthProvider';

// components
import {
  IconCircleCheck,
  IconHelp,
  IconLogout,
  IconMessageHeart,
  IconUser,
} from '@u21/tabler-icons';
import { AccountPopoverSection } from 'app/modules/navigator/components/AccountPopoverSection';

// models
import { PopoverSectionProps } from 'app/modules/navigator/models';
import { SessionAgent } from 'app/modules/session/models';

import getFromProcessEnv from 'app/shared/utils/getFromProcessEnv';
import routes from 'app/shared/utils/routes';

// Constants
import { ROOT_DOCS } from 'app/shared/constants/docs';
import { triggerFeedbackSurvey } from 'app/shared/utils/intercom';
import { useSelector } from 'react-redux';
import { selectFeedbackSurveyID } from 'app/shared/featureFlags/selectors';
import { U21_DOCS_PASSWORD_TOOLTIP } from 'app/shared/u21-ui/components/dashboard';

interface OwnProps extends PopoverSectionProps {
  agent: SessionAgent;
}

const STATUS_PAGE_URL = getFromProcessEnv('STATUSPAGE_URL');

export const AccountSection = ({ agent, closePopover }: OwnProps) => {
  const surveyID = useSelector(selectFeedbackSurveyID);
  const { authClient: auth } = useAuth();

  const { id } = agent;

  const options = useMemo(
    () => [
      {
        key: 'profile',
        text: 'Profile',
        icon: <IconUser />,
        to: routes.lumos.agentsId.replace(':id', String(id)),
      },
      ...(getFromProcessEnv('REACT_APP_INTERCOM_ID') && surveyID
        ? [
            {
              key: 'feedback',
              text: 'Feedback',
              icon: <IconMessageHeart />,
              onClick: triggerFeedbackSurvey,
            },
          ]
        : []),
      {
        key: 'help',
        text: 'Help',
        icon: <IconHelp />,
        href: ROOT_DOCS,
        tooltip: U21_DOCS_PASSWORD_TOOLTIP,
      },
      ...(STATUS_PAGE_URL
        ? [
            {
              key: 'status',
              text: 'Status page',
              icon: <IconCircleCheck />,
              href: STATUS_PAGE_URL,
            },
          ]
        : []),

      {
        key: 'logout',
        text: 'Logout',
        icon: <IconLogout />,
        onClick: () => auth.logout(),
      },
    ],
    [auth, id, surveyID],
  );

  return (
    <AccountPopoverSection
      title="Account"
      items={options}
      closePopover={closePopover}
    />
  );
};
