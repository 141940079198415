// Models
import { ToastStateObject } from 'app/shared/toasts/models';

// Styles
import styled, { css } from 'styled-components';
import { U21Alert, U21Button } from 'app/shared/u21-ui/components';
import { TOAST_TYPE_TO_ALERT_MAP } from 'app/shared/toasts/constants';

interface ToastComponentProps extends ToastStateObject {
  clearToast: () => void;
}

export const ToastComponent = ({
  message,
  type = 'Success',
  clearToast,
  position = 'Top',
  cta,
}: ToastComponentProps) => {
  return (
    <ToastAlert
      severity={TOAST_TYPE_TO_ALERT_MAP[type]}
      onClose={() => clearToast()}
      $toastPosition={position}
    >
      {message}
      {!!cta && (
        <>
          {' '}
          <Cta
            variant="text"
            to={cta.to}
            color="primary"
            onClick={() => clearToast()}
          >
            {cta.text}
          </Cta>
        </>
      )}
    </ToastAlert>
  );
};

const ToastAlert = styled(U21Alert)<{
  $toastPosition: ToastComponentProps['position'];
}>`
  ${(props) => css`
    box-shadow: ${props.theme.customShadows[props.severity || 'error']};
    border-radius: ${props.theme.shape.borderRadius}px;
    ${props.$toastPosition === 'Top'
      ? css`
          width: 95%;
          margin-top: 12px;
        `
      : css`
          width: 300px;
        `};
  `}
`;

const Cta = styled(U21Button)`
  font-weight: ${(props) => props.theme.typography.subtitle1};
`;
