import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

// Utils
import { createFormInitialValues } from 'app/shared/utils/form';

// Selectors
import { selectEditCaseLoading } from 'app/modules/cases/selectors';
import { selectInvestigation } from 'app/modules/investigations/selectors/investigations';
import { selectHasEditCasesPermission } from 'app/modules/session/selectors';

// Models
import { InvestigationType } from 'app/modules/investigations/models';
import { editCaseThunk } from 'app/modules/cases/sliceCases';
import {
  CaseDetailsFormValues,
  CaseDetailsPages,
} from 'app/modules/cases/models';

// Components
import { U21Button, U21Form, U21Spacer } from 'app/shared/u21-ui/components';
import { CaseCreateDetailsCard } from 'app/modules/cases/components/CaseCreateDetailsCard';

// Schemas
import { CASE_DETAILS_SCHEMA } from 'app/modules/cases/schemas/detailsCaseCreate';

interface OwnProps {
  readOnly?: boolean;
  isPdf?: boolean;
}

export const FormTabCaseDetails = (props: OwnProps) => {
  const { readOnly, isPdf } = props;
  const dispatch = useDispatch();
  const details = useSelector(selectInvestigation(InvestigationType.CASE));
  const loading = useSelector(selectEditCaseLoading);
  const hasEditCasesPermission = useSelector(selectHasEditCasesPermission);

  // Values are generated through the default field in the schema
  const newDetails = useMemo(() => {
    return createFormInitialValues<CaseDetailsFormValues>(
      CASE_DETAILS_SCHEMA,
      details,
    );
  }, [details]);

  const onSubmit = (values: CaseDetailsFormValues) => {
    const {
      tags,
      watchers,
      entities,
      alerts,
      events,
      action_events: actionEvents,
    } = values;

    const { id } = details;

    const payload = {
      id,
      tags,
      watchers,
      entities,
      alerts,
      events,
      action_events: actionEvents,
    };
    dispatch(editCaseThunk(payload));
  };
  const disabled = !hasEditCasesPermission || isPdf;

  return (
    <U21Form
      loading={loading}
      onSubmit={onSubmit}
      initialValues={newDetails}
      disabled={disabled}
    >
      {({ dirty }) => {
        return (
          <>
            <CaseCreateDetailsCard
              readOnly={readOnly}
              pageType={CaseDetailsPages.EDIT}
              isPdf={isPdf}
            />
            {!disabled && (
              <StyledU21Spacer align="end">
                <U21Button
                  disabled={!dirty}
                  loading={loading}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Save
                </U21Button>
              </StyledU21Spacer>
            )}
          </>
        );
      }}
    </U21Form>
  );
};

const StyledU21Spacer = styled(U21Spacer)`
  margin-top: 8px;
`;
