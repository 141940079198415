import React from 'react';
import { Loader } from 'semantic-ui-react';

import scssStyles from 'app/shared/styles/Loading.module.scss';

interface LoadingProps {
  hideText?: boolean;
  style?: StyleMap;
  inverted?: boolean;
  parentStyle?: StyleMap;
}

/**
 * @deprecated Use U21Loading instead
 */
const Loading = ({ hideText, style, parentStyle, inverted }: LoadingProps) => {
  return (
    <div style={parentStyle || styles.loading}>
      <Loader
        active
        style={style}
        inverted={inverted}
        className={scssStyles.loader}
      >
        {!hideText && 'Loading'}
      </Loader>
    </div>
  );
};

const styles: StyleMap = {
  loading: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export default React.memo(Loading);
