import {
  GetFraudLossPayload,
  GetFraudLossTablePayload,
} from 'app/modules/fraudDashboard/models';

const BASE = ['fraud-loss'] as const;
export const FRAUD_LOSS_TABLE_BASE = [...BASE, 'fraud-loss-table'] as const;

export const FRAUD_LOSS_QUERY_KEYS = {
  getTableQueryKey: (body: GetFraudLossTablePayload) =>
    [...FRAUD_LOSS_TABLE_BASE, body] as const,
  getSummaryQueryKey: (body: GetFraudLossPayload) =>
    [...BASE, 'fraud-loss-summary', body] as const,
  getLossOverTimeQueryKey: (body: GetFraudLossPayload) =>
    [...BASE, 'fraud-loss-over-time', body] as const,
  getTableGroupedKey: (body: GetFraudLossTablePayload) =>
    [...BASE, 'fraud-loss-table-grouped', body] as const,
};
