import { isValidElement } from 'react';
import styled from 'styled-components';
import {
  U21InfoItem,
  U21InfoItemProps,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { isRenderable } from 'app/shared/utils/react';

interface Props extends Omit<U21InfoItemProps, 'as'> {
  showEmptyFields: boolean; // required so people don't forget this
}

export const SidebarDataRow = (props: Props) => {
  const { children, label, showEmptyFields, width = 120, ...rest } = props;
  if (!isRenderable(children) && !showEmptyFields) {
    return null;
  }

  return (
    <U21InfoItem
      label={
        isValidElement(label) ? (
          label
        ) : (
          <StyledU21Typography color="text.secondary" variant="caption">
            {label}
          </StyledU21Typography>
        )
      }
      width={width}
      {...rest}
    >
      {children}
    </U21InfoItem>
  );
};

const StyledU21Typography = styled(U21Typography)`
  // vertically align label with value
  margin-top: 2px;
`;
