import { useSelector } from 'react-redux';

// Components
import { U21Loading } from 'app/shared/u21-ui/components';

import AssociatedEntitiesPage from 'app/modules/alerts/components/AssociatedEntitiesPage';

// Selectors
import { selectAlert, selectAlertLoading } from 'app/modules/alerts/selectors';

export const AssociatedEntities = () => {
  const alert = useSelector(selectAlert);
  const loading = useSelector(selectAlertLoading);

  if (loading || alert.id === -1) {
    return <U21Loading loading variant="graphic" delay={0} />;
  }

  return (
    <AssociatedEntitiesPage
      entityType="USER"
      alert={alert}
      alertId={alert.id}
    />
  );
};
