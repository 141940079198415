import { get } from 'lodash';

// Constants
import { FEATURE_DISPLAY_NAMES } from 'app/modules/alertScore/constants';

// Models
import { VariableDescription } from 'app/modules/mlInsights/models';

// Utils
import { fromUpperToSentenceCase } from 'app/shared/utils/string';

export const getFeatureName = (opts, defaultReturn) => {
  try {
    const dataPoint = get(
      opts,
      `w.config.series[${opts.seriesIndex}].data[${opts.dataPointIndex}]`,
      {},
    );

    // Try to use the formatted name obtained from the BE
    const formattedName: VariableDescription | undefined = get(
      dataPoint,
      'formattedName',
    );
    if (formattedName?.name) {
      return formattedName.name;
    }

    // Fallback to default logic, hardcoded map or sentence case
    const featureName = dataPoint.x;
    return FEATURE_DISPLAY_NAMES[featureName]
      ? FEATURE_DISPLAY_NAMES[featureName]
      : fromUpperToSentenceCase(featureName);
  } catch {
    return defaultReturn;
  }
};
