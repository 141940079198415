import {
  ParameterMetric,
  RiskMetricType,
  RiskParameter,
  EntityRiskSegment,
  MetricMappingMethod,
  NumericComparisonOperator,
  MetricMapping,
  RiskModelStatus,
  RiskModelExecutionStatus,
} from 'app/modules/riskRatings/models';
import { PatronusErrorResponseBody } from 'app/shared/models';
import {
  RiskModelDetails,
  SingleActiveRiskModelError,
} from 'app/modules/riskRatings/responses';
import { EditRiskModelPayload } from 'app/modules/riskRatings/requests';
import {
  RiskMetricName,
  RiskParameterName,
  ALL_RISK_METRICS,
  ALL_RISK_METRIC_DETAILS_MAP,
  RiskParameterArgs,
} from 'app/modules/riskRatings/components/riskParameters';
import { ColorSchema } from 'vendor/material-minimal/palette';
import { keyPathToLabel } from 'app/modules/dataSettings/utils';
import { OrgDataSettingsConfig } from 'app/modules/dataSettings/responses';
import { round } from 'lodash';
import { getIn } from 'final-form';

export const getMetricFormattedName = (
  metric: ParameterMetric,
  customDataConfig?: OrgDataSettingsConfig,
): string => {
  let formattedName =
    ALL_RISK_METRIC_DETAILS_MAP[metric.metric]?.label || metric.metric;
  if (isCustomDataMetric(metric.metric) && customDataConfig) {
    formattedName = keyPathToLabel(customDataConfig);
  }
  return formattedName;
};

export const isCustomDataMetric = (metric: RiskMetricName): boolean =>
  metric === ALL_RISK_METRICS.ENTITY_CUSTOM_DATA;

export const getMetricInitialValues = (
  metric: RiskMetricName,
  {
    metricType,
    customDataSettingId,
  }: {
    metricType: RiskMetricType;
    customDataSettingId?: number;
  },
): ParameterMetric => ({
  metric,
  metric_type: metricType,
  unscored_risk: 0,
  unknown_risk: 0,
  risk_mappings: [],
  custom_data_setting_id: customDataSettingId,
});

export const getParameterInitialValues = (
  parameter: RiskParameterName,
  parameterArgs: RiskParameterArgs,
): RiskParameter => ({
  parameter,
  weight: 0.5,
  parameter_args: parameterArgs,
  metrics: [],
});

const validateNumberInRange =
  (min: number = 0, max: number = 100) =>
  (input: number | undefined): string | undefined => {
    // Risk input is a number from 0 to 100
    if (input === undefined || input === null) {
      return 'Field is required.';
    }
    const numericInput = Number(input);
    if (isNaN(numericInput)) {
      return 'Must be a number.';
    }
    if (numericInput < min || numericInput > max) {
      return `Must be a number between ${min} and ${max}.`;
    }
    return undefined;
  };

export const validateRiskInput = validateNumberInRange(0, 100);
export const validateWeight = validateNumberInRange(0, 1);

const getEntityRiskSegmentsPayload = (
  formValues: RiskModelDetails,
): EntityRiskSegment[] => {
  return (formValues.entity_risk_segments || []).map((segment) => {
    const segmentParameters = (segment.parameters || []).map((parameter) => {
      const parameterMetrics = (parameter.metrics || []).map((metric) => {
        const metricMappings: MetricMapping[] = (
          metric.risk_mappings || []
        ).map((mapping) => {
          if (mapping.method === MetricMappingMethod.NUMERIC_COMPARISON) {
            // IF we have a numeric comparison make sure we never send stale information (e.g IF the user selects IN RANGE but then switches to some other operator the content will have stale data)
            if (
              mapping.content.operator === NumericComparisonOperator.IN_RANGE
            ) {
              return {
                ...mapping,
                content: {
                  operator: NumericComparisonOperator.IN_RANGE,
                  start: mapping.content.start,
                  end: mapping.content.end,
                },
              };
            }
            return {
              ...mapping,
              content: {
                operator: mapping.content.operator,
                value: mapping.content.value,
              },
            };
          }
          return mapping;
        });
        // Return updated Metric
        return {
          ...metric,
          risk_mappings: metricMappings,
        };
      });
      // Return updated Parameter
      return {
        ...parameter,
        metrics: parameterMetrics,
      };
    });
    // Return updated Segment
    return {
      ...segment,
      parameters: segmentParameters,
    };
  });
};

export const getEditRiskModelPayload = (
  formValues: RiskModelDetails,
): EditRiskModelPayload => {
  return {
    // TODO add other editable fields
    entity_risk_segments: getEntityRiskSegmentsPayload(formValues),
  };
};

export const getRiskCategoryStyles = (
  score: number,
  highRiskFloor: number,
  lowRiskCeiling: number,
): {
  riskCategory: 'HIGH' | 'MEDIUM' | 'LOW';
  label: string;
  colorSchema: ColorSchema;
} => {
  if (score > highRiskFloor) {
    return {
      riskCategory: 'HIGH',
      label: 'High risk',
      colorSchema: 'error',
    };
  } else if (score < lowRiskCeiling) {
    return {
      riskCategory: 'LOW',
      label: 'Low risk',
      colorSchema: 'success',
    };
  }
  return {
    riskCategory: 'MEDIUM',
    label: 'Medium risk',
    colorSchema: 'warning',
  };
};

export const getRiskParameterArgsPathFromRiskMetricPath = (
  riskMetricPath: string,
): string => {
  return riskMetricPath.replace(/metrics\[\d+\]$/, 'parameter_args');
};

export const riskModelIsEditable = (
  riskModel: Pick<RiskModelDetails, 'status' | 'last_validation'> | undefined,
): boolean => {
  if (!riskModel) {
    return false;
  }
  const isDraft = riskModel.status === RiskModelStatus.DRAFT;
  const hasRunningValidation =
    riskModel.last_validation?.status ===
      RiskModelExecutionStatus.IN_PROGRESS ||
    riskModel.last_validation?.status === RiskModelExecutionStatus.QUEUED;
  return isDraft && !hasRunningValidation;
};

export const canActivateRiskModel = (
  riskModel: Pick<RiskModelDetails, 'status' | 'last_validation'> | undefined,
): boolean => {
  if (!riskModel) {
    return false;
  }
  const isDraft = riskModel.status === RiskModelStatus.DRAFT;
  const hasBeenValidated =
    riskModel.last_validation?.status === RiskModelExecutionStatus.SUCCESS;
  return isDraft && hasBeenValidated;
};

export const hasRunningValidation = (
  riskModel: Pick<RiskModelDetails, 'last_validation'>,
): boolean => {
  return (
    riskModel.last_validation?.status ===
      RiskModelExecutionStatus.IN_PROGRESS ||
    riskModel.last_validation?.status === RiskModelExecutionStatus.QUEUED
  );
};

export const getPercentageComputed = (
  processedEntities: number | undefined,
  totalEntities: number | undefined,
  precision: number | undefined = undefined,
) => {
  const current = processedEntities || 0;
  const total = totalEntities || 1; // Avoid division by zero
  return Math.min(round((current / total) * 100, precision), 100);
};

export const isSingleActiveRiskModelError = (
  errBody: PatronusErrorResponseBody | SingleActiveRiskModelError,
): errBody is SingleActiveRiskModelError => {
  return (
    (errBody as SingleActiveRiskModelError | undefined)?.error_code ===
    'single_active_risk_model_error'
  );
};

export const getParameterWeightPercentageFromRawWeight = (
  parameterPath: string,
  formValues: RiskModelDetails,
  precision = 2,
): number => {
  const parameter: RiskParameter | undefined = getIn(formValues, parameterPath);
  if (!parameter) {
    // We should not be here, something is wrong with the path or the values
    return 0;
  }

  const allParamsPath = parameterPath.replace(/\[\d+\]$/g, '');
  const allParams: RiskParameter[] = getIn(formValues, allParamsPath) || [];
  const sumOfWeights = allParams.reduce((sum, { weight }) => sum + weight, 0);
  if (!sumOfWeights || !parameter.weight) {
    // Avoid division/multiplication by 0
    return 0;
  }
  return round((parameter.weight * 100) / sumOfWeights, precision);
};

export const getParameterWeightFromMetricPath = (
  path: string,
  formValues: RiskModelDetails,
): number => {
  // Remove the last .metrics[0] part and call the function above
  const parameterPath = path.replace(/.metrics\[\d+\]$/, '');
  return getParameterWeightPercentageFromRawWeight(parameterPath, formValues);
};

export const getParameterArgsFromRiskModel = (
  model: RiskModelDetails | undefined,
): Record<string, Record<string, RiskParameter['parameter_args']>> => {
  const segmentsMap = {};
  if (!model) {
    return segmentsMap;
  }

  model.entity_risk_segments.forEach((segment) => {
    const segmentId = segment.id || '';
    segmentsMap[segmentId] = segment.parameters.reduce(
      (paramsById, parameter) => {
        const parameterId = parameter.id || '';
        return {
          ...paramsById,
          [parameterId]: parameter.parameter_args,
        };
      },
      {},
    );
  });
  return segmentsMap;
};

export const getCustomLabelFromParameterArgs = (
  parameterArgs: RiskParameterArgs | undefined,
): string => {
  if (parameterArgs && Object.hasOwnProperty.call(parameterArgs, 'label')) {
    return (parameterArgs as { label: string }).label;
  }
  return '';
};

export const formatRiskPercentage = (
  numericPercentage: number,
  decimalPlaces: number = 1,
  maxScore: number = 100,
  minScore: number = 0,
): string => {
  const roundedPercentage = round(numericPercentage, decimalPlaces);
  let percentageToShow = roundedPercentage;
  if (roundedPercentage >= maxScore && numericPercentage < maxScore) {
    // Do not show the full 100% if it's not really a 100%
    percentageToShow = maxScore - 1 / 10 ** decimalPlaces;
  }
  if (roundedPercentage <= minScore && numericPercentage > minScore) {
    // Do not show a 0 if it's not really a 0
    percentageToShow = minScore + 1 / 10 ** decimalPlaces;
  }
  // Return the formatted percentage
  return `${Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: decimalPlaces,
  }).format(percentageToShow)}%`;
};
