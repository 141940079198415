// Components
import { U21Spacer } from 'app/shared/u21-ui/components';
import { useSelector } from 'react-redux';
import { selectLinkAnalysisFilters } from 'app/modules/filtersOld/selectors';
import { FlaggedTransactionsTable } from 'app/modules/alerts/components/AlertDetails/FlaggedTransactionsTable';
import { AlertEntityTransactionsTable } from 'app/modules/alerts/components/AlertDetails/AlertEntityTransactionsTable';
import { ActivityHistogram } from 'app/modules/alerts/components/AlertDetails/TransactionActivity/ActivityHistogram';
import { TransactionActivityFilters } from 'app/modules/alerts/components/AlertDetails/TransactionActivity/TransactionActivityFilters';
import { selectHasReadEventsPermission } from 'app/modules/session/selectors';
import { selectHideAlertHistogram } from 'app/shared/featureFlags/selectors';

export const TransactionActivity = () => {
  const { showCurrentAlert } = useSelector(selectLinkAnalysisFilters);
  const hasReadEventsPermission = useSelector(selectHasReadEventsPermission);
  const hideAlertHistogram = useSelector(selectHideAlertHistogram);

  return (
    <U21Spacer spacing={1}>
      {hasReadEventsPermission && <TransactionActivityFilters />}
      {showCurrentAlert ? (
        <FlaggedTransactionsTable />
      ) : (
        <AlertEntityTransactionsTable />
      )}
      {!hideAlertHistogram && <ActivityHistogram />}
    </U21Spacer>
  );
};
