import { post } from 'app/shared/utils/fetchr';
import { useQuery } from '@tanstack/react-query';
import { ALERT_QUERY_KEYS } from 'app/modules/alerts/queries/keys';
import { ShortActionEventResponse } from 'app/modules/actionEvents/responses';
import { PaginationPayload } from 'app/shared/pagination/models';
import { useSelector } from 'react-redux';
import { selectHasReadAlertsPermission } from 'app/modules/session/selectors';

export const useGetAlertActionEvents = (
  id: number,
  body: PaginationPayload,
) => {
  const hasReadAlertsPermission = useSelector(selectHasReadAlertsPermission);
  return useQuery({
    queryKey: ALERT_QUERY_KEYS.getAlertActionEvents(id, body),
    queryFn: () =>
      post<{ count: number | null; events: ShortActionEventResponse[] }>(
        `/alerts/${id}/action-events`,
        body,
      ),
    meta: { errorMessage: 'Failed to load alert action events.' },
    enabled: hasReadAlertsPermission && body.limit !== 0,
  });
};
