import { FC, ReactElement, useMemo } from 'react';
import { useSelector } from 'react-redux';

// Components
import {
  U21Spacer,
  U21Subsection,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { selectDataSettingsById } from 'app/modules/dataSettings/selectors';
import {
  DataSettingsKeyType,
  FormattedData,
} from 'app/modules/dataSettings/responses';
import { DataSettingDataDisplay } from 'app/modules/dataSettings/shared/DataSettingDataDisplay';
import { SidebarDataSettingDataLabel } from 'app/modules/dataSettings/shared/DataSettingDataLabel';

// Styles
import { DataSettingsState } from 'app/modules/dataSettings/types';
import { SidebarDataRow } from 'app/modules/sidebar/components/utils/SidebarDataRow';

interface SidebarCustomDataProps {
  data?: FormattedData;
}

const renderNestedObjectData = (
  data: FormattedData,
  dataSettingsById: DataSettingsState['dataSettingsById'],
) => {
  return Object.keys(data || {}).reduce((acc: ReactElement[], id: string) => {
    const dataSetting = dataSettingsById[id];
    const formattedDataItem = data[id];
    if (
      formattedDataItem.formatted_value &&
      dataSetting?.key_type === DataSettingsKeyType.CUSTOM
    ) {
      acc.push(
        <SidebarDataRow
          key={id}
          label={<SidebarDataSettingDataLabel dataSetting={dataSetting} />}
          showEmptyFields
        >
          <DataSettingDataDisplay
            formattedData={data}
            dataSetting={dataSetting}
          />
        </SidebarDataRow>,
      );
    }
    return acc;
  }, []);
};

export const SidebarCustomDataNew: FC<SidebarCustomDataProps> = ({ data }) => {
  const dataSettingsById = useSelector(selectDataSettingsById);
  const customDataItems = useMemo(
    () => renderNestedObjectData(data || {}, dataSettingsById),
    [data, dataSettingsById],
  );

  return (
    <U21Subsection collapsible collapsed shaded title="Custom Data">
      {customDataItems.length > 0 ? (
        <U21Spacer>{customDataItems}</U21Spacer>
      ) : (
        <U21Typography variant="caption" color="text.secondary">
          No custom data found
        </U21Typography>
      )}
    </U21Subsection>
  );
};
