import { useMemo } from 'react';
import { useSelector } from 'react-redux';

// Selectors
import { selectFormatAmount } from 'app/modules/orgSettings/selectors';

// Components
import { U21Chip } from 'app/shared/u21-ui/components';

interface OwnProps {
  amount: number;
}

/* Chip to render the amount column in the filings
 * table. The currency formatter is stateful
 * which is why this needs to be it's own component.
 * Since, the SAR form has an option to have the
 * the amount be "Unknown" the component only renders
 * text if the amount is non-zero.
 */
export const AmountChip = ({ amount }: OwnProps) => {
  const formatter = useSelector(selectFormatAmount);

  const formattedAmount = useMemo(() => {
    return formatter({ amount, currencyCodeProps: 'USD' });
  }, [amount, formatter]);

  return <U21Chip>{amount === 0 ? '' : formattedAmount}</U21Chip>;
};
