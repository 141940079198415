import {
  CustomDataSettingsConfigResponse,
  DataSettingFieldType,
  Unit21DataClassifier,
} from 'app/modules/dataSettings/responses';
import { FilterOption, FilterType } from 'app/modules/filters/models';

import { FILTER_OPERATOR, FILTER_TYPE } from 'app/modules/filters/constants';
import { HUMAN_READABLE_CUSTOM_DATA_OBJECT_TYPE } from 'app/modules/dataSettings/constants';

import { createSelector } from 'reselect';
import { selectCustomDataSettingsByClassifier } from 'app/modules/dataSettings/selectors';
import { keyPathToLabel } from 'app/modules/dataSettings/utils';

const FIELD_TO_FILTER_TYPE: Partial<Record<DataSettingFieldType, FilterType>> =
  {
    ANY: FILTER_TYPE.CUSTOM_DATA_TEXT,
    ENUM: FILTER_TYPE.CUSTOM_DATA_TEXT,
    NUMBER: FILTER_TYPE.CUSTOM_DATA_NUMBER,
    TEXT: FILTER_TYPE.CUSTOM_DATA_TEXT,
  };

const FILTER_TYPE_CUSTOMIZE = {
  [FILTER_TYPE.CUSTOM_DATA_TEXT]: {
    [FILTER_OPERATOR.IS_ONE_OF]: {
      inputProps: { creatable: true, options: [] },
    },
    [FILTER_OPERATOR.IS_NOT_ONE_OF]: {
      inputProps: { creatable: true, options: [] },
    },
  },
};

const createFilterOptions = (
  customDataByClassifier: Record<
    Unit21DataClassifier,
    CustomDataSettingsConfigResponse[]
  >,
  classifier: Unit21DataClassifier,
) => {
  return customDataByClassifier[classifier].reduce<FilterOption[]>((acc, i) => {
    const filterType = FIELD_TO_FILTER_TYPE[i.data_type];
    // only support non-nested key_paths
    if (filterType && i.key_path.length === 1) {
      const label = keyPathToLabel(i);
      acc.push({
        customize: FILTER_TYPE_CUSTOMIZE[filterType],
        key: JSON.stringify({ classifier, key: i.key_path[0] }),
        label: `${HUMAN_READABLE_CUSTOM_DATA_OBJECT_TYPE[classifier]} ${label}`,
        type: filterType,
        description: i.description,
      });
    }
    return acc;
  }, []);
};

export const selectCustomDataFilterOptions = createSelector(
  selectCustomDataSettingsByClassifier,
  (customDataByClassifier) => [
    ...createFilterOptions(customDataByClassifier, Unit21DataClassifier.ENTITY),
    ...createFilterOptions(
      customDataByClassifier,
      Unit21DataClassifier.INSTRUMENT,
    ),
    ...createFilterOptions(customDataByClassifier, Unit21DataClassifier.EVENT),
  ],
);

export const selectWatchlistCustomDataFilterOptions = createSelector(
  selectCustomDataSettingsByClassifier,
  (customDataClassifier) => [
    ...createFilterOptions(
      customDataClassifier,
      Unit21DataClassifier.WATCHLIST,
    ),
  ],
);
