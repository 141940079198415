import { useQuery } from '@tanstack/react-query';
import { get } from 'app/shared/utils/fetchr';
import { AiSessionResponse } from 'app/modules/codeInterpreter/responses';
import { AI_CHAT_KEYS } from 'app/modules/codeInterpreter/queries/keys';

const getAiChatSession = (sessionId: number): Promise<AiSessionResponse> =>
  get<AiSessionResponse>(`/code-interpreter-session/${sessionId}`);

export const useFetchAiChatSession = (sessionId: number) => {
  return useQuery({
    enabled: sessionId > 0,
    queryKey: AI_CHAT_KEYS.getAiChatSessionQueryKey(sessionId),
    queryFn: () => getAiChatSession(sessionId),
    meta: { errorMessage: 'Failed to get session.' },
    refetchInterval: (query) => (query.state.data?.is_waiting ? 5000 : false),
  });
};
