import { ShortSARFilingResponse } from 'app/modules/fincenSar/types';
import { useMemo } from 'react';
import {
  DataProps,
  SidebarExternalLinkList,
} from 'app/modules/sidebar/components/SidebarExternalLinkList';
import { formatDatetime } from 'app/shared/utils/date';
import { REPORT_TYPE_PATH_MAP } from 'app/modules/fincenSar/constants/shared';
import {
  U21Subsection,
  U21TitleCountLabel,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { IconBuildingBank } from '@u21/tabler-icons';
import { useSelector } from 'react-redux';
import { selectHasReadSARsPermissions } from 'app/modules/session/selectors';

interface Props {
  count: number | null;
  filings: ShortSARFilingResponse[];
}

export const SidebarAssociatedFilings = ({ count, filings }: Props) => {
  const hasReadSARsPermission = useSelector(selectHasReadSARsPermissions);
  const list = useMemo(
    () =>
      filings.map<DataProps>((i) => ({
        label: formatDatetime(i.created_at),
        value: i.name,
        id: i.id,
        route: hasReadSARsPermission
          ? REPORT_TYPE_PATH_MAP[i.report_type].replace(':id', String(i.id))
          : undefined,
      })),
    [filings, hasReadSARsPermission],
  );

  // SARs are sensitive so don't even show this section if no permission
  if (!hasReadSARsPermission) {
    return null;
  }

  return (
    <U21Subsection
      aria-label="Associated Filings"
      collapsible
      collapsed
      shaded
      title={
        <U21TitleCountLabel count={count} label="">
          Associated Filings
        </U21TitleCountLabel>
      }
      titleIcon={<IconBuildingBank />}
    >
      {filings.length ? (
        <SidebarExternalLinkList list={list} />
      ) : (
        <U21Typography variant="caption" color="text.secondary">
          No associated filings
        </U21Typography>
      )}
    </U21Subsection>
  );
};
