import {
  selectModalState,
  selectSelectedDatafile,
} from 'app/modules/pullBasedDataFiles/selectors';
import { setModalState } from 'app/modules/pullBasedDataFiles/slicePullBasedDataFiles';
import {
  U21Modal,
  U21Spacer,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

export const DebugFileConsentModal = ({
  debugFile,
}: {
  debugFile: () => void;
}) => {
  const file = useSelector(selectSelectedDatafile);
  const modalState = useSelector(selectModalState);
  const dispatch = useDispatch();

  return (
    <U21Modal
      title={
        <>
          Debug <Span>{file?.file_name}</Span>
        </>
      }
      open={modalState.modalStatus === 'DEBUG_CONSENT'}
      onClose={() => {
        dispatch(setModalState({ modalState: { modalStatus: 'NONE' } }));
      }}
      onAction={() => {
        debugFile();
        dispatch(setModalState({ modalState: { modalStatus: 'NONE' } }));
      }}
    >
      <U21Spacer>
        <U21Typography>
          If you are having trouble processing a file with our Flat-File
          Ingestion Pathway, we can generate a debug key for you. You can
          provide this key to our Support team or your Customer Success or
          Implementation Manager so they can better help pinpoint your issue.
        </U21Typography>
        <U21Typography color="error">
          Please note that by clicking <b>Confirm</b> below you will grant
          Unit21 temporary access to the contents of <b>{file?.file_name}</b>.
        </U21Typography>
      </U21Spacer>
    </U21Modal>
  );
};

const Span = styled.span`
  text-transform: none;
`;
