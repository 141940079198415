import {
  RiskMetricDetails,
  RiskMetricType,
} from 'app/modules/riskRatings/models';

export enum GeographicMatchMetric {
  ENTITY_COUNTRY = 'ENTITY_COUNTRY',
  ENTITY_STATE = 'ENTITY_STATE',
  ENTITY_CITY = 'ENTITY_CITY',
  ENTITY_ZIP_CODE = 'ENTITY_ZIP_CODE',
  ENTITY_FULL_ADDRESS = 'ENTITY_FULL_ADDRESS',
}

export const GEOGRAPHIC_MATCH_METRIC_DETAILS: {
  [key in GeographicMatchMetric]: RiskMetricDetails;
} = {
  [GeographicMatchMetric.ENTITY_COUNTRY]: {
    label: 'Country',
    metricType: RiskMetricType.TEXT,
  },
  [GeographicMatchMetric.ENTITY_STATE]: {
    label: 'State',
    metricType: RiskMetricType.TEXT,
  },
  [GeographicMatchMetric.ENTITY_CITY]: {
    label: 'City',
    metricType: RiskMetricType.TEXT,
  },
  [GeographicMatchMetric.ENTITY_ZIP_CODE]: {
    label: 'ZIP code',
    metricType: RiskMetricType.TEXT,
  },
  [GeographicMatchMetric.ENTITY_FULL_ADDRESS]: {
    label: 'Full address',
    metricType: RiskMetricType.TEXT,
  },
} as const;

export enum RuleMatchMetric {
  ENTITY_RULES = 'ENTITY_RULES',
}

export const RULE_MATCH_METRIC_DETAILS: {
  [key in RuleMatchMetric]: RiskMetricDetails;
} = {
  [RuleMatchMetric.ENTITY_RULES]: {
    label: 'Rules flagging the entity',
    metricType: RiskMetricType.NUMBER,
    formatValue: (value: number | null) =>
      `Rule #${value || 0} flagged the entity`,
  },
} as const;

export enum EntityCustomDataMatchMetric {
  ENTITY_CUSTOM_DATA = 'ENTITY_CUSTOM_DATA',
}

export const ENTITY_CUSTOM_DATA_MATCH_METRIC_DETAILS: {
  [key in EntityCustomDataMatchMetric]: RiskMetricDetails;
} = {
  [EntityCustomDataMatchMetric.ENTITY_CUSTOM_DATA]: {
    label: 'Custom data field',
    metricType: RiskMetricType.UNKNWON,
  },
};

export enum ArticleVolumeMetric {
  ALERT_COUNT_PAST_12_MONTHS = 'ALERT_COUNT_PAST_12_MONTHS',
  CASE_COUNT_PAST_12_MONTHS = 'CASE_COUNT_PAST_12_MONTHS',
}

export const ARTICLE_VOLUME_METRIC_DETAILS: {
  [key in ArticleVolumeMetric]: RiskMetricDetails;
} = {
  [ArticleVolumeMetric.ALERT_COUNT_PAST_12_MONTHS]: {
    label: 'Alerts in the past 12 months',
    metricType: RiskMetricType.NUMBER,
    formatValue: (value: number | null) =>
      `${value || 0} alerts in the past 12 months`,
  },
  [ArticleVolumeMetric.CASE_COUNT_PAST_12_MONTHS]: {
    label: 'Cases in the past 12 months',
    metricType: RiskMetricType.NUMBER,
    formatValue: (value: number | null) =>
      `${value || 0} cases in the past 12 months`,
  },
} as const;

export enum SarVolumeMetric {
  SAR_COUNT_PAST_12_MONTHS = 'SAR_COUNT_PAST_12_MONTHS',
}

export const SAR_VOLUME_METRIC_DETAILS: {
  [key in SarVolumeMetric]: RiskMetricDetails;
} = {
  [SarVolumeMetric.SAR_COUNT_PAST_12_MONTHS]: {
    label: 'SARs in the past 12 months',
    metricType: RiskMetricType.NUMBER,
    formatValue: (value: number | null) =>
      `${value || 0} SARs in the past 12 months`,
  },
} as const;

export enum EntityInformationMatchMetric {
  ENTITY_EXTERNAL_ID = 'ENTITY_EXTERNAL_ID',
  ENTITY_TYPE = 'ENTITY_TYPE',
  ENTITY_STATUS = 'ENTITY_STATUS',

  // Person
  ENTITY_FULL_NAME = 'ENTITY_FULL_NAME',
  ENTITY_GENDER = 'ENTITY_GENDER',
  ENTITY_SSN = 'ENTITY_SSN',
  ENTITY_AGE = 'ENTITY_AGE',

  // Business
  ENTITY_BUSINESS_NAME = 'ENTITY_BUSINESS_NAME',
  ENTITY_ACCOUNT_HOLDER_NAME = 'ENTITY_ACCOUNT_HOLDER_NAME',
  ENTITY_CORPORATE_TAX_ID = 'ENTITY_CORPORATE_TAX_ID',
  ENTITY_DBA = 'ENTITY_DBA',
}

export const ENTITY_INFORMATION_MATCH_METRIC_DETAILS: {
  [key in EntityInformationMatchMetric]: RiskMetricDetails;
} = {
  [EntityInformationMatchMetric.ENTITY_EXTERNAL_ID]: {
    label: 'Entity ID',
    metricType: RiskMetricType.TEXT,
  },
  [EntityInformationMatchMetric.ENTITY_TYPE]: {
    label: 'Entity type',
    metricType: RiskMetricType.TEXT,
  },
  [EntityInformationMatchMetric.ENTITY_STATUS]: {
    label: 'Entity status',
    metricType: RiskMetricType.TEXT,
  },
  [EntityInformationMatchMetric.ENTITY_FULL_NAME]: {
    label: 'Entity full name',
    metricType: RiskMetricType.TEXT,
  },
  [EntityInformationMatchMetric.ENTITY_GENDER]: {
    label: 'Entity gender',
    metricType: RiskMetricType.TEXT,
  },
  [EntityInformationMatchMetric.ENTITY_SSN]: {
    label: 'Entity SSN',
    metricType: RiskMetricType.TEXT,
  },
  [EntityInformationMatchMetric.ENTITY_AGE]: {
    label: 'Entity age',
    metricType: RiskMetricType.NUMBER,
  },
  [EntityInformationMatchMetric.ENTITY_BUSINESS_NAME]: {
    label: 'Entity business name',
    metricType: RiskMetricType.TEXT,
  },
  [EntityInformationMatchMetric.ENTITY_ACCOUNT_HOLDER_NAME]: {
    label: 'Entity account holder name',
    metricType: RiskMetricType.TEXT,
  },
  [EntityInformationMatchMetric.ENTITY_DBA]: {
    label: 'Entity doing business as',
    metricType: RiskMetricType.TEXT,
  },
  [EntityInformationMatchMetric.ENTITY_CORPORATE_TAX_ID]: {
    label: 'Entity corporate tax ID',
    metricType: RiskMetricType.TEXT,
  },
} as const;

export enum AgeOfRecordMetric {
  ENTITY_MONTHS_FROM_REGISTRATION = 'ENTITY_MONTHS_FROM_REGISTRATION',
}

export const AGE_OF_RECORD_METRIC_DETAILS: {
  [key in AgeOfRecordMetric]: RiskMetricDetails;
} = {
  [AgeOfRecordMetric.ENTITY_MONTHS_FROM_REGISTRATION]: {
    label: 'Months from registration',
    metricType: RiskMetricType.NUMBER,
    formatValue: (value: number | null) =>
      `${value || 0} months from registration date`,
  },
} as const;
