import { get } from 'app/shared/utils/fetchr';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

// Models
import { RiskModelDetails } from 'app/modules/riskRatings/responses';

// Constants
import { RISK_QUERY_KEYS } from 'app/modules/riskRatings/queries/keys';

// Selectors
import { selectHasReadEntitiesPermissions } from 'app/modules/session/selectors';

// An empty object is returned if there's no active risk model
type ActiveRiskModelRes = RiskModelDetails | Record<string, never>;

const fetchActiveRiskModel = (): Promise<ActiveRiskModelRes> =>
  get(`/risk/active-risk-model`);

export const useFetchActiveRiskModel = () => {
  const hasReadEntitiesPermission = useSelector(
    selectHasReadEntitiesPermissions,
  );

  return useQuery({
    queryKey: RISK_QUERY_KEYS.getActiveRiskModel(),
    queryFn: () => fetchActiveRiskModel(),
    select: (data): RiskModelDetails | undefined => {
      if (Object.keys(data).length !== 0) {
        return data as RiskModelDetails;
      }
      return undefined;
    },
    enabled: hasReadEntitiesPermission,
    // This doesn't change often, the query gets invalidated when an active model is edited
    staleTime: Infinity,
  });
};
