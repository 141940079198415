import { ClickableSection } from 'app/modules/codeInterpreter/components/shared/ClickableSection';
import { useFetchGeneratedFileExports } from 'app/modules/codeInterpreter/queries/useFetchGeneratedFileExports';
import {
  U21NoData,
  U21Loading,
  U21Grid,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { formatDate } from 'app/shared/utils/date';
import styled from 'styled-components';
import { IconReport } from '@u21/tabler-icons';
import { useState } from 'react';
import { EXPORT_FILE_TITLE_MAP } from 'app/modules/codeInterpreter/constants';

interface AiChatNoSessionProps {
  isLoading: boolean;
  isCreatingSession: boolean;
  onClick: (selectedFileId: number) => void;
}

export const AiChatNoSession = ({
  isLoading,
  isCreatingSession,
  onClick,
}: AiChatNoSessionProps) => {
  const [selectedId, setSelectedId] = useState<number>(-1);

  const { data, isLoading: isFileLoading } = useFetchGeneratedFileExports();

  if (isLoading || isFileLoading) {
    return <StyledLoader loading={isLoading || isFileLoading} />;
  }

  if (data.length === 0) {
    return (
      <U21NoData>
        Ask questions from your data with AI! Generate a file export to start
        asking questions here.
      </U21NoData>
    );
  }

  return (
    <>
      <StyledNoData>Ask questions from your data with AI!</StyledNoData>
      <StyledGridWrapper>
        <StyledTypography variant="h6">
          Available file exports:
        </StyledTypography>

        <StyledGrid columns={3}>
          {data.map((file) => (
            <ClickableSection
              icon={<IconReport />}
              key={file.id}
              title={EXPORT_FILE_TITLE_MAP[file.export_type]}
              description={`File generated on: ${formatDate(file.created_at)}`}
              onClick={() => {
                setSelectedId(file.id);
                onClick(file.id);
              }}
              disabled={isCreatingSession}
              isLoading={isCreatingSession && selectedId === file.id}
            />
          ))}
        </StyledGrid>
      </StyledGridWrapper>
    </>
  );
};

const StyledLoader = styled(U21Loading)`
  display: flex;
  flex: 1;
`;

const StyledNoData = styled(U21NoData)`
  min-height: 480px;
  max-height: 480px;
`;

const StyledTypography = styled(U21Typography)`
  padding: 16px;
`;

const StyledGridWrapper = styled.div`
  padding: 24px;
`;

const StyledGrid = styled(U21Grid)`
  max-height: 60%;
`;
