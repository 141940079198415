import { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAuth } from 'app/shared/providers/AuthProvider';

import { ROUTES_MAP } from 'app/shared/utils/routes';

// Actions
import { emailTokenExchange } from 'app/modules/session/actions';

// Helpers
import { searchParamsToObject } from 'app/shared/helpers';
import styled from 'styled-components';

import { U21Loading } from 'app/shared/u21-ui/components';

const EmailTokenExchange = () => {
  const { search } = useLocation();
  const { token } = searchParamsToObject(search);
  const dispatch = useDispatch();
  const { authClient: auth } = useAuth();

  useEffect(() => {
    if (!auth.loadingFlags) {
      dispatch(emailTokenExchange({ token, auth }));
    }
  }, [auth, dispatch, token]);

  if (!search || !token || (!auth.loadingFlags && auth.provider !== 'unit21')) {
    return <Redirect to={ROUTES_MAP.home.path} />;
  }

  return <StyledU21Loading loading />;
};

export default EmailTokenExchange;

const StyledU21Loading = styled(U21Loading)`
  height: 100%;
`;
