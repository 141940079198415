import { useDispatch } from 'react-redux';
import { post } from 'app/shared/utils/fetchr';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FRAUD_LOSS_TABLE_BASE } from 'app/modules/fraudDashboard/queries/keys';

// Actions
import { sendSuccessToast, sendErrorToast } from 'app/shared/toasts/actions';

// Models
import { EditFraudTxnMetaPayload } from 'app/modules/fraudDashboard/requests';

const editFraudTxnMeta = (payload: EditFraudTxnMetaPayload) =>
  post(`/fraud_reporting/txn_meta`, payload);

export const useEditFraudTxnMeta = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: EditFraudTxnMetaPayload) => editFraudTxnMeta(payload),
    onSuccess: () => {
      dispatch(sendSuccessToast('Updated losses'));
      queryClient.invalidateQueries({
        predicate({ queryKey }) {
          if (queryKey.length >= FRAUD_LOSS_TABLE_BASE.length) {
            // Invalidate Fraud loss queries
            return FRAUD_LOSS_TABLE_BASE.every(
              (fraudLosskey, index) => queryKey[index] === fraudLosskey,
            );
          }
          return false;
        },
      });
    },
    onError: () => {
      dispatch(sendErrorToast('Failed to update losses'));
    },
  });
};
