import {
  U21MenuItemProps,
  U21MenuItem,
  U21Typography,
} from 'app/shared/u21-ui/components';

import styled from 'styled-components';

interface OwnProps {
  title: string;
  items: (U21MenuItemProps & { key: string })[];
  closePopover: () => void;
}
export const AccountPopoverSection = (props: OwnProps) => {
  const { title, items, closePopover } = props;
  return (
    <>
      <Title color="text.secondary">{title}</Title>
      {items.map((option) => {
        const { key, ...restOption } = option;
        return (
          <StyledU21MenuItem
            key={key}
            item={{
              ...restOption,
              text: <Span>{option.text}</Span>,
              onClick: (e) => {
                option.onClick?.(e);
                closePopover();
              },
            }}
          />
        );
      })}
    </>
  );
};

const Title = styled(U21Typography)`
  margin: 8px;
`;

const Span = styled.span`
  ${(props) => props.theme.typography.body1}

  display: flex;
  align-items: center;
`;

const StyledU21MenuItem = styled(U21MenuItem)`
  padding-left: 16px;

  .MuiTypography-root {
    width: 100%;
  }
`;
