import React, { ReactNode } from 'react';

// Components
import { Table, Header, Icon, Segment, SemanticICONS } from 'semantic-ui-react';

// Styles
import styles from 'app/shared/styles/NoResultsComponent.module.scss';

// Utils

const DEFAULT_ICON: SemanticICONS = 'file alternate outline';

interface OwnProps {
  title: string;
  icon?: SemanticICONS | null;
  children?: ReactNode;
  tableCols?: number;
  height?: number;
}

type AllProps = OwnProps;

/**
 * @deprecated Use U21NoData instead
 */
const NoResultsComponent = ({
  title,
  icon,
  children,
  tableCols,
  height,
}: AllProps) => {
  if (tableCols !== undefined) {
    return (
      <>
        <Table.Row>
          <Table.HeaderCell
            colSpan={tableCols}
            className={styles.tableContainer}
          >
            <Header icon className={styles.tableContainer}>
              <Icon name={icon || DEFAULT_ICON} color="grey" />
              {title}
            </Header>
          </Table.HeaderCell>
        </Table.Row>
        {children}
      </>
    );
  }
  return (
    <Segment
      placeholder
      className={styles.gridContainer}
      style={height ? { height } : {}}
    >
      <Header icon className={styles.gridHeader}>
        <Icon name={icon || DEFAULT_ICON} color="grey" />
        {title}
      </Header>
      <div className={styles.gridChildren}>{children}</div>
    </Segment>
  );
};

export default NoResultsComponent;
