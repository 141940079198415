import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import autoBindReact from 'auto-bind/react';

// Redux
import { connect } from 'react-redux';

// Components
import { Modal, Icon, Loader, Segment } from 'semantic-ui-react';
import ContentHeader from 'app/shared/components/ContentHeader';
import GenericButton from 'app/shared/components/GenericButton';
import BasicObjectDetails from 'app/modules/entities/components/BasicObjectDetails';
import NoResultsComponent from 'app/shared/components/NoResultsComponent';
import CommentSection from 'app/modules/comments/components/CommentSection';
import CustomDataTable from 'app/shared/components/CustomDataTable';
import ReactTooltip from 'react-tooltip';
import { EntityAssociatedAlerts } from 'app/modules/entities/components/EntityAssociatedAlerts';

// Models
import { EditEntityPayload } from 'app/modules/entities/models';

// Actions
import {
  retrieveEntityPreview as retrieveEntityPreviewAction,
  editEntity as editEntityAction,
} from 'app/modules/entities/actions';

// Selectors
import { selectEditEventLoading } from 'app/shared/events/selectors';
import { selectHasPermissionsFactory } from 'app/modules/session/selectors';
import { selectLumosConfig } from 'app/modules/orgSettings/selectors';
import {
  selectEntity,
  selectRetrieveEntityLoading,
} from 'app/modules/entities/selectors';

// Utils
import permissions from 'app/shared/utils/permissions';
import assets from 'app/shared/utils/assets';

// Helpers
import {
  filterEntityCustomData,
  formatExternalUrl,
} from 'app/modules/entities/helpers';

// Styles
import styles from 'app/modules/entities/styles/EntityModal.module.scss';
import entityDetailsSummaryStyles from 'app/modules/entities/styles/EntityDetailsSummary.module.scss';
import { DataLabelList } from 'app/modules/dataLabels/DataLabelList';
import { EditAssociatedDataLabelsButton } from 'app/modules/dataLabels/EditAssociatedDataLabelsButton';
import styled from 'styled-components';

interface OwnProps {
  handleClose: () => void;
  // had trouble with typing here, typing is an html event
  onFullDetailsClick: (e) => void;
  id: number | string;
}

const PERMISSIONS = [permissions.editEntities];
const selectHasEditEntityPermissions = selectHasPermissionsFactory(PERMISSIONS);

const mapStateToProps = (state: RootState) => {
  return {
    editEntityLoading: selectEditEventLoading(state),
    hasEditEntityPermissions: selectHasEditEntityPermissions(state),
    entity: selectEntity(state),
    retrieveEntityLoading: selectRetrieveEntityLoading(state),
    lumosConfig: selectLumosConfig(state),
  };
};

const mapDispatchToProps = {
  editEntity: editEntityAction,
  retrieveEntityPreview: retrieveEntityPreviewAction,
};

type AllProps = OwnProps &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  RouteComponentProps<Record<string, never>>;

class EntityModal extends Component<AllProps> {
  constructor(props: AllProps) {
    super(props);

    autoBindReact(this);
  }

  componentDidMount() {
    const { id, entity, retrieveEntityPreview } = this.props;
    if (entity && entity.id === id) {
      return;
    }
    retrieveEntityPreview(id);
  }

  handleCommentSubmit = (comments: string) => {
    const { id, editEntity } = this.props;
    const request: EditEntityPayload = {
      id,
      comments,
    };
    editEntity(request);
  };

  renderEntitySummarySegment() {
    const { entity, lumosConfig } = this.props;

    const body =
      Object.keys(entity).length > 0 ? (
        <Segment className={styles.zeroPadding}>
          <BasicObjectDetails
            configType="entityPageConfig"
            object={entity}
            objectType={entity.entity_type || ''}
            censorshipObjectType="entity"
          />
        </Segment>
      ) : (
        <NoResultsComponent
          title="No profile data"
          icon={assets.icons.userProfile}
        />
      );

    return (
      <>
        <ReactTooltip id="externalLinkButtonTooltip" />
        <Segment.Group className={entityDetailsSummaryStyles.zeroSpacing}>
          <Segment color="blue" textAlign="center" className={styles.text}>
            Profile
            <div
              className={entityDetailsSummaryStyles.profileExternalLinkButtons}
            >
              {lumosConfig.entity_page.external_links &&
                lumosConfig.entity_page.external_links.map((link) => {
                  if (!link) return null;
                  const externalUrl = formatExternalUrl(entity, link);
                  return (
                    <div
                      key={`ext-button-${link.label}`}
                      data-for="externalLinkButtonTooltip"
                      data-tip={link.label}
                      className={
                        entityDetailsSummaryStyles.externalButtonContainer
                      }
                    >
                      {externalUrl.length > 0 && (
                        <Icon
                          onClick={() => window.open(externalUrl, '_blank')}
                          name={assets.icons.linkify}
                          className={
                            entityDetailsSummaryStyles.externalLinkButtonEnabled
                          }
                        />
                      )}
                    </div>
                  );
                })}
            </div>
          </Segment>
          {body}
        </Segment.Group>
        <CustomDataTable data={filterEntityCustomData(entity, lumosConfig)} />
        <EntityAssociatedAlerts entityExternalId={entity.external_id} />
      </>
    );
  }

  renderTagsAndComments = () => {
    const { entity, editEntityLoading, hasEditEntityPermissions, editEntity } =
      this.props;
    const tagIds = entity.tags.map((tag) => tag.id);

    return (
      <div className={styles.tags}>
        {this.renderEditLoading()}
        <TagTitle>
          <div className={styles.tagsLabel}>Tags</div>
          {hasEditEntityPermissions ? (
            <EditAssociatedDataLabelsButton
              objectDisplayName={entity.external_id}
              tagIds={tagIds}
              isEditLoading={editEntityLoading}
              editObjectTags={async (newTagIds: (string | number)[]) => {
                editEntity({
                  id: entity.id.toString(),
                  tags: newTagIds,
                });
              }}
              objectType="ENTITY"
              objectExternalId={entity.external_id}
            />
          ) : null}
        </TagTitle>
        <DataLabelList tagIds={tagIds} />
        <div className={styles.commentsHeader}>Comments</div>
        <CommentSection
          handleSubmit={this.handleCommentSubmit}
          ownPropsLoading={editEntityLoading}
          disabled={!hasEditEntityPermissions}
        />
      </div>
    );
  };

  renderEntityHeader = () => {
    const { entity, id } = this.props;
    if (!entity || Object.keys(entity).length === 0) {
      return `Entity #${id}`;
    }
    const readableName = entity.name_readable || '';
    if (!readableName) {
      return `Entity #${id}`;
    }
    return readableName;
  };

  renderContent = () => {
    const {
      entity,
      retrieveEntityLoading,
      editEntityLoading,
      id,
      handleClose,
      onFullDetailsClick,
    } = this.props;
    if (retrieveEntityLoading || entity.id !== id) {
      return (
        <Modal.Content className={styles.retrieveLoadingContainer}>
          {/* Did not use loading component here because there is a known issue with semantic ui modals and loaders */}
          <Loader active className={styles.loaderWorkaround}>
            Loading
          </Loader>
        </Modal.Content>
      );
    }

    return (
      <>
        <Modal.Content>
          <div className={styles.headerContainer}>
            <ContentHeader>
              <Icon
                name={
                  entity.type === 'BUSINESS'
                    ? assets.icons.factory
                    : assets.icons.userCircle
                }
              />
              {this.renderEntityHeader()}
            </ContentHeader>
            <GenericButton
              basic
              icon={assets.icons.goto}
              content="View full details"
              disabled={editEntityLoading}
              className={styles.fullDetailsButton}
              onClick={onFullDetailsClick}
            />
          </div>
          <div className={styles.externalIdSubtitle}>
            {`Unit21 Entity ID #${entity.id}`}
          </div>
          <ReactTooltip id="continuousTooltip" effect="solid" place="top" />
          {this.renderEntitySummarySegment()}
          <br />
          {this.renderTagsAndComments()}
        </Modal.Content>
        <Modal.Actions>
          <GenericButton
            negative
            icon={assets.icons.close}
            labelPosition="right"
            content="Close"
            onClick={handleClose}
          />
        </Modal.Actions>
      </>
    );
  };

  renderEditLoading = () => {
    const { editEntityLoading } = this.props;
    if (!editEntityLoading) {
      return null;
    }
    return (
      <div className={styles.editLoadingContainer}>
        {/* styling override required https://github.com/Semantic-Org/Semantic-UI-React/issues/3133 */}
        <div className={styles.saving}>Saving</div>{' '}
        <Loader size="mini" active inline className="modal-loading" />
      </div>
    );
  };

  render() {
    const { entity, handleClose, retrieveEntityLoading } = this.props;

    if (
      !retrieveEntityLoading &&
      (!entity || Object.keys(entity).length <= 0)
    ) {
      return null;
    }

    return (
      <Modal className={styles.modal} onClose={handleClose} open>
        {this.renderContent()}
      </Modal>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EntityModal),
);

const TagTitle = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 3px;
`;
