import { AnalyticsEvents, EventConfig } from 'app/shared/u21-ui/analytics';
import { U21DateTimePickerProps } from 'app/shared/u21-ui/components';

export const onchange: EventConfig<U21DateTimePickerProps['value']> = {
  name: 'U21DateTimePicker changed',
  createPayload: (props, _, date) => {
    const name = props.label || props['aria-label'] || props.name;
    if (!name) {
      return null;
    }
    return { date, name };
  },
};

export const u21DateTimePickerEvents = {
  [AnalyticsEvents.U21DATETIMEPICKER_ON_CHANGE]: onchange,
};
