import { IconProps } from 'app/shared/components/Icons/models';

export const IconAiAgent = ({
  size = 20,
  color = 'currentColor',
  stroke = 2,
  className = '',
  ...props
}: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`tabler tabler-icon-iconAiAgent ${className}`}
    {...props}
  >
    <path
      d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
      stroke={color}
      strokeWidth={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.90501 10.2888C9.93516 10.1972 10.0648 10.1972 10.095 10.2888L10.8322 12.5303C10.9314 12.832 11.168 13.0686 11.4697 13.1678L13.7112 13.905C13.8028 13.9352 13.8028 14.0648 13.7112 14.095L11.4697 14.8322C11.168 14.9314 10.9314 15.168 10.8322 15.4697L10.095 17.7112C10.0648 17.8028 9.93516 17.8028 9.90501 17.7112L9.16783 15.4697C9.06861 15.168 8.83199 14.9314 8.5303 14.8322L6.28884 14.095C6.19716 14.0648 6.19716 13.9352 6.28884 13.905L8.53031 13.1678C8.83199 13.0686 9.06861 12.832 9.16783 12.5303L9.90501 10.2888Z"
      fill={color}
    />
    <path
      d="M10.905 6.28884C10.9352 6.19716 11.0648 6.19716 11.095 6.28884L11.3372 7.02528C11.4364 7.32697 11.673 7.56359 11.9747 7.6628L12.7112 7.90501C12.8028 7.93516 12.8028 8.06484 12.7112 8.09499L11.9747 8.3372C11.673 8.43641 11.4364 8.67303 11.3372 8.97472L11.095 9.71116C11.0648 9.80284 10.9352 9.80284 10.905 9.71116L10.6628 8.97472C10.5636 8.67303 10.327 8.43641 10.0253 8.3372L9.28884 8.09499C9.19716 8.06484 9.19716 7.93516 9.28884 7.90501L10.0253 7.6628C10.327 7.56359 10.5636 7.32697 10.6628 7.02528L10.905 6.28884Z"
      fill={color}
    />
    <path
      d="M15.305 8.28884C15.3352 8.19716 15.4648 8.19716 15.495 8.28884L15.9847 9.77779C16.0839 10.0795 16.3205 10.3161 16.6222 10.4153L18.1112 10.905C18.2028 10.9352 18.2028 11.0648 18.1112 11.095L16.6222 11.5847C16.3205 11.6839 16.0839 11.9205 15.9847 12.2222L15.495 13.7112C15.4648 13.8028 15.3352 13.8028 15.305 13.7112L14.8153 12.2222C14.7161 11.9205 14.4795 11.6839 14.1778 11.5847L12.6888 11.095C12.5972 11.0648 12.5972 10.9352 12.6888 10.905L14.1778 10.4153C14.4795 10.3161 14.7161 10.0795 14.8153 9.77779L15.305 8.28884Z"
      fill={color}
    />
  </svg>
);
