import { Collapse } from '@mui/material';
import {
  OPERATOR_OPTIONS,
  U21_DATA_MAPPING_SCHEMA_KEYS,
} from 'app/modules/dataMapping/constants';
import { U21Object } from 'app/modules/dataMapping/types';
import { ExecutionInfo } from 'app/modules/pullBasedDataFiles/components/sidebars/ProcessingErrorsSidebar';
import {
  ANNOTATION_TRANSFORMATION_EXCEPTION_TYPE_TO_READABLE_NAME,
  PROCESSING_EXCEPTION_TYPE_TO_ERROR_DESCRIPTION,
} from 'app/modules/pullBasedDataFiles/constants';
import {
  getReadableTransformation,
  isAnnotationTransformationException,
} from 'app/modules/pullBasedDataFiles/helpers';
import {
  AnnotationTransformationException,
  AnnotationTransformationExceptionInstance,
  AnnotationTransformationExceptionType,
  ProcessingException,
  ProcessingExceptionInstance,
  ProcessingExceptionType,
} from 'app/modules/pullBasedDataFiles/responses';
import {
  U21Button,
  U21Divider,
  U21Spacer,
  U21Typography,
} from 'app/shared/u21-ui/components';
import pluralize from 'pluralize';
import { ReactNode, useState } from 'react';
import styled from 'styled-components';

interface ErrorSectionProps {
  error: ProcessingException | AnnotationTransformationException;
  objectType: U21Object;
}

const ErrorMessage = ({ error, objectType }: ErrorSectionProps) => {
  if (isAnnotationTransformationException(error)) {
    const {
      type,
      transformation,
      annotation,
      columns,
      count,
      annotation_type: annotationType,
    } = error;
    const isTransformationError =
      type === AnnotationTransformationExceptionType.TRANSFORMATION_ERROR &&
      !!transformation;
    if (
      type ===
      AnnotationTransformationExceptionType.CONDITIONAL_REQUIRED_FIELD_ERROR
    ) {
      return (
        <>
          <b>{count}</b>{' '}
          <b>
            {ANNOTATION_TRANSFORMATION_EXCEPTION_TYPE_TO_READABLE_NAME[type]}
          </b>{' '}
          {pluralize('error', count)} for{' '}
          <b>
            {annotationType === 'CUSTOM' ? 'Custom Data' : 'Unit21'}:{' '}
            {U21_DATA_MAPPING_SCHEMA_KEYS[annotation]?.label ?? annotation}
          </b>
          . This is likely due to conditions evaluating to false.
        </>
      );
    }
    return (
      <>
        <b>{count}</b>{' '}
        <b>{ANNOTATION_TRANSFORMATION_EXCEPTION_TYPE_TO_READABLE_NAME[type]}</b>{' '}
        {pluralize('error', count)} when{' '}
        {isTransformationError ? 'transforming' : 'mapping'}{' '}
        {pluralize('column', columns.length)}{' '}
        {columns.map((c, i) =>
          i < columns.length - 1 ? <b key={c}>{c}, </b> : <b key={c}>{c}</b>,
        )}{' '}
        {isTransformationError && (
          <>
            with the{' '}
            <b>{getReadableTransformation(transformation)} transformation</b>{' '}
            for mapping
          </>
        )}{' '}
        to{' '}
        <b>
          {annotationType === 'CUSTOM' ? 'Custom Data' : 'Unit21'}:{' '}
          {U21_DATA_MAPPING_SCHEMA_KEYS[annotation]?.label ?? annotation}
        </b>
        .
      </>
    );
  }
  const { type, count } = error;
  return (
    <>
      <b>{count}</b> <b>Directionality</b> {pluralize('error', count)}{' '}
      {PROCESSING_EXCEPTION_TYPE_TO_ERROR_DESCRIPTION[type]}
      {type === ProcessingExceptionType.EXCESSIVE_OBJECT_ERROR
        ? ` ${pluralize(objectType).toLowerCase()}`
        : ''}
      .
    </>
  );
};

interface ErrorInstanceListProps {
  errors:
    | ProcessingExceptionInstance[]
    | AnnotationTransformationExceptionInstance[];
  isCollapsed: boolean;
}

const ErrorInstanceList = ({ errors, isCollapsed }: ErrorInstanceListProps) => {
  const [showData, setShowData] = useState<Record<number, boolean>>({});

  return (
    <Collapse in={!isCollapsed}>
      <U21Spacer marginEnd dividers>
        {errors.map((e) => (
          <U21Spacer key={e.row_number} spacing={0.5}>
            {e.value !== undefined && (
              <ExecutionInfo
                labelWidth={86}
                label="Value"
                value={formatErrorValue(e.value)}
              />
            )}
            <ExecutionInfo
              labelWidth={86}
              label="Row number"
              value={e.row_number}
            />
            <div>
              <StyledButton
                variant="text"
                color="primary"
                onClick={() =>
                  setShowData((prev) => ({
                    ...prev,
                    [e.row_number]: !showData[e.row_number],
                  }))
                }
              >
                {showData[e.row_number] ? 'Hide' : 'Show'} raw data...
              </StyledButton>
            </div>
            <Collapse in={showData[e.row_number]}>
              <RawData>{JSON.stringify(e.raw_data, null, 4)}</RawData>
            </Collapse>
          </U21Spacer>
        ))}
      </U21Spacer>
    </Collapse>
  );
};

const formatErrorValue = (
  val: AnnotationTransformationExceptionInstance['value'],
): ReactNode => {
  if (val && typeof val === 'object') {
    return (
      <>
        Error evaluating <b>{val.left.join(', ')}</b>{' '}
        {OPERATOR_OPTIONS.options.find((o) => o.value === val.operator)?.text ??
          'matches regular expression'}{' '}
        <b>{val.right.join(', ')}</b>
      </>
    );
  }
  return JSON.stringify(val);
};

const StyledButton = styled(U21Button)`
  ${(props) => props.theme.typography.body2};
`;

const RawData = styled.pre`
  font-size: ${(props) => props.theme.typography.body2.fontSize};
`;

export const ErrorSection = ({ error, objectType }: ErrorSectionProps) => {
  const { errors } = error;
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const handleToggleCollapse = () => {
    setIsCollapsed((prevState) => {
      return !prevState;
    });
  };

  return (
    <OuterList>
      <li>
        <StyledTypography variant="body2">
          <ErrorMessage error={error} objectType={objectType} />{' '}
          <U21Button
            variant="text"
            color="primary"
            onClick={handleToggleCollapse}
          >
            {isCollapsed ? 'See' : 'Hide'} details...
          </U21Button>
        </StyledTypography>
        <ErrorInstanceList errors={errors} isCollapsed={isCollapsed} />
        {!isCollapsed && <StyledDivider horizontal />}
      </li>
    </OuterList>
  );
};

const StyledTypography = styled(U21Typography)`
  margin-bottom: 8px;
`;

const OuterList = styled.ul`
  padding-left: 16px;
  max-width: 100%;
  margin-bottom: 0;
`;

const StyledDivider = styled(U21Divider)`
  margin-bottom: 8px;
`;
