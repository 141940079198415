import { createSelector } from 'reselect';
import { unionBy } from 'lodash';

// Constants
import { initialState } from 'app/modules/permissions/reducer';
import { PermissionsActionTypes } from 'app/modules/permissions/models';

export const selectPermissions = (state: RootState) => {
  return state.permissions.permissions || { ...initialState.permissions };
};

export const selectUnassignedPermissions = (state: RootState) => {
  return (
    state.permissions.unassignedPermissions || [
      ...initialState.unassignedPermissions,
    ]
  );
};

// combo of assigned and unassigned permissions
export const selectAllPermissions = createSelector(
  selectPermissions,
  selectUnassignedPermissions,
  (permissions, unassignedPermissions) => {
    return unionBy(permissions, unassignedPermissions, 'id');
  },
);

// Loading
export const selectPermissionsLoading = (state: RootState) => {
  return Boolean(state.loading[PermissionsActionTypes.RETRIEVE_PERMISSIONS]);
};

export const selectEditPermissionsLoading = (state: RootState) => {
  return Boolean(state.loading[PermissionsActionTypes.EDIT_PERMISSIONS]);
};
