import styled from 'styled-components';
import { useSelector } from 'react-redux';

// Components
import {
  U21Avatar,
  U21Spacer,
  U21Typography,
} from 'app/shared/u21-ui/components';

// Selectors
import { selectAgent } from 'app/modules/session/selectors';
import { forwardRef, useMemo } from 'react';

import Unit21Icon from 'app/shared/components/logos/icon.svg';
import { formatDatetime } from 'app/shared/utils/date';
import { AiChatMessageResponse } from 'app/modules/codeInterpreter/responses';

export const AiChatMessageDisplay = forwardRef<
  HTMLDivElement,
  AiChatMessageResponse
>(({ role, message, image_link: imageLink, created_at: createdAt }, ref) => {
  const { picture, full_name: authoredBy } = useSelector(selectAgent);

  const isUser = useMemo<boolean>(() => role === 'user', [role]);

  return (
    <StyledUserMessage ref={ref} $isUser={isUser}>
      <U21Spacer horizontal align="start">
        {isUser ? (
          <U21Avatar src={picture} name={authoredBy} />
        ) : (
          <StyledAvatar svg={<StyledUnit21Icon />} name="Unit21" />
        )}

        <U21Spacer>
          <div>
            <U21Typography variant="subtitle1">
              {isUser ? authoredBy : 'Unit21'}
            </U21Typography>
            <U21Typography color="text.secondary" variant="subtitle2">
              {formatDatetime(createdAt)}
            </U21Typography>
          </div>

          <U21Typography>{message}</U21Typography>
          {imageLink && imageLink !== 'NA' && (
            <img
              alt="Graph generated from code"
              width="500rem"
              src={imageLink}
            />
          )}
        </U21Spacer>
      </U21Spacer>
    </StyledUserMessage>
  );
});

const StyledAvatar = styled(U21Avatar)`
  background-color: lightgray;
`;

const StyledUnit21Icon = styled(Unit21Icon)`
  height: 45%;
`;

const StyledUserMessage = styled.div<{ $isUser: boolean }>`
  padding: 1rem;
  margin-top: 8px;
  white-space: pre-line;
  border-radius: 10px;
  background-color: ${(props) =>
    props.$isUser ? props.theme.palette.background.neutral : 'transparent'};
`;
