import React, { useState, useEffect, useMemo } from 'react';
import { TextBlock } from 'react-placeholder/lib/placeholders';
import 'react-placeholder/lib/reactPlaceholder.css';
import styles from 'app/shared/styles/TextPlaceholder.module.scss';

interface TableCellProps {
  children: any;
  delay: number | undefined;
  loading: boolean;
  rows: number;
  isCollapsible?: boolean;
  isTableRow?: boolean;
}

const TextPlaceholder = ({
  children,
  delay,
  loading,
  rows,
  isCollapsible,
  isTableRow,
}: TableCellProps) => {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    let timer;
    if (loading && delay) {
      timer = setTimeout(() => setIsLoading(true), delay);
    } else {
      setIsLoading(false);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [delay, loading]);

  const className = useMemo(() => {
    if (isCollapsible) {
      return styles.textPlaceholder;
    }
    if (isTableRow) {
      return styles.tableTextPlaceholder;
    }
    return '';
  }, [isCollapsible, isTableRow]);

  if (isLoading) {
    return <TextBlock rows={rows} color="#CDCDCD" className={className} />;
  }
  return children;
};

export default TextPlaceholder;
