import { FC } from 'react';

// Components
import { U21Modal } from 'app/shared/u21-ui/components';
import { IconAlertTriangle, IconTrash } from '@u21/tabler-icons';

interface DeleteNarrativeModalProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
}

export const DeleteNarrativeModal: FC<DeleteNarrativeModalProps> = ({
  open,
  handleClose,
  handleSubmit,
}) => {
  return (
    <U21Modal
      open={open}
      titleIcon={<IconAlertTriangle />}
      title="Warning"
      onAction={handleSubmit}
      onClose={handleClose}
      actionButtonProps={{
        children: 'Delete',
        color: 'error',
        startIcon: <IconTrash />,
      }}
    >
      Are you sure you want to continue deleting this narrative?
    </U21Modal>
  );
};
