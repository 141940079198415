import { addCommentToNarrative } from 'app/shared/components/RichTextEditor/api';
import { AddCommentToNarrativePayload } from 'app/shared/components/RichTextEditor/requests';
import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';
import { sendErrorToast, sendSuccessToast } from 'app/shared/toasts/actions';

const CK_EDITOR = 'ck-editor';

export const editCommentNarrativeThunk = u21CreateAsyncThunk<
  AddCommentToNarrativePayload,
  void
>(`${CK_EDITOR}/ADD_COMMENT_TO_NARRATIVE`, async (payload, { dispatch }) => {
  try {
    const response = await addCommentToNarrative(payload);
    dispatch(sendSuccessToast(`Comment thread added to narrative`));
    return response;
  } catch (e) {
    dispatch(sendErrorToast('Failed to add comment thread to narrative'));
    throw e;
  }
});
