import {
  FilterOperatorAndValueBase,
  FilterOption,
} from 'app/modules/filters/models';

import { FILTER_OPERATOR } from 'app/modules/filters/constants';

import styled from 'styled-components';

import { CreatableMultiSelect } from 'app/modules/filters/components/CreatableMultiSelect';
import {
  U21DatePicker,
  U21DateRangePicker,
  U21NumberRange,
  U21Select,
  U21Slider,
  U21TextField,
} from 'app/shared/u21-ui/components';

type Props = {
  option: FilterOption;
} & DistributivePartialBy<FilterOperatorAndValueBase, 'value'>;

export const FilterInput = (props: Props) => {
  const {
    onChange,
    operator,
    option: { customize },
    value,
  } = props;

  const CustomInput = customize?.[operator]?.Input;
  if (CustomInput) {
    return (
      <CustomInput onChange={onChange} operator={operator} value={value} />
    );
  }

  const inputProps = customize?.[operator]?.inputProps;
  switch (operator) {
    case FILTER_OPERATOR.CONTAINS_TEXT:
      return (
        <U21TextField
          autoFocus
          clearable={false}
          {...inputProps}
          onChange={onChange}
          type="text"
          value={value}
        />
      );

    case FILTER_OPERATOR.IS_AFTER_DATE:
    case FILTER_OPERATOR.IS_BEFORE_DATE: {
      const date = typeof value === 'string' ? new Date(value) : value;
      return (
        <U21DatePicker
          autoFocus
          backdrop
          clearable={false}
          {...inputProps}
          onChange={onChange}
          value={date}
        />
      );
    }

    case FILTER_OPERATOR.IS_BETWEEN_DATE: {
      const [start, end] = value || [undefined, undefined];
      return (
        <U21DateRangePicker
          autoFocus
          backdrop
          clearable={false}
          {...inputProps}
          onChange={onChange}
          value={[
            typeof start === 'string' ? new Date(start) : start,
            typeof end === 'string' ? new Date(end) : end,
          ]}
        />
      );
    }

    case FILTER_OPERATOR.IS_BETWEEN_NUMBER:
      return (
        <U21NumberRange
          autoFocus
          clearable={false}
          {...inputProps}
          onChange={onChange}
          value={value || [undefined, undefined]}
        />
      );

    case FILTER_OPERATOR.IS_BETWEEN_SLIDER:
      return (
        <StyledU21Slider
          autoFocus
          valueLabelDisplay="on"
          {...inputProps}
          onChange={onChange}
          value={value || [0, 0]}
        />
      );

    case FILTER_OPERATOR.IS_GREATER_THAN_NUMBER:
    case FILTER_OPERATOR.IS_LESS_THAN_NUMBER:
      return (
        <U21TextField
          autoFocus
          {...inputProps}
          onChange={onChange}
          type="number"
          value={value}
        />
      );

    case FILTER_OPERATOR.IS_ONE_OF:
    case FILTER_OPERATOR.IS_NOT_ONE_OF:
      return (
        <CreatableMultiSelect
          autoFocus
          backdrop
          clearable={false}
          options={[]}
          {...inputProps}
          onChange={onChange}
          value={value}
        />
      );

    case FILTER_OPERATOR.IS:
      return (
        <U21Select
          autoFocus
          backdrop
          clearable={false}
          options={[]}
          {...inputProps}
          onChange={onChange}
          value={value}
        />
      );

    case FILTER_OPERATOR.IS_EXACT_TEXT:
    case FILTER_OPERATOR.IS_NOT_EXACT_TEXT:
      return (
        <U21TextField
          autoFocus
          clearable={false}
          {...inputProps}
          onChange={onChange}
          type="text"
          value={value}
        />
      );

    default:
      return null;
  }
};

const StyledU21Slider = styled(U21Slider)`
  padding: 0 16px;
`;
