import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { sortBy } from 'lodash';

// Components
import { Icon, SemanticICONS } from 'semantic-ui-react';
import { Timeline, TimelineEvent } from 'react-event-timeline';
import { U21Typography } from 'app/shared/u21-ui/components';
import AuditTrailModal from 'app/shared/auditTrail/components/AuditTrailModal';

// Helpers
import { renderAuditTrailIconStyle } from 'app/shared/helpers';

// Types
import { ShortAuditTrailResponse } from 'app/shared/auditTrail/types';

// Models
import { TimelineTimeFormat } from 'app/modules/comments/models';
import { AuditTrailSummary } from 'app/shared/auditTrail/models';

// Styles
import { useTheme } from '@mui/material/styles';
import { StyleConstants } from 'app/styles/StyleConstants';
import scssStyles from 'app/shared/auditTrail/styles/AuditTrail.module.scss';
import styled from 'styled-components';

// Utils
import emptyFn from 'app/shared/utils/empty-fn';
import { getTimeFromNow, getLocalFormat } from 'app/shared/utils/timeHelpers';

import { addSARLink } from 'app/shared/auditTrail/helpers';

interface OwnProps {
  trail?: AuditTrailSummary[] | ShortAuditTrailResponse[];
  isFromNowTimeFormat?: boolean;
}

type AllProps = OwnProps;

const AuditTrailSection = ({ trail, isFromNowTimeFormat }: AllProps) => {
  const theme = useTheme();
  const sortedTrail = trail
    ? sortBy(
        trail,
        (item) => item.created_at && new Date(item.created_at),
      ).reverse()
    : [];
  const [timeFormat, setTimeFormat]: [
    TimelineTimeFormat,
    Dispatch<SetStateAction<TimelineTimeFormat>>,
  ] = useState('From Now');

  useEffect(() => {
    // Case when `isFromNowTimeFormat` is not provided as props, defaults to 'From Now' format
    if (isFromNowTimeFormat === undefined) {
      setTimeFormat('From Now');
    }
    if (isFromNowTimeFormat === false) {
      setTimeFormat('LLLL');
    }
  }, [isFromNowTimeFormat]);

  const [selectedAuditTrailId, setSelectedAuditTrailId] = useState<
    number | null
  >(null);
  const [auditTrailModalOpen, setAuditTrailModalOpen] =
    useState<boolean>(false);

  const toggleTimeFormat = () =>
    setTimeFormat(timeFormat === 'From Now' ? 'LLLL' : 'From Now');

  const openModal = (id: number) => {
    setSelectedAuditTrailId(id);
    setAuditTrailModalOpen(true);
  };
  const closeModal = () => setAuditTrailModalOpen(false);

  return (
    <>
      {auditTrailModalOpen && selectedAuditTrailId && (
        <AuditTrailModal
          handleClose={closeModal}
          id={String(selectedAuditTrailId)}
        />
      )}
      {sortedTrail.length > 0 && (
        <div>
          <Timeline style={styles.timeline.main}>
            {sortedTrail.map((i) => {
              const { iconColor, iconName, trailTitleString } =
                renderAuditTrailIconStyle(i.action_type);
              return (
                <div key={`div-${i.id}`}>
                  <TimelineEvent
                    key={`timeline-event-${i.id}`}
                    title={trailTitleString}
                    icon={
                      <StyledIcon
                        name={iconName as SemanticICONS}
                        size="large"
                      />
                    }
                    iconColor={iconColor}
                    style={styles.timeline.main}
                    titleStyle={styles.timeline.title}
                    subtitleStyle={styles.timeline.subtitle}
                    bubbleStyle={{
                      border: 'unset',
                      backgroundColor: theme.palette.background.paper,
                    }}
                    createdAt={
                      <U21Typography variant="body2">
                        {timeFormat === 'From Now'
                          ? getTimeFromNow(i.created_at)
                          : getLocalFormat(i.created_at, 'LLLL')}
                      </U21Typography>
                    }
                    onClick={toggleTimeFormat}
                    onIconClick={i.use_modal ? () => openModal(i.id) : emptyFn}
                  >
                    {i.use_modal && (
                      <Icon
                        name="info circle"
                        onClick={() => openModal(i.id)}
                        className={scssStyles.pressableIcon}
                      />
                    )}
                    <span style={styles.timeline.content}>
                      {addSARLink(i.content)}
                    </span>
                  </TimelineEvent>
                </div>
              );
            })}
          </Timeline>
        </div>
      )}
    </>
  );
};

const styles: StyleMap = {
  timeline: {
    main: {
      width: '100%',
    },
    content: {
      overflowWrap: 'break-word',
      fontSize: '0.9rem',
      fontFamily: StyleConstants.FONT_FAMILY,
      fontWeight: StyleConstants.CONTENT_BODY_FONT_WEIGHT,
      color: 'black',
    },
    title: {
      fontFamily: StyleConstants.FONT_FAMILY,
      fontSize: '1rem',
      fontWeight: StyleConstants.CONTENT_BOLD_FONT_WEIGHT,
      opacity: 0.7,
    },
    subtitle: {
      fontFamily: StyleConstants.FONT_FAMILY,
      fontSize: '1rem',
      fontWeight: StyleConstants.CONTENT_BODY_FONT_WEIGHT,
    },
  },
};

const StyledIcon = styled(Icon)`
  &.icon {
    margin: 0;
  }
`;

export default AuditTrailSection;
