import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  withLDProvider,
  useFlags,
  useLDClient,
} from 'launchdarkly-react-client-sdk';

// Selectors
import { selectAgent } from 'app/modules/session/selectors';
import { selectFeatureFlagsLoading } from 'app/shared/featureFlags/selectors';

// Actions
import { setFeatureFlags } from 'app/shared/featureFlags/actions';

// Constants
import getFromProcessEnv from 'app/shared/utils/getFromProcessEnv';
import { LD_CONFIG } from 'app/shared/featureFlags/constants';

const LAUNCH_DARKLY_TIMEOUT = 4000; // Wait max 4s
const LUMOS_URL = getFromProcessEnv('REACT_APP_AUTH0_LOGIN_REDIRECT_URL');

// the purpose of this component is to store our feature flags in redux so they are accessible in selectors and not just components
// The component is in charge of setting the launch darkly user and default flags if LD takes too long to respond
// https://docs.launchdarkly.com/sdk/client-side/react/react-web
const FeatureFlags = () => {
  const dispatch = useDispatch();
  const loggedAgent = useSelector(selectAgent);
  const launchDarklyClient = useLDClient();
  const flags = useFlags();
  const flagsLoading = useSelector(selectFeatureFlagsLoading);

  // ========== Loading timeout =============
  const [timeoutReached, setTimeoutReached] = useState<boolean>(false);
  const timeoutReachedRef = useRef(timeoutReached);
  timeoutReachedRef.current = timeoutReached;
  useEffect(() => {
    // Set a timeout so we don't block the whole app if launch darkly is not responding
    const loadingTimeout = setTimeout(
      () => setTimeoutReached(true),
      LAUNCH_DARKLY_TIMEOUT,
    );
    return () => clearTimeout(loadingTimeout);
  }, []);

  useEffect(() => {
    // Dispatch empty flags if we reached the timeout and we are still loading
    if (timeoutReached && flagsLoading) {
      dispatch(setFeatureFlags({}));
    }
  }, [dispatch, flagsLoading, timeoutReached]);

  useEffect(() => {
    if (loggedAgent.email) {
      launchDarklyClient?.identify({
        key: loggedAgent.email,
        email: loggedAgent.email,
        custom: {
          org_id: loggedAgent.org_id,
          org_name: loggedAgent.org_name,
          lumos_url: LUMOS_URL,
        },
      });
    }
  }, [
    launchDarklyClient,
    loggedAgent.email,
    loggedAgent.org_id,
    loggedAgent.org_name,
  ]);

  // ========== Set Flags on redux state =============
  useEffect(() => {
    if (flags) {
      dispatch(setFeatureFlags(flags));
    }
  }, [dispatch, flags]);

  return null;
};

export default withLDProvider(LD_CONFIG)(FeatureFlags);
