import {
  U21SelectOptionProps,
  U21Chip,
  U21TableColumn,
  U21TableBodyCellProps,
  U21Typography,
} from 'app/shared/u21-ui/components';
import {
  FraudLossVictim,
  FraudType,
  FraudLossFormState,
} from 'app/modules/fraudDashboard/models';
import { selectFormatAmount } from 'app/modules/orgSettings/selectors';
import { useSelector } from 'react-redux';
import { FraudLossTableData } from 'app/modules/fraudDashboard/responses';
import { BASE_BLOCKED_REASONS_OPTIONS } from 'app/modules/detectionModels/components/scenarioWidgets/consortium/constants';
import { fromUpperToSentenceCase } from 'app/shared/utils/string';

export const FRAUD_LOSS_VICTIM_LABEL_MAP: Record<FraudLossVictim, string> = {
  CUSTOMER: 'Customer',
  INSTITUTION: 'Institution',
  SPLIT: 'Split',
};

export const FRAUD_LOSS_VICTIM_OPTIONS: U21SelectOptionProps<FraudLossVictim>[] =
  Object.entries(FRAUD_LOSS_VICTIM_LABEL_MAP).map(
    ([key, label]: [FraudLossVictim, string]) => ({
      value: key,
      text: label,
    }),
  );

export const FRAUD_TYPE_OPTIONS: U21SelectOptionProps<FraudType>[] =
  BASE_BLOCKED_REASONS_OPTIONS;
export const FRAUD_TYPE_LABEL_MAP = BASE_BLOCKED_REASONS_OPTIONS.reduce(
  (acc, opt) => {
    return {
      ...acc,
      [opt.value]: opt.text,
    };
  },
  {} as Record<FraudType, string>,
);

type TableRow = Pick<
  FraudLossTableData,
  | 'event_time'
  | 'fraud_victim'
  | 'potential_loss'
  | 'prevented_loss'
  | 'recovered_loss'
  | 'actual_loss'
  | 'fraud_type'
>;
export const TXN_META_TABLE_COLUMNS: U21TableColumn<TableRow>[] = [
  {
    id: 'event_time',
    accessor: 'event_time',
    Header: 'Event time',
    type: 'datetime',
  },
  {
    id: 'fraud_victim',
    accessor: 'fraud_victim',
    Header: 'Loss by',
    Cell: ({ value }: U21TableBodyCellProps<TableRow>) => (
      <U21Chip>{value ? FRAUD_LOSS_VICTIM_LABEL_MAP[value] : 'Other'}</U21Chip>
    ),
    sortType: 'number',
  },
  {
    id: 'potential_loss',
    accessor: 'potential_loss',
    Header: 'Potential loss',
    Cell: ({ value }: U21TableBodyCellProps<TableRow>) => {
      const formatAmount = useSelector(selectFormatAmount);
      return (
        <U21Typography color="text.secondary" variant="body2">
          {formatAmount({ amount: value })}
        </U21Typography>
      );
    },
    sortType: 'number',
  },
  {
    id: 'prevented_loss',
    accessor: 'prevented_loss',
    Header: 'Prevented loss',
    Cell: ({ value }: U21TableBodyCellProps<TableRow>) => {
      const formatAmount = useSelector(selectFormatAmount);
      return (
        <U21Typography color="text.secondary" variant="body2">
          {formatAmount({ amount: value })}
        </U21Typography>
      );
    },
    sortType: 'number',
  },
  {
    id: 'recovered_loss',
    accessor: 'recovered_loss',
    Header: 'Recovered loss',
    Cell: ({ value }: U21TableBodyCellProps<TableRow>) => {
      const formatAmount = useSelector(selectFormatAmount);
      return (
        <U21Typography color="text.secondary" variant="body2">
          {formatAmount({ amount: value })}
        </U21Typography>
      );
    },
    sortType: 'number',
  },
  {
    id: 'actual_loss',
    accessor: 'actual_loss',
    Header: 'Actual loss',
    Cell: ({ value }: U21TableBodyCellProps<TableRow>) => {
      const formatAmount = useSelector(selectFormatAmount);
      return (
        <U21Typography color="text.secondary" variant="body2">
          {formatAmount({ amount: value })}
        </U21Typography>
      );
    },
    sortType: 'number',
  },
  {
    id: 'fraud_type',
    accessor: 'fraud_type',
    Header: 'Fraud type',
    Cell: ({ value }: U21TableBodyCellProps<TableRow>) => (
      <U21Chip>
        {value
          ? FRAUD_TYPE_LABEL_MAP[value] || fromUpperToSentenceCase(value)
          : 'Other'}
      </U21Chip>
    ),
  },
] as const;

export const INITIAL_BATCH_UPDATES: FraudLossFormState['batchUpdates'] = {
  fraudType: undefined,
  fraudVictim: undefined,
};

const DAYS_PER_MONTH = 31;
export const DATE_RANGE_OPTIONS = [
  { value: 7, text: 'Last week' },
  { value: DAYS_PER_MONTH, text: 'Last month' },
  { value: DAYS_PER_MONTH * 3, text: 'Last 3 months' },
  { value: DAYS_PER_MONTH * 6, text: 'Last 6 months' },
  { value: DAYS_PER_MONTH * 12, text: 'Last 12 months' },
] as const;
export type DateRangeOption =
  | (typeof DATE_RANGE_OPTIONS)[number]['text']
  | ''
  | 'Selected period';
