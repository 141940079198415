import { useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useMemo, useState } from 'react';
import { round } from 'lodash';

// Models
import {
  EntityRiskScoreSnapshot,
  RiskMetricDetails,
} from 'app/modules/riskRatings/models';
import { RiskModelSummary } from 'app/modules/riskRatings/responses';

// Components
import {
  U21Chip,
  U21MenuLayout,
  U21Spacer,
  U21Progress,
  U21Tooltip,
  U21Button,
  U21Typography,
  U21Skeleton,
} from 'app/shared/u21-ui/components';
import { IconAlertTriangle } from '@u21/tabler-icons';

// Constants
import {
  RISK_PARAMETERS_MAP,
  ALL_RISK_METRIC_DETAILS_MAP,
} from 'app/modules/riskRatings/components/riskParameters';
import { ROUTES_MAP } from 'app/shared/utils/routes';
import { MAX_RISK } from 'app/modules/riskRatings/constants';

// Helpers
import {
  getRiskCategoryStyles,
  isCustomDataMetric,
  getParameterArgsFromRiskModel,
  getCustomLabelFromParameterArgs,
} from 'app/modules/riskRatings/helpers';
import { keyPathToLabel } from 'app/modules/dataSettings/utils';

// Selectors
import { selectHasReadRiskRatingsPermission } from 'app/modules/session/selectors';
import { selectDataSettingsById } from 'app/modules/dataSettings/selectors';

// Hooks
import { useFetchActiveRiskModel } from 'app/modules/riskRatings/queries/useFetchActiveRiskModel';
import { useFetchEntityRiskScore } from 'app/modules/riskRatings/queries/useFetchEntityRiskScore';

interface OwnProps {
  entityExternalId: string;
  showPopover?: boolean;
}

interface EntityRiskScoreDisplayOwnProps {
  score: number;
  riskModelMetadata: Pick<
    RiskModelSummary,
    'high_risk_floor' | 'low_risk_ceiling' | 'id'
  >;
  // Optional, if passed we show the evidence component on hover
  evidence?: EntityRiskScoreSnapshot;
}

const RiskScoreEvidence = ({
  evidence,
  riskModelMetadata,
  score,
}: EntityRiskScoreDisplayOwnProps) => {
  const customDataSettingsById = useSelector(selectDataSettingsById);
  const canReadRiskModel = useSelector(selectHasReadRiskRatingsPermission);
  const isOnRiskModelDetails = Boolean(
    useRouteMatch(ROUTES_MAP.riskRatingsId.path),
  );
  const showModelRedirect = !isOnRiskModelDetails && canReadRiskModel;
  const { data: activeRiskModel } = useFetchActiveRiskModel();
  const riskParameterArgsMap = useMemo(
    () =>
      getParameterArgsFromRiskModel(activeRiskModel)[
        evidence?.segment_id ?? ''
      ] ?? {},
    [activeRiskModel, evidence?.segment_id],
  );
  const defaultHighMatch = useMemo(
    () =>
      evidence?.trace.find((parameter) =>
        Boolean(
          parameter.parameter_trace.risk_metric_trace.risk_mapping_trace
            ?.default_high,
        ),
      ),
    [evidence],
  );

  if (!evidence) {
    return null;
  }

  return (
    <U21Spacer spacing={2}>
      <U21Typography variant="subtitle2">Risk Score Evidence</U21Typography>
      <StyledGrid>
        <U21Typography variant="subtitle2">Variable</U21Typography>
        <U21Typography variant="subtitle2">Risk</U21Typography>
        <U21Typography variant="subtitle2">Impact</U21Typography>
        {evidence.trace.map(
          ({ parameter, parameter_trace: parameterTrace, weight }) => {
            const isDefaultHigh = defaultHighMatch?.parameter === parameter;
            if (defaultHighMatch && !isDefaultHigh) {
              // Only show the default high match (if present)
              return null;
            }

            const key = parameter;

            // Format Parameter
            const parameterScore = isDefaultHigh
              ? MAX_RISK
              : parameterTrace.parameter_risk_score || 0;
            const { colorSchema } = getRiskCategoryStyles(
              parameterScore,
              riskModelMetadata.high_risk_floor,
              riskModelMetadata.low_risk_ceiling,
            );
            let parameterName =
              RISK_PARAMETERS_MAP[parameter || '']?.label ?? parameter;

            // Use the custom parameter name if available
            const customParameterName = getCustomLabelFromParameterArgs(
              riskParameterArgsMap[parameterTrace?.parameter_id ?? ''],
            );
            if (customParameterName) {
              parameterName = customParameterName;
            }

            // Format Metric
            const metricConfig: RiskMetricDetails | undefined =
              ALL_RISK_METRIC_DETAILS_MAP[parameterTrace.risk_metric || ''];
            let metricName = metricConfig?.label ?? parameterTrace.risk_metric;
            if (
              parameterTrace.risk_metric &&
              isCustomDataMetric(parameterTrace.risk_metric)
            ) {
              const customDataMetricConfig =
                customDataSettingsById[
                  parameterTrace.risk_metric_tracking_id || ''
                ];
              if (customDataMetricConfig) {
                metricName = keyPathToLabel(customDataMetricConfig);
              }
            }

            const metricValue =
              metricConfig?.formatValue?.(
                parameterTrace.risk_metric_trace.raw_value,
              ) ??
              parameterTrace.risk_metric_trace.raw_value ??
              'Unknown';

            return [
              <div key={key}>
                <U21Typography tooltip={parameterName}>
                  {metricName}
                </U21Typography>
              </div>,
              <div key={`${key}_score`}>
                <U21Tooltip tooltip={metricValue}>
                  <U21Spacer horizontal>
                    <U21Typography width={35}>{parameterScore}</U21Typography>
                    <U21Progress
                      width="100%"
                      color={colorSchema}
                      value={parameterScore}
                    />
                  </U21Spacer>
                </U21Tooltip>
              </div>,
              <div key={`${key}_impact`}>
                {isDefaultHigh ? (
                  <U21Chip color="error">Default High</U21Chip>
                ) : (
                  <U21Typography tooltip={`Weight: ${round(weight, 2)}`}>
                    {round(weight * parameterScore, 1)}
                  </U21Typography>
                )}
              </div>,
            ];
          },
        )}
        <div />
        <U21Typography variant="subtitle2">Final Score</U21Typography>
        <U21Typography variant="subtitle2">{Math.round(score)}</U21Typography>
      </StyledGrid>
      {showModelRedirect && (
        <U21Button
          to={ROUTES_MAP.riskRatingsId.path.replace(
            ':id',
            `${riskModelMetadata.id}`,
          )}
        >
          Go to Risk Model
        </U21Button>
      )}
    </U21Spacer>
  );
};

export const EntityRiskScoreDisplay = (
  props: EntityRiskScoreDisplayOwnProps,
) => {
  /* 
    It is recommended to use EntityRiskScore component.
    This component does not make any request and won't show the loading cases. 
    Use it if you already got the data from a different endpoint and don't need to show loaders.
  */
  const [evidenceOpen, setEvidenceOpen] = useState<boolean>(false);
  const {
    score,
    riskModelMetadata: {
      high_risk_floor: highRiskFloor,
      low_risk_ceiling: lowRiskCeiling,
    },
    evidence,
  } = props;

  const riskStyles = useMemo(
    () => getRiskCategoryStyles(score, highRiskFloor, lowRiskCeiling),
    [score, highRiskFloor, lowRiskCeiling],
  );

  const ScoreComponent = (
    <StyledScoreContainer
      onMouseEnter={(e) => {
        e.stopPropagation();
        if (evidence) {
          setEvidenceOpen(true);
        }
      }}
      horizontal
    >
      <U21Progress
        color={riskStyles.colorSchema}
        size={28}
        value={score}
        variant="circular"
      >
        {Math.round(score)}
      </U21Progress>
      <StyledRiskLabel
        variant="ghost"
        color={riskStyles.colorSchema}
        icon={<IconAlertTriangle />}
      >
        {riskStyles.label}
      </StyledRiskLabel>
    </StyledScoreContainer>
  );

  if (!evidence) {
    return ScoreComponent;
  }

  return (
    <U21Spacer>
      <U21MenuLayout
        open={evidenceOpen}
        onClose={() => {
          setEvidenceOpen(false);
        }}
        trigger={ScoreComponent}
      >
        <PopoverContainer onMouseLeave={() => setEvidenceOpen(false)}>
          <RiskScoreEvidence {...props} />
        </PopoverContainer>
      </U21MenuLayout>
    </U21Spacer>
  );
};

// Wraps the logic for retrieving the risk score for an entity and showing the right loader
export const EntityRiskScore = ({
  entityExternalId,
  showPopover = false,
}: OwnProps) => {
  const { data: riskScoreRes, isLoading } =
    useFetchEntityRiskScore(entityExternalId);

  if (isLoading) {
    return <U21Skeleton width={80} />;
  } else if (riskScoreRes?.loadingFromColdStorage) {
    return (
      <U21Tooltip tooltip="We are loading the risk score. Check back later.">
        <U21Skeleton width={80} />
      </U21Tooltip>
    );
  } else if (riskScoreRes && riskScoreRes.riskModel) {
    // We have an active model, either show the score or that the entity is unscored.
    if (riskScoreRes.result) {
      return (
        <EntityRiskScoreDisplay
          score={riskScoreRes.result.score}
          riskModelMetadata={riskScoreRes.riskModel}
          evidence={showPopover ? riskScoreRes.result.snapshot : undefined}
        />
      );
    }
    // There's no score, it should be computed _soon_
    return (
      <U21Chip
        tooltip="The entity hasn't been scored yet. Check back in 24h"
        variant="ghost"
      >
        Unscored
      </U21Chip>
    );
  }

  // Not Loading and no results
  return null;
};

const StyledGrid = styled.div`
  display: grid;
  gap: ${(props) => props.theme.spacing(2)};
  grid-template-columns: 1fr 2fr 1fr;
  width: 100%;
`;

const PopoverContainer = styled.div`
  padding: ${({ theme }) => theme.spacing(3)};
  width: 350px;
`;

const StyledScoreContainer = styled(U21Spacer)`
  overflow: hidden;
`;

const StyledRiskLabel = styled(U21Chip)`
  overflow: hidden;
`;
