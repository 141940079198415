import { IMPERSONATION_SLICE_NAME } from 'app/modules/impersonation/sliceImpersonation';
import { createSelector } from 'reselect';
import { getOriginalAgentId } from 'app/shared/utils/sessionStorage';
import { getAgentDisplayName } from 'app/modules/agents/utils';
import { U21SelectOptionProps } from 'app/shared/u21-ui/components';

const originalAgentId = getOriginalAgentId();

export const selectOrgAgents = (state: RootState) =>
  state[IMPERSONATION_SLICE_NAME].orgAgents;

export const selectOrgImpersonationAgentOptions = createSelector(
  [selectOrgAgents],
  (agents) =>
    Object.values(agents)
      .filter(
        (a) =>
          a.status === 'ACTIVE' && a.id !== originalAgentId && a.has_valid_auth,
      )
      .map<U21SelectOptionProps>((a) => {
        const agentName = getAgentDisplayName(a);
        return {
          text: agentName,
          value: a.id,
        };
      }),
);

// Loading
export const selectLoadingOrgAgents = (state: RootState) =>
  state[IMPERSONATION_SLICE_NAME].loadingOrgAgents;
