import { post } from 'app/shared/utils/fetchr';
import { useQuery } from '@tanstack/react-query';
import { ALERT_QUERY_KEYS } from 'app/modules/alerts/queries/keys';
import { RuleGraphMatches } from 'app/modules/detectionModels/models';

const fetchAlertGraphRuleMatches = (
  alertId: number,
): Promise<RuleGraphMatches[]> => {
  const path = `/alerts/${alertId}/entity-link-counts-matches`;
  return post(path);
};

export const useFetchAlertGraphRuleMatches = (alertId: number) => {
  return useQuery({
    queryKey: ALERT_QUERY_KEYS.getAlertGraphRuleMatches(alertId),
    queryFn: () => fetchAlertGraphRuleMatches(alertId),
    meta: { errorMessage: 'Failed fetching Alert Graph Rule Matches.' },
  });
};
