import { useState } from 'react';
import { U21Alert, U21Button } from 'app/shared/u21-ui/components';
import {
  HistogramGraph,
  HistogramProps,
} from 'app/modules/histogram/components/HistogramGraph';
import { Unreachable } from 'app/shared/components/Unreachable';

const MAX_GRAPHS = 20 * 500; // 20 types * 500 total events

// wrapper for HistogramGraph that shows an alert if the histogram data is too large
export const Histogram = ({ histogram, loading, type }: HistogramProps) => {
  const [hideAlert, setHideAlert] = useState(false);

  const numTransactionCodes = histogram.transaction_codes.length;
  const numTransactions =
    histogram.histogram_data.in.length + histogram.histogram_data.out.length;
  const numGraphs = numTransactionCodes * numTransactions;

  if (numGraphs > MAX_GRAPHS && !hideAlert) {
    return (
      <>
        <U21Alert severity="warning">
          ${type === 'transactions' ? 'Transaction' : 'Action event'} history
          has been hidden for this entity because there are a high number of{' '}
          {type === 'transactions' ? 'transactions' : 'action events'}
          {numTransactionCodes > 10
            ? ` and ${numTransactionCodes} ${type === 'transactions' ? 'transaction' : 'action event'} types`
            : ''}
          . With this much data, you may experience performance issues.{' '}
          <U21Button
            variant="text"
            color="primary"
            onClick={() => setHideAlert(true)}
          >
            Show me anyway
          </U21Button>
          .
        </U21Alert>
        <Unreachable
          error={`${type === 'transactions' ? 'Transaction' : 'Action event'} histogram limit reached`}
        />
      </>
    );
  }

  return <HistogramGraph histogram={histogram} loading={loading} type={type} />;
};
