import { Dispatch, SetStateAction, useMemo } from 'react';
import { SidePanelOptionProps } from 'app/shared/components/SidePanel/model';

import { alpha } from '@mui/system/colorManipulator';
import { selectFeatureFlags } from 'app/shared/featureFlags/selectors';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import styled from 'styled-components';

import { ListItem, ListItemButton } from '@mui/material';
import { U21Typography } from 'app/shared/u21-ui/components';

export const SidePanelMenuItem = ({
  Elm,
  expandable,
  selected,
  setSelected,
}: {
  Elm: SidePanelOptionProps;
  expandable?: boolean;
  selected: SidePanelOptionProps;
  setSelected: Dispatch<SetStateAction<SidePanelOptionProps>>;
}) => {
  const theme = useTheme();
  const featureFlags = useSelector(selectFeatureFlags);

  const hasFlags = useMemo(() => {
    let hasFlagsInner = true;
    if (Elm.requiredFlags) {
      if (Array.isArray(Elm.requiredFlags)) {
        hasFlagsInner = Elm.requiredFlags.every((flag) => featureFlags[flag]);
      } else {
        hasFlagsInner = featureFlags[Elm.requiredFlags];
      }
    }
    return hasFlagsInner;
  }, [Elm, featureFlags]);

  if (!hasFlags) {
    return null;
  }

  const isSelected = selected.label === Elm.label && expandable;

  return (
    <ListItem key={Elm.label} disablePadding onClick={() => setSelected(Elm)}>
      <StyledListItemButton disabled={!expandable} selected={isSelected}>
        <Elm.MenuIcon
          color={
            isSelected ? theme.palette.primary.main : theme.palette.grey[600]
          }
        />
        <U21Typography
          variant="caption"
          color={
            isSelected ? theme.palette.primary.main : theme.palette.grey[600]
          }
          textAlign="center"
        >
          {Elm.label}
        </U21Typography>
      </StyledListItemButton>
    </ListItem>
  );
};

const StyledListItemButton = styled(ListItemButton)`
  &:hover {
    color: ${({ theme }) => theme.palette.text.primary};
  }
  &.Mui-selected {
    background-color: ${({ theme }) => alpha(theme.palette.primary.main, 0.08)};
    border-left: ${({ theme }) => `2px solid ${theme.palette.primary.main}`};
  }
  border-left: 2px solid transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 60px;
`;
