import {
  RichTextEditorCommentThreadAddition,
  RichTextEditorCommentThread,
} from 'app/shared/components/RichTextEditor/models';

export const INITIAL_RICH_TEXT_EDITOR_COMMENT_THREAD_ADDITION_RESPONSE: RichTextEditorCommentThreadAddition =
  {
    threadId: '',
    comments: [],
  };

export const INITIAL_RICH_TEXT_EDITOR_COMMENT_THREAD: RichTextEditorCommentThread =
  {
    threadId: '',
    comments: [],
    // It defines the value on which the comment has been created initially.
    // If it is empty it will be set based on the comment marker.
    context: {
      type: '',
      value: '',
    },
    resolvedAt: null,
    resolvedBy: null,
    attributes: {},
    isFromAdapter: true,
  };
