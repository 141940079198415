import React, { useState } from 'react';

// Styles
import styles from 'app/shared/styles/ResizableInput.module.scss';

interface AllProps {
  value: any;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  className?: string;
  type?: HTMLInputElement['type'];
  onBlur?: (event: React.SyntheticEvent) => void;
  disabled?: boolean;
  maxLength?: number;
  placeholder?: string;
}

/**
 * @deprecated Use U21TextField instead
 */
const ResizableInput = ({
  className,
  onChange,
  onBlur,
  value,
  type,
  disabled,
  maxLength,
  placeholder,
}: AllProps) => {
  const [isValid, setIsValid] = useState(true);

  const blurValidation = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: newValue } = e.target;

    if (type === 'number') {
      const numVal = parseFloat(newValue);
      if (newValue.trim() === '') {
        setIsValid(true);
        onChange(e, newValue.trim());
      } else if (isNaN(numVal)) {
        setIsValid(false);
        onChange(e, '');
      } else {
        // eslint-disable-next-line no-param-reassign
        e.target.value = numVal.toString();
        setIsValid(true);
        onChange(e, numVal.toString());
      }
    } else {
      setIsValid(true);
    }

    if (onBlur) {
      onBlur(e);
    }
  };

  return (
    <input
      size={`${value}`.length}
      type="text"
      className={`${styles.input} ${className || ''} ${
        isValid ? '' : styles.invalidValue
      }`}
      value={value}
      onChange={(e) => {
        onChange(e, e.target.value);
      }}
      onBlur={blurValidation}
      disabled={Boolean(disabled)}
      maxLength={maxLength}
      placeholder={placeholder}
    />
  );
};

export default ResizableInput;
