import {
  FilterOperatorAndValue,
  FilterOperatorAndPartialValue,
  FilterOption,
  FilterOperator,
} from 'app/modules/filters/models';

import {
  getFilterOperators,
  isOperatorAndValueValid,
} from 'app/modules/filters/utils';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { FilterInput } from 'app/modules/filters/components/FilterInput';
import { IconArrowLeft, IconFilter } from '@u21/tabler-icons';
import {
  U21Button,
  U21Select,
  U21Spacer,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { FilterPinButton } from 'app/modules/filters/components/FilterPinButton';

interface BuilderProps {
  onClose: () => void;
  onDelete?: () => void;
  onPinChange?: () => void;
  onReset?: () => void;
  onSubmit: (operatorAndValue: FilterOperatorAndValue) => void;
  pinned: boolean;
  option: FilterOption;
}

interface UpdaterProps extends BuilderProps {
  onOperatorAndValueChange: (
    operatorAndValue: FilterOperatorAndPartialValue,
  ) => void;
  operatorAndValue: FilterOperatorAndPartialValue;
  submitLabel?: 'Add' | 'Update';
}

export const FilterUpdater = (props: UpdaterProps) => {
  const {
    onClose,
    onDelete,
    onOperatorAndValueChange,
    operatorAndValue,
    onPinChange,
    onReset,
    onSubmit,
    option,
    pinned,
    submitLabel = 'Update',
  } = props;
  const { operator, value } = operatorAndValue;
  const { customize, description, label, type } = option;

  return (
    <StyledU21Spacer
      // prevent focusing on menu when typing first letter of label
      // https://github.com/mui/material-ui/issues/28704
      onKeyDown={(e) => e.stopPropagation()}
    >
      <HeaderContainer horizontal>
        <HeaderContent horizontal spacing={0.5}>
          {Boolean(onReset) && (
            <U21Button
              aria-label="Back"
              icon={<IconArrowLeft />}
              onClick={onReset}
            />
          )}
          <U21Typography
            ellipsis
            icon={onReset ? undefined : <IconFilter />}
            tooltip={description}
            variant="subtitle1"
          >
            {label}
          </U21Typography>
        </HeaderContent>
        <FilterPinButton onPinChange={onPinChange} pinned={pinned} />
      </HeaderContainer>
      <U21Select
        backdrop
        clearable={false}
        onChange={(newOperator: FilterOperator) =>
          onOperatorAndValueChange({ operator: newOperator })
        }
        options={getFilterOperators(type, customize)}
        value={operator}
      />
      <FilterInput
        onChange={(newValue) =>
          onOperatorAndValueChange({
            operator,
            value: newValue,
          })
        }
        option={option}
        {...operatorAndValue}
      />
      <FooterContainer>
        {onDelete && (
          <U21Button onClick={onDelete} size="small" variant="ghost">
            Remove
          </U21Button>
        )}
        <FooterRightActions horizontal>
          <U21Button onClick={onClose} size="small">
            Cancel
          </U21Button>
          <U21Button
            color="primary"
            disabled={!isOperatorAndValueValid({ operator, value })}
            onClick={() => {
              const newFilter = { operator, value };
              if (isOperatorAndValueValid(newFilter)) {
                onSubmit(newFilter);
              }
            }}
            size="small"
            variant="contained"
          >
            {submitLabel}
          </U21Button>
        </FooterRightActions>
      </FooterContainer>
    </StyledU21Spacer>
  );
};

export const FilterBuilder = (props: BuilderProps) => {
  const {
    option: { customize, type },
  } = props;

  const [operatorAndValue, setOperatorAndValue] = useState<
    FilterOperatorAndPartialValue | { operator?: void; value?: void }
  >({});

  // defaultValue should not trigger the useEffect
  const customizeRef = useRef(customize);
  customizeRef.current = customize;
  useEffect(() => {
    const [{ value }] = getFilterOperators(type, customizeRef.current);
    setOperatorAndValue({
      operator: value,
      value: customizeRef.current?.[value]?.defaultValue,
    });
  }, [type]);

  const { operator } = operatorAndValue;
  if (!operator) {
    return null;
  }

  return (
    <FilterUpdater
      {...props}
      onOperatorAndValueChange={setOperatorAndValue}
      submitLabel="Add"
      operatorAndValue={operatorAndValue}
    />
  );
};

const StyledU21Spacer = styled(U21Spacer)`
  min-width: 300px;
  max-width: 600px;
`;

const HeaderContainer = styled(U21Spacer)`
  justify-content: space-between;
`;

const HeaderContent = styled(U21Spacer)`
  overflow: hidden;
`;

const FooterContainer = styled.div`
  display: flex;
`;

const FooterRightActions = styled(U21Spacer)`
  margin-left: auto;
`;
