import { TableConfig } from 'app/shared/pagination/models';

export const CHAINALYSIS_ALERT_DETAILS_TABLE_CONFIG: TableConfig[] = [
  {
    key: 'alertIdentifier',
    label: 'Alert ID',
  },
  {
    key: 'asset',
    label: 'Asset',
  },
  {
    key: 'level',
    label: 'Level',
  },
  {
    key: 'userId',
    label: 'User ID',
  },
  {
    key: 'service',
    label: 'Service',
  },
  {
    key: 'category',
    label: 'Category',
  },
  {
    key: 'createdAt',
    label: 'Created at',
  },
  {
    key: 'direction',
    label: 'Direction',
  },
  {
    key: 'timestamp',
    label: 'Event time',
  },
  {
    key: 'alertStatus',
    label: 'Alert status',
  },
  {
    key: 'exposureType',
    label: 'Exposure type',
  },
  {
    key: 'minThreshold',
    label: 'Rule threshold min',
  },
  {
    key: 'maxThreshold',
    label: 'Rule threshold max',
  },
  {
    key: 'transactionHash',
    label: 'Transaction hash',
  },
  {
    key: 'transactionIndex',
    label: 'Transaction index',
  },
  {
    key: 'transferReference',
    label: 'Transfer reference',
  },
  {
    key: 'transferReportedAt',
    label: 'Transfer reported at',
  },
  {
    key: 'transferOutputAddress',
    label: 'Transfer output address',
  },
  {
    key: 'kytAlertUrl',
    label: 'KYT Alert URL',
  },
];
