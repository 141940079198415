import { useMutation, useQueryClient } from '@tanstack/react-query';
import { NETWORK_ANALYSIS_QUERY_KEYS } from 'app/modules/networkAnalysis/queries/keys';
import { addQueuedRequest } from 'app/modules/networkAnalysis/sliceNetworkAnalysis';
import { sendErrorToast } from 'app/shared/toasts/actions';
import { post } from 'app/shared/utils/fetchr';
import { useDispatch } from 'react-redux';

export const useQueueNetworkAnalysis = (
  entityId: string,
  entityExternalId: string,
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: (payload: string) =>
      post<{ entity_external_id: string }>(`/entities/network-analysis/queue`, {
        entity_external_id: payload,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: NETWORK_ANALYSIS_QUERY_KEYS.getNetworkAnalysisStatusKey(
          entityId,
          entityExternalId,
        ),
      });
      dispatch(addQueuedRequest(entityExternalId));
    },
    onError: () => {
      dispatch(
        sendErrorToast('Failed to queue network analysis. Please try again.'),
      );
    },
  });
};
