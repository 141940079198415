import {
  LinkedEntity,
  TransactionLinkTableRow,
} from 'app/modules/networkAnalysis/types';
import { U21TableColumn } from 'app/shared/u21-ui/components';
import pluralize from 'pluralize';

export const LINKED_ENTITIES_COLUMNS: U21TableColumn<
  LinkedEntity | TransactionLinkTableRow,
  any
>[] = [
  { id: 'id', Header: 'Unit21 ID', accessor: 'id' },
  { id: 'name', Header: 'Name', accessor: 'display_name' },
  { id: 'type', Header: 'Type', accessor: 'type' },
  { id: 'subtype', Header: 'Subtype', accessor: 'internal_entity_type' },
  { id: 'status', Header: 'Status', accessor: 'status' },
  {
    id: 'link count',
    Header: 'Link count',
    accessor: (row) => pluralize('Link', row.links.length, true),
    cellProps: (row) => ({
      color: row.links.length > 3 ? 'error' : 'warning',
    }),
  },
];
