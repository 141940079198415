const NETWORK_ANALYSIS_BASE_QUERY_KEY = ['networkAnalysis'];

export const NETWORK_ANALYSIS_QUERY_KEYS = {
  getUseNetworkAnalysisKey: (
    entityId: string,
    entityExternalId: string,
    deltaLakeEnabled: boolean,
  ) =>
    [
      ...NETWORK_ANALYSIS_BASE_QUERY_KEY,
      'networkAnalysis',
      entityId,
      entityExternalId,
      deltaLakeEnabled,
    ] as const,
  getNetworkAnalysisStatusKey: (entityId: string, entityExternalId: string) =>
    [
      ...NETWORK_ANALYSIS_BASE_QUERY_KEY,
      'status',
      entityId,
      entityExternalId,
    ] as const,
};
