import React, { Component } from 'react';

// Models
import { CommentModel } from 'app/modules/comments/models';

// Components
import CommentSection from 'app/modules/comments/components/CommentSection';
import PaginatedAuditTrailSection from 'app/shared/auditTrail/components/PaginatedAuditTrailSection';

// Utils
import emptyFn from 'app/shared/utils/empty-fn';

// Styles
import styles from 'app/modules/entities/styles/TagsAndComments.module.scss';

// Selectors
import { EditAssociatedDataLabelsButton } from 'app/modules/dataLabels/EditAssociatedDataLabelsButton';
import styled from 'styled-components';
import { U21Typography } from 'app/shared/u21-ui/components';
import { DataLabelList } from 'app/modules/dataLabels/DataLabelList';

export interface EditActionPayload {
  id: number | string;
  tags?: (number | string)[];
  comments?: string;
}

interface AllProps {
  selectedTags: (number | string)[];
  id: number | string;
  objectDisplayName: string;
  hasEditPermissions: boolean;
  editObject: (payload: EditActionPayload) => void;
  loading: boolean;
  externalId: string;
  objectType: 'ENTITY' | 'DEVICE';
  comments?: CommentModel[];
  ignoreComments?: boolean;
  ignoreTags?: boolean;
}

const selectedTagsKey = 'selectedTags';

interface AllState {
  editEntityTimeout: NodeJS.Timeout;
  isEditing: boolean;
  // Used to check which loader to show, top for tags, bottom for comments
  fieldsChanged: typeof selectedTagsKey | 'comments' | '';
}

class TagsAndComments extends Component<AllProps, AllState> {
  constructor(props: AllProps) {
    super(props);
    this.state = {
      editEntityTimeout: setTimeout(emptyFn, 0),
      isEditing: false,
      fieldsChanged: '',
    };
  }

  componentWillUnmount() {
    const { editEntityTimeout } = this.state;
    if (editEntityTimeout) {
      clearTimeout(editEntityTimeout);
    }
  }

  handleCommentsSubmit = (comments: string) => {
    const { id, editObject } = this.props;
    this.setState({ fieldsChanged: 'comments' }, () => {
      const editCommentPayload = {
        id,
        comments,
      };
      editObject(editCommentPayload);
    });
  };

  render() {
    const {
      hasEditPermissions,
      objectType,
      id,
      externalId,
      objectDisplayName,
      selectedTags: propsSelectedTags,
      editObject,
      loading,
      comments,
      ignoreComments,
      ignoreTags,
    } = this.props;
    const { isEditing, fieldsChanged } = this.state;
    return (
      <div className={styles.container}>
        {!ignoreTags && (
          <div className={styles.section}>
            <StyledDiv className={styles.topTitle}>
              <U21Typography variant="body2">Tags</U21Typography>
              {hasEditPermissions ? (
                <EditAssociatedDataLabelsButton
                  objectDisplayName={objectDisplayName}
                  tagIds={propsSelectedTags}
                  isEditLoading={isEditing}
                  editObjectTags={async (tagIds: (string | number)[]) => {
                    editObject({ id, tags: tagIds });
                  }}
                  objectType={objectType}
                  objectExternalId={externalId}
                />
              ) : null}
            </StyledDiv>
            <DataLabelList tagIds={propsSelectedTags} />
          </div>
        )}

        {!ignoreComments && (
          <>
            <div className={styles.topTitle}>Comments</div>
            <CommentSection
              handleSubmit={this.handleCommentsSubmit}
              ownPropsLoading={fieldsChanged === 'comments' && loading}
              disabled={!hasEditPermissions}
              ownPropsComments={comments}
            />
          </>
        )}

        <div className={styles.section}>
          {objectType === 'ENTITY' && (
            <PaginatedAuditTrailSection entityIDs={[id]} />
          )}
        </div>
      </div>
    );
  }
}

export default TagsAndComments;

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 3px;
`;
