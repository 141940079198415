import {
  RESERVED_PRIMARY_OBJECT_INTERNAL_NAME,
  TABLE_TO_READABLE_NAME,
} from 'app/modules/dataMapping/constants';

import styled from 'styled-components';

import { ObjectChip } from 'app/modules/pullBasedDataFiles/components/ObjectChip';
import { ErrorSection } from 'app/modules/pullBasedDataFiles/components/sidebars/ErrorSection';
import { AnnotationTransformationExceptionMap } from 'app/modules/pullBasedDataFiles/responses';
import {
  U21Chip,
  U21Subsection,
  U21Spacer,
  U21Typography,
} from 'app/shared/u21-ui/components';

interface Props {
  name: string;
  errorObject: AnnotationTransformationExceptionMap;
}

export const ObjectErrorsSection = ({ name, errorObject }: Props) => {
  const { object_type: type, count, ...errorMap } = errorObject;

  return (
    <U21Subsection
      collapsible
      title={
        <U21Spacer horizontal align="center">
          <StyledU21Typography variant="subtitle1">
            {name === RESERVED_PRIMARY_OBJECT_INTERNAL_NAME
              ? TABLE_TO_READABLE_NAME[type]
              : name}{' '}
          </StyledU21Typography>
          <ObjectChip $objectType={type}>
            {TABLE_TO_READABLE_NAME[type]}
          </ObjectChip>
          <StyledU21Chip variant="ghost">{count} errors</StyledU21Chip>
        </U21Spacer>
      }
    >
      {Object.values(errorMap).map((e) => (
        <ErrorSection
          error={e}
          key={e.type}
          objectType={errorObject.object_type}
        />
      ))}
    </U21Subsection>
  );
};

const StyledU21Typography = styled(U21Typography)`
  text-transform: none;
`;

const StyledU21Chip = styled(U21Chip)`
  text-transform: none;
`;
