import { EntityRegionEntry } from 'app/modules/fincenSar/types/responses';

import {
  COUNTRY_CODES_TO_COUNTRY_NAMES,
  COUNTRY_CODES_TO_REGIONS,
} from 'app/modules/fincenSar/constants/entityRegion';

/**
 * Convert name like Leroy Jenkins --> LJ
 */
export const nameToInitials = (name: string): string => {
  if (!name) {
    return name;
  }

  return name
    .trim()
    .split(/\s+/)
    .reduce((prev, curr) => `${prev}${curr[0].toUpperCase()}`, '');
};

/**
 * Take id, full name, and email
 * and return what the displayed
 * text and tooltip should be.
 */
export const chipTextDisplayTransform = (
  id: number,
  fullName: string | undefined,
  email: string,
): { tooltipText: string; displayText: string } => {
  if (id === -1) {
    // Unassigned case
    return {
      tooltipText: '',
      displayText: 'Unassigned',
    };
  }

  if (!fullName) {
    // Sar is assigned but agent doesn't have
    // full name configured in their settings

    return {
      tooltipText: email,
      displayText: email,
    };
  }

  // Base or expected case where sar has assigned
  // user and we show their initials
  return {
    tooltipText: fullName,
    displayText: nameToInitials(fullName),
  };
};

/**
 * Given an EntityRegionEntry
 * Transform it into a text string that represents
 * the country and region/state selected
 * in the format: ""
 */
export const entityRegionToText = (entry: EntityRegionEntry): string => {
  const { country: rawCountryCode, region: rawRegionCode, city } = entry;

  return [
    city,
    COUNTRY_CODES_TO_REGIONS[rawCountryCode]?.[rawRegionCode],
    COUNTRY_CODES_TO_COUNTRY_NAMES[rawCountryCode],
  ]
    .filter(Boolean)
    .join(', ');
};
