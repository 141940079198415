import {
  DarkWebAlert,
  FullAlertResponse,
} from 'app/modules/alerts/types/responses';
import { AlertType } from 'app/modules/alerts/types/alert';

export const isDarkWebAlert = (
  alert: FullAlertResponse,
): alert is DarkWebAlert => {
  return alert.alert_type === AlertType.DARK_WEB;
};
