import { useSelector } from 'react-redux';

// Components
import { TabLabel } from 'app/modules/investigations/components/InvestigationTabs/TabLabel';

// Selectors
import { selectAlert } from 'app/modules/alerts/selectors';

export const DocumentsLabel = () => {
  const { attachments } = useSelector(selectAlert);

  const plural = (n: number) => (n !== 1 ? 's' : '');

  const tooltip = attachments.length
    ? `This alert has ${attachments.length} document${plural(
        attachments.length,
      )}`
    : undefined;

  return (
    <TabLabel label="Documents" badge={attachments.length} tooltip={tooltip} />
  );
};
