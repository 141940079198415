import { U21Grid, U21NoData } from 'app/shared/u21-ui/components';

// Components
import { EntityToAggregateCard } from 'app/modules/txnAnalysis/components/cards/EntityToAggregateCard';

// Models
import { TxnStatsFormattedResponse } from 'app/modules/txnAnalysis/queries/useGetTxnAnalysisStats';

interface TxnAnalysisStatsCardsProps {
  data?: TxnStatsFormattedResponse;
  isLoading: boolean;
}

export const TxnAnalysisStatsCard = ({
  data,
  isLoading,
}: TxnAnalysisStatsCardsProps) => {
  if (!data) {
    return <U21NoData />;
  }

  return (
    <U21Grid columns={2} spacing={2}>
      <EntityToAggregateCard
        title="Total Received"
        data={data.txnAnalysisTotalReceivedData}
        isLoading={isLoading}
      />
      <EntityToAggregateCard
        title="Total Sent"
        data={data.txnAnalysisTotalSentData}
        isLoading={isLoading}
      />
    </U21Grid>
  );
};
