import styled from 'styled-components';

import { IconPin, IconPinFilled } from '@u21/tabler-icons';
import { U21Button } from 'app/shared/u21-ui/components';

interface Props {
  onPinChange?: () => void;
  pinned: boolean;
}

export const FilterPinButton = (props: Props) => {
  const { onPinChange, pinned } = props;
  if (!onPinChange) {
    return null;
  }
  return (
    <U21Button
      aria-label="pin"
      icon={pinned ? <StyledIconPinFilled /> : <IconPin />}
      onClick={(e) => {
        e.stopPropagation();
        onPinChange();
      }}
    />
  );
};

const StyledIconPinFilled = styled(IconPinFilled)`
  color: ${(props) => props.theme.palette.primary.main};
`;
