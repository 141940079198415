import HEAP_EVENTS from 'app/shared/utils/heapEvents';

import { heapTrack } from 'app/shared/utils/heap';
import { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

export const usePageTimeTracking = () => {
  const { action } = useHistory();
  const { pathname } = useLocation();
  const mountedRef = useRef(false);
  const startTimeRef = useRef(Date.now());
  const pathnameRef = useRef('');
  const visitHashRef = useRef<string>(uuidv4());

  const actionRef = useRef(action);
  actionRef.current = action;

  // leave browser
  useEffect(() => {
    const onBlur = () => {
      heapTrack(HEAP_EVENTS.timeTracking, {
        action: 'BLUR',
        pathname: pathnameRef.current,
        time: Date.now() - startTimeRef.current,
        visitHash: visitHashRef.current,
      });
    };

    const onFocus = () => {
      // start timer again when entering window
      startTimeRef.current = Date.now();
    };

    window.addEventListener('blur', onBlur);
    window.addEventListener('focus', onFocus);
    return () => {
      window.removeEventListener('blur', onBlur);
      window.removeEventListener('focus', onFocus);
    };
  }, []);

  // close browser
  useEffect(() => {
    const onExit = () => {
      heapTrack(HEAP_EVENTS.timeTracking, {
        action: 'EXIT',
        pathname: pathnameRef.current,
        time: Date.now() - startTimeRef.current,
        visitHash: visitHashRef.current,
      });
      // reset time just in case user doesn't leave from browser warning prompt
      startTimeRef.current = Date.now();
    };
    window.addEventListener('beforeunload', onExit);
    return () => {
      window.removeEventListener('beforeunload', onExit);
    };
  }, []);

  // change page
  useEffect(() => {
    // ignore initial mount
    if (mountedRef.current) {
      heapTrack(HEAP_EVENTS.timeTracking, {
        action: actionRef.current, // PUSH, POP, REPLACE
        pathname: pathnameRef.current,
        time: Date.now() - startTimeRef.current,
        visitHash: visitHashRef.current,
      });

      // reset for next page
      startTimeRef.current = Date.now();
      visitHashRef.current = uuidv4();
    } else {
      mountedRef.current = true;
    }
    pathnameRef.current = pathname;
  }, [pathname]);
};
