import { EditAttachmentPayloadWithID } from 'app/modules/attachmentsRefresh/models';
import { FullAttachmentResponse } from 'app/modules/attachments/types';

import { EDIT_FILE_SCHEMA } from 'app/modules/attachmentsRefresh/schema';

import {
  createFormInitialValues,
  generateFormFieldProps,
} from 'app/shared/utils/form';
import { editAttachmentThunk } from 'app/modules/attachmentsRefresh/sliceAttachments';
import { parseOutFilename } from 'app/modules/attachmentsRefresh/utils';
import { selectLoadingEditAttachments } from 'app/modules/attachmentsRefresh/selectors';
import { useAppDispatch } from 'app/store/storeHooks';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

// Components
import { DocumentDisplay } from 'app/modules/attachmentsRefresh/components/DocumentDisplay';
import {
  U21Button,
  U21Chip,
  U21FormField,
  U21FormLabel,
  U21ModalBase,
  U21ModalBody,
  U21ModalFooter,
  U21ModalHeader,
  U21Form,
  U21Spacer,
} from 'app/shared/u21-ui/components';

export interface Props {
  file?: FullAttachmentResponse;
  id: number | string;
  onClose: () => void;
  onExited: () => void;
  onSuccess?: (payload: EditAttachmentPayloadWithID) => void;
  open: boolean;
  readOnly?: boolean;
}

const FORM_ID = 'edit-document-form';

export const EditDocumentModal = (props: Props) => {
  const { file, id, onClose, onExited, onSuccess, open, readOnly } = props;

  const dispatch = useAppDispatch();
  const editDocumentLoading = useSelector(selectLoadingEditAttachments);

  const [, extension] = parseOutFilename(file?.name || '');

  const initialValues = useMemo(
    () =>
      createFormInitialValues<{ name: string; description: string }>(
        EDIT_FILE_SCHEMA,
        file || {},
      ),
    [file],
  );

  return (
    <StyledU21ModalBase open={open} onExited={onExited} size="fullscreen">
      <U21ModalHeader onClose={onClose}>View Document</U21ModalHeader>
      <StyledU21ModalBody>
        <ContentGrid>
          <DocumentDisplay file={file} />
          <U21Form
            id={FORM_ID}
            disabled={readOnly}
            initialValues={initialValues}
            onSubmit={async (model) => {
              if (file?.id) {
                try {
                  const payload: EditAttachmentPayloadWithID = {
                    ...model,
                    id: file.id,
                    object_id: id,
                    name: `${model.name}${extension}`,
                  };
                  await dispatch(editAttachmentThunk(payload)).unwrap();
                  onSuccess?.(payload);
                  onClose();
                } catch {}
              }
            }}
          >
            <U21Spacer align="start" spacing={2}>
              {EDIT_FILE_SCHEMA.map((i) => (
                <StyledU21FormField
                  key={i.name}
                  {...generateFormFieldProps(i)}
                />
              ))}
              <div>
                <U21FormLabel>File type</U21FormLabel>
                <U21Chip>{file?.mime_type ?? 'No file type founded'}</U21Chip>
              </div>
            </U21Spacer>
          </U21Form>
        </ContentGrid>
      </StyledU21ModalBody>
      <U21ModalFooter>
        <U21Button onClick={onClose}>Cancel</U21Button>
        {!readOnly && (
          <U21Button
            color="primary"
            form={FORM_ID}
            loading={editDocumentLoading}
            type="submit"
            variant="contained"
          >
            Save
          </U21Button>
        )}
      </U21ModalFooter>
    </StyledU21ModalBase>
  );
};

const StyledU21ModalBase = styled(U21ModalBase)`
  .MuiDialog-paper {
    height: 100%;
  }
`;

const StyledU21ModalBody = styled(U21ModalBody)`
  height: 100%;
  overflow: hidden;
`;

const ContentGrid = styled.div`
  display: grid;
  gap: 16px;
  height: 100%;
  grid-template-columns: calc(70% - 16px) 30%;
  overflow: hidden;
`;

const StyledU21FormField = styled(U21FormField)`
  width: 100%;
`;
