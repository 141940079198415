import { QueueDetailsResponse } from 'app/modules/queues/responses';
import { QueueType } from 'app/modules/queues/models';

import { useGetAllQueues } from 'app/modules/queues/queries/useGetAllQueues';

export const useGetDefaultQueue = (
  queueType: QueueType,
): QueueDetailsResponse | undefined => {
  const { data } = useGetAllQueues(queueType, {
    select: ({ queues }) => queues.find((queue) => queue.org_default_queue),
  });
  return data;
};
