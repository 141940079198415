import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

// Thunks
import {
  readNotificationThunk,
  retrieveNotificationsThunk,
} from 'app/modules/notifications/sliceNotifications';

// Components
import { U21Table, U21TableState } from 'app/shared/u21-ui/components';

// Selectors
import { selectAgent } from 'app/modules/session/selectors';
import {
  selectLoadingNotifications,
  selectNotifications,
  selectNotificationsCount,
} from 'app/modules/notifications/selectors';

// Columns
import {
  NOTIFICATIONS_COLUMNS,
  NOTIFICATIONS_STATUS,
} from 'app/modules/notifications/columns';

// Models
import { NotificationResponse } from 'app/modules/notifications/types/responses';
import { NotificationStatus } from 'app/modules/notifications/models';

// Utils
import { handleRoutingOnEntityType } from 'app/modules/notifications/utils';
import { useMemo } from 'react';

interface OwnProps {
  unreadOnly?: boolean;
}

interface TableFilters {
  unreadOnly: boolean;
}

export const NotificationsTable = ({ unreadOnly = false }: OwnProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const agent = useSelector(selectAgent);
  const notifications = useSelector(selectNotifications);
  const count = useSelector(selectNotificationsCount);
  const loadingNotifications = useSelector(selectLoadingNotifications);

  const filters: TableFilters = useMemo(
    () => ({
      unreadOnly,
    }),
    [unreadOnly],
  );

  const onStateChange = (state: U21TableState, filterState: TableFilters) => {
    dispatch(
      retrieveNotificationsThunk({
        agent_id: agent.id,
        limit: state.pageSize,
        offset: state.page,
        unread_only: filterState.unreadOnly,
      }),
    );
  };

  const onRowClick = (id: number | string, row: NotificationResponse) => {
    const { entity_type: entityType, entity_id: entityId, status } = row;
    handleRoutingOnEntityType(entityType, entityId, history.push);

    if (status === NotificationStatus.UNREAD) {
      dispatch(
        readNotificationThunk({
          agent_id: agent.id,
          notification_id: Number(id),
        }),
      );
    }
  };

  return (
    <U21Table
      columns={NOTIFICATIONS_COLUMNS}
      count={count}
      data={notifications}
      filters={filters}
      onStateChange={onStateChange}
      onRefresh={onStateChange}
      loading={loadingNotifications}
      defaultColumnPin={{
        [NOTIFICATIONS_STATUS.id]: 'RIGHT',
      }}
      manualPagination
      onRowClick={onRowClick}
    />
  );
};
