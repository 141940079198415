import {
  NetworkAnalysisStatusResponse,
  usePollNetworkAnalysisStatus,
} from 'app/modules/networkAnalysis/queries/usePollNetworkAnalysisStatus';
import {
  U21Chip,
  U21Loading,
  U21Progress,
  U21Spacer,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { formatDatetime } from 'app/shared/utils/date';

const READABLE_STATUS: Record<NetworkAnalysisStatusResponse['status'], string> =
  {
    ERROR: 'Failed',
    PENDING: 'Queued',
    STARTED: 'In progress',
    COMPLETED: 'Completed',
  };

const STATUS_COLOR: Record<
  NetworkAnalysisStatusResponse['status'],
  'error' | 'info' | 'success'
> = {
  ERROR: 'error',
  PENDING: 'info',
  STARTED: 'info',
  COMPLETED: 'success',
};

export const NetworkAnalysisJobState = ({
  entityId,
  entityExternalId,
}: {
  entityId: string;
  entityExternalId: string;
}) => {
  const { data } = usePollNetworkAnalysisStatus(entityId, entityExternalId);
  if (!data) {
    return null;
  }
  return (
    <U21Spacer spacing={2}>
      <U21Spacer horizontal align="center">
        <U21Chip color={STATUS_COLOR[data.status]}>
          {READABLE_STATUS[data.status]}
        </U21Chip>
        <U21Typography>
          as of{' '}
          {formatDatetime(
            new Date(
              data.status === 'PENDING' ? data.created_at : data.updated_at,
            ),
          )}
        </U21Typography>
      </U21Spacer>
      {data.status === 'COMPLETED' || data.status === 'ERROR' ? (
        <U21Progress
          color={STATUS_COLOR[data.status]}
          value={data.status === 'COMPLETED' ? 100 : 25}
        />
      ) : (
        <U21Loading
          color={STATUS_COLOR[data.status]}
          delay={0}
          loading
          variant="linear"
        />
      )}
    </U21Spacer>
  );
};
