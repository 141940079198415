import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { post } from 'app/shared/utils/fetchr';
import { sendErrorToast } from 'app/shared/toasts/actions';
import { CreateAiMessagePayload } from 'app/modules/codeInterpreter/requests';
import { CreateAiMessageResponse } from 'app/modules/codeInterpreter/responses';
import { AI_CHAT_KEYS } from 'app/modules/codeInterpreter/queries/keys';

const createAiMessage = (
  sessionId: number,
  payload: CreateAiMessagePayload,
): Promise<CreateAiMessageResponse> =>
  post<CreateAiMessageResponse>(
    `/code-interpreter-session/${sessionId}/message`,
    payload,
  );

export const useCreateAiMessage = (sessionId: number) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: CreateAiMessagePayload) =>
      createAiMessage(sessionId, payload),
    onError: () =>
      dispatch(sendErrorToast('Unable to send message to AI assistant')),
    onSuccess: (response: CreateAiMessageResponse) => {
      queryClient.invalidateQueries({
        queryKey: AI_CHAT_KEYS.getAiChatSessionQueryKey(response.session_id),
      });
      queryClient.invalidateQueries({
        queryKey: AI_CHAT_KEYS.getAllAiChatSessionQueryKey(),
      });
    },
  });
};
