export const FEATURES_TO_ROLL = 5;

export const FEATURE_DISPLAY_NAMES = {
  MAX_TXN_ROW_NUMBER: 'Max transaction row number',
  AVG_ALERT_HIT_TXN_AMOUNT: 'Avg alert hit transaction amount',
  MIN_ALERT_HIT_TXN_AMOUNT: 'Min alert hit transaction amount',
  MAX_ALERT_HIT_TXN_AMOUNT: 'Max alert hit transaction amount',
  SUM_ALERT_HIT_TXN_AMOUNT: 'Sum alert hit transaction amount',
  STDDEV_ALERT_HIT_TXN_AMOUNT:
    'Standard deviation alert hit transaction amount',
  MEDIAN_ALERT_HIT_TXN_AMOUNT: 'Median alert hit transaction amount',
  KURTOSIS_ALERT_HIT_TXN_AMOUNT: 'Kurtosis alert hit transaction amount',
  ALERT_HIT_TXN_AMOUNT_TOTAL: 'Alert hit transaction amount total',
  AVG_ALERT_HIT_TXN_SENT_AMOUNT: 'Avg alert hit transaction sent amount',
  MIN_ALERT_HIT_TXN_SENT_AMOUNT: 'Min alert hit transaction sent amount',
  MAX_ALERT_HIT_TXN_SENT_AMOUNT: 'Max alert hit transaction sent amount',
  SUM_ALERT_HIT_TXN_SENT_AMOUNT: 'Sum alert hit transaction sent amount',
  AVG_ALERT_HIT_TXN_RECEIVED_AMOUNT:
    'Avg alert hit transaction received amount',
  MIN_ALERT_HIT_TXN_RECEIVED_AMOUNT:
    'Min alert hit transaction received amount',
  MAX_ALERT_HIT_TXN_RECEIVED_AMOUNT:
    'Max alert hit transaction received amount',
  SUM_ALERT_HIT_TXN_RECEIVED_AMOUNT:
    'Sum alert hit transaction received amount',
  AVG_ALERT_HIT_TXN_INTERNAL_FEE: 'Avg alert hit transaction internal fee',
  MIN_ALERT_HIT_TXN_INTERNAL_FEE: 'Min alert hit transaction internal fee',
  MAX_ALERT_HIT_TXN_INTERNAL_FEE: 'Max alert hit transaction internal fee',
  SUM_ALERT_HIT_TXN_INTERNAL_FEE: 'Sum alert hit transaction internal fee',
  AVG_ALERT_HIT_TXN_EXTERNAL_FEE: 'Avg alert hit transaction external fee',
  MIN_ALERT_HIT_TXN_EXTERNAL_FEE: 'Min alert hit transaction external fee',
  MAX_ALERT_HIT_TXN_EXTERNAL_FEE: 'Max alert hit transaction external fee',
  SUM_ALERT_HIT_TXN_EXTERNAL_FEE: 'Sum alert hit transaction external fee',
  SUM_EVENT_TYPE_ACTION: 'Sum event type action',
  SUM_EVENT_TYPE_TXN: 'Sum event type transaction',
  DISTINCT_EVENT: 'Distinct event',
  DISTINCT_EVENT_QA: 'Distinct event qa',
  DISTINCT_DEVICE: 'Distinct device',
  DISTINCT_SENDER_ENTITY_ID: 'Distinct sender entity id',
  DISTINCT_RECEIVER_ENTITY_ID: 'Distinct receiver entity id',
  DISTINCT_ENTITY_ID: 'Distinct entity id',
  TOTAL_ALERT_HITS: 'Total alert hits',
  ALERT_HIT_MOST_RECENT_TXN_AMOUNT: 'Alert hit most recent transaction amount',
  ALERT_HIT_TYPE: 'Alert hit type',
  ALERT_STATUS: 'Alert status',
  ALERT_DISPOSITION: 'Alert disposition',
  DISTINCT_ENTITY_CNT: 'Distinct entity count',
  DISTINCT_ENTITY_STATUS_CNT: 'Distinct entity status count',
  DISTINCT_ENTITY_TYPE_CNT: 'Distinct entity type count',
  MIN_ENTITY_REGISTERED_AT: 'Min entity registered at',
  MAX_ENTITY_REGISTERED_AT: 'Max entity registered at',
  DISTINCT_ENTITY_ADDRESS_CNT: 'Distinct entity address count',
  DISTINCT_ENTITY_STREET_CNT: 'Distinct entity street count',
  DISTINCT_ENTITY_CITY_CNT: 'Distinct entity city count',
  DISTINCT_ENTITY_STATE_CNT: 'Distinct entity state count',
  DISTINCT_ENTITY_ZIP_CODE_CNT: 'Distinct entity zip code count',
  DISTINCT_ENTITY_COUNTRY_CODE_CNT: 'Distinct entity country code count',
  DISTINCT_DEVICE_TYPE_CNT: 'Distinct device type count',
  DISTINCT_DEVICE_STATUS_CNT: 'Distinct device status count',
  DISTINCT_DEVICE_OS_NAME_CNT: 'Distinct device os name count',
  DISTINCT_DEVICE_MANUFACTURER_CNT: 'Distinct device manufacturer count',
  DISTINCT_DEVICE_NETWORK_CARRIER_CNT: 'Distinct device network carrier count',
  DISTINCT_DEVICES_CNT: 'Distinct devices count',
  DISTINCT_EVENT_IP_ADDRESS_CNT: 'Distinct event IP address count',
  DISTINCT_ENTITY_EMAIL_CNT: 'Distinct entity email count',
  DISTINCT_ENTITY_TRIMMED_EMAIL_CNT: 'Distinct entity trimmed email count',
  DISTINCT_ENTITY_DEDUPED_EMAIL_1_CNT: 'Distinct entity deduped email 1 count',
  DISTINCT_ENTITY_DEDUPED_EMAIL_2_CNT: 'Distinct entity deduped email 2 count',
  MIN_ENTITY_SPECIAL_CHARS_COUNT_1: 'Min entity special chars count 1',
  MAX_ENTITY_SPECIAL_CHARS_COUNT_1: 'Max entity special chars count 1',
  MIN_ENTITY_SPECIAL_CHARS_COUNT_2: 'Min entity special chars count 2',
  MAX_ENTITY_SPECIAL_CHARS_COUNT_2: 'Max entity special chars count 2',
  MIN_ENTITY_EMAIL_CREATED_AT_DIFF_IN_SECONDS:
    'Min entity email created at diff in seconds',
  MAX_ENTITY_EMAIL_CREATED_AT_DIFF_IN_SECONDS:
    'Max entity email created at diff in seconds',
  TOTAL_ENTITY_WITH_SIMILAR_EMAIL_1: 'Total entity with similar email 1',
  TOTAL_ENTITY_WITH_SIMILAR_EMAIL_2: 'Total entity with similar email 2',
  DISTINCT_SENDER_EMAIL_CNT: 'Distinct sender email count',
  DISTINCT_SENDER_TRIMMED_EMAIL_CNT: 'Distinct sender trimmed email count',
  DISTINCT_SENDER_DEDUPED_EMAIL_1_CNT: 'Distinct sender deduped email 1 count',
  DISTINCT_SENDER_DEDUPED_EMAIL_2_CNT: 'Distinct sender deduped email 2 count',
  MIN_SENDER_SPECIAL_CHARS_COUNT_1: 'Min sender special chars count 1',
  MAX_SENDER_SPECIAL_CHARS_COUNT_1: 'Max sender special chars count 1',
  MIN_SENDER_SPECIAL_CHARS_COUNT_2: 'Min sender special chars count 2',
  MAX_SENDER_SPECIAL_CHARS_COUNT_2: 'Max sender special chars count 2',
  MIN_SENDER_EMAIL_CREATED_AT_DIFF_IN_SECONDS:
    'Min sender email created at diff in seconds',
  MAX_SENDER_EMAIL_CREATED_AT_DIFF_IN_SECONDS:
    'Max sender email created at diff in seconds',
  TOTAL_SENDER_WITH_SIMILAR_EMAIL_1: 'Total sender with similar email 1',
  TOTAL_SENDER_WITH_SIMILAR_EMAIL_2: 'Total sender with similar email 2',
  DISTINCT_RECEIVER_EMAIL_CNT: 'Distinct receiver email count',
  DISTINCT_RECEIVER_TRIMMED_EMAIL_CNT: 'Distinct receiver trimmed email count',
  DISTINCT_RECEIVER_DEDUPED_EMAIL_1_CNT:
    'Distinct receiver deduped email 1 count',
  DISTINCT_RECEIVER_DEDUPED_EMAIL_2_CNT:
    'Distinct receiver deduped email 2 count',
  MIN_RECEIVER_SPECIAL_CHARS_COUNT_1: 'Min receiver special chars count 1',
  MAX_RECEIVER_SPECIAL_CHARS_COUNT_1: 'Max receiver special chars count 1',
  MIN_RECEIVER_SPECIAL_CHARS_COUNT_2: 'Min receiver special chars count 2',
  MAX_RECEIVER_SPECIAL_CHARS_COUNT_2: 'Max receiver special chars count 2',
  MIN_RECEIVER_EMAIL_CREATED_AT_DIFF_IN_SECONDS:
    'Min receiver email created at diff in seconds',
  MAX_RECEIVER_EMAIL_CREATED_AT_DIFF_IN_SECONDS:
    'Max receiver email created at diff in seconds',
  TOTAL_RECEIVER_WITH_SIMILAR_EMAIL_1: 'Total receiver with similar email 1',
  TOTAL_RECEIVER_WITH_SIMILAR_EMAIL_2: 'Total receiver with similar email 2',
  LAST_SENDER_INSTRUMENT_NAME: 'Last sender instrument name',
  LAST_SENDER_INSTRUMENT_SOURCE: 'Last sender instrument source',
  LAST_SENDER_INSTRUMENT_GATEWAY: 'Last sender instrument gateway',
  LAST_SENDER_INSTRUMENT_TYPE: 'Last sender instrument type',
  LAST_SENDER_INSTRUMENT_SUBTYPE: 'Last sender instrument subtype',
  LAST_SENDER_INSTRUMENT_STATUS: 'Last sender instrument status',
  LAST_SENDER_INSTRUMENT_CUSTOM: 'Last sender instrument custom',
  LAST_RECEIVER_INSTRUMENT_NAME: 'Last receiver instrument name',
  LAST_RECEIVER_INSTRUMENT_SOURCE: 'Last receiver instrument source',
  LAST_RECEIVER_INSTRUMENT_GATEWAY: 'Last receiver instrument gateway',
  LAST_RECEIVER_INSTRUMENT_TYPE: 'Last receiver instrument type',
  LAST_RECEIVER_INSTRUMENT_SUBTYPE: 'Last receiver instrument subtype',
  LAST_RECEIVER_INSTRUMENT_STATUS: 'Last receiver instrument status',
  LAST_RECEIVER_INSTRUMENT_CUSTOM: 'Last receiver instrument custom',
  ENTITY_REGISTERED_AT_DIFF_IN_DAYS: 'Entity registered at diff in days',
  TRANSACTION_SPAN_IN_HOURS: 'Transaction span in hours',
  TRANSACTION_SPAN_IN_DAYS: 'Transaction span in days',
  MAX_ACCOUNT_TENURE_IN_DAYS: 'Max account tenure in days',
  MIN_ACCOUNT_TENURE_IN_DAYS: 'Min account tenure in days',
  AVG_EVENTS_PER_HOUR: 'Avg events per hour',
  AVG_EVENTS_PER_DAY: 'Avg events per day',
  AVG_DISTINCT_EVENT_IP_ADDRESS_PER_HOUR:
    'Avg distinct event IP address per hour',
  AVG_DISTINCT_EVENT_IP_ADDRESS_PER_DAY:
    'Avg distinct event IP address per day',
  OTHER_FEATURES: 'Other variables',
};

export const FEATURE_DESCRIPTION = {
  MAX_TXN_ROW_NUMBER:
    'Count of transaction events associated with this Alert Hit',
  AVG_ALERT_HIT_TXN_AMOUNT:
    'Average transaction amount across all transactions for this Alert Hit',
  MIN_ALERT_HIT_TXN_AMOUNT:
    'Minimum transaction amount across all transactions for this Alert Hit',
  MAX_ALERT_HIT_TXN_AMOUNT:
    'Maximum transaction amount across all transactions for this Alert Hit',
  SUM_ALERT_HIT_TXN_AMOUNT:
    'Sum of transaction amounts across all transactions for this Alert Hit',
  STDDEV_ALERT_HIT_TXN_AMOUNT:
    'Standard deviation of transaction amounts across all transactions for this Alert Hit',
  MEDIAN_ALERT_HIT_TXN_AMOUNT:
    'Median transaction amount across all transactions for this Alert Hit',
  KURTOSIS_ALERT_HIT_TXN_AMOUNT:
    'Kurtosis of transaction amounts across all transactions for this Alert Hit',
  ALERT_HIT_TXN_AMOUNT_TOTAL:
    'Maximum sum of transaction amounts (between current and all prior transactions), across all transactions for this Alert Hit',
  AVG_ALERT_HIT_TXN_SENT_AMOUNT:
    'Average sent amount across all transactions for this Alert Hit',
  MIN_ALERT_HIT_TXN_SENT_AMOUNT:
    'Minimum sent amount across all transactions for this Alert Hit',
  MAX_ALERT_HIT_TXN_SENT_AMOUNT:
    'Maximum sent amount across all transactions for this Alert Hit',
  SUM_ALERT_HIT_TXN_SENT_AMOUNT:
    'Sum of sent amounts across all transactions for this Alert Hit',
  AVG_ALERT_HIT_TXN_RECEIVED_AMOUNT:
    'Average received amount across all transactions for this Alert Hit',
  MIN_ALERT_HIT_TXN_RECEIVED_AMOUNT:
    'Minimum received amount across all transactions for this Alert Hit',
  MAX_ALERT_HIT_TXN_RECEIVED_AMOUNT:
    'Maximum received amount across all transactions for this Alert Hit',
  SUM_ALERT_HIT_TXN_RECEIVED_AMOUNT:
    'Sum of received amounts across all transactions for this Alert Hit',
  AVG_ALERT_HIT_TXN_INTERNAL_FEE:
    'Average internal fee across all transactions for this Alert Hit',
  MIN_ALERT_HIT_TXN_INTERNAL_FEE:
    'Minimum internal fee across all transactions for this Alert Hit',
  MAX_ALERT_HIT_TXN_INTERNAL_FEE:
    'Maximum internal fee across all transactions for this Alert Hit',
  SUM_ALERT_HIT_TXN_INTERNAL_FEE:
    'Sum of internal fees across all transactions for this Alert Hit',
  AVG_ALERT_HIT_TXN_EXTERNAL_FEE:
    'Average external fee across all transactions for this Alert Hit',
  MIN_ALERT_HIT_TXN_EXTERNAL_FEE:
    'Minimum external fee across all transactions for this Alert Hit',
  MAX_ALERT_HIT_TXN_EXTERNAL_FEE:
    'Maximum external fee across all transactions for this Alert Hit',
  SUM_ALERT_HIT_TXN_EXTERNAL_FEE:
    'Sum of external fees across all transactions for this Alert Hit',
  SUM_EVENT_TYPE_ACTION: 'Count of all actions associated with the alert hit',
  SUM_EVENT_TYPE_TXN:
    'Count of all the transaction events associated with this alert hit',
  DISTINCT_EVENT: 'Count of events associated with this Alert Hit',
  DISTINCT_EVENT_QA: 'Count of events associated with this Alert Hit',
  DISTINCT_DEVICE:
    'Count of distinct device IDs across all transaction events for this Alert Hit',
  DISTINCT_SENDER_ENTITY_ID:
    'Count of distinct sender entity IDs across all transactions for this Alert Hit',
  DISTINCT_RECEIVER_ENTITY_ID:
    'Count of distinct receiver entity IDs across all transactions for this Alert Hit',
  DISTINCT_ENTITY_ID:
    'Count of distinct entity IDs across all events associated with this Alert Hit',
  TOTAL_ALERT_HITS:
    'Count of Alert Hits for this Alert that have occurred prior (in terms of most recent transaction event time) to the current Alert Hit',
  ALERT_HIT_MOST_RECENT_TXN_AMOUNT:
    'Most recent transaction amount for this Alert Hit',
  ALERT_HIT_TYPE: 'The type of the current Alert Hit',
  ALERT_STATUS: 'The status of the current Alert',
  ALERT_DISPOSITION: 'The disposition of the current Alert',
  DISTINCT_ENTITY_CNT:
    'Count of distinct Entity IDs across all entities associated with this Alert Hit',
  DISTINCT_ENTITY_STATUS_CNT:
    'Count of distinct Entity statuses across all entities associated with this Alert Hit',
  DISTINCT_ENTITY_TYPE_CNT:
    'Count of distinct Entity types across all entities associated with this Alert Hit',
  MIN_ENTITY_REGISTERED_AT:
    'Earliest Entity registration time across all entities associated with this Alert Hit',
  MAX_ENTITY_REGISTERED_AT:
    'Latest Entity registration time across all entities associated with this Alert Hit',
  DISTINCT_ENTITY_ADDRESS_CNT:
    'Count of distinct Address IDs across all entities associated with this Alert Hit',
  DISTINCT_ENTITY_STREET_CNT:
    'Count of distinct Address streets across all entities associated with this Alert Hit',
  DISTINCT_ENTITY_CITY_CNT:
    'Count of distinct Address cities across all entities associated with this Alert Hit',
  DISTINCT_ENTITY_STATE_CNT:
    'Count of distinct Address states across all entities associated with this Alert Hit',
  DISTINCT_ENTITY_ZIP_CODE_CNT:
    'Count of distinct Address zip codes across all entities associated with this Alert Hit',
  DISTINCT_ENTITY_COUNTRY_CODE_CNT:
    'Count of distinct Address countries across all entities associated with this Alert Hit',
  DISTINCT_DEVICE_TYPE_CNT:
    'Count of distinct Device types across all events associated with this Alert Hit',
  DISTINCT_DEVICE_STATUS_CNT:
    'Count of distinct Device statuses across all events associated with this Alert Hit',
  DISTINCT_DEVICE_OS_NAME_CNT:
    'Count of distinct Device OS names across all events associated with this Alert Hit',
  DISTINCT_DEVICE_MANUFACTURER_CNT:
    'Count of distinct Device manufacturers across all events associated with this Alert Hit',
  DISTINCT_DEVICE_NETWORK_CARRIER_CNT:
    'Count of distinct Device network carriers across all events associated with this Alert Hit',
  DISTINCT_DEVICES_CNT:
    'Count of distinct Device IDs across all events associated with this Alert Hit',
  DISTINCT_EVENT_IP_ADDRESS_CNT:
    'Count of distinct IP addresses across all events associated with this Alert Hit',
  DISTINCT_ENTITY_EMAIL_CNT:
    'Count of distinct email addresses (created prior to the last event and for the last entity) associated with this Alert Hit',
  DISTINCT_ENTITY_TRIMMED_EMAIL_CNT:
    'Count of distinct email addresses (created prior to the last event, for the last entity, and after removing whitespace characters) associated with this Alert Hit',
  DISTINCT_ENTITY_DEDUPED_EMAIL_1_CNT:
    'Count of distinct email addresses (created prior to the last event, for the last entity, and after removing ., +, and whitespace characters) associated with this Alert Hit',
  DISTINCT_ENTITY_DEDUPED_EMAIL_2_CNT:
    'Count of distinct email addresses (created prior to the last event, for the last entity, and after removing RFC 3696 special characters) associated with this Alert Hit',
  MIN_ENTITY_SPECIAL_CHARS_COUNT_1:
    'Minimum count of (., +, or whitespace) characters across all email addresses (created prior to the last event and for the last entity) associated with this Alert Hit',
  MAX_ENTITY_SPECIAL_CHARS_COUNT_1:
    'Maximum count of (., +, or whitespace) characters across all email addresses (created prior to the last event and for the last entity) associated with this Alert Hit',
  MIN_ENTITY_SPECIAL_CHARS_COUNT_2:
    'Minimum count of (RFC 3696 special) characters across all email addresses (created prior to the last event and for the last entity) associated with this Alert Hit',
  MAX_ENTITY_SPECIAL_CHARS_COUNT_2:
    'Maximum count of (RFC 3696 special) characters across all email addresses (created prior to the last event and for the last entity) associated with this Alert Hit',
  MIN_ENTITY_EMAIL_CREATED_AT_DIFF_IN_SECONDS:
    'Minimum difference in seconds between when an entity and its email address were created, across all email addresses (created prior to the last event and for the last entity) associated with this Alert Hit',
  MAX_ENTITY_EMAIL_CREATED_AT_DIFF_IN_SECONDS:
    'Maximum difference in seconds between when an entity and its email address were created, across all email addresses (created prior to the last event and for the last entity) associated with this Alert Hit',
  TOTAL_ENTITY_WITH_SIMILAR_EMAIL_1:
    'Count of email addresses matching and created prior to this email address (created prior to the last event, for the last entity, and after removing ., +, and whitespace characters) associated with this Alert Hit',
  TOTAL_ENTITY_WITH_SIMILAR_EMAIL_2:
    'Count of email addresses matching and created prior to this email address (created prior to the last event, for the last entity, and after removing RFC 3696 special characters) associated with this Alert Hit',
  DISTINCT_SENDER_EMAIL_CNT:
    'Count of distinct sender email addresses (created prior to the last event and for the last sender entity) associated with this Alert Hit',
  DISTINCT_SENDER_TRIMMED_EMAIL_CNT:
    'Count of distinct sender email addresses (created prior to the last event, for the last sender entity, and after removing whitespace characters) associated with this Alert Hit',
  DISTINCT_SENDER_DEDUPED_EMAIL_1_CNT:
    'Count of distinct sender email addresses (created prior to the last event, for the last sender entity, and after removing ., +, and whitespace characters) associated with this Alert Hit',
  DISTINCT_SENDER_DEDUPED_EMAIL_2_CNT:
    'Count of distinct sender email addresses (created prior to the last event, for the last sender entity, and after removing RFC 3696 special characters) associated with this Alert Hit',
  MIN_SENDER_SPECIAL_CHARS_COUNT_1:
    'Minimum count of (., +, or whitespace) characters across all sender email addresses (created prior to the last event and for the last sender entity) associated with this Alert Hit',
  MAX_SENDER_SPECIAL_CHARS_COUNT_1:
    'Maximum count of (., +, or whitespace) characters across all sender email addresses (created prior to the last event and for the last sender entity) associated with this Alert Hit',
  MIN_SENDER_SPECIAL_CHARS_COUNT_2:
    'Minimum count of (RFC 3696 special) characters across all sender email addresses (created prior to the last event and for the last sender entity) associated with this Alert Hit',
  MAX_SENDER_SPECIAL_CHARS_COUNT_2:
    'Maximum count of (RFC 3696 special) characters across all sender email addresses (created prior to the last event and for the last sender entity) associated with this Alert Hit',
  MIN_SENDER_EMAIL_CREATED_AT_DIFF_IN_SECONDS:
    'Minimum difference in seconds between when a sender entity and its email address were created, across all sender email addresses (created prior to the last event and for the last sender entity) associated with this Alert Hit',
  MAX_SENDER_EMAIL_CREATED_AT_DIFF_IN_SECONDS:
    'Maximum difference in seconds between when a sender entity and its email address were created, across all sender email addresses (created prior to the last event and for the last sender entity) associated with this Alert Hit',
  TOTAL_SENDER_WITH_SIMILAR_EMAIL_1:
    'Count of email addresses matching and created prior to this sender email address (created prior to the last event, for the last sender entity, and after removing ., +, and whitespace characters) associated with this Alert Hit',
  TOTAL_SENDER_WITH_SIMILAR_EMAIL_2:
    'Count of email addresses matching and created prior to this sender email address (created prior to the last event, for the last sender entity, and after removing RFC 3696 special characters) associated with this Alert Hit',
  DISTINCT_RECEIVER_EMAIL_CNT:
    'Count of distinct receiver email addresses (created prior to the last event and for the last receiver entity) associated with this Alert Hit',
  DISTINCT_RECEIVER_TRIMMED_EMAIL_CNT:
    'Count of distinct receiver email addresses (created prior to the last event, for the last receiver entity, and after removing whitespace characters) associated with this Alert Hit',
  DISTINCT_RECEIVER_DEDUPED_EMAIL_1_CNT:
    'Count of distinct receiver email addresses (created prior to the last event, for the last receiver entity, and after removing ., +, and whitespace characters) associated with this Alert Hit',
  DISTINCT_RECEIVER_DEDUPED_EMAIL_2_CNT:
    'Count of distinct receiver email addresses (created prior to the last event, for the last receiver entity, and after removing RFC 3696 special characters) associated with this Alert Hit',
  MIN_RECEIVER_SPECIAL_CHARS_COUNT_1:
    'Minimum count of (., +, or whitespace) characters across all receiver email addresses (created prior to the last event and for the last receiver entity) associated with this Alert Hit',
  MAX_RECEIVER_SPECIAL_CHARS_COUNT_1:
    'Maximum count of (., +, or whitespace) characters across all receiver email addresses (created prior to the last event and for the last receiver entity) associated with this Alert Hit',
  MIN_RECEIVER_SPECIAL_CHARS_COUNT_2:
    'Minimum count of (RFC 3696 special) characters across all receiver email addresses (created prior to the last event and for the last receiver entity) associated with this Alert Hit',
  MAX_RECEIVER_SPECIAL_CHARS_COUNT_2:
    'Maximum count of (RFC 3696 special) characters across all receiver email addresses (created prior to the last event and for the last receiver entity) associated with this Alert Hit',
  MIN_RECEIVER_EMAIL_CREATED_AT_DIFF_IN_SECONDS:
    'Minimum difference in seconds between when a receiver entity and its email address were created, across all receiver email addresses (created prior to the last event and for the last receiver entity) associated with this Alert Hit',
  MAX_RECEIVER_EMAIL_CREATED_AT_DIFF_IN_SECONDS:
    'Maximum difference in seconds between when a receiver entity and its email address were created, across all receiver email addresses (created prior to the last event and for the last receiver entity) associated with this Alert Hit',
  TOTAL_RECEIVER_WITH_SIMILAR_EMAIL_1:
    'Count of email addresses matching and created prior to this receiver email address (created prior to the last event, for the last receiver entity, and after removing ., +, and whitespace characters) associated with this Alert Hit',
  TOTAL_RECEIVER_WITH_SIMILAR_EMAIL_2:
    'Count of email addresses matching and created prior to this receiver email address (created prior to the last event, for the last receiver entity, and after removing RFC 3696 special characters) associated with this Alert Hit',
  LAST_SENDER_INSTRUMENT_NAME:
    'Instrument name of the sender entity for the last transaction event of this Alert Hit',
  LAST_SENDER_INSTRUMENT_SOURCE:
    'Instrument source of the sender entity for the last transaction event of this Alert Hit',
  LAST_SENDER_INSTRUMENT_GATEWAY:
    'Instrument gateway of the sender entity for the last transaction event of this Alert Hit',
  LAST_SENDER_INSTRUMENT_TYPE:
    'Instrument type of the sender entity for the last transaction event of this Alert Hit',
  LAST_SENDER_INSTRUMENT_SUBTYPE:
    'Instrument subtype of the sender entity for the last transaction event of this Alert Hit',
  LAST_SENDER_INSTRUMENT_STATUS:
    'Instrument status of the sender entity for the last transaction event of this Alert Hit',
  LAST_SENDER_INSTRUMENT_CUSTOM:
    'Instrument custom data of the sender entity for the last transaction event of this Alert Hit',
  LAST_RECEIVER_INSTRUMENT_NAME:
    'Instrument name of the receiver entity for the last transaction event of this Alert Hit',
  LAST_RECEIVER_INSTRUMENT_SOURCE:
    'Instrument source of the receiver entity for the last transaction event of this Alert Hit',
  LAST_RECEIVER_INSTRUMENT_GATEWAY:
    'Instrument gateway of the receiver entity for the last transaction event of this Alert Hit',
  LAST_RECEIVER_INSTRUMENT_TYPE:
    'Instrument type of the receiver entity for the last transaction event of this Alert Hit',
  LAST_RECEIVER_INSTRUMENT_SUBTYPE:
    'Instrument subtype of the receiver entity for the last transaction event of this Alert Hit',
  LAST_RECEIVER_INSTRUMENT_STATUS:
    'Instrument status of the receiver entity for the last transaction event of this Alert Hit',
  LAST_RECEIVER_INSTRUMENT_CUSTOM:
    'Instrument custom data of the receiver entity for the last transaction event of this Alert Hit',
  ENTITY_REGISTERED_AT_DIFF_IN_DAYS:
    'Difference in days between earliest and latest registration times across all entities associated with this Alert Hit',
  TRANSACTION_SPAN_IN_HOURS:
    'Difference in hours between earliest and latest event times for transactions in this Alert Hit',
  TRANSACTION_SPAN_IN_DAYS:
    'Difference in days between earliest and latest event times for transactions in this Alert Hit',
  MAX_ACCOUNT_TENURE_IN_DAYS:
    'Difference in days between oldest registered Entity and most recent event time in this Alert Hit',
  MIN_ACCOUNT_TENURE_IN_DAYS:
    'Difference in days between newest registered Entity and most recent event time in this Alert Hit',
  AVG_EVENTS_PER_HOUR:
    'Average number of distinct events per hour for this Alert Hit',
  AVG_EVENTS_PER_DAY:
    'Average number of distinct events per day for this Alert Hit',
  AVG_DISTINCT_EVENT_IP_ADDRESS_PER_HOUR:
    'Average number of distinct IP addresses per hour for this Alert Hit',
  AVG_DISTINCT_EVENT_IP_ADDRESS_PER_DAY:
    'Average number of distinct IP addresses per day for this Alert Hit',
  OTHER_FEATURES:
    'Includes the sum of all the other variables affecting the final score. Click "Show More" to see them.',
};

export const FROM_AI_COPILOT = 'from_ai_copilot';
