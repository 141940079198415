import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { IconDatabase } from '@u21/tabler-icons';

// Selectors
import { selectIsUnit21User } from 'app/modules/session/selectors';
import { selectOrgDataConfig } from 'app/modules/orgSettings/selectors';

// Components
import { U21Typography } from 'app/shared/u21-ui/components';

export const DatastoreIndicator = () => {
  const isUnit21User = useSelector(selectIsUnit21User);
  const dataConfig = useSelector(selectOrgDataConfig);

  const dataStoreIndicator = useMemo(() => {
    return dataConfig === 'DELTA_LAKE' ? 'DL' : 'PG';
  }, [dataConfig]);

  if (!isUnit21User) {
    return null;
  }

  return (
    <U21Typography icon={<IconDatabase />} variant="h6">
      {dataStoreIndicator}
    </U21Typography>
  );
};
