import { FullTxnResponse } from 'app/modules/transactions/types/responses';
import { TRANSACTION_QUERY_KEYS } from 'app/modules/transactions/queries/keys';
import { get } from 'app/shared/utils/fetchr';
import { useQuery } from '@tanstack/react-query';

export const useGetTransactionById = <TSelect = FullTxnResponse>(
  id?: string,
  select?: (data: FullTxnResponse) => TSelect,
) => {
  return useQuery({
    queryFn: () => get<FullTxnResponse>(`/txn-events/${id}`),
    queryKey: TRANSACTION_QUERY_KEYS.getTransactionById(id),
    select,
    enabled: !!id,
  });
};
