import { U21Button } from 'app/shared/u21-ui/components';
import { useState } from 'react';
import styled from 'styled-components';
import { EditDataLabelsModal } from 'app/modules/dataLabels/EditDataLabelsModal';

interface IProps {
  objectDisplayName: string;
  tagIds: (string | number)[];
  editObjectTags: (tagIds: (string | number)[]) => Promise<void>;
  isEditLoading: boolean;
  objectType: 'ENTITY' | 'INSTRUMENT' | 'TXN_EVENT' | 'DEVICE';
  objectExternalId: string;
}

export const EditAssociatedDataLabelsButton = ({
  objectDisplayName,
  tagIds,
  editObjectTags,
  isEditLoading,
  objectType,
  objectExternalId,
}: IProps) => {
  const [editDataLabelsModalOpen, setEditDataLabelsModalOpen] = useState(false);
  return (
    <>
      <U21Button
        aria-label="Edit Tags"
        size="small"
        onClick={() => {
          setEditDataLabelsModalOpen(true);
        }}
      >
        Edit Tags
      </U21Button>
      <EditDataLabelsModal
        editObjectTags={editObjectTags}
        isEditLoading={isEditLoading}
        tagIds={tagIds}
        title={<Span>Edit {objectDisplayName} Tags</Span>}
        open={editDataLabelsModalOpen}
        handleClose={() => {
          setEditDataLabelsModalOpen(false);
        }}
        objectType={objectType}
        objectExternalId={objectExternalId}
      />
    </>
  );
};

const Span = styled.span`
  text-transform: none;
`;
