import { RuleAdminSidebarDefinition } from 'app/modules/sidebar/models';
import { U21TextField, U21Typography } from 'app/shared/u21-ui/components';
import { U21SideMenu } from 'app/shared/u21-ui/components/dashboard';
import { TableConfig } from 'app/shared/pagination/models';
import { RULE_ADMIN_INFO_MAP } from 'app/modules/rulesAdmin/models';
import styled from 'styled-components';
import { CopyButton } from 'app/modules/rulesAdmin/components/sidebar/CopyButton';

interface OwnProps {
  data: RuleAdminSidebarDefinition['data'];
}

export const SidebarRuleAdmin = ({ data }: OwnProps) => {
  return (
    <U21SideMenu
      title={RULE_ADMIN_INFO_MAP[data.contentType].title}
      titleIcon={RULE_ADMIN_INFO_MAP[data.contentType].icon}
    >
      {data.contentHeader.map((header: TableConfig) => (
        <div key={header.key}>
          <StyledTitleBar>
            <U21Typography variant="subtitle2">{header.label}</U21Typography>
            <CopyButton content={data.content[header.key]} />
          </StyledTitleBar>
          <U21TextField
            type="textarea"
            disabled
            onChange={() => {}}
            value={data.content[header.key]}
          />
        </div>
      ))}
    </U21SideMenu>
  );
};

const StyledTitleBar = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  margin-top: 8px;
`;
