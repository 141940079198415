import { post } from 'app/shared/utils/fetchr';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

// Selectors
import { selectHasReadEntitiesPermissions } from 'app/modules/session/selectors';

// Models
import {
  EntitiesRiskScoreResponse,
  ValidEntityRiskScore,
} from 'app/modules/riskRatings/responses';

// Constants
import { RISK_QUERY_KEYS } from 'app/modules/riskRatings/queries/keys';
import { useFetchActiveRiskModel } from 'app/modules/riskRatings/queries/useFetchActiveRiskModel';

interface FetchEntityLatestRiskScorePayload {
  entity_external_ids: string[];
}

const fetchEntityRiskScore = (
  payload: FetchEntityLatestRiskScorePayload,
): Promise<EntitiesRiskScoreResponse> => post(`/risk/entities/scores`, payload);

// Scores don't change often, store them for 60min in the cache
const DEFAULT_STALE_TIME = 60 * 60 * 1000;

// IF the BE told us to wait for cold storage, refetch after 2 minutes
const COLD_STORAGE_WAIT_TIME = 2 * 60 * 1000;
const COLD_STORAGE_MAX_RETRIES = 3;

export const useFetchEntityRiskScore = (entityExternalId: string) => {
  const { data: activeRiskModel } = useFetchActiveRiskModel();

  const hasReadEntitiesPermission = useSelector(
    selectHasReadEntitiesPermissions,
  );

  return useQuery({
    queryKey: RISK_QUERY_KEYS.getEntityRiskScore(entityExternalId),
    queryFn: () =>
      fetchEntityRiskScore({
        entity_external_ids: [entityExternalId],
      }),
    enabled:
      hasReadEntitiesPermission &&
      entityExternalId.length > 0 &&
      !isEmpty(activeRiskModel),
    select: (response): undefined | ValidEntityRiskScore => {
      if (response.results) {
        const {
          results,
          entities_loading: entitiesLoading,
          risk_model: riskModel,
        } = response;
        return {
          riskModel,
          loadingFromColdStorage: entitiesLoading.includes(entityExternalId),
          result: results[entityExternalId],
        };
      }
      return undefined;
    },
    refetchInterval: ({ state: { data, dataUpdateCount } }) => {
      const loadingFromColdStorage =
        data?.entities_loading?.includes(entityExternalId);
      if (
        loadingFromColdStorage &&
        dataUpdateCount < COLD_STORAGE_MAX_RETRIES
      ) {
        // Refetch if the BE told us to wait and we haven't exceeded retries
        return COLD_STORAGE_WAIT_TIME;
      }
      return false;
    },
    staleTime: DEFAULT_STALE_TIME,
  });
};
