import { useSelector } from 'react-redux';

// Components
import { U21Section } from 'app/shared/u21-ui/components';

import AuditTrailSection from 'app/shared/auditTrail/components/AuditTrailSection';

import { selectInvestigation } from 'app/modules/investigations/selectors';

// Types
import { InvestigationType } from 'app/modules/investigations/models';

interface OwnProps {
  type: InvestigationType;
}

export const AuditTrailTab = ({ type }: OwnProps) => {
  const investigation = useSelector(selectInvestigation(type));

  return (
    <U21Section title="Audit Trail">
      <AuditTrailSection trail={investigation.audit_trail} />
    </U21Section>
  );
};
