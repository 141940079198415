import { AiChatPrompt } from 'app/modules/codeInterpreter/models';
import { ExportTypes } from 'app/modules/fileExports/responses';

export const AI_CHAT_PROMPTS: Readonly<AiChatPrompt[]> = [
  {
    title: 'Noteable Patterns / Trends',
    description:
      'Tell me if there are any notable patterns or trends that emerges from my data',
    prompt:
      'Tell me if there are any notable patterns or trends that emerges from my data',
  },
  {
    title: 'Detect Fraud',
    description:
      'Tell me any outliers or anomalies in my data that might be fraudulent',
    prompt:
      'Tell me any outliers or anomalies in my data that might be fraudulent',
  },
  {
    title: 'Valuable Insights',
    description: 'Can you extract any valuable insights from my dataset?',
    prompt: 'Can you extract any valuable insights from my dataset?',
  },
  {
    title: 'User / Group Transaction Patterns',
    description:
      'Can you help me better understand transaction patterns of different user or geographic groups?',
    prompt:
      'Can you help me better understand transaction patterns of different user or geographic groups?',
  },
];

export const EXPORT_FILE_TITLE_MAP: Readonly<Record<ExportTypes, string>> = {
  [ExportTypes.ALERT]: 'Alerts',
  [ExportTypes.ALERT_CSV]: 'Alerts',
  [ExportTypes.CASE]: 'Cases',
  [ExportTypes.CASE_CSV]: 'Cases',
  [ExportTypes.CHECKLIST]: 'Checklists',
  [ExportTypes.CHECKLIST_CSV]: 'Checklists',
  [ExportTypes.DEVICES]: 'Devices',
  [ExportTypes.DEVICES_CSV]: 'Devices',
  [ExportTypes.ENTITIES]: 'Entities',
  [ExportTypes.ENTITIES_CSV]: 'Entities',
  [ExportTypes.EVENTS]: 'Events',
  [ExportTypes.EVENTS_CSV]: 'Events',
  [ExportTypes.INSTRUMENTS]: 'Instruments',
  [ExportTypes.INSTRUMENTS_CSV]: 'Instruments',
  [ExportTypes.RULE]: 'Rules',
  [ExportTypes.RULE_CSV]: 'Rules',
  [ExportTypes.RULE_VALIDATION]: 'Rule Validations',
  [ExportTypes.RULE_VALIDATION_CSV]: 'Rule Validations',
  [ExportTypes.SAR_FILING]: 'SAR Filings',
  [ExportTypes.SAR_FILING_CSV]: 'SAR Filings',
  [ExportTypes.TRANSACTIONS]: 'Transactions',
  [ExportTypes.TRANSACTIONS_CSV]: 'Transactions',
  [ExportTypes.CRR_CSV]: 'Risk Rating',
};
