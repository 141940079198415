import { isValidElement, Fragment } from 'react';
import styled, { css } from 'styled-components';

// Components
import {
  U21Spacer,
  U21Typography,
  U21Avatar,
} from 'app/shared/u21-ui/components';

// Types
import { CommentResponse } from 'app/modules/comments/types';

// Utils
import { formatCommentContent } from 'app/modules/comments/utils';

// Helpers
import { getTimeFromNow } from 'app/shared/utils/timeHelpers';
import { formatDatetime } from 'app/shared/utils/date';

export const Comment = ({ comment }: { comment: CommentResponse }) => {
  const {
    author: { picture, full_name: fullname, email },
    created_at: createdAt,
    content,
  } = comment;

  const authoredBy = fullname || email;
  const timeFromNow = getTimeFromNow(createdAt);
  const formattedContent = formatCommentContent(content);

  return (
    <U21Spacer horizontal align="start">
      <U21Avatar src={picture} name={authoredBy} />
      <Content>
        <U21Spacer horizontal spacing={0.5} wrap>
          <U21Typography ellipsis variant="subtitle2">
            {authoredBy}
          </U21Typography>
          <StyledU21Typography
            tooltip={formatDatetime(createdAt)}
            variant="caption"
            color="text.secondary"
          >
            {timeFromNow}
          </StyledU21Typography>
        </U21Spacer>
        <CommentContent horizontal spacing={0}>
          {formattedContent.map((contentPortion, idx) => {
            if (isValidElement(contentPortion)) {
              // eslint-disable-next-line react/no-array-index-key
              return <Fragment key={idx}>{contentPortion}</Fragment>;
            }
            return (
              // eslint-disable-next-line react/no-array-index-key
              <StyledComment variant="body2" key={idx}>
                {contentPortion}
              </StyledComment>
            );
          })}
        </CommentContent>
      </Content>
    </U21Spacer>
  );
};

const Content = styled.div`
  overflow: hidden;
`;

const StyledU21Typography = styled(U21Typography)`
  &:hover {
    text-decoration: underline;
    ${(props) => css`
      text-decoration-color: ${props.theme.palette.grey[500]};
    `}
  }
`;

const CommentContent = styled(U21Spacer)`
  margin-top: 4px;
  flex-wrap: wrap;
  gap: 4px;
`;

const StyledComment = styled(U21Typography)`
  word-break: break-word;
`;
