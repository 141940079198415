import { name } from 'app/modules/networkAnalysis/sliceNetworkAnalysis';
import { createSelector } from 'reselect';

export const selectLinkAnalysisState = (state: RootState) => state[name];

export const selectLinkAnalysisFilters = createSelector(
  selectLinkAnalysisState,
  (state) => state.filters,
);

export const selectNetworkAnalysisRequestIsQueued = createSelector(
  [selectLinkAnalysisState, (_, entityExternalId: string) => entityExternalId],
  (state, entityExternalId) => state.queuedRequests.includes(entityExternalId),
);
