import {
  U21Spacer,
  U21Histogram,
  U21HistogramDateRange,
  U21HistogramProps,
} from 'app/shared/u21-ui/components';
import {
  selectLumosConfig,
  selectFormatAmount,
} from 'app/modules/orgSettings/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { EventHistogram } from 'app/modules/histogram/models';
import { selectEventFilters } from 'app/shared/events/selectors';
import { useCallback, useMemo } from 'react';
import {
  histogramToSeries,
  useHistogramColors,
} from 'app/modules/histogram/utils';
import { selectDataSettingsByNativeKey } from 'app/modules/dataSettings/selectors';
import { cloneDeep } from 'lodash';
import { generateColor } from 'app/shared/utils/stringToColor';
import { U21DynamicColorChip } from 'app/shared/u21-ui/components/dashboard';
import { setEventFilters } from 'app/shared/events/actions';
import styled from 'styled-components';

export interface HistogramProps {
  histogram: EventHistogram;
  loading?: boolean;
  type: 'transactions' | 'actions';
}

export const HistogramGraph = ({
  histogram,
  loading,
  type,
}: HistogramProps) => {
  const dispatch = useDispatch();
  const filters = useSelector(selectEventFilters);
  const formatAmount = useSelector(selectFormatAmount);
  const { colors } = useSelector(selectLumosConfig);

  const { transaction_codes: transactionCodes } = histogram;
  const { endDate, internalTxnTypes, startDate } = filters;

  const dateRange = useMemo<U21HistogramDateRange>(
    () => [
      startDate ? new Date(startDate) : undefined,
      endDate ? new Date(endDate) : undefined,
    ],
    [endDate, startDate],
  );

  const enabledSet = useMemo(() => {
    if (!internalTxnTypes.length) {
      return new Set(transactionCodes);
    }
    return new Set(internalTxnTypes);
  }, [internalTxnTypes, transactionCodes]);

  const series = useMemo(
    () => histogramToSeries(histogram, dateRange, enabledSet),
    [dateRange, enabledSet, histogram],
  );

  const sortedTransactionCodes = useMemo(
    () =>
      transactionCodes.toSorted((a, b) =>
        a.toLowerCase() > b.toLowerCase() ? 1 : -1,
      ),
    [transactionCodes],
  );

  const dataSettingByNativeKey = useSelector(selectDataSettingsByNativeKey);
  const histogramColors = useHistogramColors(series);
  const overrideOptions = useCallback<
    Exclude<U21HistogramProps['getOverrideOptions'], undefined>
  >(
    (options) => {
      const modifiedOptions = cloneDeep(options);
      if (type === 'transactions') {
        modifiedOptions.yaxis = {
          labels: {
            formatter: (amount) =>
              formatAmount({
                amount,
                precision:
                  dataSettingByNativeKey.EVENT.amount?.rendering_options
                    ?.precision,
              }),
          },
        };
      }
      modifiedOptions.colors = histogramColors;
      modifiedOptions.legend = { show: false };
      return modifiedOptions;
    },
    [histogramColors, formatAmount, dataSettingByNativeKey, type],
  );

  return (
    <div>
      <U21Histogram
        dateRange={dateRange}
        getOverrideOptions={overrideOptions}
        loading={loading}
        series={series}
        timezone={
          dataSettingByNativeKey.EVENT.event_time?.rendering_options?.timezone
        }
      />
      {series.length > 0 && (
        <StyledU21Spacer wrap>
          {sortedTransactionCodes?.map((i) => {
            const enabled = enabledSet.has(i);
            return (
              <U21DynamicColorChip
                key={i}
                color={generateColor(i, colors)}
                disabled={enabled && enabledSet.size === 1}
                onClick={() => {
                  const newTypes = enabled
                    ? Array.from(enabledSet).filter((j) => j !== i)
                    : [...Array.from(enabledSet), i];

                  dispatch(
                    setEventFilters({
                      ...filters,
                      internalTxnTypes: newTypes,
                    }),
                  );
                }}
                variant={enabled ? 'filled' : 'outlined'}
              >
                {i}
              </U21DynamicColorChip>
            );
          })}
        </StyledU21Spacer>
      )}
    </div>
  );
};

const StyledU21Spacer = styled(U21Spacer)`
  justify-content: center;
`;
