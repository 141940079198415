import { CasePages } from 'app/modules/cases/models';
import { Filter } from 'app/modules/filters/models';
import { InvestigationsAgentStatsResponse } from 'app/modules/investigations/types';
import { Investigation } from 'app/modules/investigations/models';

import { CASE_COLUMN_CONFIG } from 'app/modules/cases/columns';
import { CASES_SLICE_NAME } from 'app/modules/cases/sliceCases';

import { createSelector } from 'reselect';
import { createTableColumnConfig } from 'app/shared/utils/table';
import { selectCaseQueueCaseFilters } from 'app/modules/queues/selectors';
import { selectCaseTableConfig } from 'app/shared/CustomConfig/selectors';
import {
  selectHasEditCasesPermission,
  selectHasReadAgentsPermissions,
  selectHasReadAssignmentsPermissions,
} from 'app/modules/session/selectors';

export const selectCases = (state: RootState) =>
  state[CASES_SLICE_NAME].cases || [];

export const selectCasesCount = (state: RootState) =>
  state[CASES_SLICE_NAME].count;

export const selectAgentStats = (
  state: RootState,
): InvestigationsAgentStatsResponse => state[CASES_SLICE_NAME].agentStats;

// Loading
export const selectCreateCaseLoading = (state: RootState) =>
  state[CASES_SLICE_NAME].loadingCreateCase;

export const selectRetrieveCasesLoading = (state: RootState) =>
  state[CASES_SLICE_NAME].loadingRetrieveCases;

export const selectEditCaseLoading = (state: RootState) =>
  state[CASES_SLICE_NAME].loadingEditCase;

export const selectCaseTableColumns = createSelector(
  [selectCaseTableConfig],
  (tableConfig) => [
    ...createTableColumnConfig<Investigation>(tableConfig, CASE_COLUMN_CONFIG),
  ],
);

export const selectAdminCaseFilters = (state: RootState) =>
  state[CASES_SLICE_NAME].adminCaseFilters;

export const selectMyCaseFilters = (state: RootState) =>
  state[CASES_SLICE_NAME].myCaseFilters;

export const selectQueuedCaseFilters = (state: RootState) =>
  state[CASES_SLICE_NAME].queuedCaseFilters;

export const selectCaseFilters = createSelector(
  [
    selectAdminCaseFilters,
    selectMyCaseFilters,
    selectQueuedCaseFilters,
    selectCaseQueueCaseFilters,
    (state, type: CasePages) => type,
  ],
  (
    adminCaseFilters,
    myCaseFilters,
    queuedCaseFilters,
    caseQueueCaseFilters,
    type,
  ): Filter[] => {
    switch (type) {
      case CasePages.ADMIN_CASES:
        return adminCaseFilters;
      case CasePages.MY_CASES:
        return myCaseFilters;
      case CasePages.QUEUED_CASES:
        return queuedCaseFilters;
      case CasePages.QUEUE_DETAILS:
        return caseQueueCaseFilters;
      default:
        return [];
    }
  },
);

export const selectHasReassignCasePermission = createSelector(
  selectHasReadAgentsPermissions,
  selectHasEditCasesPermission,
  (hasReadAgentsPermission, hasEditCasesPermission) => {
    return hasReadAgentsPermission && hasEditCasesPermission;
  },
);

export const selectHasRequeueCasePermission = createSelector(
  selectHasReadAssignmentsPermissions,
  selectHasEditCasesPermission,
  (hasReadAssignmentsPermission, hasEditCasesPermission) => {
    return hasReadAssignmentsPermission && hasEditCasesPermission;
  },
);
