import { LinkSection } from 'app/modules/networkAnalysis/components/LinkSection';
import { TransactionsSection } from 'app/modules/networkAnalysis/components/TransactionsSection';
import {
  DivRefObject,
  EntityLink,
  EntityTransaction,
  FilteredNetworkAnalysisData,
  LinkSectionMounted,
  LinkSectionUnMounted,
  NetworkAnalysisResponse,
} from 'app/modules/networkAnalysis/types';
import { useMemo } from 'react';

export interface NetworkLinkProps {
  data: NetworkAnalysisResponse;
  filteredData: FilteredNetworkAnalysisData;
  dispatch: React.Dispatch<
    | {
        type: LinkSectionMounted;
        payload: Record<string, DivRefObject>;
      }
    | {
        type: LinkSectionUnMounted;
        payload: {
          sectionHash: string;
        };
      }
  >;
  expandedSections: Set<string>;
  setExpandedSections: React.Dispatch<React.SetStateAction<Set<string>>>;
}

export const NetworkLinks = ({
  data,
  filteredData,
  dispatch,
  expandedSections,
  setExpandedSections,
}: NetworkLinkProps) => {
  const transactions = useMemo(
    () =>
      Object.values(data.transactions).reduce<EntityTransaction[]>(
        (acc, txn) => {
          if (filteredData.transactions.has(txn.id)) {
            acc.push(txn);
          }
          return acc;
        },
        [],
      ),
    [data, filteredData],
  );
  const links = useMemo(
    () =>
      Object.values(data.links).reduce<EntityLink[]>((acc, link) => {
        if (filteredData.links.has(link.id)) {
          acc.push(link);
        }
        return acc;
      }, []),
    [data, filteredData],
  );
  return (
    <>
      {!!transactions.length && (
        <TransactionsSection
          data={data}
          filteredData={filteredData}
          transactions={transactions}
          dispatch={dispatch}
          expandedSections={expandedSections}
          setExpandedSections={setExpandedSections}
        />
      )}
      {Object.values(links).map((link) => (
        <LinkSection
          key={link.id}
          link={link}
          data={data}
          filteredData={filteredData}
          dispatch={dispatch}
          expandedSections={expandedSections}
          setExpandedSections={setExpandedSections}
        />
      ))}
    </>
  );
};
