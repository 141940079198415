// Components
import { IconFlag } from '@u21/tabler-icons';
import {
  DataProps,
  SidebarExternalLinkList,
} from 'app/modules/sidebar/components/SidebarExternalLinkList';
import {
  U21Subsection,
  U21TitleCountLabel,
  U21Typography,
} from 'app/shared/u21-ui/components';

// Helpers
import { useMemo } from 'react';
import { formatDatetime } from 'app/shared/utils/date';
import { ROUTES_MAP } from 'app/shared/utils/routes';
import { ShortAlertResponse } from 'app/modules/alerts/types';
import { useSelector } from 'react-redux';
import { selectHasReadAlertsPermission } from 'app/modules/session/selectors';

interface Props {
  alerts: ShortAlertResponse[];
  count: number | null;
}

export const SidebarAssociatedAlerts = ({ alerts, count }: Props) => {
  const hasReadAlertsPermission = useSelector(selectHasReadAlertsPermission);
  const list = useMemo(
    () =>
      alerts.map<DataProps>((i) => ({
        label: formatDatetime(i.created_at),
        value: i.title,
        id: i.id,
        route: hasReadAlertsPermission
          ? ROUTES_MAP.alertsId.path.replace(':id', String(i.id))
          : undefined,
      })),
    [alerts, hasReadAlertsPermission],
  );
  return (
    <U21Subsection
      aria-label="Associated Alerts"
      collapsible
      collapsed
      shaded
      title={
        <U21TitleCountLabel count={count} label="">
          Associated Alerts
        </U21TitleCountLabel>
      }
      titleIcon={<IconFlag />}
    >
      {alerts.length ? (
        <SidebarExternalLinkList list={list} />
      ) : (
        <U21Typography variant="caption" color="text.secondary">
          No associated alerts
        </U21Typography>
      )}
    </U21Subsection>
  );
};
