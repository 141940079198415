import { IconX } from '@u21/tabler-icons';
import { IconAiAgent } from 'app/shared/components/Icons/IconAiAgent';

import {
  U21Popper,
  U21Button,
  U21Typography,
  U21Card,
} from 'app/shared/u21-ui/components';
import { Dispatch, SetStateAction } from 'react';
import styled, { css } from 'styled-components';

interface AiFindingsPopperProps {
  actionLabel: string;
  disabled: boolean;
  isOpenKey: string;
  isSidePanel?: boolean;
  itemKey: string;
  onClick: () => void;
  readableOutput: string;
  setIsOpenKey: Dispatch<SetStateAction<string>>;
}

export const AiFindingsPopper = ({
  actionLabel,
  disabled,
  isOpenKey,
  isSidePanel,
  itemKey,
  onClick,
  readableOutput,
  setIsOpenKey,
}: AiFindingsPopperProps) => {
  return (
    <U21Popper
      placement="right"
      open={isOpenKey === itemKey}
      trigger={
        <StyledButton
          $isSidePanel={isSidePanel}
          aria-label="AI Investigation checklist popper"
          icon={<IconAiAgent />}
          disabled={isOpenKey !== '' && isOpenKey !== itemKey}
          onClick={() =>
            setIsOpenKey((prevIsOpenKey) =>
              prevIsOpenKey === itemKey ? '' : itemKey,
            )
          }
          color="primary"
        />
      }
    >
      <StyledCard
        action={
          <U21Button
            aria-label="Close Findings"
            size="small"
            onClick={() => setIsOpenKey('')}
          >
            <IconX />
          </U21Button>
        }
        title="AI Findings:"
      >
        <U21Typography>{readableOutput}</U21Typography>
        {!disabled && (
          <StyledFooter>
            <U21Button color="primary" size="small" onClick={onClick}>
              {actionLabel}
            </U21Button>
          </StyledFooter>
        )}
      </StyledCard>
    </U21Popper>
  );
};

const StyledButton = styled(U21Button)<{ $isSidePanel?: boolean }>`
  width: fit-content;
  height: fit-content;

  ${(props) =>
    props.$isSidePanel
      ? css`
          // reduce margin same amount as padding
          margin: -8px;
        `
      : css``}
`;

const StyledCard = styled(U21Card)`
  width: 500px;
  box-shadow: ${(props) => props.theme.customShadows.z12};
`;

const StyledFooter = styled.div`
  display: flex;
  flex: 1;
  margin-top: 16px;
  justify-content: flex-end;
`;
