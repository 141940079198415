import { createRoot } from 'react-dom/client';
import { store } from 'app/store/configureStore';
import { history } from 'app/store/browserHistory';
import { initHeap } from 'app/shared/utils/heap';
import { initSentry } from 'app/shared/utils/sentry';
import { initUILibAnalytics } from 'app/shared/utils/analytics/u21Analytics';
import getFromProcessEnv from 'app/shared/utils/getFromProcessEnv';

import { App } from 'app/components/App';
import { ConnectedRouter } from 'connected-react-router';
import { ErrorBoundary } from 'app/components/ErrorBoundary';
import { Provider } from 'react-redux';
import { U21ThemeWrapper } from 'app/modules/theme/U21ThemeWrapper';
import AppProvider from 'app/shared/providers/AppProvider';
import AuthProvider from 'app/shared/providers/AuthProvider';

import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { queryClient } from 'app/cache/queryClient';

import '@fontsource/source-sans-pro/latin.css';
import 'app/styles/base.scss';
import 'cropperjs/dist/cropper.css';
import 'app/modules/rulesAdvanced/codeEditor/styles/IRCodeEditor.scss';
import 'codemirror/addon/hint/show-hint.css';
import 'codemirror/lib/codemirror.css';
import 'react-image-crop/dist/ReactCrop.css';

const heapId = getFromProcessEnv('REACT_APP_HEAP_ID');
initHeap(heapId);
initSentry();
initUILibAnalytics();

const container = document.getElementById('root')!;
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      <U21ThemeWrapper>
        <ConnectedRouter history={history}>
          <ErrorBoundary>
            <AppProvider>
              <AuthProvider>
                <App />
              </AuthProvider>
            </AppProvider>
          </ErrorBoundary>
        </ConnectedRouter>
      </U21ThemeWrapper>
    </QueryClientProvider>
  </Provider>,
);
