import { useQuery } from '@tanstack/react-query';
import {
  AlertsSearchResponse,
  CasesSearchResponse,
  EntitySearchResponse,
  EventsSearchResponse,
  InstrumentsSearchResponse,
  RulesSearchResponse,
  SearchRequest,
  SearchResponse,
  SearchTypes,
  TeamsSearchResponse,
} from 'app/modules/search/models';
import { NAVIGATOR_QUERY_KEYS } from 'app/modules/navigator/queries/keys';
import { post } from 'app/shared/utils/fetchr';

export const useGlobalSearch = (
  type: SearchTypes | undefined,
  payload: SearchRequest,
) => {
  return useQuery<SearchResponse>({
    queryFn: () => {
      switch (type) {
        case SearchTypes.ACTION_EVENTS:
          return post<EventsSearchResponse>('/action-events/search', payload);
        case SearchTypes.ALERTS:
          return post<AlertsSearchResponse>('/alerts/search', payload);
        case SearchTypes.CASES:
          return post<CasesSearchResponse>('/cases/search', payload);
        case SearchTypes.ENTITIES:
          return post<EntitySearchResponse>('/entities/search', payload);
        case SearchTypes.INSTRUMENTS:
          return post<InstrumentsSearchResponse>(
            '/instruments/search',
            payload,
          );
        case SearchTypes.RULES:
          return post<RulesSearchResponse>('/rules/search', payload);
        case SearchTypes.TEAMS:
          return post<TeamsSearchResponse>('/teams/search', payload);
        case SearchTypes.TRANSACTIONS:
          return post<EventsSearchResponse>('/events/search', payload);
        default:
          return Promise.resolve({ data: [] });
      }
    },
    queryKey: NAVIGATOR_QUERY_KEYS.search(type, payload),
    enabled: Boolean(type && payload.phrase && payload.phrase.length > 1),
    staleTime: 5 * 60 * 1000,
  });
};
