import { EntityBlockedReasonsResponse } from 'app/modules/entities/types';

import { ENTITY_QUERY_KEYS } from 'app/modules/entitiesRefresh/queries/keys';

import { get } from 'app/shared/utils/fetchr';
import { useQuery } from '@tanstack/react-query';

export const useGetBlockedReasons = (entityExternalId: string) => {
  return useQuery({
    queryFn: () =>
      get<EntityBlockedReasonsResponse>(
        `/entities/${encodeURIComponent(entityExternalId || '')}/entity-blocked-reasons`,
      ),
    queryKey: ENTITY_QUERY_KEYS.getEntityBlockedReasons(entityExternalId),
    staleTime: 10000,
    enabled: !!entityExternalId,
  });
};
