// Components
import { DarkWebSummary } from 'app/modules/alerts/components/AlertDetails/DarkWebSummary';

// Types
import { AlertDetailsTab, AlertType } from 'app/modules/alerts/types';

export const darkWebAlertTabs: AlertDetailsTab[] = [
  {
    label: 'Dark Web',
    path: 'dark-web',
    component: <DarkWebSummary />,
    visibleOn: {
      types: [AlertType.DARK_WEB],
    },
  },
];
