import { get } from 'app/shared/utils/fetchr';
import { useQuery } from '@tanstack/react-query';
import { ALERT_SCORE_QUERY_KEYS } from 'app/modules/alertScore/queries/keys';
import { ShapValuesModel } from 'app/modules/alertScore/types/responses';

export const useShapValues = (alertId?: string, enabled: boolean = true) => {
  const parsedAlertId = alertId ? parseInt(alertId, 10) : NaN;
  return useQuery({
    queryKey: ALERT_SCORE_QUERY_KEYS.getAlertShapValues(parsedAlertId),
    queryFn: () =>
      get<ShapValuesModel>(`/ml/alert-score/shap-values/${parsedAlertId}`),
    enabled: !isNaN(parsedAlertId) && enabled,
  });
};
