import { useQuery } from '@tanstack/react-query';
import { ENTITY_QUERY_KEYS } from 'app/modules/entitiesRefresh/queries/keys';
import { post } from 'app/shared/utils/fetchr';
import { EntitiesConsortiumHitsResponse } from 'app/modules/entitiesRefresh/responses';

const fetechEntitiesConsortiumHits = (
  entityExternalIds: string[],
): Promise<EntitiesConsortiumHitsResponse> =>
  post('/consortium/info', { entity_external_ids: entityExternalIds });

export const useFetchEntitiesConsortiumHits = (entityIds: string[]) => {
  return useQuery({
    queryKey: ENTITY_QUERY_KEYS.getEntitiesConsortiumHits(entityIds),
    queryFn: () => fetechEntitiesConsortiumHits(entityIds),
    meta: { errorMessage: 'Failed to fetch consortium hits.' },
    enabled: entityIds.length > 0,
  });
};
