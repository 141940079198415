import { keyPathToLabel } from 'app/modules/dataSettings/utils';
import { ShortEntityResponse } from 'app/modules/entities/types';
import {
  FIELD_GROUP,
  INITIAL_FIELD_INFO,
  INITIAL_WATCHLIST_ENTITY,
} from 'app/modules/watchlists/constants';
import {
  FieldInfo,
  FormattedWatchlistFields,
  WatchlistFieldIds,
  WatchlistFields,
  WatchlistKey,
  WatchlistResult,
} from 'app/modules/watchlists/models';
import { FetchWatchlistConfigResponse } from 'app/modules/watchlists/responses';
import { Filter, FilterOption } from 'app/modules/filters/models';
import {
  WATCHLIST_FILTER_KEYS,
  WATCHLIST_FILTER_KEYS_SET,
  WATCHLIST_FILTER_OPTIONS,
} from 'app/modules/watchlists/filters';
import {
  ArrayComparisonOperators,
  CustomDataFilters,
  SimpleComparisonOperators,
} from 'app/modules/filtersOld/models';
import {
  FormattedRecentValues,
  OrgDataSettingsConfig,
  Unit21DataClassifier,
} from 'app/modules/dataSettings/responses';
import { FILTER_OPERATOR, FILTER_TYPE } from 'app/modules/filters/constants';
import { INITIAL_FORMATTED_DATA_ITEM } from 'app/modules/rulesAdvanced/components/Facts/tests/mockData';
import { getActualWatchlistMatchThreshold } from 'app/modules/watchlists/helpers';

export const getKeyStringFromKeyObject = (keys: WatchlistKey): string =>
  JSON.stringify(keys);

export const getKeyObjectFromKeyString = (keyString: string): WatchlistKey =>
  JSON.parse(keyString);

export const getFormattedFields = (
  watchlists: WatchlistFields[],
): FormattedWatchlistFields[] => {
  return watchlists.map((watchlist, idx) => ({
    ...watchlist,
    key: { group: FIELD_GROUP, index: idx },
  }));
};

export const getValidFieldInfo = (
  data: FetchWatchlistConfigResponse | undefined,
): FieldInfo => {
  if (!data) {
    return INITIAL_FIELD_INFO;
  }

  return {
    fields: getFormattedFields(
      data.fields.map((field) => ({
        entity_data_values: field.entity_data_values.filter(
          (ds) => keyPathToLabel(ds) !== '',
        ),
        watchlist_data_values: field.watchlist_data_values.filter(
          (ds) => keyPathToLabel(ds) !== '',
        ),
      })),
    ),
    entityUnusedFields: data.entityUnusedFields.filter(
      (ds) => keyPathToLabel(ds) !== '',
    ),
    watchlistUnusedFields: data.watchlistUnusedFields.filter(
      (ds) => keyPathToLabel(ds) !== '',
    ),
    formattedData: data.formattedData,
  };
};

export const getValidWatchlistConfig = (
  fields: FormattedWatchlistFields[],
): WatchlistFieldIds[] | undefined => {
  try {
    return fields.map((field) => {
      if (
        field.entity_data_values.length === 0 &&
        field.watchlist_data_values.length === 0
      ) {
        throw new Error('Field groups cannot be empty');
      }

      return {
        entity_data_values: field.entity_data_values.map((ds) => ds.id),
        watchlist_data_values: field.watchlist_data_values.map((ds) => ds.id),
      };
    });
  } catch {
    return undefined;
  }
};

export const createWatchlistFilters = (filters: Filter[]) => {
  const { nativeFilters, customFilters } = filters.reduce<{
    nativeFilters: Filter[];
    customFilters: Filter[];
  }>(
    (acc, i) => {
      if (WATCHLIST_FILTER_KEYS_SET.has(i.key)) {
        if (i.key === WATCHLIST_FILTER_KEYS.MATCH_SCORE) {
          if (i.operator === FILTER_OPERATOR.IS_BETWEEN_NUMBER) {
            acc.nativeFilters.push({
              key: i.key,
              operator: i.operator,
              value: [
                getActualWatchlistMatchThreshold(Number(i.value[0])),
                getActualWatchlistMatchThreshold(Number(i.value[1])),
              ],
            });
          } else {
            acc.nativeFilters.push({
              key: i.key,
              operator: i.operator,
              value: getActualWatchlistMatchThreshold(Number(i.value)),
            } as Filter);
          }
        } else {
          acc.nativeFilters.push(i);
        }
      } else {
        acc.customFilters.push(i);
      }
      return acc;
    },
    {
      nativeFilters: [],
      customFilters: [],
    },
  );

  const customDataFilters = customFilters.reduce<CustomDataFilters>(
    (acc, i) => {
      const CLASSIFIER_CUSTOM_DATA_KEY_MAPPING = {
        [Unit21DataClassifier.WATCHLIST]: 'watchlist',
      };
      const { classifier, key } = JSON.parse(i.key);
      const customDataKey = CLASSIFIER_CUSTOM_DATA_KEY_MAPPING[classifier];
      if (!acc[customDataKey]) {
        acc[customDataKey] = [];
      }
      const { operator, value } = i;
      switch (operator) {
        case FILTER_OPERATOR.IS_GREATER_THAN_NUMBER:
          acc[customDataKey].push({
            key,
            nested: false,
            operator: SimpleComparisonOperators.GT,
            value,
          });
          break;

        case FILTER_OPERATOR.IS_LESS_THAN_NUMBER:
          acc[customDataKey].push({
            key,
            nested: false,
            operator: SimpleComparisonOperators.LT,
            value,
          });
          break;

        case FILTER_OPERATOR.IS_EXACT_TEXT:
          acc[customDataKey].push({
            key,
            nested: false,
            operator: SimpleComparisonOperators.EQ,
            value,
          });
          break;

        case FILTER_OPERATOR.IS_ONE_OF:
          acc[customDataKey].push({
            key,
            nested: false,
            operator: ArrayComparisonOperators.IN,
            value,
          });
          break;

        case FILTER_OPERATOR.IS_NOT_EXACT_TEXT:
          acc[customDataKey].push({
            key,
            nested: false,
            operator: SimpleComparisonOperators.NEQ,
            value,
          });
          break;

        case FILTER_OPERATOR.IS_NOT_ONE_OF:
          acc[customDataKey].push({
            key,
            nested: false,
            operator: ArrayComparisonOperators.NOT_IN,
            value,
          });
          break;

        case FILTER_OPERATOR.IS_EMPTY:
          acc[customDataKey].push({
            key,
            nested: false,
            operator: SimpleComparisonOperators.EQ,
            value: null,
          });
          break;

        case FILTER_OPERATOR.IS_NOT_EMPTY:
          acc[customDataKey].push({
            key,
            nested: false,
            operator: SimpleComparisonOperators.NEQ,
            value: null,
          });
          break;

        default:
          break;
      }

      return acc;
    },
    {},
  );

  return {
    filters: nativeFilters,
    custom_data_filters: customDataFilters,
  };
};

export const getWatchlistFilters = (names: string[]): FilterOption[] => {
  const uniqueNames = names.map((n) => ({
    text: n,
    value: n,
  }));

  return [
    ...WATCHLIST_FILTER_OPTIONS,
    {
      key: WATCHLIST_FILTER_KEYS.WATCHLIST_NAMES,
      label: 'Watchlist names',
      type: FILTER_TYPE.MULTI_SELECT,
      customize: {
        [FILTER_OPERATOR.IS_ONE_OF]: {
          inputProps: {
            options: uniqueNames,
          },
        },
        [FILTER_OPERATOR.IS_NOT_ONE_OF]: {
          inputProps: {
            options: uniqueNames,
          },
        },
      },
    },
  ];
};

const getFormattedData = (
  dataSettings: OrgDataSettingsConfig[],
  formattedData: FormattedRecentValues,
) =>
  dataSettings.reduce(
    (acc, ds) => ({
      ...acc,
      [ds.id]:
        Object.prototype.hasOwnProperty.call(formattedData, ds.id) &&
        formattedData[ds.id]
          ? formattedData[ds.id][0]
          : INITIAL_FORMATTED_DATA_ITEM,
    }),
    {},
  );

export const getPreviewWatchlist = (
  dataSettings: OrgDataSettingsConfig[],
  formattedData: FormattedRecentValues,
): WatchlistResult => ({
  custom_data: {
    comments: 'Comments for John Doe',
    country: 'United States',
    name: 'John Doe',
  },
  formatted_data: getFormattedData(dataSettings, formattedData),
  id: 1,
  names: ['OFAC'],
  provider: 'test-provider',
  type: 'sanction',
  created_at: 'Sun, 01 Oct 2023 20:16:41 GMT',
  notes: 'Extra notes',
  categories: ['Category 1', 'Category 2'],
  urls: ['https://dashboard.unit21.com/'],
  pep_infos: [],
  match_score: 0,
});

export const getPreviewEntity = (
  dataSettings: OrgDataSettingsConfig[],
  formattedData: FormattedRecentValues,
): ShortEntityResponse => ({
  ...INITIAL_WATCHLIST_ENTITY,
  formatted_data: getFormattedData(dataSettings, formattedData),
});
