import { WatchlistPepInfo } from 'app/modules/watchlists/models';
import {
  U21Chip,
  U21Divider,
  U21Grid,
  U21InfoItem,
  U21Section,
  U21Spacer,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { formatDate, formatDatetime } from 'app/shared/utils/date';
import styled, { css } from 'styled-components';

interface OwnProps {
  info: WatchlistPepInfo;
}

export const PepSummaryCard = ({ info }: OwnProps) => {
  return (
    <StyledSection
      title={<U21Typography variant="h6">PEP Summary</U21Typography>}
      collapsible
    >
      <U21Spacer horizontal spacing={3}>
        <U21InfoItem label="Primary" width="fit-content">
          <U21Chip>{info.is_primary.toString()}</U21Chip>
        </U21InfoItem>
        <U21InfoItem label="Status" width="fit-content">
          <U21Chip>{info.status}</U21Chip>
        </U21InfoItem>
      </U21Spacer>

      <StyledDivider horizontal flexItem />

      <U21Grid columns={2}>
        <U21InfoItem label="Admin level">{info.admin_level}</U21InfoItem>
        <U21InfoItem label="Date modified">
          {formatDatetime(info.date_modified)}
        </U21InfoItem>
        <U21InfoItem label="Country">{info.country}</U21InfoItem>
        <U21InfoItem label="Effective date">
          {formatDate(info.effective_date)}
        </U21InfoItem>
        <U21InfoItem label="Country role">{info.country_role}</U21InfoItem>
        <U21InfoItem label="Effective date type">
          {info.effective_date_type}
        </U21InfoItem>
        <U21InfoItem label="Governing institution">
          {info.governing_institution}
        </U21InfoItem>
        <U21InfoItem label="Expiration date">
          {formatDate(info.expiration_date)}
        </U21InfoItem>
        <U21InfoItem label="Governing role">{info.governing_role}</U21InfoItem>
        <U21InfoItem label="Expiration date type">
          {info.expiration_date_type}
        </U21InfoItem>
        <U21InfoItem label="Subcategories">
          {new Intl.ListFormat('en-US').format(info.sub_categories)}
        </U21InfoItem>
      </U21Grid>
    </StyledSection>
  );
};

const StyledSection = styled(U21Section)`
  ${(props) => css`
    background-color: ${props.theme.palette.background.neutral};
    border-color: ${props.theme.palette.background.neutral};
  `}
`;

const StyledDivider = styled(U21Divider)`
  margin-top: 16px;
  margin-bottom: 16px;
`;
