import { useSelector, useDispatch } from 'react-redux';

// Models
import { AuthClient } from 'app/modules/session/models';
import { CancelImpersonationPayload } from 'app/modules/agentsOld/types/requests';
import { PopoverSectionProps } from 'app/modules/navigator/models';

// Components
import { AccountPopoverSection } from 'app/modules/navigator/components/AccountPopoverSection';
import { IconUserX, IconUsers } from '@u21/tabler-icons';
import { U21Divider } from 'app/shared/u21-ui/components';

// Actions
import { cancelImpersonation } from 'app/modules/agentsOld/actions';

// Selectors
import {
  selectCanImpersonate,
  selectIsImpersonating,
} from 'app/modules/session/selectors';

// Utils
import {
  setImpersonateId,
  getImpersonateLogId,
  setImpersonateLogId,
} from 'app/shared/utils/sessionStorage';

interface Props extends PopoverSectionProps {
  auth: AuthClient;
  onOpenImpersonationModal: () => void;
}

function AccountImpersonateDropdown({
  auth,
  closePopover,
  onOpenImpersonationModal,
}: Props) {
  const dispatch = useDispatch();
  const canImpersonate = useSelector(selectCanImpersonate);
  const isImpersonating = useSelector(selectIsImpersonating);

  const handleCancelImpersonate = () => {
    const payload: CancelImpersonationPayload = {
      authClient: auth,
      impersonation_log_id: getImpersonateLogId(),
    };
    setImpersonateId(-1);
    setImpersonateLogId(-1);
    dispatch(cancelImpersonation(payload));
  };

  const items = isImpersonating
    ? [
        {
          icon: <IconUserX />,
          key: 'impersonate',
          onClick: handleCancelImpersonate,
          text: 'Cancel impersonation',
        },
      ]
    : [
        {
          icon: <IconUsers />,
          key: 'impersonate',
          onClick: onOpenImpersonationModal,
          text: 'Impersonate',
        },
      ];

  if (canImpersonate || isImpersonating) {
    return (
      <>
        <U21Divider horizontal />
        <AccountPopoverSection
          closePopover={closePopover}
          items={items}
          title="Impersonate"
        />
      </>
    );
  }

  return null;
}

export default AccountImpersonateDropdown;
