import React, { Component } from 'react';
import moment from 'moment';
import { withRouter, RouteComponentProps } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// Components
import {
  Label,
  Pagination,
  Dropdown,
  PaginationProps,
} from 'semantic-ui-react';
import PaginationTable from 'app/shared/pagination/components/PaginationTable';

// Actions
import { retrieveTriggeredAlerts } from 'app/modules/rules/actions';

// Selectors
import {
  selectAgent,
  selectHasReadAssignmentsPermissions,
} from 'app/modules/session/selectors';
import { selectHomeCurrencyCode } from 'app/modules/orgSettings/selectors';
import { selectClassifiedQueuesEnabled } from 'app/shared/featureFlags/selectors';

// Models
import { AlertSummary } from 'app/modules/alerts/models';
import { SortDirection, PaginationSettings } from 'app/shared/models';
import {
  PaginationPayload,
  SelectableCheckboxProps,
  TableConfig,
} from 'app/shared/pagination/models';

// Styles
import scssStyles from 'app/shared/styles/TriggeredAlertList.module.scss';
import { StyleConstants } from 'app/styles/StyleConstants';

// Constants
import {
  PAGE_LIMIT_OPTIONS,
  MAX_PAGINATION_PAGE_COUNT,
} from 'app/shared/constants';

// Utils
import routes, { openInNewTab } from 'app/shared/utils/routes';
import assets from 'app/shared/utils/assets';
import emptyFn from 'app/shared/utils/empty-fn';
import { toggleSidebar as toggleSidebarAction } from 'app/modules/sidebar/slice';
import { SidebarComponentTypes } from 'app/modules/sidebar/models';
import { isCmdCtrlClick } from 'app/shared/utils/keyboard';
import { isQueueAccessible } from 'app/modules/queues/utils';

interface OwnProps {
  alerts: AlertSummary[];
  alertCount: number;
  retrieveAlertsLoading: boolean;
  paginationSettings: PaginationSettings;
  getNextPage?: (payload: PaginationPayload) => void;
  showStatistics?: boolean | undefined;
  selectableCheckboxProps?: SelectableCheckboxProps;
}

const mapStateToProps = (state: RootState) => ({
  homeCurrencyCode: selectHomeCurrencyCode(state),
  classifiedQueuesEnabled: selectClassifiedQueuesEnabled(state),
  hasReadAssignmentsPermission: selectHasReadAssignmentsPermissions(state),
  sessionAgent: selectAgent(state),
});

const mapDispatchToProps = {
  retrieveTriggeredAlerts,
  toggleSidebar: toggleSidebarAction,
};

interface AllState {
  sortDirection: SortDirection;
  sortColumn: string;
}

type AllProps = OwnProps &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  RouteComponentProps;

class TriggeredAlertList extends Component<AllProps, AllState> {
  constructor(props: AllProps) {
    super(props);
    this.state = {
      sortDirection: 'descending',
      sortColumn: '',
    };
  }

  handlePaginationChange: (
    event: React.MouseEvent<HTMLAnchorElement>,
    data: PaginationProps,
  ) => void = (e, data) => {
    const {
      getNextPage,
      paginationSettings: { pageLimit },
    } = this.props;
    const activePage = data.activePage as number;
    getNextPage?.({
      offset: activePage,
      limit: pageLimit,
    });
  };

  handlePageLimitChange = (e, { value }) => {
    const { getNextPage } = this.props;
    const { sortDirection, sortColumn } = this.state;
    const offsetValue: number = 1;
    getNextPage?.({
      offset: offsetValue,
      limit: value,
      sort_direction: sortDirection,
      sort_column: sortColumn,
    });
  };

  renderCellValue = (config: TableConfig, row: AlertSummary) => {
    const { showStatistics } = this.props;
    const value = row[config.key];

    if (config.key === 'status') {
      return (
        <Label
          style={{
            backgroundColor: StyleConstants.STATUS[value],
            color: 'white',
          }}
        >
          {value}
        </Label>
      );
    } else if (config.key === 'source') {
      return (
        <Label
          style={{
            backgroundColor: StyleConstants.SOURCE[value || 'INTERNAL'],
            color: 'white',
          }}
        >
          {value || 'INTERNAL'}
        </Label>
      );
    } else if (config.key === 'created_at') {
      return moment.utc(value).format('D MMM YYYY');
    } else if (['num_txns', 'amount_volume'].includes(config.key)) {
      return showStatistics ? value : null;
    }

    return value;
  };

  isRowClickable = (alert: AlertSummary) => {
    const {
      classifiedQueuesEnabled,
      hasReadAssignmentsPermission,
      sessionAgent,
    } = this.props;

    return (
      !classifiedQueuesEnabled ||
      isQueueAccessible(
        hasReadAssignmentsPermission,
        sessionAgent.accessible_queues,
        alert.queue_id,
        alert.queue_access_type,
      )
    );
  };

  onRowClick = (e: KeyboardEvent, alert: AlertSummary) => {
    const { toggleSidebar } = this.props;
    if (isCmdCtrlClick(e)) {
      openInNewTab(
        routes.patronus.alertsAlertId.replace(':alertId', String(alert.id)),
      );
    } else {
      toggleSidebar({
        type: SidebarComponentTypes.ALERT,
        data: { alert },
      });
    }
  };

  render() {
    const {
      alertCount,
      showStatistics,
      paginationSettings,
      homeCurrencyCode,
      retrieveAlertsLoading,
      alerts,
      selectableCheckboxProps,
    } = this.props;
    const { pageOffset } = paginationSettings;

    // handle the case where the default pageLimit is more than is possible
    let { pageLimit } = paginationSettings;
    if (paginationSettings.pageLimit > alertCount) {
      pageLimit = alertCount;
    }

    const pageLimitOptionsFiltered = PAGE_LIMIT_OPTIONS.filter(
      (o) => o.value < alertCount,
    );

    if (alertCount < MAX_PAGINATION_PAGE_COUNT) {
      pageLimitOptionsFiltered.push({
        key: alertCount,
        value: alertCount,
        text: 'All',
      });
    }

    const totalPages = Math.ceil(alertCount / pageLimit) || 1;

    const AlertListTableConfig: TableConfig[] = [
      {
        key: 'id',
        label: 'ID',
        sortable: false,
      },
      {
        key: 'created_at',
        label: 'Date Flagged',
        type: 'datetime',
        sortable: false,
      },
      {
        key: 'status',
        label: 'Status',
        sortable: false,
      },
      {
        key: 'title',
        label: 'Title',
        sortable: false,
      },
      {
        key: 'source',
        label: 'Source',
        type: 'label',
        sortable: false,
      },
    ];

    if (showStatistics) {
      AlertListTableConfig.push(
        {
          key: 'amount_volume',
          label: `${homeCurrencyCode} Volume`,
          width: 2,
          sortable: false,
        },
        {
          key: 'num_txns',
          label: 'Total Txns',
          width: 2,
          sortable: false,
        },
      );
    }

    return (
      <div>
        {PAGE_LIMIT_OPTIONS[0] && alertCount > PAGE_LIMIT_OPTIONS[0].value && (
          <div className={scssStyles.paginationText}>
            Showing up to{' '}
            {
              <Dropdown
                value={pageLimit}
                options={pageLimitOptionsFiltered}
                onChange={this.handlePageLimitChange}
              />
            }{' '}
            of {alertCount} alerts
          </div>
        )}

        <PaginationTable
          // TODO refactor TriggeredAlertList to receive update prop for paginating, currently getNextPage
          update={emptyFn}
          totalCount={alertCount}
          isRowClickable={this.isRowClickable}
          onRowClick={this.onRowClick}
          rows={alerts}
          disableFooter
          loading={retrieveAlertsLoading}
          renderCellValue={this.renderCellValue}
          config={AlertListTableConfig}
          dataType="triggered alerts"
          dataTypeIcon={assets.icons.checkeredFlag}
          tableProps={{
            celled: true,
            selectable: true,
            striped: true,
            className: scssStyles.table,
          }}
          selectableCheckboxProps={selectableCheckboxProps}
        />

        {totalPages > 1 && (
          <div className={scssStyles.paginationContainer}>
            <Pagination
              activePage={pageOffset}
              onPageChange={this.handlePaginationChange}
              totalPages={totalPages}
            />
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(TriggeredAlertList));
