import { useSelector } from 'react-redux';

// Components
import { SelectFilter } from 'app/modules/linkAnalysis/components/ActivityAnalysis/SelectFilter';

// Selectors
import { selectAlertEntityOptions } from 'app/modules/alerts/selectors';

export const EntitySelect = () => {
  const entityOptions = useSelector(selectAlertEntityOptions);

  return <SelectFilter filterKey="selectedEntity" options={entityOptions} />;
};
