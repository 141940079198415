import { useQuery } from '@tanstack/react-query';
import { GetFraudLossTablePayload } from 'app/modules/fraudDashboard/models';
import { post } from 'app/shared/utils/fetchr';
import { FRAUD_LOSS_QUERY_KEYS } from 'app/modules/fraudDashboard/queries/keys';
import { useSelector } from 'react-redux';
import { selectHasReadEventsPermission } from 'app/modules/session/selectors';
import { FraudLossListResponse } from 'app/modules/fraudDashboard/responses';

const fetchFraudLossTable = (
  body: GetFraudLossTablePayload,
): Promise<FraudLossListResponse> =>
  post('/fraud_reporting/txn_meta/list', body);

interface Config {
  enabled?: boolean;
}

export const useFetchFraudLossTable = (
  body: GetFraudLossTablePayload,
  { enabled = true }: Config = {},
) => {
  const hasReadEventsPermission = useSelector(selectHasReadEventsPermission);
  return useQuery<FraudLossListResponse>({
    enabled: hasReadEventsPermission && body.limit > 0 && enabled,
    queryKey: FRAUD_LOSS_QUERY_KEYS.getTableQueryKey(body),
    queryFn: () => fetchFraudLossTable(body),
  });
};
