import { SidebarComponentTypes } from 'app/modules/sidebar/models';

import { FeatureFlag } from 'app/shared/featureFlags/models';

import {
  selectHasEditPermissionsPermission,
  selectIsUnit21User,
} from 'app/modules/session/selectors';
import { setFeatureFlag } from 'app/modules/devtools/components/FeatureFlagControl';
import { toggleSidebar } from 'app/modules/sidebar/slice';
import { toggleTheme } from 'app/modules/theme/sliceTheme';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import {
  IconFlagCheck,
  IconLockCheck,
  IconLockQuestion,
  IconPalette,
  IconSend,
  IconTool,
} from '@u21/tabler-icons';
import {
  U21Button,
  U21MenuItem,
  U21MenuLayout,
} from 'app/shared/u21-ui/components';
import { PermissionsAuditorControl } from 'app/modules/devtools/components/PermissionsAuditorControl';
import Postman from 'app/modules/devtools/components/Postman';
import {
  disableNewDesign,
  enableNewDesign,
  isNewDesignEnabled,
} from 'vendor/material-minimal/featureFlag';

export const DevToolsPopover = () => {
  const dispatch = useDispatch();
  const hasEditPermissionsPermission = useSelector(
    selectHasEditPermissionsPermission,
  );
  const isUnit21User = useSelector(selectIsUnit21User);

  const [open, setOpen] = useState(false);
  const [showPostman, setShowPostman] = useState(false);
  const [showPermissionsAuditor, setShowPermissionsAuditor] = useState(false);

  useEffect(() => {
    if (hasEditPermissionsPermission) {
      window.permissions = () => {
        dispatch(
          toggleSidebar({
            data: {},
            type: SidebarComponentTypes.PERMISSIONS,
          }),
        );
      };
    } else {
      delete window.permissions;
    }
  }, [dispatch, hasEditPermissionsPermission]);

  useEffect(() => {
    if (isUnit21User) {
      window.setFeatureFlags = (payload) => {
        Object.keys(payload).forEach((key: FeatureFlag) => {
          dispatch(setFeatureFlag({ key, value: payload[key] }));
        });
      };

      window.featureFlags = () => {
        dispatch(
          toggleSidebar({
            data: {},
            type: SidebarComponentTypes.FEATURE_FLAGS,
          }),
        );
      };

      window.postman = () => setShowPostman((state) => !state);

      window.permissionsAuditor = () => {
        window.sessionStorage.setItem('permissionsAuditor', 'true');
        setShowPermissionsAuditor((state) => !state);
      };
    } else {
      delete window.setFeatureFlags;
      delete window.featureFlags;
      delete window.postman;
      delete window.permissionsAuditor;
    }
  }, [dispatch, isUnit21User]);

  if (!isUnit21User) {
    return null;
  }
  const newDesignEnabled = isNewDesignEnabled();

  return (
    <>
      <U21MenuLayout
        alignRight
        onClose={() => setOpen(false)}
        open={open}
        trigger={
          <U21Button
            aria-label="Devtools"
            icon={<IconTool />}
            onClick={() => setOpen(true)}
            tooltip="Internal tools"
          />
        }
      >
        {hasEditPermissionsPermission && (
          <U21MenuItem
            item={{
              icon: <IconLockCheck />,
              onClick: () => {
                dispatch(
                  toggleSidebar({
                    data: {},
                    type: SidebarComponentTypes.PERMISSIONS,
                  }),
                );
                setOpen(false);
              },
              text: 'Open permission control',
            }}
          />
        )}
        <U21MenuItem
          item={{
            icon: <IconFlagCheck />,
            onClick: () => {
              dispatch(
                toggleSidebar({
                  data: {},
                  type: SidebarComponentTypes.FEATURE_FLAGS,
                }),
              );
              setOpen(false);
            },
            text: 'Open feature flag control',
          }}
        />
        <U21MenuItem
          item={{
            icon: <IconLockQuestion />,
            onClick: () => {
              window.sessionStorage.setItem('permissionsAuditor', 'true');
              setShowPermissionsAuditor((state) => !state);
              setOpen(false);
            },
            text: showPermissionsAuditor
              ? 'Close permission auditor'
              : 'Open permission auditor',
          }}
        />
        <U21MenuItem
          item={{
            icon: <IconSend />,
            onClick: () => {
              setShowPostman(true);
              setOpen(false);
            },
            text: 'Open postman',
          }}
        />
        <U21MenuItem
          item={{
            icon: <IconPalette />,
            onClick: () => {
              dispatch(toggleTheme());
              setOpen(false);
            },
            text: 'Toggle theme',
          }}
        />
        <U21MenuItem
          item={{
            icon: <IconPalette />,
            onClick: () => {
              if (newDesignEnabled) {
                disableNewDesign();
              } else {
                enableNewDesign();
              }
              setOpen(false);
            },
            text: `${newDesignEnabled ? 'Disable' : 'Enable'} new design`,
          }}
        />
      </U21MenuLayout>
      {showPermissionsAuditor && <PermissionsAuditorControl />}
      {showPostman && <Postman />}
    </>
  );
};
