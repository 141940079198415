import { createSelector } from 'reselect';

// Models
import {
  InvestigationType,
  PaginatedInvestigations,
} from 'app/modules/investigations/models';

// Selectors
import {
  selectAlert,
  selectAlertLoading,
  selectAlerts,
  selectAlertsCount,
  selectAlertsLoading,
} from 'app/modules/alerts/selectors';
import { selectCase, selectCaseLoading } from 'app/modules/casesOld/selectors';
import {
  selectCases,
  selectCasesCount,
  selectRetrieveCasesLoading,
} from 'app/modules/cases/selectors';

export const selectInvestigation = (type: InvestigationType) =>
  createSelector(selectAlert, selectCase, (alertDetails, caseDetails) => {
    return type === InvestigationType.ALERT ? alertDetails : caseDetails;
  });

export const selectInvestigationCase = selectCase;

export const selectInvestigationLoading = (type: InvestigationType) =>
  createSelector(
    selectAlertLoading,
    selectCaseLoading,
    (alertLoading, caseLoading) =>
      type === InvestigationType.ALERT ? alertLoading : caseLoading,
  );

export const selectPaginatedInvestigations = (type: InvestigationType) =>
  createSelector(
    selectAlerts,
    selectAlertsCount,
    selectCases,
    selectCasesCount,
    (alerts, alertsCount, cases, casesCount): PaginatedInvestigations => ({
      investigations: type === InvestigationType.ALERT ? alerts : cases,
      count: type === InvestigationType.ALERT ? alertsCount : casesCount,
    }),
  );

export const selectPaginatedInvestigationsLoading = (type: InvestigationType) =>
  createSelector(
    selectAlertsLoading,
    selectRetrieveCasesLoading,
    (alertsLoading, casesLoading) =>
      type === InvestigationType.ALERT ? alertsLoading : casesLoading,
  );
