import { useSelector } from 'react-redux';

// components
import { AuditTrail } from 'app/modules/auditService/components/AuditTrail';
import { U21Section } from 'app/shared/u21-ui/components';
import { selectInvestigation } from 'app/modules/investigations/selectors';
import { InvestigationType } from 'app/modules/investigations/models';

interface Props {
  assetId: number;
}

export const CaseAuditTrail = ({ assetId }: Props) => {
  const { created_at: caseCreatedAt } = useSelector(
    selectInvestigation(InvestigationType.CASE),
  );

  return (
    <U21Section title="Audit Trail">
      <AuditTrail
        assetId={String(assetId)}
        assetType="case"
        assetCreatedAt={caseCreatedAt || ''}
      />
    </U21Section>
  );
};
