import { TRANSFORMATION_TYPE_OPTIONS } from 'app/modules/dataMapping/constants';
import { Transformation } from 'app/modules/dataMapping/responses';
import { ANNOTATION_TRANSFORMATION_EXCEPTION_TYPE_SET } from 'app/modules/pullBasedDataFiles/constants';
import {
  PullBasedDataFile,
  PullBasedDataFileStatus,
  PullBasedDataFileExecutionStatus,
  PullBasedDataFileExecutionErrors,
  LegacyPullBasedDataFileExecutionErrors,
  ProcessingException,
  AnnotationTransformationException,
} from 'app/modules/pullBasedDataFiles/responses';
import {
  AdditionalPullBasedDatafileStatus,
  FormattedPullBasedDatafileStatus,
} from 'app/modules/pullBasedDataFiles/types';
import { U21ChipColor, U21ChipVariant } from 'app/shared/u21-ui/components';
import { toSentenceCase } from 'app/shared/utils/string';
import { Filter } from 'app/modules/filters/models';
import { StreamFilters } from 'app/modules/pullBasedDataFiles/requests';
import { FILTER_OPERATOR } from 'app/modules/filters/constants';
import { STREAM_FILTER_KEYS } from 'app/modules/pullBasedDataFiles/filters';
import { PrimaryObject } from 'app/modules/dataMapping/types';

// There is a `status` field on the PullBasedDatafile model.
// This field is only updated when we process a file.
// It is not updated when we "validate" a config (i.e. process w/o ingesting data)
//
// This method consolidates the logic of showing the user friendly status of each file
export const getFormattedStatusForPullBasedDatafile = (
  file: PullBasedDataFile,
): FormattedPullBasedDatafileStatus => {
  const { status, latest_execution: execution } = file;
  const isTestExecution = execution?.is_dry_run;

  if (!isTestExecution) {
    // Remove the QUEUED status altogether, and replace with PROCESSING
    if (
      status === PullBasedDataFileStatus.QUEUED ||
      status === PullBasedDataFileStatus.PROCESSING
    ) {
      return PullBasedDataFileStatus.PROCESSING;
    }

    // All other cases
    return status;
  }

  return {
    // Queued status replaced with validating here (the alternative to PROCESSING)
    [PullBasedDataFileExecutionStatus.QUEUED]:
      AdditionalPullBasedDatafileStatus.VALIDATING,
    [PullBasedDataFileExecutionStatus.PROCESSING]:
      AdditionalPullBasedDatafileStatus.VALIDATING,
    [PullBasedDataFileExecutionStatus.FINISHED_IN_FAILURE]:
      AdditionalPullBasedDatafileStatus.VALIDATED,
    [PullBasedDataFileExecutionStatus.FINISHED_SUCCESSFULLY]:
      AdditionalPullBasedDatafileStatus.VALIDATED,
  }[execution.status];
};

export const isNotLegacyErrors = (
  errors:
    | PullBasedDataFileExecutionErrors
    | LegacyPullBasedDataFileExecutionErrors,
): errors is PullBasedDataFileExecutionErrors => {
  return errors && 'total_count' in errors;
};

export const lastExecutionHasErrors = ({
  latest_execution: execution,
}: PullBasedDataFile): boolean => {
  if (!execution?.errors) {
    return false;
  }
  if (isNotLegacyErrors(execution.errors)) {
    return Boolean(execution?.errors?.total_count);
  }
  return Boolean(execution.errors.length);
};

export const getCopyForPullBasedDataFileStatus = (file: PullBasedDataFile) => {
  const { latest_execution: execution } = file;
  if (file.status === PullBasedDataFileStatus.PENDING_UPLOAD) {
    return 'Pending upload';
  }
  const formattedStatus = getFormattedStatusForPullBasedDatafile(file);
  const sentenceCased = toSentenceCase(formattedStatus);
  if (
    execution &&
    (formattedStatus === PullBasedDataFileStatus.FINISHED ||
      formattedStatus === AdditionalPullBasedDatafileStatus.VALIDATED)
  ) {
    const totalRows = execution.failure_count + execution.success_count;
    return `${sentenceCased} ${execution.success_count} / ${totalRows} rows`;
  }

  // All other cases
  return sentenceCased;
};

export const getChipForPullBasedDataFile = (file: PullBasedDataFile) => {
  let variant: U21ChipVariant = 'filled';
  let color: U21ChipColor =
    {
      [PullBasedDataFileStatus.FAILED]: 'error',
      [PullBasedDataFileStatus.FINISHED]: 'success',
      [AdditionalPullBasedDatafileStatus.VALIDATED]: 'success',
    }[getFormattedStatusForPullBasedDatafile(file)] || 'default';
  if (
    file.status === PullBasedDataFileStatus.FINISHED &&
    lastExecutionHasErrors(file)
  ) {
    if (!file.latest_execution?.success_count) {
      color = 'error';
      variant = 'ghost';
    } else {
      color = 'warning';
    }
  }
  return { variant, color };
};

// TODO: handle transformation functions better e.g. with enums
// and surface transformation sub-functions, like datetime -> strptime format
export const getReadableTransformation = (
  t: Transformation['function'],
): string => {
  return TRANSFORMATION_TYPE_OPTIONS.find((o) => o.value === t)?.text ?? '';
};

export const isAnnotationTransformationException = (
  e: ProcessingException | AnnotationTransformationException,
): e is AnnotationTransformationException =>
  ANNOTATION_TRANSFORMATION_EXCEPTION_TYPE_SET.has(e.type);

export const createStreamFiltersPayload = (
  filters: Filter[],
): StreamFilters => {
  return filters.reduce<StreamFilters>(
    (acc, i) => {
      const { key, operator, value } = i;
      switch (key) {
        case STREAM_FILTER_KEYS.SEARCH: {
          if (operator === FILTER_OPERATOR.CONTAINS_TEXT) {
            acc.search = value;
          }
          break;
        }
        case STREAM_FILTER_KEYS.TYPE: {
          if (operator === FILTER_OPERATOR.IS_ONE_OF) {
            acc.unit21_objects = value as PrimaryObject[];
          }
          break;
        }
        default:
          break;
      }
      return acc;
    },
    { search: '', unit21_objects: [] },
  );
};
