// Models
import { FormFieldSchema } from 'app/shared/models/form';

// Components
import { IconList, IconMessageDots, IconTicket } from '@u21/tabler-icons';
import { U21FormFieldTypes } from 'app/shared/u21-ui/components';

// Utils
import { required } from 'app/shared/utils/validation';

const IMPERSONATION_REASONS = [
  {
    value: 'SUPPORT_TICKET',
    text: 'Customer support ticket',
  },
  {
    value: 'SCREEN_SHARE',
    text: 'Customer screen share',
  },
  {
    value: 'VIEW_CHANGES',
    text: 'Test/View changes',
  },
  {
    value: 'OTHER',
    text: 'Other',
  },
];

export const ORG_FIELD: FormFieldSchema<number> = {
  label: 'Organization',
  name: 'org_id',
  type: U21FormFieldTypes.SELECT,
  required: true,
  validate: required('Organization is required.'),
};

export const AGENT_FIELD: FormFieldSchema<number> = {
  label: 'Agent to impersonate',
  name: 'agent_to_impersonate_id',
  type: U21FormFieldTypes.SELECT,
  required: true,
  validate: required('Agent to impersonate is required.'),
};

export const REASON_DROPDOWN_FIELD: FormFieldSchema<string> = {
  label: 'Impersonation reason',
  name: 'impersonation_reason',
  required: true,
  type: U21FormFieldTypes.SELECT,
  fieldProps: {
    startIcon: <IconList />,
    options: IMPERSONATION_REASONS,
    placeholder: 'Select impersonation reason',
  },
  validate: required('Impersonation reason is required.'),
};

export const SUPPORT_TICKET_FIELD: FormFieldSchema<string> = {
  description: 'Provide support ticket ID',
  fieldProps: {
    startIcon: <IconTicket />,
  },
  label: 'Support ticket ID',
  name: 'support_ticket_id',
  required: true,
  type: U21FormFieldTypes.TEXT,
  validate: required('Support ticket ID is required.'),
};

export const SCREEN_SHARE_DETAILS_FIELD: FormFieldSchema<string> = {
  description:
    'Provide a summary of why a screen share is required through impersonation and whether it is possible to do it with the customer sharing screen instead.',
  fieldProps: {
    startIcon: <IconMessageDots />,
  },
  label: 'Screen share details',
  name: 'additional_details',
  required: true,
  type: U21FormFieldTypes.TEXT,
  validate: required('Screen share details are required.'),
};

export const TEST_CHANGES_DETAILS_FIELD: FormFieldSchema<string> = {
  description:
    'Provide a summary of what is being tested/viewed and why impersonation is required.',
  fieldProps: {
    startIcon: <IconMessageDots />,
  },
  label: 'Details of changes',
  name: 'additional_details',
  required: true,
  type: U21FormFieldTypes.TEXT,
  validate: required(
    'Details of changes and impersonation reason are required.',
  ),
};

export const OTHER_REASON_FIELD: FormFieldSchema<string> = {
  description: 'Provide a summary of why an impersonation is required',
  fieldProps: {
    startIcon: <IconMessageDots />,
  },
  label: 'Details',
  name: 'additional_details',
  required: true,
  type: U21FormFieldTypes.TEXT,
  validate: required('Details of impersonation reason are required.'),
};

export const IMPERSONATION_SCHEMA = [
  AGENT_FIELD,
  TEST_CHANGES_DETAILS_FIELD,
  SUPPORT_TICKET_FIELD,
  OTHER_REASON_FIELD,
  REASON_DROPDOWN_FIELD,
  SCREEN_SHARE_DETAILS_FIELD,
];
