import React, { Component, RefObject } from 'react';
import autoBindReact from 'auto-bind/react';
import { connect } from 'react-redux';

// Components
import { Icon, SemanticICONS } from 'semantic-ui-react';
import { Timeline, TimelineEvent } from 'react-event-timeline';
import AuditTrailModal from 'app/shared/auditTrail/components/AuditTrailModal';
import Loading from 'app/shared/components/Loading';

// Actions
import {
  retrieveAuditTrailEntries as retrieveAuditTrailEntriesAction,
  clearAuditTrailEntries as clearAuditTrailEntriesAction,
} from 'app/shared/auditTrail/actions';

// Helpers
import { renderAuditTrailIconStyle } from 'app/shared/helpers';

// Models
import { TimelineTimeFormat } from 'app/modules/comments/models';

// Styles
import { StyleConstants } from 'app/styles/StyleConstants';
import styles from 'app/shared/auditTrail/styles/AuditTrail.module.scss';

// Constants
import { OFFSET_DEFAULT } from 'app/shared/constants';

// Utils
import emptyFn from 'app/shared/utils/empty-fn';
import { getTimeFromNow, getLocalFormat } from 'app/shared/utils/timeHelpers';

// Selectors
import {
  selectAuditTrailEntries,
  selectAuditTrailEntriesCount,
  selectAuditTrailEntriesLoading,
} from 'app/shared/auditTrail/selectors';

interface OwnProps {
  entityIDs?: number[];
}

interface AllState {
  limit: number | null;
  offset: number;
  timeFormat: TimelineTimeFormat;
  modalOpen: boolean;
  selectedId: number;
}

const mapStateToProps = (state) => ({
  auditTrailEntries: selectAuditTrailEntries(state),
  auditTrailEntriesCount: selectAuditTrailEntriesCount(state),
  loading: selectAuditTrailEntriesLoading(state),
});

const mapDispatchToProps = {
  retrieveAuditTrailEntries: retrieveAuditTrailEntriesAction,
  clearAuditTrailEntries: clearAuditTrailEntriesAction,
};

type AllProps = OwnProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

class PaginatedAuditTrailSection extends Component<AllProps, AllState> {
  loadingRef: RefObject<HTMLDivElement>;

  observer: IntersectionObserver;

  constructor(props) {
    super(props);
    this.state = {
      limit: null,
      offset: OFFSET_DEFAULT,
      timeFormat: 'From Now',
      modalOpen: false,
      selectedId: -1,
    };

    autoBindReact(this);

    // Loading container
    this.loadingRef = React.createRef<HTMLDivElement>();
  }

  componentDidMount() {
    const { clearAuditTrailEntries } = this.props;
    clearAuditTrailEntries();
    this.fetchAuditTrails();
  }

  componentDidUpdate() {
    // Set Observer
    if (!this.observer && this.loadingRef.current) {
      // Infinite Scroll Logic
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
      };
      this.observer = new IntersectionObserver(this.handleObserver, options);
      this.observer.observe(this.loadingRef.current);
    }
  }

  componentWillUnmount() {
    const { clearAuditTrailEntries } = this.props;
    clearAuditTrailEntries();
  }

  handleObserver() {
    const { auditTrailEntriesCount, auditTrailEntries, loading } = this.props;
    const { offset } = this.state;

    if (auditTrailEntriesCount > auditTrailEntries.length && !loading) {
      this.setState({ offset: offset + 1 }, this.fetchAuditTrails);
    }
  }

  onOpenModal(trailId: number) {
    const setState = this.setState.bind(this);
    return () => {
      setState({
        modalOpen: true,
        selectedId: trailId,
      });
    };
  }

  toggleTimeFormat() {
    const { timeFormat } = this.state;
    this.setState({
      timeFormat: timeFormat === 'LLLL' ? 'From Now' : 'LLLL',
    });
  }

  fetchAuditTrails() {
    const { entityIDs, retrieveAuditTrailEntries } = this.props;
    const { limit, offset } = this.state;

    retrieveAuditTrailEntries({
      limit,
      offset,
      entity_ids: entityIDs,
    });
  }

  closeModal() {
    this.setState({ modalOpen: false });
  }

  render() {
    const { auditTrailEntries, auditTrailEntriesCount, loading } = this.props;
    const { timeFormat, modalOpen, selectedId } = this.state;

    if (!loading && auditTrailEntriesCount === 0) {
      return null;
    }

    return (
      <>
        {modalOpen && selectedId >= 0 && (
          <AuditTrailModal handleClose={this.closeModal} id={`${selectedId}`} />
        )}
        Audit Trail
        <div>
          <Timeline style={inlineStyles.timeline.main}>
            {auditTrailEntries.map((trail) => {
              if (!trail) {
                return null;
              }

              const { iconColor, iconName, trailTitleString } =
                renderAuditTrailIconStyle(trail.action_type);
              const onOpenModal = trail.use_modal
                ? this.onOpenModal(trail.id)
                : emptyFn;
              return (
                <div key={`div-${trail.id}`}>
                  <TimelineEvent
                    key={`timeline-event-${trail.id}`}
                    title={trailTitleString}
                    icon={
                      <Icon name={iconName as SemanticICONS} size="large" />
                    }
                    iconColor={iconColor}
                    style={inlineStyles.timeline.main}
                    titleStyle={inlineStyles.timeline.title}
                    subtitleStyle={inlineStyles.timeline.subtitle}
                    iconStyle={inlineStyles.timeline.icon}
                    bubbleStyle={inlineStyles.timeline.bubble}
                    createdAt={
                      timeFormat === 'From Now'
                        ? getTimeFromNow(trail.created_at)
                        : getLocalFormat(trail.created_at, 'LLLL')
                    }
                    onClick={this.toggleTimeFormat}
                    onIconClick={onOpenModal}
                  >
                    {trail.use_modal && (
                      <Icon
                        name="info circle"
                        onClick={onOpenModal}
                        className={styles.pressableIcon}
                      />
                    )}
                    <span className={styles.timelineContent}>
                      {trail.content}
                    </span>
                  </TimelineEvent>
                </div>
              );
            })}

            <div
              className={`${styles.loading} ${
                auditTrailEntries.length < auditTrailEntriesCount
                  ? ''
                  : styles.hidden
              }`}
              ref={this.loadingRef}
            >
              <Loading />
            </div>
          </Timeline>
        </div>
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaginatedAuditTrailSection);

// Using inline styles because TimelineEvent does not receives classNames for the different sections
const inlineStyles: StyleMap = {
  timeline: {
    title: {
      fontSize: '1rem',
      fontWeight: StyleConstants.CONTENT_BOLD_FONT_WEIGHT,
      opacity: 0.7,
    },
    subtitle: {
      fontSize: '1rem',
      fontWeight: StyleConstants.CONTENT_BODY_FONT_WEIGHT,
    },
    icon: {
      marginTop: '-2px',
      marginLeft: '3px',
    },
    bubble: {
      left: '-1px',
      width: 'unset',
      height: '60px',
      background: 'white',
      border: 'unset',
    },
  },
};
