import { ReactNode } from 'react';

import styles from 'app/modules/notes/styles/NoteSection.module.scss';
import { SessionAgent } from 'app/modules/session/models';

export const NoteContainer = ({
  author,
  children,
}: {
  author?: SessionAgent;
  children: ReactNode;
}) => {
  return (
    <div className={styles.noteContainer}>
      {author && (
        <img alt="Avatar" className={styles.noteAvatar} src={author.picture} />
      )}
      {children}
    </div>
  );
};

export default NoteContainer;
