import React, { ReactNode } from 'react';
import { isEmpty, startCase, isPlainObject } from 'lodash';
import moment from 'moment';

// Components
import { Table } from 'semantic-ui-react';

// Constants
import {
  DATE_FORMAT_MILITARY_TIME,
  BE_DATE_FORMAT,
} from 'app/shared/constants';
import { LabelWithDescription } from 'app/modules/dataSettings/types';

// Utils
import { isValidHttpUrl } from 'app/shared/utils/isValidHttpUrl';

// Styles
import styles from 'app/shared/styles/CustomDataTable.module.scss';
import { U21HelpTooltip, U21Spacer } from 'app/shared/u21-ui/components';
import styled from 'styled-components';

interface OwnProps {
  title?: string;
  data?: {
    [key: string]: any;
  } | null;
  formatKeys?: boolean;
  keyLabelMap?: Record<string, string | LabelWithDescription>;
}

type AllProps = OwnProps;

const BE_DATE_FORMAT_REGEX = /^\d{4}-\d{2}-\d{2}(\s\d{2}:\d{2}:\d{2})?$/;
const UNIX_TIMESTAMP_REGEX = /^\d{10}(\.\d{3})?$/;
const UNIX_TIMESTAMP_MIN = 10 ** 9;

function isDate(customValue: any): customValue is number | string {
  switch (typeof customValue) {
    case 'number':
      return (
        UNIX_TIMESTAMP_MIN < customValue &&
        Boolean(`${customValue}`.match(UNIX_TIMESTAMP_REGEX))
      );
    case 'string':
      return Boolean((customValue as string).match(BE_DATE_FORMAT_REGEX));
    default:
      return false;
  }
}

function formatDate(date: number | string) {
  const momentDate =
    typeof date === 'number'
      ? moment.unix(date)
      : moment(date, BE_DATE_FORMAT, true);

  // Ensure invalid dates are not returned as "Invalid date"
  // and default to using the date "as-is" if the date isn't valid.
  // https://momentjs.com/docs/#/parsing/is-valid/
  if (momentDate.isValid()) {
    return momentDate.format(DATE_FORMAT_MILITARY_TIME);
  }

  return date;
}

const renderValue = (value: any, key = '') => {
  switch (typeof value) {
    case 'boolean':
      return (
        <div className={styles.valueContainer} key={key}>
          <span>{String(value)}</span>
        </div>
      );
    case 'object':
      if (Array.isArray(value)) {
        return (
          <div className={styles.arrayContainer}>
            {(value as any[]).map((element, ix) =>
              renderValue(element, `${ix}`),
            )}
          </div>
        );
      }
      if (value === null) {
        return <div key={key} />;
      }
      return (
        <div key={key} className={styles.objectContainer}>
          <pre>{JSON.stringify(value, null, 1)}</pre>
        </div>
      );
    default:
      if (isValidHttpUrl(value)) {
        return (
          <div className={styles.valueContainer} key={key}>
            <a href={value}>{value}</a>
          </div>
        );
      }
      return (
        <div className={styles.valueContainer} key={key}>
          <StyledSpan>{isDate(value) ? formatDate(value) : value}</StyledSpan>
        </div>
      );
  }
};

function isArrayOfPlainObjects(arrObjs: any): arrObjs is object[] {
  if (Array.isArray(arrObjs) && arrObjs.length > 0) {
    return arrObjs.every((obj) => isPlainObject(obj) && obj !== null);
  }
  return false;
}

function renderPrimitivesObjectArray(arrObjs: object[]): ReactNode {
  return arrObjs.map((primitiveObject, ix) => {
    const pObjectKeys = Object.keys(primitiveObject);
    return (
      // eslint-disable-next-line react/no-array-index-key
      <Table.Row key={ix}>
        <Table.Cell collapsing className={styles.primitivesObjectCell}>
          {pObjectKeys.map((pKey) => (
            <p key={`${pKey}-sec`}>{startCase(pKey)}</p>
          ))}
        </Table.Cell>
        <Table.Cell className={styles.primitivesObjectCell}>
          {pObjectKeys.map((pKey) => (
            <p key={`${pKey}-val`}>{`${primitiveObject[pKey]}`}&nbsp;</p>
          ))}
        </Table.Cell>
      </Table.Row>
    );
  });
}

export const LabelCell = ({
  label,
}: {
  label: string | LabelWithDescription;
}) => {
  const fKey =
    typeof label === 'string' ? (
      label
    ) : (
      <U21Spacer horizontal>
        <span>{label.label}</span>
        <U21HelpTooltip help={label.description} />
      </U21Spacer>
    );

  return <Table.Cell collapsing>{fKey}</Table.Cell>;
};

const CustomDataTable = ({
  data,
  title,
  formatKeys = true,
  keyLabelMap = {},
}: AllProps) => {
  if (!data || isEmpty(data)) {
    return null;
  }
  return (
    <Table celled striped stackable className={styles.table}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan="16">
            {title || 'Custom Data'}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {Object.keys(data).map((key: string) => {
          const value = data[key];
          const fKey =
            keyLabelMap?.[key] ?? (formatKeys ? startCase(key) : key);

          // Special case for arrays of objects without nesting
          if (isArrayOfPlainObjects(value)) {
            return (
              <Table.Row key={key}>
                <LabelCell label={fKey} />
                <Table.Cell className={styles.nestedTableCell}>
                  <Table celled striped className={styles.nestedTable}>
                    <Table.Body>
                      {renderPrimitivesObjectArray(value)}
                    </Table.Body>
                  </Table>
                </Table.Cell>
              </Table.Row>
            );
          }

          return (
            <Table.Row key={key}>
              <LabelCell label={fKey} />
              <Table.Cell className={styles.tableCell}>
                {renderValue(value)}
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};

export default CustomDataTable;

const StyledSpan = styled('span')`
  white-space: pre-wrap;
`;
