import { TxnAnalysisPayload } from 'app/modules/txnAnalysis/requests';

const BASE = ['txnAnalysis'];

export const TXN_ANALYSIS_QUERY_KEYS = {
  getTxnAnalysisQueryKey: (payload: TxnAnalysisPayload) =>
    [...BASE, payload] as const,
  getTxnAnalysisStatsQueryKey: (
    payload: TxnAnalysisPayload,
    useCacheEndpoint: boolean,
  ) => [...BASE, 'stats', payload, useCacheEndpoint] as const,
};
