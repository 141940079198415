import React, { memo } from 'react';
import { Table } from 'semantic-ui-react';
import ReactPlaceholder from 'react-placeholder/lib';

interface OwnProps {
  numCells: number;
  numRows: number;
}

/**
 * @deprecated Use U21Table loading=true instead
 */
const LoadingTable = memo(({ numCells, numRows }: OwnProps) => {
  return (
    <>
      {[...Array(numRows + 1)].map((numR, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <Table.Row key={`loading-table-row-${numR}-${idx}`}>
          {[...Array(numCells)].map((numC, jdx) => (
            // eslint-disable-next-line react/no-array-index-key
            <Table.Cell key={`loading-table-cell-${numC}-${jdx}`}>
              <ReactPlaceholder
                showLoadingAnimation
                ready={false}
                style={{ height: '1.8em', paddingTop: '6px' }}
                type="text"
                rows={1}
              >
                placeholder {/* needs children to work */}
              </ReactPlaceholder>
            </Table.Cell>
          ))}
        </Table.Row>
      ))}
    </>
  );
});

export default LoadingTable;
