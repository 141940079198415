import moment from 'moment';

// update any packages used globally (like moment.js)
// preferring this over maintaining a patch so we can update versions without issues
export const globalPackageUpdates = () => {
  updateMoment();
};

// update moment.js default 'Invalid Date' str to an empty str
// solution comes from: https://stackoverflow.com/questions/38953566/override-the-moment-js-default-invalid-date-text
const updateMoment = () => {
  // we can update the invalid date in the future here (for now, empty str)
  const invalidDate = '';
  moment.updateLocale(moment.locale(), {
    invalidDate,
  });
};
