import React from 'react';
import { Icon, Label } from 'semantic-ui-react';

const DispositionLabel: React.FC<{
  useIcon?: boolean;
  resolution: string | null;
}> = ({ resolution, useIcon = true }) => {
  if (!resolution) {
    return null;
  }
  switch (resolution) {
    case 'FALSE_POSITIVE':
      return <Label color="red">FALSE POSITIVE</Label>;
    case 'TRUE_POSITIVE':
      return <Label color="green">TRUE POSITIVE</Label>;
    case 'UNRESOLVED':
      return useIcon ? (
        <Icon name="question" color="grey" />
      ) : (
        <Label color="grey">UNRESOLVED</Label>
      );
    default:
      return <Label color="grey">{resolution}</Label>;
  }
};
export default DispositionLabel;
