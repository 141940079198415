import {
  createContext,
  ReactElement,
  useContext,
  useMemo,
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import isEmpty from 'lodash/isEmpty';

import { selectFeatureFlags } from 'app/shared/featureFlags/selectors';
import { isAuth0Callback } from 'app/shared/helpers';

// Models
import { AuthClient } from 'app/modules/session/models';

// Constants
import { UNLOADED_FLAGS_KEY } from 'app/shared/featureFlags/constants';

// Auth Providers
import BaseAuthService from 'app/modules/session/authServices/baseAuthService';
import { Auth0Auth } from 'app/modules/session/authServices/auth0';
import { Unit21Auth } from 'app/modules/session/authServices/unit21';

interface AuthProviderProps {
  children: ReactElement;
}

interface AuthContextState {
  // The current client used for the session. This is what should be used for checking auth going forward. 99% of the time, only use authClient.
  authClient: AuthClient;

  // Setter for the `authClient` above. Should ONLY be used before a login has happened.
  setAuthClient: (authClient: AuthClient) => void;

  // auth0: The Auth0 auth client. (Should only be used for very specific reasons and only if a session is not logged in.)
  auth0: AuthClient;

  // The Unit21 auth client. (Should only be used for very specific reasons and only if a session is not logged in.)
  unit21: AuthClient;
}

const EMPTY_AUTH: AuthClient = new BaseAuthService();

export const AuthContext = createContext<AuthContextState>({
  authClient: EMPTY_AUTH,
  setAuthClient: () => {},
  auth0: EMPTY_AUTH,
  unit21: EMPTY_AUTH,
});

export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }: AuthProviderProps) => {
  const flags = useSelector(selectFeatureFlags);

  const [auth0] = useState(new Auth0Auth());
  const [unit21] = useState(new Unit21Auth());

  const [authClient, setAuthClient] = useState(EMPTY_AUTH);
  const [allowSetAuthClient, setAllowSetAuthClient] = useState(false);

  const { pathname, hash } = useLocation();
  const isUnit21LoginLink = /email-login/.test(pathname);

  useEffect(() => {
    if (isUnit21LoginLink || unit21.isAuthenticated()) {
      setAllowSetAuthClient(true);
      return;
    }

    auth0.renewSession(() => {
      setAllowSetAuthClient(true);
    });
  }, [auth0, isUnit21LoginLink, unit21]);

  useEffect(() => {
    if (isEmpty(flags) || flags[UNLOADED_FLAGS_KEY]) {
      setAuthClient(EMPTY_AUTH);
      return;
    }

    if (allowSetAuthClient) {
      if (isAuth0Callback(hash) || auth0.isAuthenticated()) {
        setAuthClient(auth0);
        return;
      }

      setAuthClient(unit21);
    }
  }, [flags, hash, allowSetAuthClient, auth0, unit21]);

  const state: AuthContextState = useMemo(
    () => ({ authClient, setAuthClient, auth0, unit21 }),
    [authClient, auth0, unit21],
  );

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
