import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

// Components
import CustomChecklistContent from 'app/shared/customChecklist/components/CustomChecklistContent';
import {
  U21Section,
  U21Form,
  U21Spacer,
  U21Chip,
} from 'app/shared/u21-ui/components';

// Selectors
import {
  selectCustomChecklistDefinition,
  selectCustomChecklistContent,
  selectCustomChecklistValidations,
  selectCustomSubmissionId,
  selectCustomChecklistId,
  selectCustomChecklistHistory,
  selectCustomChecklistName,
  selectCustomChecklistModifiedAt,
  selectCustomChecklistArchived,
} from 'app/shared/customChecklist/selectors';
import { selectAiInvestigationsOrgFeatureEnabled } from 'app/modules/orgSettings/selectors';

// Models
import {
  CustomChecklistContent as CustomChecklistContentType,
  CustomChecklistValidations,
} from 'app/shared/customChecklist/models';
import {
  Investigation,
  InvestigationStatus,
  InvestigationType,
} from 'app/modules/investigations/models';

// Mutations
import { useAiInvestigationResults } from 'app/modules/investigations/queries/useAiInvestigationResults';

// Helpers
import {
  flattenFormValues,
  getChecklistFields,
  prepareInitialValues,
} from 'app/shared/customChecklist/helpers';

// Utils
import { formatDistanceToNow } from 'date-fns';
import { formatDatetime as formatDatetimeHelper } from 'app/shared/utils/date';
import { upsertInvestigationChecklistSubmissionThunk } from 'app/modules/investigationChecklist/sliceInvestigationChecklist';
import { useAppDispatch } from 'app/store/storeHooks';
import { useQueryClient } from '@tanstack/react-query';
import { CHECKLIST_QUERY_KEYS } from 'app/shared/customChecklist/queries/keys';

interface OwnProps {
  article: Investigation;
  editable: boolean;
  isExport?: boolean;
  isSidePanel?: boolean;
  type: InvestigationType;
}

function CustomChecklistContainer({
  article,
  editable,
  isExport,
  isSidePanel,
  type,
}: OwnProps) {
  const dispatch = useAppDispatch();

  const definition = useSelector(selectCustomChecklistDefinition);

  const checklistId: number = useSelector(selectCustomChecklistId);
  const checklistName: string = useSelector(selectCustomChecklistName);
  const checklistModifiedAt: string = useSelector(
    selectCustomChecklistModifiedAt,
  );

  const submissionHistory = useSelector(selectCustomChecklistHistory);

  const submissionId: number | undefined = useSelector(
    selectCustomSubmissionId,
  );

  const content: CustomChecklistContentType = useSelector(
    selectCustomChecklistContent,
  );

  const validationResults: CustomChecklistValidations = useSelector(
    selectCustomChecklistValidations,
  );

  const isArchived: boolean = useSelector(selectCustomChecklistArchived);

  const aiInvestigationsOrgFeatureEnabled: boolean = useSelector(
    selectAiInvestigationsOrgFeatureEnabled,
  );

  const queryClient = useQueryClient();
  const { data: aiInvestigationResults } = useAiInvestigationResults(type, {
    articleId: article.id,
    customChecklistSubmissionId: submissionId,
  });
  const subheader = useMemo(() => {
    const queueTitle = article['queue.title'];

    if (!checklistModifiedAt) return queueTitle;

    const modifiedAt = isExport
      ? formatDatetimeHelper(new Date(checklistModifiedAt))
      : formatDistanceToNow(new Date(checklistModifiedAt), {
          addSuffix: true,
        });

    if (!queueTitle) return modifiedAt;

    return [queueTitle, modifiedAt].join(' | ');
  }, [article, checklistModifiedAt, isExport]);

  const handleSaveData = async (values: Record<string, any>) => {
    const checklistContent = flattenFormValues(values);

    try {
      await dispatch(
        upsertInvestigationChecklistSubmissionThunk({
          checklist_id: checklistId,
          article_id: article.id,
          content: checklistContent,
        }),
      ).unwrap();
      queryClient.invalidateQueries({
        queryKey: CHECKLIST_QUERY_KEYS.getChecklistSubmission(article.id),
      });
    } catch {}
  };

  const historicalInvestiationResultsByID = useMemo(
    () =>
      aiInvestigationResults?.historical_ai_investigations_results?.reduce(
        (acc, i) => ({
          ...acc,
          [i.custom_checklist_submission_id]: i,
        }),
        {},
      ) ?? {},
    [aiInvestigationResults?.historical_ai_investigations_results],
  );

  const flatChecklistDefinition = useMemo(
    // flatChecklistDefinition is created by recursively pulling out fields from nested form definition
    () => getChecklistFields(definition || []),
    [definition],
  );
  const initialValues = useMemo(() => {
    // flatChecklistDefinition is used to pull relevant data out of content
    // If flatChecklistDefinition isn't yet defined, we cannot prepare initial values
    if (!flatChecklistDefinition) return {};
    return prepareInitialValues(content, flatChecklistDefinition);
  }, [flatChecklistDefinition, content]);
  const historicalSubmissions = useMemo(
    () =>
      (submissionHistory || []).map(
        ({
          id,
          content: submissionContent,
          custom_checklist: {
            definition: checklistDefinition,
            name: historicalChecklistName,
            archived: historicalChecklistArchived,
          },
          alert_queue: { title: queueTitle },
          modified_at: modifiedAtString,
        }) => {
          const flatDefinition = getChecklistFields(checklistDefinition || []);
          const submissionModifiedAt = isExport
            ? formatDatetimeHelper(modifiedAtString)
            : formatDistanceToNow(new Date(modifiedAtString), {
                addSuffix: true,
              });
          const submissionSubheader = [
            queueTitle || 'Archived',
            submissionModifiedAt,
          ]
            .filter((_) => _)
            .join(' | ');
          return {
            id,
            checklistContent: prepareInitialValues(
              submissionContent,
              flatDefinition,
            ),
            checklistDefinition,
            checklistName: historicalChecklistName,
            checklistArchived: historicalChecklistArchived,
            submissionSubheader,
          };
        },
      ),
    [isExport, submissionHistory],
  );

  if (historicalSubmissions.length === 0 && !definition) {
    return null;
  }
  return (
    <U21Spacer>
      {(((article.status === InvestigationStatus.OPEN ||
        article.status === InvestigationStatus.IN_REVIEW) &&
        definition) ||
        (article.status === InvestigationStatus.CLOSED &&
          definition &&
          (historicalSubmissions.length === 0 ||
            (content && Object.keys(content).length > 0) ||
            (aiInvestigationsOrgFeatureEnabled &&
              aiInvestigationResults?.current_ai_investigations_results
                .results)))) && (
        <U21Section
          collapsible
          title={checklistName}
          subheader={
            <U21Spacer horizontal useFlexGap>
              {subheader}
              <U21Chip color={isArchived ? 'error' : 'success'}>
                {isArchived ? 'Archived' : 'Active'}
              </U21Chip>
            </U21Spacer>
          }
        >
          <U21Form
            autoSave
            initialValues={initialValues}
            onSubmit={handleSaveData}
          >
            {({ values }) => {
              return (
                <CustomChecklistContent
                  aiInvestigationResultItems={
                    aiInvestigationsOrgFeatureEnabled
                      ? aiInvestigationResults
                          ?.current_ai_investigations_results.results?.sections
                      : undefined
                  }
                  definition={definition}
                  validationResults={editable ? validationResults : null}
                  previouslySaved={!!submissionId}
                  editable={editable}
                  content={values}
                  isExport={isExport}
                  isSidePanel={isSidePanel}
                />
              );
            }}
          </U21Form>
        </U21Section>
      )}
      {historicalSubmissions?.map(
        ({
          checklistContent,
          checklistDefinition,
          checklistName: historicalChecklistName,
          checklistArchived: historicalChecklistArchived,
          id,
          submissionSubheader,
        }) => {
          const historicalSubmission = historicalInvestiationResultsByID[id];

          return (
            <U21Section
              key={id}
              collapsible
              collapsed={!isExport}
              title={historicalChecklistName}
              subheader={
                <U21Spacer horizontal useFlexGap>
                  {submissionSubheader}
                  <U21Chip
                    color={historicalChecklistArchived ? 'error' : 'success'}
                  >
                    {historicalChecklistArchived ? 'Archived' : 'Active'}
                  </U21Chip>
                </U21Spacer>
              }
            >
              <U21Form
                disabled
                initialValues={checklistContent}
                onSubmit={() => {}}
              >
                <CustomChecklistContent
                  articleId={article.id}
                  historicalSubmissionId={
                    historicalSubmission?.custom_checklist_submission_id
                  }
                  aiInvestigationResultItems={
                    aiInvestigationsOrgFeatureEnabled
                      ? historicalSubmission?.results?.sections
                      : undefined
                  }
                  definition={checklistDefinition}
                  content={checklistContent}
                  validationResults={null}
                  previouslySaved={false}
                  editable={false}
                  isSidePanel={isSidePanel}
                />
              </U21Form>
            </U21Section>
          );
        },
      )}
    </U21Spacer>
  );
}

export default CustomChecklistContainer;
