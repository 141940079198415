import { GoogleDriveDoc } from 'app/modules/uploads/models';

import routes from 'app/shared/utils/routes';

import { selectIDP } from 'app/modules/session/selectors';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import getFromProcessEnv from 'app/shared/utils/getFromProcessEnv';
import styled from 'styled-components';

import GDriveSvg from 'app/shared/svg/gdrive.svg';
import { U21Button } from 'app/shared/u21-ui/components';

const GOOGLE_SDK_URL = 'https://apis.google.com/js/api.js';

interface Props {
  onPick: (docs: GoogleDriveDoc[]) => void;
}

export const GoogleDrivePicker = (props: Props) => {
  const { onPick } = props;
  const { access_token: accessToken } = useSelector(selectIDP);
  const [initialized, setInitialized] = useState(
    Boolean(window.google?.picker),
  );
  const pickerRef = useRef<google.picker.Picker>();

  useEffect(() => {
    if (!window.google?.picker) {
      const script = document.createElement('script');
      script.src = GOOGLE_SDK_URL;
      script.onload = () => {
        window.gapi?.load('picker:client', {
          callback: () => {
            window.gapi?.client?.init({
              discoveryDocs: [
                'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
              ],
              clientId: getFromProcessEnv('REACT_APP_GOOGLE_CLIENT_ID') || '',
              scope: 'https://www.googleapis.com/auth/drive',
            });
            setInitialized(true);
          },
          onerror: () => {},
          ontimeout: () => {},
          timeout: 5000,
        });
      };
      document.body.appendChild(script);
    }
  }, []);

  useEffect(() => pickerRef.current?.dispose, []);

  if (!initialized) {
    return null;
  }

  return (
    <U21Button
      endIcon={
        <SVGContainer>
          <GDriveSvg />
        </SVGContainer>
      }
      onClick={() => {
        if (window.google?.picker) {
          // could theoretically use the existing picker and setVisible but
          // there's some issue with preserving a previous pick after picking
          // a new file. Creating a new picker instance works around this
          pickerRef.current?.dispose();
          const gpicker = window.google.picker;
          const view = new gpicker.DocsView(gpicker.ViewId.DOCS);

          // fix 403's on Google Drive preview images
          // https://github.com/u21/lumos/issues/278
          view.setMode(gpicker.DocsViewMode.LIST);

          const pickerBuilder = new gpicker.PickerBuilder()
            .setAppId(getFromProcessEnv('REACT_APP_GOOGLE_PROJECT_NUMBER'))
            .setOAuthToken(accessToken)
            .addView(view)
            .setCallback((data) => {
              if (data.action === gpicker.Action.PICKED) {
                const gdriveDocs =
                  data.docs?.map<GoogleDriveDoc>((i) => ({
                    description: i.description,
                    embedUrl: i.embedUrl ?? '',
                    iconUrl: i.iconUrl ?? '',
                    id: i.id,
                    isExternalDoc: false,
                    lastEditedUtc: i.lastEditedUtc ?? 0,
                    mimeType: i.mimeType ?? '',
                    name: i.name ?? '',
                    parentUrl: i.parentId
                      ? `${routes.external.googleDrive.folderPrefix}${i.parentId}`
                      : '',
                    serviceId: i.serviceId,
                    type: i.type,
                    url: i.url ?? '',
                  })) ?? [];
                onPick(gdriveDocs);
              }
            });

          // Enabling shared drive support
          // https://developers.google.com/picker/docs/reference#Feature.SUPPORT_DRIVES
          const sharedDriveView = new gpicker.DocsView(gpicker.ViewId.DOCS);
          sharedDriveView.setEnableDrives(true);
          sharedDriveView.setMode(gpicker.DocsViewMode.LIST);
          pickerBuilder.enableFeature(gpicker.Feature.SUPPORT_DRIVES);
          pickerBuilder.addView(sharedDriveView);

          pickerBuilder.enableFeature(gpicker.Feature.MULTISELECT_ENABLED);
          const picker = pickerBuilder.build();
          picker.setVisible(true);
          pickerRef.current = picker;
        }
      }}
    >
      Select from Google Drive
    </U21Button>
  );
};

const SVGContainer = styled.div`
  display: flex;
  height: 20px;
`;
