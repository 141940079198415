import { ReactNode } from 'react';

import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import { RuntimeErrorFallback } from 'app/components/RuntimeErrorFallback';

interface Props {
  children: ReactNode;
}

// wrapper is needed to React is rendered so RuntimeErrorFallback can use hooks
const RuntimeErrorFallbackWrapper = (props) => (
  <RuntimeErrorFallback {...props} />
);

export const ErrorBoundary = ({ children }: Props) => {
  return (
    <SentryErrorBoundary fallback={RuntimeErrorFallbackWrapper} showDialog>
      {children}
    </SentryErrorBoundary>
  );
};
