import { createSelector } from 'reselect';

// Models
import { InvestigationType } from 'app/modules/investigations/models';

// Selectors
import {
  selectHasEditAlertsPermission,
  selectHasEditCasesPermission,
} from 'app/modules/session/selectors';

export const selectHasEditInvestigationPermission = (type: InvestigationType) =>
  createSelector(
    selectHasEditAlertsPermission,
    selectHasEditCasesPermission,
    (hasEditAlertsPermission, hasEditCasesPermission) =>
      type === InvestigationType.ALERT
        ? hasEditAlertsPermission
        : hasEditCasesPermission,
  );
