import { NotificationEntityType } from 'app/modules/notifications/models';

import {
  IconBriefcase,
  IconBuildingBank,
  IconClipboardList,
  IconFlag,
  IconScale,
  IconUser,
  IconFileExport,
  IconCloudUpload,
} from '@u21/tabler-icons';

export const NOTIFICATION_ICON_MAPPING = {
  [NotificationEntityType.CASE]: <IconBriefcase />,
  [NotificationEntityType.ALERT]: <IconFlag />,
  [NotificationEntityType.ALERT_QUEUE]: <IconClipboardList />,
  [NotificationEntityType.PROFILE]: <IconUser />,
  [NotificationEntityType.RULE]: <IconScale />,
  [NotificationEntityType.SAR]: <IconBuildingBank />,
  [NotificationEntityType.FILE_EXPORT]: <IconFileExport />,
  [NotificationEntityType.FFIP_NOTIFICATION]: <IconCloudUpload />,
};
