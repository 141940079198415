import { NAVIGATOR_QUERY_KEYS } from 'app/modules/navigator/queries/keys';

import { get } from 'app/shared/utils/fetchr';
import { selectOutdatedIndicatorEnabled } from 'app/shared/featureFlags/selectors';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

interface AppVersionResponse {
  version: string;
}

const getAppVersion = (): Promise<AppVersionResponse> => get('/app-version');

export const useAppVersionChanged = () => {
  const versionRef = useRef<string>('');
  const enabled = useSelector(selectOutdatedIndicatorEnabled);

  const { data } = useQuery({
    enabled,
    initialData: { version: '' },
    queryFn: () => getAppVersion(),
    queryKey: NAVIGATOR_QUERY_KEYS.getVersion(),
    refetchInterval: (query) => {
      if (query.state.data?.version !== versionRef.current) {
        return false;
      }
      return 5 * 60 * 1000;
    },
  });

  useEffect(() => {
    if (!versionRef.current && data?.version) {
      versionRef.current = data.version;
    }
  }, [data]);

  return !versionRef.current || !data?.version
    ? false
    : data.version !== versionRef.current;
};
