import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  U21Card,
  U21Grid,
  U21Loading,
  U21NoData,
  U21InfoItem,
  U21NoValue,
  U21Spacer,
} from 'app/shared/u21-ui/components';
import { IconListSearch, IconUserCircle } from '@u21/tabler-icons';

import {
  EntityNativeFields,
  NativeDataSettingsConfigResponse,
} from 'app/modules/dataSettings/responses';
import { DataSettingDataDisplay } from 'app/modules/dataSettings/shared/DataSettingDataDisplay';
import { DataSettingDataLabel } from 'app/modules/dataSettings/shared/DataSettingDataLabel';
import { isFieldEmpty } from 'app/modules/dataSettings/utils';
import { ShortEntityResponse } from 'app/modules/entities/types/responses';
import { selectEntityDataSettingsByNativeKey } from 'app/modules/dataSettings/selectors';

import {
  FinCEN314aPersonScanResultMatchDetails,
  FinCEN314aBusinessScanResultMatchDetails,
} from 'app/modules/lists/responses';

const PERSON_ENTITY_FIELDS: EntityNativeFields[] = [
  EntityNativeFields.LAST_NAME,
  EntityNativeFields.FIRST_NAME,
  EntityNativeFields.MIDDLE_NAME,
];

const BUSINESS_ENTITY_FIELDS: EntityNativeFields[] = [
  EntityNativeFields.BUSINESS_NAME,
  EntityNativeFields.DOING_BUSINESS_AS,
];

const EntityCard = ({
  dataSettings,
  entity,
  fields,
  isEmptyFieldShown,
  title,
}: {
  dataSettings: Record<string, NativeDataSettingsConfigResponse | undefined>;
  entity: ShortEntityResponse;
  fields: EntityNativeFields[];
  isEmptyFieldShown: boolean;
  title: string;
}) => (
  <U21Card title={title} subheader="Unit21 Entity" icon={<IconUserCircle />}>
    <U21Spacer dividers>
      {fields.map((key) => {
        const dataSetting = dataSettings[key];
        const isEmpty =
          dataSetting === undefined ||
          isFieldEmpty(entity.formatted_data[dataSetting.id]);

        if (isEmptyFieldShown || !isEmpty) {
          return (
            <U21InfoItem
              key={key}
              label={
                dataSetting ? (
                  <StyledDataSettingDataLabel dataSetting={dataSetting} />
                ) : (
                  <U21NoValue />
                )
              }
              width={150}
            >
              <DataSettingDataDisplay
                dataSetting={dataSetting}
                formattedData={entity.formatted_data}
              />
            </U21InfoItem>
          );
        }
        return null;
      })}
    </U21Spacer>
  </U21Card>
);

const FinCEN314aPersonFileRowCard = ({
  isEmptyFieldShown,
  matchDetails,
}: {
  isEmptyFieldShown: boolean;
  matchDetails: FinCEN314aPersonScanResultMatchDetails;
}) => {
  const title = [
    matchDetails.first_name,
    matchDetails.middle_name,
    matchDetails.last_name,
    matchDetails.suffix,
  ].join(' ');
  const items: { label: string; value: string | null }[] = [
    { label: 'Tracking number', value: matchDetails.tracking_number },
    { label: 'Last name', value: matchDetails.last_name },
    { label: 'First name', value: matchDetails.first_name },
    { label: 'Middle name', value: matchDetails.middle_name },
    { label: 'Suffix', value: matchDetails.suffix },
    { label: 'Alias last name', value: matchDetails.alias_last_name },
    { label: 'Alias first name', value: matchDetails.alias_first_name },
    { label: 'Alias middle name', value: matchDetails.alias_middle_name },
    { label: 'Alias suffix', value: matchDetails.alias_suffix },
    { label: 'Number', value: matchDetails.number },
    { label: 'Number type', value: matchDetails.number_type },
    { label: 'Date of birth', value: matchDetails.dob },
    { label: 'Street', value: matchDetails.street },
    { label: 'City', value: matchDetails.city },
    { label: 'State', value: matchDetails.state },
    { label: 'Zip', value: matchDetails.zip },
    { label: 'Country', value: matchDetails.country },
    { label: 'Phone number', value: matchDetails.phone },
  ];

  return (
    <StyledCard
      title={title}
      subheader="FinCEN 314(a) matchlist record"
      icon={<IconListSearch />}
    >
      <U21Spacer dividers>
        {items.map(({ label, value }) => {
          if (isEmptyFieldShown || value !== null) {
            return (
              <U21InfoItem key={label} label={label} width={150}>
                {value ?? <U21NoValue />}
              </U21InfoItem>
            );
          }
          return null;
        })}
      </U21Spacer>
    </StyledCard>
  );
};

const FinCEN314aBusinessFileRowCard = ({
  isEmptyFieldShown,
  matchDetails,
}: {
  isEmptyFieldShown: boolean;
  matchDetails: FinCEN314aBusinessScanResultMatchDetails;
}) => {
  const title = matchDetails.business_name ?? matchDetails.dba_name;
  const items: { label: string; value: string | null }[] = [
    { label: 'Tracking number', value: matchDetails.tracking_number },
    { label: 'Business name', value: matchDetails.business_name },
    { label: 'DBA name', value: matchDetails.dba_name },
    { label: 'Number', value: matchDetails.number },
    { label: 'Number type', value: matchDetails.number_type },
    { label: 'Incorporated', value: matchDetails.incorporated },
    { label: 'Street', value: matchDetails.street },
    { label: 'City', value: matchDetails.city },
    { label: 'State', value: matchDetails.state },
    { label: 'Zip', value: matchDetails.zip },
    { label: 'Country', value: matchDetails.country },
    { label: 'Phone number', value: matchDetails.phone },
  ];

  return (
    <StyledCard
      title={title}
      subheader="FinCEN 314(a) matchlist record"
      icon={<IconListSearch />}
    >
      <U21Spacer dividers>
        {items.map(({ label, value }) => {
          if (isEmptyFieldShown || value !== null) {
            return (
              <U21InfoItem key={label} label={label} width={150}>
                {value ?? <U21NoValue />}
              </U21InfoItem>
            );
          }
          return null;
        })}
      </U21Spacer>
    </StyledCard>
  );
};

export const FinCEN314aMatch = ({
  entity,
  isEmptyFieldShown,
  isLoading,
  matchDetails,
}: {
  entity: ShortEntityResponse | undefined;
  isEmptyFieldShown: boolean;
  isLoading: boolean;
  matchDetails:
    | FinCEN314aPersonScanResultMatchDetails
    | FinCEN314aBusinessScanResultMatchDetails
    | undefined;
}) => {
  const entityDataSettingsByNativeKey = useSelector(
    selectEntityDataSettingsByNativeKey,
  );

  if (isLoading) {
    return <U21Loading loading />;
  }

  if (entity === undefined) {
    return <U21NoData>There is no entity.</U21NoData>;
  }

  if (matchDetails === undefined) {
    return <U21NoData>There is no match.</U21NoData>;
  }

  if (
    'first_name' in matchDetails // TO DO: Better differentation of file types
  ) {
    return (
      <U21Spacer>
        <U21Grid columns={2} spacing={1}>
          <EntityCard
            dataSettings={entityDataSettingsByNativeKey}
            entity={entity}
            fields={PERSON_ENTITY_FIELDS}
            isEmptyFieldShown={isEmptyFieldShown}
            title={entity.name_readable}
          />
          <FinCEN314aPersonFileRowCard
            isEmptyFieldShown={isEmptyFieldShown}
            matchDetails={matchDetails}
          />
        </U21Grid>
      </U21Spacer>
    );
  }

  return (
    <U21Spacer>
      <U21Grid columns={2} spacing={1}>
        {/* TO DO: Set better title for business entity card */}
        <EntityCard
          dataSettings={entityDataSettingsByNativeKey}
          entity={entity}
          fields={BUSINESS_ENTITY_FIELDS}
          isEmptyFieldShown={isEmptyFieldShown}
          title={entity.name_readable}
        />
        <FinCEN314aBusinessFileRowCard
          isEmptyFieldShown={isEmptyFieldShown}
          matchDetails={matchDetails}
        />
      </U21Grid>
    </U21Spacer>
  );
};

// Needed to ensure subheader has ellipsis when page is too small
const StyledCard = styled(U21Card)`
  & .MuiCardHeader-content {
    width: 100%;
  }
`;

const StyledDataSettingDataLabel = styled(DataSettingDataLabel).attrs({
  variant: 'body2',
  color: 'text.secondary',
})`
  margin-top: 2px;
`;
