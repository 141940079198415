// Models
import { EventActionTypes } from 'app/shared/events/models';

// Constants
import { initialState } from 'app/shared/events/reducer';
import { createSelector } from 'reselect';

export const selectEventFilters = (state: RootState) => {
  return state.eventFilters || { ...initialState };
};

export const selectEvaluationLog = (state: RootState) => {
  return state.sliceEvents.evaluationLog;
};

export const selectActionEvents = (state: RootState) => {
  return state.eventFilters.actionEvents;
};

export const selectActionEventsData = createSelector(
  selectActionEvents,
  (actionEvents) => actionEvents.action_events,
);

export const selectActionEventsCount = createSelector(
  selectActionEvents,
  (actionEvents) => actionEvents.count,
);

// Loading
export const selectEditEventLoading = (state: RootState) =>
  Boolean(state.loading[EventActionTypes.EDIT_EVENT]);

export const selectActionEventsLoading = (state: RootState) =>
  Boolean(state.loading[EventActionTypes.RETRIEVE_ACTION_EVENTS]);
