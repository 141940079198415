import { ShortAlertResponse } from 'app/modules/alerts/types/responses';

import { ENTITY_QUERY_KEYS } from 'app/modules/entitiesRefresh/queries/keys';

import { post } from 'app/shared/utils/fetchr';
import { useQuery } from '@tanstack/react-query';
import { PaginationPayload } from 'app/shared/pagination/models';
import {
  selectHasReadAlertsPermission,
  selectHasReadEntitiesPermissions,
} from 'app/modules/session/selectors';
import { useSelector } from 'react-redux';

export const useEntityAlerts = (
  entityExternalId: string,
  payload: PaginationPayload,
) => {
  const hasReadAlertsPermission = useSelector(selectHasReadAlertsPermission);
  const hasReadEntityPermission = useSelector(selectHasReadEntitiesPermissions);
  return useQuery({
    queryFn: () =>
      post<{ count: number; alerts: ShortAlertResponse[] }>(
        `/entities/${encodeURIComponent(entityExternalId)}/paginated-alerts`,
        payload,
      ),
    queryKey: ENTITY_QUERY_KEYS.getEntityAlerts({
      entityExternalId,
      ...payload,
    }),
    enabled:
      Boolean(entityExternalId) &&
      hasReadAlertsPermission &&
      hasReadEntityPermission,
  });
};
