import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import routes, {
  getRouteName,
  NOT_ALLOWED_BACK_ROUTES,
} from 'app/shared/utils/routes';

// Selectors
import { selectRoutingHistory } from 'app/modules/navigator/selectors';

const FourOhFour = () => {
  const routingHistory = useSelector(selectRoutingHistory);

  const route = useMemo(() => {
    const validRoute = {
      path: '',
      name: '',
    };

    for (const path of routingHistory) {
      const routeName = getRouteName(path);
      if (!NOT_ALLOWED_BACK_ROUTES[path] && routeName) {
        validRoute.path = path;
        validRoute.name = routeName;
        break;
      }
    }

    return validRoute;
  }, [routingHistory]);

  return (
    <div style={styles.container}>
      <h1>
        404
        <br />
        Page not found
      </h1>
      {route.path ? (
        <Link to={route.path}>Go back to {route.name}</Link>
      ) : (
        <Link to={routes.lumos.home}>Take me home</Link>
      )}
    </div>
  );
};

const styles: StyleMap = {
  container: {
    textAlign: 'center',
    marginTop: '100px',
  },
};

export default FourOhFour;
