import { useMemo } from 'react';

import {
  createU21FilterOptions,
  U21MultiSelect,
  U21MultiSelectProps,
  U21SelectOptionProps,
} from 'app/shared/u21-ui/components';

type TValue = number | string;

export interface CreateableMultiSelectProps<TCreatable extends boolean = true>
  extends U21MultiSelectProps<TValue, TCreatable> {
  creatable?: boolean;
}

export const CreatableMultiSelect = <TCreatable extends boolean = true>(
  props: CreateableMultiSelectProps<TCreatable>,
) => {
  const { creatable, options: optionsProp, value = [], ...rest } = props;
  const options = useMemo(() => {
    if (!creatable) {
      return optionsProp;
    }

    const optionValueSet = new Set(
      optionsProp
        .filter((i): i is U21SelectOptionProps<TValue> => !i.optionType)
        .map((i) => i.value),
    );
    const createdOptions: U21SelectOptionProps<TValue>[] = value
      .filter((i) => !optionValueSet.has(i))
      .map((i) => ({ text: i, value: i }));

    return [...optionsProp, ...createdOptions];
  }, [creatable, optionsProp, value]);

  return (
    <U21MultiSelect
      options={options}
      value={value}
      filterOptions={(selectOptions, state) => {
        const { inputValue } = state;
        const filteredOptions = createU21FilterOptions<TValue>(
          selectOptions,
          state,
        );
        if (filteredOptions.length > 0 || !inputValue) {
          return filteredOptions;
        }
        return [
          {
            value: inputValue,
            text: `Add value: ${inputValue}`,
          },
        ];
      }}
      noOptionsText="Type to add a value..."
      {...rest}
    />
  );
};
