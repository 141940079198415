import {
  FilterOperatorAndValue,
  FilterOption,
} from 'app/modules/filters/models';
import styled from 'styled-components';

import {
  FILTER_TYPE_OPERATOR_LABELS,
  FILTER_OPERATOR,
} from 'app/modules/filters/constants';

import { formatDate } from 'app/shared/utils/date';

import { FilterMoreDisplay } from 'app/modules/filters/components/FilterMoreDisplay';
import { U21Tooltip } from 'app/shared/u21-ui/components';

interface Props {
  operatorAndValue: FilterOperatorAndValue;
  option: FilterOption;
}

export const FilterDisplay = (props: Props) => {
  const {
    operatorAndValue,
    option: { customize, description, label },
  } = props;
  const { operator, value } = operatorAndValue;

  const CustomDisplay = customize?.[operator]?.Display;
  if (CustomDisplay) {
    return <CustomDisplay value={value} operator={operator} />;
  }

  const inputProps = customize?.[operator]?.inputProps;
  const operatorLabel = FILTER_TYPE_OPERATOR_LABELS[operator];
  switch (operator) {
    case FILTER_OPERATOR.CONTAINS_TEXT:
    case FILTER_OPERATOR.IS_GREATER_THAN_NUMBER:
    case FILTER_OPERATOR.IS_LESS_THAN_NUMBER:
    case FILTER_OPERATOR.IS_EXACT_TEXT:
    case FILTER_OPERATOR.IS_NOT_EXACT_TEXT:
      return (
        <StyledTruncatedChip>
          <U21Tooltip tooltip={description}>
            <StyledLabel>{label}</StyledLabel>
          </U21Tooltip>{' '}
          {operatorLabel} <b>{value}</b>
        </StyledTruncatedChip>
      );

    case FILTER_OPERATOR.IS_AFTER_DATE:
    case FILTER_OPERATOR.IS_BEFORE_DATE:
      return (
        <StyledTruncatedChip>
          <U21Tooltip tooltip={description}>
            <StyledLabel>{label}</StyledLabel>
          </U21Tooltip>{' '}
          {operatorLabel} <b>{formatDate(value)}</b>
        </StyledTruncatedChip>
      );

    case FILTER_OPERATOR.IS_BETWEEN_DATE: {
      const [start, end] = value;
      return (
        <StyledTruncatedChip>
          <U21Tooltip tooltip={description}>
            <StyledLabel>{label}</StyledLabel>
          </U21Tooltip>{' '}
          {operatorLabel} <b>{formatDate(start)}</b> and&nbsp;
          <b>{formatDate(end)}</b>
        </StyledTruncatedChip>
      );
    }

    case FILTER_OPERATOR.IS_BETWEEN_NUMBER:
    case FILTER_OPERATOR.IS_BETWEEN_SLIDER: {
      const [min, max] = value;
      return (
        <StyledTruncatedChip>
          <U21Tooltip tooltip={description}>
            <StyledLabel>{label}</StyledLabel>
          </U21Tooltip>{' '}
          {operatorLabel} <b>{min}</b> and <b>{max}</b>
        </StyledTruncatedChip>
      );
    }

    case FILTER_OPERATOR.IS: {
      const valueDisplay =
        inputProps?.options?.find((i) => i.value === value)?.text ?? value;
      return (
        <StyledTruncatedChip>
          <U21Tooltip tooltip={description}>
            <StyledLabel>{label}</StyledLabel>
          </U21Tooltip>{' '}
          {operatorLabel} <b>{valueDisplay}</b>
        </StyledTruncatedChip>
      );
    }

    case FILTER_OPERATOR.IS_ONE_OF:
    case FILTER_OPERATOR.IS_NOT_ONE_OF: {
      const valueLabelMap =
        inputProps?.options?.reduce(
          (acc, i) => ({
            ...acc,
            [i.value]: i.text,
          }),
          {},
        ) ?? {};
      const values = value.map((i) => valueLabelMap[i] ?? i);
      return (
        <StyledTruncatedChip>
          <U21Tooltip tooltip={description}>
            <StyledLabel>{label}</StyledLabel>
          </U21Tooltip>{' '}
          {operatorLabel} <FilterMoreDisplay values={values} />
        </StyledTruncatedChip>
      );
    }

    case FILTER_OPERATOR.IS_TRUE:
    case FILTER_OPERATOR.IS_FALSE:
    case FILTER_OPERATOR.IS_EMPTY:
    case FILTER_OPERATOR.IS_NOT_EMPTY:
      return (
        <StyledTruncatedChip>
          <U21Tooltip tooltip={description}>
            <StyledLabel>{label}</StyledLabel>
          </U21Tooltip>{' '}
          {operatorLabel}
        </StyledTruncatedChip>
      );

    default:
      return <>{`Failed to display ${label} value`}</>;
  }
};

// This prevents the "chip" from allowing the label to grow too large
const StyledLabel = styled.b`
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

/*
 * This keeps the items inside the overall chip vertically aligned with one another.
 * Changing to flex layout is necessary because the default `display: inline` does
 * not properly align the items with text having a shared "center line" through them.
 */
const StyledTruncatedChip = styled.span`
  display: flex;
  gap: 4px;
`;
