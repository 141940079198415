import { FullAttachmentResponse } from 'app/modules/attachments/types';
import { GoogleDriveDoc } from 'app/modules/uploads/models';
import { Props as EditDocumentModalProps } from 'app/modules/attachmentsRefresh/components/EditDocumentModal';
import { U21Section, U21TitleCountLabel } from 'app/shared/u21-ui/components';
import { Documents } from 'app/modules/attachmentsRefresh/components/Documents';

interface Props {
  addDocumentLoading?: boolean;
  documents: FullAttachmentResponse[];
  id: number | string;
  onEditDocumentSuccess?: EditDocumentModalProps['onSuccess'];
  onAddGDrive?: (files: GoogleDriveDoc[]) => void;
  onDeleteDocument: (documentID: number) => void;
  onUploadGDrive?: (files: File[]) => void;
  onUploadS3?: (files: File[]) => void;
  readOnly?: boolean;
}

export const DocumentsSection = (props: Props) => {
  const {
    addDocumentLoading,
    documents,
    id,
    onAddGDrive,
    onDeleteDocument,
    onEditDocumentSuccess,
    onUploadGDrive,
    onUploadS3,
    readOnly,
  } = props;

  return (
    <U21Section
      title={
        <U21TitleCountLabel count={documents.length} label="document">
          Documents
        </U21TitleCountLabel>
      }
    >
      <Documents
        addDocumentLoading={addDocumentLoading}
        documents={documents}
        id={id}
        onAddGDrive={onAddGDrive}
        onDeleteDocument={onDeleteDocument}
        onEditDocumentSuccess={onEditDocumentSuccess}
        onUploadGDrive={onUploadGDrive}
        onUploadS3={onUploadS3}
        readOnly={readOnly}
      />
    </U21Section>
  );
};
