// Utils
import permissions from 'app/shared/utils/permissions';

export enum PermissionsControlRoles {
  AGENT_ROLE = 'AGENT_ROLE',
  MANAGER_ROLE = 'MANAGER_ROLE',
  CUSTOM = 'CUSTOM',
  ALL = 'ALL',
}

export const agentPermissions = [
  permissions.createCases,
  permissions.createSars,
  permissions.editAccountSettings,
  permissions.editAlerts,
  permissions.editCases,
  permissions.editCustomDataSettings,
  permissions.editEntities,
  permissions.editEvents,
  permissions.editQueues,
  permissions.editSars,
  permissions.editSarsConfig,
  permissions.editTxnInstruments,
  permissions.readAgents,
  permissions.readAlerts,
  permissions.readAlertComponentButtons,
  permissions.readAssignments,
  permissions.readCases,
  permissions.readCustomDataSettings,
  permissions.readEntities,
  permissions.readEntityMedia,
  permissions.readEvents,
  permissions.readLinkAnalysis,
  permissions.readRiskRatings,
  permissions.readRules,
  permissions.readSars,
  permissions.readTxnInstruments,
  permissions.submitSars,
  permissions.editPermissions,
];

export const managerPermissions = [
  permissions.createAlerts,
  permissions.createApiKeys,
  permissions.createBlacklists,
  permissions.createCensorshipConditions,
  permissions.createDashboards,
  permissions.createDatafileUploads,
  permissions.createDeadlinesConfig,
  permissions.createRiskRatings,
  permissions.createRules,
  permissions.createTeams,
  permissions.createWebhooks,
  permissions.deleteApiKeys,
  permissions.deleteBlacklists,
  permissions.deleteCensorshipConditions,
  permissions.deleteDashboards,
  permissions.deleteDatafileUploads,
  permissions.deleteTeams,
  permissions.deleteWebhooks,
  permissions.editAgents,
  permissions.editDeadlinesConfig,
  permissions.editBlacklists,
  permissions.editCensorshipConditions,
  permissions.editCustomDataSettings,
  permissions.editDashboards,
  permissions.editDeadlines,
  permissions.editNotificationSettings,
  permissions.editPermissions,
  permissions.editRiskRatings,
  permissions.editRules,
  permissions.editStorage,
  permissions.editTeams,
  permissions.editWebhooks,
  permissions.readApiKeys,
  permissions.readBlacklists,
  permissions.readCensorshipConditions,
  permissions.readDatafileUploads,
  permissions.readDeadlinesConfig,
  permissions.readInsights,
  permissions.readPermissions,
  permissions.readTeams,
  permissions.readWebhooks,
  ...agentPermissions,
];
