import { useSelector } from 'react-redux';

// Components
import { PriorArticlesTable } from 'app/modules/alerts/components/AlertDetails/PriorActivity/PriorArticlesTable';

// Selectors
import { selectHasReadCasesPermissions } from 'app/modules/session/selectors';

// Models
import { U21TableColumn } from 'app/shared/u21-ui/components';
import { AssociatedCase } from 'app/modules/alerts/types';

// Utils
import routes from 'app/shared/utils/routes';

// Columns
import { TITLE_COLUMN } from 'app/modules/alerts/columns';
import {
  ENTITY_IDS_COLUMN,
  EVENT_IDS_COLUMN,
  ACTION_EVENT_IDS_COLUMN,
} from 'app/modules/alerts/components/AlertDetails/PriorActivity/columns';

const ALERT_ASSOCIATED_CASES_TABLE_CONFIG: U21TableColumn<AssociatedCase>[] = [
  { id: 'id', accessor: 'id', Header: 'ID' },
  {
    ...(TITLE_COLUMN as unknown as U21TableColumn<AssociatedCase>),
    accessor: 'title',
    Header: 'Title',
    headerIcon: undefined,
  },
  ENTITY_IDS_COLUMN,
  EVENT_IDS_COLUMN,
  ACTION_EVENT_IDS_COLUMN,
];

interface OwnProps {
  associatedCases: AssociatedCase[];
}

export const PriorCases = ({ associatedCases }: OwnProps) => {
  const hasReadCasesPermission = useSelector(selectHasReadCasesPermissions);

  if (!hasReadCasesPermission) {
    return null;
  }

  return (
    <PriorArticlesTable
      category="case"
      articles={associatedCases}
      detailsRoute={routes.lumos.casesId}
      config={ALERT_ASSOCIATED_CASES_TABLE_CONFIG}
    />
  );
};
