import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { IconX } from '@u21/tabler-icons';
import {
  U21Button,
  U21ModalBase,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { AiChat } from 'app/modules/codeInterpreter/components/AiChat';
import { AiSessionList } from 'app/modules/codeInterpreter/components/AiSessionList';
import { useCreateAiSession } from 'app/modules/codeInterpreter/queries/useCreateAiSession';
import { useFetchAllAiChatSession } from 'app/modules/codeInterpreter/queries/useFetchAllAiChatSession';
import { selectCodeInterpreterEnabled } from 'app/shared/featureFlags/selectors';
import { useSelector } from 'react-redux';
import { AiChatNoSession } from 'app/modules/codeInterpreter/components/AiChatNoSession';
import { SURVEY, triggerInterceptSurvey } from 'app/shared/utils/intercom';

interface AiChatModalProps {
  fileExportId: number;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const AiChatModal = ({
  fileExportId,
  isOpen,
  setIsOpen,
}: AiChatModalProps) => {
  const isCodeInterpreterEnabled = useSelector(selectCodeInterpreterEnabled);

  const [sessionId, setSessionId] = useState<number>(-1);
  const [hasChatted, setHasChatted] = useState(false);
  const { mutate: createAiSession, isPending: isCreatingSession } =
    useCreateAiSession();

  const { data, isLoading, refetch } = useFetchAllAiChatSession(
    isCodeInterpreterEnabled,
  );

  useEffect(() => {
    if (!isOpen || sessionId > 0) {
      return;
    }

    if (fileExportId > 0) {
      try {
        createAiSession(fileExportId, {
          onSuccess: (response) => {
            setSessionId(response.session_id);
            refetch();
          },
        });
      } catch {}
    } else if (data.length > 0) {
      setSessionId(data[0].id);
    }
  }, [
    createAiSession,
    data,
    fileExportId,
    isOpen,
    refetch,
    sessionId,
    setSessionId,
  ]);

  if (!isCodeInterpreterEnabled) {
    return null;
  }

  return (
    <U21ModalBase
      size="fullscreen"
      open={isOpen}
      onExited={() => {
        setSessionId(-1);
        if (hasChatted) {
          triggerInterceptSurvey(SURVEY.ASK_YOUR_DATA);
          setHasChatted(false);
        }
      }}
    >
      <StyledModalHeader>
        <div>
          <U21Button aria-label="close" onClick={() => setIsOpen(false)}>
            <IconX />
          </U21Button>
        </div>

        <StyledTitle>
          <U21Typography variant="h3">Ask Unit21 AI</U21Typography>
        </StyledTitle>
      </StyledModalHeader>

      {isLoading || sessionId < 0 ? (
        <AiChatNoSession
          isLoading={isLoading || fileExportId > 0}
          isCreatingSession={isCreatingSession}
          onClick={(selectedFileExportId) =>
            createAiSession(selectedFileExportId, {
              onSuccess: (res) => {
                setSessionId(res.session_id);
                refetch();
              },
            })
          }
        />
      ) : (
        <ContentWrapper>
          <AiSessionList
            data={data}
            sessionId={sessionId}
            setSessionId={setSessionId}
          />
          <AiChat
            sessionId={sessionId}
            setHasChatted={setHasChatted}
            setIsOpen={setIsOpen}
          />
        </ContentWrapper>
      )}
    </U21ModalBase>
  );
};

const StyledModalHeader = styled.div`
  position: sticky;
  top: 0;
  background-color: ${(props) => props.theme.palette.background.paper};
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
  height: 136px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 24px 24px 0px 24px;

  &&& {
    z-index: 2;
  }
`;

const StyledTitle = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 24px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  height: calc(100% - 136px); // 136px is header height
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
  align-items: flex-end;
`;
