import { AuthClient } from 'app/modules/session/models';
import { RefreshJWTResponse } from 'app/modules/session/responses';

import { store } from 'app/store/configureStore';

import { tokenPing } from 'app/modules/session/actions';

// Selectors
import {
  selectLogoutAfterInactivity,
  selectTrackLastActivity,
} from 'app/modules/orgSettings/selectors';
import { selectLastRequestDate } from 'app/modules/session/selectors';

// Constants
const SHARED_INTERVAL = 30 * 1000; // every 30 seconds
const SLEEP_INTERVAL = 5 * 1000; // every 5 seconds

class BaseAuthService implements AuthClient {
  provider = '';

  loadingFlags = true;

  hasRefreshedToken = false;

  tokenPingInterval = -1;

  sleepCheckInternal = -1;

  checkLastActivityInterval = -1;

  tokenRenewalTimeout: NodeJS.Timeout | null;

  /* eslint-disable-next-line class-methods-use-this */
  public isAuthenticated = () => false;

  /* eslint-disable-next-line class-methods-use-this */
  public renewSession = () => {};

  /* eslint-disable-next-line class-methods-use-this */
  public login = async () => {};

  /* eslint-disable-next-line @typescript-eslint/no-unused-vars, class-methods-use-this */
  public loginSuccess = (authResponse: RefreshJWTResponse) => {};

  /* eslint-disable-next-line @typescript-eslint/no-unused-vars, class-methods-use-this */
  public logout = async (showLogoutModal: boolean = false) => {};

  /* eslint-disable-next-line class-methods-use-this */
  public authorize = () => {};

  public scheduleTokenPing = () => {
    if (this.tokenPingInterval === -1) {
      this.tokenPingInterval = window.setInterval(() => {
        store.dispatch(tokenPing());
        this.logoutIfTokenExpired();
      }, SHARED_INTERVAL);
    }
  };

  public scheduleSleepCheck = () => {
    if (this.sleepCheckInternal === -1) {
      let lastTime = new Date().getTime();

      this.sleepCheckInternal = window.setInterval(() => {
        const currentTime = new Date().getTime();
        /**
         * Keeps a timer to determine if a session was paused
         * If the session was paused for more than 5 sec, renew the session
         */
        if (currentTime > lastTime + SLEEP_INTERVAL * 2) {
          this.renewSession();
        }

        lastTime = currentTime;
      }, SLEEP_INTERVAL);
    }
  };

  private logoutIfTokenExpired = () => {
    const state = store.getState();

    if (state.session.tokenHasExpired) {
      this.logout(true);
    }
  };

  public scheduleCheckLastActivity = () => {
    // only schedule this check if we need to
    const checkForLastActivity = selectTrackLastActivity(store.getState());
    if (checkForLastActivity && this.checkLastActivityInterval === -1) {
      this.checkLastActivityInterval = window.setInterval(() => {
        this.logoutInactiveUser();
      }, SHARED_INTERVAL);
    }
  };

  private logoutInactiveUser = () => {
    const state = store.getState();
    const logoutAfterInactivity = selectLogoutAfterInactivity(state);

    if (logoutAfterInactivity) {
      const lastRequestDate = selectLastRequestDate(state) || new Date();
      const currentDate = new Date();
      // originally in milliseconds
      const diffInSeconds =
        (currentDate.getTime() - lastRequestDate.getTime()) / 1000;
      if (diffInSeconds >= logoutAfterInactivity.time_seconds) {
        this.logout();
      }
    }
  };
}

export default BaseAuthService;
