// Models
import { AnyObjectType } from 'app/shared/models';

// slightly altered from https://www.tutorialspoint.com/accessing-nested-javascript-objects-with-string-key
const getByPath = (object: AnyObjectType, path: string[]) => {
  // change here, returning empty strings instead of undefined if value does not exist
  if (path.length === 1) return object[path[0]] || '';
  else if (path.length === 0) return '';

  if (object[path[0]]) return getByPath(object[path[0]], path.slice(1));

  // change here, making a new object instead of updating existing object which causes errors
  const newObject = { ...object, [path[0]]: {} };
  return getByPath(newObject[path[0]], path.slice(1));
};

export default getByPath;
