import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

// Components
import { U21CustomData } from 'app/shared/u21-ui/components/customData/U21CustomData';

// Selectors
import {
  selectAlert,
  selectEditAlertLoading,
} from 'app/modules/alerts/selectors';
import { selectFeatureFlags } from 'app/shared/featureFlags/selectors';
import { selectAlertCustomDataKeyLabelMap } from 'app/shared/CustomConfig/selectors';

// Actions
import { editAlert } from 'app/modules/alerts/actions';

// Models
import { FeatureFlag } from 'app/shared/featureFlags/models';

export const CustomData = () => {
  const dispatch = useDispatch();

  const featureFlags = useSelector(selectFeatureFlags);
  const alert = useSelector(selectAlert);
  const data = alert.custom_data;
  const loading = useSelector(selectEditAlertLoading);
  const alertCustomDataKeyLabelMap = useSelector(
    selectAlertCustomDataKeyLabelMap,
  );

  const { state }: { state: { editing?: boolean } } = useLocation();
  const { editing = false } = state || {};

  const handleChange = (newCustomData) => {
    dispatch(
      editAlert({
        id: alert.id,
        custom_data: newCustomData,
      }),
    );
  };

  return (
    <U21CustomData
      value={data}
      onChange={handleChange}
      disabled={!featureFlags[FeatureFlag.EDIT_CUSTOM_DATA]}
      loading={loading}
      defaultEditing={editing}
      customDataKeyValuesMap={alertCustomDataKeyLabelMap}
    />
  );
};
