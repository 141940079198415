import { post } from 'app/shared/utils/fetchr';
import { useQuery } from '@tanstack/react-query';
import { GetCtrsPayload } from 'app/modules/fincenCtr/requests';
import { CTR_FILINGS_QUERY_KEYS } from 'app/modules/fincenCtr/queries/keys';

export interface GroupedFilingItem {
  batch_id: number | null;
  total: number;
  earliest: string | null;
  latest: string | null;
  accepted: number;
  rejected: number;
  created_by: string | null;
}

export interface FilingsGroupedResponse {
  filings: GroupedFilingItem[];
  count: number;
}

const fetchFilingsByBatch = (
  body: GetCtrsPayload,
): Promise<FilingsGroupedResponse> => post(`/filings/grouped`, body);

export const useFetchFilingsGrouped = (body: GetCtrsPayload) => {
  return useQuery({
    queryKey: CTR_FILINGS_QUERY_KEYS.getGroupedFilingsQueryKey(body),
    queryFn: () => fetchFilingsByBatch(body),
    meta: { errorMessage: 'Failed to fetch filings grouped by batch id.' },
  });
};
