import { createSelector } from 'reselect';

// Models
import { InvestigationType } from 'app/modules/investigations/models';

// Selectors
import {
  selectReassignAlertsLoading,
  selectRequeueAlertsLoading,
} from 'app/modules/alerts/selectors';
import {
  selectHasEditAlertsPermission,
  selectHasEditCasesPermission,
  selectHasReadAgentsPermissions,
  selectHasReadAssignmentsPermissions,
} from 'app/modules/session/selectors';
import {
  selectReassignLoading,
  selectRequeueLoading,
} from 'app/modules/investigations/selectors/loading';

export const selectReassignInvestigationsLoading = (type: InvestigationType) =>
  createSelector(
    selectReassignAlertsLoading,
    selectReassignLoading,
    (
      reassignAlertsLoading: boolean,
      reassignInvestigationsLoading: boolean,
    ): boolean =>
      type === InvestigationType.ALERT
        ? reassignAlertsLoading || reassignInvestigationsLoading
        : reassignInvestigationsLoading,
  );

export const selectRequeueInvestigationsLoading = (type: InvestigationType) =>
  createSelector(
    selectRequeueAlertsLoading,
    selectRequeueLoading,
    // Cases don't currently have a requeue feature hence statically returning false
    (requeueAlertsLoading, requeueInvestigationsLoading) =>
      type === InvestigationType.ALERT
        ? requeueAlertsLoading || requeueInvestigationsLoading
        : requeueInvestigationsLoading,
  );

export const selectEscalateInvestigationsLoading = (type: InvestigationType) =>
  createSelector(
    selectReassignInvestigationsLoading(type),
    selectRequeueInvestigationsLoading(type),
    (reassignLoading, requeueLoading) => reassignLoading || requeueLoading,
  );

export const selectCanReassignInvestigations = (type: InvestigationType) =>
  createSelector(
    selectHasReadAssignmentsPermissions,
    selectHasReadAgentsPermissions,
    selectHasEditAlertsPermission,
    selectHasEditCasesPermission,
    (
      hasReadAssignmentsPermission,
      hasReadAgentsPermission,
      hasEditAlertsPermission,
      hasEditCasesPermission,
    ): boolean => {
      if (type === InvestigationType.ALERT) {
        return (
          hasReadAssignmentsPermission &&
          hasReadAgentsPermission &&
          hasEditAlertsPermission
        );
      }
      return (
        hasReadAssignmentsPermission &&
        hasReadAgentsPermission &&
        hasEditCasesPermission
      );
    },
  );

export const selectCanRequeueInvestigations = (type: InvestigationType) =>
  createSelector(
    selectHasReadAssignmentsPermissions,
    selectHasEditAlertsPermission,
    selectHasEditCasesPermission,
    (
      hasReadAssignmentsPermission,
      hasEditAlertsPermission,
      hasEditCasesPermission,
    ): boolean => {
      if (type === InvestigationType.ALERT) {
        return hasReadAssignmentsPermission && hasEditAlertsPermission;
      }

      return [
        type === InvestigationType.CASE,
        hasReadAssignmentsPermission,
        hasEditCasesPermission,
      ].every(Boolean);
    },
  );
