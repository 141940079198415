import { ClickableSection } from 'app/modules/codeInterpreter/components/shared/ClickableSection';
import { AI_CHAT_PROMPTS } from 'app/modules/codeInterpreter/constants';
import { U21Grid } from 'app/shared/u21-ui/components';

interface AiChatPromptsProps {
  onClick: (newPrompt: string) => void;
}

export const AiChatPromptGroup = ({ onClick }: AiChatPromptsProps) => {
  return (
    <U21Grid columns={2}>
      {AI_CHAT_PROMPTS.map((prompts) => (
        <ClickableSection
          key={prompts.description}
          title={prompts.title}
          description={prompts.description}
          onClick={() => onClick(prompts.prompt)}
        />
      ))}
    </U21Grid>
  );
};
