import { AnalyticsEvents, EventConfig } from 'app/shared/u21-ui/analytics';
import { U21DateRangePickerProps } from 'app/shared/u21-ui/components';

export const onchange: EventConfig<U21DateRangePickerProps['value']> = {
  name: 'U21DateRangePicker changed',
  createPayload: (props, _, value = [undefined, undefined]) => {
    const name = props.label || props['aria-label'] || props.name;
    if (!name) {
      return null;
    }
    return { startDate: value[0], endDate: value[1], name };
  },
};

export const u21DateRangePickerEvents = {
  [AnalyticsEvents.U21DATERANGEPICKER_ON_CHANGE]: onchange,
};
