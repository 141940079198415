import React, { Component, ReactNode } from 'react';

// Redux
import { connect } from 'react-redux';

// Components
import { Table, Label } from 'semantic-ui-react';
import GenericButton from 'app/shared/components/GenericButton';

// Selectors
import { selectLumosConfig } from 'app/modules/orgSettings/selectors';

// Models
import { EntityDetails, EntityType } from 'app/modules/entities/models';
import {
  TxnInstrumentType,
  TxnInstrumentDetails,
} from 'app/modules/txnInstruments/models';
import { TableConfig, TableValueType } from 'app/shared/pagination/models';

// Helpers
import {
  getValueFromEntity,
  generateObjectPlaceholderTableConfig,
  formatTableValue,
  getLabelStyle,
} from 'app/modules/entities/helpers';

// Styles
import scssStyles from 'app/shared/events/styles/EventTable.module.scss';

// Utils
import emptyFn from 'app/shared/utils/empty-fn';

// Constants
import { ARRAY_SIZE_LIMIT } from 'app/shared/constants';
import objectIsEmpty from 'app/shared/utils/objectIsEmpty';

type ConfigType = 'entityPageConfig';

interface OwnProps {
  object: EntityDetails | TxnInstrumentDetails;
  objectType: EntityType | TxnInstrumentType;
  configType: ConfigType;

  showCategories?: boolean;
  showBlankItems?: boolean;
  onRowClick?: (key: string, value: string) => void;
  onArrayItemClick?: (key: string, value: string) => void;
}

type AllProps = OwnProps & ReturnType<typeof mapStateToProps>;

const mapStateToProps = (state: RootState) => ({
  lumosConfig: selectLumosConfig(state),
});

interface AllState {
  showAllArrayValues: Set<string>;
}

interface ArrayValueWrapperProps {
  children: ReactNode;
  type: TableValueType;
}

const ArrayValueWrapper = ({ type, children }: ArrayValueWrapperProps) => {
  if (type === 'label' || type === 'entity') {
    return (
      <Label.Group className={scssStyles.labelGroupContainer}>
        {children}
      </Label.Group>
    );
  }
  return <>{children}</>;
};

const formatCategoryNaming = (category: string) => {
  let wordToReturn = '';
  const stringAfterSplit = category.split('_');
  stringAfterSplit.forEach((word) => {
    const finalUppercaseWord = `${
      word.charAt(0).toUpperCase() + word.slice(1)
    } `;
    wordToReturn += finalUppercaseWord;
  });
  return wordToReturn.trimRight();
};

const isPaginatedColumn = (columnName: string): boolean => {
  return columnName === 'ip_addresses' || columnName === 'client_fingerprints';
};

class BasicObjectDetails extends Component<AllProps, AllState> {
  static defaultProps = {
    showCategories: true,
    onRowClick: emptyFn,
  };

  constructor(props) {
    super(props);
    this.state = {
      showAllArrayValues: new Set<string>(),
    };
  }

  toggleShowAll = (columnName: string) => () => {
    const { showAllArrayValues: stateShowAllArrayValues } = this.state;
    const showAllArrayValues = new Set(stateShowAllArrayValues);
    if (showAllArrayValues.has(columnName)) {
      showAllArrayValues.delete(columnName);
    } else {
      showAllArrayValues.add(columnName);
    }
    this.setState({ showAllArrayValues });
  };

  formatArray = (objectField, values: any[]) => {
    const { onArrayItemClick } = this.props;
    const { showAllArrayValues } = this.state;
    const showingAllValues = showAllArrayValues.has(objectField.key);
    const entries = showingAllValues
      ? values
      : values.slice(0, ARRAY_SIZE_LIMIT);
    return (
      <>
        <ArrayValueWrapper type={objectField.type}>
          {entries.map((value, idx) => {
            const valueIsObject = value instanceof Object;
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`object-${idx}`}>
                <div className={scssStyles.arrayItemContainer}>
                  {value.type && objectField.type === 'text' && (
                    <Label
                      className={scssStyles.arrayItemLabel}
                      style={{ ...getLabelStyle(value.type) }}
                    >
                      {value.type.toUpperCase()}
                    </Label>
                  )}
                  {formatTableValue({
                    value:
                      valueIsObject &&
                      Object.prototype.hasOwnProperty.call(value, 'value')
                        ? value.value
                        : value,
                    valueType: objectField.type,
                    fmtstr: objectField.fmtstr,
                    idx,
                    key: objectField.key,
                    isGoto: true,
                    onClick: onArrayItemClick,
                  })}
                </div>
                {objectField.type === 'text' && idx < values.length - 1 && (
                  <hr />
                )}
              </div>
            );
          })}
        </ArrayValueWrapper>
        {!isPaginatedColumn(objectField.key) &&
          values.length > ARRAY_SIZE_LIMIT && (
            <GenericButton
              onClick={this.toggleShowAll(objectField.key)}
              className={scssStyles.showAllButton}
              content={
                showingAllValues ? 'Show less' : `Show All (${values.length})`
              }
            />
          )}
      </>
    );
  };

  renderValue = (value, objectField) => {
    const formatValue = (tableValue) => {
      if (Array.isArray(tableValue)) {
        return this.formatArray(objectField, tableValue);
      }
      return formatTableValue({
        key: objectField.key,
        value: tableValue,
        valueType: objectField.type,
        fmtstr: objectField.fmtstr,
        isGoto: true,
      });
    };

    return formatValue(value);
  };

  render() {
    const {
      object,
      objectType: propsObjectType,
      configType,
      lumosConfig,
      onRowClick,
      showCategories,
      showBlankItems,
    } = this.props;
    const objectSubtype = propsObjectType || 'n/a';

    let pageConfig;
    const objectInternalStatus = object.internal_status;
    const isIncompleteObject = Boolean(
      objectInternalStatus === 'spooled' ||
        objectInternalStatus === 'placeholder',
    );

    if (isIncompleteObject) {
      pageConfig = generateObjectPlaceholderTableConfig(
        configType === 'entityPageConfig' ? 'Entity' : 'Instrument',
      );
    } else if (configType === 'entityPageConfig') {
      pageConfig = lumosConfig.entity_page;
    } else {
      pageConfig = lumosConfig.txn_instruments_page;
    }

    // object type here corresponds to type enum on entity db: USER / BUSINESS and the sub type for instrument (i.e. external bank)
    const objectType = objectSubtype.toLowerCase();

    if (
      (pageConfig && objectType in pageConfig) ||
      isIncompleteObject ||
      configType === 'entityPageConfig'
    ) {
      // this constant is a list that contains the dictionaries
      // of the different categories data e.g. business_info, general_info
      let fieldsToDisplay: Record<string, TableConfig[]>[] =
        pageConfig[objectType];
      if (isIncompleteObject) {
        fieldsToDisplay = [pageConfig];
      }
      if (configType === 'entityPageConfig' && !fieldsToDisplay) {
        fieldsToDisplay = pageConfig.user;
      }

      // this constant accumulates and stores all the fields for the
      // table to render the respective rows
      const copyOfFieldsObjectToIterate: (TableConfig & {
        category_name: string;
        array_size: number;
      })[] = [];

      fieldsToDisplay.forEach((i) => {
        Object.entries(i).forEach(([key, value]) => {
          if (configType !== 'entityPageConfig' || key !== 'ancestry') {
            if (value) {
              const items = showBlankItems
                ? value
                : value.filter((item) => {
                    return !objectIsEmpty(getValueFromEntity(object, item.key));
                  });

              // add category and rowspan info to objects
              copyOfFieldsObjectToIterate.push(
                ...items.map((item) => ({
                  ...item,
                  category_name: formatCategoryNaming(key),
                  // array size is specified here for the table to render the right rows
                  array_size: items.length,
                })),
              );
            }
          }
        });
      });

      const trackRenderedCategory: any = [];

      return (
        <Table sortable celled striped selectable className={scssStyles.table}>
          <Table.Body>
            {copyOfFieldsObjectToIterate.map((objectField) => {
              const categoryName = objectField.category_name;
              const value = getValueFromEntity(object, objectField.key);
              return (
                <Table.Row
                  key={`${categoryName} - ${objectField.label}`}
                  onClick={() => onRowClick?.(objectField.label, value)}
                >
                  {showCategories &&
                    !trackRenderedCategory.includes(categoryName) &&
                    trackRenderedCategory.push(categoryName) && (
                      <Table.Cell rowSpan={objectField.array_size} width={3}>
                        {objectField.category_name}
                      </Table.Cell>
                    )}
                  <Table.Cell width={4}>{objectField.label}</Table.Cell>
                  <Table.Cell className={scssStyles.tableValue}>
                    {this.renderValue(value, objectField)}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      );
    }
    return null;
  }
}

export default connect(mapStateToProps)(BasicObjectDetails);
