import {
  PermissionSummary,
  RetrievePermissionsPayload,
} from 'app/modules/permissions/models';

import PERMISSIONS from 'app/shared/utils/permissions';

import {
  editPermissions,
  retrievePermissions,
  retrieveUnassignedPermissions,
} from 'app/modules/permissions/actions';
import { toSentenceCase } from 'app/shared/utils/string';
import {
  selectAgent,
  selectHasEditPermissionsPermission,
  selectSessionAgentPermissions,
} from 'app/modules/session/selectors';
import {
  selectAllPermissions,
  selectEditPermissionsLoading,
} from 'app/modules/permissions/selectors';
import { setSessionAgentPermissions } from 'app/modules/session/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';

import styled from 'styled-components';

import { IconSearch } from '@u21/tabler-icons';
import {
  U21ButtonGroup,
  U21Subsection,
  U21Grid,
  U21Spacer,
  U21Switch,
  U21TextField,
  U21Typography,
  U21Tooltip,
} from 'app/shared/u21-ui/components';
import { U21SideMenu } from 'app/shared/u21-ui/components/dashboard';

// Models
import {
  agentPermissions,
  managerPermissions,
  PermissionsControlRoles,
} from 'app/modules/devtools/models';
import {
  PERMISSION_DESCRIPTIONS,
  PERMISSION_NAMES,
} from 'app/modules/permissions/constants';

export const PermissionsControl = () => {
  const dispatch = useDispatch();
  const { id: agentID } = useSelector(selectAgent);
  const allPermissions = useSelector(selectAllPermissions);
  const assignedPermissionNames = useSelector(selectSessionAgentPermissions);
  const editPermissionsLoading = useSelector(selectEditPermissionsLoading);
  const hasEditPermissionsPermission = useSelector(
    selectHasEditPermissionsPermission,
  );

  const [filter, setFilter] = useState('');

  const assignedPermissions = useMemo(() => {
    const allPermissionsMap = allPermissions.reduce((acc, i) => {
      return { ...acc, [i.name]: i };
    }, {});
    return (
      assignedPermissionNames
        .map((i) => allPermissionsMap[i])
        // filter boolean since allPermissions may not have been initialized yet
        .filter(Boolean)
    );
  }, [allPermissions, assignedPermissionNames]);

  const assignRoleBasedPermissions = (role: PermissionsControlRoles) => {
    let roleBasedPermissionsToAssign;

    if (role === PermissionsControlRoles.ALL) {
      roleBasedPermissionsToAssign = allPermissions;
    } else if (
      role !== PermissionsControlRoles.MANAGER_ROLE &&
      role !== PermissionsControlRoles.AGENT_ROLE
    ) {
      roleBasedPermissionsToAssign = assignedPermissions;
    } else {
      const allPermissionsMap = allPermissions.reduce((acc, i) => {
        return { ...acc, [i.name]: i };
      }, {});

      const roleBasedPermissionsSet =
        role === PermissionsControlRoles.MANAGER_ROLE
          ? managerPermissions
          : agentPermissions;
      roleBasedPermissionsToAssign = roleBasedPermissionsSet
        .map((i) => allPermissionsMap[i])
        .filter(Boolean);
    }

    dispatch(setSessionAgentPermissions([...roleBasedPermissionsToAssign]));
  };

  const comparePermissionSets = (a: Set<string>, b: Set<string>): boolean => {
    return a.size === b.size && new Set([...a, ...b]).size === a.size;
  };

  const identifyAssignedRole = useMemo(() => {
    const flattenedPermissionNames = assignedPermissions.map(
      (perm) => perm.name,
    );
    // Look at updatedAssignedPermissions and diff it with Roles to switch context for accuracy
    if (
      comparePermissionSets(
        new Set(flattenedPermissionNames),
        new Set(managerPermissions),
      )
    ) {
      return PermissionsControlRoles.MANAGER_ROLE;
    } else if (
      comparePermissionSets(
        new Set(flattenedPermissionNames),
        new Set(agentPermissions),
      )
    ) {
      return PermissionsControlRoles.AGENT_ROLE;
    } else if (flattenedPermissionNames.length === allPermissions.length) {
      return PermissionsControlRoles.ALL;
    }

    return PermissionsControlRoles.CUSTOM;
  }, [assignedPermissions, allPermissions.length]);

  useEffect(() => {
    if (agentID > 0) {
      const payload: RetrievePermissionsPayload = {
        associationId: agentID,
        associationType: 'agent',
      };
      dispatch(retrievePermissions(payload));
      dispatch(retrieveUnassignedPermissions(payload));
    }
  }, [agentID, dispatch]);

  const permissionGroups = useMemo(() => {
    return Object.entries<PermissionSummary[]>(
      allPermissions
        .filter((i) => {
          const { name } = i;
          if (name === PERMISSIONS.rootAll) {
            return false;
          }
          const lowercaseFilter = filter.toLowerCase();
          return (
            // keep optional chaining in case there is a new permission added to BE but not FE
            PERMISSION_NAMES[name]?.toLowerCase().includes(lowercaseFilter) ||
            PERMISSION_DESCRIPTIONS[name]
              ?.toLowerCase()
              .includes(lowercaseFilter)
          );
        })
        .reduce((acc, i) => {
          const { type } = i;
          if (acc[type]) {
            acc[type].push(i);
          } else {
            acc[type] = [i];
          }
          return acc;
        }, {}),
      // sort by permission group size
    ).sort((a, b) => b[1].length - a[1].length);
  }, [allPermissions, filter]);

  const values = useMemo(
    () =>
      assignedPermissions.reduce((acc, i) => ({ ...acc, [i.id]: true }), {}),
    [assignedPermissions],
  );

  if (!hasEditPermissionsPermission) {
    return null;
  }

  return (
    <U21SideMenu
      actionButtonProps={{
        children: 'Save to DB',
        loading: editPermissionsLoading,
      }}
      onAction={() => {
        dispatch(
          editPermissions({
            associationId: agentID,
            associationType: 'agent',
            permissions: assignedPermissions.map((i) => i.id),
          }),
        );
      }}
      noPadding
      title="Permission Control"
    >
      <StyledU21Spacer marginEnd marginStart>
        <U21Typography variant="subtitle2">
          Note: Your team permissions take precedence. Ensure your agent is not
          on any teams to check these permissions only. You also must click the
          &quot;Save to DB&quot; button to have these permission changes
          reflected in Backend logic.
        </U21Typography>
        <U21ButtonGroup
          buttons={[
            {
              label: 'Agent',
              value: PermissionsControlRoles.AGENT_ROLE,
            },
            {
              label: 'Manager',
              value: PermissionsControlRoles.MANAGER_ROLE,
            },
            {
              label: 'Custom',
              value: PermissionsControlRoles.CUSTOM,
            },
            {
              label: 'All',
              value: PermissionsControlRoles.ALL,
            },
          ]}
          onClick={(value: PermissionsControlRoles) => {
            assignRoleBasedPermissions(value);
          }}
          value={identifyAssignedRole}
        />
        <U21TextField
          startIcon={<IconSearch />}
          onChange={(val?: string) => setFilter(val || '')}
          placeholder="Search permissions"
          value={filter}
        />
      </StyledU21Spacer>
      <U21Spacer spacing={0.5}>
        {permissionGroups.map((i) => {
          const [key, permissions] = i;
          return (
            <U21Subsection
              // filter included in key so that collapsible is reset on filter change
              key={`${key}${filter}`}
              collapsible
              shaded
              title={toSentenceCase(key)}
            >
              <U21Grid columns={2} minWidth={150}>
                {permissions.map((j) => {
                  const { id, name } = j;
                  // don't allow editing this
                  if (name === PERMISSIONS.editPermissions) {
                    return null;
                  }
                  return (
                    <SwitchContainer key={id}>
                      <U21Switch
                        checked={values[id]}
                        label={
                          <U21Tooltip tooltip={PERMISSION_DESCRIPTIONS[name]}>
                            <span>{PERMISSION_NAMES[name] || name}</span>
                          </U21Tooltip>
                        }
                        onChange={(checked) => {
                          const updatedAssignedPermissions = checked
                            ? [...assignedPermissions, j]
                            : assignedPermissions.filter((k) => k.id !== id);

                          dispatch(
                            setSessionAgentPermissions(
                              updatedAssignedPermissions,
                            ),
                          );
                        }}
                      />
                    </SwitchContainer>
                  );
                })}
              </U21Grid>
            </U21Subsection>
          );
        })}
      </U21Spacer>
    </U21SideMenu>
  );
};

const StyledU21Spacer = styled(U21Spacer)`
  padding: 0 16px;
`;

const SwitchContainer = styled.div`
  .MuiFormControlLabel-root {
    margin-right: 0;
  }
`;
