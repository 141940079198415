import { useLocalStorage } from 'app/shared/hooks/useLocalStorage';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

// Components
import {
  U21Button,
  U21LoadError,
  U21Section,
  U21Spacer,
  U21Table,
  U21TablePreference,
  U21TableState,
  U21TableSubComponentProps,
  U21TitleCountLabel,
  U21Typography,
} from 'app/shared/u21-ui/components';
import {
  IconEye,
  IconEyeOff,
  IconLayoutColumns,
  IconPencil,
} from '@u21/tabler-icons';
import { MatchWatchlist } from 'app/modules/watchlists/components/MatchWatchlist';
import { Filters } from 'app/modules/filters/components/Filters';

// Models
import { PaginationPayload } from 'app/shared/pagination/models';
import { LocalStorageKeys } from 'app/shared/constants/localStorage';
import {
  DEFAULT_PAGE,
  DEFAULT_TABLE_PREFRENCES,
} from 'app/shared/u21-ui/components/display/table/constants';
import { WatchlistResult } from 'app/modules/watchlists/models';
import { FilterOption } from 'app/modules/filters/models';
import { TableConfigType } from 'app/shared/CustomConfig/models';

// Selectors
import { selectAlert } from 'app/modules/alerts/selectors';
import {
  selectWatchlistFilters,
  selectWatchlistTableColumns,
} from 'app/modules/watchlists/selectors';
import {
  selectHasEditCustomDataUserPermissions,
  selectHasReadCustomDataUserPermissions,
} from 'app/modules/session/selectors';
import { selectWatchlistCustomDataFilterOptions } from 'app/modules/filters/selectors';

// Queries / Actions
import { useGetAlertWatchlistResults } from 'app/modules/watchlists/queries/useGetAlertWatchlistResults';
import { setWatchlistFilters } from 'app/modules/watchlists/sliceWatchlists';
import { toggleTableConfigSidebar } from 'app/modules/sidebar/slice';

// Utils
import { createPaginationPayload } from 'app/shared/utils/table';
import { ROUTES_MAP } from 'app/shared/utils/routes';
import { getValidFilters } from 'app/modules/filters/utils';
import { getLocalStorageJSON } from 'app/shared/utils/localStorage';
import {
  createWatchlistFilters,
  getWatchlistFilters,
} from 'app/modules/watchlists/utils';
import { TableInstance } from 'react-table';
import { useGetAlertEntities } from 'app/modules/alerts/queries/useGetAlertEntities';
import { useGetWatchlistResultNames } from 'app/modules/watchlists/queries/useGetWatchlistResultNames';

export const MatchSummary = () => {
  const { id: alertId } = useSelector(selectAlert);

  return (
    <U21Section>
      <MatchTable alertId={alertId} />
    </U21Section>
  );
};

interface MatchTableProps {
  alertId: number;
  hideManageWatchlistConfiguration?: boolean;
}
export const MatchTable = ({
  alertId,
  hideManageWatchlistConfiguration = false,
}: MatchTableProps) => {
  const dispatch = useDispatch();
  const hasReadCustomDataUserPermissions = useSelector(
    selectHasReadCustomDataUserPermissions,
  );
  const hasEditCustomDataUserPermissions = useSelector(
    selectHasEditCustomDataUserPermissions,
  );

  const columns = useSelector(selectWatchlistTableColumns);
  const filters = useSelector(selectWatchlistFilters);
  const customDataFilterOptions = useSelector(
    selectWatchlistCustomDataFilterOptions,
  );

  const [tablePreferences, setTablePreferences] =
    useLocalStorage<U21TablePreference>(
      LocalStorageKeys.WATCHLIST_RESULT_TABLE_PREFERENCES,
      { ...DEFAULT_TABLE_PREFRENCES },
    );

  const tableInstanceRef = useRef<TableInstance<WatchlistResult>>(null);

  const [paginationData, setPaginationData] = useState<U21TableState>({
    page: DEFAULT_PAGE,
    pageSize: tablePreferences.pageSize,
    sortBy: tablePreferences.sortBy,
  });

  const payload: PaginationPayload = useMemo(
    () => ({
      ...createPaginationPayload(paginationData),
      ...createWatchlistFilters(filters),
    }),
    [filters, paginationData],
  );

  const {
    isLoading,
    isRefetching,
    isError,
    data: watchlistResults,
    refetch,
  } = useGetAlertWatchlistResults(alertId, payload);

  const { data: watchlistResultNames } = useGetWatchlistResultNames(alertId);

  const filterOptions = useMemo<FilterOption[]>(() => {
    return [
      ...getWatchlistFilters(watchlistResultNames),
      ...customDataFilterOptions,
    ];
  }, [customDataFilterOptions, watchlistResultNames]);

  useEffect(() => {
    const validFilters = getValidFilters(
      getLocalStorageJSON(LocalStorageKeys.WATCHLIST_FILTERS),
      filterOptions,
    );

    if (validFilters.length !== filters.length) {
      // update watchlist filters to valid localStorage filters
      dispatch(setWatchlistFilters(validFilters));
    }
  }, [dispatch, filters, filterOptions]);

  const handleWatchlistTableConfig = () => {
    dispatch(
      toggleTableConfigSidebar({
        tableConfigType: TableConfigType.WATCHLIST_TABLE,
      }),
    );
  };

  const {
    data: { entities } = { count: 0, entities: [] },
    isLoading: entitiesLoading,
  } = useGetAlertEntities({
    alertId: String(alertId),
    limit: 1,
    offset: 1,
  }); // Watchlist alert always have a single entity

  const [isEmptyFieldShown, setIsEmptyFieldShown] = useState<boolean>(false);

  return (
    <U21Spacer spacing={3}>
      <StyledU21Spacer horizontal>
        <U21TitleCountLabel count={watchlistResults.count} label="result">
          <U21Typography variant="h4">Match Summary</U21Typography>
        </U21TitleCountLabel>
        <U21Spacer horizontal>
          {hasReadCustomDataUserPermissions &&
            hasEditCustomDataUserPermissions &&
            !hideManageWatchlistConfiguration && (
              <U21Button
                color="primary"
                to={ROUTES_MAP.watchlistSettingsClassifier.path.replace(
                  ':classifier',
                  String(watchlistResults.results[0]?.provider),
                )}
                startIcon={<IconPencil />}
              >
                Manage Watchlist Configuration
              </U21Button>
            )}
          <U21Button
            onClick={() => setIsEmptyFieldShown((prev) => !prev)}
            startIcon={isEmptyFieldShown ? <IconEyeOff /> : <IconEye />}
          >
            {isEmptyFieldShown ? 'Hide' : 'Show'} Empty Fields
          </U21Button>
          <U21Button
            onClick={handleWatchlistTableConfig}
            startIcon={<IconLayoutColumns />}
          >
            Choose Columns
          </U21Button>
        </U21Spacer>
      </StyledU21Spacer>
      <Filters
        filters={filters}
        onChange={(newFilters) => {
          dispatch(setWatchlistFilters(newFilters));
          setPaginationData((oldPaginationData) => ({
            ...oldPaginationData,
            page: 1,
          }));
          tableInstanceRef.current?.gotoPage(0);
        }}
        options={filterOptions}
      />
      {isError ? (
        <U21LoadError label="matches" onTryAgain={() => refetch()} />
      ) : (
        <U21Table
          columns={columns}
          count={watchlistResults.count}
          data={watchlistResults.results}
          defaultSortBy={tablePreferences.sortBy}
          defaultPageSize={tablePreferences.pageSize}
          defaultColumnWidths={tablePreferences.columnWidths}
          loading={isLoading}
          onRefresh={() => refetch()}
          refreshLoading={isRefetching}
          onStateChange={setPaginationData}
          manualPagination
          onPreferenceChange={(value) => setTablePreferences(value)}
          tableInstanceRef={tableInstanceRef}
          SubComponent={(props: U21TableSubComponentProps<WatchlistResult>) => {
            return (
              <MatchWatchlist
                provider={props.row.provider}
                // Watchlist alerts should have only 1 associated entity
                entity={
                  entitiesLoading || entities.length < 1
                    ? undefined
                    : entities[0]
                }
                watchlist={props.row}
                isEmptyFieldShown={isEmptyFieldShown}
              />
            );
          }}
        />
      )}
    </U21Spacer>
  );
};

const StyledU21Spacer = styled(U21Spacer)`
  justify-content: space-between;
`;
