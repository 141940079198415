import { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Selectors
import { selectActiveAgentOptions } from 'app/shared/dropdown/selectors';

// Components
import {
  U21Avatar,
  U21MenuList,
  U21MenuListOptionProps,
} from 'app/shared/u21-ui/components';

// Actions
import { retrieveDropdownAgents } from 'app/shared/dropdown/actions';

// Constants
import { INFINITE_SCROLL_LIMIT, OFFSET_DEFAULT } from 'app/shared/constants';

interface Props {
  onChange: (value: number) => void;
}

export const ReassignAgentMenuItem: FC<Props> = ({ onChange }) => {
  const dispatch = useDispatch();
  const agents = useSelector(selectActiveAgentOptions);

  const newAgents: U21MenuListOptionProps[] = useMemo(() => {
    const handleAgentSelect = (agentID: number) => {
      onChange(agentID);
    };
    return [
      {
        icon: <U21Avatar name="?" />,
        text: 'Unassigned',
        onClick: (e) => {
          handleAgentSelect(-1);
          e.stopPropagation();
        },
        value: -1,
      },
      ...agents.map((agent) => {
        return {
          text: agent.text as string,
          onClick: (e) => {
            handleAgentSelect(agent.value as number);
            e.stopPropagation();
          },
          icon: agent.icon,
        };
      }),
    ];
  }, [agents, onChange]);

  const handleOnSearch = (agentName: string) => {
    dispatch(
      retrieveDropdownAgents({
        limit: INFINITE_SCROLL_LIMIT,
        offset: OFFSET_DEFAULT,
        query: agentName,
        statuses: ['ACTIVE'],
      }),
    );
  };

  return (
    <U21MenuList
      options={newAgents}
      placeholder="Search agents"
      onSearch={handleOnSearch}
    />
  );
};
