import { createSelector } from 'reselect';

// Models
import { AuditTrailActionTypes } from 'app/shared/auditTrail/models';

export const selectAuditTrail = (state: RootState) => {
  return state.auditTrail.auditTrailDetails;
};

export const selectPaginatedAuditTrailEntries = (state: RootState) => {
  return (
    state.auditTrail.auditTrailEntries || {
      entries: [],
      count: 0,
    }
  );
};

export const selectAuditTrailEntries = createSelector(
  selectPaginatedAuditTrailEntries,
  ({ entries }) => entries,
);

export const selectAuditTrailEntriesCount = createSelector(
  selectPaginatedAuditTrailEntries,
  ({ count }) => count,
);

// Loading
export const selectAuditTrailLoading = (state: RootState) => {
  return Boolean(state.loading[AuditTrailActionTypes.RETRIEVE_AUDIT_TRAIL]);
};

export const selectAuditTrailEntriesLoading = (state: RootState) => {
  return Boolean(
    state.loading[AuditTrailActionTypes.RETRIEVE_AUDIT_TRAIL_ENTRIES],
  );
};
