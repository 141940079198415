import { useFetchAllDataLabelsById } from 'app/modules/dataLabels/queries/useFetchAllDataLabels';
import { getTagDisplayName } from 'app/modules/tags/utils';
import { U21Chip, U21Typography } from 'app/shared/u21-ui/components';
import { useMemo } from 'react';
import styled from 'styled-components';

interface IProps {
  tagIds: (string | number)[];
}

export const DataLabelList = ({ tagIds }: IProps) => {
  const dataLabelsById = useFetchAllDataLabelsById();
  const tags = useMemo(() => {
    return tagIds.map((id) =>
      dataLabelsById[id]
        ? { id, text: getTagDisplayName(dataLabelsById[id]) }
        : { id, text: 'Unknown' },
    );
  }, [tagIds, dataLabelsById]);

  return (
    <StyledDiv>
      {tagIds.length === 0 ? (
        <U21Typography variant="body2" color="text.secondary">
          No tags found
        </U21Typography>
      ) : (
        <>
          {tags.map((tag) => (
            <U21Chip key={tag.id}>{tag.text}</U21Chip>
          ))}
        </>
      )}
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;
