import { SECONDARY_OBJECT_OPTIONS_WITHOUT_CUSTOM_DATA_SUPPORT } from 'app/modules/dataMapping/constants';
import { NonPrimaryObjects, U21Object } from 'app/modules/dataMapping/types';
import { U21_OBJECT_TYPE_TO_COLOR } from 'app/modules/pullBasedDataFiles/constants';
import { U21Chip } from 'app/shared/u21-ui/components';
import styled from 'styled-components';

export const ObjectChip = styled(U21Chip)<{
  $objectType?: `${U21Object}`;
}>`
  ${(props) => {
    const { main, contrastText } =
      props.theme.palette.colors[
        SECONDARY_OBJECT_OPTIONS_WITHOUT_CUSTOM_DATA_SUPPORT.has(
          props.$objectType as NonPrimaryObjects,
        ) || !props.$objectType
          ? 'grey'
          : U21_OBJECT_TYPE_TO_COLOR[props.$objectType]
      ];
    return `
      background-color: ${main};
      color: ${contrastText};
    `;
  }};
`;
