import { createSelector } from 'reselect';

// Models
import { AlertType } from 'app/modules/alerts/types/alert';
import { CommentsActionTypes } from 'app/modules/comments/models';
import { CasesActionTypes } from 'app/modules/casesOld/models';
import { InvestigationType } from 'app/modules/investigations/models';

// Selectors
import { selectInvestigation } from 'app/modules/investigations/selectors';

export const selectComments = (state: RootState) =>
  state.comments.comments || [];

export const selectReverseChronologicalComments = createSelector(
  selectComments,
  // Since the comments are always returned in chronologial order
  // we can just apply array functions. However, if that changes,
  // this would be the selector to modify
  (comments) => comments.slice().reverse(),
);

export const selectChainalysisComments = (type: InvestigationType) =>
  createSelector(selectInvestigation(type), (investigation) => {
    if (investigation.alert_type !== AlertType.CHAINALYSIS) {
      return [];
    }

    return (investigation.content?.statusesAndComments || []).slice().reverse();
  });

// Loading
export const selectCommentsLoading = (state: RootState) =>
  Boolean(
    state.loading[CommentsActionTypes.RETRIEVE_COMMENTS] ||
      state.loading[CasesActionTypes.RETRIEVE_CASE],
  );

export const selectAddCommentLoading = (state: RootState) =>
  Boolean(state.loading[CommentsActionTypes.ADD_COMMENT]);
