import { InvestigationType } from 'app/modules/investigations/models';

export enum TxnAnalysisType {
  ALERT = 'ALERT',
  CASE = 'CASE', // To be migrated in future
}

export const TXN_ANALYSIS_CAPITAL_CASE_MAP: Record<TxnAnalysisType, string> = {
  ALERT: 'Alert',
  CASE: 'Case',
};

export const TXN_ANALYSIS_NO_CASE_MAP: Record<TxnAnalysisType, string> = {
  ALERT: 'alert',
  CASE: 'case',
};

export const TXN_ANALYSIS_INVESTIGATION_MAP: Record<
  TxnAnalysisType,
  InvestigationType
> = {
  ALERT: InvestigationType.ALERT,
  CASE: InvestigationType.CASE,
};
