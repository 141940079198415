import styled from 'styled-components';
import { U21Badge } from 'app/shared/u21-ui/components';

export const AlertScoreTabLabel = () => {
  return (
    <U21Badge color="error" variant="dot">
      <StyledSpan>Alert score</StyledSpan>
    </U21Badge>
  );
};

const StyledSpan = styled.span`
  padding-right: 8px;
`;
