import { selectAlertEntities } from 'app/modules/alerts/selectors';
import { EntitiesConsortiumHitsTable } from 'app/modules/entitiesRefresh/pages/EntitiesConsortiumHitsTable';
import { useFetchEntitiesConsortiumHits } from 'app/modules/entitiesRefresh/queries/useFetchEntitiesConsortiumHits';
import { U21Loading, U21NoData } from 'app/shared/u21-ui/components';
import { useSelector } from 'react-redux';

interface ConsortiumHitsTabProps {}

export const ConsortiumHitsTab: React.FC<ConsortiumHitsTabProps> = () => {
  const entities = useSelector(selectAlertEntities);
  const { data: consortiumHits, isLoading } = useFetchEntitiesConsortiumHits(
    entities.map((entity) => entity.external_id),
  );
  if (entities.length === 0) {
    return <U21NoData />;
  }
  if (isLoading) {
    return <U21Loading loading />;
  }
  if (!consortiumHits) {
    return <U21NoData />;
  }
  return <EntitiesConsortiumHitsTable consortiumHits={consortiumHits.hits} />;
};
