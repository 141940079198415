import { createSelector } from 'reselect';

import { INVESTIGATIONS_SLICE_NAME } from 'app/modules/investigations/sliceInvestigations';

export const selectReassignLoading = (state: RootState): boolean =>
  state[INVESTIGATIONS_SLICE_NAME].loadingReassignInvestigations;

export const selectRequeueLoading = (state: RootState): boolean =>
  state[INVESTIGATIONS_SLICE_NAME].loadingRequeueInvestigations;

export const selectResolveLoading = (state: RootState): boolean =>
  state[INVESTIGATIONS_SLICE_NAME].loadingResolveInvestigations;

export const selectAddGoogleDocsLoading = (state: RootState): boolean =>
  state[INVESTIGATIONS_SLICE_NAME].loadingAddGoogleDocs;

export const selectUploadGoogleDocsLoading = (state: RootState): boolean =>
  state[INVESTIGATIONS_SLICE_NAME].loadingUploadGoogleDocs;

export const selectAddS3DocsLoading = (state: RootState): boolean =>
  state[INVESTIGATIONS_SLICE_NAME].loadingAddS3Docs;

export const selectAddDocumentLoading = createSelector(
  selectAddS3DocsLoading,
  selectAddGoogleDocsLoading,
  selectUploadGoogleDocsLoading,
  (...loadings) => loadings.some((i) => i),
);
