import { SarStatusTypes } from 'app/modules/fincenSarNew/models';

import {
  BE_FE_STATUS_MAPPING,
  BE_STATUS_KEYS,
  FILING_STATUS_DISPLAY_LABEL,
  FILING_STATUS_KEYS,
} from 'app/modules/fincenSarNew/status';

import { U21Chip } from 'app/shared/u21-ui/components';

export const StatusPill = ({ status }: { status: SarStatusTypes }) => {
  const label = FILING_STATUS_DISPLAY_LABEL[BE_FE_STATUS_MAPPING[status]];
  switch (status) {
    case BE_STATUS_KEYS.ARCHIVED:
      return <U21Chip variant="ghost"> {label}</U21Chip>;
    case BE_STATUS_KEYS.PREFILING_VALIDATION_PENDING:
    case BE_STATUS_KEYS.PREFILING_VALIDATION_IN_PROCESS:
    case BE_STATUS_KEYS.PREFILING_VALIDATION_SUCCESS:
    case BE_STATUS_KEYS.PREFILING_VALIDATION_FAILED:
      return (
        <U21Chip color="primary" variant="ghost">
          {label}
        </U21Chip>
      );
    case BE_STATUS_KEYS.READY_FOR_REVIEW:
      return (
        <U21Chip color="primary" variant="ghost">
          {label}
        </U21Chip>
      );
    case BE_STATUS_KEYS.QUEUED_FOR_FILING:
    case BE_STATUS_KEYS.SENDING_TO_FINCEN:
    case BE_STATUS_KEYS.SENT_TO_FINCEN:
      return (
        <U21Chip
          color="warning"
          tooltip="Waiting for FinCEN to accept your submission"
          variant="ghost"
        >
          {label}
        </U21Chip>
      );
    case BE_STATUS_KEYS.FINCEN_ACCEPTED:
      return (
        <U21Chip
          color="success"
          tooltip="Successfully submitted and waiting for FinCEN to process"
          variant="ghost"
        >
          {label}
        </U21Chip>
      );
    case BE_STATUS_KEYS.FILING_SUCCESS:
      return (
        <U21Chip
          color="success"
          tooltip="Successfully submitted and processed by FinCEN"
        >
          {label}
        </U21Chip>
      );
    case BE_STATUS_KEYS.FINCEN_VALIDATION_FAILED:
      return (
        <U21Chip
          color="error"
          tooltip="Submission failed because it failed validation."
          variant="ghost"
        >
          {label}
        </U21Chip>
      );
    case BE_STATUS_KEYS.FILING_FAILED:
      return (
        <U21Chip
          color="error"
          tooltip="Submission failed to send to FinCEN"
          variant="ghost"
        >
          {label}
        </U21Chip>
      );
    case BE_STATUS_KEYS.FINCEN_REJECTED:
      return (
        <U21Chip
          color="error"
          tooltip="Submission failed because FinCEN rejected"
          variant="ghost"
        >
          {label}
        </U21Chip>
      );
    case BE_STATUS_KEYS.FILING_MAX_RETRIES_EXCEEDED:
      return (
        <U21Chip color="error" variant="ghost">
          {label}
        </U21Chip>
      );
    default:
      return (
        <U21Chip tooltip="Contact Unit21 for more information" variant="ghost">
          {FILING_STATUS_DISPLAY_LABEL[FILING_STATUS_KEYS.OTHER]}
        </U21Chip>
      );
  }
};
