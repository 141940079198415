import { FallbackRender } from '@sentry/react';

import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import {
  U21Button,
  U21Spacer,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { Unit21Logo } from 'app/shared/components/logos/Unit21Logo';

interface Props {
  resetError: () => void;
}

export const RuntimeErrorFallback: FallbackRender = (props: Props) => {
  const { resetError } = props;
  const {
    location: { pathname },
  } = useHistory();

  const hasMountedRef = useRef(false);
  useEffect(() => {
    if (hasMountedRef.current) {
      resetError();
    } else {
      hasMountedRef.current = true;
    }
  }, [pathname, resetError]);

  return (
    <Container align="center" spacing={2}>
      <StyledUnit21Logo />
      <TextCenter>
        <U21Typography variant="h6">
          Something unexpected has happened. We have received the error and are
          working on it.
        </U21Typography>
        <U21Typography variant="h6">
          Please&nbsp;
          <U21Button
            color="primary"
            onClick={() => window.location.reload()}
            variant="text"
          >
            reload
          </U21Button>
          &nbsp;or&nbsp;
          <U21Button color="primary" onClick={resetError} variant="text">
            try again
          </U21Button>
          .
        </U21Typography>
      </TextCenter>
      <U21Typography color="text.secondary">
        If this issue persists, please&nbsp;
        <U21Button
          color="primary"
          href="https://unit21.zendesk.com/"
          variant="text"
        >
          contact Unit21
        </U21Button>
        &nbsp;for assistance.
      </U21Typography>
    </Container>
  );
};

const Container = styled(U21Spacer)`
  min-height: 100%;
  justify-content: center;
  padding-bottom: 200px;
`;

const StyledUnit21Logo = styled(Unit21Logo)`
  width: 300px;
  height: 100%;
`;

const TextCenter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
