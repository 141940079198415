import { ReactElement } from 'react';
import compact from 'lodash/compact';
import differenceInSeconds from 'date-fns/differenceInSeconds';

// Components
import { TaggedAgent } from 'app/modules/comments/components/TaggedAgent';

// Constants
import { AGENT_TAG_REGEX } from 'app/modules/comments/constants';

// Models
import {
  CommentModel,
  ChainalysisCommentModel,
} from 'app/modules/comments/models';

/*
   Takes the string content of a comment and returns a list of string snippets or agent tags

   Example input: `This is a comment for @[Yev](yev@unit21.ai)!`
   Example output: [`This is a comment for`, <U21Chip>Yev</U21Chip>, `!`]
*/
export const formatCommentContent = (
  content: string,
): (string | ReactElement)[] => {
  // Matches for `@[some string](another@string.com)`
  const matches = [...content.matchAll(AGENT_TAG_REGEX)];

  // If there aren't any agents to tag, we can return the entire string
  if (!matches.length) {
    return [content];
  }

  const output: (string | ReactElement)[] = [];
  let lastIndex = 0;
  matches.forEach((match) => {
    const matchString = match[0];

    // Slicing from index 2 since we want the content inside the square brackets
    // `@[What we want](some@email)
    const name = matchString.slice(2, matchString.indexOf(']'));

    // Adding remaining text up to the current tagged agent
    output.push(content.slice(lastIndex, match.index));
    lastIndex = match.index! + matchString.length;

    // Adding the tagged agent's email
    output.push(<TaggedAgent name={name} />);
  });

  // Adding leftover text after going through all agent references
  output.push(content.slice(lastIndex));

  return output;
};

export const formatChainalysisComments = (
  chainalysisComments,
): ChainalysisCommentModel[] => {
  return compact(
    chainalysisComments.map((statusComment) => {
      const { comment, updatedBy, status, updatedAt } = statusComment;
      if (comment?.length > 0) {
        return {
          author: {
            email: `Chainalysis - ${updatedBy}`,
          },
          content: `(${status}) - ${comment}`,
          raw_content: comment,
          created_at: updatedAt,
          id: `chainalysis-${updatedAt}`,
        };
      }

      return null;
    }),
  );
};

export const dedupComments = (
  u21Comments: CommentModel[],
  chainalysisComments: ChainalysisCommentModel[],
): CommentModel[] => {
  const u21CommentsMemo = u21Comments.reduce(
    (memo, comment) => ({ ...memo, [comment.content]: comment.created_at }),
    {},
  );

  const filteredChainalysisComments = compact(
    chainalysisComments.map((comment) => {
      // The comments have the exact same content
      if (u21CommentsMemo[comment.raw_content]) {
        const u21Date = new Date(u21CommentsMemo[comment.raw_content]);
        const chainalysisDate = new Date(comment.created_at || '');

        // If the Unit21 comment was created within 5 sec of the Chainalysis one, we can probably assume it's the same comment
        if (differenceInSeconds(u21Date, chainalysisDate) < 5) {
          return null;
        }
      }

      return comment;
    }),
  );

  return [...u21Comments, ...filteredChainalysisComments];
};
