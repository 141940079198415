import { AnalyticsEvents, EventConfig } from 'app/shared/u21-ui/analytics';
import { ChangeEvent } from 'react';
import { U21CheckboxProps } from 'app/shared/u21-ui/components';

export const getCheckboxName = (props: U21CheckboxProps): string => {
  const { 'aria-label': ariaLabel, label } = props;
  return typeof label === 'string' ? label : ariaLabel || '';
};

export const click: EventConfig<ChangeEvent<HTMLInputElement>> = {
  name: 'Checkbox Clicked',
  createPayload: (props: U21CheckboxProps, _, e) => {
    const name = getCheckboxName(props);
    if (!name) {
      return null;
    }
    return {
      checked: e.target.checked,
      name,
    };
  },
};

export const u21CheckboxEvents = {
  [AnalyticsEvents.U21CHECKBOX_ON_CLICK]: click,
};
