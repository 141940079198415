import { post } from 'app/shared/utils/fetchr';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { GetTransactionsPayload } from 'app/modules/transactions/requests';
import { ALERT_QUERY_KEYS } from 'app/modules/alerts/queries/keys';
import { ShortTxnResponse } from 'app/modules/transactions/types';
import { useSelector } from 'react-redux';
import { selectDLEnabledForAlertTxnList } from 'app/shared/featureFlags/selectors';

const fetchTransactionsByAlert = (
  id: number,
  body: GetTransactionsPayload,
): Promise<ShortTxnResponse[]> => post(`/alerts/${id}/transactions`, body);

export const fetchTransactionsByAlertFromCache = (
  id: number,
  body: GetTransactionsPayload,
): Promise<ShortTxnResponse[]> => {
  return post(`/alerts/${id}/paginated/transactions`, body);
};

export const useGetAlertTransactions = (
  id: number,
  body: GetTransactionsPayload,
  options?: Pick<UseQueryOptions<ShortTxnResponse[]>, 'enabled'>,
) => {
  const useCacheEndpoint = useSelector(selectDLEnabledForAlertTxnList);
  return useQuery({
    queryKey: ALERT_QUERY_KEYS.getAlertTransactions(id, body, useCacheEndpoint),
    queryFn: () =>
      useCacheEndpoint
        ? fetchTransactionsByAlertFromCache(id, body)
        : fetchTransactionsByAlert(id, body),
    meta: { errorMessage: 'Failed to fetch transactions for alert.' },
    enabled: body.limit !== 0 && id !== -1,
    ...options,
  });
};
