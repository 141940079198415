import {
  U21Chip,
  U21ShowMoreList,
  U21TableBodyCellProps,
} from 'app/shared/u21-ui/components';
import {
  IconArrowRight,
  IconBriefcase,
  IconClipboardList,
  IconUser,
} from '@u21/tabler-icons';

// Models
import {
  Investigation,
  InvestigationTableColumn,
} from 'app/modules/investigations/models';
import { ShortCaseResponse } from 'app/modules/casesOld/types';
import { ArticleDeadline } from 'app/modules/deadlines/models';
import { INVESTIGATION_COLUMN_CONFIG } from 'app/modules/investigations/columns';
import { AgentResponse } from 'app/modules/agentsOld/types/responses';

// Utils
import { ROUTES_MAP } from 'app/shared/utils/routes';
import { DeadlineChip } from 'app/modules/deadlines/components/DeadlineChip';

export const GOTO_COLUMN: InvestigationTableColumn = {
  id: 'goto',
  type: 'button',
  cellProps: (row: ShortCaseResponse) => ({
    children: <IconArrowRight />,
    to: ROUTES_MAP.casesId.path.replace(':id', String(row.id)),
  }),
  disableSortBy: true,
};

const TITLE_COLUMN: InvestigationTableColumn = {
  id: 'title',
  cellProps: { variant: 'subtitle2' },
  headerIcon: <IconBriefcase />,
  width: 300,
};

const QUEUE_COLUMN: InvestigationTableColumn = {
  id: 'queue.title',
  accessor: (row) => row['queue.title'],
  headerIcon: <IconClipboardList />,
};

const DEADLINE_COLUMN: InvestigationTableColumn = {
  id: 'deadline',
  type: 'label',
  Cell: ({
    value,
    row,
  }: U21TableBodyCellProps<Investigation, ArticleDeadline>) => (
    <DeadlineChip
      articleDeadline={value}
      deadlinesConfig={row.deadline_config || undefined}
      closed={row.status !== 'OPEN'}
    />
  ),
};

const WATCHERS_COLUMN: InvestigationTableColumn = {
  id: 'watchers',
  accessor: 'watchers',
  headerIcon: <IconUser />,
  type: 'list',
  disableSortBy: true,
  Cell: (props: U21TableBodyCellProps<Investigation, AgentResponse[]>) => {
    const { value } = props;
    return (
      <U21ShowMoreList
        displayFunc={(watcher) => {
          return <U21Chip key={watcher.id}>{watcher.full_name}</U21Chip>;
        }}
        onLessClick={(e) => e.stopPropagation()}
        onMoreClick={(e) => e.stopPropagation()}
        value={value}
      />
    );
  },
};

export const CASE_COLUMN_CONFIG = {
  ...INVESTIGATION_COLUMN_CONFIG,
  [TITLE_COLUMN.id]: TITLE_COLUMN,
  [QUEUE_COLUMN.id]: QUEUE_COLUMN,
  [DEADLINE_COLUMN.id]: DEADLINE_COLUMN,
  [WATCHERS_COLUMN.id]: WATCHERS_COLUMN,
};
