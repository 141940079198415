// Models
import {
  ResourceDropdownItem,
  SearchResponse,
  SearchResult,
  SearchTypes,
} from 'app/modules/search/models';
import { SearchResultProps } from 'semantic-ui-react';

// Components
import { IconChevronDown, IconHelpCircle, IconSearch } from '@u21/tabler-icons';
import { Menu, MenuItem, Divider, PaperProps } from '@mui/material';
import {
  U21TextField,
  U21Button,
  U21Spacer,
  U21Typography,
  U21Switch,
} from 'app/shared/u21-ui/components';
import TopSearchBarResult from 'app/modules/search/components/TopSearchBarResult';

// Selectors
import {
  selectAgent,
  selectHasPermissionsFactory,
  selectHasReadAssignmentsPermissions,
} from 'app/modules/session/selectors';

// Constants
import {
  RESOURCE_DROPDOWN_ITEMS,
  RESOURCE_TO_TYPE_DROPDOWN_OPTIONS,
  DEFAULT_SEARCH_PAGINATION_PAYLOAD,
} from 'app/modules/search/constants';

// Styles
import { experimentalStyled } from '@mui/material/styles';
import styled, { css } from 'styled-components';

// Utils
import { heapTrack } from 'app/shared/utils/heap';
import { useSelector } from 'react-redux';
import { useState, useRef, SyntheticEvent, useEffect } from 'react';
import heapEvents from 'app/shared/utils/heapEvents';
import { useHistory } from 'react-router-dom';
import { selectClassifiedQueuesEnabled } from 'app/shared/featureFlags/selectors';
import { isQueueAccessible } from 'app/modules/queues/utils';
import { useGlobalSearch } from 'app/modules/navigator/queries/useGlobalSearch';
import { useQueryClient } from '@tanstack/react-query';
import { NAVIGATOR_QUERY_KEYS } from 'app/modules/navigator/queries/keys';
import { unionBy } from 'lodash';
import { getDescriptionColor } from 'app/shared/u21-ui/components/input/select/utils';
import { LocalStorageKeys } from 'app/shared/constants/localStorage';
import { useLocalStorageState } from 'app/shared/hooks/useLocalStorage';

const SearchTypeStyle = experimentalStyled(U21Button)(({ theme }) => ({
  color: theme.palette.grey[600],
  border: '1px solid rgba(145, 158, 171, 0.32)',
  minWidth: '250px',
  height: '40px',
}));

const SearchTypeMenuItemStyle = experimentalStyled(MenuItem)(({ theme }) => ({
  padding: '10px',
  minWidth: '250px',
  color: theme.palette.grey[600],
}));

const getCacheKey = (resource: ResourceDropdownItem) =>
  `${resource.value}-${resource.type}`;

interface OwnProps {
  hardcodedResource?: string;
  placeholder?: string;
  searchResultsSelect?: (result: SearchResultProps) => void;
}

const SearchPopover = ({
  hardcodedResource,
  placeholder,
  searchResultsSelect,
}: OwnProps) => {
  const history = useHistory();
  const searchRef = useRef<HTMLDivElement>(null);
  const [searchTypeRef, setSearchTypeRef] = useState<HTMLButtonElement | null>(
    null,
  );
  const [entityIdExactMatch, setEntityIdExactMatch] =
    useLocalStorageState<boolean>(
      LocalStorageKeys.SEARCH_ENTITY_ID_EXACT_MATCH,
      true,
    );

  // filtered by permission
  const resourceItems: ResourceDropdownItem[] = RESOURCE_DROPDOWN_ITEMS.filter(
    (resourceItem) => {
      const selectHasPermission = selectHasPermissionsFactory(
        resourceItem.permission,
      );
      /* eslint-disable-next-line react-hooks/rules-of-hooks */
      return useSelector(selectHasPermission);
    },
  ).reduce(
    // Combine Event & Entity type options with resource dropdown items
    (acc, item) => {
      const subItems = RESOURCE_TO_TYPE_DROPDOWN_OPTIONS[item.value];
      return [
        ...acc,
        ...(subItems
          ? subItems.map((subItem) => ({
              ...item,
              icon: item.icon,
              text: `${item.text} - ${subItem.text}`,
              placeholder: `${item.placeholder} ${subItem.placeholder}`,
              type: subItem.value,
            }))
          : [item]),
      ];
    },
    [],
  );

  const [phrase, setPhrase] = useState<string>('');
  const [selectedResource, setSelectedResource] =
    useState<ResourceDropdownItem | null>(() => {
      const cachedOption = localStorage.getItem(
        LocalStorageKeys.GLOBAL_SEARCH_OPTION,
      );
      return (
        resourceItems.find((item) => {
          if (hardcodedResource) {
            return item.value === hardcodedResource;
          }
          if (cachedOption) {
            return getCacheKey(item) === cachedOption;
          }
          return false;
        }) ||
        resourceItems[0] ||
        null
      );
    });

  useEffect(() => {
    if (selectedResource) {
      localStorage.setItem(
        LocalStorageKeys.GLOBAL_SEARCH_OPTION,
        getCacheKey(selectedResource),
      );
    }
  }, [selectedResource]);

  const hasReadAssignmentsPermission = useSelector(
    selectHasReadAssignmentsPermissions,
  );
  const classifiedQueuesEnabled = useSelector(selectClassifiedQueuesEnabled);
  const sessionAgent = useSelector(selectAgent);

  const { data, isFetching, isLoading, refetch } = useGlobalSearch(
    selectedResource?.value,
    {
      phrase,
      ...DEFAULT_SEARCH_PAGINATION_PAYLOAD,
      type: selectedResource?.type,
      ...(entityIdExactMatch &&
      selectedResource?.value === SearchTypes.ENTITIES &&
      selectedResource.type === 'external_id'
        ? { external_ids: phrase.split(',').map((id) => id.trim()) }
        : {}),
    },
  );

  const handleSearchResultSelect = (
    e: SyntheticEvent,
    result: SearchResultProps,
  ) => {
    if (searchResultsSelect) {
      searchResultsSelect(result);
      return;
    }

    if (typeof result.url === 'string') {
      // Block routing if 'result' is not in an `OPEN`
      // queue and agent does not have access to the queue.
      // Non-alert/cases do not have queueAccessType and will not be blocked.
      if (
        classifiedQueuesEnabled &&
        !isQueueAccessible(
          hasReadAssignmentsPermission,
          sessionAgent.accessible_queues,
          result.queueId,
          result.queueAccessType,
        )
      ) {
        e.stopPropagation();
        return;
      }

      // clear phrase before routing
      setPhrase('');
      history.push(result.url);

      // Analytics
      heapTrack(heapEvents.navigator.topBarClickedResult, { url: result.url });
    }
  };

  const [open, setOpen] = useState(false);

  const queryClient = useQueryClient();

  if (!selectedResource) {
    // empty div used to keep the right side stuff on the right
    return <EmptyDiv />;
  }

  return (
    <SearchPopoverContainer horizontal spacing={2}>
      {!hardcodedResource && (
        <SearchTypeStyle
          variant="outlined"
          endIcon={<IconChevronDown />}
          startIcon={<selectedResource.icon />}
          onClick={(e) => setSearchTypeRef(e.currentTarget)}
        >
          {selectedResource.text}
        </SearchTypeStyle>
      )}
      {!hardcodedResource && (
        <Menu
          keepMounted
          anchorEl={searchTypeRef}
          open={Boolean(searchTypeRef)}
          onClose={() => setSearchTypeRef(null)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          {resourceItems.map(
            (resourceItem: ResourceDropdownItem, idx: number) => [
              idx > 0 && resourceItem.icon !== resourceItems[idx - 1].icon && (
                <Divider />
              ),
              <SearchTypeMenuItemStyle
                key={resourceItem.text}
                selected={resourceItem === selectedResource}
                onClick={() => {
                  setSelectedResource(resourceItem);
                  setPhrase('');
                  setSearchTypeRef(null);
                }}
                sx={{ typography: 'body2' }}
              >
                <DropdownItemIconWrapper>
                  <resourceItem.icon />
                </DropdownItemIconWrapper>
                {resourceItem.value === SearchTypes.ENTITIES &&
                resourceItem.type === 'external_id' ? (
                  <U21Spacer horizontal>
                    <U21Spacer>
                      <U21Spacer horizontal>
                        <span>{resourceItem.text}</span>
                        <U21Button
                          aria-label="tooltip"
                          color="info"
                          icon={<IconHelpCircle style={{ margin: '0' }} />}
                          size="small"
                          tooltip="Entity ID search can take some time. Toggle 'exact match' to 'On' to enhance search performance and reduce load time."
                          onClick={(e) => e.stopPropagation()}
                        />
                      </U21Spacer>
                      <U21Typography
                        variant="caption"
                        color={getDescriptionColor('warning')}
                      >
                        Toggle is &apos;{entityIdExactMatch ? 'On' : 'Off'}
                        &apos; for exact match search
                      </U21Typography>
                    </U21Spacer>
                    <U21Switch
                      onChange={(checked) => setEntityIdExactMatch(checked)}
                      onClick={(e) => e.stopPropagation()}
                      checked={entityIdExactMatch}
                    />
                  </U21Spacer>
                ) : (
                  resourceItem.text
                )}
              </SearchTypeMenuItemStyle>,
            ],
          )}
        </Menu>
      )}
      <StyledU21TextField
        loading={isLoading}
        onBlur={() => setOpen(false)}
        onChange={(val: string = '') => setPhrase(val)}
        onFocus={() => setTimeout(() => setOpen(true), 200)}
        onKeyPress={(e) => {
          if (e.code === 'Enter' && !isFetching) {
            // only refetch if we don't have data yet
            if (data === undefined) {
              refetch();
            }
          }
        }}
        placeholder={placeholder || selectedResource.placeholder}
        ref={searchRef}
        startIcon={<IconSearch />}
        value={phrase}
      />
      <Menu
        anchorEl={searchRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        autoFocus={false}
        disableAutoFocus
        onClose={() => setOpen(false)}
        open={open}
        PaperProps={{ component: MenuContainer } as PaperProps}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        {(() => {
          if (isLoading) {
            return <MenuItem disabled>Loading...</MenuItem>;
          }

          if (data) {
            // check phrase as well since if no phrase, we should display all cached options
            // if we didn't have this check we would only display the empty phrase options
            if (data.data.length && phrase) {
              return data.data.map((i) => {
                const formattedResult = selectedResource.formatSearchResult(i);
                return (
                  <MenuItem
                    key={formattedResult.value}
                    onClick={(e) =>
                      handleSearchResultSelect(e, formattedResult)
                    }
                  >
                    <TopSearchBarResult {...formattedResult} />
                  </MenuItem>
                );
              });
            }

            if (data.data.length && !phrase) {
              const cachedQueryData = selectedResource?.value
                ? queryClient.getQueriesData<SearchResponse>({
                    queryKey: NAVIGATOR_QUERY_KEYS.search(
                      selectedResource?.value,
                    ),
                  })
                : [];

              const allSearchResults = cachedQueryData.reduce<SearchResult[]>(
                (acc, i) => {
                  const [, queryData] = i;
                  if (queryData) {
                    return unionBy(queryData.data, acc, 'id');
                  }
                  return acc;
                },
                [],
              );

              // show all cached data if no phrase
              if (allSearchResults.length) {
                return allSearchResults.map((i) => {
                  const formattedResult =
                    selectedResource.formatSearchResult(i);
                  return (
                    <MenuItem
                      key={formattedResult.value}
                      onClick={(e) =>
                        handleSearchResultSelect(e, formattedResult)
                      }
                    >
                      <TopSearchBarResult {...formattedResult} />
                    </MenuItem>
                  );
                });
              }
              return <MenuItem disabled>No results found</MenuItem>;
            }

            if (!data.data.length && phrase) {
              return <MenuItem disabled>No results found</MenuItem>;
            }
          }

          if (!phrase) {
            return <MenuItem disabled>Type to search</MenuItem>;
          }

          if (phrase.length <= 1) {
            return <MenuItem disabled>Press enter to search</MenuItem>;
          }
          return <MenuItem disabled>No results found</MenuItem>;
        })()}
      </Menu>
    </SearchPopoverContainer>
  );
};

const EmptyDiv = styled.div`
  flex: 1;
`;

const StyledU21TextField = styled(U21TextField)`
  .MuiInputBase-root {
    max-width: 400px;

    ${(props) => css`
      transition: ${props.theme.transitions.create(['all'])};
    `}

    &.Mui-focused {
      box-shadow: ${(props) => props.theme.customShadows.z8};
      max-width: 550px;
    }
  }
`;

const MenuContainer = styled.div`
  max-height: 40vh;
  width: 550px;
`;

const SearchPopoverContainer = styled(U21Spacer)`
  flex: 1;
  height: 100%;
`;

const DropdownItemIconWrapper = styled.div`
  margin-right: 15px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default SearchPopover;
