// Components
import AccountPopover from 'app/modules/navigator/components/AccountPopover';
import SearchPopover from 'app/modules/navigator/components/SearchPopover';
import ToastMessages from 'app/shared/toasts/components/ToastMessages';
import { DevToolsPopover } from 'app/modules/devtools/components/DevToolsPopover';
import { NotificationPopover } from 'app/modules/notifications/components/NotificationPopover';
import { VersionOutdatedIndicator } from 'app/modules/navigator/components/VersionOutdatedIndicator';
import { U21Button, U21Spacer } from 'app/shared/u21-ui/components';
import { DatastoreIndicator } from 'app/modules/devtools/components/DatastoreIndicator';
import { IconSpeakerphone } from '@u21/tabler-icons';
import { SwitchedOrgBanner } from 'app/modules/navigator/components/SwitchedOrgBanner';

// Styles
import styled, { css } from 'styled-components';

// Utils
import { getIsOrgSwitcherAgentSet } from 'app/modules/session/helpers';

import { CHANGELOG_DOCS } from 'app/shared/constants/docs';
import { GlobalAiChatModal } from 'app/modules/navigator/components/GlobalAiChatModal';
import { U21_DOCS_PASSWORD_TOOLTIP } from 'app/shared/u21-ui/components/dashboard';

const TopBar = () => {
  const isOrgSwitcherAgentSet = getIsOrgSwitcherAgentSet();

  return (
    <RootStyle>
      <ToastMessages />
      <MainBar $isOrgSwitched={isOrgSwitcherAgentSet} horizontal spacing={2}>
        <SearchPopover />
        <U21Spacer horizontal spacing={0.5}>
          <DatastoreIndicator />
          <DevToolsPopover />
          <GlobalAiChatModal />
          <VersionOutdatedIndicator />
          <U21Button
            aria-label="Announcements"
            href={CHANGELOG_DOCS}
            icon={<IconSpeakerphone />}
            tooltip={
              <U21Spacer align="center" dividers spacing={0.5} useFlexGap>
                <span>Announcements</span>
                {U21_DOCS_PASSWORD_TOOLTIP}
              </U21Spacer>
            }
          />
          <NotificationPopover />
          <AccountPopover />
        </U21Spacer>
      </MainBar>
      <SwitchedOrgBanner isOrgSwitched={isOrgSwitcherAgentSet} />
    </RootStyle>
  );
};

const RootStyle = styled.div`
  grid-area: TopBar;
  /* hide this component in pdf exports */
  @media print {
    display: none;
  }
`;

interface MainBarStyleProps {
  $isOrgSwitched?: boolean;
}

const MainBar = styled(U21Spacer)<MainBarStyleProps>`
  height: 72px;
  background: ${(props) => props.theme.palette.background.paper};
  display: flex;
  align-items: center;
  padding: 0 20px;
  ${(props) => {
    if (props.$isOrgSwitched) {
      return css``;
    }
    return css`
      border-bottom: 1px solid ${props.theme.palette.grey[500_24]};
    `;
  }}
`;

export default TopBar;
