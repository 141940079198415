import React from 'react';
import { isEmpty } from 'lodash';

// Components
import { Segment } from 'semantic-ui-react';
import DispositionDetails from 'app/modules/alerts/components/DispositionDetails';

// Types
import { AuditTrailActionTriggerDigest } from 'app/shared/auditTrail/types';

// Styles
import styles from 'app/shared/auditTrail/styles/AuditTrail.module.scss';
import { StyleConstants } from 'app/styles/StyleConstants';

interface OwnProps {
  actionTriggerDigest: AuditTrailActionTriggerDigest;
}

const ActionTriggerDigestSummary = ({ actionTriggerDigest }: OwnProps) => {
  if (!actionTriggerDigest || isEmpty(actionTriggerDigest)) {
    return null;
  }
  return (
    <Segment.Group className={styles.noBorderContainer}>
      <Segment.Group horizontal>
        <Segment style={StyleConstants.HALF_COLUMN_SEGMENT}>
          <DispositionDetails obj={actionTriggerDigest} />
        </Segment>
        <Segment style={StyleConstants.HALF_COLUMN_SEGMENT} />
      </Segment.Group>
    </Segment.Group>
  );
};

export default ActionTriggerDigestSummary;
