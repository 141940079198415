import { useRef, useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

// Routes
import { ROUTES_MAP } from 'app/shared/utils/routes';

// Constants
import { OFFSET_DEFAULT } from 'app/shared/constants';

// Components
import { NotificationPopoverItem } from 'app/modules/notifications/components/NotificationPopoverItem';
import {
  U21Badge,
  U21Button,
  U21Divider,
  U21NoData,
  U21Spacer,
  U21Typography,
  U21Loading,
  U21MenuLayout,
} from 'app/shared/u21-ui/components';
import { IconBell, IconChecks } from '@u21/tabler-icons';

// Selectors
import { selectAgent } from 'app/modules/session/selectors';
import {
  selectTopbarNotifications,
  selectUnreadNotificationsCount,
  selectLoadingTopbarNotifications,
} from 'app/modules/notifications/selectors';

// Thunks
import {
  retrieveTopbarNotificationsThunk,
  markAllNotificationsReadThunk,
} from 'app/modules/notifications/sliceNotifications';

export const NotificationPopover = () => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const notifications = useSelector(selectTopbarNotifications);
  const loadingTopbarNotifications = useSelector(
    selectLoadingTopbarNotifications,
  );
  const agent = useSelector(selectAgent);
  const notificationsUnread = useMemo(() => {
    return notifications.filter((item) => item.status === 'UNREAD');
  }, [notifications]);

  const notificationsRead = useMemo(() => {
    return notifications.filter((item) => item.status !== 'UNREAD');
  }, [notifications]);

  const totalUnread = useSelector(selectUnreadNotificationsCount);

  const dispatch = useDispatch();

  useEffect(() => {
    const payload = {
      agent_id: agent.id,
      offset: OFFSET_DEFAULT,
      limit: 6,
    };
    dispatch(retrieveTopbarNotificationsThunk(payload));
  }, [agent.id, dispatch]);

  const handleMarkAllAsRead = () => {
    dispatch(markAllNotificationsReadThunk());
  };

  return (
    <StyledU21MenuLayout
      alignRight
      onClose={() => setOpen(false)}
      open={open}
      trigger={
        <U21Button
          aria-label="Notifications"
          icon={
            <U21Badge content={totalUnread}>
              <IconBell />
            </U21Badge>
          }
          ref={anchorRef}
          onClick={() => setOpen(true)}
          tooltip="Notifications"
        />
      }
    >
      <Container>
        <StyledHeader horizontal>
          <U21Spacer horizontal spacing={2}>
            <IconBell />
            <U21Spacer>
              <U21Typography variant="subtitle1">Notifications</U21Typography>
              <U21Typography color="text.secondary" variant="body2">
                You have {totalUnread} unread notification(s)
              </U21Typography>
            </U21Spacer>
          </U21Spacer>

          <U21Button
            color="primary"
            onClick={() => {
              setOpen(false);
            }}
            to={ROUTES_MAP.notifications.path}
          >
            View all
          </U21Button>
        </StyledHeader>

        <U21Divider horizontal />

        <NotificationsContainer>
          {loadingTopbarNotifications && <StyledU21Loading loading />}
          {notificationsUnread.length > 0 && (
            <>
              <UnreadContainer horizontal>
                <U21Typography variant="overline">New</U21Typography>
                {totalUnread > 0 && (
                  <U21Button
                    icon={<IconChecks />}
                    tooltip="Mark all as read"
                    onClick={handleMarkAllAsRead}
                  />
                )}
              </UnreadContainer>
              <StyledList>
                {notificationsUnread.map((notification) => (
                  <NotificationPopoverItem
                    key={notification.id}
                    notification={notification}
                    setClose={() => setOpen(false)}
                  />
                ))}
              </StyledList>
            </>
          )}
          {notificationsRead.length > 0 && (
            <>
              <ReadContainer horizontal>
                <U21Typography variant="overline">Before that</U21Typography>
              </ReadContainer>

              <U21Divider horizontal />

              <StyledList>
                {notificationsRead.map((notification) => (
                  <NotificationPopoverItem
                    key={notification.id}
                    notification={notification}
                    setClose={() => setOpen(false)}
                  />
                ))}
              </StyledList>
            </>
          )}
          {!loadingTopbarNotifications &&
            notificationsUnread.length === 0 &&
            notificationsRead.length === 0 && <StyledU21NoData />}
        </NotificationsContainer>
      </Container>
    </StyledU21MenuLayout>
  );
};

const Container = styled.div`
  padding: 8px;
`;

const StyledU21MenuLayout = styled(U21MenuLayout)`
  .MuiPopover-paper {
    width: 450px;
  }
`;

const NotificationsContainer = styled(U21Spacer)`
  &::-webkit-scrollbar {
    display: none;
  }
  height: 340px;
  overflow-y: scroll;
`;

const StyledHeader = styled(U21Spacer)`
  justify-content: space-between;
  padding: 16px;
`;

const UnreadContainer = styled(U21Spacer)`
  justify-content: space-between;
  padding: 0px 17px 0px 17px;
  margin-bottom: -8px;
`;

const ReadContainer = styled(U21Spacer)`
  padding: 8px 17px 0px 17px;
`;

const StyledList = styled.ul`
  margin: 0px;
`;

const StyledU21NoData = styled(U21NoData)`
  padding-top: 32px;
`;

const StyledU21Loading = styled(U21Loading)`
  height: inherit;
`;
