import { useSelector } from 'react-redux';

// Components
import { U21BadgeProps } from 'app/shared/u21-ui/components';

import { TabLabel } from 'app/modules/investigations/components/InvestigationTabs/TabLabel';

// Selectors
import {
  selectCustomChecklistValidated,
  selectCustomSubmissionId,
} from 'app/shared/customChecklist/selectors';

type Color = U21BadgeProps['color'];

const getColorAndTooltip = (
  isValidated: boolean,
  previouslySaved: boolean,
): [Color, string] => {
  if (isValidated) {
    return ['success', 'Investigation checklist has been completed'];
  }

  if (previouslySaved) {
    return [
      'error',
      'Investigation checklist has been previously saved but not completed',
    ];
  }

  return ['warning', 'Investigation checklist has not been started'];
};

export const InvestigationChecklistLabel = () => {
  const isValidated = useSelector(selectCustomChecklistValidated);
  const previouslySaved = useSelector(selectCustomSubmissionId);

  const [badgeColor, tooltip] = getColorAndTooltip(
    isValidated,
    Boolean(previouslySaved),
  );

  return (
    <TabLabel
      label="Investigation checklist"
      tooltip={tooltip}
      badgeProps={{
        color: badgeColor,
        variant: 'dot',
      }}
    />
  );
};
