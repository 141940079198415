// React
import { useMemo } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';

// Thunk
import { retrieveAssociatedAgentsThunk } from 'app/modules/queues/sliceQueues';

// Components
import {
  U21MenuList,
  U21MenuListOptionProps,
  U21Avatar,
} from 'app/shared/u21-ui/components';

// Selectors
import { selectAssociatedAgents } from 'app/modules/queues/selectors';

import { QueueType } from 'app/modules/queues/models';
import { getAgentDisplayName } from 'app/modules/agents/utils';
import { editSarReviewerThunk as editSarReviewerNewThunk } from 'app/modules/fincenSarNew/sliceFincenSar';

interface OwnProps {
  sarId: number;
  queueId?: number;
  onSelect: () => void;
}

export const ReassignSarReviewerSelect = ({
  sarId,
  onSelect,
  queueId,
}: OwnProps) => {
  const agents = useSelector(selectAssociatedAgents);

  const dispatch = useDispatch();

  const newAgents: U21MenuListOptionProps[] = useMemo(() => {
    const handleAgentSelect = (reviewerId: number) => {
      const payload = { reviewer_id: reviewerId, sarId };
      dispatch(editSarReviewerNewThunk(payload));
      onSelect();
    };

    return agents.map((agent) => {
      return {
        text: getAgentDisplayName(agent),
        onClick: (e) => {
          e.stopPropagation();
          handleAgentSelect(agent.id);
        },
        icon: (
          <U21Avatar
            name={agent.full_name || agent.email}
            src={agent.picture}
          />
        ),
      };
    });
  }, [agents, sarId, dispatch, onSelect]);

  const handleOnSearch = (agentName: string) => {
    dispatch(
      retrieveAssociatedAgentsThunk({
        search_query: agentName,
        queue_type: QueueType.SAR_QUEUE,
        alert_queue_id: queueId,
      }),
    );
  };
  return (
    <U21MenuList
      options={newAgents}
      placeholder="Search agents"
      onSearch={handleOnSearch}
    />
  );
};
