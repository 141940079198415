import {
  U21Modal,
  U21Spacer,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { U21DataLabelSelect } from 'app/shared/u21-ui/components/dashboard';
import { ReactNode, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectIsDataConfigDeltaLake } from 'app/modules/orgSettings/selectors';
import { useGetCgdoDataLabelRecentEdit } from 'app/modules/dataLabels/queries/useGetDataLabelRecentEdit';
import { formatDatetime } from 'app/shared/utils/date';

interface EditDataLabelsModalProps {
  tagIds: (string | number)[];
  editObjectTags: (tagIds: (string | number)[]) => Promise<void>;
  isEditLoading: boolean;
  title: ReactNode;
  open: boolean;
  handleClose: () => void;
  objectType: 'ENTITY' | 'INSTRUMENT' | 'TXN_EVENT' | 'DEVICE';
  objectExternalId: string;
}

export const EditDataLabelsModal = ({
  tagIds: objectTagIds,
  editObjectTags,
  isEditLoading,
  title,
  open,
  handleClose,
  objectType,
  objectExternalId,
}: EditDataLabelsModalProps) => {
  const { data: recentEdit } = useGetCgdoDataLabelRecentEdit(
    objectExternalId,
    objectType,
  );
  const isDataConfigDeltalake = useSelector(selectIsDataConfigDeltaLake);
  const [tagIdsStaged, setTagIdStaged] =
    useState<(number | string)[]>(objectTagIds);
  const tagUpdateStartedAt = recentEdit?.data[0]?.started_at;
  const shouldDisableEdit = !!tagUpdateStartedAt;
  const handleChange = (tagIds: (string | number)[]) => {
    setTagIdStaged(tagIds);
  };

  return (
    <U21Modal
      actionButtonProps={{
        loading: isEditLoading || shouldDisableEdit,
        tooltip: tagUpdateStartedAt
          ? `Update requested at ${formatDatetime(tagUpdateStartedAt)}`
          : undefined,
      }}
      open={open}
      title={title}
      onClose={() => {
        handleClose();
        setTagIdStaged(objectTagIds);
      }}
      onAction={async () => {
        try {
          await editObjectTags(tagIdsStaged);
          handleClose();
        } catch {
          // No-op because the error has already been handled.
        }
      }}
    >
      <U21Spacer spacing={2}>
        <U21Typography color="text.secondary">
          Add or remove tags using the dropdown below.
        </U21Typography>
        <U21DataLabelSelect
          onChange={handleChange}
          value={tagIdsStaged}
          label="Tags"
        />
        {isDataConfigDeltalake && (
          <U21Typography color="text.secondary">
            Note: It will take several minutes for this change to take effect,
            and you will not be able to edit the tags for this object while the
            update is in progress.
          </U21Typography>
        )}
      </U21Spacer>
    </U21Modal>
  );
};
