import { initAnalytics, AnalyticsConfig } from 'app/shared/u21-ui/analytics';

import { heapTrack } from 'app/shared/utils/heap';
import { u21ButtonEvents } from 'app/shared/utils/analytics/u21Button';
import { u21CheckboxEvents } from 'app/shared/utils/analytics/u21Checkbox';
import { u21DatePickerEvents } from 'app/shared/utils/analytics/u21DatePicker';
import { u21DateRangePickerEvents } from 'app/shared/utils/analytics/u21DateRangePicker';
import { u21DateTimePickerEvents } from 'app/shared/utils/analytics/u21DateTimePicker';
import { u21MenuItemEvents } from 'app/shared/utils/analytics/u21MenuItem';
import { u21MultiSelectEvents } from 'app/shared/utils/analytics/u21Multiselect';
import { u21PaginationTableEvents } from 'app/shared/utils/analytics/u21PaginationTable';
import { u21SelectEvents } from 'app/shared/utils/analytics/u21Select';

// Config to track events on the u21 ui lib
export const ANALYTICS_CONFIG: AnalyticsConfig = {
  track: (eventName, properties = {}) => {
    heapTrack(`UI Library - ${eventName}`, { ...properties, u21lib: true });
  },
  events: {
    ...u21ButtonEvents,
    ...u21CheckboxEvents,
    ...u21DatePickerEvents,
    ...u21DateRangePickerEvents,
    ...u21DateTimePickerEvents,
    ...u21MenuItemEvents,
    ...u21MultiSelectEvents,
    ...u21PaginationTableEvents,
    ...u21SelectEvents,
  },
};

export const initUILibAnalytics = (
  config: AnalyticsConfig = ANALYTICS_CONFIG,
) => {
  initAnalytics(config);
};
