import { ListNarrativesPayload } from 'app/modules/narratives/requests';

const BASE = ['narratives'];

export const NARRATIVE_QUERY_KEYS = {
  getNarratives: (payload: ListNarrativesPayload) => [
    ...BASE,
    'narratives',
    payload,
  ],
};
