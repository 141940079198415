import React, { Component, MouseEvent as ReactMouseEvent } from 'react';
import numeral from 'numeral';
import { withRouter, RouteComponentProps } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// Models
import { PaginationPayload } from 'app/shared/pagination/models';
import {
  RetrieveAlertEntitiesPayload,
  AlertDetails,
} from 'app/modules/alerts/models';
import { EntityType, EntityAlertsPayload } from 'app/modules/entities/models';
import { Segment } from 'semantic-ui-react';

// Components
import AssociatedEntitiesTable from 'app/modules/alerts/components/AssociatedEntitiesTable';
import LoadingWrapper from 'app/shared/components/LoadingWrapper';
import BasicObjectDetails from 'app/modules/entities/components/BasicObjectDetails';
import NoResultsComponent from 'app/shared/components/NoResultsComponent';
import TagsAndComments from 'app/modules/entities/components/TagsAndComments';
import TriggeredAlertList from 'app/shared/components/TriggeredAlertList';
import CollapsibleDropdown from 'app/shared/components/CollapsibleDropdown';
import CustomDataTable from 'app/shared/components/CustomDataTable';

// Selectors
import {
  selectAlertEntities,
  selectAlertEntitiesLoading,
  selectRetrieveAlertEntitiesLoading,
} from 'app/modules/alerts/selectors';
import {
  selectEntity,
  selectRetrieveEntityLoading,
  selectEditEntityLoading,
  selectEntityAlertsLoading,
  selectPaginatedEntityAlerts,
} from 'app/modules/entities/selectors';
import { selectLumosConfig } from 'app/modules/orgSettings/selectors';
import { selectEntityTableConfig } from 'app/shared/CustomConfig/selectors';

// Actions
import {
  retrieveAlertEntities as retrieveAlertEntitiesAction,
  clearAlertEntities as clearAlertEntitiesAction,
} from 'app/modules/alerts/actions';
import {
  retrieveEntity as retrieveEntityAction,
  clearAllEntityState as clearAllEntityStateAction,
  editEntity as editEntityAction,
  retrieveEntityAlerts as retrieveEntityAlertsAction,
} from 'app/modules/entities/actions';

// Constants
import { DEFAULT_PAGINATION_PAYLOAD } from 'app/shared/pagination/constants';
import {
  DEFAULT_TAB_LOADING_HEIGHT,
  STANDARD_NUMBER_FORMAT,
} from 'app/shared/constants';

// Utils
import routes, { openInNewTab } from 'app/shared/utils/routes';
import assets from 'app/shared/utils/assets';

// Helpers
import { filterEntityCustomData } from 'app/modules/entities/helpers';
import { getA11yClickProps } from 'app/shared/utils/a11y';

// Styles
import styles from 'app/modules/alerts/styles/AssociatedEntitiesPage.module.scss';
import { PaginationSettings } from 'app/shared/models';
import { INITIAL_TRIGGERED_ALERT_PAGINATION_SETTINGS } from 'app/modules/rules/constants';

interface OwnProps {
  alertId: number;
  alert: AlertDetails;
  entityType: EntityType;
}

const mapStateToProps = (state: RootState) => ({
  alertEntities: selectAlertEntities(state),
  alertEntitiesLoading: selectAlertEntitiesLoading(state),
  entity: selectEntity(state),
  entityTableConfig: selectEntityTableConfig(state),
  retrieveEntityLoading: selectRetrieveEntityLoading(state),
  editEntityLoading: selectEditEntityLoading(state),
  entityAlertsLoading: selectEntityAlertsLoading(state),
  entityAlerts: selectPaginatedEntityAlerts(state),
  retrieveAlertEntitiesLoading: selectRetrieveAlertEntitiesLoading(state),
  lumosConfig: selectLumosConfig(state),
});

const mapDispatchToProps = {
  retrieveAlertEntities: retrieveAlertEntitiesAction,
  retrieveEntity: retrieveEntityAction,
  clearAllEntityState: clearAllEntityStateAction,
  editEntity: editEntityAction,
  retrieveEntityAlerts: retrieveEntityAlertsAction,
  clearAlertEntities: clearAlertEntitiesAction,
};

type AllProps = OwnProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteComponentProps;

interface AllState {
  selectedEntityId: number | string;
  selectedEntityExternalId: string;
  triggeredAlertsPaginationSettings: PaginationSettings;
}

const initialState: AllState = {
  selectedEntityId: -1,
  selectedEntityExternalId: '',
  triggeredAlertsPaginationSettings: {
    ...INITIAL_TRIGGERED_ALERT_PAGINATION_SETTINGS,
  },
};

class AssociatedEntitiesPage extends Component<AllProps, AllState> {
  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
    };

    this.retrieveEntities = this.retrieveEntities.bind(this);
    this.retrieveEntityAlerts = this.retrieveEntityAlerts.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleGotoEntity = this.handleGotoEntity.bind(this);
    this.renderEntityDetails = this.renderEntityDetails.bind(this);
    this.renderTriggeredAlerts = this.renderTriggeredAlerts.bind(this);
  }

  componentDidMount() {
    this.retrieveEntities({ ...DEFAULT_PAGINATION_PAYLOAD });
  }

  componentDidUpdate(prevProps: AllProps) {
    const {
      alertEntities,
      clearAllEntityState,
      entity: { id },
      entityType,
      retrieveEntity,
    } = this.props;
    const { selectedEntityId } = this.state;

    // used in users and businesses tab so need to reset data and get new entities on entity type switch
    if (prevProps.entityType !== entityType) {
      this.setState({ ...initialState });
      clearAllEntityState();
      this.retrieveEntities({ ...DEFAULT_PAGINATION_PAYLOAD });
    }
    // used to populate the first paginated alerts. can not do in handleRowClick b/c the entity result from retrieveEntity will override the paginated_alerts in the redux state
    if (prevProps.entity.id !== id && selectedEntityId !== -1) {
      this.retrieveEntityAlerts({ ...DEFAULT_PAGINATION_PAYLOAD });
    }
    // if there is only entity and it is not selected, select it
    if (
      prevProps.alertEntities.length <= 0 &&
      alertEntities.length === 1 &&
      selectedEntityId === initialState.selectedEntityId
    ) {
      const firstId = alertEntities[0].id;
      const firstExternalId = alertEntities[0].external_id;
      this.setState({
        selectedEntityId: firstId,
        selectedEntityExternalId: firstExternalId,
      });
      retrieveEntity(String(firstId), true);
    }
  }

  componentWillUnmount() {
    const { clearAllEntityState, clearAlertEntities } = this.props;
    // clear any stale state on unmount
    clearAllEntityState();
    clearAlertEntities();
  }

  handleRowClick(e: MouseEvent, row) {
    const { retrieveEntity, clearAllEntityState, alertEntities } = this.props;
    const { selectedEntityId } = this.state;
    const { id } = row;

    const path = routes.lumos.entitiesId.replace(':id', String(id));
    // open new entity tab if metaKey selected
    if (e.metaKey) {
      openInNewTab(path);
      return;
    }

    // return if there is only one entity and it is already selected
    if (alertEntities.length === 1 && selectedEntityId === row.id) {
      return;
    }

    // retrieve new entity if new entity is selected
    if (selectedEntityId !== id) {
      this.setState(
        { selectedEntityId: row.id, selectedEntityExternalId: row.external_id },
        () => {
          retrieveEntity(String(row.id), true);
        },
      );
      return;
    }
    // otherwise, clear entity data and reset entity id
    this.setState(
      {
        selectedEntityId: initialState.selectedEntityId,
        selectedEntityExternalId: initialState.selectedEntityExternalId,
      },
      clearAllEntityState,
    );
  }

  // type issues with using generic mouse event on span
  handleGotoEntity(e: ReactMouseEvent) {
    const {
      history: { push },
    } = this.props;
    const { selectedEntityId } = this.state;
    // return if no entity id selected
    if (selectedEntityId === initialState.selectedEntityId) {
      return;
    }

    const path = routes.lumos.entitiesId.replace(
      ':id',
      String(selectedEntityId),
    );

    // open new entity tab if metaKey selected
    if (e.metaKey) {
      openInNewTab(path);
      return;
    }

    push(path);
  }

  retrieveEntities(payload: PaginationPayload) {
    const { alertId, retrieveAlertEntities, entityType } = this.props;
    const newPayload: RetrieveAlertEntitiesPayload = {
      entity_type: entityType,
      alertId: String(alertId),
      ...payload,
    };
    retrieveAlertEntities(newPayload);
  }

  retrieveEntityAlerts(payload: PaginationPayload) {
    const { selectedEntityExternalId } = this.state;
    const { retrieveEntityAlerts, alertId } = this.props;
    const formattedPayload: EntityAlertsPayload = {
      entityExternalId: selectedEntityExternalId,
      excluded_alert_ids: [alertId],
      ...payload,
    };

    this.setState((state) => ({
      triggeredAlertsPaginationSettings: {
        pageLimit: payload.limit,
        pageOffset: payload.offset,
        totalPages: state.triggeredAlertsPaginationSettings.totalPages,
      },
    }));

    retrieveEntityAlerts(formattedPayload);
  }

  renderTriggeredAlerts() {
    const { entityAlerts, entityAlertsLoading } = this.props;
    const { triggeredAlertsPaginationSettings } = this.state;

    const formattedTotal = numeral(entityAlerts.count).format(
      STANDARD_NUMBER_FORMAT,
    );

    const { count } = entityAlerts;

    return (
      <div className={styles.triggeredAlertsContainer}>
        <LoadingWrapper
          styleProps={{ height: '60px' }}
          loading={entityAlertsLoading}
        >
          {/* hide triggered alert list if empty */}
          {count <= 0 ? null : (
            <CollapsibleDropdown
              label={
                <div
                  className={styles.triggeredAlertsLabel}
                >{`${formattedTotal} Flagged Alerts`}</div>
              }
              loading={entityAlertsLoading}
              labelClassNameProps={styles.triggeredAlertsLabelContainer}
              initialCollapse
              dropdown={
                <TriggeredAlertList
                  alerts={entityAlerts.alerts}
                  alertCount={entityAlerts.count}
                  retrieveAlertsLoading={entityAlertsLoading}
                  getNextPage={this.retrieveEntityAlerts}
                  paginationSettings={triggeredAlertsPaginationSettings}
                />
              }
            />
          )}
        </LoadingWrapper>
      </div>
    );
  }

  renderEntityDetails() {
    const {
      retrieveEntityLoading,
      alert,
      entity,
      editEntityLoading,
      editEntity,
      alertEntitiesLoading,
      lumosConfig,
    } = this.props;
    const { selectedEntityId } = this.state;

    const entitiesExist: boolean = Boolean(
      Array.isArray(alert.entities) && alert.entities.length > 0,
    );

    // if no entities exist or entities table is loading, return null
    if (!entitiesExist || alertEntitiesLoading) {
      return null;
    }

    // return empty UI if entities exist but are not selected
    if (
      selectedEntityId === initialState.selectedEntityId &&
      entitiesExist &&
      !retrieveEntityLoading
    ) {
      return <NoResultsComponent title="Select an entity above" />;
    }
    // need the any b/c the selector return value is typed incorrectly as immutable, fixing that causes bugs in a lot of other places
    const selectedTags: any = Array.isArray(entity.tags)
      ? entity.tags.map((tag) => tag.id)
      : [];

    const loading = retrieveEntityLoading;
    const name = entity.name_readable || entity.external_id;

    // return UI
    return (
      <div className={styles.entityDetailsContainer}>
        <LoadingWrapper
          loading={loading}
          styleProps={{ height: DEFAULT_TAB_LOADING_HEIGHT }}
        >
          {this.renderTriggeredAlerts()}
          <Segment.Group>
            <Segment color="blue" textAlign="center">
              <span
                className={styles.clickable}
                {...getA11yClickProps(this.handleGotoEntity)}
              >{`Profile: ${name}`}</span>
            </Segment>
            <Segment>
              <BasicObjectDetails
                configType="entityPageConfig"
                object={entity}
                objectType={entity.entity_type || ''}
                censorshipObjectType="entity"
              />

              <CustomDataTable
                data={filterEntityCustomData(entity, lumosConfig)}
              />

              <TagsAndComments
                selectedTags={selectedTags}
                id={entity.id}
                externalId={entity.external_id}
                objectDisplayName={entity.name_readable}
                editObject={editEntity}
                hasEditPermissions
                loading={editEntityLoading}
                objectType="ENTITY"
                ignoreComments
              />
            </Segment>
          </Segment.Group>
        </LoadingWrapper>
      </div>
    );
  }

  render() {
    const { alert, entityType, retrieveAlertEntitiesLoading, alertEntities } =
      this.props;
    const { selectedEntityId } = this.state;

    const entities = alertEntities;
    return (
      <>
        {entities.length <= 0 && !retrieveAlertEntitiesLoading ? (
          <NoResultsComponent
            title="No associated entities"
            icon={assets.icons.entity}
          />
        ) : (
          <AssociatedEntitiesTable
            onRowClick={this.handleRowClick}
            alert={alert}
            entityType={entityType}
            selectedRowId={selectedEntityId}
            addGotoEntityInDropdown
          />
        )}
        {this.renderEntityDetails()}
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(AssociatedEntitiesPage));
