import { useEffect } from 'react';
import { consoleError } from 'app/shared/utils/console';

interface UnreachableProps {
  error: string;
}
export const Unreachable = ({ error }: UnreachableProps) => {
  useEffect(() => {
    consoleError(error);
  }, [error]);
  return null;
};
