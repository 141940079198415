import { toggleSidebar } from 'app/modules/sidebar/slice';
import { SidebarComponentTypes } from 'app/modules/sidebar/models';
import { LINKED_ENTITIES_COLUMNS } from 'app/modules/networkAnalysis/columns';
import {
  FilteredNetworkAnalysisData,
  NetworkAnalysisResponse,
  LinkedEntity,
} from 'app/modules/networkAnalysis/types';
import { U21Chip, U21Table } from 'app/shared/u21-ui/components';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { selectNetworkAnalysisOnDeltaLake } from 'app/shared/featureFlags/selectors';

interface Props {
  entities: Array<string>;
  data: NetworkAnalysisResponse;
  filteredData: FilteredNetworkAnalysisData;
}
export const LinkedEntitiesTable = ({
  entities,
  data,
  filteredData,
}: Props) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const history = useHistory();

  const networkAnalysisOnDeltaLake = useSelector(
    selectNetworkAnalysisOnDeltaLake,
  );

  const columns = useMemo(
    () => [
      ...LINKED_ENTITIES_COLUMNS.filter(
        (c) => !networkAnalysisOnDeltaLake || c.id !== 'id',
      ),
      {
        id: 'links',
        accessor: 'links',
        Header: 'Links',
        type: 'list' as const,
        cellProps: {
          displayFunc: (linkId: string) => (
            <U21Chip
              disabled={!filteredData.links.has(linkId)}
              onClick={(e) => {
                e.stopPropagation();
                history.replace(`${pathname}#${linkId}`);
              }}
            >
              {data.links[linkId].value}
            </U21Chip>
          ),
        },
        disableSortBy: true,
      },
    ],
    [
      data.links,
      filteredData.links,
      history,
      networkAnalysisOnDeltaLake,
      pathname,
    ],
  );

  const tableData = useMemo(
    () =>
      entities.reduce<LinkedEntity[]>((acc, e) => {
        if (filteredData.entities.has(e)) {
          acc.push(data.entities[e]);
        }
        return acc;
      }, []),
    [entities, data.entities, filteredData.entities],
  );

  return (
    <U21Table
      data={tableData}
      columns={columns}
      onRowClick={(id, row) =>
        dispatch(
          toggleSidebar({
            type: SidebarComponentTypes.ENTITY,
            data: {
              // response won't contain PG IDs so will need to do it this way
              ...(networkAnalysisOnDeltaLake
                ? { externalId: row.external_id, id: '' }
                : { id }),
            },
          }),
        )
      }
      defaultPageSize={10}
    />
  );
};
