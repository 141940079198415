import { AnalyticsEvents, EventConfig } from 'app/shared/u21-ui/analytics';

export const filtersChanged: EventConfig<Record<string, any>> = {
  name: 'Filters Changed',
  createPayload: (_, __, diff) => {
    const name = 'Filters Changed';
    return { ...diff, name };
  },
};

export const sortChanged: EventConfig<{
  toAscending: boolean;
  columnKey: string;
  column?: string;
}> = {
  name: 'Sort Changed',
  createPayload: (_, __, { toAscending, columnKey, column }) => {
    const name = 'Sort Changed';

    return {
      name,
      direction: toAscending ? 'ascending' : 'descending',
      columnKey,
      column,
    };
  },
};

export const u21PaginationTableEvents = {
  [AnalyticsEvents.U21FILTERS_CHANGED]: filtersChanged,

  // Used on both U21PaginationTable and U21Table
  [AnalyticsEvents.U21SORT_CHANGED]: sortChanged,
};
