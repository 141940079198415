import React, { useMemo } from 'react';
import { RouteProps } from 'react-router';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

// Selectors
import { selectFeatureFlags } from 'app/shared/featureFlags/selectors';

// Utils
import { ROUTES_MAP } from 'app/shared/utils/routes';
import { hasPermissionChecker } from 'app/modules/permissions/utils';
import { selectSessionAgentPermissions } from 'app/modules/session/selectors';

interface OwnProps extends Pick<RouteProps, 'component' | 'exact' | 'path'> {
  permissions?: string | string[]; // like "read:alerts"
  requiredFlags?: string | string[];
}

const mapStateToProps = (state: RootState) => ({
  allPermissions: selectSessionAgentPermissions(state),
  featureFlags: selectFeatureFlags(state),
});

type Props = OwnProps & ReturnType<typeof mapStateToProps>;

// defined separately for memoization purposes
const DEFAULT_PERMISSIONS = [];

// handles auth and read permissions
const ProtectedRoute = ({
  permissions = DEFAULT_PERMISSIONS,
  allPermissions,
  exact,
  path,
  component,
  requiredFlags,
  featureFlags,
}: Props) => {
  const hasAnyPermissions = (): boolean => {
    if (allPermissions.length === 0) {
      return false;
    }
    return true;
  };

  const hasRequiredFeatureFlags = (): boolean => {
    if (requiredFlags) {
      const flagsToCheck = Array.isArray(requiredFlags)
        ? requiredFlags
        : [requiredFlags];
      return flagsToCheck.every((flag) => Boolean(featureFlags[flag]));
    }
    return true;
  };

  const hasPermissions = useMemo(
    () =>
      hasPermissionChecker(
        allPermissions,
        typeof permissions === 'string' ? [permissions] : permissions,
      ),
    [allPermissions, permissions],
  );

  if (hasPermissions && hasRequiredFeatureFlags()) {
    return <Route exact={exact} path={path} component={component} />;
  }

  // redirecting to the home page if the user does not have access to a certain feature
  if (hasAnyPermissions()) {
    return <Redirect to="/" />;
  }
  // Redirect to error page if user doesn't have any permissions
  return <Redirect to={ROUTES_MAP.error.path} />;
};

export default connect(mapStateToProps)(ProtectedRoute);
