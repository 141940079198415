// Models
import { NoteModel } from 'app/modules/notes/models';

// Components
import NoteContainer from 'app/modules/notes/components/NoteContainer';
import {
  U21Typography,
  U21Spacer,
  U21Chip,
} from 'app/shared/u21-ui/components';
import {
  Editor as HtmlRenderer,
  EditorState,
  RawDraftContentState,
  convertFromRaw,
} from 'draft-js';

// Styling
import styles from 'app/modules/notes/styles/NoteSection.module.scss';
import styled, { css } from 'styled-components';

// Utils
import { formatDatetime, formatDateToNow } from 'app/shared/utils/date';
import { createSentryError } from 'app/shared/utils/sentry';
import { IconMapPin } from '@u21/tabler-icons';

interface OwnProps {
  note: NoteModel;
  disableFromNowTimeFormat?: boolean;
}

const getEditorState = (content: RawDraftContentState, note: NoteModel) => {
  try {
    return EditorState.createWithContent(convertFromRaw(content));
  } catch (e) {
    createSentryError({
      error: `Unable to decode note (id: ${note.id} created at: ${
        note.created_at
      } ) with content: ${JSON.stringify(content)}`,
    });
  }
  return EditorState.createEmpty();
};

export const NoteImmutable = ({ note, disableFromNowTimeFormat }: OwnProps) => {
  const {
    author,
    content: noteContent,
    created_at: noteCreatedAt,
    disposition,
  } = note;

  const editorState = getEditorState(noteContent, note);

  const relativeDate = formatDateToNow(noteCreatedAt, { addSuffix: true });
  const absoluteDate = formatDatetime(noteCreatedAt);

  const createdAt = !disableFromNowTimeFormat ? relativeDate : absoluteDate;

  // Include disposition info in author text if it exists
  const authorText = `${author.full_name}${disposition ? ' applied disposition ' : ''}`;

  return (
    <NoteContainer author={author}>
      <div className={styles.noteHeader}>
        <StyledU21Spacer horizontal>
          <U21Typography variant="caption">{authorText}</U21Typography>
          {/* Display the disposition chip, if the note is for a disposition */}
          {disposition && (
            <U21Chip icon={<IconMapPin />}>{disposition}</U21Chip>
          )}
          <StyledU21Typography
            color="text.secondary"
            tooltip={absoluteDate}
            variant="caption"
          >
            {createdAt}
          </StyledU21Typography>
        </StyledU21Spacer>
      </div>
      <div className={styles.notesDraftJS}>
        <HtmlRenderer editorState={editorState} onChange={() => {}} readOnly />
      </div>
    </NoteContainer>
  );
};

const StyledU21Spacer = styled(U21Spacer)`
  flex: 1 1 auto;
  padding: 8px 0 8px 48px;
`;

const StyledU21Typography = styled(U21Typography)`
  &:hover {
    text-decoration: underline;
    ${(props) => css`
      text-decoration-color: ${props.theme.palette.grey[500]};
    `}
  }
`;
