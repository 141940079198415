import { SyntheticEvent, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { U21RichTextEditor as RichTextEditor } from '@u21/rich-text-editor';

import { RichTextEditorCommentType } from 'app/shared/components/RichTextEditor/enums';
import { selectAgent } from 'app/modules/session/selectors';
import { RichTextEditorAgents } from 'app/shared/components/RichTextEditor/responses';
import { getRichTextEditorCommentAdapter } from 'app/shared/components/RichTextEditor/adapter';
import { useFetchAllRichTextEditorAgents } from 'app/shared/components/RichTextEditor/queries/useFetchAllRichTextEditorAgents';

import '@u21/rich-text-editor/dist/styles.css';
import getFromProcessEnv from 'app/shared/utils/getFromProcessEnv';

interface U21RichTextEditorProps {
  id?: string;
  onChange: (value: string, e: SyntheticEvent) => void;
  value?: string;
  disabled?: boolean;
  isCommentShown?: boolean;
  parent?: { id: number; type: RichTextEditorCommentType };
}

export const U21RichTextEditor = ({
  id,
  onChange,
  value = '',
  disabled = false,
  isCommentShown = false,
  parent = { id: -1, type: RichTextEditorCommentType.NARRATIVE },
}: U21RichTextEditorProps) => {
  const sessionAgent = useSelector(selectAgent);

  const { data: threadAgents } = useFetchAllRichTextEditorAgents({
    parentId: parent.id,
    parentType: parent.type,
  });

  const availableThreadAgents = useMemo<RichTextEditorAgents[]>(() => {
    if (sessionAgent.id === -1) {
      return [];
    }

    return parent.id === -1
      ? [
          {
            id: sessionAgent.id.toString(),
            name: sessionAgent.full_name,
            avatar: sessionAgent.picture,
          },
        ]
      : threadAgents;
  }, [
    parent.id,
    sessionAgent.full_name,
    sessionAgent.id,
    sessionAgent.picture,
    threadAgents,
  ]);

  return (
    <RichTextEditor
      id={id}
      onChange={onChange}
      value={value}
      disabled={disabled}
      isCommentShown={isCommentShown}
      licenseKey={getFromProcessEnv(
        'REACT_APP_CK_EDITOR_LICENSE_AFTER_VERSION_38',
      )}
      threadAgents={availableThreadAgents}
      sessionAgentId={sessionAgent.id}
      adapter={() => getRichTextEditorCommentAdapter(parent.id, parent.type)}
    />
  );
};
