import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

// Components
import {
  U21Button,
  U21ButtonGroup,
  U21Divider,
  U21ModalBase,
  U21ModalBody,
  U21ModalFooter,
  U21ModalHeader,
  U21NoData,
  U21Spacer,
} from 'app/shared/u21-ui/components';
import { DocumentDisplay } from 'app/modules/attachmentsRefresh/components/DocumentDisplay';
import { DocumentList } from 'app/modules/attachmentsRefresh/components/DocumentList';

// Models
import { ShortTxnResponse } from 'app/modules/transactions/types/responses';

// Utils
import {
  TxnDocumentModalDisplayMode,
  TxnDocumentModalHeader,
  TxnDocumentModalState,
} from 'app/modules/transactions/constants';
import { OtherImageComparisonModalBody } from 'app/modules/transactions/components/OtherImageComparisonModalBody';
import { TxnImageComparisonModalBody } from 'app/modules/transactions/components/TxnImageComparisonModalBody';
import { CheckImageComparisonModalBody } from 'app/modules/transactions/components/CheckImageComparisonModalBody';
import { TxnImageComparisonOverlay } from 'app/modules/transactions/components/TxnImageComparisonOverlay';

type OwnProps = {
  txnEvent: ShortTxnResponse;
  isOpen: boolean;
  selectedFileIdx: number;
  setIsOpen: (newIsOpen: boolean) => void;
  setSelectedFileIdx: (newIdx: number) => void;
  handleOnDelete?: () => void;
};

export const TxnDocumentModal = ({
  txnEvent,
  isOpen,
  selectedFileIdx,
  setIsOpen,
  setSelectedFileIdx,
  handleOnDelete,
}: OwnProps) => {
  const [modalState, setModalState] = useState(
    TxnDocumentModalState.VIEW_TXN_DOCUMENTS,
  );
  const [modalMode, setModalMode] = useState(
    TxnDocumentModalDisplayMode.SIDE_BY_SIDE,
  );
  const [hasDocumentsToCompare, setHasDocumentsToCompare] = useState(false);
  const [upperOffscreenCanvas, setUpperOffscreenCanvas] =
    useState<OffscreenCanvas | null>(null);
  const [lowerOffscreenCanvas, setLowerOffscreenCanvas] =
    useState<OffscreenCanvas | null>(null);

  useEffect(() => {
    if (modalMode !== TxnDocumentModalDisplayMode.OVERLAY) {
      setUpperOffscreenCanvas(null);
      setLowerOffscreenCanvas(null);
    }
  }, [modalMode]);

  const renderModalBody = useMemo(() => {
    switch (modalState) {
      case TxnDocumentModalState.VIEW_TXN_DOCUMENTS:
        return (
          <StyledSpacer spacing={12}>
            {txnEvent.documents.length > 0 ? (
              <StyledSpacer spacing={2} horizontal align="start">
                <DocumentList
                  title="Documents on this transaction"
                  files={txnEvent.documents}
                  selectedFileIdx={selectedFileIdx}
                  setSelectedFileIdx={setSelectedFileIdx}
                  handleOnDelete={handleOnDelete}
                />
                <DocumentSpacer spacing={2}>
                  <DocumentWrapper>
                    <DocumentDisplay
                      file={txnEvent.documents[selectedFileIdx]}
                      isEditableImage
                    />
                  </DocumentWrapper>
                  <U21Spacer horizontal>
                    <U21Button
                      color="primary"
                      variant="contained"
                      onClick={() =>
                        setModalState(
                          TxnDocumentModalState.COMPARE_CHECK_DOCUMENTS,
                        )
                      }
                    >
                      Compare against other checks
                    </U21Button>
                    <U21Button
                      color="primary"
                      variant="contained"
                      onClick={() =>
                        setModalState(
                          TxnDocumentModalState.COMPARE_OTHER_DOCUMENTS,
                        )
                      }
                    >
                      Compare against other documents
                    </U21Button>
                  </U21Spacer>
                </DocumentSpacer>
              </StyledSpacer>
            ) : (
              <U21NoData />
            )}
          </StyledSpacer>
        );
      case TxnDocumentModalState.COMPARE_OTHER_DOCUMENTS:
        return (
          <StyledSpacer>
            <StyledSpacer
              $hidden={modalMode === TxnDocumentModalDisplayMode.OVERLAY}
              horizontal
            >
              <TxnImageComparisonModalBody
                txnEventMap={txnEvent.documents.reduce<
                  Record<number, ShortTxnResponse>
                >((acc, document) => {
                  acc[document.id] = txnEvent;
                  return acc;
                }, {})}
                selectedAttachmentIdx={selectedFileIdx}
                header="Images on this transaction"
                subheader="Select which image you would like to compare"
                isOverlayMode={
                  modalMode === TxnDocumentModalDisplayMode.OVERLAY
                }
                setParentOffsetCanvas={setLowerOffscreenCanvas}
              />
              <U21Divider />
              <OtherImageComparisonModalBody
                txnEvent={txnEvent}
                isOverlayMode={
                  modalMode === TxnDocumentModalDisplayMode.OVERLAY
                }
                setParentOffsetCanvas={setUpperOffscreenCanvas}
                setHasDocumentsToCompare={setHasDocumentsToCompare}
              />
            </StyledSpacer>
            <TxnImageComparisonOverlay
              hidden={modalMode !== TxnDocumentModalDisplayMode.OVERLAY}
              upperOffscreenCanvas={upperOffscreenCanvas}
              lowerOffscreenCanvas={lowerOffscreenCanvas}
            />
          </StyledSpacer>
        );
      case TxnDocumentModalState.COMPARE_CHECK_DOCUMENTS:
        return (
          <StyledSpacer>
            <StyledSpacer
              $hidden={modalMode === TxnDocumentModalDisplayMode.OVERLAY}
              horizontal
            >
              <TxnImageComparisonModalBody
                txnEventMap={txnEvent.documents.reduce<
                  Record<number, ShortTxnResponse>
                >((acc, document) => {
                  acc[document.id] = txnEvent;
                  return acc;
                }, {})}
                selectedAttachmentIdx={selectedFileIdx}
                header="Flagged Check"
                subheader="Select which image you would like to compare"
                isOverlayMode={
                  modalMode === TxnDocumentModalDisplayMode.OVERLAY
                }
                setParentOffsetCanvas={setLowerOffscreenCanvas}
              />
              <U21Divider />
              <CheckImageComparisonModalBody
                txnEvent={txnEvent}
                isOverlayMode={
                  modalMode === TxnDocumentModalDisplayMode.OVERLAY
                }
                setParentOffsetCanvas={setUpperOffscreenCanvas}
                setHasDocumentsToCompare={setHasDocumentsToCompare}
              />
            </StyledSpacer>
            <TxnImageComparisonOverlay
              hidden={modalMode !== TxnDocumentModalDisplayMode.OVERLAY}
              upperOffscreenCanvas={upperOffscreenCanvas}
              lowerOffscreenCanvas={lowerOffscreenCanvas}
            />
          </StyledSpacer>
        );
      default:
        return null;
    }
  }, [
    lowerOffscreenCanvas,
    modalMode,
    modalState,
    selectedFileIdx,
    setSelectedFileIdx,
    txnEvent,
    upperOffscreenCanvas,
    handleOnDelete,
  ]);

  return (
    <StyledModaBase
      onClick={(e) => e.stopPropagation()}
      open={isOpen}
      onExited={() => setModalState(TxnDocumentModalState.VIEW_TXN_DOCUMENTS)}
      size="fullscreen"
      $modalState={modalState}
    >
      <U21ModalHeader
        onClose={() => {
          setModalMode(TxnDocumentModalDisplayMode.SIDE_BY_SIDE);
          setIsOpen(false);
        }}
        action={
          modalState === TxnDocumentModalState.VIEW_TXN_DOCUMENTS ||
          !hasDocumentsToCompare ? undefined : (
            <U21ButtonGroup
              buttons={[
                {
                  label: 'Side-by-side',
                  value: TxnDocumentModalDisplayMode.SIDE_BY_SIDE,
                },
                {
                  label: 'Overlay',
                  value: TxnDocumentModalDisplayMode.OVERLAY,
                },
              ]}
              onClick={(value) => setModalMode(value)}
              value={modalMode}
            />
          )
        }
      >
        {modalMode === TxnDocumentModalDisplayMode.OVERLAY
          ? 'Overlay Comparison'
          : TxnDocumentModalHeader[modalState]}
      </U21ModalHeader>
      <StyledU21ModalBody>{renderModalBody}</StyledU21ModalBody>
      <U21ModalFooter
        leftAction={
          modalState !== TxnDocumentModalState.VIEW_TXN_DOCUMENTS ? (
            <U21Button
              onClick={() => {
                if (modalMode === TxnDocumentModalDisplayMode.OVERLAY) {
                  setModalMode(TxnDocumentModalDisplayMode.SIDE_BY_SIDE);
                } else {
                  setModalState(TxnDocumentModalState.VIEW_TXN_DOCUMENTS);
                }
              }}
            >
              Back
            </U21Button>
          ) : null
        }
      >
        <U21Button
          onClick={() => {
            setModalMode(TxnDocumentModalDisplayMode.SIDE_BY_SIDE);
            setIsOpen(false);
          }}
        >
          Cancel
        </U21Button>
      </U21ModalFooter>
    </StyledModaBase>
  );
};

const StyledModaBase = styled(U21ModalBase)<{
  $modalState: TxnDocumentModalState;
}>`
  .MuiDialog-container {
    .MuiDialog-paper {
      overflow-y: ${(props) =>
        props.$modalState === TxnDocumentModalState.VIEW_TXN_DOCUMENTS
          ? 'scroll'
          : 'auto'};
    }
  }
`;

const DocumentWrapper = styled.div`
  height: calc(100% - 40px);
`;

const StyledSpacer = styled(U21Spacer)<{ $hidden?: boolean }>`
  display: ${(props) => (props.$hidden ? 'none' : 'flex')};
  width: 100%;
  height: 100%;
`;

const DocumentSpacer = styled(U21Spacer)`
  height: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const StyledU21ModalBody = styled(U21ModalBody)`
  width: 100%;
`;
