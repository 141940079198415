import { sendErrorToast } from 'app/shared/toasts/actions';
import { useDispatch } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { post } from 'app/shared/utils/fetchr';

export interface CustomerDebugDump {
  ddo_debugger_key: string;
}

const debugFile = (id: number): Promise<CustomerDebugDump> =>
  post(`/database-connections/pull-based-datafiles/debug/${id}`);

export const useDebugFile = () => {
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: (id: number) => debugFile(id),
    onError: () => dispatch(sendErrorToast('Failed to debug file.')),
  });
};
