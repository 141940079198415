import styled from 'styled-components';
import pluralize from 'pluralize';

import {
  U21Chip,
  U21MenuButton,
  U21Spacer,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { IconChevronRight, IconDots, IconTrash } from '@u21/tabler-icons';

import { FullAttachmentResponse } from 'app/modules/attachments/types';

import { formatDate } from 'app/shared/utils/date';
import {
  parseOutFilename,
  toReadableMediaType,
} from 'app/modules/attachmentsRefresh/utils';
import { useDeleteAttachment } from 'app/modules/attachmentsRefresh/queries/useDeleteAttachment';

type OwnProps = {
  title?: string;
  files: FullAttachmentResponse[];
  selectedFileIdx: number;
  setSelectedFileIdx: (newIdx: number) => void;
  handleOnDelete?: () => void;
};

export const DocumentList = ({
  title = 'Documents',
  files,
  selectedFileIdx,
  setSelectedFileIdx,
  handleOnDelete,
}: OwnProps) => {
  const { isPending, mutateAsync } = useDeleteAttachment();

  return (
    <SelectorSpacer>
      <SelectorTitle variant="subtitle1">{title}</SelectorTitle>
      <StyledU21Chip>
        {pluralize('document', files.length, true)}{' '}
      </StyledU21Chip>
      <List>
        {files.map((document: FullAttachmentResponse, idx: number) => (
          <ListItem
            key={document.id}
            selected={selectedFileIdx === idx}
            secondaryAction={
              handleOnDelete === undefined ? (
                <div>
                  <IconChevronRight />
                </div>
              ) : (
                <U21MenuButton
                  buttonProps={{ size: 'small' }}
                  items={[
                    {
                      color: 'error',
                      icon: <IconTrash />,
                      text: 'Delete',
                      disabled: isPending,
                      onClick: async () => {
                        try {
                          await mutateAsync(document.id);
                          handleOnDelete();
                        } catch {}
                      },
                    },
                  ]}
                >
                  <IconDots />
                </U21MenuButton>
              )
            }
            disablePadding
          >
            <ListItemButton onClick={() => setSelectedFileIdx(idx)}>
              <ListItemText
                disableTypography
                primary={
                  <U21Typography ellipsis variant="subtitle2">
                    {parseOutFilename(document.name)[0]}
                  </U21Typography>
                }
                secondary={
                  <>
                    <U21Typography
                      color="text.secondary"
                      variant="caption"
                    >{`Media Type: ${toReadableMediaType(
                      document.media_type,
                    )}`}</U21Typography>
                    <U21Typography color="text.secondary" variant="caption">
                      {`Uploaded: ${formatDate(document.created_at)}`}
                    </U21Typography>
                  </>
                }
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </SelectorSpacer>
  );
};

const SelectorSpacer = styled(U21Spacer)`
  min-width: 280px;
  width: 20%;
  background-color: ${(props) => props.theme.palette.background.neutral};
  padding: 12px;
  border-radius: 8px;
  max-height: 600px;
  overflow-y: auto;
`;

const SelectorTitle = styled(U21Typography)`
  margin-bottom: 20px;
`;

const StyledU21Chip = styled(U21Chip)`
  width: fit-content;
`;
