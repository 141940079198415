import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'app/store/storeHooks';

import { InstrumentsDocumentChipList } from 'app/modules/instruments/components/InstrumentsDocumentChipList';
import { selectAttachments } from 'app/modules/attachmentsRefresh/selectors';
import { retrieveAttachmentsThunk } from 'app/modules/attachmentsRefresh/sliceAttachments';

type OwnProps = {
  externalId: string;
};

export const InstrumentsDocumentChipListWrapper = ({
  externalId,
}: OwnProps) => {
  const dispatch = useAppDispatch();

  // Get attachments - keep in redux as it is managed in attachment refresh
  useEffect(() => {
    if (externalId) {
      dispatch(
        retrieveAttachmentsThunk({ instrument_external_id: externalId }),
      );
    }
  }, [dispatch, externalId]);
  const documents = useSelector(selectAttachments);

  return <InstrumentsDocumentChipList documents={documents} />;
};
