import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  U21Alert,
  U21Button,
  U21Chip,
  U21Form,
  U21FormField,
  U21SelectOptionProps,
  U21ShowMoreList,
  U21Spacer,
  U21Typography,
  U21HelpTooltip,
  U21FormFieldCustom,
} from 'app/shared/u21-ui/components';
import { useAppDispatch } from 'app/store/storeHooks';
import { AddOrEditDataSettingsForm } from 'app/modules/dataSettings/types';
import {
  DataSettingFieldType,
  Unit21DataClassifier,
  DataSettingsKeyType,
  RenderingOptionsResponse,
  CensorshipSetting,
} from 'app/modules/dataSettings/responses';
import {
  CONFIGURED_FLOW_OPTIONS,
  DATA_CLASSIFIER_SELECT_OPTIONS,
  RELATED_RULES_CHIP_COLORS,
  SEMANTIC_TYPE_OPTIONS,
  NUMBER_PRECISION_RENDERING_OPTIONS,
  MAX_ALLOWED_ENUMS,
  RENDERING_OPTIONS_BY_DATA_TYPE,
  REFERENCE_DISPLAY_FIELDS_OPTIONS,
  REFERENCE_TYPES,
  DATA_TYPES_WITH_RENDERING_OPTIONS,
  RULES_ORDER,
  DEFAULT_REFERENCE_DISPLAY_FIELDS,
  SEMANTIC_TYPES_TO_U21_DATA_CLASSIFIER,
  CENSORSHIP_SETTING_OPTIONS,
  NON_CENSORABLE_NATIVE_DATA_SETTINGS,
} from 'app/modules/dataSettings/constants';
import {
  addOrgCustomDataSettingsConfigThunk,
  clearOverlay,
  editOrgCustomDataSettingsConfigThunk,
} from 'app/modules/dataSettings/sliceDataSettings';
import {
  selectDataSettingsById,
  selectDataSettingsOverlay,
} from 'app/modules/dataSettings/selectors';
import { selectWatchlistAlertsEnabled } from 'app/shared/featureFlags/selectors';
import {
  U21AgentSelect,
  U21SideMenu,
  U21TeamSelect,
} from 'app/shared/u21-ui/components/dashboard';
import { hideSidebar } from 'app/modules/sidebar/slice';
import { useGetTimezones } from 'app/modules/dataMapping/queries/useGetTimezones';
import {
  selectIsUnit21User,
  selectHasCreateRulesPermission,
  selectHasEditCustomDataUserPermissions,
  selectHasReadRulesPermission,
} from 'app/modules/session/selectors';
import { ROUTES_MAP } from 'app/shared/utils/routes';
import {
  enumDataSettingCustomFilterOptions,
  getDataSettingDataTypeOptions,
  validateNonEmpty,
} from 'app/modules/dataSettings/utils';
import { pick } from 'lodash';
import objectIsEmpty from 'app/shared/utils/objectIsEmpty';
import { generateFormFieldProps } from 'app/shared/utils/form';
import { FormFieldCustomSchema } from 'app/shared/models/form';
import { IconEye, IconEyeOff } from '@u21/tabler-icons';
import styled from 'styled-components';
import { selectPCIOrgFeatureEnabled } from 'app/modules/orgSettings/selectors';

const ADD_FORM_ID = 'add_custom_data_overlay';
const EDIT_FORM_ID = 'edit_custom_data_overlay';
const TEAM_CENSORSHIP_FIELD: FormFieldCustomSchema<number[]> = {
  children: <U21TeamSelect label={undefined} multi onChange={() => {}} />,
  label: 'Teams With Read Access',
  name: 'team_ids_with_censorship_read_permission',
  type: 'CUSTOM',
  fieldProps: {
    placeholder: 'Grant read access to team(s)',
  },
};
const AGENT_CENSORSHIP_FIELD: FormFieldCustomSchema<number[]> = {
  children: <U21AgentSelect label={undefined} multi onChange={() => {}} />,
  label: 'Agents With Read Access',
  name: 'agent_ids_with_censorship_read_permission',
  type: 'CUSTOM',
  fieldProps: {
    placeholder: 'Grant read access to agent(s)',
  },
};

export const DataSettingSidebar = () => {
  const dispatch = useAppDispatch();
  const overlayState = useSelector(selectDataSettingsOverlay);
  const dataSettingsById = useSelector(selectDataSettingsById);
  const watchlistAlertsEnabled = useSelector(selectWatchlistAlertsEnabled);
  const hasReadRulesPermission = useSelector(selectHasReadRulesPermission);
  const hasCreateRulesPermission = useSelector(selectHasCreateRulesPermission);
  const hasEditCustomDataPermission = useSelector(
    selectHasEditCustomDataUserPermissions,
  );

  const [loading, setLoading] = useState(false);
  const [editingEnumValues, setEditingEnumValues] = useState(false);
  const isAddCustomData = overlayState.overlay === 'ADD_CUSTOM_DATA';
  const isEditCustomData = overlayState.overlay === 'EDIT_DATA_SETTING';
  const keyPathHelpText = isAddCustomData
    ? 'Key path supports dot notation. For nested keys, you can enter in with the format: "key1.key2". We currently do not support escaping special characters.'
    : 'Name within data ingestion/your database. You can not edit the key path once set. Use `Friendly name` to change display, and add a new custom data field if the data is being ingested under a different name.';

  const activeDataSetting = isEditCustomData
    ? dataSettingsById[overlayState.id]
    : null;

  const isNativeDataSetting =
    activeDataSetting?.key_type === DataSettingsKeyType.NATIVE;

  const activeDataSettingNativeKey = isNativeDataSetting
    ? activeDataSetting.native_key
    : '';

  const isActiveDataSettingEnum =
    activeDataSetting?.data_type === DataSettingFieldType.ENUM;
  let dataTypeHelpText: string;
  if (!isNativeDataSetting) {
    dataTypeHelpText = isAddCustomData
      ? 'The data type influences the operations available when using this custom data field for rules in Detection Models.'
      : 'Please note that changing the data type may affect the operations available when using this custom data for new rules under Detection Models.';
  }

  const sortedActiveDataSettingEnums = useMemo(() => {
    return [...(activeDataSetting?.enum_set ?? [])].sort();
  }, [activeDataSetting]);

  const relatedRules = useMemo(() => {
    const sortedDataSettingRules = [...(activeDataSetting?.rules ?? [])].sort(
      (a, b) => RULES_ORDER.indexOf(a.status) - RULES_ORDER.indexOf(b.status),
    );
    return sortedDataSettingRules.map((rule) => (
      <U21Chip
        key={rule.id}
        to={
          hasReadRulesPermission
            ? ROUTES_MAP.detectionModelsId.path.replace(':id', String(rule.id))
            : undefined
        }
        color={RELATED_RULES_CHIP_COLORS[rule.status].color}
        variant={RELATED_RULES_CHIP_COLORS[rule.status].style}
      >
        {rule.title}
      </U21Chip>
    ));
  }, [activeDataSetting?.rules, hasReadRulesPermission]);

  const { data: timezoneSelectOptions } = useGetTimezones();
  const timezoneRenderingOptions = useMemo(() => {
    return [
      {
        text: 'Local time',
        value: null,
      },
      ...(timezoneSelectOptions || []).map<U21SelectOptionProps>(
        (timezoneOption) => ({
          text: timezoneOption.text,
          value: timezoneOption.value,
        }),
      ),
    ];
  }, [timezoneSelectOptions]);

  const initialValues: AddOrEditDataSettingsForm = useMemo(() => {
    if (isEditCustomData && activeDataSetting) {
      // grooming rendering options to be null/default when missing
      const precision = activeDataSetting.rendering_options?.precision ?? null;
      const timezone = activeDataSetting.rendering_options?.timezone ?? null;
      const referenceDisplayFields =
        activeDataSetting.rendering_options?.reference_display_fields ??
        (activeDataSetting.data_type === DataSettingFieldType.ENTITY_REFERENCE
          ? DEFAULT_REFERENCE_DISPLAY_FIELDS
          : undefined);
      // properties present in both native and custom data settings
      const baseValues = {
        configured_flow: activeDataSetting.configured_flow ?? undefined,
        data_type: activeDataSetting.data_type,
        description: activeDataSetting.description,
        enum_set: sortedActiveDataSettingEnums,
        exported: activeDataSetting.exported,
        matchable: activeDataSetting.matchable,
        rendering_options: {
          ...activeDataSetting.rendering_options,
          precision,
          timezone,
          reference_display_fields: referenceDisplayFields,
        },
        unit21_data_classifier: activeDataSetting.unit21_data_classifier,
        user_facing_label: activeDataSetting.user_facing_label,
        censorship_setting: activeDataSetting.censorship_setting,
        team_ids_with_censorship_read_permission:
          activeDataSetting.team_ids_with_censorship_read_permission,
        agent_ids_with_censorship_read_permission:
          activeDataSetting.agent_ids_with_censorship_read_permission,
      };
      // edit custom data setting initial values
      if (activeDataSetting.key_type === DataSettingsKeyType.CUSTOM) {
        const keyPath: string = activeDataSetting.key_path?.join('.') ?? '';
        return {
          ...baseValues,
          key_path: keyPath,
          semantic_type: activeDataSetting.semantic_type,
        };
      }
      // edit native data setting initial values
      return {
        // for display purposes (property is removed when editing)
        key_path: activeDataSetting.native_field,
        ...baseValues,
      };
    }

    // create custom data initial values
    return {
      key_path: '',
      unit21_data_classifier: Unit21DataClassifier.ENTITY,
      data_type: DataSettingFieldType.TEXT,
      exported: false,
      enum_set: [],
      rendering_options: {},
      censorship_setting: CensorshipSetting.ALWAYS_VISIBLE,
      agent_ids_with_censorship_read_permission: [],
      team_ids_with_censorship_read_permission: [],
    };
  }, [activeDataSetting, sortedActiveDataSettingEnums, isEditCustomData]);

  const onClose = useCallback(() => dispatch(clearOverlay()), [dispatch]);

  const onSubmit = useCallback(
    async (model: AddOrEditDataSettingsForm) => {
      setEditingEnumValues(false);
      setLoading(true);
      try {
        // removes keys that are not relevant to the data type
        let strippedRenderingOptions:
          | Partial<RenderingOptionsResponse>
          | undefined = pick(
          model.rendering_options,
          RENDERING_OPTIONS_BY_DATA_TYPE[model.data_type],
        );
        let strippedAgentIds: number[] =
          model.agent_ids_with_censorship_read_permission;
        let strippedTeamIds: number[] =
          model.team_ids_with_censorship_read_permission;
        let strippedCensorshipSetting: CensorshipSetting =
          model.censorship_setting;

        if (model.unit21_data_classifier === Unit21DataClassifier.ALERT) {
          strippedCensorshipSetting = CensorshipSetting.ALWAYS_VISIBLE;
        }
        if (
          strippedCensorshipSetting === CensorshipSetting.ALWAYS_VISIBLE ||
          strippedCensorshipSetting === CensorshipSetting.CENSORED_BUT_VIEWABLE
        ) {
          strippedAgentIds = [];
          strippedTeamIds = [];
        }
        if (objectIsEmpty(strippedRenderingOptions)) {
          strippedRenderingOptions = undefined;
        } else {
          if (strippedRenderingOptions.reference_display_fields?.length === 0) {
            strippedRenderingOptions.reference_display_fields = undefined;
          }
          if (strippedRenderingOptions.is_url === false) {
            strippedRenderingOptions.url_prefix = undefined;
            strippedRenderingOptions.url_postfix = undefined;
          }
        }
        if (overlayState.overlay === 'EDIT_DATA_SETTING') {
          // removes fields that cannot be edited
          const {
            key_path: ignoreKeyPath,
            unit21_data_classifier: ignoreUnit21DataClassifier,
            ...rest
          } = model;
          await dispatch(
            editOrgCustomDataSettingsConfigThunk({
              id: overlayState.id,
              payload: {
                // ensures these fields are null if they are otherwise undefined
                description: null,
                user_facing_label: null,
                semantic_type: null,
                ...rest,
                rendering_options: strippedRenderingOptions,
                agent_ids_with_censorship_read_permission: strippedAgentIds,
                team_ids_with_censorship_read_permission: strippedTeamIds,
                censorship_setting: strippedCensorshipSetting,
              },
            }),
          ).unwrap();
        } else {
          await dispatch(
            addOrgCustomDataSettingsConfigThunk({
              payload: {
                ...model,
                rendering_options: strippedRenderingOptions,
                key_path: model.key_path?.trim().split('.'),
              },
            }),
          ).unwrap();
        }
        onClose();
        dispatch(hideSidebar());
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    },
    [dispatch, overlayState, setLoading, onClose],
  );

  const isUnit21User = useSelector(selectIsUnit21User);

  const disableSensitiveFields = isEditCustomData && !isUnit21User;

  const [recentValuesUncensored, setRecentValuesUncensored] = useState(false);
  const [potentialValuesUncensored, setPotentialValuesUncensored] =
    useState(false);

  const parsedRecentValues = activeDataSetting?.recent_values?.map((val) => {
    try {
      return JSON.parse(val);
    } catch {
      return val;
    }
  }, []);

  const pciEnabledForOrg = useSelector(selectPCIOrgFeatureEnabled);

  return (
    <U21SideMenu
      title={
        isAddCustomData
          ? 'Add Custom Data Setting'
          : `${hasEditCustomDataPermission ? 'Edit ' : ''}Data Setting`
      }
      onClose={onClose}
      actionButtonProps={
        hasEditCustomDataPermission
          ? {
              type: 'submit',
              form: isEditCustomData ? EDIT_FORM_ID : ADD_FORM_ID,
              children: isAddCustomData ? 'Add' : 'Save',
              loading,
            }
          : undefined
      }
    >
      <U21Form
        disabled={!hasEditCustomDataPermission}
        onSubmit={onSubmit}
        id={isEditCustomData ? EDIT_FORM_ID : ADD_FORM_ID}
        initialValues={initialValues}
        keepDirtyOnReinitialize={false}
      >
        {({ values, form: { change } }) => {
          const isNonCensorableField = activeDataSettingNativeKey
            ? NON_CENSORABLE_NATIVE_DATA_SETTINGS[
                values.unit21_data_classifier
              ].includes(activeDataSettingNativeKey)
            : false;

          return (
            <U21Spacer spacing={2} dividers>
              <U21Spacer>
                <U21FormField
                  required
                  label={isNativeDataSetting ? 'Unit21 field' : 'Key path'}
                  name="key_path"
                  type="TEXT"
                  disabled={!isAddCustomData}
                  validate={validateNonEmpty}
                  help={!isNativeDataSetting ? keyPathHelpText : undefined}
                />
                <U21FormField
                  required
                  name="unit21_data_classifier"
                  label="Object"
                  disabled={!isAddCustomData}
                  type="SELECT"
                  fieldProps={{
                    // Can't add custom data on address
                    options: DATA_CLASSIFIER_SELECT_OPTIONS.filter(
                      (option) =>
                        (isNativeDataSetting ||
                          option.value !== Unit21DataClassifier.ADDRESS) &&
                        (watchlistAlertsEnabled ||
                          option.value !== Unit21DataClassifier.WATCHLIST),
                    ),
                    clearable: false,
                    onChange: () => {
                      change('semantic_type', undefined);
                      change('configured_flow', undefined);
                      if (REFERENCE_TYPES.has(values.data_type)) {
                        change('data_type', DataSettingFieldType.TEXT);
                      }
                    },
                  }}
                />

                <U21FormField
                  required
                  name="data_type"
                  label="Data type"
                  type="SELECT"
                  disabled={
                    // cant edit data type for native data settings
                    isNativeDataSetting ||
                    // this field is a sensitive field and can only be edited by Unit21
                    // if it is an event or instrument with a reference data type
                    (disableSensitiveFields &&
                      (values.unit21_data_classifier ===
                        Unit21DataClassifier.EVENT ||
                        values.unit21_data_classifier ===
                          Unit21DataClassifier.INSTRUMENT) &&
                      REFERENCE_TYPES.has(values.data_type))
                  }
                  fieldProps={{
                    options: getDataSettingDataTypeOptions(
                      values.unit21_data_classifier,
                    ),
                    clearable: false,
                    // TODO sc-67811: when changed to enum, make sure we add enum_mapping dict to rendering options to avoid exceptions
                    onChange: (newDataType: DataSettingFieldType) => {
                      change('configured_flow', undefined);
                      const renderingOptions: RenderingOptionsResponse = {
                        precision:
                          newDataType === DataSettingFieldType.NUMBER
                            ? null
                            : undefined,
                        timezone:
                          newDataType === DataSettingFieldType.DATE_TIME
                            ? null
                            : undefined,
                        reference_display_fields:
                          newDataType === DataSettingFieldType.ENTITY_REFERENCE
                            ? DEFAULT_REFERENCE_DISPLAY_FIELDS
                            : undefined,
                      };
                      change('rendering_options', renderingOptions);
                    },
                  }}
                  help={dataTypeHelpText}
                />
                {initialValues.data_type !== values.data_type &&
                  (activeDataSetting?.rules?.length || 0) > 0 && (
                    <U21Spacer marginStart spacing={1}>
                      <U21Alert severity="info">
                        This data setting is used in a rule. Changing the data
                        type will not affect the existing rule, but may change
                        the operations available for this custom data field in
                        future rules.
                      </U21Alert>
                    </U21Spacer>
                  )}
                {(values.unit21_data_classifier ===
                  Unit21DataClassifier.EVENT ||
                  values.unit21_data_classifier ===
                    Unit21DataClassifier.INSTRUMENT) &&
                  REFERENCE_TYPES.has(values.data_type) &&
                  !isNativeDataSetting && (
                    <U21Alert severity="info">
                      Once created, only Unit21 can modify entity/instrument
                      reference data settings, as these changes may require a
                      programmatic backfill.
                    </U21Alert>
                  )}
                <U21FormField
                  label="Friendly name"
                  name="user_facing_label"
                  help="When set, this field will appear with this name everywhere in your app. This overrides the actual name that should mirror how you ingest data/what the field is called in your database."
                  type="TEXT"
                  fieldProps={{ placeholder: 'Enter a display name...' }}
                />
                <U21FormField
                  name="description"
                  label="Description"
                  type="TEXTAREA"
                  fieldProps={{ placeholder: 'Describe this field...' }}
                />

                {/* EXPORTS */}

                <U21FormField
                  disabled={isNativeDataSetting}
                  name="exported"
                  fieldProps={{ label: 'Include in exports' }}
                  type="CHECKBOX"
                  help={
                    isNativeDataSetting
                      ? "Can't edit export behavior of native data settings."
                      : null
                  }
                  label="Exports"
                />
              </U21Spacer>

              {/* CENSORSHIP SETTINGS */}

              <U21Spacer>
                <U21Spacer horizontal align="center">
                  <U21Typography variant="h6">
                    Censorship Settings
                  </U21Typography>
                  <U21HelpTooltip help="Configure what agents are able to view this data field" />
                </U21Spacer>
                <U21FormField
                  required
                  name="censorship_setting"
                  label="Setting"
                  type="SELECT"
                  fieldProps={{
                    options: CENSORSHIP_SETTING_OPTIONS.filter(
                      ({ value }) =>
                        value !== CensorshipSetting.ENCRYPTED ||
                        (pciEnabledForOrg &&
                          values.data_type === DataSettingFieldType.TEXT),
                    ),
                    clearable: false,
                  }}
                  disabled={
                    isNonCensorableField ||
                    values.unit21_data_classifier === Unit21DataClassifier.ALERT
                  }
                  help={
                    isNonCensorableField ||
                    values.unit21_data_classifier === Unit21DataClassifier.ALERT
                      ? 'This field is unavailable to be censored'
                      : null
                  }
                />
                {values.censorship_setting === CensorshipSetting.CENSORED && (
                  <>
                    <U21FormFieldCustom
                      {...generateFormFieldProps(TEAM_CENSORSHIP_FIELD)}
                    />
                    <U21FormFieldCustom
                      {...generateFormFieldProps(AGENT_CENSORSHIP_FIELD)}
                    />
                  </>
                )}
              </U21Spacer>

              {/* IMPLEMENTATION SETTINGS */}

              <U21Spacer>
                <U21Spacer horizontal align="center">
                  <U21Typography variant="h6">
                    Implementation Settings
                  </U21Typography>
                  <U21HelpTooltip
                    help={`${isAddCustomData ? 'Once created, t' : 'T'}hese settings can only be edited by Unit21's implementation team. Contact support if you require changes.`}
                  />
                </U21Spacer>
                {values.unit21_data_classifier === Unit21DataClassifier.EVENT &&
                  REFERENCE_TYPES.has(values.data_type) &&
                  !isNativeDataSetting && (
                    <U21FormField<string | undefined, AddOrEditDataSettingsForm>
                      name="configured_flow"
                      label="Directionality"
                      help="Used to understand the direction of funds for an entity on a transaction."
                      type="SELECT"
                      fieldProps={{
                        options: CONFIGURED_FLOW_OPTIONS,
                        clearable: false,
                      }}
                      required
                      validate={(v, currentValues) => {
                        return currentValues.unit21_data_classifier ===
                          Unit21DataClassifier.EVENT &&
                          REFERENCE_TYPES.has(currentValues.data_type) &&
                          !v
                          ? 'Directionality is required.'
                          : undefined;
                      }}
                      disabled={disableSensitiveFields}
                    />
                  )}
                <U21FormField
                  label="Semantic type"
                  name="semantic_type"
                  type="SELECT"
                  help="Identifies if the field has a specific meaning (i.e. Field is an `MCC` code) that Unit21 will use for additional monitoring & features."
                  fieldProps={{
                    options: SEMANTIC_TYPE_OPTIONS.filter((option) =>
                      (
                        SEMANTIC_TYPES_TO_U21_DATA_CLASSIFIER[
                          values.unit21_data_classifier
                        ] ?? new Set()
                      ).has(option.value),
                    ),
                  }}
                  disabled={disableSensitiveFields}
                />
                {values.unit21_data_classifier ===
                  Unit21DataClassifier.ENTITY && (
                  <U21FormField
                    name="matchable"
                    fieldProps={{ label: 'Use in graph based rules' }}
                    type="CHECKBOX"
                    help="If checked, this data setting can be used in graph based rules."
                    label="Graph based rules"
                    disabled={disableSensitiveFields}
                  />
                )}
              </U21Spacer>

              {/* RENDERING_OPTIONS */}

              {DATA_TYPES_WITH_RENDERING_OPTIONS.has(values.data_type) &&
                // there are rendering options for enums but they are currently only configurable via type classification
                // TODO sc-67811: extend to all enums and allow editing via sidebar (and thus remove this condition)
                values.data_type !== DataSettingFieldType.ENUM && (
                  <U21Spacer>
                    <U21Typography variant="h6">
                      Rendering Options
                    </U21Typography>
                    {values.data_type === DataSettingFieldType.DATE_TIME && (
                      <U21FormField
                        name="rendering_options.timezone"
                        label="Timezone"
                        type="SELECT"
                        fieldProps={{
                          options: timezoneRenderingOptions,
                          clearable: false,
                        }}
                        help="This data setting will be shown in the specified timezone in most places in the app."
                        allowNull
                      />
                    )}
                    {values.data_type === DataSettingFieldType.NUMBER && (
                      <U21FormField
                        name="rendering_options.precision"
                        label="Precision"
                        type="SELECT"
                        fieldProps={{
                          options: NUMBER_PRECISION_RENDERING_OPTIONS,
                          clearable: false,
                        }}
                        help="Precision refers to the number of decimal points shown for this data setting in most places in the app, assuming that precision is available. For example, a precision of 3 would round the number 1.2345 to 1.235, while 1.2 would stay as 1.2."
                        allowNull
                      />
                    )}
                    {(values.data_type === DataSettingFieldType.TEXT ||
                      (values.data_type === DataSettingFieldType.NUMBER &&
                        !isNativeDataSetting)) && (
                      <>
                        <U21FormField
                          name="rendering_options.is_url"
                          label="Hyperlink"
                          fieldProps={{
                            label: 'Render as hyperlink',
                            onChange: (v: boolean) => {
                              if (!v) {
                                change('rendering_options', {
                                  ...values.rendering_options,
                                  is_url: false,
                                  url_prefix: undefined,
                                  url_postfix: undefined,
                                });
                              }
                            },
                          }}
                          type="CHECKBOX"
                          help="If checked, the value will render as a clickable link if it is a valid URL."
                        />
                        {values.rendering_options?.is_url && (
                          <>
                            <U21FormField
                              label="URL prefix"
                              name="rendering_options.url_prefix"
                              type="TEXT"
                              help='Specify the URL prefix that should come before the data value. For example, if the data value is "123" and the prefix is "https://my-service.com/user/", the link will be "https://my-service.com/user/123".'
                              fieldProps={{ placeholder: 'https://' }}
                              validate={(value: string) =>
                                !value || value.startsWith('https://')
                                  ? undefined
                                  : 'URL prefix must begin with https:// in order to be valid'
                              }
                            />
                            <U21FormField
                              label="URL postfix"
                              name="rendering_options.url_postfix"
                              type="TEXT"
                              help='Specify the postfix that should come after the URL prefix and the data value. Extending the example in the URL prefix help text, if the postfix is "-account", the link will be "https://my-service.com/user/123-account".'
                            />
                          </>
                        )}
                      </>
                    )}
                    {values.data_type ===
                      DataSettingFieldType.ENTITY_REFERENCE && (
                      <U21FormField
                        name="rendering_options.reference_display_fields"
                        label="Reference display fields"
                        type="MULTISELECT"
                        fieldProps={{
                          options: REFERENCE_DISPLAY_FIELDS_OPTIONS,
                          clearable: false,
                        }}
                        help="The order of selected fields will be the order in which they are displayed."
                      />
                    )}
                  </U21Spacer>
                )}

              {/* CUSTOM DATA METRICS */}

              {!isAddCustomData ? (
                <>
                  <U21Spacer key="recent">
                    <StyledCensorshipSpacer horizontal align="center">
                      <U21Spacer horizontal align="center">
                        <U21Typography variant="h6">
                          Sample Recent Values
                        </U21Typography>
                        <U21HelpTooltip help="We automatically detect recently seen values from your input data." />
                      </U21Spacer>

                      {!!activeDataSetting?.recent_values?.length &&
                        activeDataSetting?.censorship_setting !==
                          CensorshipSetting.ALWAYS_VISIBLE && (
                          <U21Button
                            size="small"
                            onClick={() =>
                              setRecentValuesUncensored((prev) => !prev)
                            }
                            tooltip={
                              recentValuesUncensored
                                ? 'Hide recent values'
                                : 'Show recent values'
                            }
                            icon={
                              recentValuesUncensored ? (
                                <IconEyeOff />
                              ) : (
                                <IconEye />
                              )
                            }
                          />
                        )}
                    </StyledCensorshipSpacer>
                    <>
                      {activeDataSetting?.recent_values?.length ? (
                        <>
                          {activeDataSetting?.censorship_setting ===
                            CensorshipSetting.ALWAYS_VISIBLE ||
                          recentValuesUncensored ? (
                            <U21ShowMoreList
                              value={parsedRecentValues}
                              limit={10}
                              horizontal
                            />
                          ) : (
                            <U21Typography variant="body2">
                              Recent values are censored.{' '}
                              <U21Button
                                variant="text"
                                onClick={() => setRecentValuesUncensored(true)}
                                color="primary"
                              >
                                Show recent values
                              </U21Button>
                              .
                            </U21Typography>
                          )}
                        </>
                      ) : (
                        <U21Typography variant="body2">
                          No values received in this field yet, consider sending
                          data with this field to get greater usage out of
                          Unit21.
                        </U21Typography>
                      )}
                    </>
                  </U21Spacer>
                  {isActiveDataSettingEnum ? (
                    <U21Spacer key="potential">
                      <StyledCensorshipSpacer horizontal align="center">
                        <U21Spacer horizontal align="center">
                          <U21Typography variant="h6" align="center">
                            Potential Values
                          </U21Typography>
                          <U21HelpTooltip help="We automatically detect potential values from your data. You can also add additional options. Note that this field reflects how data is stored in Unit21, which on rare occasions may differ slightly from your input values." />
                        </U21Spacer>
                        {activeDataSetting?.censorship_setting !==
                          CensorshipSetting.ALWAYS_VISIBLE && (
                          <U21Button
                            size="small"
                            onClick={() =>
                              setPotentialValuesUncensored((prev) => !prev)
                            }
                            tooltip={
                              potentialValuesUncensored
                                ? 'Hide potential values'
                                : 'Show potential values'
                            }
                            icon={
                              potentialValuesUncensored ? (
                                <IconEyeOff />
                              ) : (
                                <IconEye />
                              )
                            }
                          />
                        )}
                      </StyledCensorshipSpacer>
                      {activeDataSetting?.censorship_setting ===
                        CensorshipSetting.ALWAYS_VISIBLE ||
                      potentialValuesUncensored ? (
                        <>
                          {!editingEnumValues ? (
                            <U21Spacer marginStart spacing={1.5}>
                              {sortedActiveDataSettingEnums.length ? (
                                <U21ShowMoreList
                                  value={sortedActiveDataSettingEnums}
                                  limit={10}
                                  horizontal
                                />
                              ) : (
                                <U21Typography variant="body2">
                                  No values received in this field yet, consider
                                  using this field to get greater usage out of
                                  Unit21.
                                </U21Typography>
                              )}
                              <div>
                                <U21Button
                                  color="primary"
                                  onClick={() => {
                                    setEditingEnumValues(true);
                                  }}
                                >
                                  Add values
                                </U21Button>
                              </div>
                            </U21Spacer>
                          ) : (
                            <U21Spacer marginStart spacing={1}>
                              <U21Alert severity="info">
                                Note that editing potential values is
                                case-sensitive, and limited to{' '}
                                {MAX_ALLOWED_ENUMS} unique values.
                              </U21Alert>
                              <U21FormField
                                type="MULTISELECT"
                                name="enum_set"
                                fieldProps={{
                                  options: values.enum_set.map((val) => ({
                                    text: val,
                                    value: val,
                                  })),
                                  noOptionsText:
                                    values.enum_set.length < MAX_ALLOWED_ENUMS
                                      ? 'Start typing to add values...'
                                      : 'Cannot add any more values',
                                  filterOptions:
                                    enumDataSettingCustomFilterOptions,
                                  label: 'Potential values',
                                }}
                              />
                              <div>
                                <U21Button
                                  color="primary"
                                  onClick={() => {
                                    change(
                                      'enum_set',
                                      activeDataSetting.enum_set,
                                    );
                                    setEditingEnumValues(false);
                                  }}
                                >
                                  Cancel
                                </U21Button>
                              </div>
                            </U21Spacer>
                          )}
                        </>
                      ) : (
                        <U21Typography variant="body2">
                          Potential values is censored.{' '}
                          <U21Button
                            variant="text"
                            onClick={() => setPotentialValuesUncensored(true)}
                            color="primary"
                          >
                            Show potential values
                          </U21Button>
                          .
                        </U21Typography>
                      )}
                    </U21Spacer>
                  ) : null}
                  <U21Spacer key="rules">
                    <U21Typography variant="h6">Related Rules</U21Typography>
                    {activeDataSetting?.rules?.length ? (
                      <U21Spacer wrap>{relatedRules}</U21Spacer>
                    ) : (
                      <>
                        <U21Typography variant="body2">
                          No rules detected using this data setting.
                        </U21Typography>
                        {hasCreateRulesPermission && (
                          <div>
                            <U21Button
                              to="/detection-models"
                              onClick={onClose} // used to close the overlay and reset state
                              color="primary"
                            >
                              Create rule
                            </U21Button>
                          </div>
                        )}
                      </>
                    )}
                  </U21Spacer>
                </>
              ) : null}
            </U21Spacer>
          );
        }}
      </U21Form>
    </U21SideMenu>
  );
};
const StyledCensorshipSpacer = styled(U21Spacer)`
  justify-content: space-between;
`;
