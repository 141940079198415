import { AnalyticsEvents, EventConfig } from 'app/shared/u21-ui/analytics';
import { U21ButtonProps } from 'app/shared/u21-ui/components';

export const getButtonName = (props: U21ButtonProps): string => {
  const { 'aria-label': ariaLabel, children } = props;
  if (ariaLabel) {
    return ariaLabel;
  }
  if (typeof children === 'string') {
    return children;
  }
  return '';
};

export const click: EventConfig = {
  name: 'Button Clicked',
  createPayload: (props: U21ButtonProps) => {
    const name = getButtonName(props);
    if (!name) {
      return null;
    }
    return { name };
  },
};

export const u21ButtonEvents = {
  [AnalyticsEvents.U21BUTTON_ON_CLICK]: click,
};
