import { useQuery } from '@tanstack/react-query';
import { post } from 'app/shared/utils/fetchr';
import { WATCHLIST_QUERY_KEYS } from 'app/modules/watchlists/queries/keys';
import { WatchlistResultListResponse } from 'app/modules/watchlists/responses';
import { WatchlistResultListRequest } from 'app/modules/watchlists/requests';

const getAlertWatchlistResults = (
  alertId: number,
  payload: WatchlistResultListRequest,
): Promise<WatchlistResultListResponse> =>
  post(`/alerts/${alertId}/watchlist-results`, payload);

export const useGetAlertWatchlistResults = (
  alertId: number,
  payload: WatchlistResultListRequest,
) => {
  return useQuery({
    queryKey: WATCHLIST_QUERY_KEYS.getAlertWatchlistResults(alertId, payload),
    queryFn: () => getAlertWatchlistResults(alertId, payload),
    meta: {
      errorMessage:
        'Unable to retrieve watchlist result data. Please try again',
    },
    initialData: { results: [], count: 0 },
    enabled: alertId > 0,
  });
};
