import { get } from 'app/shared/utils/fetchr';
import { useQuery } from '@tanstack/react-query';
import { ALL_DATA_LABELS_QUERY_KEYS } from 'app/modules/dataLabels/queries/keys';
import { selectIsDataConfigDeltaLake } from 'app/modules/orgSettings/selectors';
import { useSelector } from 'react-redux';

interface GetCgdoDataLabelRecentEdit {
  data: {
    cgdo_type: 'ENTITY' | 'INSTRUMENT' | 'TXN_EVENT' | 'DEVICE';
    cgdo_external_id: string;
    started_at: string;
  }[];
  count: number;
}

export const useGetCgdoDataLabelRecentEdit = (
  objectId: string,
  objectType: 'ENTITY' | 'INSTRUMENT' | 'TXN_EVENT' | 'ACTION_EVENT' | 'DEVICE',
) => {
  const isDataConfigDeltalake = useSelector(selectIsDataConfigDeltaLake);
  return useQuery({
    queryFn: () =>
      isDataConfigDeltalake
        ? get<GetCgdoDataLabelRecentEdit>('/delta_lake_cgdo_data_label_edits', [
            {
              key: 'cgdo_type',
              value: objectType,
            },
            {
              key: 'cgdo_external_id',
              value: objectId,
            },
            {
              key: 'within_last_mins',
              value: '5',
            },
            {
              key: 'limit',
              value: '1',
            },
          ])
        : Promise.resolve({
            data: [],
            count: 0,
          } satisfies GetCgdoDataLabelRecentEdit),
    queryKey: ALL_DATA_LABELS_QUERY_KEYS.getCgdoDataLabelRecentEdit(
      objectType,
      objectId,
      isDataConfigDeltalake,
    ),
    refetchInterval: 5000,
  });
};
