import { useDispatch } from 'react-redux';
import { useMutation } from '@tanstack/react-query';

// Models
import { CreateAiSessionPayload } from 'app/modules/codeInterpreter/requests';
import { CreateAiSessionResponse } from 'app/modules/codeInterpreter/responses';

import { post } from 'app/shared/utils/fetchr';
import { sendErrorToast } from 'app/shared/toasts/actions';

const createAiSession = (payload: CreateAiSessionPayload) => {
  return post<CreateAiSessionResponse>('/code-interpreter-session', payload);
};

export const useCreateAiSession = () => {
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: (fileExportId: number) =>
      createAiSession({ file_export_id: fileExportId }),
    onError: () => dispatch(sendErrorToast('Failed to create chat session.')),
  });
};
