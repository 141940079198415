export const sortCreatedAt = <TElement extends { created_at: string | null }>(
  array: TElement[],
): TElement[] => {
  return array.toSorted((a, b) =>
    new Date(a.created_at ?? 0) < new Date(b.created_at ?? 0) ? 1 : -1,
  );
};

export const sortEventTime = <TElement extends { event_time: string | null }>(
  array: TElement[],
): TElement[] => {
  return array.toSorted((a, b) =>
    new Date(a.event_time ?? 0) < new Date(b.event_time ?? 0) ? 1 : -1,
  );
};
