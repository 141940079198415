import { FC } from 'react';

// Components
import { U21Spacer } from 'app/shared/u21-ui/components';
import LinkedLabel, { ListItemProps } from 'app/shared/components/LinkedLabel';
import { Typography } from '@mui/material';

const MAX_ID_LENGTH = 3;

export interface IdsTableCellProps {
  ids: number[];
  type: ListItemProps['type'];
}

export const IdsTableCell: FC<IdsTableCellProps> = ({ ids, type }) => {
  if (ids.length > MAX_ID_LENGTH) {
    const idsToShow = ids.slice(0, MAX_ID_LENGTH);
    const additionalIdCount = ids.length - MAX_ID_LENGTH;
    return (
      <U21Spacer>
        {idsToShow.map((id) => {
          return (
            <LinkedLabel
              key={`${type}-${id}`}
              id={String(id)}
              title={String(id)}
              type={type}
              shouldLink
            />
          );
        })}
        <Typography>{`And ${additionalIdCount} more`}</Typography>
      </U21Spacer>
    );
  }
  return (
    <U21Spacer>
      {ids.map((id) => {
        return (
          <LinkedLabel
            key={`${type}-${id}`}
            id={String(id)}
            title={String(id)}
            type={type}
            shouldLink
          />
        );
      })}
    </U21Spacer>
  );
};
