import styled from 'styled-components';
import { List } from '@mui/material';
import { U21Typography } from 'app/shared/u21-ui/components';
import { AiSessionItem } from 'app/modules/codeInterpreter/components/AiSessionItem';
import { AiSessionResponse } from 'app/modules/codeInterpreter/responses';

interface AiSessionListProps {
  data: AiSessionResponse[];
  sessionId: number;
  setSessionId: (newSessionId: number) => void;
}

export const AiSessionList = ({
  data,
  sessionId,
  setSessionId,
}: AiSessionListProps) => {
  return (
    <BarWrapper>
      <StyledHeader>
        <U21Typography variant="h5">Sessions</U21Typography>
      </StyledHeader>
      <StyledList>
        {data.map((session) => {
          return (
            <AiSessionItem
              key={session.id}
              session={session}
              isSelected={session.id === sessionId}
              onClick={setSessionId}
            />
          );
        })}
      </StyledList>
    </BarWrapper>
  );
};

const BarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 320px;
  height: calc(100% - 24px); // 24px to account for bottom margin
  margin: 24px 24px 24px 0px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.palette.background.neutral};
`;

const StyledList = styled(List)`
  padding: 0px;
  height: 100%;
  overflow-y: auto;
`;

const StyledHeader = styled.div`
  margin: 16px;
`;
