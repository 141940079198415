import { useMutation } from '@tanstack/react-query';
import { destroy } from 'app/shared/utils/fetchr';
import { sendErrorToast, sendSuccessToast } from 'app/shared/toasts/actions';
import { useDispatch } from 'react-redux';
import { DeleteAttachmentResponse } from 'app/modules/attachmentsRefresh/responses';
import { deleteAttachment } from 'app/modules/attachmentsRefresh/sliceAttachments';

export const useDeleteAttachment = () => {
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: (id: number) =>
      destroy<DeleteAttachmentResponse>(`/attachments/${id}`),
    onError: () =>
      dispatch(
        sendErrorToast('Failed to delete attachment. Please try again.'),
      ),
    onSuccess: (response) => {
      dispatch(deleteAttachment(response.id));
      dispatch(sendSuccessToast('Successfully deleted attachment'));
    },
  });
};
