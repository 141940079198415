import { Collapse } from '@mui/material';
import { ExecutionInfo } from 'app/modules/pullBasedDataFiles/components/sidebars/ProcessingErrorsSidebar';
import { UnhandledProcessingExceptionInstance } from 'app/modules/pullBasedDataFiles/responses';
import { U21Button, U21Spacer } from 'app/shared/u21-ui/components';
import { useState } from 'react';
import styled from 'styled-components';

export const UnknownError = ({
  obj,
}: {
  obj: UnhandledProcessingExceptionInstance;
}) => {
  const { value: error, raw_data: rawData, row_number: rowNumber } = obj;
  const [showData, setShowData] = useState<boolean>(false);

  return (
    <InnerSpacer>
      <ExecutionInfo labelWidth={86} label="Error" value={error} />
      <ExecutionInfo labelWidth={86} label="Row number" value={rowNumber} />
      <div>
        <StyledButton
          variant="text"
          color="primary"
          onClick={() => setShowData((prev) => !prev)}
        >
          {showData ? 'Hide' : 'Show'} raw data...
        </StyledButton>
      </div>
      <Collapse in={showData}>
        <RawData>{JSON.stringify(rawData, null, 4)}</RawData>
      </Collapse>
    </InnerSpacer>
  );
};

const InnerSpacer = styled(U21Spacer)`
  margin-bottom: 8px;
`;

const StyledButton = styled(U21Button)`
  ${(props) => props.theme.typography.body2};
`;

const RawData = styled.pre`
  font-size: ${(props) => props.theme.typography.body2.fontSize};
`;
