import React from 'react';

// Components
import { Table } from 'semantic-ui-react';

// Styles
import styles from 'app/modules/alerts/styles/AlertCustomDataTable.module.scss';

interface OwnProps {
  data: object;
}

type AllProps = OwnProps;

const DictionaryToTable = (props: AllProps) => {
  const renderValueCells = (title, value, ix = 0) => {
    if (!isNaN(title) && typeof value === 'object' && value !== null) {
      // if Array of objects
      return (
        <>
          <Table.Row key={`${title}-${value}-${ix}`}>
            <Table.HeaderCell colSpan="2" />
          </Table.Row>
          {Object.keys(value).map((key: string, idx) => {
            const nestedValue = value[key];
            return renderValueCells(key, nestedValue, idx);
          })}
        </>
      );
    }

    if (typeof value === 'object' && value !== null) {
      // Single object
      return (
        <>
          <Table.Row key={`${title}-${value}-${ix}`}>
            <Table.HeaderCell colSpan="2">
              {title.replace('_', ' ')}
            </Table.HeaderCell>
          </Table.Row>
          {Object.keys(value).map((key: string, idx) => {
            const nestedValue = value[key];
            return renderValueCells(key, nestedValue, idx);
          })}
        </>
      );
    }

    if (!isNaN(title)) {
      // Array of simple values
      return (
        <Table.Row key={`${title}-${value}-${ix}`}>
          <Table.Cell colSpan="2">{value}</Table.Cell>
        </Table.Row>
      );
    }

    return (
      <Table.Row key={`${title}-${value}-${ix}`}>
        <Table.Cell>{title}</Table.Cell>
        <Table.Cell>{value}</Table.Cell>
      </Table.Row>
    );
  };

  const { data } = props;
  const sortOrder = [
    'general_data',
    'user_data',
    'business_data',
    'custom_data',
    'location_data',
    'communication_data',
    'digital_data',
    'document_data',
    'options',
  ];
  function compare(a: string, b: string) {
    const aOrder =
      sortOrder.indexOf(a) !== -1 ? sortOrder.indexOf(a) : Infinity;
    const bOrder =
      sortOrder.indexOf(b) !== -1 ? sortOrder.indexOf(b) : Infinity;
    if (aOrder < bOrder) {
      return -1;
    }
    return 1;
  }

  return (
    <>
      {Object.keys(data)
        .sort(compare)
        .map((key: string) => {
          return (
            <Table celled striped fixed className={styles.table} key={key}>
              <Table.Header>
                <Table.Row textAlign="center">
                  <Table.HeaderCell colSpan="2">
                    {key.replace('_', ' ')}
                  </Table.HeaderCell>
                </Table.Row>
                {typeof data[key] === 'object' &&
                  Object.keys(data[key]).map((innerKey: string, jdx) => {
                    const value = data[key][innerKey];
                    return renderValueCells(innerKey, value, jdx);
                  })}
                {typeof data[key] !== 'object' && (
                  <Table.Row>
                    <Table.Cell colSpan="2">{data[key]}</Table.Cell>
                  </Table.Row>
                )}
              </Table.Header>
            </Table>
          );
        })}
    </>
  );
};

export default DictionaryToTable;
